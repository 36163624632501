import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../common/global-constants';
import { environment } from './../../../environments/environment';
import { Session } from '../../common/session';
import { HostListener } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';

import { UserService } from '../../services/user.service';
import { FellohService } from '../../services/felloh.service';
import { BranchService } from '../../services/branch.service';
import { BjornService } from '../../services/bjorn.service';

import { AppComponent } from '../../app.component';

import { animate, state, style, transition, trigger } from '@angular/animations';
import * as moment from 'moment';
import { EmailTemplates } from 'src/app/common/email-templates';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', marginTop: '-1.25px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})

export class WCHomeComponent implements OnInit, AfterViewInit {
  // Imported variables from outside
  constants = new GlobalConstants();
  fellohPayGate = environment.fellohPayGate;
  innerWidth = AppComponent.myapp.innerWidth;

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild('paginatorOverdue') paginatorOverdue!: MatPaginator;
  @ViewChild('paginatorDue') paginatorDue!: MatPaginator;
  @ViewChild('chart', { static: false }) chart: any;
  @ViewChild('popUpDialog') popUpDialog!: TemplateRef<any>;
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;

  // Table data and table columns below
  fellohData: any = new MatTableDataSource<any>();
  fellohColumns = ['custName', 'bookingReference', 'method', 'createdDate', 'amount', 'status', 'expand'];
  overdueData: any = new MatTableDataSource<any>();
  overdueColumns = ['custName', 'bookingReference', 'amount', 'expand'];
  paymentsDueData: any = new MatTableDataSource<any>();
  paymentsDueColumns = ['custName', 'custEmail', 'bookingReference', 'amount', 'email'];

  // Variables controlling user access
  userType = '';
  singsPrefix = '';
  haveAccess = false;
  pageLoaded = false;
  adminJustOpened = false;
  restrictedUser = false;

  // Regex for dropping requirement for booking prefix
  noLongerIbos = /^()$/;

  // Variables controlling side-nav view
  overView = false;
  transactionView = true;
  pendingView = false;
  temporaryView = false;

  // Variables controlling Felloh getTransactionList() API
  pageSize = 25;
  pageNumber = 0;
  fromDate: any = '';
  toDate: any = '';
  filterValue: any = '';

  // Variables dealing with the multi-currency behaviour
  selectedCurrency: any = 'GBP';
  chartCurrencies: any = [];

  // Other variables
  chosenPrefix: any = '';
  bookingPrefixStatic: any = '';
  fellohTxnsDummyData: any = [];
  paymentNumber: any = 0;
  totalBalance: any = 0;
  errorMessage: any = '';
  successMessage: any = '';

  // Variables used for Admin Only
  branchListData: any = []; // Holds data of all branches
  branchListFiltered: any = []; // Holds data filtered by the company (TTNG/GTG etc..)
  filterString: any = ''; // String used in filtering out / in to filterInBranches variable
  selectedBranch: any = null;

  // Main chart options below - initially without any data
  public chartOptions: Partial<any> = {
    series: [{ name: '', data: [] },
    { name: '', data: [] }],
    chart: {
      height: 375,
      type: 'area',
      zoom: {
        enabled: false
      },
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: false,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 500
        }
      }
    },
    colors: ['#4D5FD1', '#b0b0b0'],
    dataLabels: {
      enabled: false,
    },
    title: {
      text: 'Sales',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      labels: {
        formatter: (value: any) => {
          return this.constants.getChartsValue(this.selectedCurrency, value);
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '100%',
        endingShape: 'rounded'
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
  };

  public pieChartOptions: Partial<any> = {
    series: [],
    chart: {
      type: 'donut',
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      height: 350,
      zoom: {
        enabled: false
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: false,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 500
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [{
      breakpoint: 280,
      options: {
        chart: {
          width: 100
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    colors: ['#4D5FD1', '#b0b0b0'],
    labels: ['Confirmed', 'Pending'],
    legend: {
      show: true,
      position: 'bottom',
      itemMargin: {
        horizontal: 5,
      },
    },
    yaxis: {
      labels: {
        formatter: (value: any) => {
          return this.constants.getChartsValue(this.selectedCurrency, value);
        }
      }
    },
  };

  constructor(private router: Router, private userService: UserService,
              private fellohService: FellohService, private branchService: BranchService,
              private bjornService: BjornService, public dialog: MatDialog) { }

  expandedElement: any;
  isExpansionDetailRow = (i: number, row: object) => row.hasOwnProperty('detailRow');

  ngOnInit(): void {
    // Login redirects user to this page. If that happens, browser needs to refresh the page
    // This is so the top-nav renders correctly
    if (Session.mySession.get('justLoggedIn') === true) {
      window.location.reload();
      Session.mySession.set('showStartPopup', true);
      Session.mySession.set('justLoggedIn', false);
      Session.mySession.set('bookingPop', false);
    } else {
      if (sessionStorage.length === 0 || Session.mySession === undefined) {
        sessionStorage.clear();
        this.router.navigate(['/login']);
      } else {
        // Browser wasn't refreshed but the button was clicked so the user could get here - no API need to be called
        this.userType = Session.mySession.getUser().userType;
        this.singsPrefix = Session.mySession.getUser().bookRefStr;
        this.setStartingDates(); // Call funciton which sets fromDate <-> toDate values..
        setTimeout(() => { AppComponent.myapp.fellohNav = true; }, 1);

        // Restricted user will have less options (e.g. no over the phone options etc..)
        if (Session.mySession.getUser().othersBookingAccess === 'no') { this.restrictedUser = true; }

        if (this.userType === 'sinGSAdmin' || this.userType === 'sinGSstaff' || this.userType === 'trustee') {
          // SinGS Admin and Staff are able to retrieve transactions from all of the branches.
          if (Session.mySession.getBranchList().expiryTime === 'EXPIRED') {
            this.branchService.getBranches(Session.mySession.getUser()).then((branches: any) => {
              if (branches.status === 'OK') {
                // Sort branch list A to Z and save it in the session first..
                const sorted = branches.data.sort((a: any, b: any) => (a.fullName > b.fullName) ? 1 : -1);
                Session.mySession.setBranchList(sorted);
                // Filter out all branches with an empty Felloh account ID
                // Change Q0000 branch name to '-- All branches --' and sort once again..
                this.branchListData = sorted.filter((branch: any) => branch.fellohSetup === 'yes');
                const allBranch = this.branchListData.find((branch: any) => (branch.tradeCode === 'Q0000')); allBranch.fullName = '-- All Branches --';
                this.branchListData.sort((a: any, b: any) => (a.fullName > b.fullName) ? 1 : -1);
                // Re-assign for manual filtering and change branch to Q0000 [-- All branches --]
                this.branchListFiltered = this.branchListData; // It needs to be the same list
                this.changeBranch(allBranch, 'script', 'no'); // Call this to change current branch
              } else {
                this.sendMessageToDialog('', branches.status, '', '');
              }
            }).catch((error: any) => {
              this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0201S)', error, Session.mySession.getUser());
            });
          } else {
            // Filter out all branches with an empty Felloh account ID
            this.branchListData = Session.mySession.getBranchList().branchList.filter((branch: any) => branch.fellohSetup === 'yes');
            // Change Q0000 branch name to '-- All branches --' and sort once again..
            const allBranch = this.branchListData.find((branch: any) => (branch.tradeCode === 'Q0000')); allBranch.fullName = '-- All Branches --';
            this.branchListData.sort((a: any, b: any) => (a.fullName > b.fullName) ? 1 : -1);
            // Re-assign for manual filtering and change branch to Q0000 [-- All branches --]
            this.branchListFiltered = this.branchListData; // It needs to be the same list
            this.changeBranch(allBranch, 'script', 'no'); // Call this to change current branch
          }
        } else {
          if (Session.mySession.getUsersGroup().length > 0) {
            // Logged in user is in the group and branch list already exists within the session variable..
            this.branchListData = Session.mySession.getUsersGroup();
            this.branchListFiltered = this.branchListData; // It needs to be the same list
            if (Session.mySession.get('createdPayment') === true) {
              // Payment has just been created - we need to set the branch the payment was created to first
              this.changeBranch(this.branchListData.filter((branch: any) => branch.tradeCode === Session.mySession.get('paymentBranch'))[0], 'script', 'yes');
            } else {
              // Multi-user branch just opened the page - set its default branch for start
              this.changeBranch(this.branchListData.filter((branch: any) => branch.tradeCode === Session.mySession.getUser().tradeCode)[0], 'script', 'yes');
            }
          } else {
            // Non-multi user accessed the page - set its branch (felloh acc id etc.)
            this.changeBranch(Session.mySession.getBranch(), 'script', 'yes');
          }
        }
      }
    }
  }

  ngAfterViewInit(): void {
    // Remove paginator's mat tooltips (unnecesary)
    const paginatorIntl = this.paginator._intl;
    paginatorIntl.firstPageLabel = '';
    paginatorIntl.nextPageLabel = '';
    paginatorIntl.previousPageLabel = '';
    paginatorIntl.lastPageLabel = '';
  }

  setStartingDates(): void {
    this.haveAccess = true;
    // Calculate today's date for yyyy-mm-dd format
    const today: any = new Date(); // today.setDate(today.getDate() - 94); // Remove comment when testing..
    const weekBefore = new Date(); weekBefore.setDate(weekBefore.getDate() - 7); // Change to 94 when testing..
    // Assign dates to the global dates which will be changed later on by the user
    this.toDate = this.constants.convertDateNotMoment(today);
    this.fromDate = this.constants.convertDateNotMoment(weekBefore);
  }

  changeDate(dateType: any, date: any): void {
    // Translate for Felloh-friendly date and reload Felloh data
    if (dateType === 'fromDate') {
      if (date.value != null) {
        this.fromDate = this.constants.convertDateMoment(date.value);
      }

      if (this.toDate._i !== undefined) {
        this.toDate = this.constants.convertDateMoment(this.toDate);
      }
    } else if (dateType === 'toDate') {
      if (date.value != null) {
        this.toDate = this.constants.convertDateMoment(date.value);

        if (this.fromDate._i !== undefined) {
          this.fromDate = this.constants.convertDateMoment(this.fromDate);
        }
      }
    }
    this.adminJustOpened = false; // Changing dates will disable the justOpened admin case
    // Below we're checking the number of the days the request is made for
    // We don't to allow huge requests (e.g. a year worth of data) due to server limits
    const diffInDays = Math.ceil((new Date(this.toDate).getTime() - new Date(this.fromDate).getTime()) / (1000 * 60 * 60 * 24));

    // If the number is above the treshold, we'll set the other date type automatically
    // The new date will be !dateType (othertype) +/- threshold
    if (diffInDays > 30 && dateType === 'fromDate') {
      const toDate = new Date(this.fromDate); toDate.setDate(toDate.getDate() + 30);
      this.toDate = this.constants.convertDateNotMoment(toDate);
    } else if (diffInDays > 30 && dateType === 'toDate') {
      const fromDate = new Date(this.toDate); fromDate.setDate(fromDate.getDate() - 30);
      this.fromDate = this.constants.convertDateNotMoment(fromDate);
    }

    // Make sure none of these two are null (non-date value entered in the field)
    if (this.toDate != null && this.fromDate != null) {
      // Check if the date is in the right format here
      if (moment(this.fromDate, 'YYYY-MM-DD', true).isValid() && moment(this.toDate, 'YYYY-MM-DD', true).isValid()) {
        this.pageLoaded = false;
        this.prepareForFellohTxns();
      } else {
        this.sendMessageToDialog('', 'One of the dates was in the wrong format', '', '');
        this.setStartingDates();
      }
    } else {
      this.sendMessageToDialog('', 'One of the dates was in the wrong format', '', '');
      this.setStartingDates();
    }
  }

  changeBranch(branch: any, source: any, loadTxns: any): void {
    // Function called whenver SinGS Admin/Staff changes the branch from select list
    this.pageLoaded = false;
    if (branch !== undefined) {
      Session.mySession.setFellohAdmin(branch.fellohAccountId);
      this.selectedBranch = branch;
    } else {
      Session.mySession.setFellohAdmin('');
      this.selectedBranch = branch;
    }
    // Depending on the company (and ), set the bookingPrefix for creating/updating payments
    if (Session.mySession.getUser().company === 'ttng' && !this.noLongerIbos.test(this.selectedBranch.tradeCode)) {
      this.bookingPrefixStatic = 'NWG-'; this.chosenPrefix = 'NWG-';
    } else if (Session.mySession.getUser().company === 'gtg' && !this.noLongerIbos.test(this.selectedBranch.tradeCode)) {
      this.bookingPrefixStatic = 'GTG-'; this.chosenPrefix = 'GTG-';
    } else if (this.noLongerIbos.test(this.selectedBranch.tradeCode)) {
      this.bookingPrefixStatic = ''; this.chosenPrefix = '';
    }
    // Reload felloh if loadTxns is set to 'yes'. Otherwise - we'll need user to do it themselves (saves Felloh money)
    if (loadTxns === 'yes') {
      this.adminJustOpened = false; // Second (or further) reload removes 'justOpened' which will remove unnecessary pre-load
      this.prepareForFellohTxns().then(() => {
        // If the browser is refreshed (or user just logged in), show the popup message with payments
        if (Session.mySession.get('showStartPopup') === true) {
          if (this.paymentsDueData.data.length > 0 || this.overdueData.data.length > 0) {
            this.dialog.open(this.popUpDialog);
          }
          Session.mySession.set('showStartPopup', false);
        }
        // Open Pending Payments after creating new one.. possibly open the payment up..?
        if (Session.mySession.get('createdPayment') === true) {
          this.transactionView = false;
          this.pendingView = true;
          this.expandedElement = this.paymentsDueData.data[0];
          Session.mySession.set('createdPayment', false);
        }
        // If the page was just open - do as below..
        if (source === 'script') {
          AppComponent.myapp.fellohNav = true;
          window.dispatchEvent(new Event('resize'));
        }
      });
    } else { this.pageLoaded = true; this.adminJustOpened = true; }
  }

  changeStatus(status: any): void {
    this.filterData(''); // Clear the filter below - much needed indeed!
    this.overdueData.filter = status.trim().toLowerCase();
    this.paymentsDueData.filter = status.trim().toLowerCase();
    this.fellohData.filter = status.trim().toLowerCase();
  }

  prepareForFellohTxns(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Reset total balance & charges to £0 and recalculate it again
      this.totalBalance = 0; this.paymentNumber = 0;
      // Reset dummy array to nothing - we'll need to re-calculate stuff once again
      this.pageNumber = 0; this.fellohTxnsDummyData = [];
      this.filterData(''); // Clear the filter below - much needed indeed!
      // Call main function below (where there may be loop within the loop)
      this.reloadFelloh().then(() => {
        resolve('');
      });
    });
  }

  reloadFelloh(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Function called whenever date range or branch has been changed in UI
      // Create Felloh request hash to request access token and call the API
      Session.mySession.fetchFellohAuthorisation(this.fellohService).then((tokenOut: any) => {
        // Create a Felloh payment request hash to which will be used to retrieve Felloh transactions list
        const paymentRequest = {
          pageSize: this.pageSize, pageNumber: this.pageNumber, fromDate: this.fromDate, toDate: this.toDate,
          connectedAccID: Session.mySession.getFelloh().connectedAccID
        };

        this.fellohService.getFellohTransactionList(paymentRequest, tokenOut).then((fellohList: any) => {
          let filteredList: any = []; // Assign empty array here

          // Staff (non-managers) will only be able to see payments made by themselves only
          if (this.userType === 'wcMember' || this.userType === 'memberStaff') {
            filteredList = fellohList.transactionList.filter((txn: any) => txn.requestCreator === Session.mySession.getUser().email);
          } else {
            filteredList = fellohList.transactionList;
          }

          // filteredList = filteredList.filter((txn: any) => txn.status != 'PaymentDeclined' && txn.status != 'PaymentRejected' && txn.status != 'PaymentTimeout' && txn.status != 'Expired');
          filteredList.forEach((element: any) => {
            element.detailRow = true;
            element.filterStatus = '';
            element.isSingsBooking = false;
            // We are grouping statuses into 2 main ones so we can easily filter it with MatTableDataSource variable
            if (element.status === 'Created' || element.status === 'Pending' || element.status === 'PaymentInProgress') {
              element.filterStatus = 'createdStatus';
            } else if (element.status === 'PaymentReceived' || element.status === 'PaymentCompleted' || element.status === 'PayoutComplete' || element.status === 'PaymentAuthorised' || element.status === 'ExternalSettlement') {
              element.filterStatus = 'receivedStatus';
            }
            element.customerName = element.customer.name; // This is for easier filtering
          });

          // Merge output into one variable here (in case there's more than 25 payments within date range)
          this.fellohTxnsDummyData = this.fellohTxnsDummyData.concat(filteredList);

          // In case there's more payments than 25, increase add another page and re-do the method
          if (fellohList.totalPages > this.pageNumber + 1) {
            this.pageNumber = this.pageNumber + 1;
            this.reloadFelloh(); // Call yourself once again
          } else {
            // Assign transaction list and paginator to the data table variable
            this.fellohData.data = this.fellohTxnsDummyData;
            this.fellohData.paginator = this.paginator;
            // Set possible currencies to choose from in the chart tab - the selected currency will be the first one available
            const dataCurrencies: any = [...new Set(this.fellohData.data.map((item: any) => item.currency))];
            if (!dataCurrencies.includes('GBP')) { dataCurrencies.push('GBP'); }
            this.chartCurrencies = dataCurrencies.sort((a: any, b: any) =>  (a > b ? 1 : -1));

            this.feedChartWithFelloh().then((out: any) => {
              // Filter transaction list and assign the output to the data table variables below
              this.overdueData.data = this.fellohTxnsDummyData.filter((txn: any) => (txn.merchantRequestId !== undefined && txn.merchantRequestId !== null &&
              (txn.merchantRequestId.toUpperCase().includes('TEMP-') || txn.merchantRequestId.toUpperCase().includes('TMP-'))) || txn.merchantRequestId === null);
              // this.overdueData.data = filteredList.filter((txn: any) => (txn.merchantRequestId.includes('temp-') || txn.merchantRequestId.includes('TEMP-')) && txn.status != 'PaymentRejected' && txn.status != 'PaymentDeclined');
              this.paymentsDueData.data = this.fellohTxnsDummyData.filter((txn: any) => txn.status.includes('Created') || txn.status.includes('Pending') || txn.status.includes('PaymentInProgress'));
              // Assign paginators to data table variables
              this.overdueData.paginator = this.paginatorOverdue;
              this.paymentsDueData.paginator = this.paginatorDue;
              this.pageLoaded = true;
              resolve('');
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E0201F)', error, paymentRequest);
          resolve('');
        });
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E0202F)', error, '');
        resolve('');
      });
    });
  }

  feedChartWithFelloh(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Convert date range using 'moment' function
      const fromDate = moment(this.fromDate);
      const toDate = moment(this.toDate);

      let sumDone = 0;
      let sumPending = 0;
      // Empty array lists for filtered payments
      let filtered: any = [];
      let filteredTEMP: any = [];
      // Empty array lists for series objects
      const updateSeriesArray: any = [];
      const updateSeriesArrayTEMP: any = [];

      // Get the number of payments with selected currency (GBP / USD..)
      this.paymentNumber = this.fellohTxnsDummyData.filter((txn: any) =>
      txn.currency === this.selectedCurrency &&
      txn.status !== 'PaymentDeclined' && txn.status !== 'PaymentRejected' &&
      txn.status !== 'PaymentTimeout' && txn.status !== 'Expired').length;
      // Calculate the total balance with selected currency (GBP / USD..)
      this.totalBalance = this.fellohTxnsDummyData.filter((txn: any) =>
      txn.currency === this.selectedCurrency &&
      txn.status !== 'PaymentDeclined' && txn.status !== 'PaymentRejected' &&
      txn.status !== 'PaymentTimeout' && txn.status !== 'Expired').reduce((a: any, b: any) => a + (b.amount || 0), 0);

      // SinGS needs to go through each day within date range and check for the payment within it
      for (const day = moment(fromDate); day.diff(toDate, 'days') <= 0; day.add(1, 'days')) {
        // Create series object which will store x - date and y - total amount (££££)
        const seriesObject: any = { x: '', y: '' };
        const seriesObjectTEMP: any = { x: '', y: '' };
        seriesObject.x = day.format('YYYY-MM-DD');
        seriesObjectTEMP.x = day.format('YYYY-MM-DD');

        // Filter out/in temporary references from transaction list and assign results into variables below
        filtered = this.fellohData.data.filter((txn: any) =>
        txn.createdDate.includes(day.format('YYYY-MM-DD')) && txn.currency === this.selectedCurrency &&
        (txn.status.includes('PaymentReceived') || txn.status.includes('PaymentCompleted') ||
        txn.status.includes('PaymentAuthorised') || txn.status.includes('PaymentRefundComplete') ||
        txn.status.includes('PayoutComplete') || txn.status.includes('ExternalSettlement')));
        // Temporary references are below and non-TEMP above - simple..
        filteredTEMP = this.fellohData.data.filter((txn: any) =>
        txn.createdDate.includes(day.format('YYYY-MM-DD')) && txn.currency === this.selectedCurrency &&
        (txn.status.includes('Created') || txn.status.includes('Pending') ||
        txn.status.includes('PaymentInProgress')));

        if (filtered.length > 0) {
          // Add up all of the payments' values together and assign it to series y variable
          seriesObject.y = filtered.reduce((sum: any, current: any) => sum + current.amount, 0).toFixed(2);
          sumDone = sumDone + Number(seriesObject.y);
        } else {
          seriesObject.y = 0;
        }

        if (filteredTEMP.length > 0) {
          // Add up all of the payments' values together and assign it to series y variable
          seriesObjectTEMP.y = filteredTEMP.reduce((sum: any, current: any) => sum + current.amount, 0).toFixed(2);
          sumPending = sumPending + Number(seriesObjectTEMP.y);
        } else {
          seriesObjectTEMP.y = 0;
        }
        // Add series object into array list
        updateSeriesArray.push(seriesObject);
        updateSeriesArrayTEMP.push(seriesObjectTEMP);
      }
      // Update chart series with series objects created earlier for temp/non-temp references
      this.chartOptions.series = [{ name: 'Confirmed', data: updateSeriesArray }, { name: 'Pending', data: updateSeriesArrayTEMP }];
      this.pieChartOptions.series = [Number(sumDone.toFixed(2)), Number(sumPending.toFixed(2))];
      resolve('');
    }).catch((error: any) => {
      this.sendMessageToDialog('', '', error, 'Home Component - feedChartWithFelloh() failed');
    });
  }

  changeChartCurrency(currency: any): void {
    this.selectedCurrency = currency; // Change global currency values
    this.feedChartWithFelloh();
  }

  editBookingRef(form: NgForm, fellohPayment: any): void {
    const blockedPrefixes: any = ['TTAS-', 'TTNG-', 'GTGS-', 'NWG-', 'GTG-'];
    const blockedSingsPfx: any = ['TTAS-', 'TTNG-', 'GTGS-'];

    let bookingPrefix = ''; // We need to set the prefix here

    // Check if booking is from SinGS or from outside
    if (!fellohPayment.isSingsBooking && !fellohPayment.merchantRequestId?.startsWith('FX-')) {
      bookingPrefix = this.bookingPrefixStatic; // Not from SinGS; use usual NWG / GTG prefixes
    } else if (!fellohPayment.isSingsBooking && fellohPayment.merchantRequestId?.startsWith('FX-')) {
      bookingPrefix = 'FX-'; // If current reference starts with FX- then it's an FX transactions (always?)
    } else {
      bookingPrefix = this.singsPrefix.toUpperCase() + '-'; // From SinGS - remember to WRITE IT IN CAPS
    }
    // Re-assign booking prefix if the select list was present on the UI side
    if (form.value.fellohPrefix) { bookingPrefix = form.value.fellohPrefix; }

    this.pageLoaded = false;
    if ((!fellohPayment.isSingsBooking && Session.mySession.getUser().company === 'tta') || bookingPrefix === 'FX-') {
      // TTA reference numbers are free text but we still need to validate passed prefix
      if (!blockedPrefixes.includes(form.value.newRef.substring(0, 4).toUpperCase()) &&
          !blockedPrefixes.includes(form.value.newRef.substring(0, 5).toUpperCase())) {
            if (fellohPayment.temporaryRequestIdFlag) {
              this.updateMerchantRequestId(form, fellohPayment, bookingPrefix);
            } else {
              this.updateNonTempReference(form, fellohPayment, bookingPrefix);
            }
      } else {
        this.sendMessageToDialog('', 'You are not allowed to use this booking reference prefix', '', '');
      }
    } else if (this.noLongerIbos.test(this.selectedBranch.tradeCode)) {
      // Because Global / WC+ no longer uses iSell, we allow free text in the booking reference (but still need to block Sings refs though)
      if (!blockedSingsPfx.includes(form.value.newRef.substring(0, 5).toUpperCase())) {
        this.updateMerchantRequestId(form, fellohPayment, bookingPrefix);
      } else {
        this.sendMessageToDialog('', 'You are not allowed to use this booking reference prefix', '', '');
      }
    } else if (fellohPayment.isSingsBooking || Session.mySession.getUser().company === 'ttng' || Session.mySession.getUser().company === 'gtg') {
      // Check if the input value contains only numbers. Also, the number provided MUST be higher than 0 i.e. GTG-000001
      if (form.value.newRef.match(/^-?\d+$/) && form.value.newRef > 0) {
        this.validateBookingRef(form, fellohPayment, bookingPrefix);
      } else {
        // Wrong format message
        this.sendMessageToDialog('', 'Booking reference is in the wrong format', '', '');
      }
    }
  }

  validateBookingRef(form: NgForm, fellohPayment: any, bookingPrefix: any): void {
    // Create request hash which is then used in the isValidBookingRef() - Front calls SinGS, which then calls Bjorn for verification.
    const request = {
      company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation, tradeCode: this.selectedBranch.tradeCode,
      bookingRef: bookingPrefix + form.value.newRef.trim(), token: Session.mySession.get('user').token
    };
    // New NgForm object needed as values dissapear after submission
    const formRequest = { value: { newRef: form.value.newRef } } as NgForm;

    this.bjornService.isValidBookingRef(request).then((output: any) => {
      if (output.result === 'exists') {
        // Validation was a success, go and try to change the reference number now
        if (fellohPayment.temporaryRequestIdFlag) {
          this.updateMerchantRequestId(formRequest, fellohPayment, bookingPrefix);
        } else {
          this.updateNonTempReference(formRequest, fellohPayment, bookingPrefix);
        }
      } else if (output?.status === 'Access denied') {
        this.sendMessageToDialog('', 'Access denied - please refresh your page', '', '');
      } else {
        // Bjorn may return the message saying that it couldn't find the booking. If that's the case, nothing is being updated in Felloh
        this.sendMessageToDialog('', 'This booking doesn\'t exist', '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0206S)', error, request);
    });
  }

  updateMerchantRequestId(form: NgForm, fellohPayment: any, bookingPrefix: any): void {
    // Create a new hash which holds all data needed to transform temp booking to GTG/NWG
    const paymentRequest = {
      transactionId: fellohPayment.transactionId, oldMerchantRequestId: fellohPayment.merchantRequestId,
      newMerchantRequestId: bookingPrefix + form.value.newRef.trim(), connectedAccountId: Session.mySession.getFelloh().connectedAccID
    };
    // Function called whenever date range or branch has been changed in UI
    // Create Felloh request hash to request access token and call the API
    Session.mySession.fetchFellohAuthorisation(this.fellohService).then((tokenOut: any) => {
      // Token out, token in. Update the reference
      this.fellohService.updateMerchantRequestId(paymentRequest, tokenOut).then(() => {
        this.prepareForFellohTxns().then(() => {
        });
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E0205F)', error, paymentRequest);
      });
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E0203F)', error, '');
    });
  }

  updateNonTempReference(form: NgForm, fellohPayment: any, bookingPrefix: any): void {
    const updateRequest = { reference: bookingPrefix + form.value.newRef.trim() };
    // Create Felloh request hash to request access token and call the API
    Session.mySession.fetchFellohAuthorisation(this.fellohService).then((tokenOut: any) => {
      // Token out, token in. Update the reference
      this.fellohService.updateNonTempReference(updateRequest, fellohPayment.transactionId, tokenOut).then(() => {
        this.prepareForFellohTxns().then(() => {
        });
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E0208F)', error, updateRequest);
      });
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E0207F)', error, '');
    });
  }

  openFellohLink(payment: any, source: any): void {
    const url = this.fellohPayGate + payment.transactionId + '?' + source;
    // Create a url based from the link's ID + source (over phone or in person)
    window.open(url, '_blank'); // Open the link afterwards
  }

  emailFellohLink(payment: any, type: any): void {
    // Create urlLink variable which is fellohPayGate (taken from Global Constants file) + unique transactionId
    const urlLink = this.fellohPayGate + payment.transactionId;
    // Call email tempalte method which will open a new window with pre-populated email text
    // This is also where custom email templates are created for selected branch(es)
    EmailTemplates.myEmails.getFellohEmailBody(urlLink, payment, this.selectedBranch, Session.mySession.getUser(), type);
  }

  deleteFellohLink(payment: any): void {
    if (confirm('Are you sure to remove payment ' + payment.transactionId + '?')) {
      // Function called whenever date range or branch has been changed in UI
      // Create Felloh request hash to request access token and call the API
      this.pageLoaded = false;
      Session.mySession.fetchFellohAuthorisation(this.fellohService).then((tokenOut: any) => {
        const request = { transactionId: payment.transactionId, connectedAccID: Session.mySession.getFelloh().connectedAccID };

        this.fellohService.deletePaymentRequest(request, tokenOut).then(() => {
          this.fellohData.data = this.fellohData.data.filter((transaction: any) => transaction.transactionId !== payment.transactionId);
          this.sendMessageToDialog('Payment link has been removed', '', '', '');
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E0206F)', error, request);
        });

      }).catch((error: any) => {
        this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E0204F)', error, '');
      });
    }
  }

  emailConfirmationEmails(payment: any): void {
    const request = {
      p: Session.mySession.getEmail().emailP, u: Session.mySession.getEmail().emailU,
      tradeCode: this.selectedBranch.tradeCode, custEmail: payment.customer.email,
      resendEmail: Session.mySession.getUser().email, payment, version: 'v1'
    };

    this.pageLoaded = false;
    this.fellohService.resendFellohReceipt(request).then((output: any) => {
      if (output.status === 'OK') {
        this.sendMessageToDialog('Emails have been sent to your email address', '', '', '');
      } else {
        this.sendMessageToDialog('', output.status, '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E0202S)', error, request);
    });
  }

  duplicateFellohLink(payment: any): void {
    // Function purely for transfering payment object to the /newLink page
    const stateVar = { payment, selectedBranch: this.selectedBranch };
    this.router.navigateByUrl('/newLink', { state: stateVar });
  }

  filterData(event: any): void {
    // Receive the value used for filtering Felloh data
    this.filterValue = event?.target?.value;
    // We're filtering all of data sources below
    this.fellohData.filter = this.filterValue;
    this.paymentsDueData.filter = this.filterValue;
    this.overdueData.filter = this.filterValue;
  }

  addFellohLinkToTemps(payment: any): void {
    // Function which will 'move' selected Felloh link over to 'Temporary References'
    // This way the user will be able to change its reference without changing UI too much
    let tempData = []; tempData = this.fellohTxnsDummyData.filter((txn: any) => txn.merchantRequestId !== undefined && txn.merchantRequestId !== null &&
    (txn.merchantRequestId.toUpperCase().includes('TEMP-') || txn.merchantRequestId.toUpperCase().includes('TMP-')));
    // We're introducing a new variable above which will 'reset' overdueData and insert requested payment at the beginning
    tempData.unshift(payment);
    this.overdueData.data = tempData;
    // Wait for 1 second before proceeding and open the table row on the top
    setTimeout(() => {
      this.switchView('temporary');
      this.expandedElement = this.overdueData.data[0];
    }, 200);
  }

  validateFXopeness(tradeCode: any): boolean {
    const regex = /(Q0284|Q0655|Q0829|Q7211|Q7226|Q9039|Q9043|Q9058|Q9062|Q9077|Q9081|Q9096|Q9109|Q9113|Q9128|Q9166|Q9363)/;
    // Pretty straight forward - we're 'opening' the FX part for above members..
    if (regex.test(tradeCode)) { return true; }
    else { return false; }
  }

  switchView(view: any): void {
    // Nothing special to add. Whenever button on left nav-bar is clicked, change the view variables values.
    this.expandedElement = null;
    this.filterData(''); // Reset filtering whenever the tab is changed
    if (view === 'overwiev') {
      this.overView = true;
      this.transactionView = false;
      this.pendingView = false;
      this.temporaryView = false;
    } else if (view === 'transaction') {
      this.overView = false;
      this.transactionView = true;
      this.pendingView = false;
      this.temporaryView = false;
    } else if (view === 'pending') {
      this.overView = false;
      this.transactionView = false;
      this.pendingView = true;
      this.temporaryView = false;
    } else if (view === 'temporary') {
      this.overView = false;
      this.transactionView = false;
      this.pendingView = false;
      this.temporaryView = true;
    } else if (view === 'reloadView') {
      this.pageLoaded = false;
      this.adminJustOpened = false;
      this.prepareForFellohTxns();
    }
  }

  exportToExcel(whatData: any): void {
    const exportMe: any = [];
    let oneLine: any = {};

    // Depending on what page we're trying to export, we'll be using different
    // variable storing data. An object represents one line which are then pushed
    // into array list. That represents excel sheet in short
    if (whatData === 'allPayments') {
      this.fellohData.data.forEach((data: any) => {
        oneLine = {};
        oneLine.transactionId = data.transactionId;

        if (data.additionalProperties !== undefined && data.additionalProperties.travelGroup !== undefined) {
          oneLine.travelGroup = data.additionalProperties.travelGroup;
        } else {
          oneLine.travelGroup = '';
        }

        oneLine.merchantName = data.merchantName;
        oneLine.bookingReference = data.merchantRequestId;
        oneLine.customerName = data.customer.name;
        oneLine.customerEmail = data.customer.email;
        oneLine.createdDate = data.createdDate;

        if (data.additionalProperties !== undefined && data.additionalProperties.departureDate !== undefined) {
          oneLine.departureDate = data.additionalProperties.departureDate;
        } else if (data.additionalProperties !== undefined && data.additionalProperties.departAt !== undefined) {
          oneLine.departureDate = data.additionalProperties.departAt;
        } else {
          oneLine.departureDate = '';
        }

        if (data.additionalProperties !== undefined && data.additionalProperties.returnDate !== undefined) {
          oneLine.returnDate = data.additionalProperties.returnDate;
        } else {
          oneLine.returnDate = '';
        }

        if (data.paymentMethod !== undefined) {
          oneLine.paymentMethod = data.paymentMethod;
        } else {
          oneLine.paymentMethod = '';
        }

        if (data.cardDetails !== undefined && data.cardDetails !== null && data.cardDetails.cardHolderName !== null) {
          oneLine.cardHolderName = data.cardDetails.cardHolderName;
          oneLine.lastFourDigits = data.cardDetails.lastFourDigits;
          oneLine.cardType = data.cardDetails.paymentBrand + ' ' + data.cardDetails.cardType;
        } else {
          oneLine.cardHolderName = '';
          oneLine.lastFourDigits = '';
          oneLine.cardType = '';
        }

        if (data.paymentDate !== undefined) {
          oneLine.paymentDate = data.paymentDate;
        } else {
          oneLine.paymentDate = '';
        }

        oneLine.currency = data.currency;
        oneLine.amount = data.amount;

        if (data.status === 'Pending' || data.status === 'PaymentInProgress' || data.status === 'AwaitingPayout') {
          oneLine.status = 'In Progress';
        } else if (data.status === 'PaymentReceived' || data.status === 'PaymentCompleted' || data.status === 'PayoutComplete' || data.status === 'PaymentAuthorised' || data.status === 'ExternalSettlement') {
          oneLine.status = 'Paid';
        } else {
          oneLine.status = data.status;
        }

        exportMe.push(oneLine);
      });
      this.constants.exportAsExcelFile(exportMe, 'fellohAllPayments');
    } else if (whatData === 'overduePayments') {
      this.overdueData.data.forEach((data: any) => {
        oneLine = {};
        oneLine.transactionId = data.transactionId;
        oneLine.merchantName = data.merchantName;
        oneLine.customerName = data.customer.name;
        oneLine.bookingReferene = data.merchantRequestId;
        oneLine.amount = data.amount;
        oneLine.createdDate = data.createdDate;
        exportMe.push(oneLine);
      });
      this.constants.exportAsExcelFile(exportMe, 'fellohTempReferences');
    } else if (whatData === 'duePayments') {
      this.paymentsDueData.data.forEach((data: any) => {
        oneLine = {};
        oneLine.transactionId = data.transactionId;
        oneLine.merchantName = data.merchantName;
        oneLine.customerName = data.customer.name;
        oneLine.email = data.customer.email;
        oneLine.bookingReferene = data.merchantRequestId;
        oneLine.amount = data.amount;
        oneLine.createdDate = data.createdDate;
        exportMe.push(oneLine);
      });
      this.constants.exportAsExcelFile(exportMe, 'fellohDuePayments');
    }
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  filterSelect(): void {
    this.branchListFiltered = []; // Empty filtered array first
    const filter = this.filterString.toLowerCase(); // Get the string we filter with here

    // Loop through our MAIN array and add whatever matches our search string
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.branchListData.length; i++) {
      const option = this.branchListData[i];
      if (option.fullName.toLowerCase().indexOf(filter) >= 0) {
        this.branchListFiltered.push(option);
      }
    }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // Check if the error comes from Felloh and if we can display it to the user
      // If we can then let's display it rather than give them weird code..
      if (error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
        failureMessage = error.error.message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      } else if (error.hasOwnProperty('error') && error.error.hasOwnProperty('error') && error.error.error.hasOwnProperty('message')) {
        failureMessage = error.error.error.message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      } else if (error.hasOwnProperty('error') && error.error.hasOwnProperty('errors') && error.error.errors.length > 0) {
        failureMessage = error.error.errors[0].message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      }
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    this.pageLoaded = true; // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    // Pop-up message only appears if either success or error message is not empty
    if (this.successMessage !== '' || this.errorMessage !== '') { this.dialog.open(this.statusDialog); }
  }

  showHelp(): void {
    // (window as any).Beacon('suggest', ['6245acd5ab585b230a8a708c', '624c0ff0ec0e757ba5af1fc2']);
    (window as any).Beacon('toggle'); // Open Help Scout
  }
}
