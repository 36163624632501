import { Component, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Session } from '../../common/session';
import { ElementService } from '../../services/element.service';
import { UserService } from '../../services/user.service';
import { BookingService } from '../../services/booking.service';
import { GlobalConstants } from '../../common/global-constants';
import { AppComponent } from '../../app.component';
import { HostListener, ElementRef } from '@angular/core';
import { environment } from './../../../environments/environment';
import { animate, style, transition, trigger } from '@angular/animations';
import * as moment from 'moment';

@Component({
  selector: 'app-element-create',
  templateUrl: './element-create.component.html',
  styleUrls: ['./element-create.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class ElementCreateComponent implements OnInit {
  // Boolean deciding whether user has access or not
  pageLoaded = false;
  elementNoView: boolean[] = [true]; // We are looking at the first element when page is loaded

  // Element and booking related variables
  element: any = []; // Contains information about the element
  elementData: any = {}; // Contains data which we'll create/edit element with
  elementType: any = ''; // Type of an element we're editing
  openedBooking: any; // Contains information about the booking (basic info needed for CORE stuff)
  supplier: any = {}; // Contains supplier info (number of days etc) (needed when creating new element)
  constDeptDate: any = ''; // This is the dept date we will come back to in case of an error
  constReturnDate: any = ''; // this is the return date we will come back to in case of an error

  // Other variables
  elementTypeNiceName: any = ''; // Prints out nicely formatted element type name
  elementDivs: number[] = [0]; // Array containing only numbers which are used in UI. It goes incrementaly by 1
  addnDataDiv: number[][] = [[0]]; // Array containing additonal data such as cruise-cabins or hotel-rooms (one to many)
  costingBreakdown: any = [{ description: '', supplierPrice: 0, realGross: 0, vatPrice: 0, discountPrice: 0, netPrice: 0, commissionPrice: 0, depositPrice: 0 }]; // Holds costing info
  totalCosts: any = { supplierPrice: 0, realGross: 0, commissionPrice: 0, vatPrice: 0, discountPrice: 0, netPrice: 0, depositPrice: 0 };
  driverArray: any = []; // Needed for assigning the driver to the element
  filterString: any = ''; // Used to filter country list (and maybe more in the future..)
  errorMessage: any = '';
  successMessage: any = '';

  // Imported variables from outside
  constants = new GlobalConstants();
  countries = GlobalConstants.countryList;
  countriesFiltered = GlobalConstants.countryList;
  flightCategories = GlobalConstants.flightCategories;
  hotelCategories = GlobalConstants.hotelCategories;
  cruiseRegions = GlobalConstants.cruiseRegions;
  cruiseCurrencies = GlobalConstants.cruiseCurrencies;
  paxNumber = GlobalConstants.paxNumber;
  ratings = GlobalConstants.ratings;
  innerWidth = AppComponent.myapp.innerWidth;

  // Pretty static variables
  today: any = new Date(); // Needed for booking date
  supplierReference: any = ''; // Outside of form hence need for this variable..
  elementNotes: any = ''; // Main element notes - outside of form hence need for this variable..
  boardBasiss = ['All Inclusive', 'As Brochure', 'Bed Breakfast', 'Chalet Board', 'Flight Only', 'Full Board', 'Half Board', 'Room Only', 'Self Catering'];
  flightClass: string[] = ['Business', 'Economy', 'First', 'Premium Economy'];

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;
  @ViewChild('helpDialog') helpDialog!: TemplateRef<any>;
  @ViewChild('createForm') createForm!: NgForm;

  constructor(private route: ActivatedRoute, private router: Router, private el: ElementRef,
              private userService: UserService, private bookingService: BookingService,
              private elementService: ElementService, public dialog: MatDialog,
              private ngZone: NgZone) {
  }

  ngOnInit(): void {
    if (sessionStorage.length === 0 || Session.mySession === undefined) {
      this.router.navigate(['/']);
    } else {
      this.route.params.subscribe(params => {
        this.elementType = params.element; // Get the element type from the URL
        this.openedBooking = Session.mySession.getOpenedBooking(); // Get booking's details here (TBD this will change)
        this.supplier = Session.mySession.getElementSupplier();
        this.element = window.history.state; // Holds element (supplier) data and sub-elements (types)
        this.constDeptDate = this.element.deptDate; // Assign const variable of dept date
        this.constReturnDate = this.element.returnDate; // Assign const variable of return date
        this.driverArray = Session.mySession.getLatestPax(); // Get the passenger list from session - used for Car Hire only (for now?)
        this.today = this.constants.convertDateNotMoment(this.today); // Convert today's date here
        // If param is not one of below it means we're not accepting it. We've got only so 'little' element types we support

        const validElements = ['flight', 'accommodation', 'carParking', 'carHire', 'attraction', 'cruise', 'miscellaneous', 'transfer', 'train'];

        if (!validElements.includes(params.element)) { this.elementType = 'unknown'; }
        else { this.setNiceName(); }
        this.pageLoaded = true;
      });
    }
  }

  createCoreElementData(form: NgForm, elementType: any): void {
    // Validate characters entered in the form and costing breakdown + costing comment..
    const validate1 = this.constants.validateFormCharacters(form);
    const validate2 = this.constants.validateVariableRegex(this.costingBreakdown, 'array');
    const validate3 = this.constants.validateVariableRegex(this.element.comment, 'variable');
    const validate4 = this.constants.validateVariableRegex(this.elementNotes, 'variable');
    const validate5 = this.constants.validateVariableRegex(this.supplierReference, 'variable');
    // Check and pop-up wrong validations (if true)
    if (validate1 !== true) { this.sendMessageToDialog('', 'Invalid characters in ' + validate1, '', ''); throw new Error(('Invalid string value')); }
    else if (validate2 !== true) { this.sendMessageToDialog('', 'Invalid characters in ' + validate2, '', ''); throw new Error(('Invalid string value')); }
    else if (validate3 !== true) { this.sendMessageToDialog('', 'Invalid characters in ' + validate3, '', ''); throw new Error(('Invalid string value')); }
    else if (validate4 !== true) { this.sendMessageToDialog('', 'Invalid characters in ' + validate4, '', ''); throw new Error(('Invalid string value')); }
    else if (validate5 !== true) { this.sendMessageToDialog('', 'Invalid characters in ' + validate5, '', ''); throw new Error(('Invalid string value')); }
    // We want to check whether the supplier reference provided by the user (FOR FRESH ELEMENTS ONLY) is valid
    // That is check if it's provided at all..
    if (this.openedBooking.freshElement === 'yes' && this.supplierReference.length < 3) {
      this.sendMessageToDialog('', 'Supplier Reference must be at least 3 characters long', '', '');
      throw new Error(('Invalid Supplier Reference'));
    }
    // That is check if it doesn't exist already in other elements..
    if (this.openedBooking.freshElement === 'yes' && this.openedBooking.blockSuppRefs.some((ref: any) => ref === this.supplierReference)) {
      this.sendMessageToDialog('', 'This supplier reference already exists in this booking', '', '');
      throw new Error(('Invalid Supplier Reference'));
    }
    // That is check if booking date has been applied at all..
    if (this.today === '' || this.today === undefined || this.today == null) {
      this.sendMessageToDialog('', 'Booking Date is in the wrong format', '', '');
      throw new Error(('Invalid Booking Date'));
    }
    // Convert today's date first..
    if (this.today._i !== null) { this.today = this.constants.convertDateMoment(this.today); }
    else { this.today = this.today; }

    // Create coreElementData containing all necessary stuff (tradeCode, operation etc..)
    this.elementData.coreElementData = {};
    this.elementData.token = Session.mySession.get('user').token;
    this.elementData.coreElementData.company = this.openedBooking.company;
    this.elementData.coreElementData.operation = this.openedBooking.operation;
    this.elementData.coreElementData.tradeCode = this.openedBooking.tradeCode;
    this.elementData.coreElementData.elementStatus = 'enquiry';
    this.elementData.coreElementData.bookingReference = this.openedBooking.bookingReference;
    this.elementData.coreElementData.supplierID = this.supplier.id;
    this.elementData.coreElementData.supplierName = this.supplier.supplierNameM;
    this.elementData.coreElementData.leadName = this.openedBooking.leadName;

    if (this.openedBooking.freshElement === 'no') {
      this.elementData.coreElementData.elementCount = this.element.elementCount; // It's needed so it 'connects' to the main Element
      this.elementData.coreElementData.bookingDate = this.element.bookingDate; // Booking date is static - just get it from element
      this.elementData.coreElementData.elementType = 'mixed'; // Adding new subElementType to existing element means there are 2+ different types inside element
      this.elementData.coreElementData.comment = this.element.comment;
      this.elementData.coreElementData.supplierReference = this.openedBooking.supplierReference;
    } else if (this.openedBooking.freshElement === 'yes') {
      this.elementData.coreElementData.elementType = elementType; // Fresh element means the type will be whatever was selected before
      // Set booking date over here - it may be selected from date-picker or simply inputed with keyboard..
      this.elementData.coreElementData.bookingDate = this.today;
      this.elementData.coreElementData.comment = this.elementNotes;
      this.elementData.coreElementData.supplierReference = this.supplierReference;
    }
  }

  createFlight(form: NgForm): void {
    if (form.valid) {
      this.createCoreElementData(form, 'flight'); // Create core which contains most basic element info
      this.elementData.coreElementData.deptAir = form.value.departAirCode0; // Save first flight's deptAir code in element table
      this.elementData.coreElementData.destAir = form.value.arriveAirCode0; // Save first flight's destAir code in element table
      this.elementDivs.forEach((index: any) => {
        const miniHashName = JSON.parse('{"elmentDataMiniHash' + index + '" : {}}'); // Very clever way of adding a suffix to property name
        miniHashName[Object.keys(miniHashName)[0]].subElementType = 'flight'; // Sub element type is flight.. no?

        // This is not the first element for for this supplier reference. We need to parse action for the update API call here..
        if (this.openedBooking.freshElement === 'no') {
          miniHashName[Object.keys(miniHashName)[0]].action = 'create';
          miniHashName[Object.keys(miniHashName)[0]].supplierRef = this.openedBooking.supplierReference;
        } else {
          miniHashName[Object.keys(miniHashName)[0]].supplierRef = this.supplierReference;
        }

        // Set booking date over here - it may be selected from date-picker or simply inputed with keyboard..
        miniHashName[Object.keys(miniHashName)[0]].bookingDate = this.today;
        miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
        miniHashName[Object.keys(miniHashName)[0]].departAirCode = form.value['departAirCode' + index as keyof typeof form.value]; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].destinAirCode = form.value['arriveAirCode' + index as keyof typeof form.value]; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].departAirName = form.value['departAirName' + index as keyof typeof form.value]; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].arriveAirName = form.value['arriveAirName' + index as keyof typeof form.value]; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].carrier = form.value['carrier' + index as keyof typeof form.value]; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].number = form.value['flightNo' + index as keyof typeof form.value]; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].flightClass = form.value['flightClass' + index as keyof typeof form.value]; // Usuall stuff..

        // 27.01.2022 new stuff here - removed HUGE calculations. Replaced with a nice and thin function to do the job..
        miniHashName[Object.keys(miniHashName)[0]].deptDate = this.calculateDeptArrDates('departure', form.value['departDate' + index as keyof typeof form.value]);
        miniHashName[Object.keys(miniHashName)[0]].returnDate = this.calculateDeptArrDates('return', form.value['arriveDate' + index as keyof typeof form.value]);

        // 13.04.2023 - validate Hrs and Mins
        const departHrs = this.constants.validateTime(form.value['departHrs' + index as keyof typeof form.value], 'hrs');
        const departMin = this.constants.validateTime(form.value['departMin' + index as keyof typeof form.value], 'min');
        const arriveHrs = this.constants.validateTime(form.value['arriveHrs' + index as keyof typeof form.value], 'hrs');
        const arriveMin = this.constants.validateTime(form.value['arriveMin' + index as keyof typeof form.value], 'min');

        // Calculate date + time here and assign it to variables
        miniHashName[Object.keys(miniHashName)[0]].departDateTime = miniHashName[Object.keys(miniHashName)[0]].deptDate.toString() +
          ' ' + this.constants.zeroBeforeNo(departHrs) + ':' + this.constants.zeroBeforeNo(departMin) + ':00';
        miniHashName[Object.keys(miniHashName)[0]].arriveDateTime = miniHashName[Object.keys(miniHashName)[0]].returnDate.toString() +
          ' ' + this.constants.zeroBeforeNo(arriveHrs) + ':' + this.constants.zeroBeforeNo(arriveMin) + ':00';

        miniHashName[Object.keys(miniHashName)[0]].adults = form.value['paxNo' + index as keyof typeof form.value]; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].children = form.value['paxNoInfants' + index as keyof typeof form.value]; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].infants = form.value['paxInfants' + index as keyof typeof form.value]; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].direction = form.value['direction' + index as keyof typeof form.value]; // Usuall stuff..

        miniHashName[Object.keys(miniHashName)[0]].description = this.costingBreakdown[index].description; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].grossCost = this.costingBreakdown[index].realGross; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].netCost = this.costingBreakdown[index].netPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].tax = this.costingBreakdown[index].vatPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].discount = this.costingBreakdown[index].discountPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].commission = this.costingBreakdown[index].commissionPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].depositAmount = this.costingBreakdown[index].depositPrice; // Usuall stuff..

        // Check if any of the flight's arrive date is before departure date..
        const arriveDateTime = new Date(miniHashName[Object.keys(miniHashName)[0]].arriveDateTime); // Get the arrival date from miniHashName and add one day
        arriveDateTime.setDate(arriveDateTime.getDate() + 1);  // Adding one day
        const departDateTime = new Date(miniHashName[Object.keys(miniHashName)[0]].departDateTime); // Get the departure date from miniHashName
        // We are allowing one day earlier in arriveDateTime..
        if (arriveDateTime < departDateTime) {
          this.sendMessageToDialog('', 'Flight number ' + (index + 1).toString() + ': the departure date cannot be more than 24 hours after the arrival date', '', '');
          this.element.deptDate = this.constDeptDate; // Set the departure date to what it was initially
          this.element.returnDate = this.constReturnDate; // Set the departure date to what it was initially
          throw new Error('Bad dates!');
        }
        Object.assign(this.elementData, miniHashName); // Assign each property to the elementData object at the end of the loop
      });

      this.pageLoaded = false;
      if (this.openedBooking.freshElement === 'yes') {
        this.elementService.createElement(this.elementData).then((output: any) => {
          if (output.status !== 'OK') {
            this.sendMessageToDialog('', 'Failed to create Flight element (' + output.status + ')', '', '');
          } else {
            this.updateBookingData().then(res => {
              Session.mySession.resetTimersOnBookingValues().then(() => {
                this.router.navigate(['/booking/' + this.openedBooking.bookingReference + '/1']);
              });
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0901S)', error, this.elementData);
        });
      } else if (this.openedBooking.freshElement === 'no') {
        this.elementService.updateElement(this.elementData).then((output: any) => {
          if (output.status !== 'OK') {
            this.sendMessageToDialog('', 'Failed to create Flight element (' + output.status + ')', '', '');
          } else {
            this.updateBookingData().then(res => {
              Session.mySession.resetTimersOnBookingValues().then(() => {
                this.router.navigate(['/booking/' + this.openedBooking.bookingReference + '/1']);
              });
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0909S)', error, this.elementData);
        });
      }
    } else {
      this.sendMessageToDialog('', 'Please fill in all of the required fields', '', '');
    }
  }

  createHotel(form: NgForm): void {
    if (form.valid) {
      this.createCoreElementData(form, 'accom'); // Create core which contains most basic element info
      this.elementData.coreElementData.hotelName = form.value.accomName0; // Main element's accommodation will be the first one
      this.elementDivs.forEach((index: any) => {
        const miniHashName = JSON.parse('{"elmentDataMiniHash' + index + '" : {}}'); // Very clever way of adding a suffix to property name
        miniHashName[Object.keys(miniHashName)[0]].subElementType = 'accom'; // It is an accommodation isn't it?

        // This is not the first element for for this supplier reference. We need to parse action for the update API call here..
        if (this.openedBooking.freshElement === 'no') {
          miniHashName[Object.keys(miniHashName)[0]].action = 'create';
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.openedBooking.supplierReference;
        } else {
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.supplierReference;
        }

        miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
        miniHashName[Object.keys(miniHashName)[0]].supplier = this.supplier.supplierNameM; // Supplier name taken from HTML input (it should be readOnly!)
        miniHashName[Object.keys(miniHashName)[0]].country = form.value['country' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].city = form.value['city' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].numNights = form.value['nights' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].accomName = form.value['accomName' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].rating = form.value['rating' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].address = form.value['address' + index as keyof typeof form.value]; // Usual stuff..

        miniHashName[Object.keys(miniHashName)[0]].numRooms = this.addnDataDiv[index].length; // Usual stuff..

        // Multiple room stuff below (addndata table)
        const addnData: any = []; // Create addnData array object within the mini hash first..
        this.addnDataDiv[index].forEach((indexRoom: any) => {
          const addnDataObject: any = {}; // Create addnData object within the mini hash first..
          addnDataObject.company = this.openedBooking.company; // Usual stuff..
          addnDataObject.operation = this.openedBooking.operation; // Usual stuff..
          addnDataObject.tradeCode = this.openedBooking.tradeCode; // Usual stuff..
          addnDataObject.rowType = 'accomRoom'; // Describe addnData object
          addnDataObject.subElementType = 'accom'; // Describe addnData object

          addnDataObject.labelStr1 = 'roomType'; // That's a name of the property below..
          addnDataObject.valueStr1 = form.value['roomType' + index + 'R' + indexRoom as keyof typeof form.value]; // Get above's property value here..
          addnDataObject.labelStr2 = 'roomView'; // That's a name of the property below..
          addnDataObject.valueStr2 = form.value['roomView' + index + 'R' + indexRoom as keyof typeof form.value]; // Get above's property value here..
          addnDataObject.labelStr3 = 'boardBasis'; // That's a name of the property below..
          addnDataObject.valueStr3 = form.value['boardBasis' + index + 'R' + indexRoom as keyof typeof form.value]; // Get above's property value here..
          addnData.push(addnDataObject); // Push addnData object itno the array at the end
        });
        miniHashName[Object.keys(miniHashName)[0]].addnData = addnData; // Assign addnData variable to miniHash object at the end

        // 27.01.2022 new stuff here - removed HUGE calculations. Replaced with a nice and thin function to do the job..
        miniHashName[Object.keys(miniHashName)[0]].checkInDate = this.calculateDeptArrDates('departure', form.value['checkinDate' + index as keyof typeof form.value]);

        const checkInDate = new Date(miniHashName[Object.keys(miniHashName)[0]].checkInDate); // Get the date when checks in to hotel
        checkInDate.setDate(checkInDate.getDate() + Number(miniHashName[Object.keys(miniHashName)[0]].numNights)); // Add number of nights to the date
        const lastDate = this.constants.convertDateNotMoment(checkInDate); // Convert the date here
        this.calculateDeptArrDates('return', lastDate); // Insert converted date to the funciton from 27.01.2022

        miniHashName[Object.keys(miniHashName)[0]].description = this.costingBreakdown[index].description; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].grossCost = this.costingBreakdown[index].realGross; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].netCost = this.costingBreakdown[index].netPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].tax = this.costingBreakdown[index].vatPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].discount = this.costingBreakdown[index].discountPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].commission = this.costingBreakdown[index].commissionPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].depositAmount = this.costingBreakdown[index].depositPrice; // Usuall stuff..

        // Check if any of the car parkings's end date is before start date
        if (Number(miniHashName[Object.keys(miniHashName)[0]].numNights) < 0) {
          this.sendMessageToDialog('', 'Accommodation number ' + (index + 1).toString() + ': the number of nights cannot be negative', '', '');
          this.element.deptDate = this.constDeptDate; // Set the departure date to what it was initially
          this.element.returnDate = this.constReturnDate; // Set the departure date to what it was initially
          throw new Error('Bad dates!');
        }
        Object.assign(this.elementData, miniHashName); // Assign each property to the elementData object at the end of the loop
      });

      this.pageLoaded = false;
      if (this.openedBooking.freshElement === 'yes') {
        this.elementService.createElement(this.elementData).then((output: any) => {
          if (output.status !== 'OK') {
            this.sendMessageToDialog('', 'Failed to create Accommodation element (' + output.status + ')', '', '');
          } else {
            this.updateBookingData().then(res => {
              Session.mySession.resetTimersOnBookingValues().then(() => {
                this.router.navigate(['/booking/' + this.openedBooking.bookingReference + '/1']);
              });
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0902S)', error, this.elementData);
        });
      } else if (this.openedBooking.freshElement === 'no') {
        this.elementService.updateElement(this.elementData).then((output: any) => {
          if (output.status !== 'OK') {
            this.sendMessageToDialog('', 'Failed to create Accommodation element (' + output.status + ')', '', '');
          } else {
            this.updateBookingData().then(res => {
              Session.mySession.resetTimersOnBookingValues().then(() => {
                this.router.navigate(['/booking/' + this.openedBooking.bookingReference + '/1']);
              });
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0910S)', error, this.elementData);
        });
      }
    } else {
      this.sendMessageToDialog('', 'Please fill in all of the required fields', '', '');
    }
  }

  createCarPark(form: NgForm): void {
    if (form.valid) {
      this.createCoreElementData(form, 'carpark'); // Create core which contains most basic element info
      this.elementDivs.forEach((index: any) => {
        const miniHashName = JSON.parse('{"elmentDataMiniHash' + index + '" : {}}'); // Very clever way of adding a suffix to property name
        miniHashName[Object.keys(miniHashName)[0]].subElementType = 'carpark'; // It is a car park yes?

        // This is not the first element for for this supplier reference. We need to parse action for the update API call here..
        if (this.openedBooking.freshElement === 'no') {
          miniHashName[Object.keys(miniHashName)[0]].action = 'create';
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.openedBooking.supplierReference;
        } else {
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.supplierReference;
        }

        miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
        miniHashName[Object.keys(miniHashName)[0]].supplier = this.supplier.supplierNameM; // Supplier name taken from HTML input (it should be readOnly!)

        // miniHashName[Object.keys(miniHashName)[0]].durationDays = form.value["duration" + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].location = form.value['location' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].carParkName = form.value['carParkName' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].carParkCode = form.value['carParkCode' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].carMake = form.value['carMake' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].carModel = form.value['carModel' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].carReg = form.value['carReg' + index as keyof typeof form.value]; // Usual stuff..

        // 27.01.2022 new stuff here - removed HUGE calculations. Replaced with a nice and thin function to do the job..
        const startDateNoTime = this.calculateDeptArrDates('departure', form.value['startDate' + index as keyof typeof form.value]);
        const endDateNoTime = this.calculateDeptArrDates('return', form.value['endDate' + index as keyof typeof form.value]);

        // 13.04.2023 - validate Hrs and Mins
        const departHrs = this.constants.validateTime(form.value['startDateHrs' + index as keyof typeof form.value], 'hrs');
        const departMin = this.constants.validateTime(form.value['startDateMin' + index as keyof typeof form.value], 'min');
        const arriveHrs = this.constants.validateTime(form.value['endDateHrs' + index as keyof typeof form.value], 'hrs');
        const arriveMin = this.constants.validateTime(form.value['endDateMin' + index as keyof typeof form.value], 'min');

        // Calculate date + time here and assign it to variables
        miniHashName[Object.keys(miniHashName)[0]].startDate = startDateNoTime.toString() +
          ' ' + this.constants.zeroBeforeNo(departHrs) + ':' + this.constants.zeroBeforeNo(departMin) + ':00';
        miniHashName[Object.keys(miniHashName)[0]].endDate = endDateNoTime.toString() +
          ' ' + this.constants.zeroBeforeNo(arriveHrs) + ':' + this.constants.zeroBeforeNo(arriveMin) + ':00';

        miniHashName[Object.keys(miniHashName)[0]].description = this.costingBreakdown[index].description; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].grossCost = this.costingBreakdown[index].realGross; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].netCost = this.costingBreakdown[index].netPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].tax = this.costingBreakdown[index].vatPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].discount = this.costingBreakdown[index].discountPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].commission = this.costingBreakdown[index].commissionPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].depositAmount = this.costingBreakdown[index].depositPrice; // Usuall stuff..

        // Check if any of the car parkings's end date is before start date
        if (new Date(miniHashName[Object.keys(miniHashName)[0]].endDate) < new Date(miniHashName[Object.keys(miniHashName)[0]].startDate)) {
          this.sendMessageToDialog('', 'Car parking number ' + (index + 1).toString() + ': the start date must be set before the end date', '', '');
          this.element.deptDate = this.constDeptDate; // Set the departure date to what it was initially
          this.element.returnDate = this.constReturnDate; // Set the departure date to what it was initially
          throw new Error('Bad dates!');
        }
        Object.assign(this.elementData, miniHashName); // Assign each property to the elementData object at the end of the loop
      });

      this.pageLoaded = false;
      if (this.openedBooking.freshElement === 'yes') {
        this.elementService.createElement(this.elementData).then((output: any) => {
          if (output.status !== 'OK') {
            this.sendMessageToDialog('', 'Failed to create Car Park element (' + output.status + ')', '', '');
          } else {
            this.updateBookingData().then(res => {
              Session.mySession.resetTimersOnBookingValues().then(() => {
                this.router.navigate(['/booking/' + this.openedBooking.bookingReference + '/1']);
              });
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0903S)', error, this.elementData);
        });
      } else if (this.openedBooking.freshElement === 'no') {
        this.elementService.updateElement(this.elementData).then((output: any) => {
          if (output.status !== 'OK') {
            this.sendMessageToDialog('', 'Failed to create Car Park element (' + output.status + ')', '', '');
          } else {
            this.updateBookingData().then(res => {
              Session.mySession.resetTimersOnBookingValues().then(() => {
                this.router.navigate(['/booking/' + this.openedBooking.bookingReference + '/1']);
              });
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0911S)', error, this.elementData);
        });
      }
    } else {
      this.sendMessageToDialog('', 'Please fill in all of the required fields', '', '');
    }
  }

  createCarHire(form: NgForm): void {
    if (form.valid) {
      this.createCoreElementData(form, 'carhire'); // Create core which contains most basic element info
      this.elementDivs.forEach((index: any) => {
        const miniHashName = JSON.parse('{"elmentDataMiniHash' + index + '" : {}}'); // Very clever way of adding a suffix to property name
        miniHashName[Object.keys(miniHashName)[0]].subElementType = 'carhire'; // It is a Car Hire no?

        // This is not the first element for for this supplier reference. We need to parse action for the update API call here..
        if (this.openedBooking.freshElement === 'no') {
          miniHashName[Object.keys(miniHashName)[0]].action = 'create';
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.openedBooking.supplierReference;
        } else {
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.supplierReference;
        }

        miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
        miniHashName[Object.keys(miniHashName)[0]].supplier = this.supplier.supplierNameM; // Supplier name taken from HTML input (it should be readOnly!)

        // 27.01.2022 new stuff here - removed HUGE calculations. Replaced with a nice and thin function to do the job..
        miniHashName[Object.keys(miniHashName)[0]].pickUpDate = this.calculateDeptArrDates('departure', form.value['pickUpDate' + index as keyof typeof form.value]);
        miniHashName[Object.keys(miniHashName)[0]].dropOffDate = this.calculateDeptArrDates('return', form.value['dropOffDate' + index as keyof typeof form.value]);

        miniHashName[Object.keys(miniHashName)[0]].pickUpLocation = form.value['pickUpLocation' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].dropOffLocation = form.value['dropOffLocation' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].carType = form.value['carType' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].driver = form.value['driver' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].driverAddn = form.value['driver' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].NumNoInfants = form.value['paxNo' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].numInfants = form.value['paxNoInfants' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].childseats = form.value['childSeats' + index as keyof typeof form.value]; // Usual stuff..

        miniHashName[Object.keys(miniHashName)[0]].description = this.costingBreakdown[index].description; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].grossCost = this.costingBreakdown[index].realGross; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].netCost = this.costingBreakdown[index].netPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].tax = this.costingBreakdown[index].vatPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].discount = this.costingBreakdown[index].discountPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].commission = this.costingBreakdown[index].commissionPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].depositAmount = this.costingBreakdown[index].depositPrice; // Usuall stuff..

        // Check if any of the car hires' drop-off date is before pick-up date..
        if (new Date(miniHashName[Object.keys(miniHashName)[0]].dropOffDate) < new Date(miniHashName[Object.keys(miniHashName)[0]].pickUpDate)) {
          this.sendMessageToDialog('', 'Car hire number ' + (index + 1).toString() + ': the pick up date must be set before the drop off date', '', '');
          this.element.deptDate = this.constDeptDate; // Set the departure date to what it was initially
          this.element.returnDate = this.constReturnDate; // Set the departure date to what it was initially
          throw new Error('Bad dates!');
        }
        Object.assign(this.elementData, miniHashName); // Assign each property to the elementData object at the end of the loop
      });

      this.pageLoaded = false;
      if (this.openedBooking.freshElement === 'yes') {
        this.elementService.createElement(this.elementData).then((output: any) => {
          if (output.status !== 'OK') {
            this.sendMessageToDialog('', 'Failed to create Car Hire element (' + output.status + ')', '', '');
          } else {
            this.updateBookingData().then(res => {
              Session.mySession.resetTimersOnBookingValues().then(() => {
                this.router.navigate(['/booking/' + this.openedBooking.bookingReference + '/1']);
              });
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0904S)', error, this.elementData);
        });
      } else if (this.openedBooking.freshElement === 'no') {
        this.elementService.updateElement(this.elementData).then((output: any) => {
          if (output.status !== 'OK') {
            this.sendMessageToDialog('', 'Failed to create Car Hire element (' + output.status + ')', '', '');
          } else {
            this.updateBookingData().then(res => {
              Session.mySession.resetTimersOnBookingValues().then(() => {
                this.router.navigate(['/booking/' + this.openedBooking.bookingReference + '/1']);
              });
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0912S)', error, this.elementData);
        });
      }
    } else {
      this.sendMessageToDialog('', 'Please fill in all of the required fields', '', '');
    }
  }

  createAttraction(form: NgForm): void {
    if (form.valid) {
      this.createCoreElementData(form, 'attraction'); // Create core which contains most basic element info
      this.elementDivs.forEach((index: any) => {
        const miniHashName = JSON.parse('{"elmentDataMiniHash' + index + '" : {}}'); // Very clever way of adding a suffix to property name
        miniHashName[Object.keys(miniHashName)[0]].subElementType = 'attraction'; // It's the attraction element..

        // This is not the first element for for this supplier reference. We need to parse action for the update API call here..
        if (this.openedBooking.freshElement === 'no') {
          miniHashName[Object.keys(miniHashName)[0]].action = 'create';
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.openedBooking.supplierReference;
        } else {
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.supplierReference;
        }

        miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
        miniHashName[Object.keys(miniHashName)[0]].supplier = this.supplier.supplierNameM; // Supplier name taken from HTML input (it should be readOnly!)
        miniHashName[Object.keys(miniHashName)[0]].country = form.value['country' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].location = form.value['location' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].name = form.value['name' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].adults = form.value['paxNo' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].children = form.value['paxNoInfants' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].infants = form.value['paxInfants' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].duration = form.value['duration' + index as keyof typeof form.value]; // Usual stuff

        // 27.01.2022 new stuff here - removed HUGE calculations. Replaced with a nice and thin function to do the job..
        const startDateNoTime = this.calculateDeptArrDates('departure', form.value['startDateTime' + index as keyof typeof form.value]);
        const endDateNoTime = this.calculateDeptArrDates('return', form.value['endDateTime' + index as keyof typeof form.value]);

        // 13.04.2023 - validate Hrs and Mins
        const departHrs = this.constants.validateTime(form.value['startDateTimeHrs' + index as keyof typeof form.value], 'hrs');
        const departMin = this.constants.validateTime(form.value['startDateTimeMin' + index as keyof typeof form.value], 'min');
        const arriveHrs = this.constants.validateTime(form.value['endDateTimeHrs' + index as keyof typeof form.value], 'hrs');
        const arriveMin = this.constants.validateTime(form.value['endDateTimeMin' + index as keyof typeof form.value], 'min');

        // Calculate date + time here and assign it to variables
        miniHashName[Object.keys(miniHashName)[0]].startDateTime = startDateNoTime.toString() +
          ' ' + this.constants.zeroBeforeNo(departHrs) + ':' + this.constants.zeroBeforeNo(departMin) + ':00';
        miniHashName[Object.keys(miniHashName)[0]].endDateTime = endDateNoTime.toString() +
          ' ' + this.constants.zeroBeforeNo(arriveHrs) + ':' + this.constants.zeroBeforeNo(arriveMin) + ':00';

        miniHashName[Object.keys(miniHashName)[0]].description = this.costingBreakdown[index].description; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].grossCost = this.costingBreakdown[index].realGross; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].netCost = this.costingBreakdown[index].netPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].tax = this.costingBreakdown[index].vatPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].discount = this.costingBreakdown[index].discountPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].commission = this.costingBreakdown[index].commissionPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].depositAmount = this.costingBreakdown[index].depositPrice; // Usuall stuff..

        // Check if any of the attraction end date is before start date
        if (new Date(miniHashName[Object.keys(miniHashName)[0]].endDateTime) < new Date(miniHashName[Object.keys(miniHashName)[0]].startDateTime)) {
          this.sendMessageToDialog('', 'Attraction number ' + (index + 1).toString() + ': the start date must be set before the end date', '', '');
          this.element.deptDate = this.constDeptDate; // Set the departure date to what it was initially
          this.element.returnDate = this.constReturnDate; // Set the departure date to what it was initially
          throw new Error('Bad dates!');
        }
        Object.assign(this.elementData, miniHashName); // Assign each property to the elementData object at the end of the loop
      });

      this.pageLoaded = false;
      if (this.openedBooking.freshElement === 'yes') {
        this.elementService.createElement(this.elementData).then((output: any) => {
          if (output.status !== 'OK') {
            this.sendMessageToDialog('', 'Failed to create Attraction element (' + output.status + ')', '', '');
          } else {
            this.updateBookingData().then(res => {
              Session.mySession.resetTimersOnBookingValues().then(() => {
                this.router.navigate(['/booking/' + this.openedBooking.bookingReference + '/1']);
              });
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0905S)', error, this.elementData);
        });
      } else if (this.openedBooking.freshElement === 'no') {
        this.elementService.updateElement(this.elementData).then((output: any) => {
          if (output.status !== 'OK') {
            this.sendMessageToDialog('', 'Failed to create Attraction element (' + output.status + ')', '', '');
          } else {
            this.updateBookingData().then(res => {
              Session.mySession.resetTimersOnBookingValues().then(() => {
                this.router.navigate(['/booking/' + this.openedBooking.bookingReference + '/1']);
              });
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0913S)', error, this.elementData);
        });
      }
    } else {
      this.sendMessageToDialog('', 'Please fill in all of the required fields', '', '');
    }
  }

  createCruise(form: NgForm): void {
    if (form.valid) {
      this.createCoreElementData(form, 'cruise'); // Create core which contains most basic element info
      this.elementDivs.forEach((index: any) => {
        const miniHashName = JSON.parse('{"elmentDataMiniHash' + index + '" : {}}'); // Very clever way of adding a suffix to property name
        miniHashName[Object.keys(miniHashName)[0]].subElementType = 'cruise'; // It's Crusie element

        // This is not the first element for for this supplier reference. We need to parse action for the update API call here..
        if (this.openedBooking.freshElement === 'no') {
          miniHashName[Object.keys(miniHashName)[0]].action = 'create';
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.openedBooking.supplierReference;
        } else {
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.supplierReference;
        }

        miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
        miniHashName[Object.keys(miniHashName)[0]].supplier = this.supplier.supplierNameM; // Supplier name taken from HTML input (it should be readOnly!)
        miniHashName[Object.keys(miniHashName)[0]].adults = form.value['paxNo' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].children = form.value['paxNoInfants' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].infants = form.value['paxInfants' + index as keyof typeof form.value]; // Usual stuff..

        // Calculate total numberf of passengers - Done only here I think..
        miniHashName[Object.keys(miniHashName)[0]].passengers = (miniHashName[Object.keys(miniHashName)[0]].adults +
          miniHashName[Object.keys(miniHashName)[0]].children + miniHashName[Object.keys(miniHashName)[0]].infants).toString();

        // 27.01.2022 new stuff here - removed HUGE calculations. Replaced with a nice and thin function to do the job..
        miniHashName[Object.keys(miniHashName)[0]].deptDate = this.calculateDeptArrDates('departure', form.value['departureDate' + index as keyof typeof form.value]);
        miniHashName[Object.keys(miniHashName)[0]].returnDate = this.calculateDeptArrDates('return', form.value['returnDate' + index as keyof typeof form.value]);

        // Calculate the duration here.. I think this is done only once..
        const differenceDate = new Date(miniHashName[Object.keys(miniHashName)[0]].returnDate).getTime() - new Date(miniHashName[Object.keys(miniHashName)[0]].deptDate).getTime();
        const diffInTime = differenceDate / (1000 * 3600 * 24); miniHashName[Object.keys(miniHashName)[0]].duration = diffInTime.toString();

        // Cruise stuff below..
        miniHashName[Object.keys(miniHashName)[0]].cruiseLine = form.value['cruiseLine' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].cruiseShip = form.value['cruiseShip' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].cruiseName = form.value['cruiseName' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].region = form.value['region' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].departurePort = form.value['departurePort' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].visitingPort = form.value['visitingPort' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].disembarkPort = form.value['disembarkPort' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].voyageCode = form.value['voyageCode' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].onBoardCreditVal = form.value['onBoardCreditVal' + index as keyof typeof form.value]; // Usual stuff..
        miniHashName[Object.keys(miniHashName)[0]].onBoardCreditCur = form.value['onBoardCreditCur' + index as keyof typeof form.value]; // Usual stuff..

        // Cabin stuff below (addndata table)
        const addnData: any = []; // Create addnData array object within the mini hash first..
        this.addnDataDiv[index].forEach((indexCabin: any) => {
          const addnDataObject: any = {}; // Create addnData object within the mini hash first..
          addnDataObject.company = this.openedBooking.company; // Usual stuff..
          addnDataObject.operation = this.openedBooking.operation; // Usual stuff..
          addnDataObject.tradeCode = this.openedBooking.tradeCode; // Usual stuff..
          addnDataObject.rowType = 'cruiseCabin'; // Describe addnData object
          addnDataObject.subElementType = 'cruise'; // Describe addnData object

          addnDataObject.labelStr1 = 'cabinName'; // That's a name of the property below..
          addnDataObject.valueStr1 = form.value['cabinName' + index + 'C' + indexCabin as keyof typeof form.value]; // Get above's property value here..
          addnDataObject.labelStr2 = 'cabinGrade'; // That's a name of the property below..
          addnDataObject.valueStr2 = form.value['cabinGrade' + index + 'C' + indexCabin as keyof typeof form.value]; // Get above's property value here..
          addnDataObject.labelStr3 = 'cabinNumber'; // That's a name of the property below..
          addnDataObject.valueStr3 = form.value['cabinNumber' + index + 'C' + indexCabin as keyof typeof form.value]; // Get above's property value here..
          addnDataObject.labelStr4 = 'cabinDeck'; // That's a name of the property below..
          addnDataObject.valueStr4 = form.value['cabinDeck' + index + 'C' + indexCabin as keyof typeof form.value]; // Get above's property value here..
          addnDataObject.labelStr5 = 'shipSide'; // That's a name of the property below..
          addnDataObject.valueStr5 = form.value['shipSide' + index + 'C' + indexCabin as keyof typeof form.value]; // Get above's property value here..
          addnDataObject.labelStr6 = 'cabinPosition'; // That's a name of the property below..
          addnDataObject.valueStr6 = form.value['cabinPosition' + index + 'C' + indexCabin as keyof typeof form.value]; // Get above's property value here..
          addnDataObject.labelStr7 = 'bedType'; // That's a name of the property below..
          addnDataObject.valueStr7 = form.value['bedType' + index + 'C' + indexCabin as keyof typeof form.value]; // Get above's property value here..
          addnDataObject.labelStr8 = 'seating'; // That's a name of the property below..
          addnDataObject.valueStr8 = form.value['seating' + index + 'C' + indexCabin as keyof typeof form.value]; // Get above's property value here..
          addnDataObject.labelStr9 = 'tableSize'; // That's a name of the property below..
          addnDataObject.valueStr9 = form.value['tableSize' + index + 'C' + indexCabin as keyof typeof form.value]; // Get above's property value here..
          addnData.push(addnDataObject); // Push addnData object itno the array at the end
        });
        miniHashName[Object.keys(miniHashName)[0]].addnData = addnData; // Assign addnData variable to miniHash object at the end

        miniHashName[Object.keys(miniHashName)[0]].description = this.costingBreakdown[index].description; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].grossCost = this.costingBreakdown[index].realGross; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].netCost = this.costingBreakdown[index].netPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].tax = this.costingBreakdown[index].vatPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].discount = this.costingBreakdown[index].discountPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].commission = this.costingBreakdown[index].commissionPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].depositAmount = this.costingBreakdown[index].depositPrice; // Usuall stuff..

        // Return date cannot be before dept date in any of the cruise
        if (new Date(miniHashName[Object.keys(miniHashName)[0]].returnDate) <= new Date(miniHashName[Object.keys(miniHashName)[0]].deptDate)) {
          this.sendMessageToDialog('', 'Cruise number ' + (index + 1).toString() + ': the departure date must be set before the return date', '', '');
          this.element.deptDate = this.constDeptDate; // Set the departure date to what it was initially
          this.element.returnDate = this.constReturnDate; // Set the departure date to what it was initially
          throw new Error('Bad dates!');
        }
        Object.assign(this.elementData, miniHashName); // Assign each property to the elementData object at the end of the loop
      });

      this.pageLoaded = false;
      if (this.openedBooking.freshElement === 'yes') {
        this.elementService.createElement(this.elementData).then((output: any) => {
          if (output.status !== 'OK') {
            this.sendMessageToDialog('', 'Failed to create Cruise element (' + output.status + ')', '', '');
          } else {
            this.updateBookingData().then(res => {
              Session.mySession.resetTimersOnBookingValues().then(() => {
                this.router.navigate(['/booking/' + this.openedBooking.bookingReference + '/1']);
              });
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0906S)', error, this.elementData);
        });
      } else if (this.openedBooking.freshElement === 'no') {
        this.elementService.updateElement(this.elementData).then((output: any) => {
          if (output.status !== 'OK') {
            this.sendMessageToDialog('', 'Failed to create Cruise element (' + output.status + ')', '', '');
          } else {
            this.updateBookingData().then(res => {
              Session.mySession.resetTimersOnBookingValues().then(() => {
                this.router.navigate(['/booking/' + this.openedBooking.bookingReference + '/1']);
              });
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0914S)', error, this.elementData);
        });
      }
    } else {
      this.sendMessageToDialog('', 'Please fill in all of the required fields', '', '');
    }
  }

  createMiscellaneous(form: NgForm): void {
    if (form.valid) {
      this.createCoreElementData(form, 'misc'); // Create core which contains most basic element info
      this.elementDivs.forEach((index: any) => {
        const miniHashName = JSON.parse('{"elmentDataMiniHash' + index + '" : {}}'); // Very clever way of adding a suffix to property name
        miniHashName[Object.keys(miniHashName)[0]].subElementType = 'misc'; // It's the attraction element..

        // This is not the first element for for this supplier reference. We need to parse action for the update API call here..
        if (this.openedBooking.freshElement === 'no') {
          miniHashName[Object.keys(miniHashName)[0]].action = 'create';
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.openedBooking.supplierReference;
        } else {
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.supplierReference;
        }

        miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
        miniHashName[Object.keys(miniHashName)[0]].supplier = this.supplier.supplierNameM; // Supplier name taken from HTML input (it should be readOnly!)
        miniHashName[Object.keys(miniHashName)[0]].country = form.value['country' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].location = form.value['location' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].name = form.value['name' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].adults = form.value['paxNo' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].children = form.value['paxNoInfants' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].infants = form.value['paxInfants' + index as keyof typeof form.value]; // Usual stuff
        miniHashName[Object.keys(miniHashName)[0]].duration = form.value['duration' + index as keyof typeof form.value]; // Usual stuff

        // 27.01.2022 new stuff here - removed HUGE calculations. Replaced with a nice and thin function to do the job..
        const startDateNoTime = this.calculateDeptArrDates('departure', form.value['startDateTime' + index as keyof typeof form.value]);
        const endDateNoTime = this.calculateDeptArrDates('return', form.value['endDateTime' + index as keyof typeof form.value]);

        // 13.04.2023 - validate Hrs and Mins
        const departHrs = this.constants.validateTime(form.value['startDateTimeHrs' + index as keyof typeof form.value], 'hrs');
        const departMin = this.constants.validateTime(form.value['startDateTimeMin' + index as keyof typeof form.value], 'min');
        const arriveHrs = this.constants.validateTime(form.value['endDateTimeHrs' + index as keyof typeof form.value], 'hrs');
        const arriveMin = this.constants.validateTime(form.value['endDateTimeMin' + index as keyof typeof form.value], 'min');

        // Calculate date + time here and assign it to variables
        miniHashName[Object.keys(miniHashName)[0]].startDateTime = startDateNoTime.toString() +
          ' ' + this.constants.zeroBeforeNo(departHrs) + ':' + this.constants.zeroBeforeNo(departMin) + ':00';
        miniHashName[Object.keys(miniHashName)[0]].endDateTime = endDateNoTime.toString() +
          ' ' + this.constants.zeroBeforeNo(arriveHrs) + ':' + this.constants.zeroBeforeNo(arriveMin) + ':00';

        miniHashName[Object.keys(miniHashName)[0]].description = this.costingBreakdown[index].description; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].grossCost = this.costingBreakdown[index].realGross; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].netCost = this.costingBreakdown[index].netPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].tax = this.costingBreakdown[index].vatPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].discount = this.costingBreakdown[index].discountPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].commission = this.costingBreakdown[index].commissionPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].depositAmount = this.costingBreakdown[index].depositPrice; // Usuall stuff..

        // Check if any of the attraction end date is before start date
        if (new Date(miniHashName[Object.keys(miniHashName)[0]].endDateTime) < new Date(miniHashName[Object.keys(miniHashName)[0]].startDateTime)) {
          this.sendMessageToDialog('', 'Miscellaneous number ' + (index + 1).toString() + ': the start date must be set before the end date', '', '');
          this.element.deptDate = this.constDeptDate; // Set the departure date to what it was initially
          this.element.returnDate = this.constReturnDate; // Set the departure date to what it was initially
          throw new Error('Bad dates!');
        }
        Object.assign(this.elementData, miniHashName); // Assign each property to the elementData object at the end of the loop
      });

      this.pageLoaded = false;
      if (this.openedBooking.freshElement === 'yes') {
        this.elementService.createElement(this.elementData).then((output: any) => {
          if (output.status !== 'OK') {
            this.sendMessageToDialog('', 'Failed to create Miscellaneous element (' + output.status + ')', '', '');
          } else {
            this.updateBookingData().then(res => {
              Session.mySession.resetTimersOnBookingValues().then(() => {
                this.router.navigate(['/booking/' + this.openedBooking.bookingReference + '/1']);
              });
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0907S)', error, this.elementData);
        });
      } else if (this.openedBooking.freshElement === 'no') {
        this.elementService.updateElement(this.elementData).then((output: any) => {
          if (output.status !== 'OK') {
            this.sendMessageToDialog('', 'Failed to create Miscellaneous element (' + output.status + ')', '', '');
          } else {
            this.updateBookingData().then(res => {
              Session.mySession.resetTimersOnBookingValues().then(() => {
                this.router.navigate(['/booking/' + this.openedBooking.bookingReference + '/1']);
              });
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0915S)', error, this.elementData);
        });
      }
    } else {
      this.sendMessageToDialog('', 'Please fill in all of the required fields', '', '');
    }
  }

  createTrain(form: NgForm): void {
    if (form.valid) {
      this.createCoreElementData(form, 'train'); // Create core which contains most basic element info
      this.elementDivs.forEach((index: any) => {
        const miniHashName = JSON.parse('{"elmentDataMiniHash' + index + '" : {}}'); // Very clever way of adding a suffix to property name
        miniHashName[Object.keys(miniHashName)[0]].subElementType = 'train'; // It is train element

        // This is not the first element for for this supplier reference. We need to parse action for the update API call here..
        if (this.openedBooking.freshElement === 'no') {
          miniHashName[Object.keys(miniHashName)[0]].action = 'create';
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.openedBooking.supplierReference;
        } else {
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.supplierReference;
        }

        miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
        miniHashName[Object.keys(miniHashName)[0]].supplier = this.supplier.supplierNameM; // Supplier name taken from HTML input (it should be readOnly!)

        // 27.01.2022 new stuff here - removed HUGE calculations. Replaced with a nice and thin function to do the job..
        const departDate = this.calculateDeptArrDates('departure', form.value['departDateTime' + index as keyof typeof form.value]);
        const arriveDate = this.calculateDeptArrDates('return', form.value['arriveDateTime' + index as keyof typeof form.value]);

        // 13.04.2023 - validate Hrs and Mins
        const departHrs = this.constants.validateTime(form.value['departHrs' + index as keyof typeof form.value], 'hrs');
        const departMin = this.constants.validateTime(form.value['departMin' + index as keyof typeof form.value], 'min');
        const arriveHrs = this.constants.validateTime(form.value['arriveHrs' + index as keyof typeof form.value], 'hrs');
        const arriveMin = this.constants.validateTime(form.value['arriveMin' + index as keyof typeof form.value], 'min');

        // Calculate date + time here and assign it to variables
        miniHashName[Object.keys(miniHashName)[0]].departDateTime = departDate.toString() +
          ' ' + this.constants.zeroBeforeNo(departHrs) + ':' + this.constants.zeroBeforeNo(departMin) + ':00';
        miniHashName[Object.keys(miniHashName)[0]].arriveDateTime = arriveDate.toString() +
          ' ' + this.constants.zeroBeforeNo(arriveHrs) + ':' + this.constants.zeroBeforeNo(arriveMin) + ':00';

        miniHashName[Object.keys(miniHashName)[0]].departStation = form.value['departStation' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].arriveStation = form.value['arriveStation' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].trainNameNo = form.value['trainNameNo' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].carriageClass = form.value['carriageClass' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].seat = form.value['seat' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].adults = form.value['paxNo' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].children = form.value['paxNoInfants' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].infants = form.value['paxInfants' + index as keyof typeof form.value]; // Usual stuff here

        miniHashName[Object.keys(miniHashName)[0]].description = this.costingBreakdown[index].description; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].grossCost = this.costingBreakdown[index].realGross; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].netCost = this.costingBreakdown[index].netPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].tax = this.costingBreakdown[index].vatPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].discount = this.costingBreakdown[index].discountPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].commission = this.costingBreakdown[index].commissionPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].depositAmount = this.costingBreakdown[index].depositPrice; // Usuall stuff..

        // Make sure each element's return date is after pick up date here
        if (new Date(miniHashName[Object.keys(miniHashName)[0]].arriveDateTime) < new Date(miniHashName[Object.keys(miniHashName)[0]].departDateTime)) {
          this.sendMessageToDialog('', 'Train number ' + (index + 1).toString() + ': the departrue date must be set before the arrival date', '', '');
          this.element.deptDate = this.constDeptDate; // Set the departure date to what it was initially
          this.element.returnDate = this.constReturnDate; // Set the departure date to what it was initially
          throw new Error('Bad dates!');
        }
        Object.assign(this.elementData, miniHashName); // Assign each property to the elementData object at the end of the loop
      });

      this.pageLoaded = false;
      if (this.openedBooking.freshElement === 'yes') {
        this.elementService.createElement(this.elementData).then((output: any) => {
          if (output.status !== 'OK') {
            this.sendMessageToDialog('', 'Failed to create Train element (' + output.status + ')', '', '');
          } else {
            this.updateBookingData().then(res => {
              Session.mySession.resetTimersOnBookingValues().then(() => {
                this.router.navigate(['/booking/' + this.openedBooking.bookingReference + '/1']);
              });
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0918S)', error, this.elementData);
        });
      } else if (this.openedBooking.freshElement === 'no') {
        this.elementService.updateElement(this.elementData).then((output: any) => {
          if (output.status !== 'OK') {
            this.sendMessageToDialog('', 'Failed to create Train element (' + output.status + ')', '', '');
          } else {
            this.updateBookingData().then(res => {
              Session.mySession.resetTimersOnBookingValues().then(() => {
                this.router.navigate(['/booking/' + this.openedBooking.bookingReference + '/1']);
              });
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0919S)', error, this.elementData);
        });
      }
    } else {
      this.sendMessageToDialog('', 'Please fill in all of the required fields', '', '');
    }
  }

  createTransfer(form: NgForm): void {
    if (form.valid) {
      this.createCoreElementData(form, 'transfer'); // Create core which contains most basic element info
      this.elementDivs.forEach((index: any) => {
        const miniHashName = JSON.parse('{"elmentDataMiniHash' + index + '" : {}}'); // Very clever way of adding a suffix to property name
        miniHashName[Object.keys(miniHashName)[0]].subElementType = 'transfer'; // It is transfer element

        // This is not the first element for for this supplier reference. We need to parse action for the update API call here..
        if (this.openedBooking.freshElement === 'no') {
          miniHashName[Object.keys(miniHashName)[0]].action = 'create';
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.openedBooking.supplierReference;
        } else {
          miniHashName[Object.keys(miniHashName)[0]].supplierReference = this.supplierReference;
        }

        miniHashName[Object.keys(miniHashName)[0]].subElementStatus = 'enquiry'; // It's status is enquiry by default
        miniHashName[Object.keys(miniHashName)[0]].supplier = this.supplier.supplierNameM; // Supplier name taken from HTML input (it should be readOnly!)

        // 27.01.2022 new stuff here - removed HUGE calculations. Replaced with a nice and thin function to do the job..
        const pickUpDate = this.calculateDeptArrDates('departure', form.value['pickUpDate' + index as keyof typeof form.value]);
        const returnDate = this.calculateDeptArrDates('return', form.value['dropOffDate' + index as keyof typeof form.value]);

        // 13.04.2023 - validate Hrs and Mins
        const departHrs = this.constants.validateTime(form.value['pickUpHrs' + index as keyof typeof form.value], 'hrs');
        const departMin = this.constants.validateTime(form.value['pickUpMin' + index as keyof typeof form.value], 'min');
        const arriveHrs = this.constants.validateTime(form.value['dropHrs' + index as keyof typeof form.value], 'hrs');
        const arriveMin = this.constants.validateTime(form.value['dropMin' + index as keyof typeof form.value], 'min');

        // Calculate date + time here and assign it to variables
        miniHashName[Object.keys(miniHashName)[0]].pickUpDateTime = pickUpDate.toString() +
          ' ' + this.constants.zeroBeforeNo(departHrs) + ':' + this.constants.zeroBeforeNo(departMin) + ':00';
        miniHashName[Object.keys(miniHashName)[0]].dropOffDateTime = returnDate.toString() +
          ' ' + this.constants.zeroBeforeNo(arriveHrs) + ':' + this.constants.zeroBeforeNo(arriveMin) + ':00';

        miniHashName[Object.keys(miniHashName)[0]].pickUpLocation = form.value['pickUpLocation' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].dropOffLocation = form.value['dropOffLocation' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].country = form.value['country' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].pickUpInstructions = form.value['pickUpInstructions' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].dropOffInstructions = form.value['dropOffInstructions' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].VehicleType = form.value['VehicleType' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].emergencyResortContact = form.value['emergencyResortContact' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].emergencyPhoneNo = form.value['emergencyPhoneNo' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].adults = form.value['paxNo' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].children = form.value['paxNoInfants' + index as keyof typeof form.value]; // Usual stuff here
        miniHashName[Object.keys(miniHashName)[0]].infants = form.value['paxInfants' + index as keyof typeof form.value]; // Usual stuff here

        miniHashName[Object.keys(miniHashName)[0]].description = this.costingBreakdown[index].description; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].grossCost = this.costingBreakdown[index].realGross; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].netCost = this.costingBreakdown[index].netPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].tax = this.costingBreakdown[index].vatPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].discount = this.costingBreakdown[index].discountPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].commission = this.costingBreakdown[index].commissionPrice; // Usuall stuff..
        miniHashName[Object.keys(miniHashName)[0]].depositAmount = this.costingBreakdown[index].depositPrice; // Usuall stuff..

        // Make sure each element's return date is after pick up date here
        if (new Date(miniHashName[Object.keys(miniHashName)[0]].dropOffDateTime) < new Date(miniHashName[Object.keys(miniHashName)[0]].pickUpDateTime)) {
          this.sendMessageToDialog('', 'Transfer number ' + (index + 1).toString() + ': the pick up date must be set before the drop off date', '', '');
          this.element.deptDate = this.constDeptDate; // Set the departure date to what it was initially
          this.element.returnDate = this.constReturnDate; // Set the departure date to what it was initially
          throw new Error('Bad dates!');
        }
        Object.assign(this.elementData, miniHashName); // Assign each property to the elementData object at the end of the loop
      });

      this.pageLoaded = false;
      if (this.openedBooking.freshElement === 'yes') {
        this.elementService.createElement(this.elementData).then((output: any) => {
          if (output.status !== 'OK') {
            this.sendMessageToDialog('', 'Failed to create Transfer element (' + output.status + ')', '', '');
          } else {
            this.updateBookingData().then(res => {
              Session.mySession.resetTimersOnBookingValues().then(() => {
                this.router.navigate(['/booking/' + this.openedBooking.bookingReference + '/1']);
              });
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0908S)', error, this.elementData);
        });
      } else if (this.openedBooking.freshElement === 'no') {
        this.elementService.updateElement(this.elementData).then((output: any) => {
          if (output.status !== 'OK') {
            this.sendMessageToDialog('', 'Failed to create Transfer element (' + output.status + ')', '', '');
          } else {
            this.updateBookingData().then(res => {
              Session.mySession.resetTimersOnBookingValues().then(() => {
                this.router.navigate(['/booking/' + this.openedBooking.bookingReference + '/1']);
              });
            });
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0916S)', error, this.elementData);
        });
      }
    } else {
      this.sendMessageToDialog('', 'Please fill in all of the required fields', '', '');
    }
  }

  updateBookingData(): Promise<any> {
    return new Promise((resolve, reject) => {
      const bookingData: any = this.openedBooking; // Get the booking data from session
      const request = {
        company: bookingData.company, operation: bookingData.operation, tradeCode: bookingData.tradeCode,
        bookingReference: bookingData.bookingReference, token: Session.mySession.get('user').token
      };

      this.bookingService.autoCalcBookingDates(request).then((output: any) => {
        if (output.status !== 'OK') { this.sendMessageToDialog('', 'Booking dates have not been updated properly (' + output.status + ')', '', ''); }
        this.unblockNewBooking(); resolve(null);
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0917S)', error, request);
        this.unblockNewBooking(); resolve(null);
      });
    });
  }

  calculateDeptArrDates(dateType: any, dateIn: any): any {
    if (dateIn._i === undefined) { } else { dateIn = this.constants.convertDateMoment(dateIn); } // Convert if selected from mat-datepicker
    // Validate the date format first here (no more 01/01/22) - raised on 28/02/2022
    if (moment(dateIn, 'YYYY-MM-DD', true).isValid() === false && moment(dateIn, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid() === false) {
      this.sendMessageToDialog('', 'One of your dates is in the wrong format', '', '');
      this.element.deptDate = this.constDeptDate; // Set the departure date to what it was initially
      this.element.returnDate = this.constReturnDate; // Set the departure date to what it was initially
      throw new Error('Bad dates!');
    }
    // Calculate departure date below
    if (dateType === 'departure') {
      // Work out ELEMENT's departure date below - change ONLY if updated element's dept date is earlier than current one
      if (new Date(dateIn) <= new Date(this.element.deptDate) || this.element.deptDate === undefined) {
        this.elementData.coreElementData.deptDate = dateIn.substring(0, 10); // Core element deptDate pushed back
        this.element.deptDate = dateIn; // Update global element's deptDate for next element's calculations
        // Work out supplier due date of the main element below
        if (this.element.bookingDate !== undefined) {
          if (new Date(this.element.bookingDate) < new Date(this.calculateSuppDueDate(dateIn))) {
            this.elementData.coreElementData.suppDueDate = this.calculateSuppDueDate(dateIn);
          } else {
            this.elementData.coreElementData.suppDueDate = this.element.bookingDate;
          }
        } else {
          if (new Date(this.elementData.coreElementData.bookingDate) < new Date(this.calculateSuppDueDate(dateIn))) {
            this.elementData.coreElementData.suppDueDate = this.calculateSuppDueDate(dateIn);
          } else {
            this.elementData.coreElementData.suppDueDate = this.elementData.coreElementData.bookingDate;
          }
        }
      }
      // Calculate return date below
    } else if (dateType === 'return') {
      // Work out ELEMENT's return date below - change ONLY if updated element's return date is after the current one
      if (new Date(dateIn) >= new Date(this.element.returnDate) || this.element.returnDate === undefined) {
        this.elementData.coreElementData.returnDate = dateIn.substring(0, 10); // Core element returnDate moved forward
        this.element.returnDate = dateIn; // Update global element's reutrnDate for next element's calculations
      }
    }
    return dateIn.substring(0, 10); // Return back to create controllers..
  }

  calculateSuppDueDate(dateIn: any): any {
    // Calculate supplier due date (deptDate - paymentDueDays[from supplier])
    const suppDueDate: any = new Date(dateIn);
    if (Session.mySession.getElementSupplier() === undefined) {
      suppDueDate.setDate(suppDueDate.getDate() - Number(84)); // If the supplier doesn't exist anymore or its hidden - set it to 84 as a default
    } else {
      suppDueDate.setDate(suppDueDate.getDate() - Number(Session.mySession.getElementSupplier().paymentDueDays)); // Supplier still visible - use it
    }
    return this.constants.convertDateNotMoment(suppDueDate).substring(0, 10); // Convert the date here
  }

  changeSuppPrice(event: any, div: any): void {
    this.costingBreakdown[div].supplierPrice = event; // Assign the input value to elements supplier price

    // Calculate the real gross below
    this.costingBreakdown[div].realGross = (this.costingBreakdown[div].supplierPrice - this.costingBreakdown[div].discountPrice).toFixed(2);
    // Calculate objects commission without the VAT
    this.costingBreakdown[div].commissionPrice = (this.costingBreakdown[div].realGross -
      this.costingBreakdown[div].netPrice).toFixed(2);
    // Work out the vat BASED ON COMMISSION
    if (this.openedBooking.vatReg === 'yes') { this.costingBreakdown[div].vatPrice = (this.costingBreakdown[div].commissionPrice * 0.16666667).toFixed(2); }
    else { this.costingBreakdown[div].vatPrice = 0; }
    // Calculate objects commission with VAT
    this.costingBreakdown[div].commissionPrice = (this.costingBreakdown[div].realGross - this.costingBreakdown[div].netPrice -
      this.costingBreakdown[div].vatPrice).toFixed(2);

    // Work out the deposit from supplier.depositRate (if exists and non-£0)
    if (Session.mySession.getElementSupplier() !== undefined && Session.mySession.getElementSupplier().depositRate !== '0.0') {
      this.costingBreakdown[div].depositPrice = (this.costingBreakdown[div].supplierPrice * (Number(Session.mySession.getElementSupplier().depositRate) / 100)).toFixed(2);
    }

    this.addUpCostings(); // Add up total costings
  }

  changeNet(event: any, div: any): void {
    this.costingBreakdown[div].netPrice = event; // Assign input to netPrice

    // Calculate objects commission without the VAT
    this.costingBreakdown[div].commissionPrice = (this.costingBreakdown[div].realGross -
      this.costingBreakdown[div].netPrice).toFixed(2);
    // Work out the vat BASED ON COMMISSION
    if (this.openedBooking.vatReg === 'yes') { this.costingBreakdown[div].vatPrice = (this.costingBreakdown[div].commissionPrice * 0.16666667).toFixed(2); }
    else { this.costingBreakdown[div].vatPrice = 0; }
    // Calculate objects commission with VAT
    this.costingBreakdown[div].commissionPrice = (this.costingBreakdown[div].realGross - this.costingBreakdown[div].netPrice -
      this.costingBreakdown[div].vatPrice).toFixed(2);

    this.addUpCostings(); // Add up total costings
  }

  changeVAT(event: any, div: any): void {
    this.costingBreakdown[div].vatPrice = event; // Assign input to vatPrice

    // Calculate objects commission without the VAT
    this.costingBreakdown[div].commissionPrice = (this.costingBreakdown[div].realGross -
      this.costingBreakdown[div].netPrice).toFixed(2);
    // Work out the vat BASED ON COMMISSION
    // if (this.openedBooking.vatReg == 'yes') { this.costingBreakdown[div].vatPrice = (this.costingBreakdown[div].commissionPrice * 0.16666667).toFixed(2); }
    // else { this.costingBreakdown[div].vatPrice = 0; }
    // Calculate objects commission with VAT
    this.costingBreakdown[div].commissionPrice = (this.costingBreakdown[div].realGross - this.costingBreakdown[div].netPrice -
      this.costingBreakdown[div].vatPrice).toFixed(2);

    this.addUpCostings(); // Add up total costings
  }

  changeDiscount(event: any, div: any): void {
    this.costingBreakdown[div].discountPrice = event; // Assign input to discountPrice

    // Calculate the real gross below
    this.costingBreakdown[div].realGross = (this.costingBreakdown[div].supplierPrice - this.costingBreakdown[div].discountPrice).toFixed(2);
    // Calculate objects commission without the VAT
    this.costingBreakdown[div].commissionPrice = (this.costingBreakdown[div].realGross -
      this.costingBreakdown[div].netPrice).toFixed(2);
    // Work out the vat BASED ON COMMISSION
    if (this.openedBooking.vatReg === 'yes') { this.costingBreakdown[div].vatPrice = (this.costingBreakdown[div].commissionPrice * 0.16666667).toFixed(2); }
    else { this.costingBreakdown[div].vatPrice = 0; }
    // Calculate objects commission with VAT
    this.costingBreakdown[div].commissionPrice = (this.costingBreakdown[div].realGross - this.costingBreakdown[div].netPrice -
      this.costingBreakdown[div].vatPrice).toFixed(2);

    this.addUpCostings(); // Add up total costings
  }

  addUpCostings(): void {
    let supplierPrice = 0; // Start from 0..
    let realGross = 0; // Start from 0..
    let commissionPrice = 0; // Start from 0..
    let vatPrice = 0; // Start from 0..
    let discountPrice = 0; // Start from 0..
    let netPrice = 0; // Start from 0..
    let depositPrice = 0; // Start from 0

    // Add up all costs from accross costingBreakdown array list
    // tslint:disable-next-line:typedef
    // tslint:disable-next-line:only-arrow-functions
    this.costingBreakdown.forEach(function(breakdown: any): any {
      supplierPrice = Number(supplierPrice) + Number(breakdown.supplierPrice);
      realGross = Number(realGross) + Number(breakdown.realGross);
      commissionPrice = Number(commissionPrice) + Number(breakdown.commissionPrice);
      vatPrice = Number(vatPrice) + Number(breakdown.vatPrice);
      discountPrice = Number(discountPrice) + Number(breakdown.discountPrice);
      netPrice = Number(netPrice) + Number(breakdown.netPrice);
      depositPrice = Number(depositPrice) + Number(breakdown.depositPrice);
    });

    this.totalCosts.supplierPrice = supplierPrice.toFixed(2); // Assign the value
    this.totalCosts.realGross = realGross.toFixed(2); // Assign the value
    this.totalCosts.commissionPrice = commissionPrice.toFixed(2); // Assign the value
    this.totalCosts.vatPrice = vatPrice.toFixed(2); // Assign the value
    this.totalCosts.discountPrice = discountPrice.toFixed(2); // Assign the value
    this.totalCosts.netPrice = netPrice.toFixed(2); // Assign the value
    this.totalCosts.depositPrice = depositPrice.toFixed(2); // Assign the value
  }

  addDiv(source: any): void {
    if (source === 'script' || this.createForm.valid) {
      this.elementDivs.push(this.elementDivs.length); // Add another number to elementDiv (used when creating massive Objects above)
      this.addnDataDiv.push([0]); // Add a new number to addnDataDiv where we'll keep multiple rooms, cabins etc.

      this.elementNoView.fill(false); // Set all array's items to false here
      this.elementNoView.push(true); // Push another item to array which is true - focus UI view on this element

      // Div element has been added from the UI which means the element is empty. Add default values below
      this.costingBreakdown.push({ description: '', supplierPrice: 0, realGross: 0, vatPrice: 0, discountPrice: 0, netPrice: 0, commissionPrice: 0, depositPrice: 0 });
      this.addUpCostings();
    } else {
      // this.switchView(this.elementDivs.length - 1); // Make sure we've got last element open
      setTimeout(() => {
        this.createForm.ngSubmit.emit(); // Otherwise prompt a message and highlight invalid fields
        const invalidControl = this.el.nativeElement.querySelectorAll('.ng-invalid');
        if (invalidControl) {
          invalidControl.forEach((control: any) => {
            control.focus();
          });
        }
      }, 100);
    }
  }

  removeDiv(): void {
    if (this.elementDivs.length > 1) {
      this.elementDivs.pop(); // Remove the last element from array (removes last element in UI)
      this.addnDataDiv.pop(); // Remove all rooms/cabins etc. from the last div
      // Code below ensures that removing latest element won't affect users current view
      // UNLESS UI was focues on to-be-deleted element. If so, switch to previous one
      if (this.elementNoView[this.elementNoView.length - 1] === true) {
        this.elementNoView[this.elementNoView.length - 2] = true;
      }
      this.elementNoView.pop(); // Remove last element from view array
      this.costingBreakdown.pop(); // Remove current costings breakdown
      this.addUpCostings(); // Add up costings once again (if there was anything in deleted breakdown)
    }
  }

  addAddnDiv(divNo: any): void {
    this.addnDataDiv[divNo].push(this.addnDataDiv[divNo].length);
  }

  removeAddnDiv(divNo: any): void {
    if (this.addnDataDiv[divNo].length > 1) {
      this.addnDataDiv[divNo].pop(); // Remove the last element from array (removes last element in UI)
    }
  }

  leadingZeroCheck(divId: any): void {
    const divValue = (document.getElementById(divId) as HTMLInputElement).value;
    // Get the value from HTML by the div ID and make sure there is a leading 0
    // When single value is entered AND check if Hrs and Min are in the correct range
    if (divValue.includes('.') || divValue === 'e' || divValue.length == 0) {
      (document.getElementById(divId) as HTMLInputElement).value = '00';
    } else if (divValue.length === 1) {
      (document.getElementById(divId) as HTMLInputElement).value = '0' + divValue;
    } else if (divId.includes('Hrs') && (Number(divValue) < 0 || Number(divValue) > 23)) {
      (document.getElementById(divId) as HTMLInputElement).value = '00';
    } else if (divId.includes('Min') && (Number(divValue) < 0 || Number(divValue) > 59)) {
      (document.getElementById(divId) as HTMLInputElement).value = '00';
    }
  }

  unblockNewBooking(): void {
    // This is getting called any time a create API is done. Unlocks creating new bookings..
    if (Session.mySession.get('blockNewBooking') != null &&
      Session.mySession.get('blockNewBooking') !== false) {
      Session.mySession.set('blockNewBooking', false);
    }
  }

  setNiceName(): void {
    // Set a 'cool' name to display in UI. No more small case letters without spaces
    const elementTypesNiceNames: any = {
      flight: 'Flight',
      accommodation: 'Accommodation',
      carHire: 'Car Hire',
      carParking: 'Car Park',
      attraction: 'Attraction',
      cruise: 'Cruise',
      miscellaneous: 'Miscellaneous',
      train: 'Train',
      transfer: 'Transfer'
    };

    if (elementTypesNiceNames.hasOwnProperty(this.elementType)) {
      this.elementTypeNiceName = elementTypesNiceNames[this.elementType];
    }
  }

  switchView(divNo: any): void {
    // Nothing special to add. Whenever button on left nav-bar is clicked, change the view variables values
    this.elementNoView = Array(this.elementDivs.length).fill(false);
    this.elementNoView[divNo] = true;
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  setEndDate(fromId: any, toId: any): void {
    const divValue = (document.getElementById(fromId) as HTMLInputElement).value;
    (document.getElementById(toId) as HTMLInputElement).value = divValue;
    // Trigger Angular's change detection to update the UI
    this.ngZone.run(() => {
      (document.getElementById(toId) as HTMLInputElement).dispatchEvent(new Event('input'));
    });
  }

  valiDate(event: any): void {
    if (event.value != null) {
      const dateIn = event.value._i;
      if (dateIn.year === undefined && // Whenever date is being changed, check if it's in one of three formats (backslash / dots / dash)
        !moment(dateIn, 'DD/MM/YYYY', true).isValid() && !moment(dateIn, 'DD.MM.YYYY', true).isValid() && !moment(dateIn, 'DD-MM-YYYY', true).isValid()) {
        this.sendMessageToDialog('', 'Please follow DD MM YYYY format', '', '');
        event.target.value = '';
      }
    }
  }

  filterSelect(): void {
    this.countriesFiltered = []; // Empty filtered array first
    const filter = this.filterString.toLowerCase(); // Get the string we filter with here

    // Loop through our MAIN array and add whatever matches our search string
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.countries.length; i++) {
      const option = this.countries[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.countriesFiltered.push(option);
      }
    }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    this.pageLoaded = true; this.dialog.open(this.statusDialog);
  }

  showHelp(): void {
    this.dialog.open(this.helpDialog);
  }
}
