<div *ngIf="!pageLoaded" class="loading-indicator">
  <mat-spinner style="margin:0 auto;"></mat-spinner>
</div>

<mat-card-content>
  <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
    <div [@inAnimation] *ngIf="haveAccess && innerWidth > 1024" style="width: 100%;">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('felloh')" mat-button [disabled]="fellohView">
          <mat-icon>payments</mat-icon> Customer Payment
        </button>
        <button class="navButtons" *ngIf="fxOpenView" (click)="switchView('fx')" mat-button [disabled]="fxView">
          <mat-icon>receipt</mat-icon> FX Transaction
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon> Help
        </button>
      </div>
    </div>

    <div [@inAnimation] *ngIf="haveAccess && innerWidth < 1025" style="width: 100%">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('felloh')" mat-button [disabled]="fellohView">
          <mat-icon>payments</mat-icon>
        </button>
        <button class="navButtons" *ngIf="fxOpenView" (click)="switchView('fx')" mat-button [disabled]="fxView">
          <mat-icon>receipt</mat-icon>
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon>
        </button>

      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #drawer mode="side" closed>

    </mat-sidenav>
    <mat-sidenav-content>
      <mat-card [style.overflow]="'auto'">
        <div class="sideNavContent" *ngIf="haveAccess">

          <div [@inAnimation] *ngIf="fellohView">
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>New Customer Payment
            </h1>
            <mat-divider></mat-divider>

            <div class="withinMatCard">
              <mat-form-field *ngIf="branchListData.length > 1" class="branchEdit" style="max-width: unset; width: 322.5px; padding-top: 5px; margin-bottom: -10px; margin-bottom: -25px; display: block-inline;">
                <mat-select #tradeCode="ngModel" name="tradeCode" [(ngModel)]="selectedBranch" placeholder="Branch" (selectionChange)="changeBranch($event.value, 'view')">
                  <mat-option *ngFor="let branch of branchListData" [value]="branch">
                    {{branch.fullName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <h3 style="margin-top: 25px;">
                <mat-radio-group style="vertical-align: middle;" color="primary" (change)="radioChangeCustomer($event)">
                  <mat-radio-button value="new" [checked]="newCustomer">New Customer</mat-radio-button>
                  <mat-radio-button style="margin-left: 1%;" value="existing" [checked]="existingCustomer">Existing Customer</mat-radio-button>
                </mat-radio-group>
              </h3>

              <form ngNativeValidate #getCustomerForm="ngForm" (ngSubmit)="getCustomer(getCustomerForm)" [@inAnimation] *ngIf="existingCustomer">
                <h5><i>Fill in surname or email below and click 'Search'. If costumer exists, you'll be able to select it from the list.</i></h5>

                <mat-form-field>
                  <input placeholder="Surname" matInput ngModel name="lastName" #lastName="ngModel" maxlength="50">
                </mat-form-field>

                <mat-form-field>
                  <input placeholder="Email Address" matInput ngModel name="email" #email="ngModel" minlength="5" maxlength="50"
                  pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$">
                </mat-form-field>

                <mat-form-field [@inAnimation] *ngIf="customerData.data.length > 0" class="passengerSelect">
                  <mat-select placeholder="Customer" #index (selectionChange)="customerSelected(index.value)">
                    <mat-option *ngFor="let data of customerData.data; index as i" [value]="i">{{data['firstName']}} {{data['middleName']}} {{data['lastName']}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <button mat-raised-button color="accent" type="submit" class="buttonToRight">
                  <mat-icon class="iconInButton">person_search</mat-icon>Search
                </button>
              </form>

              <div *ngIf="(userCompany == 'gtg' || userCompany == 'ttng') && !noLongerIbos.test(selectedBranch.tradeCode)">
                <form ngNativeValidate #createFellohForm="ngForm" (ngSubmit)="createFellohLink(createFellohForm)" [@inAnimation] *ngIf="(newCustomer || existingCustomer) && showSelectedCustomer">
                  <h5 *ngIf="!formCustomerDisable"><i>Please enter customer details below. Remember - one email address can be used only for one customer. It will be used to send emails and receipts from us.</i></h5>
                  <h5 *ngIf="formCustomerDisable"><i>Below are the details of selected customer. If they are correct, please continue.</i></h5>

                  <mat-form-field class="passengerExpandField">
                    <mat-select placeholder="Title" required ngModel [(ngModel)]="customer.title" name="title" #title="ngModel">
                      <mat-option *ngFor="let title of titles" [value]="title['value']">
                        {{title['value']}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <input placeholder="Customer Name" ngModel required matInput name="firstName" [(ngModel)]="customer.firstName" [readonly]="formCustomerDisable">
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <input placeholder="Customer Surname" ngModel required matInput name="lastName" [(ngModel)]="customer.lastName" [readonly]="formCustomerDisable">
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <input placeholder="Customer Email" matTooltip="We strongly advise to use customer email address" matTooltipClass="line-normal-tooltip" ngModel matInput name="email" [(ngModel)]="customer.email" [readonly]="formCustomerDisable"
                    pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$">
                  </mat-form-field>

                  <input type="number" style="display: none" ngModel matInput name="customerID" [(ngModel)]="customer.id">

                  <h5><i>In case this payment is for the new booking, please select 'New Booking' and we'll assign it a temporary reference. <br>
                      Otherwise please select 'Existing Booking' and enter iSell reference number.</i></h5>
                  <h3>
                    <mat-radio-group style="vertical-align: middle;" color="primary" (change)="radioChangeBooking($event)">
                      <mat-radio-button value="new" [checked]="bookingPrefix == 'temp-'">New Booking</mat-radio-button>
                      <mat-radio-button style="margin-left: 1%;" value="existing" [checked]="bookingPrefix != '' && bookingPrefix != 'temp-'">Existing Booking</mat-radio-button>
                    </mat-radio-group>
                  </h3>

                  <div [@inAnimation] *ngIf="bookingPrefix != '' && bookingPrefix != 'temp-'" style="margin-bottom: -11.75px; font-size: 9.5px; color: grey;">Booking Reference</div>
                  <mat-form-field class="passengerExpandField" [@inAnimation] *ngIf="bookingPrefix != '' && bookingPrefix != 'temp-'">
                    <span matPrefix>{{bookingPrefix}}</span>
                    <input *ngIf="bookingPrefix == 'NWG-'" ngModel required matInput title="Non-numeric characters are not allowed" pattern="[0-9]+" name="bookingReference" [(ngModel)]="bookingReference" minlength="5" maxlength="5">
                    <input *ngIf="bookingPrefix == 'GTG-'" ngModel required matInput title="Non-numeric characters are not allowed" pattern="[0-9]+" name="bookingReference" [(ngModel)]="bookingReference" minlength="6" maxlength="6">
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <mat-select placeholder="Allowed Payment Methods" required ngModel name="allowedPaymentMethods" [(ngModel)]="allowedPaymentMethods">
                      <mat-option [value]="'ALL'">Card & Open Banking</mat-option>
                      <mat-option [value]="'OPEN_BANKING'">Open Banking Only</mat-option>
                      <mat-option [value]="'CARD'">Card Payment Only</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <input placeholder="Amount Due" type="number" step="0.01" min="1" ngModel required matInput name="amountDue" max="100000">
                    <mat-select matSuffix ngModel name="currency" [(ngModel)]="selectedCurrency" class="amountSuffix" panelClass="fellohCurrency">
                      <mat-option *ngFor="let currency of selectedBranch.currencyList" [value]="currency">{{currency}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <mat-label>Notes</mat-label>
                    <input placeholder="e.g Trip to Bali" ngModel required matInput pattern="[A-Za-z0-9 ]+" title="Special characters are not allowed" name="notes">
                  </mat-form-field>

                  <br><br>

                  <button mat-raised-button color="accent" type="submit" class="buttonToRight">
                    <mat-icon class="iconInButton">create</mat-icon>Create Payment
                  </button>

                  <h5><i>*The <u>Notes</u> field is included in the Email Notifications sent to customer on making a payment.</i></h5>
                </form>
              </div>

              <div *ngIf="userCompany == 'tta' || noLongerIbos.test(selectedBranch.tradeCode)">
                <form ngNativeValidate #createFellohForm="ngForm" (ngSubmit)="createFellohLinkTTA(createFellohForm)" [@inAnimation] *ngIf="(newCustomer || existingCustomer) && showSelectedCustomer">
                  <h5 *ngIf="!formCustomerDisable"><i>Please enter customer details below. Remember - one email address can be used only for one customer. It will be used to send email and receipts from us.</i></h5>
                  <h5 *ngIf="formCustomerDisable"><i>Below are the details of selected customer. If they are correct, please continue.</i></h5>

                  <mat-form-field class="passengerExpandField">
                    <mat-select placeholder="Title" required ngModel [(ngModel)]="customer.title" name="title" #title="ngModel">
                      <mat-option *ngFor="let title of titles" [value]="title['value']">
                        {{title['value']}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <input placeholder="Customer Name" ngModel required matInput name="firstName" [(ngModel)]="customer.firstName" [readonly]="formCustomerDisable">
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <input placeholder="Customer Surname" ngModel required matInput name="lastName" [(ngModel)]="customer.lastName" [readonly]="formCustomerDisable">
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <input placeholder="Customer Email" matTooltip="We strongly advise to use customer email address" matTooltipClass="line-normal-tooltip" ngModel matInput name="email" [(ngModel)]="customer.email" [readonly]="formCustomerDisable"
                    pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$">
                  </mat-form-field>

                  <input type="number" style="display: none" ngModel matInput name="customerID" [(ngModel)]="customer.id">

                  <br><br>

                  <h5><i>
                      Please provide details about your booking below - the reference number is not mandatory. We will give it a temporary reference if submitted field is empty.</i></h5>

                  <mat-form-field>
                    <mat-autocomplete #auto="matAutocomplete" style="margin-top: -25px;" [panelWidth]="'auto'" (optionSelected)='selectBookRef($event.option.value)'>
                      <mat-option *ngFor="let bookRef of bookRefList; let i = index" [value]="i" style="font-family: Azeret Mono; font-size: 12px;">
                        <mat-icon style="margin-right: 2.5px;">flight_takeoff</mat-icon>
                        <span *ngIf="bookRef.additionalProperties.departDate !== undefined">{{ bookRef.additionalProperties.departDate | date : "dd.MM.y" }}</span>
                        <span *ngIf="bookRef.additionalProperties.departAt !== undefined">{{ bookRef.additionalProperties.departAt | date : "dd.MM.y" }}</span>
                        <mat-icon style="margin-left: 2.5px; margin-right: 2.5px;">flight_land</mat-icon>
                        {{ bookRef.additionalProperties.returnDate | date : "dd.MM.y" }}
                        ({{ bookRef.additionalProperties.supplierName }})
                      </mat-option>
                    </mat-autocomplete>
                    <input placeholder="Booking Reference" matInput id="bookingReference" ngModel pattern="[a-zA-Z0-9-]+" title="Special characters are not allowed" name="bookingReference" [(ngModel)]="bookingReference" minlength="5" maxlength="40"
                      [matAutocomplete]="auto" (keyup)="bookingRefLookup($event, 'input')">
                    <span matSuffix>
                      <button (click)="bookingRefLookup($event, 'button')" mat-icon-button color="primary" matTooltip="Search previous payments" matTooltipClass="line-normal-tooltip" type="button" id="searchRef">
                        <mat-icon style="transform: scale(1.5) !important;">search</mat-icon>
                      </button>
                    </span>
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>Departure Date</mat-label>
                    <input required matInput [(ngModel)]="departureDate" name="deptDate" [matDatepicker]="deptDate" (dateChange)="valiDate($event)">
                    <mat-datepicker-toggle matSuffix [for]="deptDate"></mat-datepicker-toggle>
                    <mat-datepicker #deptDate></mat-datepicker>
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>Return Date</mat-label>
                    <input required matInput [(ngModel)]="rtnDate" name="returnDate" [matDatepicker]="returnDate" (dateChange)="valiDate($event)">
                    <mat-datepicker-toggle matSuffix [for]="returnDate"></mat-datepicker-toggle>
                    <mat-datepicker #returnDate></mat-datepicker>
                  </mat-form-field>

                  <p></p>

                  <mat-form-field class="passengerExpandField" style="width: 559.5px; max-width: unset;">
                    <mat-label>Supplier Names</mat-label>
                    <mat-chip-list #chipList aria-label="Supplier Names" matTooltip="Submit multiple names by pressing enter or comma" matTooltipClass="line-normal-tooltip">
                      <mat-chip *ngFor="let name of supplierNames" [selectable]="false" [removable]="true" (removed)="removeChip(name, supplierNames)">
                        {{name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                      <input [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addChip($event, supplierNames)">
                    </mat-chip-list>
                  </mat-form-field>

                  <p></p>

                  <mat-form-field class="passengerExpandField">
                    <mat-select placeholder="Allowed Payment Methods" required ngModel name="allowedPaymentMethods" [(ngModel)]="allowedPaymentMethods">
                      <mat-option [value]="'ALL'">Card & Open Banking</mat-option>
                      <mat-option [value]="'OPEN_BANKING'">Open Banking Only</mat-option>
                      <mat-option [value]="'CARD'">Card Payment Only</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <input placeholder="Amount Due" type="number" step="0.01" min="1" ngModel required matInput name="amountDue" max="100000">
                    <mat-select matSuffix ngModel name="currency" [(ngModel)]="selectedCurrency" class="amountSuffix" panelClass="fellohCurrency">
                      <mat-option *ngFor="let currency of selectedBranch.currencyList" [value]="currency">{{currency}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <mat-label>Notes</mat-label>
                    <input placeholder="e.g Trip to Bali" ngModel required matInput pattern="[A-Za-z0-9 ]+" title="Special characters are not allowed" name="notes">
                  </mat-form-field>

                  <br><br>

                  <button mat-raised-button color="accent" type="submit" class="buttonToRight">
                    <mat-icon class="iconInButton">create</mat-icon>Create Payment
                  </button>

                  <h5><i>*The <u>Notes</u> field is included in the Email Notifications sent to customer on making a payment.</i></h5>
                </form>
              </div>
            </div>
          </div>

          <div [@inAnimation] *ngIf="fxView">
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>New FX Payment
            </h1>
            <mat-divider></mat-divider>

            <div class="withinMatCard">
              <h3 style="margin-top: 25px;">
                <mat-radio-group style="vertical-align: middle;" color="primary" (change)="radioChangeCustomer($event)">
                  <mat-radio-button value="new" [checked]="newCustomer">New Customer</mat-radio-button>
                  <mat-radio-button style="margin-left: 1%;" value="existing" [checked]="existingCustomer">Existing Customer</mat-radio-button>
                </mat-radio-group>
              </h3>

              <form ngNativeValidate #getCustomerForm="ngForm" (ngSubmit)="getCustomer(getCustomerForm)" [@inAnimation] *ngIf="existingCustomer">
                <h5><i>Fill in surname or email below and click 'Search'. If costumer exists, you'll be able to select it from the list.</i></h5>

                <mat-form-field>
                  <input placeholder="Surname" matInput ngModel name="lastName" #lastName="ngModel" maxlength="50">
                </mat-form-field>

                <mat-form-field>
                  <input placeholder="Email Address" matInput ngModel name="email" #email="ngModel" minlength="5" maxlength="50"
                  pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$">
                </mat-form-field>

                <mat-form-field *ngIf="customerData.data.length > 0" class="passengerSelect">
                  <mat-select placeholder="Customer" #index (selectionChange)="customerSelected(index.value)">
                    <mat-option *ngFor="let data of customerData.data; index as i" [value]="i">{{data['firstName']}} {{data['middleName']}} {{data['lastName']}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <button mat-raised-button color="accent" type="submit" class="buttonToRight">
                  <mat-icon>person_search</mat-icon>Search
                </button>
              </form>

              <form ngNativeValidate #createFellohForm="ngForm" (ngSubmit)="createFX(createFellohForm)" [@inAnimation] *ngIf="(newCustomer || existingCustomer) && showSelectedCustomer">
                <h5 *ngIf="!formCustomerDisable"><i>Please enter customer details below. Remember - one email address can be used only for one customer. It will be used to send email and receipts from us</i></h5>
                <h5 *ngIf="formCustomerDisable"><i>Below are the details of selected customer. If they are correct, please continue.</i></h5>

                <mat-form-field class="passengerExpandField">
                  <mat-select placeholder="Title" required ngModel [(ngModel)]="customer.title" name="title" #title="ngModel">
                    <mat-option *ngFor="let title of titles" [value]="title['value']">
                      {{title['value']}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="passengerExpandField">
                  <input placeholder="Customer Name" ngModel required matInput name="firstName" [(ngModel)]="customer.firstName" [readonly]="formCustomerDisable">
                </mat-form-field>

                <mat-form-field class="passengerExpandField">
                  <input placeholder="Customer Surname" ngModel required matInput name="lastName" [(ngModel)]="customer.lastName" [readonly]="formCustomerDisable">
                </mat-form-field>

                <mat-form-field class="passengerExpandField">
                  <input placeholder="Customer Email" matTooltip="We strongly advise to use customer email address" matTooltipClass="line-normal-tooltip" ngModel matInput name="email" [(ngModel)]="customer.email" [readonly]="formCustomerDisable"
                  pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$">
                </mat-form-field>

                <input type="number" style="display: none" ngModel matInput name="customerID" [(ngModel)]="customer.id">

                <h5><i>Please fill in FX payment details below</i></h5>

                <div style="margin-bottom: -11.75px; font-size: 9.5px; color: grey;">ICE Reference</div>
                <mat-form-field class="passengerExpandField">
                  <span matPrefix>FX-</span>
                  <input ngModel required matInput pattern="[a-zA-Z0-9]+" title="Special characters are not allowed" name="bookingReference" [(ngModel)]="bookingReference" minlength="3" maxlength="37">
                </mat-form-field>

                <mat-form-field class="passengerExpandField">
                  <mat-select placeholder="Allowed Payment Methods" required ngModel name="allowedPaymentMethods" [(ngModel)]="allowedPaymentMethods">
                    <mat-option [value]="'ALL'">Card & Open Banking</mat-option>
                    <mat-option [value]="'OPEN_BANKING'">Open Banking Only</mat-option>
                    <mat-option [value]="'CARD'">Card Payment Only</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="passengerExpandField">
                  <input placeholder="Amount Due" type="number" step="0.01" min="1" ngModel required matInput name="amountDue" max="100000">
                  <mat-select matSuffix ngModel name="currency" [(ngModel)]="selectedCurrency" class="amountSuffix" panelClass="fellohCurrency">
                    <mat-option *ngFor="let currency of selectedBranch.currencyList" [value]="currency">{{currency}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <br><br>
                <button mat-raised-button color="accent" type="submit" class="buttonToRight">
                  <mat-icon class="iconInButton">create</mat-icon>Create FX
                </button>

                <h5><i><u>Only Debit Card and Open Banking are permitted for FX Transactions</u></i></h5>
              </form>
            </div>
          </div>

        </div>

        <div class="accessDenied" *ngIf="pageLoaded && !haveAccess">
          <div class="errorMessage">
            <h2 style="text-align: center; margin-top: 2.5%;">Access denied</h2>
          </div>
        </div>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</mat-card-content>

<ng-template #myDialog>
  <div *ngIf="errorMessage != ''" class="errorMessage">
    <p>{{errorMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>

  <div *ngIf="successMessage != ''" class="successMessage">
    <p>{{successMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>
</ng-template>

<ng-template #helpDialog>
  <mat-dialog-content>
    <h1 style="margin-top: 0px;"><u>New Transaction</u></h1>
    <p>This is where you can create a new payment link for your customers.<br>
      Succsessful creation will redirect you back to the main page.</p>

    <h2>Customer</h2>
    <p>We hold Customers data in our database - hence you've got two options:<br>
      1. Add a new Customer to the system while creating a payment link*<br>
      2. Pull the Customer and use it to create a payment link.<br></p>

    <h2>Booking</h2>
    <p *ngIf="userCompany == 'ttng' || userCompany == 'gtg'">All payment links need to have a booking reference entered:<br>
      1. Tell us the iSell booking reference**, or<br>
      2. Select 'New Booking' and we'll assign it a <b>temporary</b> one.<br>

      <br><i>*Customer email <b>must</b> be unique</i>
      <br><i>**SinGS will check whether the reference exists within your shop</i>
    </p>

    <p *ngIf="userCompany == 'tta'">All payment links need to have a booking reference entered:<br>
      1. Tell us the booking reference in your system, or<br>
      2. Leave the field blank and we'll assign it reference ourselves.<br>
      3. You must provide both departure and return dates.<br>
      4. Supplier name(s) are also mandatory.<br>

      <br><i>*Customer email <b>must</b> be unique</i>
    </p>

    <p style="margin: auto; text-align: center; margin-bottom: 0px;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </mat-dialog-content>
</ng-template>
