<div *ngIf="!pageLoaded" class="loading-indicator">
  <mat-spinner style="margin:0 auto;"></mat-spinner>
</div>

<mat-card-content>
  <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
    <div [@inAnimation] *ngIf="haveAccess && innerWidth > 1260" style="width: 100%;">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" mat-button [disabled]="generateView" (click)="switchView('generate')">
          <mat-icon>post_add</mat-icon> Generate Report
        </button>
        <button class="navButtons" mat-button [disabled]="browseView" (click)="switchView('browse')">
          <mat-icon>library_books</mat-icon> Report Archive
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon> Help
        </button>
      </div>
    </div>

    <div [@inAnimation] *ngIf="haveAccess && innerWidth < 1261" style="width: 100%">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" mat-button [disabled]="generateView" (click)="switchView('generate')">
          <mat-icon>post_add</mat-icon>
        </button>
        <button class="navButtons" mat-button [disabled]="browseView" (click)="switchView('browse')">
          <mat-icon>library_books</mat-icon>
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #drawer mode="side" closed></mat-sidenav>

    <mat-sidenav-content>
      <mat-card [style.overflow]="'auto'">
        <div [@inAnimation] *ngIf="haveAccess">
          <h1 fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>
            <span *ngIf="generateView">Generate Report</span>
            <span *ngIf="browseView">Report Archive</span>
          </h1>
          <div class="divider">
            <mat-divider></mat-divider>
          </div>


          <div *ngIf="generateView">
            <div style="width: 100%; margin-bottom: 20px;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="expansionSearch" (click)="expansionSearch = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansionSearch" (click)="expansionSearch = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  Generate Report
                </div>
              </div>

              <div [@customExpansionDetails]="expansionSearch == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                <form ngNativeValidate #produceReportForm="ngForm" style="margin-top: 4px; margin-bottom: -11px;">
                  <mat-form-field class="companyEdit" *ngIf="companies.length > 1" style="margin-bottom: -15px;">
                    <mat-select ngModel required name="company" #company="ngModel" [(ngModel)]="currentRequest.company" placeholder="Company" (selectionChange)="filterBranches($event.value, produceReportForm)">
                      <mat-option *ngFor="let company of companies" [value]="company['value']">
                        {{company['viewValue']}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <input *ngIf="companies.length == 1" type="hidden" ngModel #company="ngModel" name="company" [(ngModel)]="companies[0]['value']" />

                  <!-- <mat-form-field class="operationEdit" *ngIf="operations.length > 1">
                    <mat-select ngModel required name="operation" #operation="ngModel" placeholder="Operation">
                      <mat-option *ngFor="let operation of operations" [value]="operation['value']">
                        {{operation['viewValue']}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <input *ngIf="operations.length == 1" type="hidden" ngModel #operation="ngModel" name="operation" [(ngModel)]="operations[0]['value']"/> -->

                  <mat-form-field class="branchEdit" *ngIf="branches.length > 1" style="max-width: unset; width: 310px; margin-bottom: -15px;">
                    <mat-select ngModel required name="selBranch" #selBranch="ngModel" [(ngModel)]="currentRequest.branch" placeholder="Branch" [disabled]="filterInBranches.length == 0">
                      <mat-form-field appearance="fill" class="filterSelectList">
                        <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                      </mat-form-field>
                      <mat-option *ngFor="let branch of filterInBranches" [value]="branch" (click)="filterString = ''; filterSelect();">
                        {{branch['fullName']}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <input *ngIf="branches.length == 1" type="hidden" ngModel #selBranch="ngModel" name="selBranch" [(ngModel)]="branches[0]" />

                  <mat-form-field class="reportEdit" style="max-width: unset; width: 310px; margin-bottom: -15px;">
                    <mat-select ngModel required name="chosenReport" #chosenReport="ngModel" [(ngModel)]="selectedReport" placeholder="Report Type" (selectionChange)="changeReport($event.value)">
                      <mat-option *ngFor="let type of reportTypes" [value]="type" [disabled]="type.value == 'commissionReport' && disableReports">
                        {{type['viewValue']}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="reportEdit" style="max-width: unset; width: 150px; margin-bottom: -15px;" *ngIf="selectedReport.value == 'getPaymentsReport' && branches.length > 1">
                    <mat-select required name="paymentCategory" [(ngModel)]="paymentCategoryStr" ngModel #paymentCategory="ngModel" placeholder="Payment Category">
                      <mat-option value="all">All</mat-option>
                      <mat-option value="commission">Commission</mat-option>
                      <mat-option value="clientRefund">Client Refund</mat-option>
                      <mat-option value="reimbursement">Reimbursement</mat-option>
                      <mat-option value="payment">Supplier Payment</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="reportEdit" style="max-width: unset; width: 150px; margin-bottom: -15px;" *ngIf="selectedReport.value == 'getCommissionReport'">
                    <mat-select required name="commissionFilter" [(ngModel)]="commissionFilterStr" ngModel #commissionFilter="ngModel" placeholder="Display Commissions">
                      <mat-option value="all">All</mat-option>
                      <mat-option value="negativeOnly" *ngIf="userType == 'sinGSAdmin' || userType == 'sinGSstaff'">Negative Only</mat-option>
                      <mat-option value="paidOnly">Paid Only</mat-option>
                      <mat-option value="toPay">To Pay Only</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field style="max-width: unset; width: 150px; margin-bottom: -15px;" *ngIf="selectedReport.value == 'getDestinationReport'">
                    <mat-select ngModel name="country" #country="ngModel" placeholder="Country" [(ngModel)]="selectedCountry">
                      <mat-option value="">All</mat-option>
                      <mat-option *ngFor="let country of countries" [value]="country">{{country}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <p *ngIf="branches.length > 1" style="height: 0px;"></p>

                  <mat-form-field class="dateTypeEdit">
                    <mat-select ngModel required name="dateType" [(ngModel)]="dateType" placeholder="Date Type" [disabled]="dateTypes.length == 0">
                      <mat-option *ngFor="let type of dateTypes" [value]="type['value']">
                        {{type['viewValue']}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field style="max-width: 150px;">
                    <mat-label>Start Date</mat-label>
                    <input ngModel required maxlength="50" matInput name="startDate" [(ngModel)]="fromDate" [matDatepicker]="startDate" [disabled]="dateType == ''" (dateChange)="valiDate($event)">
                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                    <mat-datepicker #startDate></mat-datepicker>
                  </mat-form-field>

                  <mat-form-field style="max-width: 150px;">
                    <mat-label>End Date</mat-label>
                    <input ngModel required maxlength="50" matInput name="endDate" [(ngModel)]="toDate" [matDatepicker]="endDate" [disabled]="dateType == ''" (dateChange)="valiDate($event)">
                    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                    <mat-datepicker #endDate></mat-datepicker>
                  </mat-form-field>

                  <mat-form-field class="reportEdit" style="max-width: unset; width: 150px; margin-bottom: -15px;" *ngIf="selectedReport.value == 'getPaymentsReport' && branches.length == 1">
                    <mat-select required name="paymentCategory" [(ngModel)]="paymentCategoryStr" ngModel #paymentCategory="ngModel" placeholder="Payment Category">
                      <mat-option value="all">All</mat-option>
                      <mat-option value="commission">Commission</mat-option>
                      <mat-option value="clientRefund">Client Refund</mat-option>
                      <mat-option value="reimbursement">Reimbursement</mat-option>
                      <mat-option value="payment">Supplier Payment</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field style="max-width: 150px;"
                  *ngIf="(selectedReport.keyword == 'bankingReport' && dateType == 'bookingDate') || (selectedReport.keyword == 'paymentsReport' && dateType == 'deptDate') ||
                  selectedReport.keyword == 'salesReport' || selectedReport.keyword == 'custBalDueReport' || selectedReport.keyword == 'customerDetailReport' ||
                  selectedReport.keyword == 'commissionReport'">
                    <input matInput ngModel placeholder="Booking Group" name="bookingGroup" #bookingGroup="ngModel" [(ngModel)]="bookingGroupStr">
                  </mat-form-field>

                  <div style="height: 50px; padding-top: 5px; margin-bottom: 5px;">
                    <button class="buttonToRight" style="margin-right: 30px;" (click)="produceReport(produceReportForm, 'rtnData')" mat-raised-button color="primary"
                      [disabled]="produceReportForm.invalid" matTooltip="Large reports will time out after 30 seconds" matTooltipClass="line-normal-tooltip">
                      Preview
                    </button>

                    <button class="buttonToRight" style="margin-right: 30px;" (click)="produceReport(produceReportForm, 'csv')" mat-mini-fab matTooltip="Generate CSV" matTooltipClass="line-normal-tooltip"
                      color="primary" [disabled]="produceReportForm.invalid">
                      <svg-icon src="assets/icons/csv-file-format-extension.svg" [svgStyle]="{'width.px':27,'height.px':27,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)'}"></svg-icon>
                    </button>

                    <button class="buttonToRight" (click)="produceReport(produceReportForm, 'xls')" mat-mini-fab matTooltip="Generate XLSX" matTooltipClass="line-normal-tooltip" color="primary"
                      [disabled]="produceReportForm.invalid">
                      <svg-icon src="assets/icons/xlsx-file-format-extension.svg" [svgStyle]="{'width.px':27,'height.px':27,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)'}"></svg-icon>
                    </button>
                  </div>
                </form>
              </div>

              <div [@customExpansionDetails]="expansionSearch == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="expansionSearch = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>

            <div style="width: 100%; margin-bottom: 60px;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="expansionList" (click)="expansionList = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansionList" (click)="expansionList = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  Report Results
                </div>

                <mat-paginator #paginatorData [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
              </div>

              <div [@customExpansionDetails]="expansionList == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                <div [@inAnimation] *ngIf="reportData.data.length > 0 && selectedReport.value == 'getSalesReport'" style="clear: both;">
                  <mat-table class="tableWithinExpansV2" [dataSource]="reportData">
                    <!-- Username Column -->

                    <ng-container matColumnDef="bookingRef">
                      <mat-header-cell *matHeaderCellDef> Booking Ref. </mat-header-cell>
                      <mat-cell *matCellDef="let data" style="font-family: Azeret Mono;">
                        <a (click)="goToBooking(data['0'])" style="font-family: Azeret Mono;">{{ data['0'] | uppercase }}</a>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="tradeCode">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Trading Name </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['3'] }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="status">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Status </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['1'] | titlecase }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="bookingDate">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Booked </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['5'] | date:'dd.MM.yyyy' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="departDate">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Deprature </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['9'] | date:'dd.MM.yyyy' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="gross">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Gross </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['14'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable">{{ salesReportValues.gross | currency:'GBP' }}</mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="commission">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Comm. </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['15'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable">{{ salesReportValues.commission | currency:'GBP' }}</mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="vat">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> VAT </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['16'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable">{{ salesReportValues.vat | currency:'GBP' }}</mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="net">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Net </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['17'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable">{{ salesReportValues.net | currency:'GBP' }}</mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="discount">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Disc. </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['18'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable">{{ salesReportValues.discount | currency:'GBP' }}</mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="markup">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Markup </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['20'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable">{{ salesReportValues.markup | currency:'GBP' }}</mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="finalTax">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Final Tax </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['21'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable">{{ salesReportValues.finalTax | currency:'GBP' }}</mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="customerPrice">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Cust. Price </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['22'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable">{{ salesReportValues.customerPrice | currency:'GBP' }}</mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="totalInvoiced">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Total Invoiced </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['23'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable">{{ salesReportValues.totalInvoiced | currency:'GBP' }}</mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="totalReceipted">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Customer Paid </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['24'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable">{{ salesReportValues.totalReceipted | currency:'GBP' }}</mat-footer-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="salesReportColumns"></mat-header-row>
                    <mat-row class="rowNotClick" *matRowDef="let row; columns : salesReportColumns;"></mat-row>
                    <mat-footer-row class="rowNotClick" *matFooterRowDef="salesReportColumns"></mat-footer-row>
                  </mat-table>
                </div>

                <div [@inAnimation] *ngIf="reportData.data.length > 0 && selectedReport.value == 'getBankingReport'" style="clear: both;">
                  <mat-table class="tableWithinExpansV2" [dataSource]="reportData">
                    <!-- Username Column -->

                    <ng-container matColumnDef="bookingRef">
                      <mat-header-cell *matHeaderCellDef> Booking Ref. </mat-header-cell>
                      <mat-cell *matCellDef="let data" style="font-family: Azeret Mono;">
                        <a (click)="goToBooking(data['0'])" style="font-family: Azeret Mono;">{{ data['0'] | uppercase }}</a>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="tradeCode">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Trading Name </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable">{{ data['2'] }}</mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="leadPax">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Lead Pax </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['5'] }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="deptDate">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Departure </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['6'] | date:'dd.MM.yyyy' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="receiptDate">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Receipted </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['7'] | date:'dd.MM.yyyy' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="payerName">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Payer </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['8'] }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="paymentMethod">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Method </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['9'] }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="value">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Value </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['10'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable">{{ bankingReportValues.value | currency:'GBP' }}</mat-footer-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="bankingReportColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns : bankingReportColumns;" class="rowNotClick"></mat-row>
                    <mat-footer-row class="rowNotClick" *matFooterRowDef="bankingReportColumns"></mat-footer-row>
                  </mat-table>
                </div>

                <div [@inAnimation] *ngIf="reportData.data.length > 0 && selectedReport.value == 'getPaymentsReport'" style="clear: both;">
                  <mat-table class="tableWithinExpansV2" [dataSource]="reportData">

                    <ng-container matColumnDef="bookingRef">
                      <mat-header-cell *matHeaderCellDef> Booking Ref. </mat-header-cell>
                      <mat-cell *matCellDef="let data" style="font-family: Azeret Mono;">
                        <a (click)="goToBooking(data['0'])" style="font-family: Azeret Mono;">{{ data['0'] | uppercase }}</a>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="tradeCode">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Trading Name </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['2'] }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="deptDate">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Departure </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['3'] | date:'dd.MM.yyyy' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="supplierDueDate">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Supplier Due </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['4'] | date:'dd.MM.yyyy' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="paymentSupplier">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Supplier </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['5'] }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="paymentDate">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Payment Date </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['7'] | date:'dd.MM.yyyy' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="paymentAmount">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Payment Amount </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['6'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable">{{ bankingReportValues.value | currency:'GBP' }}</mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="paymentMethod">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Method </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['8'] }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="paymentReportColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns : paymentReportColumns;" class="rowNotClick"></mat-row>
                    <mat-footer-row class="rowNotClick" *matFooterRowDef="paymentReportColumns"></mat-footer-row>
                  </mat-table>
                </div>

                <div [@inAnimation] *ngIf="reportData.data.length > 0 && selectedReport.value == 'getCustBalDueReport'" style="clear: both;">
                  <mat-table class="tableWithinExpansV2" [dataSource]="reportData">

                    <ng-container matColumnDef="bookingRef">
                      <mat-header-cell *matHeaderCellDef> Booking Ref. </mat-header-cell>
                      <mat-cell *matCellDef="let data" style="font-family: Azeret Mono;">
                        <a (click)="goToBooking(data['0'])" style="font-family: Azeret Mono;">{{ data['0'] | uppercase }}</a>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="tradeCode">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Trading Name </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['2'] }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="leadPax">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Lead Pax </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['3'] }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="custDueDate">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Customer Due </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['4'] | date:'dd.MM.yyyy' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="suppDueDate">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Supplier Due </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['5'] | date:'dd.MM.yyyy' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="deptDate">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Departure </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['6'] | date:'dd.MM.yyyy' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="returnDate">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Return </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['7'] | date:'dd.MM.yyyy' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="gross">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Selling Price </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['8'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable">{{ custBalDueValues.custPrice | currency:'GBP' }}</mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="discount">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Discount </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['9'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable">{{ custBalDueValues.discount | currency:'GBP' }}</mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="totalReceipted">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Receipted </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['10'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable">{{ custBalDueValues.custPaid | currency:'GBP' }}</mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="balanceToOp">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Balance </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['11'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable">{{ custBalDueValues.custBal | currency:'GBP' }}</mat-footer-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="custBalDueReportColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns : custBalDueReportColumns;" class="rowNotClick"></mat-row>
                    <mat-footer-row class="rowNotClick" *matFooterRowDef="custBalDueReportColumns"></mat-footer-row>
                  </mat-table>
                </div>

                <div [@inAnimation] *ngIf="reportData.data.length > 0 && selectedReport.value == 'getCustomerDetailReport'" style="clear: both;">
                  <mat-table class="tableWithinExpansV2" [dataSource]="reportData">
                    <ng-container matColumnDef="custName">
                      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                      <mat-cell *matCellDef="let data">{{ data['1'] }}</mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="custEmail">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Email </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['2'] }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="address1">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Address 1 </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable">{{ data['3'] }}</mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="address2">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Address 2 </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable">{{ data['4'] }}</mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="address3">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Address 3 </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable">{{ data['5'] }}</mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="address4">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Address 4 </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable">{{ data['6'] }}</mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="postcode">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Postcode </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['7'] }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="county">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> County </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['8'] }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="country">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Country </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['9'] }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="telNo">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Tel. No. </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['10'] }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="noOfBookings">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Bookings </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['11'] }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="latestDeptDate">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Latest Departure </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['12'] | date:'dd.MM.yyyy' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="latestBookgDate">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Latest Booking </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['13'] | date:'dd.MM.yyyy' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="customerDetailReportColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns : customerDetailReportColumns;" class="rowNotClick"></mat-row>
                    <mat-footer-row class="rowNotClick" *matFooterRowDef="customerDetailReportColumns"></mat-footer-row>
                  </mat-table>
                </div>

                <div [@inAnimation] *ngIf="reportData.data.length > 0 && selectedReport.value == 'getSupplementReport'" style="clear: both;">
                  <mat-table class="tableWithinExpansV2" [dataSource]="reportData">
                    <ng-container matColumnDef="bookingRef">
                      <mat-header-cell *matHeaderCellDef> Booking Ref. </mat-header-cell>
                      <mat-cell *matCellDef="let data" style="font-family: Azeret Mono;">
                        <a (click)="goToBooking(data['0'])" style="font-family: Azeret Mono;">{{ data['0'] | uppercase }}</a>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="tradeCode">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Trading Name</mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['2'] }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="supplementName">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Supplement </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['3'] }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="gross">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Gross </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['4'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable"> {{ supplementReportValues.gross | currency:'GBP' }} </mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="discount">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Discount </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['5'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable"> {{ supplementReportValues.discount | currency:'GBP' }} </mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="net">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Net </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['6'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable"> {{ supplementReportValues.net | currency:'GBP' }} </mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="createTS">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Created </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['7'] | date:'dd.MM.y hh:mm a' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="updateTS">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Last Update </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['8'] | date:'dd.MM.y hh:mm a' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="supplementReportColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns : supplementReportColumns;" class="rowNotClick"></mat-row>
                    <mat-footer-row class="rowNotClick" *matFooterRowDef="supplementReportColumns"></mat-footer-row>
                  </mat-table>
                </div>

                <div [@inAnimation] *ngIf="reportData.data.length > 0 && selectedReport.value == 'getCommissionReport'" style="clear: both;">
                  <mat-table class="tableWithinExpansV2" [dataSource]="reportData">

                    <ng-container matColumnDef="bookingRef">
                      <mat-header-cell *matHeaderCellDef> Booking Ref. </mat-header-cell>
                      <mat-cell *matCellDef="let data" style="font-family: Azeret Mono;">
                        <a (click)="goToBooking(data['0'])" style="font-family: Azeret Mono;">{{ data['0'] | uppercase }}</a>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="tradeCode">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Trading Name </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable">{{ data['2'] }}</mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="bookingDate">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Booked </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['3'] | date:'dd.MM.yyyy' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="deptDate">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Departure </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['4'] | date:'dd.MM.yyyy' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="returnDate">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Return </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['5'] | date:'dd.MM.yyyy' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="totalCommission">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Commission </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['6'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable">{{ commissionReportValues.totalCommission | currency:'GBP' }}</mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="commissionPaid">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Paid </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['7'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable">{{ commissionReportValues.commissionPaid | currency:'GBP' }}</mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="commissionToPay">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> To Pay </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['8'] | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable">{{ commissionReportValues.commissionToPay | currency:'GBP' }}</mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="comment">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Comment </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['9'] }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef class="centerTable"></mat-footer-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="commissionReportColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns : commissionReportColumns;" class="rowNotClick"></mat-row>
                    <mat-footer-row class="rowNotClick" *matFooterRowDef="commissionReportColumns"></mat-footer-row>
                  </mat-table>
                </div>

                <div [@inAnimation] *ngIf="reportData.data.length > 0 && selectedReport.value == 'getDestinationReport'" style="clear: both;">
                  <mat-table class="tableWithinExpansV2" [dataSource]="reportData">

                    <ng-container matColumnDef="bookingRef">
                      <mat-header-cell *matHeaderCellDef> Booking Ref. </mat-header-cell>
                      <mat-cell *matCellDef="let data" style="font-family: Azeret Mono;">
                        <a (click)="goToBooking(data['0'])" style="font-family: Azeret Mono;">{{ data['0'] | uppercase }}</a>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="tradeCode">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Trading Name </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable">{{ data['2'] }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="leadPax">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Lead Pax </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable">{{ data['3'] }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="contactNo">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Contact No. </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable">{{ data['4'] }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="bookingDate">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Booked </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['5'] | date:'dd.MM.yyyy' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="deptDate">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Start </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['6'] | date:'dd.MM.yyyy' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="returnDate">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> End </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['7'] | date:'dd.MM.yyyy' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="supplierName">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Supplier </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['8'] }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="supplierReference">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Reference </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['9'] }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="elementType">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Type </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['10'] }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="country">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Country </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['11'] }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="location">
                      <mat-header-cell *matHeaderCellDef class="centerTable"> Location </mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centerTable"> {{ data['12'] }} </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="destinationReportColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns : destinationReportColumns;" class="rowNotClick"></mat-row>
                  </mat-table>
                </div>

                <div [@inAnimation] *ngIf="reportData.data.length == 0" style="clear: both;">
                  <h3>There is no data to show</h3>
                </div>
              </div>

              <div [@customExpansionDetails]="expansionList == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="expansionList = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>
          </div>

          <div *ngIf="browseView">
            <div style="width: 100%; margin-bottom: 20px;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="expansionBrowse" (click)="expansionBrowse = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansionBrowse" (click)="expansionBrowse = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  Browse Reports
                </div>

                <mat-paginator #paginatorFile [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
              </div>

              <div [@customExpansionDetails]="expansionBrowse == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                <form ngNativeValidate #browseReportForm="ngForm" style="margin-top: 6px; margin-bottom: -6px; display: flex; align-items: center; height: 57px;" (ngSubmit)="browseReports(browseReportForm)">
                  <mat-form-field class="companyEdit" *ngIf="companies.length > 1">
                    <mat-select ngModel required name="company" #company="ngModel" [(ngModel)]="currentRequest.company" placeholder="Company" (selectionChange)="filterBranches($event.value, browseReportForm)">
                      <mat-option *ngFor="let company of companies" [value]="company['value']">
                        {{company['viewValue']}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="branchEdit" *ngIf="branches.length > 1" style="max-width: unset; width: 310px; margin-right: 30px;">
                    <mat-select ngModel required name="selBranch" #selBranch="ngModel" [(ngModel)]="currentRequest.branch" placeholder="Branch" [disabled]="filterInBranches.length == 0">
                      <mat-form-field appearance="fill" class="filterSelectList">
                        <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                      </mat-form-field>
                      <mat-option *ngFor="let branch of filterInBranches" [value]="branch" (click)="filterString = ''; filterSelect();">
                        {{branch['fullName']}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <input *ngIf="branches.length == 1" type="hidden" ngModel #selBranch="ngModel" name="selBranch" [(ngModel)]="branches[0]" />

                  <button class="buttonToRight" style="vertical-align: middle; display: inline-block;" mat-raised-button color="primary" [disabled]="browseReportForm.invalid">
                    Reload
                  </button>

                  <mat-checkbox style="margin-left: 25px; margin-top: -9px;" ngModel name="borderauUpload" color="primary" *ngIf="userType == 'sinGSAdmin' || userType == 'sinGSstaff'">
                    Show Borderau Uploads
                  </mat-checkbox>
                </form>

                <mat-table [@customExpansionDetails]="fileData.data.length > 0 ? 'expanded' : 'collapsed'" *ngIf="fileData.data.length > 0" class="tableWithinExpansV2" style="zoom: 1 !important;" [dataSource]="fileData">
                  <ng-container matColumnDef="fileName">
                    <mat-header-cell *matHeaderCellDef> File Name </mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.name }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="reportType">
                    <mat-header-cell *matHeaderCellDef> Report Type </mat-header-cell>
                    <mat-cell *matCellDef="let data">
                      <span *ngIf="data.reportType == 'customerDetailReportSinGS'">Customer Detail Report</span>
                      <span *ngIf="data.reportType == 'custBalDueReportSinGS'">Customer Balance Due Report</span>
                      <span *ngIf="data.reportType == 'paymentsReportSinGS'">Payments Report</span>
                      <span *ngIf="data.reportType == 'bankingReportSinGS'">Banking Report</span>
                      <span *ngIf="data.reportType == 'salesReportSinGS'">Sales Report</span>
                      <span *ngIf="data.reportType == 'BookingSupplementsSinGS'">Supplement Report</span>
                      <span *ngIf="data.reportType == 'commissionReportSinGS'">Commission Report - BETA</span>
                      <span *ngIf="data.reportType == 'destinationReportSinGS'">Destination Report</span>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="lastModified">
                    <mat-header-cell *matHeaderCellDef> Last Modified Date </mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.last_modified | date : "dd.MM.y hh:mm a" }} <span *ngIf="data.new" style="margin-left: 10px;" matTooltip="Requested Report" matTooltipClass="line-normal-tooltip">
                        <mat-icon>new_releases</mat-icon>
                      </span></mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="size">
                    <mat-header-cell *matHeaderCellDef> Size </mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.size | filesize }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="download">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let data">
                      <button class="matIcon" style="float: right;" type="button" mat-mini-fab color="primary" (click)="downloadReport(data)" [class.mat-elevation-z0]="true">
                        <mat-icon>file_download</mat-icon>
                      </button>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="s3FilesColumns"></mat-header-row>
                  <mat-row class="rowNotClick" *matRowDef="let row; columns : s3FilesColumns;"></mat-row>
                </mat-table>

                <div [@inAnimation] *ngIf="fileData.data.length == 0" style="clear: both;">
                  <h3>There is no data to show</h3>
                </div>
              </div>

              <div [@customExpansionDetails]="expansionBrowse == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="expansionBrowse = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="accessDenied" *ngIf="!haveAccess && pageLoaded">
          <div class="errorMessage">
            <h2 style="text-align: center; margin-top: 2.5%;">Access denied</h2>
          </div>
        </div>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</mat-card-content>

<ng-template #myDialog>
  <div *ngIf="errorMessage != ''" class="errorMessage">
    <p>{{errorMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>

  <div *ngIf="successMessage != ''" class="successMessage">
    <p>{{successMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>
</ng-template>

<ng-template #helpDialog>
  <mat-dialog-content>
    <h1 style="margin-top: 0px;"><u>Financial Reports</u></h1>
    <p>This page allows you to produce one of the available reports.</p>

    <p>Set the search criteria for the report and click one of the available buttons:<br>
      - Click <b>Preview</b> to display the results* on the page.<br>
      - Click <b>CSV</b> or <b>XLSX</b> to start generating reports at the back.</p>

    <p>Click on <b>Report Archive</b> to find previously generated reports.<br />
      <br><i>*Due to the screen size limit preview may not include all columns in bigger reports</i>
    </p>
    <br><br>
    <p style="margin: auto; text-align: center; margin-bottom: 0px;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </mat-dialog-content>
</ng-template>
