import { Component, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Session } from '../../../common/session';
import { environment } from './../../../../environments/environment';
import { UserService } from '../../../services/user.service';
import { GlobalConstants } from 'src/app/common/global-constants';
import { AppComponent } from 'src/app/app.component';
import { MatDialog } from '@angular/material/dialog';
import { SupplierService } from 'src/app/services/supplier.service';
import { ReportsService } from 'src/app/services/reports.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-request-supplier',
  templateUrl: './request-supplier.component.html',
  styleUrls: ['./request-supplier.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', marginTop: '-1.25px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class RequestSupplierComponent implements OnInit {
  // Imported variables from outside
  constants = new GlobalConstants();
  innerWidth = AppComponent.myapp.innerWidth;
  //supplierTypes = GlobalConstants.supplierTypes;
  countryList = GlobalConstants.countryList;
  countryFiltered = GlobalConstants.countryList;

  // Variables controlling user access
  pageLoaded = false;
  sfcFormValid = false;
  followUpWithSFC = false;
  userType: any = '';

  // Other variables
  errorMessage: any = '';
  successMessage: any = '';
  filterString: any = ''; // Used to filter country list (and maybe more in the future..)
  supplierList: any = [];
  supplierFilteredData: any = [];
  suggestedSuppliers: any = [];
  selectedSfcSupplier: any = { supplierNameM: '', supplierType: '', addressLine1: '', website: '' };
  allSfcFiles: any = [];

  // Upload file arrays
  sfcFiles1: any = [];
  sfcFiles2: any = [];
  sfcFiles3: any = [];
  sfcFiles4: any = [];
  sfcFiles5: any = [];
  sfcFiles6: any = [];

  // Variables taken from the child component
  @Input() requestSource: any;
  @Input() requestCompany: any;
  @Input() requestOperation: any;
  @Input() requestTradeCode: any;
  @Input() requestMode: any;

  // Variable going out of the child component
  @Output() sfcReqDone = new EventEmitter<any>();

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;

  constructor(public dialog: MatDialog, public userService: UserService, public supplierService: SupplierService,
              public reportService: ReportsService) { }

  ngOnInit(): void {
    this.userType = Session.mySession.getUser().userType; // Assign the user type at the beginning on load

    if (Session.mySession.getSupplierList(this.requestTradeCode).expiryTime === 'EXPIRED') {
      const request = {
        company: this.requestCompany, operation: this.requestOperation,
        tradeCode: this.requestTradeCode, token: Session.mySession.get('user').token
      };

      this.supplierService.getSupplierList(request).then((suppliers: any) => {
        if (suppliers.status === 'OK') {
          const sorted = suppliers.data.sort((a: any, b: any) => (a.supplierNameM > b.supplierNameM) ? 1 : -1); // Sort suppliers alphabetically
          Session.mySession.setSupplierList(this.requestTradeCode, sorted); // Set suppliers values in session
          this.supplierList = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a.supplierNameM === e.supplierNameM) === i); // Group suppliers (1 of each name) here
          this.supplierFilteredData = this.supplierList;
          this.pageLoaded = true;
        } else {
          this.sendMessageToDialog('', 'SinGS could not load suppliers (' + suppliers.status + ')', '', ''); // Print error message..
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2801S)', error, request);
      });
    } else {
      const sorted = Session.mySession.getSupplierList(this.requestTradeCode).supplierList.sort((a: any, b: any) => (a.supplierNameM > b.supplierNameM) ? 1 : -1);
      this.supplierList = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a.supplierNameM === e.supplierNameM) === i); // Group suppliers (1 of each name) here
      this.supplierFilteredData = this.supplierList;
      this.pageLoaded = true;
    }
  }

  findPsuedoMasterName(form: NgForm): void {
    const format = /[`!@#$%^*_+\=\[\]{}"\\|,<>?~]/; // These characters are not allowed in the fields..
    if (format.test(this.selectedSfcSupplier.supplierNameM)) {
      this.sendMessageToDialog('', 'Please remove invalid characters from Supplier Name', '', ''); // Please do!
    } else if (form.valid) {
      const request = {
        company: this.requestCompany, operation: this.requestOperation, tradeCode: this.requestTradeCode,
        supplierName: this.selectedSfcSupplier.supplierNameM, token: Session.mySession.get('user').token
      };
  
      this.pageLoaded = false;
      this.supplierService.findMasterName(request).then((res: any) => {
        if (res.status === 'OK') {

          if (res.data.length > 0) {
            this.suggestedSuppliers = res.data; // Assign suggested names over here..
            this.pageLoaded = true;
          } else {
            this.createUnapprovedSupplier(this.selectedSfcSupplier.supplierNameM, true);
          }

        } else {
          this.sendMessageToDialog('', res.status, '', ''); // Display pop-up error message
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2806S)', error, request);
      });
    }
  }

  createUnapprovedSupplier(form: NgForm, skipAlert: boolean): void {
    if (skipAlert || confirm('Are you sure you want to choose ' + form.value.supplierNameM + '?')) {
      const request: any = {
        company: this.requestCompany, operation: this.requestOperation, tradeCode: this.requestTradeCode,
        supplierNameM: form.value.supplierNameM, requestSource: this.requestSource,
        requestedName: Session.mySession.getUser().fullName, requestedEmail: Session.mySession.getUser().email,
        token: Session.mySession.get('user').token, type: 'supplier'
      };

      // Set the request status based on user type
      request.status = (this.userType === 'sinGSAdmin') ? 'Approved' : 'Pending';
  
      this.pageLoaded = false;
      this.supplierService.createSupplierRequest(request).then((result: any) => {
        // We don't really care if the supplier exists - it may exists, but its unapproved
        // In the meantime, we want them to be able to select 'TBC' supplier
        if (result.status === 'OK') {
  
          if (this.followUpWithSFC) {
            const supplier = this.supplierList.find((supplier: any) => supplier.supplierNameM === form.value.supplierNameM);
  
            if (supplier) {
              this.selectedSfcSupplier = supplier;
            } else {
              this.supplierList.unshift(this.selectedSfcSupplier);
            }
  
            this.selectedSfcSupplier.supplierNameM = form.value.supplierNameM; // Reassign the correct supplier name below..
            this.requestMode = 'sfc'; // Switch to the SFC mode
            this.pageLoaded = true;
          } else {
            this.dialog.closeAll(); // Close all dialogs first
            this.sendMessageToDialog('Request has been sent!', '', '', ''); // And then show the message to the user
          }
  
        } else {
          this.sendMessageToDialog('', result.status, '', ''); // Show error message to the user
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2802S)', error, request);
      });
    }
  }

  requestSFC(form: NgForm): void {
    // Clear existing content in allSfcFiles array
    this.allSfcFiles = [];

    // Concatenate all sfcFiles arrays into allSfcFiles
    this.allSfcFiles = this.allSfcFiles.concat(this.sfcFiles1, this.sfcFiles2, this.sfcFiles3, this.sfcFiles4, this.sfcFiles5, this.sfcFiles6);
    
    if (this.allSfcFiles.length < 3 && this.sfcFiles6.length === 0 && this.userType != 'sinGSAdmin') {
      this.sendMessageToDialog('', 'You need to upload at least three supporting files', '', '');
    } else if (!form.value.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      this.sendMessageToDialog('', 'Please enter a valid email address', '', '');
    } else if (!/^[0-9]+$/.test(form.value.accountNo)) {
      this.sendMessageToDialog('', 'Account number must be made of numbers only', '', '');
    } else if (!/^[0-9]+$/.test(form.value.sortCode)) {
      this.sendMessageToDialog('', 'Sort code must be made of numbers only', '', '');
    } else if (form.value.accountNo.length < 8) {
      this.sendMessageToDialog('', 'Account number needs to be at least 8 characters long', '', '');
    } else if (form.value.sortCode.length < 6) {
      this.sendMessageToDialog('', 'Sort code needs to be at least 6 characters long', '', '');
    } else if (form.value.currency !== '' && (!/^[A-Za-z]{3}$/.test(form.value.currency))) {
      this.sendMessageToDialog('', 'Currency needs to be in ISO 4217 format which is 3 characters long', '', '');
    } else {

      let currency = form.value.currency === '' ? 'GBP' : form.value.currency;

      const request: any = {
        company: this.requestCompany,
        operation: this.requestOperation,
        tradeCode: this.requestTradeCode,
        supplierNameM: this.selectedSfcSupplier.supplierNameM,
        requestedName: Session.mySession.getUser().fullName,
        requestedEmail: Session.mySession.getUser().email,
        tradingName: form.value.tradingName,
        telephone: form.value.telephone,
        email: form.value.email,
        website: form.value.website,
        address1: form.value.address1,
        contactName: form.value.contactName,
        contactRole: form.value.contactRole,
        bankName: form.value.bankName,
        accountNo: form.value.accountNo,
        sortCode: form.value.sortCode,
        accountName: form.value.accountName,
        iban: form.value.iban,
        swift: form.value.swift,
        currency: currency.toUpperCase(),
        type: 'sfc',
        token: Session.mySession.get('user').token
      };

      // Set the request status based on user type
      request.status = (this.userType === 'sinGSAdmin') ? 'Approved' : 'Pending';

      this.pageLoaded = false;

      this.uploadFile(`temp_${this.constants.getCurrentDateTime()}`).then((tempRes: any) => {
        if (tempRes === 'OK') {
          this.supplierService.createSupplierRequest(request).then((res: any) => {
            if (res.status != 'OK') {
              this.sendMessageToDialog('', res.status, '', '');
            } else if (res.status === 'OK' && this.allSfcFiles.length > 0) {
              this.uploadFile(res.reference).then((res: any) => {
                if (res === 'OK') {
                  this.sfcFiles1 = []; this.sfcFiles2 = []; this.sfcFiles3 = [];
                  this.sfcFiles4 = []; this.sfcFiles5 = []; this.sfcFiles6 = [];
                  this.dialog.closeAll(); // Close all dialogs first
                  this.sfcReqDone.emit(); // This will reload SFC list in the parent component
                  this.sendMessageToDialog('Request has been sent!', '', '', '');
                }
              });
            } else {
              this.dialog.closeAll(); // Close all dialogs first
              this.sfcReqDone.emit(); // This will reload SFC list in the parent component
              this.sendMessageToDialog('Request has been sent!', '', '', '');
            }
          }).catch((error: any) => {
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2803S)', error, request);
          });
        }
      });
    }
  }

  uploadFile(reference: any): Promise<any> {
    return new Promise((resolve, reject) => { // Check if the booking is in the session variable, It not / expired - reload by calling API
      const uploadRequest = {
        company: this.requestCompany, operation: this.requestOperation, tradeCode: this.requestTradeCode,
        bookingReference: reference, pathType: 'sfcSupportingFiles', token: Session.mySession.get('user').token
      };

      this.pageLoaded = false;
      // Call the sequential upload function
      this.uploadFilesSequentially(uploadRequest, this.allSfcFiles).then(() => {
        resolve('OK'); // All files uploaded successfully
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'There was a problem uploading your documents. If the problem persists, please contact the Operations Team', error, uploadRequest);
        resolve('ERROR'); // Resolve with error if any file upload fails
      });
    });
  }

  private async uploadFilesSequentially(uploadRequest: any, files: File[]): Promise<void> {
    for (const singleFile of files) {
      try {
        const result: any = await this.reportService.uploadS3files(uploadRequest, [singleFile]);
        if (result.status !== 'OK') {
          this.sendMessageToDialog('', result.status, '', '');
          throw new Error(result.status); // Stop on error
        }
      } catch (error) {
        throw error; // Propagate the error to stop the process
      }
    }
  }

  keepUploadedFile(fileInput: any, sfcFiles: any): void {
    const allFiles = Array.from(fileInput.target.files); // Get the file from target array list

    if (allFiles.some((singleFile: any) => singleFile.size > 4194304)) {
      this.sendMessageToDialog('', 'Maximum size is 4MB per file', '', '');
    } else {
      if (sfcFiles === 1) { this.sfcFiles1 = allFiles; }
      else if (sfcFiles === 2) { this.sfcFiles2 = allFiles; }
      else if (sfcFiles === 3) { this.sfcFiles3 = allFiles; }
      else if (sfcFiles === 4) { this.sfcFiles4 = allFiles; }
      else if (sfcFiles === 5) { this.sfcFiles5 = allFiles; }
      else if (sfcFiles === 6) { this.sfcFiles6 = allFiles; }

      if (((this.sfcFiles1.length > 0 || this.sfcFiles2.length > 0) && (this.sfcFiles3.length > 0 || this.sfcFiles4.length > 0)
          && this.sfcFiles5.length > 0) || this.sfcFiles6.length > 0) {
        this.sfcFormValid = true; // All three required documents have been attached - all good to go!
      }
    }
  }

  getTurnoverTemplate(fileName: any): void {
    const downloadRequest = {
      company: this.requestCompany, operation: this.requestOperation, tradeCode: this.requestTradeCode,
      pathType: 'templates', fileName: fileName, token: Session.mySession.get('user').token
    };

    const type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

    // Call downloadS3file method
    this.pageLoaded = false;
    this.reportService.downloadS3file(downloadRequest).then((output: any) => {
      if (output.status === 'OK') {
        try {
          // convert base64 to raw binary data held in a string
          // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
          const byteString = atob(output.fileContent);

          // write the bytes of the string to an ArrayBuffer
          const ab = new ArrayBuffer(byteString.length);

          // create a view into the buffer
          const ia = new Uint8Array(ab);

          // set the bytes of the buffer to the correct values
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }

          // Output BLOB needs to be transformed into an excel application file
          const data = new Blob([ab], { type });
          saveAs(data, fileName); // Call this function which opens browser's 'Save As..' window
          this.pageLoaded = true;
        } catch (error) {
          this.sendMessageToDialog('', error, '', ''); // File download OK but failed to convert Base64 to whatever
        }
      } else {
        this.sendMessageToDialog('', output.status, '', ''); // File download failed at the back-end
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2805S)', error, downloadRequest);
    });
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  selectSfcSupplier(supplier: any): void {
    this.selectedSfcSupplier = supplier; // Assign sleected supplier to the global value
  }

  filterSelect(): void {
    this.supplierFilteredData = []; // Empty filtered array first
    const filter = this.filterString.toLowerCase(); // Get the string we filter with here

    /// Loop through our MAIN array and add whatever matches our search string
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.supplierList.length; i++) {
      const option = this.supplierList[i];
      if (option.supplierNameM.toLowerCase().indexOf(filter) >= 0) {
        this.supplierFilteredData.push(option);
      }
    }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    this.pageLoaded = true; this.dialog.open(this.statusDialog, {autoFocus: false});
  }
}
