<div *ngIf="!pageLoaded" class="loading-indicator" style="margin:0 auto;">
  <mat-spinner></mat-spinner>
</div>

<mat-card-content>
  <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
    <div [@inAnimation] *ngIf="innerWidth > 1024 && haveAccess" style="width: 100%;">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('overview')" mat-button [disabled]="fellohFeesOverview">
          <mat-icon>visibility</mat-icon> Overview
        </button>
        <button class="navButtons" (click)="switchView('fellohFees')" mat-button [disabled]="fellohFeesDetails">
          <mat-icon matBadge="{{fellohFeesData.data.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="primary">percent</mat-icon> Charge List
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon> Help
        </button>
      </div>
    </div>

    <div [@inAnimation] *ngIf="innerWidth < 1025 && haveAccess" style="width: 100%">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('overview')" mat-button [disabled]="fellohFeesOverview">
          <mat-icon>visibility</mat-icon>
        </button>
        <button class="navButtons" (click)="switchView('fellohFees')" mat-button [disabled]="fellohFeesDetails">
          <mat-icon matBadge="{{fellohFeesData.data.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="primary">percent</mat-icon>
        </button>
        <button class="navButtons" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav mode="side" #drawer closed>
    </mat-sidenav>

    <mat-sidenav-content>
      <mat-card [style.overflow]="'auto'">
        <div [@inAnimation] class="sideNavContent" *ngIf="haveAccess">
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <div class="headerArrow" *ngIf="fellohFeesOverview">
              <h1 fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Overview
              </h1>
            </div>
            <div class="headerArrow" *ngIf="fellohFeesDetails">
              <h1 fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Payment Processing Charges
              </h1>
            </div>

            <div *ngIf="selectedBranch?.fellohConfig?.length > 1" class="fellohConfigDiv">
              <mat-select ngModel placeholder="Trading Name" name="fellohConfig" [(ngModel)]="selectedFellohAccount" id="fellohAccount" #fellohConfig="ngModel"
                (ngModelChange)="this.pageLoaded = false; prepareForFellohFees()">
                  <mat-option *ngFor="let fellohConfig of selectedBranch?.fellohConfig" [value]="fellohConfig">
                    {{fellohConfig['accountName']}} | {{fellohConfig['accountCode']}}
                  </mat-option>
              </mat-select>
            </div>
          </div>

          <mat-divider></mat-divider>

          <div class="dates">
            <div class="datesAndDownload">
              <mat-form-field style="width: 110px;">
                <input placeholder="From" matInput [(ngModel)]="fromDate" [matDatepicker]="fromDateSet" (dateChange)="changeDate('fromDate', $event)">
                <mat-datepicker-toggle matSuffix [for]="fromDateSet"></mat-datepicker-toggle>
                <mat-datepicker #fromDateSet></mat-datepicker>
              </mat-form-field>

              <mat-form-field style="max-width: 110px;">
                <input placeholder="To" matInput [(ngModel)]="toDate" [matDatepicker]="toDateSet" (dateChange)="changeDate('toDate', $event)">
                <mat-datepicker-toggle matSuffix [for]="toDateSet"></mat-datepicker-toggle>
                <mat-datepicker #toDateSet></mat-datepicker>
              </mat-form-field>

              <mat-form-field style="max-width: unset; width: 240px;" *ngIf="branchListData.length > 1">
                <mat-select ngModel placeholder="Branch" name="branch" [(ngModel)]="selectedBranch" id="branch" #branch="ngModel" (ngModelChange)="changeBranch($event)">
                  <mat-form-field appearance="fill" class="filterSelectList">
                    <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                  </mat-form-field>
                  <mat-option *ngFor="let branch of branchListFiltered" [value]="branch" (click)="filterString = ''; filterSelect();">
                    {{branch['fullName']}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <button *ngIf="fellohFeesData.data.length > 0" mat-stroked-button color="primary" (click)="exportToExcel()" style="margin-top: -4px;" matTooltip="Download XLSX" matTooltipClass="line-normal-tooltip">
                <mat-icon>file_download</mat-icon>
              </button>

              <button style="margin-top: -4px; margin-left: 10px" (click)="pageLoaded = false; prepareForFellohFees()" mat-stroked-button color="primary" matTooltip="Reload Transactions" matTooltipClass="line-normal-tooltip">
                <mat-icon>sync</mat-icon>
              </button>
            </div>

            <div [ngClass]="branchListData.length > 1 ? 'paginatorFooter' : 'paginatorFooterLong'" style="padding-top: 7.5px !important;" [hidden]="!fellohFeesDetails">
              <mat-paginator #paginatorFees [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
            </div>
          </div>

          <div *ngIf="fellohFeesOverview && chartCurrencies.length > 1" style="clear: both; display: flex; justify-content: center;">
            <div *ngFor="let currency of chartCurrencies">
              <button style="margin-top: -4px; margin-left: 10px; margin-right: 10px;"
              color="primary" mat-raised-button [disabled]="currency == selectedCurrency"
              (click)="changeChartCurrency(currency)">
                {{currency}}
              </button>
            </div>
          </div>

          <div [@inAnimation] *ngIf="fellohFeesOverview && pageLoaded" style="clear: both;">
            <div style="clear: both;" fxLayout="row" fxLayoutAlign="space-between center">
              <div fxFlex="100">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div class="topBoxes" fxFlex="25">
                    <h1>Payments<br>{{ paymentNumber }}</h1>
                  </div>
                  <div class="topBoxes" fxFlex="25">
                    <h1>Total Charges<br>{{ totalCharges * -1 | currency:selectedCurrency }}</h1>
                  </div>
                  <div class="topBoxes" fxFlex="25" style="float: left;">
                    <h1>Total Amount<br>{{ totalBalance | currency:selectedCurrency }}</h1>
                  </div>
                </div>

                <div class="apexChartZoom">
                  <apx-chart id="chart" [series]="chartOptions.series" [chart]="chartOptions.chart" [fill]="chartOptions.fill" [grid]="chartOptions.grid" [dataLabels]="chartOptions.dataLabels" [plotOptions]="chartOptions.plotOptions"
                    [xaxis]="chartOptions.xaxis" [yaxis]="chartOptions.yaxis" [legend]="chartOptions.legend" [colors]="chartOptions.colors"></apx-chart>
                </div>

                <div style="text-align: right; margin-right: 30px;">
                  <h4>Last updated {{yesterDate}} at 11:00pm</h4>
                </div>
              </div>
            </div>

            <img src="../../../assets/singsLogoMinix75.png" style="margin-top: -2.5px; margin-right: -10px; opacity: 0.25; float: right;">
          </div>

          <div class="warning">
            <h4 style="font-size: 14px;">
              <b>For informational purposes only</b><br>
              <span style="font-size: 13px; font-weight: 400;">
                Charges are estimated based on rate information available from your merchant acquirer(s), this can vary and should be used for rough predictions only.
              </span>
            </h4>
          </div>

          <div [@inAnimation] *ngIf="fellohFeesDetails && pageLoaded" style="clear: both; margin-bottom: 50px;">
            <div *ngIf="fellohFeesData.data.length > 0">
              <div class="table-responsive">
                <mat-table class="tableClass" multiTemplateDataRows [dataSource]="fellohFeesData">
                  <ng-container matColumnDef="payer">
                    <mat-header-cell *matHeaderCellDef>Booking Reference</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.booking.booking_reference }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="txnAmount">
                    <mat-header-cell *matHeaderCellDef>Transaction Amount</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.transaction.amount | currency : data.transaction.currency }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="txnDateCompleted">
                    <mat-header-cell *matHeaderCellDef>Date Completed</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.transaction.completed_at | date : "dd.MM.y hh:mm a" }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.metadata.payment_brand | titlecase }} {{ data.metadata.card_type | titlecase }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="region">
                    <mat-header-cell *matHeaderCellDef>Region</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.metadata.country_type | titlecase }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="percentage">
                    <mat-header-cell *matHeaderCellDef>Rate</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.charges.rate }}% </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="charges">
                    <mat-header-cell *matHeaderCellDef>Charges</mat-header-cell>
                    <mat-cell *matCellDef="let data"> <b>{{ data.charges.amount | currency : data.charges.currency }}</b> </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="expandedDetail">
                    <mat-cell class="expandedDetail" *matCellDef="let data" [attr.colspan]="7">
                      <div style="width: 100%; margin-bottom: 5px;">
                        <div class="fellohExpandRow">
                          <div>
                            <div>
                              <h3>
                                <mat-icon style="color: #565656; margin-top: -1.5px;">info</mat-icon> Transaction Details
                              </h3>
                            </div>
                            <div class="fellohExpandRowColumn">
                              <div>ID</div><div>{{data.transaction.id}}</div>
                            </div>
                            <div class="fellohExpandRowColumn">
                              <div>Completed At</div><div>{{data.transaction.completed_at | date : "dd.MM.y hh:mm a"}}</div>
                            </div>
                          </div>

                          <div *ngIf="data.metadata.card_type != undefined && data.metadata.card_type !== null">
                            <div>
                              <h3>
                                <mat-icon style="color: #565656; margin-top: -1.5px;">payment</mat-icon> Payment Details
                              </h3>
                            </div>
                            <div class="fellohExpandRowColumn">
                              <div>Cardholder Name</div><div>{{data.metadata.cardholder_name}}</div>
                            </div>
                            <div class="fellohExpandRowColumn">
                              <div>Card Type</div><div>{{ data.metadata.payment_brand | titlecase }} {{ data.metadata.card_type | titlecase }}</div>
                            </div>
                            <div class="fellohExpandRowColumn">
                              <div>Last Four Digits</div><div>{{data.metadata.last_four_digits}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="fellohFeesColumns;"></mat-header-row>
                  <mat-row *matRowDef="let row; columns : fellohFeesColumns;" class="trueRow"></mat-row>
                  <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden"></mat-row>
                </mat-table>

                <div style="text-align: right; margin-right: 30px;">
                  <h4>Last updated {{yesterDate}} at 11:00pm</h4>
                </div>
              </div>
            </div>

            <div *ngIf="pageLoaded && fellohFeesData.data.length == 0">
              <h3 style="margin-left: 22.5px; margin-top: 22.5px;">There is no data to show for this date range</h3>
            </div>
          </div>
        </div>

        <div class="accessDenied" *ngIf="pageLoaded && !haveAccess">
          <div class="errorMessage">
            <h2 style="text-align: center; margin-top: 2.5%;">Access denied</h2>
          </div>
        </div>
      </mat-card>


    </mat-sidenav-content>
  </mat-sidenav-container>
</mat-card-content>

<div *ngIf="!haveAccess && pageLoaded">
  <mat-error>
    <p style="text-align: center; margin-top: 2.5%;">We're sorry but You don't have access to this page.</p>
  </mat-error>
</div>

<ng-template #myDialog>
  <div *ngIf="errorMessage != ''" class="errorMessage">
    <p>{{errorMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>

  <div *ngIf="successMessage != ''" class="successMessage">
    <p>{{successMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>
</ng-template>
