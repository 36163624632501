<div *ngIf="!pageLoaded" class="loading-indicator">
    <mat-spinner style="margin:0 auto;"></mat-spinner>
</div>

<mat-card-content>
    <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
        <div [@inAnimation] *ngIf="innerWidth > 1024" style="width: 100%;">
            <div fxLayout="row" fxLayoutAlign="start center">
                <!--<button class="navButtons" (click)="switchView('summary')" mat-button [disabled]="summaryView">
                    <mat-icon>bar_chart</mat-icon> Banking Summary
                </button>-->
                <button class="navButtons" (click)="switchView('details')" mat-button [disabled]="detailsView">
                    <mat-icon>query_stats</mat-icon> Banking Details
                </button>
                <button class="navButtons" style="width: 100px; margin-left: auto;"
                    (click)="openSelectedDialog(helpDialog, '')" mat-button>
                    <mat-icon class="helpIcon">help</mat-icon> Help
                </button>
            </div>
        </div>

        <div [@inAnimation] *ngIf="innerWidth < 1025" style="width: 100%">
            <div fxLayout="row" fxLayoutAlign="start center">
                <!--<button class="navButtons" (click)="switchView('summary')" mat-button [disabled]="summaryView">
                    <mat-icon>bar_chart</mat-icon>
                </button>-->
                <button class="navButtons" (click)="switchView('details')" mat-button [disabled]="detailsView">
                    <mat-icon>query_stats</mat-icon>
                </button>
                <button class="navButtons" style="width: 100px; margin-left: auto;"
                    (click)="openSelectedDialog(helpDialog, '')" mat-button>
                    <mat-icon class="helpIcon">help</mat-icon>
                </button>
            </div>
        </div>
    </mat-toolbar>

    <mat-sidenav-container>
        <mat-sidenav #drawer mode="side" closed></mat-sidenav>

        <mat-sidenav-content>
            <mat-card [style.overflow]="'auto'">
                <div [@inAnimation]>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div>
                            <h1 style="display: flex;">
                                <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>
                                <span *ngIf="summaryView">Banking Summary</span>
                                <span *ngIf="detailsView">Banking Details</span>
                            </h1>
                        </div>

                        <div>
                            <button mat-icon-button style="background-color: #F9F9F9;; margin-right: 15px;"
                                (click)="loadBankStmnt('csv')">
                                <svg-icon src="assets/icons/csv-file-format-extension.svg"
                                    [svgStyle]="{'width.px':30,'height.px':30,'vertical-align':'middle','margin-top.px':'0','fill':'rgb(77,95,209)'}"></svg-icon>
                            </button>

                            <!--<button mat-icon-button style="background-color: #F9F9F9;" (click)="loadBankStmnt('xlsx')">
                                <svg-icon src="assets/icons/xlsx-file-format-extension.svg"
                                    [svgStyle]="{'width.px':30,'height.px':30,'vertical-align':'middle','margin-top.px':'0','fill':'rgb(0, 0, 0)'}"></svg-icon>
                            </button>-->
                        </div>
                    </div>

                    <div class="divider">
                        <mat-divider></mat-divider>
                    </div>

                    <div style="width: 100%; margin-bottom: 20px;">
                        <div class="ownExpansionHeader">
                            <div class="headerTopLeft">
                                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="expansionSearch"
                                    (click)="expansionSearch = false;">
                                    <mat-icon>expand_less</mat-icon>
                                </button>
                                <button type="button" mat-icon-button style="margin-right: 5px;"
                                    *ngIf="!expansionSearch" (click)="expansionSearch = true;">
                                    <mat-icon>expand_more</mat-icon>
                                </button>
                                Search Criteria
                            </div>
                        </div>

                        <div [@customExpansionDetails]="expansionSearch == true ? 'expanded' : 'collapsed'"
                            class="ownExpansionBody">
                            <div>
                                <div class="flexMiddle" *ngIf="branches.length > 1" style="margin-bottom: 15px;">

                                    <div class="flexDivsColumns" *ngIf="companies.length > 1" style="width: 35%;">

                                        <div>
                                            <h4>Selected Company</h4>
                                        </div>

                                        <div style="width:100%;">
                                            <mat-select ngModel required name="company" #company="ngModel"
                                                [(ngModel)]="selectedCompany" style="max-width: unset;"
                                                (selectionChange)="filterBranches($event)" class="customSelect"
                                                placeholder="Company">
                                                <mat-option *ngFor="let company of companies"
                                                    [value]="company['value']">
                                                    {{company['viewValue']}}
                                                </mat-option>
                                            </mat-select>
                                        </div>

                                    </div>

                                    <div class="flexDivsColumns" style="width: 35%;">

                                        <div>
                                            <h4>Selected Branch</h4>
                                        </div>

                                        <div style="width:100%;">
                                            <mat-select ngModel required name="tradeCode" [(ngModel)]="selectedBranch"
                                                class="customSelect" style="max-width: unset;" placeholder="Branch"
                                                [matTooltip]="filteredBranches.length === 0 ? 'No branches available' : ''">
                                                <mat-form-field appearance="fill" class="filterSelectList">
                                                    <input placeholder="Search.." matInput name="filterInput"
                                                        #filterInput="ngModel" [(ngModel)]="filterString"
                                                        (keyup)="filterSelect()">
                                                </mat-form-field>
                                                <mat-option *ngFor="let branch of filterInBranches"
                                                    [value]="branch['tradeCode']"
                                                    (click)="filterString = ''; filterSelect(); setStartingPagination(); loadBankStmnt('seq');">
                                                    {{branch['fullName']}}
                                                </mat-option>
                                            </mat-select>
                                        </div>

                                    </div>
                                </div>


                                <div class="flexMiddle">

                                    <div class="flexDivsRows">
                                        <div class="flexDivsColumns">
                                            <div>
                                            </div>
                                            <div>
                                                <button mat-icon-button color="warn" class="customButton"
                                                    [disableRipple]="true" id="rmDescFilterBtn"
                                                    (click)="clearDescriptionFilter(); setStartingPagination(); loadBankStmnt('seq')"><mat-icon>clear</mat-icon></button>
                                            </div>
                                        </div>

                                        <div class="flexDivsColumns">
                                            <div>
                                                <h4>Description Filter</h4>
                                            </div>
                                            <div>
                                                <input class="customInput" matInput [(ngModel)]="descriptionFilter" />
                                            </div>
                                        </div>

                                        <div class="flexDivsColumns">
                                            <div>
                                            </div>
                                            <div><button mat-icon-button color="primary" class="customButton"
                                                    [disableRipple]="true"
                                                    (click)="setStartingPagination(); loadBankStmnt('seq')"><mat-icon>search</mat-icon></button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flexDivsRows">
                                        <div class="flexDivsColumns">
                                            <div>
                                                <h4>Date From</h4>
                                            </div>
                                            <div>
                                                <input [matDatepicker]="fromDate" class="customInput" matInput
                                                    [(ngModel)]="bankingFromDate" id="dateFrom"
                                                    (dateChange)="setStartingPagination(); changeDate('fromDate', $event)" />
                                            </div>
                                        </div>

                                        <div class="flexDivsColumns">
                                            <div>
                                            </div>
                                            <div><mat-datepicker-toggle matSuffix
                                                    [for]="fromDate"></mat-datepicker-toggle>
                                                <mat-datepicker #fromDate></mat-datepicker>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flexDivsRows">
                                        <div class="flexDivsColumns">
                                            <div>
                                                <h4>Date To</h4>
                                            </div>
                                            <div>
                                                <input [matDatepicker]="toDate" class="customInput" matInput
                                                    [(ngModel)]="bankingToDate" id="dateTo"
                                                    (dateChange)="setStartingPagination(); changeDate('toDate', $event)" />
                                            </div>
                                        </div>

                                        <div class="flexDivsColumns">
                                            <div>
                                            </div>
                                            <div><mat-datepicker-toggle matSuffix
                                                    [for]="toDate"></mat-datepicker-toggle>
                                                <mat-datepicker #toDate></mat-datepicker>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flexDivsColumns" style="width: 200px;">
                                        <div>
                                            <h4>Reconciliation Status</h4>
                                        </div>
                                        <div style="width: 90%;">
                                            <mat-select ngModel [(ngModel)]="approvedFilter" name="statusFilter"
                                                #statusFilter="ngModel" class="customSelect"
                                                (selectionChange)="setStartingPagination(); loadBankStmnt('seq')">
                                                <mat-option [value]="''">All</mat-option>
                                                <mat-option [value]="'yes'">Reconciled Only</mat-option>
                                                <mat-option [value]="'no'">To Reconcile</mat-option>
                                            </mat-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div [@customExpansionDetails]="expansionSearch == false ? 'expanded' : 'collapsed'"
                            class="ownExpansionBodyEmpty" (click)="expansionSearch = true;">
                            <mat-icon>expand_more</mat-icon>
                        </div>
                    </div>

                </div>

                <div [@inAnimation] *ngIf="summaryView">
                    Summary TBD
                </div>

                <div [@inAnimation] *ngIf="detailsView">
                    <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-evenly; margin-bottom: 20px; font-size: 13px; font-weight: 600;">
                        <div><mat-icon style="color: #ce983a;">link_off</mat-icon> Unlink Payment / Receipt</div>
                        <div><mat-icon style="color: #ce983a;">clear</mat-icon> Remove Custom Comment</div>
                        <div><mat-icon style="color: #ce983a;">search</mat-icon> Search For Match</div>
                        <div><mat-icon style="color: #4d5fd1;">check_box_outline_blank</mat-icon> Unreconciled Row</div>
                        <div><mat-icon style="color: #4d5fd1;">check_box</mat-icon> Reconciled Row</div>
                    </div>

                    <div style="width: 100%; margin-bottom: 20px;">
                        <div class="ownExpansionHeader">
                            <div class="headerTopLeft">
                                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="expansionList"
                                    (click)="expansionList = false;">
                                    <mat-icon>expand_less</mat-icon>
                                </button>
                                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansionList"
                                    (click)="expansionList = true;">
                                    <mat-icon>expand_more</mat-icon>
                                </button>
                                Search Result
                            </div>

                            <div style="height: 56px; float: right; display: flex; align-items: center;">

                                <button mat-icon-button color="primary" class="paginateArrows"
                                    style="margin-right: 20px;" [disableRipple]="true"
                                    (click)="loadBankStmnt('seq')" [disabled]="!selectedBranch">
                                    <mat-icon>
                                        refresh
                                    </mat-icon>
                                </button>

                                <button class="paginateArrows" style="margin-right: 10px;"
                                    mat-icon-button [disabled]="pageNo <= 0" (click)="changePagination('back')">
                                    <mat-icon>arrow_back</mat-icon>
                                </button>

                                <button class="paginateArrows" style="margin-right: 20px;"
                                    mat-icon-button [disabled]="bankingData.data.length < limit"
                                    (click)="changePagination('forward')">
                                    <mat-icon>arrow_forward</mat-icon>
                                </button>
                            </div>
                        </div>

                        <div [@customExpansionDetails]="expansionList == true ? 'expanded' : 'collapsed'"
                            class="ownExpansionBody">
                            <mat-table multiTemplateDataRows [dataSource]="bankingData" style="zoom: 0.9;"
                                class="tableWithinExpansV2">
                                <ng-container matColumnDef="checkbox">
                                    <mat-header-cell *matHeaderCellDef>
                                        <mat-checkbox color="accent"
                                            (change)="changeUpdateStatus(bankingData.data, $event.checked)">
                                        </mat-checkbox>
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let bankingRow">
                                        <mat-checkbox color="accent" [(ngModel)]="bankingRow.update"
                                            (change)="changeUpdateStatus(bankingRow, $event.checked)"></mat-checkbox>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="date">
                                    <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                                    <mat-cell *matCellDef="let bankingRow"
                                        (click)="expandedElement = expandedElement === bankingRow ? null : bankingRow;">
                                        {{
                                        bankingRow.posting_date | date: 'dd.MM.yyyy' }}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="description">
                                    <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
                                    <mat-cell *matCellDef="let bankingRow"
                                        (click)="expandedElement = expandedElement === bankingRow ? null : bankingRow;">
                                        {{
                                        bankingRow.description }} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="credit">
                                    <mat-header-cell *matHeaderCellDef> Credit </mat-header-cell>
                                    <mat-cell *matCellDef="let bankingRow">
                                        <span *ngIf="(bankingRow.approved == 'yes' || bankingRow.matchFinal || bankingRow.matchFinalUI) && bankingRow.credit !== null">
                                            {{ bankingRow.credit | currency: bankingRow.currency }}</span>
                                        <input *ngIf="bankingRow.approved == 'no' && !bankingRow.matchFinal && !bankingRow.matchFinalUI" class="customInputSmall" matInput
                                            [(ngModel)]="bankingRow.credit" type="number" step="0.01"
                                            (ngModelChange)="changeUpdateStatus(bankingRow, true)" />
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="debit">
                                    <mat-header-cell *matHeaderCellDef> Debit </mat-header-cell>
                                    <mat-cell *matCellDef="let bankingRow">
                                        <span *ngIf="(bankingRow.approved == 'yes' || bankingRow.matchFinal || bankingRow.matchFinalUI) && bankingRow.debit !== null">
                                            {{ bankingRow.debit | currency: bankingRow.currency }}</span>
                                        <input *ngIf="bankingRow.approved == 'no' && !bankingRow.matchFinal && !bankingRow.matchFinalUI" class="customInputSmall" matInput
                                            [(ngModel)]="bankingRow.debit" type="number" step="0.01"
                                            (ngModelChange)="changeUpdateStatus(bankingRow, true)" />
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="matched">
                                    <mat-header-cell *matHeaderCellDef class="centeredColumn"> Matched
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let bankingRow" class="centeredColumn">
                                        <span id="commentMatch"
                                            *ngIf="bankingRow.matchFinal && !bankingRow.matchFinalUI && !bankingRow.matchFinalUI"
                                            style="font-family: Azeret Mono; margin-top: 1.5px; text-align: center;">{{bankingRow.matchFinal}}</span>

                                        <a (click)="openBookingNewWindow(bankingRow.matchFinalUI?.bookingRef, 2, bankingRow.matchFinalUI?.receiptCount, 'yes', false)"
                                            *ngIf="bankingRow.matchFinalUI?.receiptCount"
                                            style="font-family: Azeret Mono; margin-top: 1.5px;">{{bankingRow.matchFinalUI?.bookingRef
                                            |
                                            uppercase}}</a>

                                        <a (click)="openBookingNewWindow(bankingRow.matchFinalUI?.bookingRef, 3, bankingRow.matchFinalUI?.paymentCount, 'yes', false)"
                                            *ngIf="bankingRow.matchFinalUI?.paymentCount"
                                            style="font-family: Azeret Mono; margin-top: 1.5px;">{{bankingRow.matchFinalUI?.bookingRef
                                            |
                                            uppercase}}</a>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="operations">
                                    <mat-header-cell *matHeaderCellDef class="centeredColumn"> Operations
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let bankingRow" class="centeredColumn">

                                        <!-- Visible when matchFinalUI is present - stmntLine is matched to a receipt / payment -->
                                        <span class="custom-tooltip" *ngIf="bankingRow?.matchFinalUI">
                                            <button mat-button color="accent" (click)="unlinkMatchStmnt(bankingRow)"
                                                [style.visibility]="bankingRow.approved == 'no' ? 'visible' : 'hidden'">
                                                <mat-icon>link_off</mat-icon>
                                            </button>
                                            <span class="tooltip-text">Unlink</span>
                                        </span>

                                        <!-- Visible when custom comment is entered - no matchFinalUI present (matchFinal does not meet pattern req.) -->
                                        <span class="custom-tooltip" *ngIf="bankingRow?.matchFinal && !bankingRow?.matchFinalUI">
                                            <button mat-button color="accent" [style.visibility]="bankingRow.approved == 'no' ? 'visible' : 'hidden'"
                                            (click)="commentSelectedStmntID(bankingRow, null, null)"><mat-icon>clear</mat-icon></button>
                                            <span class="tooltip-text">Clear Comment</span>
                                        </span>

                                        <!-- Visible when matchFinal and matchFinal are null - typically no comment and no receipt/payment match -->
                                        <span class="custom-tooltip" *ngIf="!bankingRow?.matchFinal && !bankingRow?.matchFinalUI">
                                            <button mat-button color="accent" [style.visibility]="bankingRow.approved == 'no' ? 'visible' : 'hidden'"
                                            (click)="openMatchWindow(bankingRow)"><mat-icon>search</mat-icon></button>
                                            <span class="tooltip-text">Search</span>
                                        </span>

                                        <!-- Visible when either matchFinalUI or matchFinal present - could be comment or 'proper' match -->
                                        <span class="custom-tooltip" *ngIf="bankingRow.approved == 'no'">
                                            <button [style.visibility]="bankingRow?.matchFinal || bankingRow?.matchFinalUI ? 'visible' : 'hidden'"
                                             mat-button color="primary" (click)="changeApprovedStatus(bankingRow, 'yes')">
                                            <mat-icon>check_box_outline_blank</mat-icon>
                                            </button>
                                            <span class="tooltip-text" *ngIf="bankingRow?.matchFinal || bankingRow?.matchFinalUI">Reconcile</span>
                                        </span>

                                        <span class="custom-tooltip" *ngIf="bankingRow.approved == 'yes'">
                                            <button  mat-button color="primary" (click)="changeApprovedStatus(bankingRow, 'no')"><mat-icon>check_box</mat-icon></button>
                                            <span class="tooltip-text">De-reconcile</span>
                                        </span>
                                    </mat-cell>
                                </ng-container>

                                <!-- Expansion text -->
                                <ng-container matColumnDef="expandedDetail">
                                    <mat-cell class="expandedDetail" *matCellDef="let bankingRow" [attr.colspan]="7">
                                        <div class="flexBankDescriptionMain">
                                            <div class="flexBankDescriptionBox">
                                                <div>
                                                    <mat-icon
                                                        style="color: #565656; vertical-align: middle;">info</mat-icon>
                                                    <span>
                                                        {{ bankingRow.stmntType }} ({{bankingRow.typeCode}})
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="flexBankDescriptionBox">
                                                <div class="clickableText" (click)="showBankRawDetails(bankingRow)">
                                                    <mat-icon
                                                        style="color: #565656; vertical-align: middle;">fingerprint</mat-icon>
                                                    <span>
                                                        Original Values
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="flexBankDescriptionBox">
                                                <div class="clickableText" (click)="filterSelectedStmntID(bankingRow)">
                                                    <mat-icon
                                                        style="color: #565656; vertical-align: middle;">link</mat-icon>
                                                    <span class="clickableText">
                                                        View Related
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="flexBankDescriptionBox">
                                                <div class="clickableText" (click)="openCommentPopup(bankingRow)"
                                                    *ngIf="!bankingRow?.matchFinal && !bankingRow?.matchFinalUI">
                                                    <mat-icon
                                                        style="color: #565656; vertical-align: middle;">edit_note</mat-icon>
                                                    <span class="clickableText">
                                                        Comment
                                                    </span>
                                                </div>

                                                <div *ngIf="bankingRow?.matchFinal || bankingRow?.matchFinalUI"
                                                    style="color: #b5b5b5;">
                                                    <mat-icon style="vertical-align: middle;">edit_note</mat-icon>
                                                    <span>
                                                        Comment
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="flexBankDescriptionBox">
                                                <div class="clickableText" (click)="duplicateSelectedRow(bankingRow)">
                                                    <mat-icon
                                                        style="color: #565656; vertical-align: middle;">content_copy</mat-icon>
                                                    <span>
                                                        Duplicate Row
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns : displayedColumns;" class="trueRow"></mat-row>
                                <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
                                    [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'"
                                    style="overflow: hidden"></mat-row>
                            </mat-table>

                            <div class="buttonsBelowTable">
                                <div>
                                    <button mat-raised-button color="accent" (click)="updateSelectedRows()" [disabled]="!selectedBranch">
                                        <mat-icon>update</mat-icon> Update Selected
                                    </button>
                                </div>

                                <div>
                                    <button mat-raised-button color="accent"
                                        (click)="openSelectedDialog(synchDialog, 'pullRawDialog')" [disabled]="!selectedBranch">
                                        <mat-icon>cloud_download</mat-icon> Pull Banking Data
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div [@customExpansionDetails]="expansionList == false ? 'expanded' : 'collapsed'"
                            class="ownExpansionBodyEmpty" (click)="expansionList = true;">
                            <mat-icon>expand_more</mat-icon>
                        </div>
                    </div>
                </div>
            </mat-card>
        </mat-sidenav-content>

    </mat-sidenav-container>
</mat-card-content>

<ng-template #matchingDialog>
    <mat-dialog-content>

        <div>
            <div style="margin-bottom: 10px;">
                <div class="mainMatchDescription">
                    <h3>{{ selectedBankRow.description }}</h3>
                </div>
                <div class="mainMatchAmounts">
                    <div>
                        <h4>Debit: {{ selectedBankRow.debit | currency: selectedBankRow.currency }}</h4>
                    </div>
                    <div>
                        <h4>Credit: {{ selectedBankRow.credit | currency: selectedBankRow.currency }}</h4>
                    </div>
                </div>
            </div>

            <div *ngIf="systemMatched.receipts.length > 0 || systemMatched.payments.length > 0"
                class="mainMatchContainer">
                <div style="margin-bottom: 20px;">
                    Below is the list of bookings which we think match this row (wording TBD)<br>
                    Click on any of below to see suggested match(es) (wording TBD)
                </div>

                <div *ngIf="systemMatched.receipts.length > 0" style="width: 90%;">
                    <div *ngFor="let receipt of systemMatched.receipts" class="proposedMatches">
                        <div class="flexMiddle" style="align-items: center;" *ngIf="receipt.receiptCount">
                            <a (click)="openBookingNewWindow(receipt.bookingReference, 2, receipt.receiptCount, 'yes', true)"
                                style="font-family: Azeret Mono; margin-top: 1.5px;">{{receipt.bookingReference |
                                uppercase}}</a>
                            <button color="accent" mat-button matDialogClose
                                (click)="manMatchBankStmntLine(receipt, null, receipt.bookingReference)"><mat-icon>link</mat-icon>
                                Link</button>
                        </div>

                        <div class="flexMiddle" style="align-items: center;" *ngIf="!receipt.receiptCount">
                            <span style="font-family: Azeret Mono; margin-top: 1.5px;">{{receipt.bookingReference | uppercase}}</span>
                            <button color="accent" mat-button matDialogClose
                                (click)="commentSelectedStmntID(selectedBankRow, null, receipt.bookingReference)">
                                <mat-icon>note_add</mat-icon> Submit
                            </button>
                        </div>
                    </div>
                </div>

                <div *ngIf="systemMatched.payments.length > 0" style="width: 90%;">
                    <div *ngFor="let payment of systemMatched.payments" class="proposedMatches">
                        <div class="flexMiddle" style="align-items: center;"><a
                                (click)="openBookingNewWindow(payment.bookingReference, 3, payment.paymentCount, 'yes', true)"
                                style="font-family: Azeret Mono; margin-top: 1.5px;">{{payment.bookingReference |
                                uppercase}}</a>
                            <button color="accent" mat-button matDialogClose
                                (click)="manMatchBankStmntLine(null, payment, payment.bookingReference)"><mat-icon>link</mat-icon>
                                Link</button>
                        </div>
                    </div>
                </div>

                <div style="margin-top: 20px;">
                    <i>Match type</i><br>
                    <b>{{ systemMatched.matchType }}</b>
                </div>
            </div>

            <div *ngIf="systemMatched.receipts.length == 0 && systemMatched.payments.length == 0 &&
            selectedBankRow?.disbursalBookingRef" class="mainMatchContainer">
                <div style="margin-bottom: 20px;">
                    Below is the booking you provided when creating Felloh payment link<br>
                </div>

                <div style="width: 90%;">
                    <div>
                        <div class="flexMiddle" style="align-items: center;">
                            <a (click)="openBookingNewWindow(selectedBankRow.disbursalBookingRef, 2, null, 'no', true)"
                                style="font-family: Azeret Mono; margin-top: 1.5px;">{{selectedBankRow.disbursalBookingRef
                                |
                                uppercase}}</a>
                        </div>
                    </div>
                </div>

                <div style="margin-top: 20px;">
                    <i>Match type</i><br>
                    <b>fellohDisbursement</b>
                </div>
            </div>

            <div style="margin-top: 20px;">
                <i>Category</i><br>
                <b>{{ systemMatched.stmntLineCategory }}</b>
            </div>

            <div *ngIf="systemMatched.receipts.length == 0 && systemMatched.payments.length == 0 && !selectedBankRow?.disbursalBookingRef"
                class="mainMatchContainer" style="margin-top: 40px;">
                <div>
                    Unfortunately we were unable to match this row
                </div>
            </div>

            <div class="mainMatchContainer" style="margin-top: 40px;">
                <div>
                    Use the box below to match this row to a booking
                </div>

                <span>
                    <button mat-button color="primary" type="button" class="prefixButton" (click)="searchPrefixOnOff()"
                        [disableRipple]="true">
                        <mat-icon style="margin: auto;" *ngIf="prefixOn">chevron_left</mat-icon>
                        <mat-icon style="margin: auto;" *ngIf="!prefixOn">chevron_right</mat-icon>
                    </button>

                    <mat-form-field class="searchBooking" floatLabel="never">
                        <span matPrefix *ngIf="prefixOn">{{ bookRefPrefix | uppercase }}-</span>
                        <mat-label>Search</mat-label>
                        <input matInput ngModel type="text" name="bookingReference" #bookingReference="ngModel"
                            [maxlength]="maxBkLength" [(ngModel)]="bookRefSearch">
                    </mat-form-field>

                    <span *ngIf="innerWidth > 520">
                        <button mat-button color="accent" type="submit" class="topNavIconButtons" id="searchButton"
                            (click)="searchForMatchManually()" [disableRipple]="true">
                            <mat-icon style="margin: auto;">search</mat-icon>
                        </button>
                    </span>
                </span>
            </div>
        </div>

    </mat-dialog-content>
</ng-template>

<ng-template #commentDialog>
    <mat-dialog-content>

        <form ngNativeValidate #setCommentForm="ngForm" (ngSubmit)="commentSelectedStmntID(selectedBankRow, setCommentForm, null)">

            <div style="font-size: 16px; margin-bottom: 20px;">
                <p>Use form below to set custom 'Matched' messsage (wording TBD)
                </p>
                <p></p>
            </div>

            <div>
                <div style="margin-bottom: 16px;">
                    <mat-form-field appearance="fill" class="filterSelectList">
                        <input placeholder="Comment" matInput name="comment" maxlength="100" #comment="ngModel" required
                            [(ngModel)]="selectedBankRow.matchFinal">
                    </mat-form-field>
                </div>

                <div>
                    <button class="buttonToRight" mat-button [disableRipple]="true" [disabled]="setCommentForm.invalid"
                        type="submit" matDialogClose style="height: 77px;">
                        <mat-icon>note_add</mat-icon> Submit
                    </button>
                </div>
            </div>
        </form>

    </mat-dialog-content>
</ng-template>

<ng-template #synchDialog>
    <mat-dialog-content>

        <form ngNativeValidate #pullBankingForm="ngForm" (ngSubmit)="pullRawDataFromFelloh(pullBankingForm)">

            <div style="font-size: 16px; margin-bottom: 20px;">
                <p>Choose a banking date from the options below in order to retrieve the most recent data from your bank
                </p>
                <p>The system will attempt to automatically match new lines with bookings already registered in the
                    system</p>
                <p></p>
            </div>

            <div>
                <div style="margin-bottom: 10px;">
                    <mat-form-field style="width: 100%;">
                        <input ngModel required matInput name="pullDate" [matDatepicker]="pullDate"
                            placeholder="Banking Date">
                        <mat-datepicker-toggle matSuffix [for]="pullDate"></mat-datepicker-toggle>
                        <mat-datepicker #pullDate></mat-datepicker>
                    </mat-form-field>
                </div>

                <div>
                    <button class="buttonToRight" mat-button [disableRipple]="true" [disabled]="pullBankingForm.invalid"
                        type="submit" matDialogClose style="height: 77px;">
                        <mat-icon>synch</mat-icon> Load
                    </button>
                </div>
            </div>
        </form>

    </mat-dialog-content>
</ng-template>

<ng-template #bookingExternalBox style="padding: 0px !important;">
    <app-booking-external [bookingOperation]="'View'" [bookingReference]="bookingRefExternal" [userTypeOut]="userType"
        (selectedRec)="selectManualMatch($event)"></app-booking-external>
</ng-template>

<ng-template #rawStmntDialog>
    <mat-dialog-content>
        <div>
            <div style="margin-bottom: 10px;">
                <div class="mainMatchDescription">
                    <h3>{{ selectedRawRow.description }}</h3>
                </div>

                <div style="text-align: center;" class="mainMatchAmounts">
                    <h4>Appeared on<br>{{ selectedRawRow.posting_date | date: 'dd.MM.yyyy' }}</h4>
                </div>

                <div class="mainMatchAmounts">
                    <div>
                        <h4>Debit: {{ selectedRawRow.debit | currency: selectedRawRow.currency }}</h4>
                    </div>
                    <div>
                        <h4>Credit: {{ selectedRawRow.credit | currency: selectedRawRow.currency }}</h4>
                    </div>
                </div>

            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #myDialog>
    <div *ngIf="errorMessage != ''" class="errorMessage">
        <p>{{errorMessage}}</p>
        <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary"
                matDialogClose>OK</button></p>
    </div>

    <div *ngIf="successMessage != ''" class="successMessage">
        <p>{{successMessage}}</p>
        <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary"
                matDialogClose>OK</button></p>
    </div>
</ng-template>

<ng-template #helpDialog>
    <mat-dialog-content>
        <h1 style="margin-top: 0px;"><u>Help coming soon..</u></h1>

        <p style="margin: auto; text-align: center; margin-bottom: 0px;"><button mat-raised-button color="primary"
                matDialogClose>OK</button></p>
    </mat-dialog-content>
</ng-template>