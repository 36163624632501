import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../common/global-constants';
import { environment } from './../../../environments/environment';
import { Session } from '../../common/session';
import { HostListener } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { UserService } from '../../services/user.service';
import { FellohService } from '../../services/felloh.service';
import { BranchService } from '../../services/branch.service';

import { AppComponent } from '../../app.component';

import { animate, state, style, transition, trigger } from '@angular/animations';
import * as moment from 'moment';


@Component({
  selector: 'app-felloh-transactions',
  templateUrl: './felloh-transactions.component.html',
  styleUrls: ['./felloh-transactions.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', marginTop: '-1.25px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})

export class FellohTransactionsComponent implements OnInit, AfterViewInit {
  // Imported variables from outside
  constants = new GlobalConstants();
  innerWidth = AppComponent.myapp.innerWidth;

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild('chart', { static: false }) chart: any;
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;
  @ViewChild('paymentDialog') paymentDialog!: TemplateRef<any>;

  // Table data and table columns below
  fellohData: any = new MatTableDataSource<any>();
  fellohColumns = ['custName', 'bookingReference', 'type', 'method', 'amount', 'createdDate', 'status'];
  fellohColumnsSurcharge = ['custName', 'bookingReference', 'type', 'method', 'amount', 'surcharge', 'createdDate', 'status'];

  // Variables controlling user access
  userType = '';
  haveAccess = false;
  pageLoaded = false;
  adminJustOpened = false;

  // Variables controlling side-nav view
  overView = false;
  transactionView = true;

  // Variables controlling Felloh getTransactionList() API
  pageSize = 25;
  pageNumber = 0;
  fromDate: any = '';
  toDate: any = '';
  filterValue: any = '';

  // Variables dealing with the multi-currency behaviour
  selectedCurrency: any = 'GBP';
  chartCurrencies: any = [];

  // Other variables
  selectedPayment: any = {};
  fellohTxnsDummyData: any = [];
  paymentNumber: any = 0;
  totalBalance: any = 0;
  errorMessage: any = '';
  successMessage: any = '';

  // Variables used for Admin Only
  branchListData: any = []; // Holds data of all branches
  branchListFiltered: any = []; // Holds data filtered by the company (TTNG/GTG etc..)
  filterString: any = ''; // String used in filtering out / in to filterInBranches variable
  selectedBranch: any = null;
  selectedFellohAccount: any = null;

  // Main chart options below - initially without any data
  public chartOptions: Partial<any> = {
    series: [{ name: '', data: [] },
    { name: '', data: [] }],
    chart: {
      height: 375,
      type: 'area',
      zoom: {
        enabled: false
      },
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
        animateGradually: {
          enabled: false,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 500
        }
      }
    },
    colors: ['#4D5FD1', '#b0b0b0'],
    dataLabels: {
      enabled: false,
    },
    title: {
      text: 'Sales',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      labels: {
        formatter: (value: any) => {
          return this.constants.getChartsValue(this.selectedCurrency, value);
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '100%',
        endingShape: 'rounded'
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
  };

  constructor(private router: Router, private userService: UserService, private fellohService: FellohService,
              private branchService: BranchService, public dialog: MatDialog) { }

  ngOnInit(): void {
    if (sessionStorage.length === 0 || Session.mySession === undefined) {
      sessionStorage.clear();
      this.router.navigate(['/login']);
    } else {
      // Browser wasn't refreshed but the button was clicked so the user could get here - no API need to be called
      this.userType = Session.mySession.getUser().userType;
      this.setStartingDates(); // Call funciton which sets fromDate <-> toDate values..

      if (this.userType === 'sinGSAdmin' || this.userType === 'sinGSstaff' || this.userType === 'trustee') {
        // SinGS Admin and Staff are able to retrieve transactions from all of the branches.
        if (Session.mySession.getBranchList().expiryTime === 'EXPIRED') {
          this.branchService.getBranches(Session.mySession.getUser()).then((branches: any) => {
            if (branches.status === 'OK') {
              // Sort branch list A to Z and save it in the session first..
              const sorted = branches.data.sort((a: any, b: any) => (a.fullName > b.fullName) ? 1 : -1);
              Session.mySession.setBranchList(sorted);
              // Filter out all branches with an empty Felloh account ID
              // Change Q0000 branch name to '-- All branches --' and sort once again..
              this.branchListData = sorted.filter((branch: any) => branch.fellohSetup === 'yes');
              const allBranch = this.branchListData.find((branch: any) => (branch.tradeCode === 'Q0000')); allBranch.fullName = '-- All Branches --';
              this.branchListData.sort((a: any, b: any) => (a.fullName > b.fullName) ? 1 : -1);
              // Re-assign for manual filtering and change branch to Q0000 [-- All branches --]
              this.branchListFiltered = this.branchListData; // It needs to be the same list
              this.changeBranch(allBranch, 'no'); // Call this to change current branch
            } else {
              this.sendMessageToDialog('', branches.status, '', '');
            }
          }).catch((error: any) => {
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2601S)', error, Session.mySession.getUser());
          });
        } else {
          // Filter out all branches with an empty Felloh account ID
          this.branchListData = Session.mySession.getBranchList().branchList.filter((branch: any) => branch.fellohSetup === 'yes');
          // Change Q0000 branch name to '-- All branches --' and sort once again..
          const allBranch = this.branchListData.find((branch: any) => (branch.tradeCode === 'Q0000')); allBranch.fullName = '-- All Branches --';
          this.branchListData.sort((a: any, b: any) => (a.fullName > b.fullName) ? 1 : -1);
          // Re-assign for manual filtering and change branch to Q0000 [-- All branches --]
          this.branchListFiltered = this.branchListData; // It needs to be the same list
          this.changeBranch(allBranch, 'no'); // Call this to change current branch
        }
      } else {
        if (Session.mySession.getUsersGroup().length > 0) {
          // Logged in user is in the group and branch list already exists within the session variable..
          this.branchListData = Session.mySession.getUsersGroup();
          this.branchListFiltered = this.branchListData; // It needs to be the same list
          if (Session.mySession.get('createdPayment') === true) {
            // Payment has just been created - we need to set the branch the payment was created to first
            this.changeBranch(this.branchListData.filter((branch: any) => branch.tradeCode === Session.mySession.get('paymentBranch'))[0], 'yes');
          } else {
            // Multi-user branch just opened the page - set its default branch for start
            this.changeBranch(this.branchListData.filter((branch: any) => branch.tradeCode === Session.mySession.getUser().tradeCode)[0], 'yes');
          }
        } else {
          // Non-multi user accessed the page - set its branch (felloh acc id etc.)
          this.changeBranch(Session.mySession.getBranch(), 'yes');
        }
      }
    }
  }

  ngAfterViewInit(): void {
    // Remove paginator's mat tooltips (unnecesary)
    const paginatorIntl = this.paginator._intl;
    paginatorIntl.firstPageLabel = '';
    paginatorIntl.nextPageLabel = '';
    paginatorIntl.previousPageLabel = '';
    paginatorIntl.lastPageLabel = '';
  }

  setStartingDates(): void {
    this.haveAccess = true;
    // Calculate today's date for yyyy-mm-dd format
    const today: any = new Date(); // today.setDate(today.getDate() - 94); // Remove comment when testing..
    const weekBefore = new Date(); weekBefore.setDate(weekBefore.getDate() - 7); // Change to 94 when testing..
    // Assign dates to the global dates which will be changed later on by the user
    this.toDate = this.constants.convertDateNotMoment(today);
    this.fromDate = this.constants.convertDateNotMoment(weekBefore);
  }

  changeDate(dateType: any, date: any): void {
    // Translate for Felloh-friendly date and reload Felloh data
    if (dateType === 'fromDate') {
      if (date.value != null) {
        this.fromDate = this.constants.convertDateMoment(date.value);
      }

      if (this.toDate._i !== undefined) {
        this.toDate = this.constants.convertDateMoment(this.toDate);
      }
    } else if (dateType === 'toDate') {
      if (date.value != null) {
        this.toDate = this.constants.convertDateMoment(date.value);

        if (this.fromDate._i !== undefined) {
          this.fromDate = this.constants.convertDateMoment(this.fromDate);
        }
      }
    }
    this.adminJustOpened = false; // Changing dates will disable the justOpened admin case
    // Below we're checking the number of the days the request is made for
    // We don't to allow huge requests (e.g. a year worth of data) due to server limits
    const diffInDays = Math.ceil((new Date(this.toDate).getTime() - new Date(this.fromDate).getTime()) / (1000 * 60 * 60 * 24));

    // If the number is above the treshold, we'll set the other date type automatically
    // The new date will be !dateType (othertype) +/- threshold
    if (diffInDays > 30 && dateType === 'fromDate') {
      const toDate = new Date(this.fromDate); toDate.setDate(toDate.getDate() + 30);
      this.toDate = this.constants.convertDateNotMoment(toDate);
    } else if (diffInDays > 30 && dateType === 'toDate') {
      const fromDate = new Date(this.toDate); fromDate.setDate(fromDate.getDate() - 30);
      this.fromDate = this.constants.convertDateNotMoment(fromDate);
    }

    // Make sure none of these two are null (non-date value entered in the field)
    if (this.toDate != null && this.fromDate != null) {
      // Check if the date is in the right format here
      if (moment(this.fromDate, 'YYYY-MM-DD', true).isValid() && moment(this.toDate, 'YYYY-MM-DD', true).isValid()) {
        this.pageLoaded = false;
        this.prepareForFellohTxns();
      } else {
        this.sendMessageToDialog('', 'One of the dates was in the wrong format', '', '');
        this.setStartingDates();
      }
    } else {
      this.sendMessageToDialog('', 'One of the dates was in the wrong format', '', '');
      this.setStartingDates();
    }
  }

  changeBranch(branch: any, loadTxns: any): void {
    // Function called whenver SinGS Admin/Staff changes the branch from select list
    this.pageLoaded = false;
    if (branch !== undefined) {
      Session.mySession.setFellohAdmin(branch.fellohAccountId);
      this.selectedBranch = branch;
      this.selectedFellohAccount = branch?.fellohConfig[0];
    } else {
      Session.mySession.setFellohAdmin('');
      this.selectedBranch = branch;
      this.selectedFellohAccount = branch?.fellohConfig[0];
    }
    this.fellohData.data = []; this.chartCurrencies = []; this.selectedCurrency = 'GBP';
    // Reload felloh if loadTxns is set to 'yes'. Otherwise - we'll need user to do it themselves (saves Felloh money)
    if (loadTxns === 'yes') {
      this.adminJustOpened = false; // Second (or further) reload removes 'justOpened' which will remove unnecessary pre-load
      this.prepareForFellohTxns().then(() => { });
    } else { this.pageLoaded = true; this.adminJustOpened = true; }
  }

  prepareForFellohTxns(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Reset total balance & charges to £0 and recalculate it again
      this.totalBalance = 0; this.paymentNumber = 0;
      // Reset dummy array to nothing - we'll need to re-calculate stuff once again
      this.pageNumber = 0; this.fellohTxnsDummyData = [];
      this.filterData(''); // Clear the filter below - much needed indeed!
      // Call main function below (where there may be loop within the loop)
      if (this.selectedBranch.fellohSetup === 'yes' && this.selectedBranch?.fellohConfig?.length > 0) {
        this.reloadFelloh().then(() => { resolve(''); });
      } else { this.pageLoaded = true; resolve(''); }
    });
  }

  reloadFelloh(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.selectedBranch.fullName !== '-- All Branches --') {
        // Function called whenever date range or branch has been changed in UI
        // Create Felloh request hash to request access token and call the API
        Session.mySession.fetchFellohAuthorisation(this.fellohService).then((tokenOut: any) => {
          // Create a Felloh payment request hash to which will be used to retrieve Felloh transactions list
          const paymentRequest = {
            organisation: this.constants.getFellohCodeMapV2(this.selectedFellohAccount.accountCode),
            skip: this.pageNumber * this.pageSize, take: this.pageSize,
            date_from: this.fromDate, date_to: this.toDate,
          };

          this.fellohService.fetchPayments(paymentRequest, tokenOut).then((fellohList: any) => {
            let filteredList: any = []; // Assign empty array here

            // Staff (non-managers) will only be able to see payments made by themselves only
            if (this.userType === 'wcMember' || this.userType === 'memberStaff') {
              filteredList = fellohList.data.filter((txn: any) => txn.metadata.requestCreator === Session.mySession.getUser().email);
            } else {
              filteredList = fellohList.data;
            }

            filteredList.forEach((element: any) => {
              if (element.currency === 'GBX') { element.currency = 'GBP'; element.amount = element.amount / 100; }
              else if (element.currency === 'USX') { element.currency = 'USD'; element.amount = element.amount / 100; }
              else if (element.currency === 'EUX') { element.currency = 'EUR'; element.amount = element.amount / 100; }
              // Below are being assigned for filtering by string
              element.bookingReference = element.booking?.booking_reference;
              element.customerName = element.booking?.customer_name;

              // Deal with surcharges (if they exist..)
              if (element.surcharge) {
                if (element.surcharge.currency === 'GBX') { element.surcharge.currency = 'GBP'; element.surcharge.amount = element.surcharge.amount / 100; }
                else if (element.surcharge.currency === 'USX') { element.surcharge.currency = 'USD'; element.surcharge.amount = element.surcharge.amount / 100; }
                else if (element.surcharge.currency === 'EUX') { element.surcharge.currency = 'EUR'; element.surcharge.amount = element.surcharge.amount / 100; }
                // Deduct the surcharge from the total value of payment..
                element.amount = element.amount - element.surcharge.amount;
              }
            });

            // Merge output into one variable here (in case there's more than 25 payments within date range)
            this.fellohTxnsDummyData = this.fellohTxnsDummyData.concat(filteredList);

            // In case there's more payments than 25, increase add another page and re-do the method
            if (fellohList.meta.count > (this.pageNumber + 1) * this.pageSize) {
              this.pageNumber = this.pageNumber + 1;
              this.reloadFelloh(); // Call yourself once again
            } else {
              // Assign transaction list and paginator to the data table variable
              this.fellohData.data = this.fellohTxnsDummyData;
              this.fellohData.paginator = this.paginator;
              // Set possible currencies to choose from in the chart tab - the selected currency will be the first one available
              const dataCurrencies: any = [...new Set(this.fellohData.data.map((item: any) => item.currency))];
              if (!dataCurrencies.includes('GBP')) { dataCurrencies.push('GBP'); }
              this.chartCurrencies = dataCurrencies.sort((a: any, b: any) =>  (a > b ? 1 : -1));

              this.feedChartWithFelloh().then((out: any) => {
                this.pageLoaded = true;
                resolve('');
              });
            }
          }).catch((error: any) => {
            this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E2601F)', error, paymentRequest);
            resolve('');
          });
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E2602F)', error, '');
          resolve('');
        });
      } else {
        this.pageLoaded = true;
        resolve(true);
      }
    });
  }

  feedChartWithFelloh(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Convert date range using 'moment' function
      const fromDate = moment(this.fromDate);
      const toDate = moment(this.toDate);

      let sumDone = 0;
      let sumPending = 0;
      // Empty array lists for filtered payments
      let payments: any = [];
      let refunds: any = [];
      // Empty array lists for series objects
      const updateSeriesPayments: any = [];
      const updateSeriesRefunds: any = [];

      // Get the number of payments with selected currency (GBP / USD..)
      this.paymentNumber = this.fellohTxnsDummyData.filter((txn: any) => txn.currency === this.selectedCurrency).length;
      // Calculate the total balance with selected currency (GBP / USD..)
      this.totalBalance = this.fellohTxnsDummyData.filter((txn: any) => txn.currency === this.selectedCurrency).reduce((a: any, b: any) => a + (b.amount || 0), 0);

      // SinGS needs to go through each day within date range and check for the payment within it
      for (const day = moment(fromDate); day.diff(toDate, 'days') <= 0; day.add(1, 'days')) {
        // Create series object which will store x - date and y - total amount (££££)
        const seriesObjectPayment: any = { x: '', y: '' };
        const seriesObjectRefund: any = { x: '', y: '' };
        seriesObjectPayment.x = day.format('YYYY-MM-DD');
        seriesObjectRefund.x = day.format('YYYY-MM-DD');

        // Filter out/in temporary references from transaction list and assign results into variables below
        payments = this.fellohData.data.filter((txn: any) =>
        txn.created_at.includes(day.format('YYYY-MM-DD')) && txn.currency === this.selectedCurrency && txn.status !== 'REFUNDED');
        // Temporary references are below and non-TEMP above - simple..
        refunds = this.fellohData.data.filter((txn: any) =>
        txn.created_at.includes(day.format('YYYY-MM-DD')) && txn.currency === this.selectedCurrency && txn.status === 'REFUNDED');

        if (payments.length > 0) {
          // Add up all of the payments' values together and assign it to series y variable
          seriesObjectPayment.y = payments.reduce((sum: any, current: any) => sum + current.amount, 0).toFixed(2);
          sumDone = sumDone + Number(seriesObjectPayment.y);
        } else {
          seriesObjectPayment.y = 0;
        }

        if (refunds.length > 0) {
          // Add up all of the payments' values together and assign it to series y variable
          seriesObjectRefund.y = refunds.reduce((sum: any, current: any) => sum + current.amount, 0).toFixed(2);
          sumPending = sumPending + Number(seriesObjectRefund.y);
        } else {
          seriesObjectRefund.y = 0;
        }
        // Add series object into array list
        updateSeriesPayments.push(seriesObjectPayment);
        updateSeriesRefunds.push(seriesObjectRefund);
      }
      // Update chart series with series objects created earlier for temp/non-temp references
      this.chartOptions.series = [{ name: 'Payments', data: updateSeriesPayments }, { name: 'Refunds', data: updateSeriesRefunds }];
      resolve('');
    }).catch((error: any) => {
      this.sendMessageToDialog('', '', error, 'Home Component - feedChartWithFelloh() failed');
    });
  }
  
  showTxnDetails(fellohTxn: any): void {
    // Function called whenever date range or branch has been changed in UI
    // Create Felloh request hash to request access token and call the API
    this.pageLoaded = false;
    Session.mySession.fetchFellohAuthorisation(this.fellohService).then((tokenOut: any) => {
      this.fellohService.fetchOnePayment(fellohTxn, tokenOut).then((res: any) => {
        if (res.meta.reason === 'OK') {

          if (res.data.currency === 'GBX') { res.data.currency = 'GBP'; res.data.amount = res.data.amount / 100; }
          else if (res.data.currency === 'USX') { res.data.currency = 'USD'; res.data.amount = res.data.amount / 100; }
          else if (res.data.currency === 'EUX') { res.data.currency = 'EUR'; res.data.amount = res.data.amount / 100; }

          res.data.refund.forEach((refund: any) => {
            refund.amount = refund.amount / 100; // Felloh returns amount in 0.01
          });
          // Deal with surcharges (if they exist..)
          if (res.data.surcharge) {
            if (res.data.surcharge.currency === 'GBX') { res.data.surcharge.currency = 'GBP'; res.data.surcharge.amount = res.data.surcharge.amount / 100; }
            else if (res.data.surcharge.currency === 'USX') { res.data.surcharge.currency = 'USD'; res.data.surcharge.amount = res.data.surcharge.amount / 100; }
            else if (res.data.surcharge.currency === 'EUX') { res.data.surcharge.currency = 'EUR'; res.data.surcharge.amount = res.data.surcharge.amount / 100; }
            // Deduct the surcharge from the total value of payment..
            res.data.amount = res.data.amount - res.data.surcharge.amount;
          }

          this.pageLoaded = true; this.selectedPayment = res.data; // Page loaded - assign our payment to the variable
          this.dialog.open(this.paymentDialog, { autoFocus: false,  disableClose: false, panelClass: 'paymentDialog' });
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E2603F)', error, fellohTxn);
      });
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E2602F)', error, '');
    });
  }

  changeChartCurrency(currency: any): void {
    this.selectedCurrency = currency; // Change global currency values
    this.feedChartWithFelloh();
  }

  filterData(event: any): void {
    // Receive the value used for filtering Felloh data
    this.filterValue = event?.target?.value;
    // We're filtering all of data sources below
    this.fellohData.filter = this.filterValue;
  }

  switchView(view: any): void {
    // Nothing special to add. Whenever button on left nav-bar is clicked, change the view variables values.
    this.filterData(''); // Reset filtering whenever the tab is changed
    if (view === 'overwiev') {
      this.overView = true;
      this.transactionView = false;
    } else if (view === 'transaction') {
      this.overView = false;
      this.transactionView = true;
    } else if (view === 'reloadView') {
      this.pageLoaded = false;
      this.adminJustOpened = false;
      this.prepareForFellohTxns();
    }
  }

  exportToExcel(): void {
    const exportMe: any = [];
    let oneLine: any = {};

    // Depending on what page we're trying to export, we'll be using different
    // variable storing data. An object represents one line which are then pushed
    // into array list. That represents excel sheet in short
    this.fellohData.data.forEach((data: any) => {
      oneLine = {};
      oneLine.paymentId = data.id;
      oneLine.tradeCode = data.organisation.id;
      oneLine.branchName = data.organisation.name;
      oneLine.bookingReference = data.booking.booking_reference;
      oneLine.customerName = data.booking.customer_name;
      if (data.metadata) {
        oneLine.paymentType = data.metadata.payment_brand + ' ' + data.metadata.card_type
        oneLine.method = data.method;
        oneLine.cardHolderName = data.metadata.cardholder_name;
        oneLine.lastFourDigits = data.metadata.last_four_digits;
      } else if (data.type === 'OPEN_BANKING') {
        oneLine.paymentType = 'OPEN BANKING';
        oneLine.method = data.method;
        oneLine.cardHolderName = '';
        oneLine.lastFourDigits = '';
      }
      oneLine.currency = data.currency;
      // Deal with surcharging..
      if (this.selectedBranch.fellohSurcharging == 'yes') {
        oneLine.amount = data.amount;

        if (data.surcharge) {
          oneLine.surcharge = data.surcharge.amount;
          oneLine.totalAmount = data.amount + data.surcharge.amount;
        } else {
          oneLine.surcharge = '0';
          oneLine.totalAmount = data.amount;
        }
        
      } else {
        oneLine.amount = data.amount;
      }
      oneLine.createdDate = data.created_at;
      oneLine.status = data.status;

      exportMe.push(oneLine);
    });
    this.constants.exportAsExcelFile(exportMe, 'fellohTransactions');
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  filterSelect(): void {
    this.branchListFiltered = []; // Empty filtered array first
    const filter = this.filterString.toLowerCase(); // Get the string we filter with here

    // Loop through our MAIN array and add whatever matches our search string
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.branchListData.length; i++) {
      const option = this.branchListData[i];
      if (option.fullName.toLowerCase().indexOf(filter) >= 0) {
        this.branchListFiltered.push(option);
      }
    }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // Check if the error comes from Felloh and if we can display it to the user
      // If we can then let's display it rather than give them weird code..
      if (error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
        failureMessage = error.error.message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      } else if (error.hasOwnProperty('error') && error.error.hasOwnProperty('error') && error.error.error.hasOwnProperty('message')) {
        failureMessage = error.error.error.message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      } else if (error.hasOwnProperty('error') && error.error.hasOwnProperty('errors') && error.error.errors.length > 0) {
        failureMessage = error.error.errors[0].message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      }
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    this.pageLoaded = true; // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    // Pop-up message only appears if either success or error message is not empty
    if (this.successMessage !== '' || this.errorMessage !== '') { this.dialog.open(this.statusDialog); }
  }

  showHelp(): void {
    // (window as any).Beacon('suggest', ['6245acd5ab585b230a8a708c', '624c0ff0ec0e757ba5af1fc2']);
    (window as any).Beacon('toggle'); // Open Help Scout
  }
}
