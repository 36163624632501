<div *ngIf="!pageLoaded" class="loading-indicator" style="margin:0 auto;">
  <mat-spinner></mat-spinner>
</div>

<mat-card-content>
  <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
    <div [@inAnimation] *ngIf="innerWidth > 1024 && haveAccess" style="width: 100%;">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('overwiev')" mat-button [disabled]="overView">
          <mat-icon>visibility</mat-icon> Overview
        </button>
        <button class="navButtons" (click)="switchView('transaction')" mat-button [disabled]="transactionView">
          <mat-icon matBadge="{{fellohData.data.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="primary">reorder</mat-icon> Payment Links
        </button>
        <button class="navButtons" (click)="switchView('pending')" mat-button [disabled]="pendingView">
          <mat-icon matBadge="{{paymentsDueData.data.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent">hourglass_bottom</mat-icon> Pending Payments
        </button>
        <button class="navButtons" (click)="switchView('temporary')" mat-button [disabled]="temporaryView">
          <mat-icon matBadge="{{overdueData.data.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent">update</mat-icon> Temporary References
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon> Help
        </button>
      </div>
    </div>

    <div [@inAnimation] *ngIf="innerWidth < 1025 && haveAccess" style="width: 100%">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('overwiev')" mat-button [disabled]="overView">
          <mat-icon>visibility</mat-icon>
        </button>
        <button class="navButtons" (click)="switchView('transaction')" mat-button [disabled]="transactionView">
          <mat-icon matBadge="{{fellohData.data.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="primary">reorder</mat-icon>
        </button>
        <button class="navButtons" (click)="switchView('pending')" mat-button [disabled]="pendingView">
          <mat-icon matBadge="{{paymentsDueData.data.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent">hourglass_bottom</mat-icon>
        </button>
        <button class="navButtons" (click)="switchView('temporary')" mat-button [disabled]="temporaryView">
          <mat-icon matBadge="{{overdueData.data.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent">update</mat-icon>
        </button>
        <button class="navButtons" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav mode="side" #drawer closed>

    </mat-sidenav>

    <mat-sidenav-content>
      <mat-card [style.overflow]="'auto'">
        <div [@inAnimation] class="sideNavContent" *ngIf="haveAccess">
          <div class="headerArrow" *ngIf="overView">
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Overview
            </h1>
          </div>
          <div class="headerArrow" *ngIf="transactionView">
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Payment Links
            </h1>
          </div>
          <div class="headerArrow" *ngIf="pendingView">
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Pending Payments
            </h1>
          </div>
          <div class="headerArrow" *ngIf="temporaryView">
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Temporary References
            </h1>
          </div>
          <mat-divider></mat-divider>

          <div class="dates">
            <div [ngClass]="branchListData.length > 1 ? 'datesAndDownload' : 'datesAndDownloadLong'">
              <mat-form-field style="width: 110px;">
                <input placeholder="From" matInput [(ngModel)]="fromDate" [matDatepicker]="fromDateSet" (dateChange)="changeDate('fromDate', $event)">
                <mat-datepicker-toggle matSuffix [for]="fromDateSet"></mat-datepicker-toggle>
                <mat-datepicker #fromDateSet></mat-datepicker>
              </mat-form-field>

              <mat-form-field style="max-width: 110px;">
                <input placeholder="To" matInput [(ngModel)]="toDate" [matDatepicker]="toDateSet" (dateChange)="changeDate('toDate', $event)">
                <mat-datepicker-toggle matSuffix [for]="toDateSet"></mat-datepicker-toggle>
                <mat-datepicker #toDateSet></mat-datepicker>
              </mat-form-field>

              <mat-form-field style="max-width: unset; width: 240px;" *ngIf="branchListData.length > 1">
                <mat-select ngModel placeholder="Branch" name="branch" [(ngModel)]="selectedBranch" id="branch" #branch="ngModel" (ngModelChange)="changeBranch($event, 'view', 'yes')">
                  <mat-form-field appearance="fill" class="filterSelectList">
                    <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                  </mat-form-field>
                  <mat-option *ngFor="let branch of branchListFiltered" [value]="branch" (click)="filterString = ''; filterSelect();">
                    {{branch['fullName']}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field style="max-width: unset; width: 100px;">
                <mat-select ngModel placeholder="Status" name="status" id="status" #status="ngModel" (ngModelChange)="changeStatus(status.value)">
                  <mat-option [value]="''">All</mat-option>
                  <mat-option [value]="'createdStatus'">Created</mat-option>
                  <mat-option [value]="'receivedStatus'">Received</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field [hidden]="overView" class="filterFelloh">
                <input matInput (keyup)="filterData($event)" placeholder="Filter Transactions" [(ngModel)]="filterValue">
              </mat-form-field>

              <button *ngIf="transactionView" mat-stroked-button color="primary" (click)="exportToExcel('allPayments')" style="margin-top: -4px;" matTooltip="Download XLSX" matTooltipClass="line-normal-tooltip">
                <mat-icon>file_download</mat-icon>
              </button>
              <button *ngIf="temporaryView" mat-stroked-button color="primary" (click)="exportToExcel('overduePayments')" style="margin-top: -4px;" matTooltip="Download XLSX" matTooltipClass="line-normal-tooltip">
                <mat-icon>file_download</mat-icon>
              </button>
              <button *ngIf="pendingView" mat-stroked-button color="primary" (click)="exportToExcel('duePayments')" style="margin-top: -4px;" matTooltip="Download XLSX" matTooltipClass="line-normal-tooltip">
                <mat-icon>file_download</mat-icon>
              </button>

              <button style="margin-top: -4px; margin-left: 10px" (click)="switchView('reloadView')" mat-stroked-button color="primary" matTooltip="Reload Transactions" matTooltipClass="line-normal-tooltip">
                <mat-icon>sync</mat-icon>
              </button>

              <button *ngIf="userType == 'wcMember' || userType == 'wcManager' || userType == 'memberManager' || userType == 'memberStaff'" style="margin-top: -4px; margin-left: 10px" color="accent" routerLink="/newLink"
                matTooltip="New Transaction" matTooltipClass="line-normal-tooltip" mat-raised-button>
                <mat-icon style="margin-left: 3.5px;">playlist_add</mat-icon>
              </button>
            </div>

            <div [ngClass]="branchListData.length > 1 ? 'paginatorFooter' : 'paginatorFooterLong'"  style="padding-top: 7.5px !important;" [hidden]="!transactionView">
              <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
            </div>

            <div [ngClass]="branchListData.length > 1 ? 'paginatorFooter' : 'paginatorFooterLong'"  style="padding-top: 7.5px !important;" [hidden]="!temporaryView">
              <mat-paginator #paginatorOverdue [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
            </div>

            <div [ngClass]="branchListData.length > 1 ? 'paginatorFooter' : 'paginatorFooterLong'"  style="padding-top: 7.5px !important;" [hidden]="!pendingView">
              <mat-paginator #paginatorDue [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
            </div>
          </div>

          <div *ngIf="overView && chartCurrencies.length > 1" style="clear: both; display: flex; justify-content: center;">
            <div *ngFor="let currency of chartCurrencies">
              <button style="margin-top: -4px; margin-left: 10px; margin-right: 10px;"
              color="primary" mat-raised-button [disabled]="currency == selectedCurrency"
              (click)="changeChartCurrency(currency)">
                {{currency}}
              </button>
            </div>
          </div>

          <div [@inAnimation] *ngIf="overView && pageLoaded">
            <div style="clear: both;" fxLayout="row" fxLayoutAlign="space-between center">
              <div fxFlex="71.5">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div class="topBoxes">
                    <h1>Payments<br>{{ paymentNumber }}</h1>
                  </div>
                  <div class="topBoxes">
                    <h1>Average Sales<br>{{ totalBalance / paymentNumber | currency:selectedCurrency }}</h1>
                  </div>
                  <div class="topBoxes" style="float: left;">
                    <h1>Total Sales<br>{{ totalBalance | currency:selectedCurrency }}</h1>
                  </div>
                </div>

                <div class="apexChartZoom">
                  <apx-chart id="chart" [series]="chartOptions.series" [chart]="chartOptions.chart" [fill]="chartOptions.fill" [grid]="chartOptions.grid" [dataLabels]="chartOptions.dataLabels" [plotOptions]="chartOptions.plotOptions"
                    [xaxis]="chartOptions.xaxis" [yaxis]="chartOptions.yaxis" [legend]="chartOptions.legend" [colors]="chartOptions.colors"></apx-chart>
                </div>
              </div>

              <div fxFlex="27">
                <div style="text-align: center; margin: auto;" class="apexChartZoom">
                  <apx-chart id="chartDonut" [series]="pieChartOptions.series" [chart]="pieChartOptions.chart" [fill]="pieChartOptions.fill" [labels]="pieChartOptions.labels" [dataLabels]="pieChartOptions.dataLabels"
                    [plotOptions]="pieChartOptions.plotOptions" [xaxis]="pieChartOptions.xaxis" [yaxis]="pieChartOptions.yaxis" [legend]="pieChartOptions.legend" [colors]="pieChartOptions.colors"></apx-chart>
                </div>
              </div>
            </div>

            <img src="../../../assets/singsLogoMinix75.png" style="margin-top: -2.5px; margin-right: -10px; opacity: 0.25; float: right;">
          </div>

          <div [@inAnimation] *ngIf="transactionView && pageLoaded" style="clear: both; margin-bottom: 100px;">
            <div *ngIf="fellohData.data.length > 0">
              <div class="table-responsive">
                <mat-table class="tableClass" multiTemplateDataRows [dataSource]="fellohData">
                  <ng-container matColumnDef="custName">
                    <mat-header-cell *matHeaderCellDef>Customer</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.customer.name }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="bookingReference">
                    <mat-header-cell *matHeaderCellDef>Reference No.</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.merchantRequestId }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="method">
                    <mat-header-cell *matHeaderCellDef>Method</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                      <span *ngIf="data.paymentMethod == 'CardMotoInPerson'">Card Moto <span matTooltip="In Person" matTooltipClass="line-normal-tooltip">(IP)</span></span>
                      <span *ngIf="data.paymentMethod == 'CardMoto'">Card Moto</span>
                      <span *ngIf="data.paymentMethod == 'OpenBanking'">Open Banking</span>
                      <span *ngIf="data.paymentMethod == 'CardInPerson'">Card <span matTooltip="In Person" matTooltipClass="line-normal-tooltip">(IP)</span></span>

                      <span *ngIf="data.paymentMethod != 'CardMotoInPerson' && data.paymentMethod != 'CardMoto' &&
                      data.paymentMethod != 'OpenBanking' && data.paymentMethod != 'CardInPerson'">{{ data.paymentMethod }}</span>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="createdDate">
                    <mat-header-cell *matHeaderCellDef>Created Date</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.createdDate | date : "dd.MM.y hh:mm a" }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="amount">
                    <mat-header-cell *matHeaderCellDef>Amount</mat-header-cell>
                    <mat-cell *matCellDef="let data"><b>{{ data.amount | currency:data.currency }}</b></mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                    <mat-cell *matCellDef="let data">
                      <span *ngIf="data.status == 'Created'" style="color: rgb(128,180,221); font-weight: bold;">Created</span>

                      <span *ngIf="data.status == 'Pending' || data.status == 'PaymentInProgress'" style="color: rgb(242, 166, 58); font-weight: bold;">In Progress</span>

                      <span *ngIf="data.status == 'PaymentReceived' || data.status == 'PaymentCompleted' || data.status == 'PayoutComplete' || data.status == 'PaymentAuthorised' || data.status == 'AwaitingPayout' || data.status == 'ExternalSettlement'"
                        style="color: rgb(129,199,132); font-weight: bold;">Paid</span>

                      <span *ngIf="data.status == 'PaymentAbandoned'" style="color: rgb(244,67,54); font-weight: bold;">Abandoned</span>
                      <span *ngIf="data.status == 'PaymentDeclined'" style="color: rgb(244,67,54); font-weight: bold;">Declined</span>
                      <span *ngIf="data.status == 'PaymentRejected'" style="color: rgb(244,67,54); font-weight: bold;">Rejected</span>
                      <span *ngIf="data.status == 'PaymentTimeout'" style="color: rgb(244,67,54); font-weight: bold;">Timeout</span>
                      <span *ngIf="data.status == 'Expired'" style="color: rgb(244,67,54); font-weight: bold;">Expired</span>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="expand">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let data">
                      <mat-icon style="float: right;">expand_more</mat-icon>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="expandedDetail">
                    <mat-cell class="expandedDetail" *matCellDef="let data" [attr.colspan]="7">
                      <div style="width: 100%; margin-bottom: 5px;">
                        <div class="fellohExpandRow">
                          <div>
                            <div>
                              <h3>
                                <mat-icon style="color: #565656; margin-top: -1.5px;">info</mat-icon> Transaction Details
                              </h3>
                            </div>
                            <div class="fellohExpandRowColumn">
                              <div>ID</div><div>{{data.transactionId}}</div>
                            </div>
                            <div class="fellohExpandRowColumn">
                              <div>Created By</div><div>{{data.requestCreator}}</div>
                            </div>
                            <div class="fellohExpandRowColumn">
                              <div>Payment Description</div><div>{{data.paymentDescription}}</div>
                            </div>
                            <div class="fellohExpandRowColumn">
                              <div>Expiry Date</div><div>{{data.expiryDate | date : "dd.MM.y hh:mm a"}}</div>
                            </div>
                          </div>

                          <div *ngIf="data.cardDetails != undefined && data.cardDetails !== null && data.cardDetails.cardHolderName !== null">
                            <div>
                              <h3>
                                <mat-icon style="color: #565656; margin-top: -1.5px;">payment</mat-icon> Payment Details
                              </h3>
                            </div>
                            <div class="fellohExpandRowColumn">
                              <div>Cardholder Name</div><div>{{data.cardDetails.cardHolderName}}</div>
                            </div>
                            <div class="fellohExpandRowColumn">
                              <div>Card Type</div><div>{{ data.cardDetails.paymentBrand | titlecase }} {{ data.cardDetails.cardType | titlecase }}</div>
                            </div>
                            <div class="fellohExpandRowColumn">
                              <div>Last Four Digits</div><div>{{data.cardDetails.lastFourDigits}}</div>
                            </div>
                            <div class="fellohExpandRowColumn">
                              <div>Payment Date</div><div>{{data.paymentDate | date : "dd.MM.y hh:mm a"}}</div>
                            </div>
                          </div>

                          <div *ngIf="data.cardDetails == undefined || data.cardDetails == null || data.cardDetails.cardHolderName == null">
                            <div>
                              <h3>
                                <mat-icon style="color: #565656; margin-top: -1.5px;">payment</mat-icon> Payment Details
                              </h3>
                            </div>
                          </div>

                          <div style="display: flex; flex-direction: column; align-items: center;">
                            <div>
                              <h3>
                                <mat-icon style="color: #565656; margin-top: -1.5px;">settings</mat-icon> Transaction Settings
                              </h3>
                            </div>
                            <div class="fellohExpandRowColumn">
                              <div>
                                <button type="button" mat-stroked-button color="primary" (click)="duplicateFellohLink(data)" class="fellohSettingButtons"
                                [disabled]="!(!data.temporaryRequestIdFlag && data.merchantRequestId != undefined && data.merchantRequestId.substring(0,2) != 'FX' &&
                                              (data.status == 'PaymentReceived' || data.status == 'PaymentCompleted' || data.status == 'PayoutComplete' ||
                                               data.status == 'PaymentAuthorised' || data.status == 'ExternalSettlement')) ||
                                               userType == 'sinGSAdmin' || userType == 'sinGSstaff' || userType == 'trustee'">
                                  <mat-icon>content_copy</mat-icon> Duplicate
                                </button>
                              </div>
                            </div>

                            <div class="fellohExpandRowColumn">
                              <div>
                                <button type="button" mat-stroked-button color="primary" (click)="addFellohLinkToTemps(data)" class="fellohSettingButtons"
                                [disabled]="!(!data.temporaryRequestIdFlag && data.merchantRequestId != undefined) ||
                                               userType == 'sinGSAdmin' || userType == 'sinGSstaff' || userType == 'trustee'">
                                  <mat-icon>update</mat-icon> Update Reference
                                </button>
                              </div>
                            </div>
                            
                            <div class="fellohExpandRowColumn">
                              <div>
                                <button type="button" mat-stroked-button color="primary" (click)="emailConfirmationEmails(data)" class="fellohSettingButtons"
                                [disabled]="!(!data.temporaryRequestIdFlag && data.merchantRequestId != undefined && data.merchantRequestId.substring(0,2) != 'FX' &&
                                              (data.status == 'PaymentReceived' || data.status == 'PaymentCompleted' || data.status == 'PayoutComplete' ||
                                               data.status == 'PaymentAuthorised' || data.status == 'ExternalSettlement')) ||
                                               userType == 'sinGSAdmin' || userType == 'sinGSstaff' || userType == 'trustee'">
                                  <mat-icon>email</mat-icon> Email Confirmations
                                </button>
                              </div>
                            </div>
                            <div class="fellohExpandRowColumn">
                              <div>
                                <button type="button" mat-stroked-button color="warn" (click)="deleteFellohLink(data)" class="fellohSettingButtons"
                                [disabled]="!(data.status == 'Created' && (userType != 'sinGSAdmin' && userType != 'sinGSstaff' && userType != 'trustee'))">
                                  <mat-icon>delete</mat-icon> Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="fellohColumns;"></mat-header-row>
                  <mat-row *matRowDef="let row; columns : fellohColumns;" class="trueRow" (click)="expandedElement = expandedElement === row ? null : row"></mat-row>
                  <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden"></mat-row>
                </mat-table>
              </div>
            </div>

            <div *ngIf="pageLoaded" style="margin-left: 22.5px; margin-top: 22.5px;">
              <h3 *ngIf="adminJustOpened">Please click <i>Reload Transactions</i> button to pull the data</h3>
              <h3 *ngIf="!adminJustOpened && fellohData.data.length == 0">There are no payments for this date range</h3>
            </div>
          </div>

          <div [@inAnimation] *ngIf="temporaryView && pageLoaded" style="clear: both; margin-bottom: 100px;">
            <div *ngIf="overdueData.data.length > 0">
              <div class="table-responsive">
                <mat-table class="tableClass" multiTemplateDataRows [dataSource]="overdueData">
                  <ng-container matColumnDef="custName">
                    <mat-header-cell *matHeaderCellDef>Customer</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.customer.name }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="amount">
                    <mat-header-cell *matHeaderCellDef>Amount</mat-header-cell>
                    <mat-cell *matCellDef="let data"><b>{{ data.amount | currency:data.currency }}</b></mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="bookingReference">
                    <mat-header-cell *matHeaderCellDef>Reference No.</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.merchantRequestId }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="expand">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let data">
                      <mat-icon style="float: right;">expand_more</mat-icon>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="expandedDetail">
                    <mat-cell class="expandedDetail" *matCellDef="let data" [attr.colspan]="3">
                      <div style="width: 100%; margin-bottom: 5px;">
                        <div class="fellohExpandRow" style="margin-left: unset; margin-right: unset; text-align: center;">
                          <form ngNativeValidate #editRefForm="ngForm" (ngSubmit)="editBookingRef(editRefForm, data)">
                            <div>
                              <h3>
                                <mat-icon style="color: #565656; margin-top: -1.5px;">update</mat-icon> 
                                Update <span *ngIf="data.temporaryRequestIdFlag">Temporary</span> Booking Reference
                              </h3>
                            </div>

                            <div class="singsRefRadio fellohExpandRowColumn" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                              <h3>Is it SinGS booking?</h3>
                            </div>

                            <div class="fellohExpandRowColumn" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                              <mat-radio-group #isSings{{data.transactionId}}="ngModel" name="isSings{{data.transactionId}}" [(ngModel)]="data.isSingsBooking" color="primary">
                                <mat-radio-button [disableRipple]="true" [value]="false">No</mat-radio-button>
                                <mat-radio-button [disableRipple]="true" style="margin-left: 15%;" [value]="true">Yes</mat-radio-button>
                              </mat-radio-group>
                            </div>

                            <div class="fellohExpandRowColumn">
                              <div>
                                <i><b>Please enter new booking reference below to update the payment</b></i>
                              </div>
                            </div>

                            <div class="fellohExpandRowColumn">
                              <mat-form-field *ngIf="!data.isSingsBooking && bookingPrefixStatic != ''" style="width: 60px !important; min-width: unset;">
                                <mat-select ngModel name="fellohPrefix" [(ngModel)]="chosenPrefix" panelClass="fellohCurrency">
                                  <mat-option value="FX-" *ngIf="validateFXopeness(selectedBranch.tradeCode)">FX-</mat-option>
                                  <mat-option [value]="bookingPrefixStatic">{{bookingPrefixStatic}}</mat-option>
                                </mat-select>
                              </mat-form-field>

                              <mat-form-field *ngIf="!data.isSingsBooking && bookingPrefixStatic != ''">
                                <input *ngIf="chosenPrefix == 'NWG-'" required ngModel name="newRef" #newRef="ngModel" title="Non-numeric characters are not allowed" pattern="[0-9]+" matInput type="text" minlength="5" maxlength="5"
                                  matTooltip="New Booking Reference" matTooltipClass="line-normal-tooltip" matTooltipPosition="below">
                                <input *ngIf="chosenPrefix == 'GTG-'" required ngModel name="newRef" #newRef="ngModel" title="Non-numeric characters are not allowed" pattern="[0-9]+" matInput type="text" minlength="6" maxlength="6"
                                  matTooltip="New Booking Reference" matTooltipClass="line-normal-tooltip" matTooltipPosition="below">
                                <input *ngIf="chosenPrefix == 'FX-'" required ngModel name="newRef" #newRef="ngModel" title="Non-numeric characters are not allowed" pattern="[a-zA-Z0-9]+" matInput type="text" minlength="3" maxlength="37"
                                  matTooltip="New Booking Reference" matTooltipClass="line-normal-tooltip" matTooltipPosition="below">
                              </mat-form-field>
      
                              <mat-form-field *ngIf="!data.isSingsBooking && bookingPrefixStatic == ''">
                                <input required ngModel name="newRef" #newRef="ngModel" pattern="[a-zA-Z0-9-]+" title="Special characters are not allowed" matInput type="text" minlength="5" maxlength="40" placeholder="New Booking Ref.">
                              </mat-form-field>
      
                              <mat-form-field *ngIf="data.isSingsBooking">
                                <span matPrefix>{{singsPrefix | uppercase }}-</span>
                                <input required ngModel name="newRef" #newRef="ngModel" title="Non-numeric characters are not allowed" pattern="[0-9]+" matInput type="text" minlength="7" maxlength="7"
                                  matTooltip="New Booking Reference" matTooltipClass="line-normal-tooltip" matTooltipPosition="below">
                              </mat-form-field>
                            
                            </div>

                            <div class="fellohExpandRowColumn">
                              <div>
                                <button type="submit" mat-stroked-button color="primary" class="fellohSettingButtons" [disabled]="!editRefForm.valid">
                                    <mat-icon>check</mat-icon> Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="overdueColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns : overdueColumns;" class="trueRow" (click)="expandedElement = expandedElement === row ? null : row"></mat-row>
                  <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
                    [@detailExpand]="(userType == 'wcMember' || userType == 'wcManager' || userType == 'memberStaff' || userType == 'memberManager') && row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden;"></mat-row>
                </mat-table>
              </div>
            </div>

            <div *ngIf="pageLoaded" style="margin-left: 22.5px; margin-top: 22.5px;">
              <h3 *ngIf="adminJustOpened">Please click <i>Reload Transactions</i> button to pull the data</h3>
              <h3 *ngIf="!adminJustOpened && overdueData.data.length == 0">None of the payments require your attention</h3>
            </div>
          </div>

          <div [@inAnimation] *ngIf="pendingView && pageLoaded" style="clear: both; margin-bottom: 100px;">
            <div *ngIf="paymentsDueData.data.length > 0">
              <div class="table-responsive">
                <mat-table class="tableClass" multiTemplateDataRows [dataSource]="paymentsDueData">
                  <ng-container matColumnDef="custName">
                    <mat-header-cell *matHeaderCellDef>Customer</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.customer.name }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="custEmail">
                    <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.customer.email }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="bookingReference">
                    <mat-header-cell *matHeaderCellDef>Reference No.</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.merchantRequestId }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="amount">
                    <mat-header-cell *matHeaderCellDef>Amount</mat-header-cell>
                    <mat-cell *matCellDef="let data"><b>{{ data.amount | currency:data.currency }}</b></mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let data">
                      <mat-icon style="float: right;">expand_more</mat-icon>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="expandedDetail">
                    <mat-cell class="expandedDetail" *matCellDef="let data" [attr.colspan]="5">
                      <div style="width: 100%; margin-bottom: 5px;">
                        <div class="fellohExpandRow">
                          
                          <div style="display: flex; flex-direction: column; align-items: center;">
                            <div>
                              <h3>
                                <mat-icon style="color: #565656; margin-top: -1.5px;">payment</mat-icon> Take Payment Now
                              </h3>
                            </div>

                            <div class="fellohExpandRowColumn">
                              <div *ngIf="data.allowedPaymentMethods == 'OPEN_BANKING'">
                                <i><b>This option is not available as this is an Open Banking payment only</b></i>
                              </div>
                              <div *ngIf="data.allowedPaymentMethods != 'OPEN_BANKING'">
                                <i><b>Click the button below and hand the device over to the customer to complete</b></i>
                              </div>
                            </div>

                            <div class="fellohExpandRowColumn">
                              <div>
                                <button type="button" mat-stroked-button color="primary" [disabled]="data.allowedPaymentMethods == 'OPEN_BANKING' ||
                                userType == 'sinGSAdmin' || userType == 'sinGSstaff' || userType == 'trustee' || restrictedUser"
                                (click)="openFellohLink(data, 'manualInPerson')" class="fellohSettingButtons">
                                  <mat-icon>person</mat-icon> Payment In Person
                                </button>
                              </div>
                            </div>

                            <div class="fellohExpandRowColumn">
                              <div *ngIf="data.allowedPaymentMethods == 'OPEN_BANKING'">
                                <i><b>This option is not available as this is an Open Banking payment only</b></i>
                              </div>
                              <div *ngIf="data.allowedPaymentMethods != 'OPEN_BANKING'">
                                <i><b>Click the button below and request your customer for the card details</b></i>
                              </div>
                            </div>

                            <div class="fellohExpandRowColumn">
                              <div>
                                <button type="button" mat-stroked-button color="primary" [disabled]="data.allowedPaymentMethods == 'OPEN_BANKING' ||
                                userType == 'sinGSAdmin' || userType == 'sinGSstaff' || userType == 'trustee' || restrictedUser"
                                (click)="openFellohLink(data, 'manual')" class="fellohSettingButtons">
                                  <mat-icon>phone_in_talk</mat-icon> Payment Over Phone
                                </button>
                              </div>
                            </div>
                          </div>

                          <div style="display: flex; flex-direction: column; align-items: center;">

                            <div>
                              <h3>
                                <mat-icon style="color: #565656; margin-top: -2.5px;">send</mat-icon> Send Payment Link
                              </h3>
                            </div>

                            <div class="fellohExpandRowColumn">
                              <div>
                                <button type="button" mat-stroked-button color="primary" [cdkCopyToClipboard]="fellohPayGate + data.transactionId" class="fellohSettingButtons"
                                [disabled]="userType == 'sinGSAdmin' || userType == 'sinGSstaff' || userType == 'trustee'">
                                  <mat-icon>content_copy</mat-icon> Copy Link
                                </button>
                              </div>
                            </div>

                            <div class="fellohExpandRowColumn">
                              <div>
                                <button type="button" mat-stroked-button color="primary" (click)="emailFellohLink(data, 'link')" class="fellohSettingButtons"
                                [disabled]="userType == 'sinGSAdmin' || userType == 'sinGSstaff' || userType == 'trustee'">
                                  <mat-icon>email</mat-icon> Email Customer
                                </button>
                              </div>
                            </div>

                            <div class="fellohExpandRowColumn">
                              <div>
                                <button type="button" mat-stroked-button color="primary" (click)="emailFellohLink(data, 'reminder')" class="fellohSettingButtons"
                                [disabled]="userType == 'sinGSAdmin' || userType == 'sinGSstaff' || userType == 'trustee'">
                                  <mat-icon>notifications</mat-icon> Email Reminder
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="paymentsDueColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns : paymentsDueColumns;" class="trueRow" (click)="expandedElement = expandedElement === row ? null : row"></mat-row>
                  <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
                    [@detailExpand]="(userType == 'wcMember' || userType == 'wcManager' || userType == 'memberStaff' || userType == 'memberManager') && row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden"></mat-row>
                </mat-table>
              </div>
            </div>

            <div *ngIf="pageLoaded" style="margin-left: 22.5px; margin-top: 22.5px;">
              <h3 *ngIf="adminJustOpened">Please click <i>Reload Transactions</i> button to pull the data</h3>
              <h3 *ngIf="!adminJustOpened && paymentsDueData.data.length == 0">None of the payments require your attention</h3>
            </div>
          </div>

        </div>

        <div class="accessDenied" *ngIf="pageLoaded && !haveAccess">
          <div class="errorMessage">
            <h2 style="text-align: center; margin-top: 2.5%;">Access denied</h2>
          </div>
        </div>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</mat-card-content>

<ng-template #myDialog>
  <div *ngIf="errorMessage != ''" class="errorMessage">
    <p>{{errorMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>

  <div *ngIf="successMessage != ''" class="successMessage">
    <p>{{successMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>
</ng-template>

<ng-template #popUpDialog>
  <mat-dialog-content>
    <div *ngIf="paymentsDueData.data.length > 0">
      <h2>You've got <span style="color: red">{{paymentsDueData.data.length}}</span> Pending Payment(s)</h2>
      <p>Please ensure you have sent the payment link to your customer(s) via email!<br>
        Another alternative would be taking payment yourself via phone or next to your customer.</p>
    </div>
    <br *ngIf="overdueData.data.length > 0 && paymentsDueData.data.length > 0">
    <div *ngIf="overdueData.data.length > 0">
      <h2>You've got <span style="color: red">{{overdueData.data.length}}</span> Temporary Reference(s)</h2>
      <p>Please don't forget to update them as soon as you'll know the actual Booking Reference<br>
        Unchanged temporary references will slow down some of the financial processes.
    </div>

    <p style="margin: auto; text-align: center; margin-bottom: 0px;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </mat-dialog-content>
</ng-template>
