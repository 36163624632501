<div *ngIf="!pageLoaded" class="loading-indicator">
  <mat-spinner style="margin:0 auto;"></mat-spinner>
</div>

<mat-card-content>
  <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
    <div [@inAnimation] *ngIf="haveAccess && innerWidth > 1024" style="width: 100%;">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('edit')" mat-button [disabled]="editView">
          <mat-icon>list</mat-icon> User List
        </button>
        <button class="navButtons" (click)="switchView('create')" mat-button [disabled]="createView">
          <mat-icon>add</mat-icon> Create User
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon> Help
        </button>
      </div>
    </div>

    <div [@inAnimation] *ngIf="haveAccess && innerWidth < 1025" style="width: 100%">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('edit')" mat-button [disabled]="editView">
          <mat-icon>list</mat-icon>
        </button>
        <button class="navButtons" (click)="switchView('create')" mat-button [disabled]="createView">
          <mat-icon>add</mat-icon>
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>


  <mat-sidenav-container>
    <mat-sidenav #drawer mode="side" closed>

    </mat-sidenav>
    <mat-sidenav-content>
      <mat-card [style.overflow]="'auto'">
        <div [@inAnimation] class="sideNavContent" *ngIf="haveAccess">
          <div [@inAnimation] *ngIf="editView">
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>User List
            </h1>
            <mat-divider></mat-divider>

            <div style="display: flex;">
              <div style="display: flex;">
                <div>
                <mat-form-field style="padding-top: 5px; margin-bottom: -10px; min-width: unset; float: left; margin-left: 22.5px;">
                  <input matInput type="text" (keyup)="doFilter($event)" id="filterUsers" placeholder="Filter Users">
                </mat-form-field>
                </div>
                <div>
                <mat-form-field *ngIf="branchData.length > 1" class="branchEdit" style="max-width: unset; width: 322.5px; padding-top: 5px; margin-bottom: -10px; float: left; display: block-inline;">
                  <mat-select #tradeCode="ngModel" required name="tradeCode" [(ngModel)]="currentRequest.tradeCode" placeholder="Branch" (selectionChange)="changeBranch($event.value)">
                    <mat-option *ngFor="let branch of branchData" [value]="branch.tradeCode">
                      {{branch.fullName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                </div>
              </div>

              <div class="paginatorFooter">
                <div>
                  <button class="buttonToRight" color="primary" style="margin-right: 20px; margin-bottom: -7.5px;"
                          mat-icon-button (click)="exportToExcel()">
                        <mat-icon>file_download</mat-icon>
                  </button>
                </div>
                <div>
                  <mat-paginator style="margin-top: -5px;" #paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
                </div>
              </div>
            </div>

            <mat-table multiTemplateDataRows [dataSource]="userData" style="margin-bottom: 200px;">
              <ng-container matColumnDef="fullName">
                <mat-header-cell *matHeaderCellDef> Full Name </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.fullName }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="company">
                <mat-header-cell *matHeaderCellDef> Company </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.company | uppercase }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="operation">
                <mat-header-cell *matHeaderCellDef> Operation </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.operation | titlecase }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="tradeCode">
                <mat-header-cell *matHeaderCellDef> Trade Code </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.tradeCode }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="userType">
                <mat-header-cell *matHeaderCellDef> User Type </mat-header-cell>
                <mat-cell *matCellDef="let user">{{ user.niceUserType }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="blocked">
                <mat-header-cell *matHeaderCellDef> Blocked </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.blocked | titlecase }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="lastLoggedIn">
                <mat-header-cell *matHeaderCellDef> Last Login </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.lastLoggedIn | date : "dd.MM.y hh:mm a" }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="expand">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let customer">
                  <mat-icon>expand_more</mat-icon>
                </mat-cell>
              </ng-container>

              <!-- Expansion text -->
              <ng-container matColumnDef="expandedDetail" class="expandedDetail">
                <mat-cell class="expandedDetail" *matCellDef="let user" [attr.colspan]="9">
                  <div *ngIf="!user.resetPasswordOpen">
                    <form [@inOutAnimation] *ngIf="user == expandedElement" ngNativeValidate #editUserForm="ngForm" (ngSubmit)="editUser(editUserForm, user)">
                      <h3>User Details</h3>
                      <mat-form-field class="fullNameEdit">
                        <input matInput ngModel required placeholder="Full Name" maxlength="80" minlength="5" name="fullName" #fullName="ngModel" [(ngModel)]="user.fullName">
                      </mat-form-field>
                      <mat-form-field class="emailEdit">
                        <input matInput ngModel required placeholder="Email" maxlength="100" minlength="5" name="email" #email="ngModel" [(ngModel)]="user.email" readonly="true"
                        pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$">
                      </mat-form-field>
                      <mat-form-field class="prefixEdit">
                        <mat-select ngModel required [(ngModel)]="user.bookRefStr" name="bookRefStr" #bookRefStr="ngModel" placeholder="Booking Prefix">
                          <mat-option *ngFor="let prefix of filteredPrefixes" [value]="prefix['value']">
                            {{prefix['viewValue']}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="userGroup">
                        <mat-select ngModel [(ngModel)]="user.group" name="group" #group="ngModel" placeholder="User Group">
                          <ng-container *ngFor="let group of userGroups">
                            <mat-option *ngIf="group == null" [value]="group">-- None --</mat-option>
                            <mat-option *ngIf="group != null && group.groupName == undefined" [value]="group">{{group}}</mat-option>
                            <mat-option *ngIf="group != null && group.groupName != undefined" [value]="group.groupName">{{group.groupName}} ({{ group.listCodes }})</mat-option>
                          </ng-container>
                        </mat-select>
                      </mat-form-field>

                      <h3>User Permissions</h3>
                      <mat-form-field class="companyEdit">
                        <mat-select ngModel required [(ngModel)]="user.company" (selectionChange)="changeCompany(user)" name="company" #company="ngModel" placeholder="Company">
                          <mat-option *ngFor="let company of companies" [value]="company['value']">
                            {{company['viewValue']}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="operationEdit">
                        <mat-select ngModel required [(ngModel)]="user.operation" name="operation" #operation="ngModel" placeholder="Operation">
                          <mat-option *ngFor="let operation of filteredOperations" [value]="operation['value']">
                            {{operation['viewValue']}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="userLevelEdit">
                        <mat-select ngModel required [(ngModel)]="user.userType" (selectionChange)="filterType($event.value)" placeholder="User Type" name="userType" #userType="ngModel">
                          <mat-option *ngFor="let userType of userTypes" [value]="userType['value']">
                            {{userType['viewValue']}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="tradeCodeEdit">
                        <mat-select ngModel required [(ngModel)]="user.tradeCode" placeholder="Branch" name="tradeCode" #tradeCode="ngModel">
                          <mat-option *ngFor="let branch of filteredBranches" [value]="branch.tradeCode">
                            {{branch.fullName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <div class="checkboxContainer" style="margin-bottom: unset; border-top: 1px solid rgb(219, 219, 219);" *ngIf="user.userType == 'wcMember' || user.userType == 'memberStaff'">
                        <div class="checkboxElement">
                          <label style="display: flex;">
                            <h3>All Bookings Access</h3>
                            <input type="checkbox" (change)="checkboxYesNo($event, user, 'othersBookingAccess')"
                            [checked]="user.othersBookingAccess == 'yes'" style="margin-left: 32.25px;"
                            value="{{user.othersBookingAccess}}">
                          </label>
                        </div>
                      </div>

                      <div *ngIf="loggedInType == 'sinGSAdmin' || loggedInType == 'sinGSstaff' || loggedInType == 'tapsAdmin'" style="border-top: 1px solid rgb(219, 219, 219); padding-top: 15px;">
                        <mat-form-field class="supplierName" style="width: 370px; max-width: unset;">
                          <mat-select ngModel required [(ngModel)]="user.supplierName" name="supplierName" #supplierName="ngModel" placeholder="Supplier Name" [disabled]="user.userType != 'supplier'">
                            <mat-option *ngFor="let supplier of supplierList" [value]="supplier.supplierNameM">
                              {{ supplier.supplierNameM }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <input matInput hidden="true" ngModel [(ngModel)]="user.id" name="id" #id="ngModel">

                      <div *ngIf="loggedInType == 'memberManager' || loggedInType == 'wcManager'" style="padding-top: 10px; font-size: 13.5px; border-top: 1px solid rgb(219, 219, 219);">
                        If you would like to amend a manager or make a user a manager, please contact <a href="mailto:sings@thetravelnetworkgroup.co.uk?subject=New Manager Request">sings@thetravelnetworkgroup.co.uk</a>
                        <p>Include the following details in your request: <b>Full Name, Email Address, Branch Name or Code</b></p>
                      </div>

                      <button class="buttons" mat-raised-button color="accent" type="accent" [disabled]="editUserForm.invalid || !userTypeAllowed">
                        <mat-icon class="iconInButton">edit</mat-icon>Save
                      </button>
                      <button class="buttons" *ngIf="(loggedInType == 'memberManager' || loggedInType == 'wcManager' || loggedInType == 'sinGSAdmin') && user.blocked == 'no'" mat-raised-button color="primary" type="button"
                        (click)="switchBlockUser(user, 'block')">
                        <mat-icon class="iconInButton">lock</mat-icon>Block
                      </button>
                      <button class="buttons" *ngIf="(loggedInType == 'memberManager' || loggedInType == 'wcManager' || loggedInType == 'sinGSAdmin') && user.blocked == 'yes'" mat-raised-button color="primary" type="button"
                        (click)="switchBlockUser(user, 'unblock')">
                        <mat-icon class="iconInButton">lock_open</mat-icon>Unblock
                      </button>
                      <button class="buttons" *ngIf="loggedEmail == 'greg@gmail.com'" mat-raised-button color="warn" type="button" (click)="removeUser(user)">
                        <mat-icon class="iconInButton">delete</mat-icon>Remove
                      </button>
                      <button class="buttons" *ngIf="loggedInType == 'sinGSAdmin' || loggedInType == 'tapsAdmin'" mat-raised-button color="accent" type="button" (click)="openClosePasswordDiv(user, true)">
                        <mat-icon class="iconInButton">password</mat-icon>Password
                      </button>
                    </form>
                  </div>
                  <div *ngIf="user.resetPasswordOpen">
                    <h3>Set New Password</h3>
                    <form ngNativeValidate #resetPasswordForm="ngForm" (ngSubmit)="changeUserPassword(resetPasswordForm, user)">
                      <mat-form-field class="newPassword">
                        <input matInput ngModel required placeholder="Password" maxlength="20" minlength="5" name="password" #password="ngModel" type="password" (input)="validatePassword($event.target)">
                      </mat-form-field>

                      <mat-form-field class="newPassword">
                        <input matInput ngModel required placeholder="Password Repeat" maxlength="20" minlength="5" name="passwordRepeat" #passwordRepeat="ngModel" type="password">
                      </mat-form-field>

                      <mat-checkbox ngModel name="sendEmail" #sendEmail="ngModel">Send Password</mat-checkbox>

                      <br>
                      <p style="color: red;" *ngIf="passwordMessage != ''"><b>{{passwordMessage}}</b></p>

                      <button class="buttons" *ngIf="loggedInType == 'sinGSAdmin' || loggedInType == 'tapsAdmin'" mat-raised-button color="warn" type="button" (click)="openClosePasswordDiv(user, false)">
                        <mat-icon class="iconInButton">arrow_back</mat-icon>Back
                      </button>
                      <button class="buttons" *ngIf="loggedInType == 'sinGSAdmin' || loggedInType == 'tapsAdmin'" mat-raised-button color="accent" type="submit" [disabled]="resetPasswordForm.invalid || passwordMessage != ''">
                        <mat-icon class="iconInButton">restart_alt</mat-icon>Reset
                      </button>
                    </form>
                  </div>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns : displayedColumns;" class="trueRow " (click)="expandedElement = expandedElement === row ? null : row; filterBranches(row.company, null); filterType('reset');"></mat-row>
              <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden"></mat-row>
            </mat-table>
          </div>

          <div [@inAnimation] *ngIf="createView">
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Create User
            </h1>
            <mat-divider></mat-divider>
            <form ngNativeValidate #createUserForm="ngForm" (ngSubmit)="createUser(createUserForm)">
              <div class="withinMatCard">
                <h5><i>All fields below are mandatory</i></h5>
                <h3>User Details</h3>

                <mat-form-field class="fullNameCreate">
                  <input matInput ngModel required placeholder="Full Name" id="fullNameCreate" name="fullName" #fullName="ngModel" maxlength="80" minlength="5">
                </mat-form-field>

                <mat-form-field class="emailEdit">
                  <input matInput ngModel required placeholder="Email" id="emailCreate" name="email" #email="ngModel" maxlength="100" minlength="5"
                  pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$">
                </mat-form-field>

                <mat-form-field class="passwordCreate">
                  <input matInput ngModel required placeholder="Password" id="passwordCreate" name="password" #password="ngModel" type="password" maxlength="20" minlength="5" (input)="validatePassword($event.target)">
                </mat-form-field>

                <mat-form-field class="passwordCreate">
                  <input matInput ngModel required placeholder="Repeat Password" id="passwordRepeat" name="passwordRepeat" #passwordRepeat="ngModel" type="password" maxlength="20" minlength="5">
                </mat-form-field>

                <mat-checkbox ngModel name="sendEmail" #sendEmail="ngModel">Send Password</mat-checkbox>

                <h3>User Permissions</h3>

                <mat-form-field class="userLevelCreate">
                  <mat-select ngModel required placeholder="User Type" name="userType" id="userTypeCreate" #userType="ngModel" (selectionChange)="filterType($event.value)">
                    <mat-option *ngFor="let userType of userTypes" [value]="userType['value']" [disabled]="userType['block']">
                      {{userType['viewValue']}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="companyCreate">
                  <mat-select ngModel required name="company" #company="ngModel" id="companyCreate" placeholder="Company" (selectionChange)="filterBranches($event.value, null)">
                    <mat-option *ngFor="let company of companies" [value]="company['value']">
                      {{company['viewValue']}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="operationCreate">
                  <mat-select ngModel required name="operation" [(ngModel)]="chosenOperation" id="operationCreate" placeholder="Operation" [disabled]="filteredOperations.length == 0">
                    <mat-option *ngFor="let operation of filteredOperations" [value]="operation['value']">
                      {{operation['viewValue']}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="tradeCodeCreate">
                  <mat-select ngModel required placeholder="Branch" name="tradeCode" id="tradeCodeCreate" [(ngModel)]="chosenBranch" [disabled]="filteredBranches.length == 0">
                    <mat-option *ngFor="let branch of filteredBranches" [value]="branch.tradeCode">
                      {{branch.fullName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="prefixEdit" style="width: 140px;">
                  <mat-select ngModel required name="bookRefStr" [(ngModel)]="chosenPrefix" placeholder="Booking Prefix" [disabled]="filteredPrefixes.length == 0">
                    <mat-option *ngFor="let prefix of filteredPrefixes" [value]="prefix['value']">
                      {{prefix['viewValue']}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <br>

                <mat-form-field class="userGroup" style="width: 370px; max-width: unset;">
                  <mat-select ngModel name="group" #group="ngModel" placeholder="User Group">
                    <ng-container *ngFor="let group of userGroups">
                      <mat-option *ngIf="group == null" [value]="group">-- None --</mat-option>
                      <mat-option *ngIf="group != null && group.groupName == undefined" [value]="group">{{group}}</mat-option>
                      <mat-option *ngIf="group != null && group.groupName != undefined" [value]="group.groupName">{{group.groupName}} ({{ group.listCodes }})</mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="supplierName" style="width: 370px; max-width: unset;" *ngIf="loggedInType == 'sinGSAdmin' || loggedInType == 'sinGSstaff' || loggedInType == 'tapsAdmin'">
                  <mat-select ngModel required name="supplierName" placeholder="Supplier Name" [disabled]="!isSupplier">
                    <mat-option *ngFor="let supplier of supplierList" [value]="supplier.supplierNameM">
                      {{ supplier.supplierNameM }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div *ngIf="loggedInType == 'memberManager' || loggedInType == 'wcManager'" style="padding-top: 10px; font-size: 13.5px; border-top: 1px solid rgb(219, 219, 219);">
                  If you would like to add a new manager, please contact <a href="mailto:sings@thetravelnetworkgroup.co.uk?subject=New Manager Request">sings@thetravelnetworkgroup.co.uk</a>
                  <p>Include the following details in your request: <b>Full Name, Email Address, Branch Name or Code</b></p>
                </div>

                <div class="checkboxContainer" style="margin-bottom: unset; border-top: 1px solid rgb(219, 219, 219);">
                  <div class="checkboxElement">
                    <label style="display: flex;">
                      <h3>All Bookings Access</h3>
                      <input type="checkbox" style="margin-left: 32.25px;" [(ngModel)]="othersAccess" name="othersBookingAccess" [disabled]="othersDisabled">
                    </label>
                  </div>
                </div>

                <p style="color: red;" *ngIf="passwordMessage != ''"><b>{{passwordMessage}}</b></p>

                <button type="submit" class="buttons" mat-raised-button color="accent" [disabled]="createUserForm.invalid || passwordMessage != ''">
                  <mat-icon class="iconInButton">add</mat-icon>Create
                </button>
              </div>
            </form>
          </div>
        </div>

        <div class="accessDenied" *ngIf="pageLoaded && !haveAccess">
          <div class="errorMessage">
            <h2 style="text-align: center; margin-top: 2.5%;">Access denied</h2>
          </div>
        </div>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</mat-card-content>

<ng-template #myDialog>
  <div *ngIf="errorMessage != ''" class="errorMessage">
    <p>{{errorMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>

  <div *ngIf="successMessage != ''" class="successMessage">
    <p>{{successMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>
</ng-template>

<ng-template #helpDialog>
  <mat-dialog-content>
    <h1 style="margin-top: 0px;"><u>Users</u></h1>
    <p>This page allows you to manage Users within your branch.</p>

    <h2>User Details</h2>
    <p>Agent's full name is used in various documents and emails. Email address must be <b>unique</b>.</p>

    <h2>User Permissions</h2>
    <p>Depending on the setup, selected User will be able to perform different operation within the system.</p>

    <h2>User Groups</h2>
    <p>Selecting a group to the user means it will be able to use all trade codes within the group (multi access).<br>
      If left empty, user will be able to use only one branch specifed in <b>Branch</b> field.</p>

    <p style="margin: auto; text-align: center; margin-bottom: 0px;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </mat-dialog-content>
</ng-template>
