import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Session } from '../../common/session';
import { GlobalConstants } from '../../common/global-constants';
import { HostListener } from '@angular/core';
import { AppComponent } from '../../app.component';
import { UserService } from '../../services/user.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})

export class LoginComponent implements OnInit, AfterViewInit {
  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;

  // Boolean deciding whether user has access or not
  pageLoaded = true;
  loginPage = true;
  forgotPage = false;

  // Login UI presentation page variables
  innerWidth = 0;
  innerHeight = 0;
  leftFX = 0;
  rightFX = 0;

  // Imported variables from outside
  constants = new GlobalConstants();

  // Other variables
  errorMessage: any = '';
  successMessage: any = '';
  versionTooltip: any = 'New version has been released. Click here to see more details.';
  goodBrowser: boolean = AppComponent.myapp.goodBrowser;

  // Version variables below..
  newVersion = false;
  versionApplied: any = '2024-09-10';

  constructor(private userService: UserService, private location: Location, private router: Router,
              private route: ActivatedRoute, private session: Session, public dialog: MatDialog) { }

  ngOnInit(): void {
    if (sessionStorage.length > 0 && Session.mySession.get('user')) {
      this.router.navigate(['/']); // If session defined, redirect user to Dashboard where user will be logged out / will end up
    } else {
      this.innerWidth = window.innerWidth; // Needed to presentaion logic
      this.innerHeight = window.innerHeight; // Needed to presentaion logic

      // /resetPassword and /login share the same component (this one!). Though if /resetPassword is the URL, we'll do as follows..
      if (this.router.url.includes('resetPassword')) {
        this.route.params.subscribe(params => {
          // To reset password, URL must contain id and token params. If not there - the request is invalid
          if (params.id != null && params.token != null) {
            // We're setting up new random password and we're creating a request to change it here..
            const randomPassword = Math.random().toString(36).substr(2, 10);
            const request = { id: params.id, password: randomPassword, sendEmail: true, token: params.token };
            this.userService.setOtherUserPassword(request).then((result: any) => {
              if (result.status === 'OK') {
                this.sendMessageToDialog('Password has been successfully reset - please check your email', '', '', '');
              } else {
                this.sendMessageToDialog('', 'Sorry - the link has expired', '', '');
              }
            }).catch((error: any) => {
              this.sendMessageToDialog('', 'Your request could not be completed by SinGS at this time (E0501S)', error, request);
            });
          } else {
            this.sendMessageToDialog('', 'Bad Request', '', ''); // Invalid request..
          }
        });
        this.location.replaceState('/login');
      }

      // Work out the number of days after the new version has been released
      const timeDiff = Math.abs(Date.now() - new Date(this.versionApplied).getTime());
      const age = Math.floor(timeDiff / (1000 * 3600 * 24));

      // Work out if we want to show NEW 'message' - generally we want to keep it for 3 days..
      if (age < 2) { this.newVersion = true; }

      this.changeFxFlex(); // Call Flex-changing funciton
    }
  }

  ngAfterViewInit(): void {
    if (localStorage.getItem('timeOut') !== null) {
      this.sendMessageToDialog('You have been logged out due to prolonged inactivity', '', '', '');
      localStorage.removeItem('timeOut'); // After the page loaded, show message the user only once
    }
  }

  login(form: NgForm): void {
    // Validate characters entered in the form
    if (this.constants.validateFormCharacters(form) !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else if (form.value.email !== '' && form.value.password !== '' && form.value.a_password === '' && form.value.login === '') {
      // a_password and login properties need to be empty (honeypot..). The only properties we're using are as below
      const request = { email: form.value.email, password: form.value.password };

      this.pageLoaded = false;
      this.userService.login(request).then((output: any) => {
        if (output.status === 'OK') {
          this.session.set('user', { id: output.data.id, token: output.data.token }); // Needed for easy id / token access within the app
          this.session.set('blockNewBooking', false); // needed in booking system - cannot keep creating new empty bookings
          this.session.set('justLoggedIn', true); // needed for top-nav correct display
          this.session.set('changePassword', output.changePassword); // needed for a pop-up warning user about password expiry date
          if (output.data.userType === 'wcMember' || output.data.userType === 'wcManager') {
            this.router.navigate(['/paymentLinks']); // redirect to Felloh app
          } else if (output.data.userType === 'trustee') {
            this.router.navigate(['/prf']); // Navigate to the Felloh UI + PRF system as a default
          } else {
            this.router.navigate(['/']); // Redirect to the Dashboard page
          }
        } else {
          this.sendMessageToDialog('', output.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0502S) - please try again', error, '');
      });
    } else {
      this.sendMessageToDialog('', 'Email or password cannot be empty', '', '');
    }
  }

  remindPassword(form: NgForm): void {
    // Validate characters entered in the form
    if (this.constants.validateFormCharacters(form) !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else if (form.value.a_password === '' && form.value.login === '') {
      // a_password and login properties need to be empty (honeypot..). The only properties we're using are as below
      const request = { email: form.value.email };

      this.pageLoaded = false;
      this.userService.generateRemindLink(request).then((output: any) => {
        if (output.status === 'OK') {
          this.sendMessageToDialog('Link to reset your password has been sent over to you', '', '', '');
        } else {
          this.sendMessageToDialog('', output.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0503S)', error, request);
      });
    }
  }

  switchView(type: any): void {
    // Not much to say, it controls the viewport
    if (type === 'login') {
      this.loginPage = true;
      this.forgotPage = false;
    } else if (type === 'password') {
      this.loginPage = false;
      this.forgotPage = true;
    }
  }

  changeFxFlex(): void {
    // Control the logic of UI presentation layer in Login page
    if (this.innerWidth < 640) {
      this.leftFX = 100;
      this.rightFX = 0;
    } else {
      this.leftFX = 50;
      this.rightFX = 50;
    }
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
    this.changeFxFlex();
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    this.pageLoaded = true; this.dialog.open(this.statusDialog);
  }
}
