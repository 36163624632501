import { Component, HostListener, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Session } from '../../common/session';
import { AppComponent } from 'src/app/app.component';
import { GlobalConstants } from 'src/app/common/global-constants';
import { ReportsService } from 'src/app/services/reports.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-email-popup',
  templateUrl: './email-popup.component.html',
  styleUrls: ['./email-popup.component.css', '../../../app/app.component.fellohStyles.css']
})
export class EmailPopupComponent implements OnInit {
  // Imported variables from outside
  constants = new GlobalConstants();
  innerWidth = AppComponent.myapp.innerWidth;

  // Variables taken from the child component
  @Input() htmlPage: any;
  @Input() documentRequest: any;
  @Input() documentName: any;
  @Input() leadPax: any;

  sanitizedHtmlMarkup: SafeHtml = '';
  preSetEmail: any = '';

  // Toolbar values
  preview = true; emailBody = false;

  // Other variables
  errorMessage: any = '';
  successMessage: any = '';
  pageLoaded = true;

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;

  constructor(public dialog: MatDialog, private sanitizer: DomSanitizer, private reportService: ReportsService, private userService: UserService) { }

  ngOnInit(): void {
    this.sanitizedHtmlMarkup = this.sanitizer.bypassSecurityTrustHtml(this.htmlPage);

    this.preSetEmail = 'Dear ' + this.leadPax + ',\n\nI trust this email finds you well.\n\n' + 
    'We are delighted to assist you with your upcoming travel plans, and attached you will find the necessary travel document for your reference.\n\n' +
    'Please review the document carefully, ensuring that all details are accurate and meet your requirements. If you have any questions or require further clarification, do not hesitate to reach out to our dedicated team.\n\n' +
    'Kind regards,\n' + Session.mySession.getUser().fullName;
  }

  sendEmail(form: NgForm, emailYourself: boolean): void {
    if (confirm('Are you sure you want to send this email?')) {
      const element = document.getElementById("manualInput") as HTMLTextAreaElement;
      let textContent = ''; // To be filled in next (or not..)

      if (element) { textContent = element.value; } // Assign content only if the element is present

      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().replace(/[:.]/g, '');

      const documentRequest = { ...this.documentRequest };
      
      documentRequest.outputFileName = `${documentRequest.bookingReference}_${formattedDate}.html`
      documentRequest.addnText = textContent;
      documentRequest.emailWording = form.value.emailWording;
      documentRequest.documentNiceName = this.documentName;
      documentRequest.generatedBy = Session.mySession.getUser().fullName;

      // Append email address to the object - we'll be sending it directly to the user (as a test I suppose)
      if (emailYourself) { documentRequest.emailAddress = Session.mySession.getUser().email; }
      
      this.pageLoaded = false;
      this.reportService.genAndSendDoc(documentRequest).then((output: any) => {
        if (output.status === 'OK') {
          this.sendMessageToDialog('Document has been sent', '', '', '');
        } else {
          this.sendMessageToDialog('', output.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'Felloh could not complete your request at this time (E2401S)', error, documentRequest);
      });
    }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    this.pageLoaded = true; this.dialog.open(this.statusDialog);
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
}