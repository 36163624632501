import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';

import { ElementTypeComponent } from './components/element-type/element-type.component';
import { ElementCreateComponent } from './components/element-create/element-create.component';
import { ElementEditComponent } from './components/element-edit/element-edit.component';
import { ElementPackageComponent } from './components/element-package/element-package.component';

import { BookingPortfolioComponent } from './components/booking-portfolio/booking-portfolio.component';

import { SupplierListComponent } from './components/supplier-list/supplier-list.component';
import { SupplementListComponent } from './components/supplement-list/supplement-list.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { UserGroupsComponent } from './components/user-groups/user-groups.component';
import { BranchListComponent } from './components/branch-list/branch-list.component';
import { CustomerListComponent } from './components/customer-list/customer-list.component';
import { BookingListComponent } from './components/booking-list/booking-list.component';
import { BankingComponent } from './components/banking/banking.component';
import { FinancialReportsComponent } from './components/financial-reports/financial-reports.component';
import { ConfigMarketingComponent } from './components/config-marketing/config-marketing.component';

import { WCHomeComponent } from './wcFelloh/home/home.component';
import { CreateLinkComponent } from './wcFelloh/create-link/create-link.component';
import { PaymentsOutComponent } from './wcFelloh/payments-out/payments-out.component';
import { PrfComponent } from './wcFelloh/prf/prf.component';
import { TapsComponent } from './wcFelloh/taps/taps.component';
import { ChargesComponent } from './wcFelloh/charges/charges.component';
import { ChangelogComponent } from './components/changelog/changelog.component';
import { FellohTransactionsComponent } from './wcFelloh/felloh-transactions/felloh-transactions.component';
import { PaymentLinksComponent } from './wcFelloh/payment-links/payment-links.component';
import { NewLinkComponent } from './wcFelloh/payment-links/new-link/new-link.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'resetPassword/:id/:token', component: LoginComponent },

  { path: 'addElement', component: ElementTypeComponent },
  { path: 'addElement/:element', component: ElementCreateComponent },
  { path: 'package/:operation', component: ElementPackageComponent },
  { path: 'element/:element', component: ElementEditComponent },
  { path: 'booking/:bookingReference', component: BookingPortfolioComponent },
  { path: 'booking/:bookingReference/:openTab', component: BookingPortfolioComponent },

  { path: 'branchList', component: BranchListComponent },
  { path: 'customerList', component: CustomerListComponent },

  { path: 'supplierList', component: SupplierListComponent },
  { path: 'supplementList', component: SupplementListComponent },

  { path: 'userList', component: UserListComponent },
  { path: 'groups', component: UserGroupsComponent },

  { path: 'bookingList', component: BookingListComponent },
  { path: 'banking', component: BankingComponent },
  { path: 'financialReports', component: FinancialReportsComponent },
  { path: 'marketing/config', component: ConfigMarketingComponent },

  { path: 'changelog', component: ChangelogComponent },
  { path: 'main', component: WCHomeComponent },
  { path: 'newLink', component: CreateLinkComponent },
  { path: 'paymentsOut', component: PaymentsOutComponent },
  { path: 'fellohCharges', component: ChargesComponent },
  { path: 'fellohTxn', component: FellohTransactionsComponent },
  { path: 'paymentLinks', component: PaymentLinksComponent },
  { path: 'newPaymentLink', component: NewLinkComponent },
  { path: 'prf', component: PrfComponent },
  { path: 'taps', component: TapsComponent },
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
