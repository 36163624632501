import { Component, OnInit, ElementRef, TemplateRef, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewChild } from '@angular/core';
import { HostListener } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from './services/user.service';
import { BranchService } from './services/branch.service';
import { PublicService } from './services/public.service';
import { SupplierService } from './services/supplier.service';
import { Session } from './common/session';
import { AsynchRequests } from './common/asynch-requests';
import { GlobalConstants } from './common/global-constants';
import { Documents } from './common/documents';
import { NgForm } from '@angular/forms';
import { BookingService } from './services/booking.service';
import { AutoLogoutService } from './common/auto-logout';
import { Location } from '@angular/common';
import { environment } from './../environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { isSameDay, isSameMonth } from 'date-fns';
import { collapseAnimation } from 'angular-calendar';
import { EmailTemplates } from './common/email-templates';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ],
  animations: [
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    ),
    trigger('customExpansionDetails', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', opacity: 0 })),
      state('expanded', style({ height: '*', opacity: 1 })),
      transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    collapseAnimation]
})

export class AppComponent implements OnInit {
  static myapp: any;
  // Imported variables from outside & exporting variables
  session = new Session(); documents = new Documents(); emailTemplates = new EmailTemplates();
  autoLogout = new AutoLogoutService(this.router);
  title = 'SinGS';

  showNotice: boolean = false;

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('iconsPanel') iconsPanel!: ElementRef<HTMLTableDataCellElement>;
  @ViewChild('mainPanel') mainPanel!: ElementRef<HTMLTableDataCellElement>;
  @ViewChild('searchPanel') searchPanel!: ElementRef<HTMLTableDataCellElement>;
  @ViewChild('userInfoBox') userInfoBox!: TemplateRef<any>;
  @ViewChild('userCalendarBox') userCalendarBox!: TemplateRef<any>;
  @ViewChild('bookingExternalBox') bookingExternalBox!: TemplateRef<any>;
  @ViewChild('systemMessageBox') systemMessageBox!: TemplateRef<any>;
  // Top-left corner menu ViewChild below
  @ViewChild('menuBox') menuBox!: ElementRef<HTMLTableDataCellElement>;
  @ViewChild('menuOpen') menuOpen!: any;

  // Google Calendar stuff
  calendarViewCurrent: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: any = new Date();
  activeDayIsOpen = false; // Deals with open/close div inside the calendar library
  calendarExists = false;
  calendarEvents: CalendarEvent[] = []; // Holds all Google Calendar events
  bookingEvents: CalendarEvent[] = []; // Holds all Event Celdnar events (from SinGS)

  // Calendar or event displayed?
  googleCalendarOpen = true;

  // Other variables
  goodBrowser = true; // Tells you whether user is using Chrome or not
  passwordExpires: any = null; // Holds password expiration date (usually after user has been created)
  bookSearch: any = ''; // Used in the top right corner search box (contains booking prefix + user's booking number input)
  systemMessage: any = ''; // Used to print out system messages out to the user
  passwordMessage: any = ''; // Used to print out password feedback to the user

  // Variable used for view and access control
  userType = '';
  userSignatureURL = '';
  currentTradeCode: any = '';
  bookingTradeCode: any = ''; // Variable used when creating bookings - its changed when pressing buttons
  usersBranches: any = []; // Variable which will hold users branches (groups)
  usersBranchesFiltered: any = []; // Varaible which will hold filtered branches (onType)
  filterString: any = ''; // Used for filtering branches
  isLoggedIn = false;
  fellohNav = true; // Do we display Felloh navigation or SinGS?
  parentLoaded = false; // Used for more seamless page loading (upon refresh)

  // Variables used for the custom top-left menu box
  menuOpened = false;
  menuMain = false;
  menuGroup = false;
  menuSource = false;
  menuMarketing = false;
  menuSetup = false;
  fellohCheckbox = false;

  // Variables used to determine whether user can access specific pages
  canSeeBanking = /^(Q0000)$/;

  // Booking upload changes and variables below
  prefixOn = true; // Display pefix on the top-right corner or not
  maxBkLength = 7; // The max length user can type in the book ref field

  // Variables used for Account settings stuff..
  accountDetails = true;
  accountGroup = false;
  accountPassword = false;
  accountSignature = false;
  accountGoogle = false;
  bookingPop = false;
  showExternalRef = false;

  // Variables used in SinGS booking system for top-nav icon navigation (and other things..)
  outOptionExternal: any = 'Upload';
  bookingReference: any = ''; bookingRefExternal: any = '';
  bookingData: any = {}; // Holds booking only data (without elements..)
  bookingCostings: any = []; // Holds supplier in booking data (elements..)
  passengersDataText = ''; // Holds passenger list text
  bookingReceipts: any = []; // Holds receipts data
  bookingPayments: any = []; // Holds payments data
  // bookingInsurance: any = [];
  bookingNotes: any = []; // Holds notes data
  elementNotes: any = []; // Holds element notes data

  // Variables used for top-nav view mobile/desktop UI
  constants = new GlobalConstants();
  innerWidth: any = window.innerWidth;
  navPanelWidthSum = 0;
  iconsStaticWidth = 0;

  constructor(public router: Router, private route: ActivatedRoute,
              private userService: UserService, private branchService: BranchService,
              private bookingService: BookingService, private publicService: PublicService,
              private location: Location, public dialog: MatDialog, private renderer: Renderer2,
              public platform: Platform, private supplierService: SupplierService, private asynchReq: AsynchRequests) {
    // Below we're setting a renderer which checks if each click is done on the top-left menu item
    // If it's not (or there was error), it will close the menus. Otherwise it will do nothing.
    this.renderer.listen('window', 'click', (e: Event) => {
      try {
        if (!this.menuOpen._elementRef.nativeElement.contains(e.target as Node) && !this.menuBox.nativeElement.contains(e.target as Node)) {
          this.menuReset();
          this.menuOpened = false;
        }
      } catch (error: any) {
        this.menuReset();
        this.menuOpened = false;
      }
    });
  }

  ngOnInit(): void {
    this.setBrowserClass();
    this.checkAndShowNotice();
    this.session.initialise(); // Creates a Session object here - right at the beginning of the app
    this.asynchReq.initialise(); // Creates a Asynch Request object here - same as above..
    this.documents.initialise(); // Creates a Document object here - not used anywhere else but Booking Portfolio but gotta do it!
    this.emailTemplates.initialise(); // Creates a Custom Email object here - used in Felloh Home and Booking Portfolio which can bring custom email templates
    this.autoLogout.initListener(); // Creates a Auto-Logout object here - as the name suggests, it will log users out
    AppComponent.myapp = this; // Allows other components (child components) to read & set variables in this component
    const pathString = this.location.path(); // Read the location string from URL
    // Currently the only fully supported browser is Chrome. If not used, display a warning message to users
    if (this.platform.BLINK === false) { this.goodBrowser = false; }
    // Proceed with default loading only if session isn't empty and the url is not changelog / resetPassword
    if (sessionStorage.length > 0 && pathString.substring(1, 6) !== 'changelog' && pathString.substring(1, 6) !== 'resetPassword') {
      // Create a request variable and authenticate the user
      const request = { id: this.session.get('user')?.id, token: this.session.get('user')?.token };
      this.userService.authenticate(request).then((output: any) => {
        if (output.status === 'OK') {

          this.branchService.getBranch(output.data).then((branch: any) => {
            if (branch.status === 'OK') {
              this.session.setBranch(branch.data); // Set branch data in session for later use
              this.session.setUser(output.data); // Set user data in session for later use
              this.session.setFelloh(output.accountID, output.fellohClient, output.fellohSecret, branch.data.fellohAccountId); // Set Felloh data in session
              this.session.setFellohKeys(output.fellohPublicKey, output.fellohPrivateKey); // Set Felloh data in session (V2 - felloh.com)
              this.session.setEmail(output.emailU, output.emailP); // Set email for later use

              this.userType = output.data.userType; // Global user type set here
              this.userSignatureURL = output.data.signatureURL; // Global signature URL here
              this.currentTradeCode = output.data.tradeCode; // Set it so 'Change Branch' is auto-set
              this.bookingTradeCode = output.data.tradeCode; // Set new booking's trade code by default (MUST for non-group users..)
              this.passwordExpires = output.data.passwordExpires; // Tell the browser whether password expires soon..
              this.bookSearch = this.session.getUser().bookRefStr + '-'; // Set bookRef string search here
              // Make it possible to switch to Felloh UI (fellohSetup is set to 'yes' OR just be Head Office user..)
              if (branch.data.fellohSetup === 'yes' || output.data.tradeCode === 'Q0000') {
                this.fellohCheckbox = true;
              }
              // Open user info box automatically if password NEEDS to be changed
              if (this.session.get('changePassword') === true) {
                this.accountDetails = false; this.accountPassword = true;
                this.openDialog('userInfo'); this.session.set('changePassword', false);
              }

              // In case a user is in the group (column 'group' isn't null or empty), we need to pull the list of branches (and their details)
              if (this.session.getUser().group != null && this.session.getUser().group !== undefined && this.session.getUser().group !== '') {
                // Create a request below..
                const groupRequest = { groupName: this.session.getUser().group, token: this.session.getUser().token };
                this.branchService.getUserGroup(groupRequest).then((userGroup: any) => {
                  if (userGroup.status === 'OK') {
                    this.session.setUsersGroup(userGroup.data); // Everything OK - set the data in the sesssion variable..
                    this.usersBranches = userGroup.data; // And set the data in the component..
                    this.usersBranchesFiltered = userGroup.data; // Filtered branches too!
                  } else {
                    this.systemMessage = userGroup.status; // Display error status message
                    this.openDialog('message'); // Display error status message
                  }
                  this.redirectForward();
                });
              } else {
                this.redirectForward();
              }
            } else {
              this.systemMessage = branch.status; // Display error status message
              this.openDialog('message'); // Display error status message
              this.logout(); // Branch status was not 'OK' - log the user out
            }
          }).catch((error: any) => {
            if (!environment.production) { this.systemMessage = JSON.stringify(error); this.openDialog('message'); }
            this.logout(); // Something went wrong with getting user's branch - log the user out
          });

        } else {
          this.logout(); // Not authenticated - log the user out
        }
      }).catch((error: any) => {
        if (!environment.production) { this.systemMessage = JSON.stringify(error); this.openDialog('message'); }
        this.logout(); // Something went wrong with authentication - log the user out
      });
    } else if (pathString.substring(1, 10) === 'changelog') {
      this.router.navigate(['/changelog']); // Just navigate user to /changelog
      this.parentLoaded = true; // And set parent as loaded
    } else if (pathString.substring(1, 6) === 'resetPassword') {
      this.logout(); // Make sure we clear all sessions etc. and redirecting user to /login page
    } else {
      this.parentLoaded = true; // Page has been loaded within seconds
    }
  }

  redirectForward(): void {
    if (this.userType === 'wcMember' || this.userType === 'wcManager') {
      this.router.navigate(['/paymentLinks']); // redirect to Felloh app
    } else if (this.userType === 'trustee') {
      this.router.navigate(['/prf']); // redirect to prf..
    } else if (this.userType === 'supplier' || this.userType === 'tapsAdmin') {
      this.router.navigate(['/taps']); // redirect to taps..
    } else {
      this.router.navigate(['/']); // redirect to sings dashboard
      this.getGoogleCalendar();
    }

    this.isLoggedIn = true;
    this.parentLoaded = true;
  }

  createBooking(bookingSource: any): void {
    if (this.session.get('blockNewBooking') == null || this.session.get('blockNewBooking') === false) {
      // Create request variable below. It contains all basic info about booking
      const bookingRequest: any = {
        userID: this.session.getUser().id.toString(), company: this.session.getUser().company,
        operation: this.session.getUser().operation, tradeCode: this.bookingTradeCode,
        agentEmail: this.session.getUser().email, paxNo: '0', paxNoInfants: '0', paxInfants: '0', bookingStatus: 'enquiry',
        bookingSource, token: this.session.get('user').token
      };
      
      let filteredBranch = null; // Session tells me that creating new booking is unblocked - carry on with the process..
      // Get the branch name from either array or session variable (single vs multi branch)
      if (this.usersBranches.length > 0) {
        filteredBranch = this.usersBranches.find((b: any) => b.tradeCode === this.bookingTradeCode);
        bookingRequest.vatReg = this.usersBranches.filter((b: any) => b.tradeCode === this.bookingTradeCode)[0].isVatReg;
      } else {
        filteredBranch = this.session.getBranch();
        bookingRequest.vatReg = this.session.getBranch().isVatReg;
      }

      // If the branch has only one trading name, then we'll set it to our booking data...
      const activeTradingNames = filteredBranch.tradingNames.filter((t: any) => t.active === 'yes');
      if (activeTradingNames.length === 1) {
        bookingRequest.branchName = activeTradingNames[0].tradingName;
        bookingRequest.tradingNameId = Number(activeTradingNames[0].id);
      }

      this.bookingService.createBooking(bookingRequest).then((bookingOutput: any) => {
        if (bookingOutput.status === 'OK') {
          // Booking created - now we need to generate public key for outside access
          const request = {
            company: this.session.getUser().company, operation: this.session.getUser().operation,
            tradeCode: this.bookingTradeCode, bookingReference: bookingOutput.bookingReference,
            apiCalling: 'getBookingFull', limitUse: 0, token: this.session.get('user').token
          };

          // We block creating new bookigns for the time being below (until something is added to it OR logging out from Sings)
          this.session.set('blockNewBooking', bookingOutput.bookingReference);

          this.publicService.createPublicKey(request).then((publicOutput: any) => {
            // Call this to clear out current booking data and to navigate to booking portfolio
            this.closeBooking('fromScript').then((res: any) => { this.navigateToBooking(bookingOutput.bookingReference, true); });
          }).catch((error: any) => {
            // Call this to clear out current booking data and to navigate to booking portfolio
            this.closeBooking('fromScript').then((res: any) => { this.navigateToBooking(bookingOutput.bookingReference, true); });
            if (!environment.production) { this.systemMessage = JSON.stringify(error); this.openDialog('message'); }
          });

        } else {
          // Display status message to the user in case the booking couldn't be created
          this.systemMessage = bookingOutput.status;
          this.openDialog('message');
        }
      }).catch((error: any) => {
        if (!environment.production) { this.systemMessage = JSON.stringify(error); this.openDialog('message'); }
      });

    } else {
      // Call this to clear out current booking data and to navigate to booking portfolio
      this.closeBooking('fromScript').then((res: any) => { this.navigateToBooking(this.session.get('blockNewBooking'), true); });
      // Display a pop-up message explaining what's happening
      this.systemMessage = 'Retrieved previously created booking ' + this.session.get('blockNewBooking') + '.';
      this.openDialog('message');
    }
  }

  validatePassword(input: any): void {
    this.passwordMessage = this.constants.validatePassword(input.value);
  }

  changeUserPassword(form: NgForm): void {
    // Both password input need to be the same. If not, display appropiate message
    if (form.value.password !== form.value.passwordRepeat) {
      this.systemMessage = 'Passwords do not match';
      this.openDialog('message');
    } else {
      // Create request variable with the password in so it's called to reset it
      const request: any = {
        id: this.session.getUser().id, password: form.value.password, production: environment.production,
        sendEmail: false, token: this.session.get('user').token
      };

      this.userService.setOtherUserPassword(request).then((result: any) => {
        if (result.status === 'OK') {
          this.passwordExpires = null; // Password no longer expires..
          this.systemMessage = 'Password has been updated'; // Display appropiate message
          this.accountPassword = false; // Hide password form
          this.openDialog('message');
        } else {
          this.systemMessage = result.status; // Display error status message
          this.openDialog('message');
        }
      }).catch((error: any) => {
        if (!environment.production) { this.systemMessage = JSON.stringify(error); this.openDialog('message'); }
      });
    }
  }

  updateGoogleAccInfo(form: NgForm): void {
    // Create request variable with the password in so it's called to reset it
    const request: any = {
      googleCalID: form.value.googleCalID, googleCalAPIkey: form.value.googleCalAPIkey,
      id: this.session.getUser().id, token: this.session.get('user').token
    };

    this.userService.setSelfGoogleAccount(request).then((result: any) => {
      if (result.status === 'OK') {
        this.systemMessage = 'Calendar details have been updated'; // Display appropiate message
        this.accountGoogle = false; // Hide password form
        this.openDialog('message');
        // Set session's Google account details below
        this.session.getUser().googleCalID = form.value.googleCalID;
        this.session.getUser().googleCalAPIkey = form.value.googleCalAPIkey;
        this.getGoogleCalendar(); // Reload Google calendar so user doesn't have to refresh the page
      } else {
        this.systemMessage = result.status; // Display error status message
        this.openDialog('message');
      }
    }).catch((error: any) => {
      if (!environment.production) { this.systemMessage = JSON.stringify(error); this.openDialog('message'); }
    });
  }

  setSelfTradeCode(tradeCode: any): void {
    // Create a request variable first..
    const request: any = { tradeCode, token: this.session.get('user').token };

    this.parentLoaded = false;
    this.userService.setSelfTradeCode(request).then((result: any) => {
      if (result.status === 'OK') {
        window.location.reload(); // Refresh the browser for changes to take effect
      } else {
        this.systemMessage = result.status; // Display error status message
        this.openDialog('message');
      }
    }).catch((error: any) => {
      if (!environment.production) { this.systemMessage = JSON.stringify(error); this.openDialog('message'); }
    });
  }

  uploadSignature(fileInputEvent: any): void {
    // Depending on what file is being uploaded, we create a variable name to be displayed in the inputs
    // The xxxFiles variables presumably are holding all selected files - will see if that's true
    let fileComment = 'none'; const arr = Array.from(fileInputEvent.target.files);
    // Make sure we add some randomness to the filename. This is to prevent URL guessing!
    const randomString: string = this.generateRandomString(20);
    // signatureID will contain a tradeCode-userID-randomString + the extension..
    const signatureID = `${this.session.get('user').id}-${randomString}`;

    arr.forEach((file: any) => {
      if (file.size > 2097152) {
        fileComment = 'File size limit exceeded - please allow maximum 2MB per file'; // Don't allow too big files in S3 I'm afraid..
      } else if (file.name.match(/[!@#+=$£%^&*(),?":;'{}|<>[\]/]/)) {
        fileComment = 'Invalid file name - please avoid using special characters'; // Don't allow special characters in the file name..
      } else if (file.name.length > 200) {
        fileComment = 'File name limit exceeded - please allow a maximum of 200 characters';
      } else {
        const request = {
          fileName: signatureID + '-' + file.name, file, whatFile: 'signature', token: Session.mySession.get('user').token
        };

        this.branchService.updateBranchMedia(request).then((output: any) => {
          if (output.status === 'OK') {
            // Create a request variable first..
            const request: any = { signatureURL: signatureID + '-' + file.name, token: this.session.get('user').token };

            this.userService.setSelfSignature(request).then((result: any) => {
              if (result.status === 'OK') {
                // Display appropiate message
                this.systemMessage = 'Your signature has been updated';
                this.userSignatureURL = signatureID + '-' + file.name;
              } else {
                this.systemMessage = result.status; // Display error status message
              }
              this.openDialog('message');
            }).catch((error: any) => {
              if (!environment.production) { this.systemMessage = JSON.stringify(error); this.openDialog('message'); }
            });
          }
        }).catch((error: any) => {
          if (!environment.production) { this.systemMessage = JSON.stringify(error); this.openDialog('message'); }
        });
      }
    });

    if (fileComment !== 'none') { this.systemMessage = fileComment; this.openDialog('message'); }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    if (this.isLoggedIn === true) {
      this.innerWidth = window.innerWidth;
      if (this.searchPanel === undefined || this.mainPanel.nativeElement === undefined) {
        this.navPanelWidthSum = this.innerWidth - this.iconsStaticWidth;
      } else {
        this.navPanelWidthSum = this.innerWidth - this.mainPanel.nativeElement.offsetWidth - this.iconsStaticWidth - this.searchPanel.nativeElement.offsetWidth;
      }
    }
  }

  getIconsWidth(): void {
    this.iconsStaticWidth = this.iconsPanel.nativeElement.offsetWidth;
    this.navPanelWidthSum = this.innerWidth - this.mainPanel.nativeElement.offsetWidth - this.iconsStaticWidth - this.searchPanel.nativeElement.offsetWidth;
  }

  logout(): void {
    sessionStorage.clear(); // Clear borwser's session storage
    this.userType = ''; // Clear user type
    this.isLoggedIn = false; // User no longer logged in
    this.parentLoaded = true; // Parent is loaded though..
    this.router.navigate(['/login']); // Go back to /login page
  }

  closeBooking(fromWhere: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // Set all booking-related variables to nothing / empty arrays etc..
      this.bookingReference = '';
      this.bookingData = {};
      this.bookingCostings = [];
      this.passengersDataText = '';
      this.bookingReceipts = [];
      this.bookingPayments = [];
      // this.bookingInsurance = [];
      this.bookingNotes = [];
      this.elementNotes = [];
      // We need to reset all timers on the bookings so its refreshed next time we open the same booking
      Session.mySession.resetTimersOnBookingValues().then((res: any) => {
        // And we set openedBooking session variable to null
        Session.mySession.setOpenedBooking(null);
        // If we've closed the booking by pressing big X button, we go back to the dashboard
        if (fromWhere === 'fromButton') { this.router.navigate(['/']); }
        resolve('');
      });
    });
  }

  bookingPopSwitch(event: any): void {
    // Set both of below values to the event value taken from the button in UI
    Session.mySession.set('bookingPop', event);
    this.bookingPop = event;
  }

  showExtRefSwitch(event: any): void {
    // Set both of below values to the event value taken from the button in UI
    Session.mySession.set('showExternalRef', event);
    this.showExternalRef = event;
  }

  navigateToBooking(bookingReference: any, openSummaryOverride: any): void {
    // We will be displaying bookings differently depending on whether fellohNav is on or off
    if ((!this.fellohNav && this.bookingPop) || openSummaryOverride) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => // Go quickly to Dashboard
      this.router.navigate(['/booking/', bookingReference])); // And enter bookign reference URL (to re-draw everything..)
    } else {
      this.naviagateToCheckup(bookingReference);
    }
  }

  naviagateToCheckup(bookingReference: any): void {
    // Assign external reference to a variable and open a dialog
    this.bookingRefExternal = bookingReference;
    this.openDialog('bookingCheckup');
  }

  searchBookingRef(form: NgForm): void {
    if (form.value.bookingReference !== undefined && form.value.bookingReference != null) {
      let bookNo = '';
      const stringLength = form.value.bookingReference.length;
      form.value.bookingReference = form.value.bookingReference.trim(); // Remove whitespaces
      // Check if user entered only numbers and if the reference is 7 characters or less
      if (this.prefixOn && form.value.bookingReference.match(/^[0-9]+$/) !== null && form.value.bookingReference.length < 8) {

        for (let i = stringLength; i < 7; i++) { bookNo = '0' + bookNo; } // Add any missing leading zeroes to the booking reference
        bookNo = bookNo + form.value.bookingReference; // Add user input to missing zeroes
        // Call this to clear out current booking data and to navigate to booking portfolio
        this.closeBooking('fromScript').then((res: any) => {
          this.navigateToBooking(this.bookSearch + bookNo, false); // Go to the booking first..
          form.controls.bookingReference.reset(); // Reset booking reference field in top right corner
        });
      } else if (!this.prefixOn && form.value.bookingReference.length < 26) {
        // Call this to clear out current booking data and to navigate to booking portfolio
        this.closeBooking('fromScript').then((res: any) => {
          this.navigateToBooking(form.value.bookingReference, false); // Go to the booking first..
          form.controls.bookingReference.reset(); // Reset booking reference field in top right corner
        });
      } else {
        // Call this to clear out current booking data and navigate back to the dashboard
        this.closeBooking('fromScript').then((res: any) => { this.router.navigate(['/']); });
        this.systemMessage = 'Requested booking reference is in the wrong format';
        this.openDialog('message'); // Open dialog with error message
      }
    }
  }

  iconToBookingTab(bookingReference: any, option: any): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      // Naviagte to the booking reference, straight into tab number OPTION
      this.router.navigate(['/booking/' + bookingReference + '/' + option]));
  }

  iconToBookingElement(type: any, costings: any): void {
    try {
       // Find the supplier in the array by its ID..
      const supplierInfo = Session.mySession.supplierList.supplierList.find((supplier: any) => supplier.id.toString() === costings.supplierID.toString());
      Session.mySession.setElementSupplier(supplierInfo); // Assign supplier information in the session variable
    } catch {
      Session.mySession.setElementSupplier({}); // Not existent supplier / supplier ID not there (external bookings..)
    }
    const blockSuppRefs: any = []; // Create an array list which will contain invalid supplier references (cannot use already existing ones in new element)
    const openedBooking = this.bookingData; // Create a variable which will hold booking data (and more below). Used in create-element page..

    this.bookingCostings.forEach((costing: any) => {
      // Dump all but one supplier reference in the array (other than selected one)
      if (costing.id !== costings.id) { blockSuppRefs.push(costing.supplierReference); }
    });
    openedBooking.blockSuppRefs = blockSuppRefs; // Assign supp refs to block to the object here..

    Session.mySession.setOpenedBooking(openedBooking); // Set booking data inside session variable..

    if (type !== 'package') {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        // Naviagte to the element edit page, straight into the type of TYPE and with COSTINGS saved
        this.router.navigateByUrl('/element/' + type, { state: costings }));
    } else {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        // Naviagte to the element edit page, straight into the type of TYPE and with COSTINGS saved
        this.router.navigateByUrl('/package/edit', { state: costings }));
    }
  }

  fellohNavOnOff(): void {
    if (this.fellohNav === true) {
      this.fellohNav = false;
      this.router.navigate(['/']);
    } else {
      this.fellohNav = true;
      this.router.navigate(['/paymentLinks']); // redirect to Felloh app
    }
  }

  getRouterLink(which: any): any {
    const user = this.session.getUser();

    if (which === 'new') { return '/newPaymentLink'; }
    else if (which === 'list') { return '/paymentLinks'; }
  }

  searchPrefixOnOff(): void {
    if (this.prefixOn) { this.prefixOn = false; this.maxBkLength = 25; }
    else { this.prefixOn = true; this.maxBkLength = 7; }
  }

  openDialog(which: any): void {
    if (which === 'userInfo') { this.passwordMessage = ''; this.dialog.open(this.userInfoBox, { autoFocus: false }); }
    else if (which === 'calendarInfo') { this.passwordMessage = ''; this.dialog.open(this.userCalendarBox, { autoFocus: false }); }
    else if (which === 'message') { this.dialog.open(this.systemMessageBox); }
    else if (which === 'bookingList') {
      this.outOptionExternal = 'List';
      this.dialog.open(this.bookingExternalBox, {
        panelClass: 'bookingExternalBox', disableClose: false, autoFocus: false }
      );
    } else if (which === 'bookingCheckup') {
      this.outOptionExternal = 'View';
      this.dialog.open(this.bookingExternalBox, {
        panelClass: 'bookingExternalBox', disableClose: false, autoFocus: false }
      );
    }
  }

  menuReset(): void {
    this.menuMain = false;
    this.menuGroup = false;
    this.menuSource = false;
    this.menuMarketing = false;
    this.menuSetup = false;
    this.filterString = ''; this.filterSelect(); // Reset filtered branches array - quick!
  }

  filterSelect(): void {
    this.usersBranchesFiltered = []; // Empty filtered array first
    const filter = this.filterString.toLowerCase(); // Get the string we filter with here

    // Loop through our MAIN array and add whatever matches our search string
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.usersBranches.length; i++) {
      const option = this.usersBranches[i];
      if (option.fullName.toLowerCase().indexOf(filter) >= 0) {
        this.usersBranchesFiltered.push(option);
      }
    }
  }

  getGoogleCalendar(): Promise<any> {
    return new Promise((resolve, reject) => {

      if (Session.mySession.getUser().googleCalID && Session.mySession.getUser().googleCalAPIkey) {
        this.userService.getGoogleEvents(Session.mySession.getUser()).then((resEvents: any) => {
          const nextWeek: any = new Date(); nextWeek.setDate(nextWeek.getDate() + 7); // Get yesterday's date here
          const calendarData: any = []; // This is where we'll dump all calendar data in
          let eventsInWeek = 0; // This is where we'll recount the number of events within a week

          resEvents.items.forEach((event: any) => {
            // Some events will not have exact times in it (e.g. they last for the whole day). Below we check if appropiate property exist
            const startDate = event.start?.date && new Date(event.start.date) || event.start?.dateTime && new Date(event.start.dateTime);
            const endDate = event.end?.date && new Date(event.end.date) || event.end?.dateTime && new Date(event.end.dateTime);
            // In case the event lasts for the whole day (e.g. 2 days), we'll need to deduct one day
            // We also need to convert Date into DateTime so it properly displays times (if event lasts only 1 day)
            if (event.end?.date) {
              startDate.setHours(1); startDate.setMinutes(0);
              endDate.setDate(endDate.getDate() - 1); endDate.setHours(23); endDate.setMinutes(0);
            }
            // For each element from Google, we'll create a new object
            const newEvent = {
              start: startDate, // Start date
              end: endDate, // End date
              title: event.summary, // This is not description, just a title
              htmlLink: event.htmlLink, // onClick will redirect to this pagenew Date(event.start.dateTime)
              color: {
                primary: '#4D5FD1',
                secondary: '#D1E8FF',
              },
              allDate: false,
              resizable: {
                beforeStart: false,
                afterEnd: false,
              },
              draggable: false,
            };
            // Check if the event is happening within 7 days from now on (including)
            if (new Date(this.viewDate) <= new Date(endDate) && new Date(endDate) <= new Date(nextWeek)) {
              eventsInWeek += 1; // Event within a week
            }

            calendarData.push(newEvent);
          });
          this.calendarExists = true; // Calendar exists - open the UI for it..
          this.calendarEvents = calendarData; // Assign calendar data as per objects created earlier..
          resolve('');
        }).catch((error: any) => {
          // Make sure calendar does not exist and calendar data is empty
          this.calendarExists = false; this.calendarEvents = [];
          resolve('');
        });
      }
    });
  }

  getBookingEvents(): void {
    const eventsCalendar = this.session.getYtdReportsValues().eventsCalendar;

    const calendarData: any = []; // This is where we'll dump all event data in

    eventsCalendar.forEach((event: any) => {
      // We need to parse string into date first
      const dateStart = new Date(event.dateTime);
      const dateEnd = new Date(event.dateTime); dateEnd.setHours(dateEnd.getHours() + 1);
      // For each element from Event Calendar, we'll create a new object
      const newEvent = {
        start: dateStart, // Start date
        end: dateEnd, // End date
        elementType: event.elementType,
        dateType: event.dateType,
        bookingReference: event.bookingReference,
        title: event.bookingReference,
        description: event.description,
        color: {
          primary: this.constants.getEventTextColour(event),
          secondary: '#D1E8FF',
        },
        allDate: false,
        resizable: {
          beforeStart: false,
          afterEnd: false,
        },
        draggable: false
      };
      calendarData.push(newEvent);
    });
    this.bookingEvents = calendarData; // Assign calendar data as per objects created earlier..
    this.calendarViewCurrent = CalendarView.Month; // Make sure calendar is set to Month
    this.closeOpenMonthViewDay(); // Make sure we're closing currently selected day
    this.googleCalendarOpen = false; // Make sure the view is being switched to google
  }

  setCalendarView(view: CalendarView): void {
    this.calendarViewCurrent = view; // Change view to day / week / month
  }

  closeOpenMonthViewDay(): void {
    this.activeDayIsOpen = false; // Close 'day box' where all events are listed for chosen day
  }

  clickGoogleEvent(event: any): void {
    window.open(event.htmlLink, '_blank')?.focus(); // Open new window and go to google calendar
  }

  clickCalendarEvent(event: any): void {
    this.navigateToBooking(event, true); // Use existing method to go to the booking
    this.dialog.closeAll(); // Close all dialogs afterwards..
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false; // Something went wrong or there's no events within date
      } else {
        this.activeDayIsOpen = true; // Open 'day box' and show event list within it
      }
      this.viewDate = date;
    }
  }

  generateRandomString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  setBrowserClass() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isSafari) {
      this.renderer.addClass(document.body, 'safari-browser');
    } else {
      this.renderer.addClass(document.body, 'non-safari-browser');
    }
  }

  checkAndShowNotice() {
    const noticeKey = 'zoomNoticeDisplayCount';
    const maxDisplayCount = 0; // Change this to display a message..

    let displayCount = parseInt(sessionStorage.getItem(noticeKey) || '0', 10);

    if (displayCount < maxDisplayCount) {
      this.showNotice = true;
      displayCount++;
      sessionStorage.setItem(noticeKey, displayCount.toString());
      // Hide the notice after 6 seconds
      setTimeout(() => {
        this.showNotice = false;
      }, 6000); // 6000 milliseconds = 6 seconds
    }
  }
}

