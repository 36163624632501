<div class="mainDiv">
    <div class="chosenDiv">
        <mat-table class="tableWithinExpansV2" multiTemplateDataRows [dataSource]="contactData">
            <ng-container matColumnDef="contactType">
                <mat-header-cell *matHeaderCellDef>Contact Type</mat-header-cell>
                <mat-cell *matCellDef="let contact">
                    <mat-select ngModel name="contactType" #contactType="ngModel" placeholder="Contact Type" [(ngModel)]="contact.contactType">
                        <mat-option value="commercial">Commercial</mat-option>
                        <mat-option value="sfc" *ngIf="ownerType == 'supplier'">SFC</mat-option>
                        <mat-option value="taps">TAPS</mat-option>
                    </mat-select>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <mat-select ngModel name="contactType" #contactType="ngModel" placeholder="Contact Type" [(ngModel)]="newContact.contactType">
                        <mat-option value="commercial">Commercial</mat-option>
                        <mat-option value="sfc" *ngIf="ownerType == 'supplier'">SFC</mat-option>
                        <mat-option value="taps">TAPS</mat-option>
                    </mat-select>
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="contactName">
                <mat-header-cell *matHeaderCellDef>Contact Name</mat-header-cell>
                <mat-cell *matCellDef="let contact">
                    <input matInput ngModel required placeholder="Contact Name*" name="contactName"
                    #contactName="ngModel" [(ngModel)]="contact.contactName" maxlength="100">
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <input matInput ngModel required placeholder="Contact Name*" name="contactName"
                    #contactName="ngModel" [(ngModel)]="newContact.contactName" maxlength="100">
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="emailAddress">
                <mat-header-cell *matHeaderCellDef>Email Address</mat-header-cell>
                <mat-cell *matCellDef="let contact">
                    <input matInput ngModel required placeholder="Email Address*" name="emailAddress"
                    #emailAddress="ngModel" [(ngModel)]="contact.emailAddress" maxlength="100">
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <input matInput ngModel required placeholder="Email Address*" name="emailAddress"
                    #emailAddress="ngModel" [(ngModel)]="newContact.emailAddress" maxlength="100">
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="phoneNumber1">
                <mat-header-cell *matHeaderCellDef>Phone No. 1</mat-header-cell>
                <mat-cell *matCellDef="let contact">
                    <input matInput ngModel required placeholder="Phone Number 1" name="phoneNumber1"
                    #phoneNumber1="ngModel" [(ngModel)]="contact.phoneNumber1" maxlength="50">
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <input matInput ngModel required placeholder="Phone Number 1" name="phoneNumber1"
                    #phoneNumber1="ngModel" [(ngModel)]="newContact.phoneNumber1" maxlength="50">
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="phoneNumber2">
                <mat-header-cell *matHeaderCellDef>Phone No. 2</mat-header-cell>
                <mat-cell *matCellDef="let contact">
                    <input matInput ngModel required placeholder="Phone Number 1" name="phoneNumber2"
                    #phoneNumber2="ngModel" [(ngModel)]="contact.phoneNumber2" maxlength="50">
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <input matInput ngModel required placeholder="Phone Number 1" name="phoneNumber2"
                    #phoneNumber2="ngModel" [(ngModel)]="newContact.phoneNumber2" maxlength="50">
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="faxNumber">
                <mat-header-cell *matHeaderCellDef>Fax Number</mat-header-cell>
                <mat-cell *matCellDef="let contact">
                    <input matInput ngModel required placeholder="Fax Number" name="faxNumber"
                    #faxNumber="ngModel" [(ngModel)]="contact.faxNumber" maxlength="50">
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <input matInput ngModel required placeholder="Fax Number" name="faxNumber"
                    #faxNumber="ngModel" [(ngModel)]="newContact.faxNumber" maxlength="50">
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="active">
                <mat-header-cell *matHeaderCellDef>Update / Active</mat-header-cell>
                <mat-cell *matCellDef="let contact">
                    <span>
                        <button mat-button color="primary" type="button" [disableRipple]="true"
                        (click)="updateContactDetails(contact, false)">
                            <mat-icon>update</mat-icon>
                        </button>
                    </span>
                    
                    <span *ngIf="contact.active == 'yes'">
                        <button mat-button color="primary" type="button" [disableRipple]="true"
                        (click)="updateContactDetails(contact, 'no')">
                            <mat-icon>done</mat-icon>
                        </button>
                    </span>

                    <span *ngIf="contact.active == 'no'">
                        <button mat-button color="warn" type="button" [disableRipple]="true"
                        (click)="updateContactDetails(contact, 'yes')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </span>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                    <button type="button" mat-button color="primary" [disableRipple]="true"
                    (click)="createContactDetails()">
                        <mat-icon>add</mat-icon>
                    </button>
                </mat-footer-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns : displayedColumns;" class="trueRow"></mat-row>
            <mat-footer-row class="rowNotClick" *matFooterRowDef="displayedColumns"></mat-footer-row>
        </mat-table>

        <h3 style="text-align: center;">Fill in above fields in order to add another contact details</h3>

        <h4 style="text-align: center;"><i>There can be only one active contact per contact type</i></h4>
    </div>
</div>

<ng-template #myDialog>
    <div *ngIf="errorMessage != ''" class="errorMessage">
        <p>{{errorMessage}}</p>
        <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary"
                matDialogClose>OK</button></p>
    </div>

    <div *ngIf="successMessage != ''" class="successMessage">
        <p>{{successMessage}}</p>
        <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary"
                matDialogClose>OK</button></p>
    </div>
</ng-template>