import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { CustomerService } from '../../services/customer.service';
import { BranchService } from '../../services/branch.service';
import { ReportsService } from '../../services/reports.service';
import { UserService } from '../../services/user.service';
import { GlobalConstants } from '../../common/global-constants';
import { Session } from '../../common/session';
import { MatTableDataSource } from '@angular/material/table';
import { NgForm } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from './../../../environments/environment';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', marginTop: '-1.25px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0 }),
            animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)',
              style({ height: 300 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 300 }),
            animate('250ms cubic-bezier(0.4, 0.0, 0.2, 1)',
              style({ height: 0 }))
          ]
        )
      ]
    ),
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    ),
    trigger('customExpansionDetails', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', opacity: 0 })),
      state('expanded', style({ height: '*', opacity: 1 })),
      transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class CustomerListComponent implements OnInit {
  // Imported variables from outside
  constants = new GlobalConstants();
  countryList = GlobalConstants.countryList;
  countryFiltered = GlobalConstants.countryList;
  holidayInterests = GlobalConstants.holidayInterests;
  titles = GlobalConstants.titles;
  innerWidth = AppComponent.myapp.innerWidth;

  // Boolean deciding whether user has access or not
  userType = '';
  pageLoaded = true;
  haveAccess = false;
  createView = false;
  allUsersView = true;

  // CRF75 - Duplicate customer. Display / not display message about duplicate customers
  createConfirm = true;
  showDupliBox = false;

  // Custom mat expansion variables
  expansionSearch = true;
  expansionList = true;
  addCustomer = true;
  uploadCustomers = true;

  // Other variables
  currentRequest: any = {};
  errorMessage: any = '';
  successMessage: any = '';
  uploadedFileName: any = ''; // File name of the uploaded CSV (not uploaded at the end)
  csvCustomerData: any = []; // Holds uploaded customer data which is then sent to Ruby
  filterString: any = ''; // Used to filter country list (and maybe more in the future..)

  // Address variables which allows postcode lookup
  everyoneCustomer: any = { id: 0, fullName: 'Everyone but restricted team members' };
  addressLookup: any = { address1: '', address2: '', address3: '', address4: '', postCode: '', postCodeString: '', countyLine: '', countryLine: '' };
  postCodeString: any = '';
  rawAddressList: any = [];
  addressList: any = [];
  selHolidayInterests: any = [];
  userList: any = [];

  // The customerData from API will be assign to this variable. The MatTableDataSource class
  // is used for to the whole Component to work (+ it has its own built-in functions)
  customerData: any = new MatTableDataSource<any>();

  // List of the table columns which are displayed in .html file
  displayedColumns = ['fullName', 'email', 'fullAddress', 'postCode', 'tradeCode', 'expand'];

  // Import the const arrays
  branches: any = [];

  // Custom mat paginator varaibles
  pageSizeOptions = [25, 50, 100];
  pageSize = 25;
  firstRec = 1;
  lastRec = 25;

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;
  @ViewChild('helpDialog') helpDialog!: TemplateRef<any>;

  constructor(private router: Router, private userService: UserService,
              private customerService: CustomerService, public dialog: MatDialog,
              private branchService: BranchService, private ngxCsvParser: NgxCsvParser,
              private reportsService: ReportsService) {
  }

  // Stuff needed for the 'expandable rows' to work
  expandedElement: any;
  isExpansionDetailRow = (i: number, row: object) => row.hasOwnProperty('detailRow');

  ngOnInit(): void {
    if (sessionStorage.length === 0 || Session.mySession === undefined) {
      // If the browser refreshed, SinGS redirects the page to the main page (components/home)
      this.router.navigate(['/']);
    } else {
      // If the page has been accessed by clicking a button - get userType & userCompany and load the page
      this.userType = Session.mySession.getUser().userType;
      // This function is called only once when loading the page for the first time. That's why we set the reqeust with default values here
      this.currentRequest = {
        tradeCode: Session.mySession.getUser().tradeCode, token: Session.mySession.get('user').token, firstRec: this.firstRec, lastRec: this.lastRec
      };

      // Apply exclusiveToId filter if user is not permitted to see others' bookings
      if (Session.mySession.getUser().othersBookingAccess === 'no') {
        this.currentRequest.exclusiveToId = Session.mySession.getUser().id.toString();
      }
      
      this.setUserList().then(() => {
        this.loadPage();
      });
    }
  }

  loadPage(): void {
    this.haveAccess = true;
    if (Session.mySession.getUsersGroup().length > 0) {
      // Logged in user is in the group and branch list already exists within the session variable..
      this.branches = Session.mySession.getUsersGroup();
      this.getAllCustomersFiltered();
    } else if (Session.mySession.getBranchList().expiryTime === 'EXPIRED') {
      this.branchService.getBranches(Session.mySession.getUser()).then((branches: any) => {
        if (branches.status === 'OK') {
          // Assign sorted to global variable
          const sorted = branches.data.sort((a: any, b: any) => (a.tradeCode > b.tradeCode) ? 1 : -1);
          Session.mySession.setBranchList(sorted);
          // If it happens to be a Q0000 user (admin), rename the Q0000 branch to 'All Branches' by excluding it and shifting new one at start
          // Otherwise do nothing and assign sorted to global branches variable
          if (Session.mySession.getUser().tradeCode === 'Q0000') {
            const allBranch = { tradeCode: 'Q0000', fullName: 'All Branches' };
            this.branches = sorted.filter((branch: any) => branch.tradeCode !== 'Q0000');
            this.branches.unshift(allBranch);
            this.currentRequest.tradeCode = allBranch.tradeCode;
          } else {
            this.branches = sorted;
          }
          // Call the actual function which gets customers here
          this.getAllCustomersFiltered();
        } else {
          // Status was not OK meaning something went wrong with it.. display it to the user
          this.sendMessageToDialog('', branches.status, '', '');
          this.haveAccess = false;
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0407S)', error, Session.mySession.getUser());
      });
    } else {
      // Get branch list from the session varaible - no need to call API
      this.branches = Session.mySession.getBranchList().branchList;
      // If it happens to be a Q0000 user (admin), rename the Q0000 branch to 'All Branches' by excluding it and shifting new one at start
      // Otherwise do nothing and assign sorted to global branches variable
      if (Session.mySession.getUser().tradeCode === 'Q0000') {
        const allBranch = { tradeCode: 'Q0000', fullName: 'All Branches' };
        this.branches.unshift(allBranch);
        this.currentRequest.tradeCode = allBranch.tradeCode;
      }
      // Call the actual function which gets customers here
      this.getAllCustomersFiltered();
    }
  }

  editCustomer(form: NgForm, customerInput: any): void {
    const customer = { ... customerInput };
    // Validate characters entered in the form
    if (this.constants.validateFormCharacters(form) !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else if (this.constants.validateVariableRegex(form.value.customerNotes, 'variable') !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + form.value.customerNotes, '', '');
    } else {
      // Assign token into form hash input (necessary for the API)
      customer.token = Session.mySession.get('user').token;
      // DoB isn't required anymore, hence we need to check if it was provided by the usr before converting it
      if (form.value.dob !== undefined && form.value.dob !== '') {
        customer.dob = this.constants.convertDateMoment(form.value.dob);
        // In case the date was entered in the wrong format, 'erase' it instead..
        if (moment(customer.dob, 'YYYY-MM-DD', true).isValid() === false) { customer.dob = null; }
      }
      // If postcode haven't been touched, just use old postcode
      // Otherwise postCodeString will contain user input - use it to update customer, if exists
      if (this.postCodeString !== '') { customer.postcode = this.postCodeString; }
      // Join all selected holiday interests into a string (from array of strings). Separate them with semicolon
      customer.holidayInterest = this.selHolidayInterests.join(';');

      // Make sure the exclusiveToId is set to null if it's set to 0
      if (customer.exclusiveToId === 0) { customer.exclusiveToId = null; }

      this.pageLoaded = false;
      this.customerService.updateCustomer(customer).then((result: any) => {
        // Update customer and display appropiate message
        if (result.status === 'OK') {
          if (result.custCount > 1) {
            this.sendMessageToDialog('Customer has been updated (This customer may have duplicates)', '', '', '');
          } else {
            this.sendMessageToDialog('Customer has been updated', '', '', '');
          }
        } else {
          this.sendMessageToDialog('', result.status, '', '');
        }
        // Clear postCodeString variable here
        this.clearPostCodeString();
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0402S)', error, customer);
      });
    }
  }

  removeCustomer(customer: any): void {
    if (confirm('Are you sure to remove ' + customer.firstName + ' ' + customer.lastName)) {
      if (confirm('100% sure you want to remove ' + customer.firstName + ' ' + customer.lastName)) {
        this.pageLoaded = false;
        customer.token = Session.mySession.get('user').token;
        this.customerService.removeCustomer(customer).then((result: any) => {
          if (result.status === 'OK') {
            this.customerData.data = this.customerData.filteredData.filter((item: any) => item.id !== customer.id);
            this.sendMessageToDialog('Customer has been removed', '', '', '');
          } else {
            this.sendMessageToDialog('', result.status, '', ''); // Return error status message back to the user
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0409S)', error, customer);
        });
      }
    }
  }

  createCustomer(form: NgForm): void {
    // Validate characters entered in the form
    if (this.constants.validateFormCharacters(form) !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else if (this.constants.validateVariableRegex(form.value.customerNotes, 'variable') !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + form.value.customerNotes, '', '');
    } else {
      // Assign token into form hash input (necessary for the API)
      form.value.token = Session.mySession.get('user').token;
      // DoB isn't required anymore, hence we need to check if it was provided by the usr before converting it
      if (form.value.dob !== undefined && form.value.dob !== '') {
        form.value.dob = this.constants.convertDateMoment(form.value.dob);
        // In case the date was entered in the wrong format, 'erase' it instead..
        if (moment(form.value.dob, 'YYYY-MM-DD', true).isValid() === false) { form.value.dob = null; }
      }
      // CRF75 - By default the unique field will be set to true. Only after checking the checkbox we will allow duplicates
      if (this.showDupliBox && this.createConfirm) { form.value.onlyUnique = false; }
      // Get postcode value from postCodeString - autocomplete thing (annoying it is but I made it work!)
      form.value.postcode = this.postCodeString;
      // Join all selected holiday interests into a string (from array of strings). Separate them with semicolon
      form.value.holidayInterest = this.selHolidayInterests.join(';');

      // Apply exclusiveToId filter if user is not permitted to see others' bookings
      if (Session.mySession.getUser().othersBookingAccess === 'no') {
        form.value.exclusiveToId = Session.mySession.getUser().id.toString();
      }

      this.pageLoaded = false;
      this.customerService.createCustomer(form.value).then((result: any) => {
        if (result.status === 'OK') {
          this.switchView('all');
          this.sendMessageToDialog('Customer has been created', '', '', '');
        } else if (result.status === 'Error - this customer already exist in the system') {
          // We are not creating the customer yet - instead we'll display a checkbox to do. Re-send form to create duplicate
          this.showDupliBox = true; this.createConfirm = false; this.pageLoaded = true;
        } else {
          this.sendMessageToDialog('', result.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0403S)', error, form.value);
      });
    }
  }

  // Functions dealing with auto-complete address (postcode)
  postCodeLookup(event: any): void {
    this.postCodeString = event.target.value;
    // Whenever user types into the field, assign to variable in UPCASE
    // If it meets the regex then proceed with if statement. Otherwise - clear the list
    if (/^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/.test(event.target.value.toUpperCase())) {
      const niceOutput: any = [];
      // Postcode API needs two calls to be sent in order to retrieve address list we need
      // Second call requires container together with the postcode value
      const request = { postcode: event.target.value.toUpperCase(), container: '' };
      this.customerService.getPostcodeAddress(request).then((results: any) => {
        request.container = results.Items[0].Id;
        this.customerService.getPostcodeAddress(request).then((output: any) => {
          let address = '';
          // We need to break the address list output to an array(s) so we can work with them
          output.Items.forEach((addressLine: any) => {
            address = addressLine.Text + ', ' + addressLine.Description;
            niceOutput.push(address);
          });
          // Both varaibles contain same data here but they're used in a different way later
          this.rawAddressList = niceOutput;
          this.addressList = niceOutput;
        }).catch((error: any) => {
          //this.sendMessageToDialog('', 'Post Code checker could not complete your request at this time (E0402P)', error, request);
        });
      }).catch((error: any) => {
        //this.sendMessageToDialog('', 'Post Code checker could not complete your request at this time (E0401P)', error, request);
      });
    } else {
      this.addressList = [];
    }
  }

  selectPostcode(event: any, option: any, customer: any): void {
    const selectedRaw = this.rawAddressList[event].split(', ');
    this.filterString = ''; this.filterSelect(); // Whether there was a string or not - we need to erase the 'Search..' bit
    // Whether we assign the auto-address to new or existing customers, we have no way of knowing whether something is county, postcode etc..
    // With the current API provider, the lines are not cohesive hence we're assigning whatever's first to address1 etc. etc.
    if (option === 'new') {
      // Erase current selection below first
      this.addressLookup.address1 = ''; this.addressLookup.address2 = ''; this.addressLookup.address3 = ''; this.addressLookup.address4 = '';
      if (selectedRaw[0] != null && selectedRaw[0] !== this.postCodeString.toUpperCase()) { this.addressLookup.address1 = selectedRaw[0]; }
      if (selectedRaw[1] != null && selectedRaw[1] !== this.postCodeString.toUpperCase()) { this.addressLookup.address2 = selectedRaw[1]; }
      if (selectedRaw[2] != null && selectedRaw[2] !== this.postCodeString.toUpperCase()) { this.addressLookup.address3 = selectedRaw[2]; }
      if (selectedRaw[3] != null && selectedRaw[3] !== this.postCodeString.toUpperCase()) { this.addressLookup.address4 = selectedRaw[3]; }
      // tslint:disable-next-line:no-non-null-assertion
      (document.getElementById('postCode')! as HTMLInputElement).value = this.postCodeString;
      this.addressLookup.countryLine = 'United Kingdom';
    } else {
      // Erase current selection below first
      customer.addressLine1 = ''; customer.addressLine2 = ''; customer.addressLine3 = ''; customer.addressLine4 = '';
      if (selectedRaw[0] != null && selectedRaw[0] !== this.postCodeString.toUpperCase()) { customer.addressLine1 = selectedRaw[0]; }
      if (selectedRaw[1] != null && selectedRaw[1] !== this.postCodeString.toUpperCase()) { customer.addressLine2 = selectedRaw[1]; }
      if (selectedRaw[2] != null && selectedRaw[2] !== this.postCodeString.toUpperCase()) { customer.addressLine3 = selectedRaw[2]; }
      if (selectedRaw[3] != null && selectedRaw[3] !== this.postCodeString.toUpperCase()) { customer.addressLine4 = selectedRaw[3]; }
      customer.postcode = this.postCodeString;
      // tslint:disable-next-line:no-non-null-assertion
      (document.getElementById('postCode' + customer.id)! as HTMLInputElement).value = this.postCodeString;
      customer.country = 'United Kingdom';
    }
  }

  clearPostCodeString(): void {
    // Clear the postCodeString variable
    this.postCodeString = '';
    this.addressLookup = { address1: '', address2: '', address3: '', address4: '', postCode: '', postCodeString: '', countyLine: '', countryLine: '' };
    this.addressList = [];
    this.rawAddressList = [];
  }

  setHolidayInterests(customer: any): void {
    // Holiday interests are stored as a string - separated by comma
    // We need to convert it to array so the whole mini-system works as designed
    if (customer.holidayInterest !== null && customer.holidayInterest !== '') {
      this.selHolidayInterests = customer.holidayInterest.split(';');
    } else {
      this.selHolidayInterests = []; // Anything non-expected - set as empty
    }
  }

  addRemoveHolInterest(event: any): void {
    if (!event.target.checked) {
      // Remove selected holiday interest from the array list
      this.selHolidayInterests.splice(this.selHolidayInterests.indexOf(event.target.value), 1);
    } else {
      // Add selected holiday interest into the list (end of the array but so what..?)
      this.selHolidayInterests.push(event.target.value);
    }
  }

  switchView(view: any): void {
    // Switch view controllers to display selected page
    this.expandedElement = null;
    this.createConfirm = true;
    this.showDupliBox = false;
    if (view === 'create') {
      this.createView = true;
      this.allUsersView = false;
      this.csvCustomerData = [];
      this.uploadedFileName = '';
    } else if (view === 'all') {
      this.createView = false;
      this.allUsersView = true;
      this.customerData.data = [];
      this.getAllCustomersFiltered();
    }
    this.clearPostCodeString(); // Make customer table data empty and remove postcodeString
    this.selHolidayInterests = []; // Make sure holiday interests are empty from the go
  }

  getCustomers(form: NgForm): void {
    // Validate characters entered in the form
    if (this.constants.validateFormCharacters(form) !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else {
      this.firstRec = 1; // Reassign the value back to default one
      this.lastRec = this.pageSize; // Reassign the value back to default one

      // Request's core parameters set up here
      this.currentRequest.tradeCode = form.value.tradeCode;
      this.currentRequest.firstName = form.value.firstName;
      this.currentRequest.lastName = form.value.surname;
      this.currentRequest.email = form.value.email;
      this.currentRequest.holidayInterest = form.value.holidayInterest;

      // Fire up getAllCustomersFiltered() function
      this.getAllCustomersFiltered();
    }
  }

  getAllCustomersFiltered(): void {
    this.pageLoaded = false;
    this.currentRequest.firstRec = this.firstRec; // Get the firstRec variable here
    this.currentRequest.lastRec = this.lastRec; // Get the lastRec variable here
    this.customerService.getCustomerListFiltered(this.currentRequest).then((customers: any) => {
      if (customers.status === 'success') {
        // It was a success and we've got some data here
        customers.customers.forEach((element: any) => {
          element.detailRow = true;         // Make the data 'expandable' on the UI
          if (element.exclusiveToId === null) {
              element.exclusiveToId = 0;       // Make sure the customerId is set to 0 (for select list)
          }
        });
        this.customerData.data = customers.customers;
        this.pageLoaded = true;
      } else if (customers.status === 'no customers found') {
        // We don't really want to display it to the user in the usual way
        // Hence we only clear out customerData instead
        this.customerData.data = [];
        this.pageLoaded = true;
      } else {
        // If it's anything else, pop the message up to the user
        this.sendMessageToDialog('', customers.status, '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0404S)', error, this.currentRequest);
    });
  }

  changePageSize(size: any): void {
    this.firstRec = 1; // We'll start from the 'bottom' again
    this.lastRec = size; // Last res will be the selected size
    this.pageSize = size; // Set the size requested by the user
    this.getAllCustomersFiltered();
  }

  changePageNumber(direction: any): void {
    // Depending on the arrow clicked, we must retrieve previous/next 'page'
    if (direction === 'previous') {
      this.firstRec -= this.pageSize;
      this.lastRec -= this.pageSize;
    } else if (direction === 'next') {
      this.firstRec += this.pageSize;
      this.lastRec += this.pageSize;
    }
    this.getAllCustomersFiltered();
  }

  getCSVtemplate(): void {
    // Create request variable and use it to get the tempalte file
    const request = { name: 'custDataTemplate.csv', type: 'csv', directory: 'temple', token: Session.mySession.get('user').token };
    this.pageLoaded = false;

    this.reportsService.getFile(request).then((blob: any) => {
      // Output BLOB needs to be transformed into an excel application file
      const data = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      saveAs(data, 'custDataTemplate.csv'); // Call this function which opens browser's 'Save As..' window
      this.pageLoaded = true;
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0408S)', error, request);
    });
  }

  validateCustomerFile(fileInputEvent: any): void {
    this.pageLoaded = false;
    // We use the ngxCsvParser here to read the data from CSV files. We want headers to false
    // beacuse that will 'translate' the data into the csv format we need at the back..
    this.ngxCsvParser.parse(fileInputEvent.srcElement.files[0], { header: false, delimiter: ',' })
      .pipe().subscribe((csvData: any) => {
        // Once we've got the data, we need to create a request variable which will hold it along with validation stuff
        const request = {
          company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation,
          tradeCode: this.currentRequest.tradeCode, token: Session.mySession.get('user').token, data: csvData
        };

        this.customerService.validateCustCSV(request).then((result: any) => {
          if (result.status === 'OK' && result.resErrCnt === 0) {
            this.csvCustomerData = csvData; // Assign CSV data to global variable which will be used later on
            this.uploadedFileName = fileInputEvent.target.files[0].name; // Assign file name to the input
            this.sendMessageToDialog('File has been validated', '', '', '');
          } else if (result.status === 'OK' && result.resErrCnt > 0) {
            this.sendMessageToDialog('', result.description, '', ''); // Validation was not successfull
            this.switchView('create'); // Resets both variables set by a success status
          } else {
            this.sendMessageToDialog('', result.status, '', ''); // Validation was not successfull
            this.switchView('create'); // Resets both variables set by a success status
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0405S)', error, request);
          this.switchView('create'); // Resets both variables set by a success status
        });
      }, (error: NgxCSVParserError) => {
        this.sendMessageToDialog('', 'BETA version error: ' + error, '', '');
        this.switchView('create'); // Resets both variables set by a success status
      });
  }

  uploadCustomerData(): void {
    this.pageLoaded = false;
    // Create a request variable which will use all validation properties and takes csv customer data from global variable
    const request = {
      company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation,
      tradeCode: this.currentRequest.tradeCode, token: Session.mySession.get('user').token, data: this.csvCustomerData
    };

    this.customerService.uploadCustCSVasAoA(request).then((result: any) => {
      if (result.status === 'OK' && result.errorCount === 0) {
        this.switchView('all'); // Customers uploaded! Switch view to 'all'
        this.sendMessageToDialog('Customers have been uploaded successfully!', '', '', ''); // Display OK message
      } else if (result.status === 'OK' && result.errorCount > 0) {
        this.sendMessageToDialog('', result.desc, '', ''); // Validation was not successfull
        this.switchView('create'); // Resets both variables set by a success status
      } else {
        this.sendMessageToDialog('', result.status, '', ''); // Validation was not successfull
        this.switchView('create'); // Resets both variables set by a success status
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0406S)', error, request);
      this.switchView('create'); // Resets both variables set by a success status
    });
  }

  setUserList(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.userType === 'memberManager' || this.userType === 'wcManager') {
        const request = { tradeCode: this.currentRequest.tradeCode, token: Session.mySession.get('user').token };

        if (Session.mySession.getUserList(this.currentRequest.tradeCode).expiryTime === 'EXPIRED') {
          this.userService.getUserList(request).then((users: any) => {
            if (users.status === 'OK') {
              Session.mySession.setUserList(this.currentRequest.tradeCode, users.data);
              this.userList = users.data.filter((user: any) => {
                return user.othersBookingAccess === 'no';
              });
              this.userList.unshift(this.everyoneCustomer);
            }
            resolve('');
          });
        } else {

          this.userList = Session.mySession.getUserList(this.currentRequest.tradeCode).userList;
          this.userList = this.userList.filter((user: any) => {
            return user.othersBookingAccess === 'no';
          });
          this.userList.unshift(this.everyoneCustomer);
          resolve('');
        }
      } else { resolve(''); } // We don't need to re-assign users as admins..
    }).catch((err: any) => {
      this.sendMessageToDialog('', 'Agent list failed to load', '', ''); // Display error message here..
    });
  }

  exportToExcel(): void {
    const exportRequest = this.currentRequest;
    // Borrow current request variable and assign 'from' and 'to' to pull all customers at once
    exportRequest.firstRec = 1; exportRequest.lastRec = 999999;

    this.pageLoaded = false;
    this.customerService.getCustomerListFiltered(exportRequest).then((customers: any) => {
      if (customers.status === 'success') {

        const exportMe: any = [];
        let oneLine: any = {};
        // For each customer object, oneLine will be generated with appropiate values
        // And then pushed to exportMe which will be.. exported
        customers.customers.forEach((data: any) => {
          oneLine = {};
          oneLine.title = data.title;
          oneLine.firstName = data.firstName;
          oneLine.middleName = data.middleName;
          oneLine.lastName = data.lastName;
          oneLine.dob = data.dob;
          oneLine.telNo = data.telNo;
          oneLine.homeNo = data.homeNo;
          oneLine.email = data.email;
          oneLine.tradeCode = data.tradeCode;
          oneLine.addressLine1 = data.addressLine1;
          oneLine.addressLine2 = data.addressLine2;
          oneLine.addressLine3 = data.addressLine3;
          oneLine.addressLine4 = data.addressLine4;
          oneLine.postCode = data.postcode;
          oneLine.county = data.county;
          oneLine.country = data.country;
          oneLine.companyName = data.companyName;
          oneLine.holidayInterests = data.holidayInterest;
          oneLine.customerNotes = data.customerNotes;
          exportMe.push(oneLine);
        });
        this.constants.exportAsExcelFile(exportMe, 'customerList');

        this.pageLoaded = true;
      } else {
        // If it's anything else, pop the message up to the user
        this.sendMessageToDialog('', customers.status, '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0404S)', error, exportRequest);
    });
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  getAddressLines(customer: any): string[] {
    const addressLines = [
      customer.addressLine1,
      customer.addressLine2,
      customer.addressLine3,
      customer.addressLine4,
      customer.county,
      customer.country
    ];
    return addressLines.filter(line => line !== null && line.trim() !== '');
  }  

  filterSelect(): void {
    this.countryFiltered = []; // Empty filtered array first
    const filter = this.filterString.toLowerCase(); // Get the string we filter with here

    // Loop through our MAIN array and add whatever matches our search string
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.countryList.length; i++) {
      const option = this.countryList[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.countryFiltered.push(option);
      }
    }
  }

  valiDate(event: any): void {
    if (event.value != null) {
      const dateIn = event.value._i;
      if (dateIn.year === undefined && // Whenever date is being changed, check if it's in one of three formats (backslash / dots / dash)
        !moment(dateIn, 'DD/MM/YYYY', true).isValid() && !moment(dateIn, 'DD.MM.YYYY', true).isValid() && !moment(dateIn, 'DD-MM-YYYY', true).isValid()) {
        this.sendMessageToDialog('', 'Please follow DD MM YYYY format', '', '');
        event.target.value = '';
      }
    }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    this.pageLoaded = true; this.dialog.open(this.statusDialog);
  }

  showHelp(): void {
    this.dialog.open(this.helpDialog);
  }
}
