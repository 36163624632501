import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from '../../common/global-constants';
import { environment } from '../../../environments/environment';
import { Session } from '../../common/session';
import { HostListener } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from "@angular/material/paginator";
import { UserService } from '../../services/user.service';
import { SupplierService } from '../../services/supplier.service';
import { TapsService } from 'src/app/services/taps.service';
import { ReportsService } from '../../services/reports.service';
import { AppComponent } from '../../app.component';
import * as XLSX from 'xlsx';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { map } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { AsynchRequests } from 'src/app/common/asynch-requests';
import { BranchService } from '../../services/branch.service';
import * as moment from 'moment';

moment.locale('en-gb');

@Component({
  selector: 'app-taps',
  templateUrl: './taps.component.html',
  styleUrls: ['./taps.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1, display: 'inline' }))
          ]
        )
      ]
    ),
    trigger('customExpansionDetails', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', opacity: 0 })),
      state('expanded', style({ height: '*', opacity: 1 })),
      transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', marginTop: '-1.25px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])],
})

export class TapsComponent implements OnInit {
  // Imported variables from outside
  constants = new GlobalConstants();
  innerWidth = AppComponent.myapp.innerWidth;

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('paginator') paginatorDocuments!: MatPaginator;
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;
  @ViewChild('helpDialog') helpDialog!: TemplateRef<any>;
  @ViewChild('contactDialog') contactDialog!: TemplateRef<any>;
  @ViewChild('commitAll') commitAll!: TemplateRef<any>;
  @ViewChild('commitUpload') commitUpload!: TemplateRef<any>;

  // Variables controlling user access
  userName = '';
  userEmail = '';
  userCompany = '';
  userTradeCode = '';
  userFullCompanyName = '';
  haveAccess = false;
  pageLoaded = false;
  loggedInUserType = '';
  onBookingPlatform = true;
  userHomeAddress = '';
  userHomeEmail = '';
  userHomeTelephone = '';

  // Other variables
  errorMessage: any = '';
  successMessage: any = '';
  expansionTAPSUserManual:boolean = true;

  // Taps Main Navigation List
  listOfPages =[ 
    {key:"homePage",value:true},
    {key:"agentManagementPage",value:false},
    {key:"supplierManagementPage",value:false},
    {key:"supplierStatusPage",value:false},
    {key:"unmatchedTapsPayments",value:false},
    {key:"lateAdjustmentsPage",value:false},
    {key:"exportOrImportTransactionsAdminPage",value:false},
    {key:"sendAgentRemindersPage",value:false},
    {key:"deleteUploadPage",value:false},
    {key:"commitUploadPage",value:false},
    {key:"collectionFromAgentsPage",value:false},
    {key:"deliveryToSuppliersPage",value:false},
    {key:"transactionsInvoicingPage",value:false},
    {key:"reportingPage",value:false},
    {key:"analysisReportingPage",value:false},
    {key:"transactionApprovalPage",value:false},
    {key:"exportOrImportTransactionsMemberPage",value:false},
    {key:"suppliersPage",value:false},
    {key:"reportingMemberPage",value:false},
    {key:"analysisReportingMemberPage",value:false},
    {key:"uploadFilePage",value:false},
    {key:"uploadHistoryPage",value:false},
    {key:"pullDownFilePage",value:false},
    {key:"agentsPage",value:false},
    {key:"reportingOperatorPage",value:false},
    {key:"uploadFileScreenPage",value:false},
    {key:"agentPage",value:false},
    {key:"supplierPage",value:false},
    {key:"deleteS3UploadsPage",value:false},
    {key:"bacsHistoryPage",value:false},
    {key:"switchToDevAndLivePage",value:false},
    {key:"bacsReportingPage",value:false},
    {key:"collectFtpPage",value:false},
    {key:"uploadHistoryAdmin",value:false}
  ];   

  //
  // Admin Variables (aka TapsAdmin) Below
  //

  // Admin - Agent Management & Reporting Filtering
  adminAgentListColumns = ['agentListName','agentListTapsIdenti','agentListTaps','agentListAddress']
  adminAgentListData:any = new MatTableDataSource<any>();
  backUpAgentListData:any = new MatTableDataSource<any>();
  agentFilter:any = "";
  listActiveAgents:any = true;

  // Admin - Agent Management / Agent View 
  viewAgentName:any = "";
  viewAgentTradeCode:any = "";
  viewAgentAddress:any = "";
  viewAgentContact:any = "";
  viewAgentTelephone:any = "";
  viewAgentEmail:any = "";
  viewAgentThreshold:any = "";
  viewAgentTaps:any = "";
  viewAgentHeadOffice:any = "";

  // Admin - Agent Management / Agent View / Bacs History
  tapsMembersBacsReportColumns = ["bacsReportTradeCode","bacsReportAgentName","bacsReportReference","bacsReportSortCode","bacsReportAC","bacsReportDue","bacsReportValue"];
  tapsMembersBacsReportData:any = new MatTableDataSource<any>(); 
  viewOverallBacsReportAmount:any = 0.00;

  // Admin & Member - Supplier Management & Suppliers
  suppliersListColumns = ['supplierName','supplierTapsIdenti','supplierTelephone','supplierAddress']
  suppliersListData:any = new MatTableDataSource<any>();
  activeSuppliersListData:any = new MatTableDataSource<any>();
  inactiveSuppliersListData:any = new MatTableDataSource<any>();
  backUpSupplierListData:any = new MatTableDataSource<any>();
  supplierFilter:any = "";
  listActiveSuppliers:any = true;

  // Admin - Supplier Management / Supplier View  
  viewSupplierName:any = "";
  viewSupplierTradeCode:any = "";
  viewSupplierAddress:any = "";
  viewSupplierEmail:any = "";
  viewSupplierContact:any = "";
  viewSupplierTelephone:any = "";
  viewSupplierTaps:any = "";
  viewSupplierDenyAmendments:any = "";
  viewSupplierTransactionFee:any = "";
  viewSupplierPaymentDays:any = "";

  // Admin - Supplier Status 
  selectedSupplierForStatusReport:any = "";
  adminSupplierStatusListColumns = ['supplierListName','supplierListTapsIdenti','failed','successful','lastSuccessfulDate','lastSuccessfulValue']
  adminSupplierStatusListData:any = new MatTableDataSource<any>();
  supplierStatusListSortedbyTradeCode:boolean = false;
  supplierStatusListSortedbyName:boolean = false;

  // Admin - UnMatched Taps Payments 
  unmatchedTapsPaymentsListColumns = ["unMatchedTransactionID","unMatchedCollectionDate","unMatchedSupplierName", "unMatchedSupplierTradeCode","unMatchedAgentName", "unMatchedAgentTradeCode","unMatchedOperatorReference","unMatchedClientName","unMatchedDepartureDate","unMatchedAmount","unMatchedBookingRefOther","unMatchedBookingReference", "unMatchedType"];
  unmatchedTapsPaymentsListData:any = new MatTableDataSource<any>();
  unmatchedCollectionDate:any = "";
  unmatchedAgent:any = "";

  // Admin - Late Adjustments 
  uploadFromDateAdmin:any = "";
  uploadToDateAdmin:any = ""; 
  lateAdjustmentUploadedFile:any = false;
  lateAdjustmentFile:any = "";
  viewAdjustmentUploadTapsId:any = "";
  viewAdjustmentUploadSupplierName:any = "";

  // Admin & Supplier - Late Adjustment & Upload File 
  typeOfFileUpload = "";

  // Admin - Export or Import Transactions
  listOfExportedAgents:any = new MatTableDataSource<any>();
  selectedAgentForAdminExport:any = "";
  filteredAdminAgentsListData:any = new MatTableDataSource<any>();
  filteredAdminSupplierListData:any = new MatTableDataSource<any>();
  filterAdminAgentsListString: any = '';
  filterAdminSupplierListString: any = '';

  // Admin - Send Agent Reminders 
  tapsAgentRemindersListColumns = ["agentReminderListName","agentReminderListTapsIdenti","agentReminderListTelephone","agentReminderListEmail","agentReminderListValue","agentSendReminder"];
  tapsAgentRemindersListData:any = new MatTableDataSource<any>();

  // Admin - Delete Upload
  adminDeleteUploadListColumns = ["deleteUploadListSupplier","deleteUploadListTapsIdenti","deleteUploadListUpload","deleteUploadListCollection","deleteUploadListValue","deleteUploadListState","deleteUploadListId"];                               
  adminDeleteUploadListData:any = new MatTableDataSource<any>();

  // Admin - Commit Upload
  adminCommitUploadListColumns = ["commitListSupplier","commitListABTA","commitListUpload","commitListCollection","commitListValue","commitListState","commitListId"];
  adminCommitUploadListData:any = new MatTableDataSource<any>();
  adminSelectedCommitUpload:any = null;
  adminCommitSelectedUpload = "";
  adminCommitNumOfTransactions = 0;
  adminCommitOriginalTotal = 0;
  adminCommitAdjustments = 0;
  adminCommitCollectionTotal = 0;
  adminCommitState = "";
  commitAllInProgress = false;

  // Admin - Collection from Agents
  collectionDueDateFrom:any = new Date("01/01/2000");
  collectionDueDateTo:any = "";
  collectionViewDueDate:any = "";
  tapsCollectionAgentAcceptedListColumns = ["collectionAgentAcceptedListCollection","collectionAgentAcceptedListTapsIdenti","collectionAgentAcceptedListName","collectionAgentAcceptedListSortcode","collectionAgentAcceptedListAC","collectionAgentAcceptedListType","collectionAgentAcceptedListAmount","collectionAgentAcceptedListApproved"];
  tapsCollectionAgentAcceptedListData:any = new MatTableDataSource<any>(); 
  tapsCollectionAgentExcelAcceptedData:any = new MatTableDataSource<any>(); 
  tapsCollectionAgentAcceptedOverall:any = 0;
  tapsCollectionAgentRejectedListColumns = ["collectionAgentRejectedListTapsIdenti","collectionAgentRejectedListName","collectionAgentRejectedListBookingRef","collectionAgentRejectedListDepart","collectionAgentRejectedListAmount"];
  tapsCollectionAgentRejectedListData :any = new MatTableDataSource<any>();  
  tapsCollectionAgentRejectedOverall:any = 0;

  // Admin - Delivery to Suppliers
  deliveryDueDateFrom:any = new Date("01/01/2000");
  deliveryDueDateTo:any = "";
  tapsDeliveryToSupplierAcceptedListColumns = ["deliveryToSupplierAcceptedListPayment","deliveryToSupplierAcceptedListTapsIdenti","deliveryToSupplierAcceptedListName","deliveryToSupplierAcceptedListSortcode","deliveryToSupplierAcceptedListPaymentListAC","deliveryToSupplierAcceptedListPaymentType","deliveryToSupplierAcceptedListPaymentDue","deliveryToSupplierAcceptedListApproved"];
  tapsDeliveryToSupplierAcceptedListData:any = new MatTableDataSource<any>();
  tapsDeliveryToSupplierExcelAcceptedData:any = new MatTableDataSource<any>();
  tapsDeliveryToSupplierAcceptedOverall:any = 0;
  tapsDeliveryToSupplierRejectedListColumns = ["deliveryToSupplierRejectedListTtaNo","deliveryToSupplierRejectedListName","deliveryToSupplierRejectedListRef","deliveryToSupplierRejectedListPaymentListDepart","deliveryToSupplierRejectedListAmount"];
  tapsDeliveryToSupplierRejectedListData:any = new MatTableDataSource<any>();  
  tapsDeliveryToSupplierRejectedOverall:any = 0;

  // Admin - Transactions Invoicing
  invoicingDateFrom:any = "";
  invoicingDateTo:any = "";
  tranInvoiceListColumns = ["tranInvoiceTapsId","tranInvoiceName","tranInvoiceQuantity","tranInvoiceRate","tranInvoiceValue","tranInvoiceChecked"]
  tranInvoiceListData:any = new MatTableDataSource<any>();
  transactionInvoicingListSortedbyTradeCode:boolean = false;
  transactionInvoicingListSortedbyName:boolean = false;
  transactionInvoicingListOfSuppliers:String[] = [];

  // Admin - Delete S3 Uploads 
  adminDeleteTapsUploadListColumns = ["deleteTapsUploadTransactionID","deleteTapsUploadListSupplier","deleteTapsUploadListTapsIdenti","deleteTapsUploadListUpload","deleteTapsUploadListCollection","deleteTapsUploadListValue","deleteTapsUploadListState","deleteTapsUploadListId"];                               
  adminDeleteTapsUploadListData:any = new MatTableDataSource<any>();

  //
  // Member Variables (aka Agent) Below
  //

  // Member - Transaction Approval
  transactionAvailability:boolean = false;
  transactionViewIfAvailable:boolean = true;
  membersRequestPeriod:string = "";
  tapsTransactionApprovalColumns = ["tranAprAgent","tranAprAgentTapsId", "tranAprSupplier", "tranAprSupTapsId", "tranAprRef","tranAprDepart","tranAprClient","tranAprValue", "bookingReference", "matchType","tranAprViewState"];
  tapsTransactionApprovalNoBookingColumns = ["tranAprAgent","tranAprAgentTapsId", "tranAprSupplier", "tranAprSupTapsId", "tranAprRef","tranAprDepart","tranAprClient","tranAprValue", "tranAprViewState"];
  tapsTransactionApprovalData:any = new MatTableDataSource<any>();
  tapsTransactionApprovalBackUpData:any = new MatTableDataSource<any>();
  transAprCollectionDate:any = "";
  tranAprTotalTransactions:any = 0;
  tranAprOriginalTotal:any = 0;
  tranAprAdjustmentsTotal:any = 0;
  tranAprAdjustmentsPercentage:any = 0;
  tranAprCollectionTotal:any = 0;
  transactionStateOptions: any[] = [
    { label: 'PENDING APPROVAL', value: 'PENDING APPROVAL' },
    { label: 'TRANSACTION ACCEPTED', value: 'TRANSACTION ACCEPTED' },
    { label: 'A - BOOKING NOT YET DUE', value: 'A - BOOKING NOT YET DUE' },
    { label: 'B - BOOKING ALREADY PAID', value: 'B - BOOKING ALREADY PAID' },
    { label: 'C - CANCELLED BOOKING', value: 'C - CANCELLED BOOKING' },
    { label: 'D - DUPLICATE TRANSACTION', value: 'D - DUPLICATE TRANSACTION' },
    { label: 'E - INCORRECT COMMISSION LEVEL', value: 'E - INCORRECT COMMISSION LEVEL' },
    { label: 'F - CLIENT NON-PAYMENT OR CLEARING', value: 'F - CLIENT NON-PAYMENT OR CLEARING' },
    { label: 'G - BOOKING NOT RECOGNISED', value: 'G - BOOKING NOT RECOGNISED' },
    { label: 'H - AWAITING AMENDED INVOICE', value: 'H - AWAITING AMENDED INVOICE' },
  ];
  currertUploadPeriod:any = "";
  needsToBeSaved:any = false;
  memberTransactionListSortAgent:boolean = false;
  memberTransactionListSortAgentTaps:boolean = false;
  memberTransactionListSortSupplier:boolean = false;
  memberTransactionListSortSupplierTaps:boolean = false;
  memberTransactionListSortRef:boolean = false;
  memberTransactionListSortDepart:boolean = false;
  memberTransactionListSortClient:boolean = false;
  memberTransactionListSortValue:boolean = false;
  memberTransactionListSortBookingReference:boolean = false;
  memberTransactionListSortMatchType:boolean = false;
  memberTransactionListSortState:boolean = false;
  transactionApprovalBlocked:boolean = false;

  // Member - Export or Import Transactions
  importedTFAFile:any = "";

  // Member - Suppliers 
  memberSupplierListSortedbyName:boolean = false;
  memberSupplierListSortedbyTradeCode:boolean = false;

  //
  // Supplier Variables (aka Operator) Below
  //

  // Supplier - Upload File
  preImportedTransactionID:String = "";
  supplierUploadedFile:any = false;
  supplierUploadFile:any = "";
  uploadAvailability:boolean = false;
  uploadFromDate:any = "";
  uploadToDate:any = "";
  userSupplierName:any = "";
  userSupplierMisttaCode:any = "";
  userSupplierPaymentDays:any = "";
  userSupplierTapsTradeCode:String = ""; 
  userSupplierTapsExcludeFlag:String = "";  
  userSupplierOnAtol:boolean = false; 
  
  // Supplier - Upload History
  datePeriods:any = [];
  selectedDatePeriod:any = {};
  selectedDateFrom:any = "";
  selectedDateTo:any = "";
  tapsUploadHistoryListColumns = ["uploadPeriod","tranValue","tranState","tranFailure","numOfTransactions"]
  tapsUploadHistoryListData:any = new MatTableDataSource<any>();
  supplierUploadHistorySortedbyDate:boolean = false;
  supplierUploadHistorySortedbyValue:boolean = false;
  supplierUploadHistorySortedbyState:boolean = false;
  supplierUploadHistorySortedbyFailure:boolean = false;
  supplierUploadHistorySortedbyTransactions:boolean = false;

  // Supplier - Upload History / Upload File Screen
  viewTransactionID = "";
  viewOriginalTransactionID = "";
  viewUploadDate = "";
  viewNumOfTransactions = "0";
  viewNumOfRejectedTransactions = "0";
  viewValue:any = 0;
  viewAdjustments:any = 0;
  viewOverallAdjustments:any = 0;
  viewAgentReview = "";
  viewToPay:any = 0;
  viewPaymentDue = "";
  tapsUploadTransactionColumns = ["tranAgent","tranTapsId","tranRef","tranDepart","tranClient","tranWarning","tranValue","tranAdjust","tranCollectedValue","tranCode","tranName"];
  tapsUploadTransactionData:any = new MatTableDataSource<any>();
  tapsUploadRejectedTransactionColumns = ["tranAgent","tranTapsId","tranRef","tranDepart","tranClient","tranValue","tranFailure"];
  tapsUploadTransactionRejectedData:any = new MatTableDataSource<any>();
  supplierUploadTransactionSortedbySupplier:boolean = false;
  supplierUploadTransactionSortedbySupId:boolean = false;
  supplierUploadTransactionSortedbyAgent:boolean = false;
  supplierUploadTransactionSortedbyTapsId:boolean = false;
  supplierUploadTransactionSortedbyRef:boolean = false;
  supplierUploadTransactionSortedbyDepart:boolean = false;
  supplierUploadTransactionSortedbyClient:boolean = false;
  supplierUploadTransactionSortedbyWarning:boolean = false;
  supplierUploadTransactionSortedbyValue:boolean = false;
  supplierUploadTransactionSortedbyAdjust:boolean = false;
  supplierUploadTransactionSortedbyCollection:boolean = false;
  supplierUploadTransactionSortedbyCode:boolean = false;
  supplierUploadTransactionSortedbyName:boolean = false;
  supplierUploadTransactionSortedbyPayment:boolean = false;
  viewAllTransactions:any = []
  viewRejectedTransactions:any = []
  viewTransactionState:any = "";
  viewTranFailureState:any = "";
  viewOverallAcceptedAmount:any = 0;
  viewOverallRejectedAmount:any = 0;
  viewDownloadFile:any = new MatTableDataSource<any>();;
  viewTransactionFailure:any = "";

  // Supplier - Pull Down File
  pullDownFilePeriods:any = [];
  selectedPullDownFilePeriod:any = {};
  pullDownCommittedFiles:any = [];
  selectedPullDownCommittedFile:any = "";
  includesPullDownMultipleTransactionFiles:any = false;

  // Supplier - Agents
  tapsAgentPageListColumns = ["agentPageListName","agentPageListTapsIdenti", "agentPageListTelephone","agentPageListEmail"];
  tapsAgentPageListData:any = new MatTableDataSource<any>();
  tapsExcludedAgentPageListData:any = new MatTableDataSource<any>();
  tapsIncludedAgentPageListData:any = new MatTableDataSource<any>();
  supplierAgentListSortedbyName:boolean = false;
  supplierAgentListSortedbyTradeCode:boolean = false; 
  
  //
  // Reporting Variables Below
  // 

  // Generated Reports
  generatedReportTitle:string = "Generated Reports";
  generatedReportBadge:number = 0;
  generatedReportBadgeHidden:boolean = true;

  // Admin & Member & Supplier - Reporting
  reportingDatePeriods:any = [];
  reportingDatePeriod:any = {};
  reportingDateFrom:any = "";
  reportingDateTo:any = "";

  // Supplier - Reporting
  selectedAgent:any = "";
  tapsSupplierReportListColumns = ["tranAgent","tranTapsId","tranRef","tranDepart","tranClient","tranPayment","tranWarning",
                                    "tranValue","tranAdjust","tranCollectedValue","tranCode","tranName"];
  tapsSupplierReportListData:any = new MatTableDataSource<any>();
  supplierReportOriginalTotal:any = 0;
  supplierReportAdjustmentsTotal:any = 0;
  supplierReportCollectionTotal:any = 0;
  onlyRejectedTransactions:any = false;

  // Member - Reporting 
  tapsReportMemberListColumns = ['tranSupplier','tranSupTapsId',"tranAgent","tranTapsId","tranRef","tranDepart","tranClient","tranPayment",
                                  "tranValue","tranAdjust","tranCollectedValue","tranCode","tranName"]
  tapsReportMemberListData:any = new MatTableDataSource<any>();
  memberReportOriginalTotal:any = 0;
  memberReportAdjustmentsTotal:any = 0;
  memberReportCollectionTotal:any = 0;
  supplierReportListData:any = [];
  selectedAgentTradeCode:any = "";
  listOfChildrenAgentsTradeCode:any = [];
  selectedSupplierTradeCode:any = "all";
  memberAnalysisReportYear:any = this.getCurrentYear();
  memberIncludeBranches:any = false;

  // Admin - Reporting 
  tapsReportAdminListColumns = ['tranSupplier','tranSupTapsId',"tranAgent","tranTapsId","tranRef","tranDepart","tranClient","tranPayment","tranWarning",
                                  "tranValue","tranAdjust","tranCollectedValue","tranCode","tranName"]
  tapsReportAdminListData:any = new MatTableDataSource<any>();
  adminReportingDatePeriods:any = [];
  adminAnalysisReportingDatePeriods:any = [];
  adminReportingDatePeriod:any = {};
  adminReportingDateFrom:any = "";
  adminReportingDateTo:any = "";
  adminReportSupplier:any = "all";
  adminReportAgent:any = "all";
  adminReportUploadState:any = "all";
  adminReportPaymentState:any = "all";
  adminIncludeBranches:any = false;
  alreadyListedReport:any = false;
  adminCameFromAgentManagement:any = false;
  adminCameFromSupplierManagement:any = false;
  analysisReportType = [
                {format:"1", type:"weeklyAnalysis", display:"Weekly Analysis"},
                {format:"1", type:"weeklyAnalysis(byGroup)", display:"Weekly Analysis (By Group)"},
                {format:"2", type:"monthlyAnalysis", display:"Monthly Analysis"},
                {format:"2", type:"monthlyAnalysis(byGroup)", display:"Monthly Analysis (By Group)"},
                {format:"3", type:"agentQuarterlyAnalysis", display:"Agent Quarterly Analysis"},
                {format:"3", type:"supplierQuarterlyAnalysis", display:"Supplier Quarterly Analysis"},
                {format:"3", type:"supplierQuarterlyAnalysis(byGroup)", display:"Supplier Quarterly Analysis (By Group)"},
                {format:"4", type:"agentAdjustment", display:"Agent Adjustment"},
                {format:"4", type:"supplierPaymentDue", display:"Supplier Payment Due"},
                {format:"4", type:"adminRejections", display:"Admin Rejections"},
                {format:"5", type:"monthlyVariance", display:"Monthly Variance"},
                {format:"5", type:"monthlyVariance(byGroup)", display:"Monthly Variance (By Group)"}]  
  adminAnalysisReport:any = "";  
  adminAnalysisYear:any = this.getCurrentYear();
  adminAnalysisWeek:any = this.getCurrentWeek();
  adminAnalysisMonth:any = this.getCurrentMonth();
  adminAnalysisStartDate:any = "";
  adminAnalysisEndDate:any = "";  
  adminAnalysisYearOne:any = this.getCurrentYear();
  adminAnalysisMonthOne:any = this.getCurrentMonth();
  adminAnalysisYearTwo:any = this.getCurrentYear();
  adminAnalysisMonthTwo:any = this.getCurrentMonth(); 
  adminAnalysisMonths:any = "3";
  switchToGeneratedReportsView:Boolean = false;
  analysisYearsList:any = [];

  // Admin - Bacs Reporting
  adminBacsReporting:any = "";
  attachedDocs: any = new MatTableDataSource<any>();
  s3FilesColumns = ['fileName', 'lastModified', 'size', 'download'];
  collectFtpColumns = ['name', 'supplierCode', 'supplierName', 'collect'];
  showArchived:boolean = false;

  // Supplier and Admin
  uploadFileBlocked:boolean = false;
  uploadFileFtpRequest:any = null;

  // Blocked Upload Reason
  uploadFailedReason:any = "";

  // Used for Member/Admin to view Review Transactions of a Parent Agent
  selectedAgentParent:any = "";

  // Dates Variables With Momentum
  possibleDateFormats = [
    'DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY',
    'DD.MM.YYYY', 'DD.MM.YY', 'DD/MMM/YYYY', 'DD-MMM-YYYY',
    'DDMMMYY', 'MM/DD/YYYY', 'MM/DD/YY', 'MM-DD-YYYY',
    'MM-DD-YY', 'MMM DD, YYYY', 'MMM-DD-YY'
  ];

  constructor(private router: Router, private route: ActivatedRoute, private reportService: ReportsService,
    private userService: UserService, private ngxCsvParser: NgxCsvParser, private supplierService: SupplierService,
    private session: Session, private tapsService: TapsService, private  branchService: BranchService, public dialog: MatDialog) { }

  ngOnInit(): void {
    // Login redirects user to this page. If that happens, browser needs to refresh the page
    // This is so the top-nav renders correctly
    if (Session.mySession.get('justLoggedIn') == true) {
      window.location.reload();
      Session.mySession.set('showStartPopup', false);
      Session.mySession.set('justLoggedIn', false);
    } if (sessionStorage.length == 0 || Session.mySession == undefined) {
      sessionStorage.clear();
      this.router.navigate(['/login']);
    } else {
      this.userName = Session.mySession.getUser().fullName;
      this.userEmail = Session.mySession.getUser().email;
      this.userCompany = Session.mySession.getUser().company.toUpperCase();
      this.userTradeCode = Session.mySession.getUser().tradeCode;
      this.loadPage();
    }
  }

  loadPage() {
    if (Session.mySession.getUser().userType == 'tapsAdmin') {
        // Above users will have access to the page. Transaction data object needs first line (well, it doesnt but it looks better!)
        window.dispatchEvent(new Event('resize'));
        this.haveAccess = true;
        this.setUpAdminPage();
        this.setUpHomePage();
        this.pageLoaded = true;
    } else if(Session.mySession.getUser().userType == 'memberManager' || Session.mySession.getUser().userType == 'memberStaff'
              || Session.mySession.getUser().userType == 'wcMember' || Session.mySession.getUser().userType == 'wcManager') {
        window.dispatchEvent(new Event('resize'));
        this.haveAccess = true;
        this.setUpMemberPage();
        this.setUpHomePage();
        this.pageLoaded = true;
    } else if (Session.mySession.getUser().userType == 'supplier') {
        window.dispatchEvent(new Event('resize'));
        this.haveAccess = true;
        this.setUpSupplierPage();
        this.setUpHomePage();
        this.pageLoaded = true;
    } else {
      window.dispatchEvent(new Event('resize'));
      this.haveAccess = false;
      this.pageLoaded = true;
    }
  }

  async switchView(view: any) {
    if (this.needsToBeSaved == true){
      if (confirm("Changes that you made are not saved. Are you sure that you want to leave this page?")) {
        this.needsToBeSaved = false;
        this.pageLoaded = false;
        this.listOfPages.forEach((page:any) => {page.value = false});
        this.listOfPages.findIndex((page:any)=>{ 
        if(page.key == view){
          page.value = true;
          if (page.key == "transactionApprovalPage"){
            this.setupTransactionApprovalPage();
          }
          if (page.key == "exportOrImportTransactionsMemberPage" || page.key == "exportOrImportTransactionsAdminPage"){
            this.setupExportOrImportTransactionsPage();
          }
          if (page.key == "reportingMemberPage"){
            this.resetMemberReportData() 
          }
          if (page.key == "analysisReportingMemberPage"){
            this.resetMemberReportData() 
          }
          if (page.key == "homePage"){

          }
        }});
        this.pageLoaded = true;
      }
    } else {
      this.pageLoaded = false;
      this.listOfPages.forEach((page:any) => {page.value = false});
      this.listOfPages.findIndex((page:any)=>{ 
        if(page.key == view){
          page.value = true;
          if (page.key == "homePage"){
            this.setUpHomePage();
          }
          if (page.key == "reportingOperatorPage"){
            this.switchToGeneratedReportsView = false;
          }
          if (page.key == "bacsReportingPage"){
            this.adminBacsReporting = "";
          }
          if (page.key == "pullDownFilePage"){
            this.setupPullDownFilePage();
          }
          if (page.key == "uploadHistory"){
            this.clearViewUploadFile();
          }
          if (page.key == "transactionApprovalPage"){
            this.setupTransactionApprovalPage();
          } 
          if (page.key == "exportOrImportTransactionsMemberPage" || page.key == "exportOrImportTransactionsAdminPage"){
            this.setupExportOrImportTransactionsPage();
          }
          if (page.key == "deleteUploadPage"){
            this.setupDeleteUploadPage();
          }
          if (page.key == "commitUploadPage"){
            this.setupCommitUploadPage();
          }
          if (page.key == "reportingPage"){
            this.clearAdminReportData();
          }
          if (page.key == "analysisReportingPage"){
            this.clearAdminReportData();
            this.analysisYearsList = []
            const tempDate =  new Date();
            const tempYear =  tempDate.getFullYear();
            for (let index = tempYear; index >= 2000; index--) {
              this.analysisYearsList.push(index);
            }
            this.adminAnalysisYear = tempYear;
            this.adminAnalysisYearOne = tempYear;
            this.adminAnalysisYearTwo = tempYear;
          }
          if (page.key == "sendAgentRemindersPage"){
            this.setupAgentsReminderList();
          }
          if (page.key == "supplierStatusPage"){
            this.setupSupplierStatusPage();
          }
          if (page.key == "uploadFilePage"){
            this.supplierUploadFile == "";
            this.clearViewUploadFile();
            this.supplierUploadedFile = false;
          }
          if (page.key == "lateAdjustmentsPage"){
            this.lateAdjustmentFile == "";
            this.activeSuppliers();
            this.activeAgents();
            this.clearViewUploadFile();
            this.lateAdjustmentUploadedFile = false;
            this.checkUploadAvailabilityForAdmin();
          }
          if (page.key == "supplierManagementPage"){
            this.activeSuppliers();
          }
          if (page.key == "suppliersPage") {
            this.setupAgentSupplierList();
          }
          if (page.key == "agentsPage"){
            this.setupSuppliersAgentsList();
          }
          if (page.key == "agentManagementPage"){
            this.activeAgents();
          }
          if (page.key == "collectionFromAgentsPage"){
            this.clearCollectionFromAgentsData();
          }
          if (page.key == "deliveryToSuppliersPage"){
            this.clearDeliveryToSuppliersData();
          }
          if (page.key == "reportingMemberPage"){
            this.resetMemberReportData()
          }
          if (page.key == "analysisReportingMemberPage"){
            this.resetMemberReportData()
            this.analysisYearsList = []
            const tempDate =  new Date();
            const tempYear =  tempDate.getFullYear();
            for (let index = tempYear; index >= 2000; index--) {
              this.analysisYearsList.push(index);
            }
            this.memberAnalysisReportYear = tempYear;
          }
          if (page.key == "unmatchedTapsPayments"){
            this.clearUnMatchedTapsPayments()
          }
          if (page.key == "supplierPage"){
            const request = { 
                ownerName: this.viewSupplierName, 
                ownerType: "supplier", 
                token: Session.mySession.get('user').token 
              };
            this.pageLoaded = false; // Create a request variable and set as 'loading..'
            this.branchService.getContactDetails(request).then((contactDetails: any) => {
              if (contactDetails.status === 'OK') {
                this.viewSupplierEmail = "";
                contactDetails.data.forEach((tempContact:any)=>{
                  if (tempContact.contactType == "taps" && tempContact.active == "yes") {
                    if (this.viewSupplierEmail == ""){
                      this.viewSupplierEmail = tempContact.emailAddress;
                    } else {
                      this.viewSupplierEmail = this.viewSupplierEmail + ";" + tempContact.emailAddress;
                    }
                    if (this.viewSupplierContact == ""){
                      this.viewSupplierContact = tempContact.contactName;
                    }
                    if (this.viewSupplierTelephone == ""){
                      this.viewSupplierTelephone = tempContact.phoneNumber1;
                      if (tempContact.phoneNumber2 != null && tempContact.phoneNumber2 != "" && tempContact.phoneNumber2 != undefined){
                        this.viewSupplierTelephone = this.viewSupplierTelephone + ", " + tempContact.phoneNumber2;
                      }
                    }
                  }
                })
                this.pageLoaded = true; // Looks like everything went well - stop 'loading..' and assign data to a var.
              } else {
                this.sendMessageToDialog('', contactDetails.status, '');
              }
            }).catch((error: any) => {
              this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2701S)', error);
            });
          }
          if (page.key == "agentPage"){
            const request = { 
              ownerName: this.viewAgentTradeCode, 
              ownerType: "branch", 
              token: Session.mySession.get('user').token 
            };
            this.pageLoaded = false; // Create a request variable and set as 'loading..'
            this.branchService.getContactDetails(request).then((contactDetails: any) => {
              if (contactDetails.status === 'OK') {
                this.viewAgentEmail = "";
                contactDetails.data.forEach((tempContact:any)=>{
                  if (tempContact.contactType == "taps" && tempContact.active == "yes") {
                    if (this.viewAgentEmail == ""){
                      this.viewAgentEmail = tempContact.emailAddress;
                    } else {
                      this.viewAgentEmail = this.viewAgentEmail + ";" + tempContact.emailAddress;
                    }
                    if (this.viewAgentContact == ""){
                      this.viewAgentContact = tempContact.contactName;
                    }
                    if (this.viewAgentTelephone == ""){
                      this.viewAgentTelephone = tempContact.phoneNumber1;
                      if (tempContact.phoneNumber2 != null && tempContact.phoneNumber2 != "" && tempContact.phoneNumber2 != undefined){
                        this.viewAgentTelephone = this.viewAgentTelephone + ", " + tempContact.phoneNumber2;
                      }
                    }
                  }
                })
                this.pageLoaded = true; // Looks like everything went well - stop 'loading..' and assign data to a var.
              } else {
                this.sendMessageToDialog('', contactDetails.status, '');
              }
            }).catch((error: any) => {
              this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2701S)', error);
            });
          }
          if (page.key == "collectFtpPage"){
            this.setupCollectFtpPage();
          }
          if (page.key == "uploadHistoryAdmin"){
            this.clearViewUploadFile();
          }
        }});
      this.pageLoaded = true;
    }    
  }

  showContactView(){
    this.dialog.open(this.contactDialog);
  }

  safeString(value: string | null | undefined): string {
    return value || "";
  }
 
  setUpHomePage(){
    this.userHomeAddress = "N/A";
    this.userHomeEmail = "N/A";
    this.userHomeTelephone = "N/A";
    if (this.loggedInUserType == "Admin"){
      this.userHomeEmail = Session.mySession.getUser().email;
      let resBranchDetails = Session.mySession.getBranch();
      if (resBranchDetails){
        this.userHomeTelephone = resBranchDetails.telephone;
        this.userHomeAddress = 
          this.safeString(resBranchDetails.address1) + "\n" + 
          this.safeString(resBranchDetails.address2) + "\n" + 
          this.safeString(resBranchDetails.city) + "\n" +
          this.safeString(resBranchDetails.postcode) + "\n" + 
          this.safeString(resBranchDetails.county) + "\n" + 
          this.safeString(resBranchDetails.country);
      }
    } 
    if (this.loggedInUserType == "Supplier"){
      let request = {
        supplierName: this.userSupplierName,
        token: Session.mySession.get('user').token
      };
      this.supplierService.getSupplierList(request).then((suppliers: any) => {
        if (suppliers.status != "OK") {
            this.sendMessageToDialog('', 'SinGS could not load suppliers home page data (' + suppliers.status + ')', '');
        } else {
          if (suppliers.data && suppliers.data.length > 0){
            const validTempSupplier = suppliers.data[0];
            let supplierMap: { [key: string]: any } = {};
            if (validTempSupplier.contactTAPS) {
                if (validTempSupplier.contactTAPS.length > 0){
                  validTempSupplier.contactTAPS.forEach((tempContact:any)=>{
                    if (tempContact.active == "yes" && tempContact.contactType == "taps"){
                      if (validTempSupplier.telephoneTAPS == null){
                        if (tempContact.phoneNumber1 != null){
                          validTempSupplier.telephoneTAPS = tempContact.phoneNumber1
                        } else {
                          if (tempContact.phoneNumber2 != null){
                            validTempSupplier.telephoneTAPS = tempContact.phoneNumber1
                          }
                        }
                      }
                      if (!supplierMap[validTempSupplier.supplierNameM]) {
                        if (tempContact.emailAddress) {
                          supplierMap[validTempSupplier.supplierNameM] = { ...validTempSupplier, emailTAPS: new Set(tempContact.emailAddress ? [tempContact.emailAddress] : []) };
                        }
                      } else {
                        if (tempContact.emailAddress) {
                            supplierMap[validTempSupplier.supplierNameM].emailTAPS.add(tempContact.emailAddress);
                        }
                      }
                    }
                  })
                } else {
                  if (!supplierMap[validTempSupplier.supplierNameM]) {
                    if (validTempSupplier.emailTAPS) {
                      supplierMap[validTempSupplier.supplierNameM] = { ...validTempSupplier, emailTAPS: new Set(validTempSupplier.emailTAPS ? [validTempSupplier.emailTAPS] : []) };
                    }
                  } else {
                    if (validTempSupplier.emailTAPS) {
                        supplierMap[validTempSupplier.supplierNameM].emailTAPS.add(validTempSupplier.emailTAPS);
                    }
                  }
                }
            }
            // Convert Set to a single string with emails separated by ';'
            for (let key in supplierMap) {
              supplierMap[key].emailTAPS = Array.from(supplierMap[key].emailTAPS).join(';');
            }
            Object.values(supplierMap).forEach((supplier: any) => {
                if (supplier.telephoneTAPS == null){
                  if (supplier.telephone != null) {
                    supplier.telephoneTAPS = supplier.telephone;
                  }
                }
                this.userHomeEmail = supplier.emailTAPS;
                this.userHomeTelephone = supplier.telephoneTAPS;
            });
            this.userHomeAddress = 
              this.safeString(validTempSupplier.addressLine1) + "\n" + 
              this.safeString(validTempSupplier.addressLine2) + "\n" + 
              this.safeString(validTempSupplier.addressLine3) + "\n" + 
              this.safeString(validTempSupplier.addressLine4) + "\n" + 
              this.safeString(validTempSupplier.postCode) + "\n" + 
              this.safeString(validTempSupplier.country);
          }      
        }
      }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2001S)', error);
      }).then(() => { this.pageLoaded = true; });
    }
    if (this.loggedInUserType == 'Member') {
      let resBranchDetails = Session.mySession.getBranch();
      if (resBranchDetails){
        this.userHomeEmail = resBranchDetails.emailTAPS;
        this.userHomeTelephone = resBranchDetails.telephoneTAPS;
        this.userHomeAddress = 
          this.safeString(resBranchDetails.address1) + "\n" + 
          this.safeString(resBranchDetails.address2) + "\n" + 
          this.safeString(resBranchDetails.city) + "\n" + 
          this.safeString(resBranchDetails.postcode) + "\n" + 
          this.safeString(resBranchDetails.county) + "\n" + 
          this.safeString(resBranchDetails.country);
      }
    }
  }
  
  //////////////////////////////////
  //
  // Admin Functionalities Below
  //
  //////////////////////////////////

  // INITIAL SETUP FOR ADMIN
  setUpAdminPage(){
    this.loggedInUserType = "Admin";  
    this.userFullCompanyName = Session.mySession.getBranch().fullName;
    this.checkUploadAvailabilityForAdmin(); 
    this.setUpAdminDatePeriods();
    this.setUpAdminAnalysisDatePeriods();
    this.setSuppliersList();
    this.setupAdminAgentsList();
    this.checkTransactionCommitAvailability();
  }

  // Switch to Dev Version (Used by the Dev team for testing)
  // switchToDevSiteForAdmin(){
  //   if (this.pinGiven == this.pinToSwitch){
  //     if (this.liveVersion == true){
  //       if (confirm("Are you sure you want to switch to the dev site?")) {
  //         this.sendMessageToDialog("Successfully switched to the 'Dev' site", "", "");
  //         this.liveVersion = false;
  //         this.transactionAvailability = true;    
  //       }
  //     } else {
  //       if (confirm("Are you sure you want to switch to the live site?")) {
  //         this.sendMessageToDialog("Successfully switched to the 'Live' site", "", "");
  //         this.liveVersion = true;
  //         this.transactionAvailability = false;  
  //         this.checkTransactionCommitAvailability();     
  //       }
  //     }      
  //   } else {
  //     this.sendMessageToDialog("", "Please enter a valid pin to switch.", "");
  //   }    
  // }

  // Check if Commit Upload page is available
  checkTransactionCommitAvailability(){
    let currentDate:any = new Date();
    if (currentDate.getDay() == 3){
      // Wednesday (Available after 16:00)
      if (currentDate.getHours() < 16){
        this.transactionAvailability = false;
      } else {
        this.transactionAvailability = true;
      }
    } else if (currentDate.getDay() >= 4 && currentDate.getDay() <= 5) {
      // Thursday and Friday (Not Available)
      this.transactionAvailability = true;
    } else if (currentDate.getDay() == 6) {
      // Saturday (Available after 9:00)
      if (currentDate.getHours() > 8){
        this.transactionAvailability = false;
      } else {
        this.transactionAvailability = true;
      }
    } else {
      // Sunday - Tuesday (Available)
      this.transactionAvailability = false;
    }
  }

  // Calls :: getTapsAgentsList
  // Setups Agent Management Page
  setupAdminAgentsList(){
    this.pageLoaded = false;
    let request = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      token: Session.mySession.get('user').token
    }
    this.tapsService.getTapsAgentsList(request).then((output:any)=>{
      if (output.status == "OK"){
        let tempAgentList = new MatTableDataSource<any>();
        output.data.forEach((agent:any) => {
          if (agent.tradeCode != "Q0000"){
            tempAgentList.data.push(agent);
          }
        });
        let sorted = tempAgentList.data.sort((a: any, b: any) => (a.branchName > b.branchName) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['branchName'] === e['branchName']) === i);
        this.adminAgentListData.data = sorted;
        this.backUpAgentListData.data = sorted;
        this.filteredAdminAgentsListData.data = sorted;
      } else {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
      }
    }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
    }).then(()=>{this.pageLoaded = true}); 
  }

  // Filters the listed Agents in 'Agent Management Page' (Admin)
  filterAgentsList() {    
    this.adminAgentListData.data = this.backUpAgentListData.data;    
    let filterInput = String(this.agentFilter).toLowerCase();
    if (filterInput != ""){
      let filteredTable:any = new MatTableDataSource<any>();
      this.adminAgentListData.data.forEach((agent:any)=>{
        let tempAgent = String(agent.branchName).toLowerCase();
        let tempTradeCode = String(agent.tradeCode).toLowerCase();
        if (this.listActiveAgents == true){
          if (agent.isTapsReg == "yes"){
            if (tempAgent == filterInput || tempAgent.includes(filterInput)){
              filteredTable.data.push(agent);
            } else if (tempTradeCode == filterInput || tempTradeCode.includes(filterInput)){
              filteredTable.data.push(agent);
            }
          }
        } else {
          if (tempAgent == filterInput || tempAgent.includes(filterInput)){
            filteredTable.data.push(agent);
          } else if (tempTradeCode == filterInput || tempTradeCode.includes(filterInput)){
            filteredTable.data.push(agent);
          }
        }
      });
      this.adminAgentListData.data = filteredTable.data;
    } else {
      if (this.listActiveAgents == true){
        this.activeAgents();
      }
    }
  }
  
  // Show only active (TAPS:Yes) or all agents (TAPS:Yes/No) 
  activeAgents(){
    this.adminAgentListData.data = this.backUpAgentListData.data;
    if (this.listActiveAgents == true){
      let filteredTable:any = new MatTableDataSource<any>();
      this.adminAgentListData.data.forEach((agent:any)=>{
        if (agent.isTapsReg == "yes"){
          filteredTable.data.push(agent);
        }
      });  
      this.adminAgentListData.data = filteredTable.data;
    }     
  }

  // Filters the listed Suppliers in 'Supplier Management Page' (Admin)
  filterSuppliersList() {
    this.backUpSupplierListData.data = this.suppliersListData.data;
    let filterInput = String(this.supplierFilter).toLowerCase();
    if (filterInput != ""){
      if (this.listActiveSuppliers == true){
        this.activeSuppliers();
      }
      let filteredTable:any = new MatTableDataSource<any>();
      this.backUpSupplierListData.data.forEach((supplier:any)=>{
        let tempSupplierName = "";
        if (supplier.supplierNameM != null && supplier.supplierNameM != ""){
          tempSupplierName = String(supplier.supplierNameM).toLowerCase();
        }
        let tempSupplierMisttaCode = "";
        if (supplier.misttaCode != null && supplier.misttaCode != ""){
          tempSupplierMisttaCode = String(supplier.misttaCode).toLowerCase();
        }
        if (tempSupplierName == filterInput || tempSupplierName.includes(filterInput)){
          filteredTable.data.push(supplier);
        } else if (tempSupplierMisttaCode != "") {
          if (tempSupplierMisttaCode == filterInput || tempSupplierMisttaCode.includes(filterInput)){
            filteredTable.data.push(supplier);
          }
        }         
      });
      this.backUpSupplierListData.data = filteredTable.data;
    } else {
      this.activeSuppliers();
    }
  }

  // Show only active (TAPS:Yes) or all suppliers (TAPS:Yes/No)
  activeSuppliers(){
    this.backUpSupplierListData.data = this.suppliersListData.data;
    if (this.listActiveSuppliers == true){
      let filteredTable:any = new MatTableDataSource<any>();
      this.backUpSupplierListData.data.forEach((supplier:any)=>{
        if (supplier.misttaCode != "" && supplier.misttaCode != null && supplier.isTapsReg == "yes"){
          filteredTable.data.push(supplier);
        }
      });  
      this.backUpSupplierListData.data = filteredTable.data;
    }       
  }

  // Redirects to selected Agent Page
  viewAgentPage(agent:any){
    this.viewAgentName = "";
    this.viewAgentTradeCode = "";
    this.viewAgentAddress = "";
    this.viewAgentTelephone = "";
    this.viewAgentEmail = "";
    this.viewAgentContact = "";
    this.viewAgentThreshold = "";
    this.viewAgentTaps = "";
    this.viewAgentHeadOffice = "";
    this.setupViewAgent(agent);
    this.switchView("agentPage");
  }

  // Setup View Agent Page Data 
  setupViewAgent(agent:any){
    this.viewAgentName = agent.branchName;
    this.viewAgentTradeCode = agent.tradeCode
    if (agent.address1 != null && agent.address1 != ""){
      this.viewAgentAddress = agent.address1;
    }
    if (agent.address2 != null && agent.address2 != ""){
      this.viewAgentAddress = this.viewAgentAddress + "\n" + agent.address2;
    }
    if (agent.city != null && agent.city != ""){
      this.viewAgentAddress = this.viewAgentAddress + "\n" + agent.city;
    }
    if (agent.postcode != null && agent.postcode != ""){
      this.viewAgentAddress = this.viewAgentAddress + "\n" + agent.postcode;
    }
    if (agent.county != null && agent.county != ""){
      this.viewAgentAddress = this.viewAgentAddress + "\n" + agent.county;
    }
    if (agent.country != null && agent.country != ""){
      this.viewAgentAddress = this.viewAgentAddress + "\n" + agent.country;
    }
    this.viewAgentThreshold = agent.tapsThreshold;
    this.viewAgentTaps = agent.isTapsReg;
    this.viewAgentHeadOffice = agent.tapsMaster;
  }

  // Redirects to view selected Agent Report (Goes to Admin Report Page)
  viewAgentHistory(){
    this.switchView("reportingPage");
    this.adminReportAgent = this.viewAgentTradeCode;
    this.adminReportingDatePeriod = this.adminReportingDatePeriods[0];
    this.adminCameFromAgentManagement = true;
    this.setupDatesForAdminReportingPeriod();
  }

  // Redirects to Agent's Suppliers List (Included / Excluded) (MISTTA Data required)
  viewAgentSupplierList(){
    this.adminCameFromAgentManagement = true;
    this.switchView("suppliersPage")
  }

  // Calls getSupplierList to setup include / excluded Suppliers for Member or an Admin viewing an Agent's Supplier List
  setupAgentSupplierList(){
    this.activeSuppliersListData.data = [];
    this.inactiveSuppliersListData.data = [];
    let tempViewAgentTradeCode = "";
    if (this.adminCameFromAgentManagement == true) {
        tempViewAgentTradeCode = this.viewAgentTradeCode;
    } else {
        tempViewAgentTradeCode = Session.mySession.getUser().tradeCode;
    }
    let request = {
        token: Session.mySession.get('user').token
    };
    this.supplierService.getSupplierList(request).then((suppliers: any) => {
        if (suppliers.status != "OK") {
            this.sendMessageToDialog('', 'SinGS could not load suppliers (' + suppliers.status + ')', '');
        } else {
            let supplierMap: { [key: string]: any } = {};
            // MAPPING SUPPLIERS EMAILS FROM CONTACTS
            suppliers.data.forEach((supplier: any) => {
              if (supplier.contactTAPS) {
                if (supplier.contactTAPS.length > 0){
                  supplier.contactTAPS.forEach((tempContact:any)=>{
                    if (tempContact.active == "yes" && tempContact.contactType == "taps"){
                      if (supplier.telephoneTAPS == null){
                        if (tempContact.phoneNumber1 != null){
                          supplier.telephoneTAPS = tempContact.phoneNumber1
                        } else {
                          if (tempContact.phoneNumber2 != null){
                            supplier.telephoneTAPS = tempContact.phoneNumber1
                          }
                        }
                      }
                      if (!supplierMap[supplier.supplierNameM]) {
                        if (tempContact.emailAddress) {
                          supplierMap[supplier.supplierNameM] = { ...supplier, emailTAPS: new Set(tempContact.emailAddress ? [tempContact.emailAddress] : []) };
                        }
                      } else {
                        if (tempContact.emailAddress) {
                            supplierMap[supplier.supplierNameM].emailTAPS.add(tempContact.emailAddress);
                        }
                      }
                    }
                  })
                } else {
                  if (!supplierMap[supplier.supplierNameM]) {
                    if (supplier.emailTAPS) {
                      supplierMap[supplier.supplierNameM] = { ...supplier, emailTAPS: new Set(supplier.emailTAPS ? [supplier.emailTAPS] : []) };
                    }
                  } else {
                    if (supplier.emailTAPS) {
                        supplierMap[supplier.supplierNameM].emailTAPS.add(supplier.emailTAPS);
                    }
                  }
                }
              }
            });
            // Convert Set to a single string with emails separated by ';'
            for (let key in supplierMap) {
                supplierMap[key].emailTAPS = Array.from(supplierMap[key].emailTAPS).join(';');
            }
            let tempActiveSuppliers = new MatTableDataSource<any>();
            let tempInactiveSuppliers = new MatTableDataSource<any>();
            Object.values(supplierMap).forEach((supplier: any) => {
                if (supplier.telephoneTAPS == null){
                  if (supplier.telephone != null) {
                    supplier.telephoneTAPS = supplier.telephone;
                  }
                }
                if (supplier.misttaCode != "" && supplier.misttaCode != null && supplier.isTapsReg == 'yes') {
                    if (!supplier.tapsTradeCode || !supplier.tapsTradeCode.includes(tempViewAgentTradeCode) || supplier.tapsExcludeFlag != 'yes') {
                        tempActiveSuppliers.data.push(supplier);
                    } else {
                        tempInactiveSuppliers.data.push(supplier);
                    }
                }
            });
            // Sort active and inactive suppliers
            let activeSorted = tempActiveSuppliers.data.sort((a: any, b: any) => a.supplierNameM.localeCompare(b.supplierNameM));
            let inactiveSorted = tempInactiveSuppliers.data.sort((a: any, b: any) => a.supplierNameM.localeCompare(b.supplierNameM));
            this.activeSuppliersListData.data = activeSorted;
            this.inactiveSuppliersListData.data = inactiveSorted;
        }
    }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2001S)', error);
    }).then(() => { this.pageLoaded = true; });
  }

  // Redirects to view the BACS Report of the selected Agent
  viewAgentBACSHistory(){
    this.switchView("bacsHistoryPage");
    this.adminCameFromAgentManagement = true;
    this.setupBacsReportPage()
  }

  // Calls :: getTapsBacsReport
  // Setups the Reporting (BACS) List Table 
  setupBacsReportPage(){
    this.pageLoaded = false;
    this.tapsMembersBacsReportData.data = [];
    let tempTable:any = new MatTableDataSource<any>();
    this.viewOverallBacsReportAmount = 0.00;
    let request = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      operatorTradeCode: this.viewAgentTradeCode,
      token: Session.mySession.get('user').token
    }
    this.tapsService.getTapsBacsReport(request).then((output:any)=>{
      if (output.status == "OK"){
        if (output.res.length > 0){
          if (this.viewAgentTradeCode == "GB001"){
            output.res.forEach((record:any)=>{
              if (record.operatorTradeCode == "GB001")
              {
                const tempValue = Number(record.value).toFixed(2);
                this.viewOverallBacsReportAmount = Number(this.viewOverallBacsReportAmount) + Number(tempValue)
                tempTable.data.push(record);
              }
            });
          } else {
            output.res.forEach((record:any)=>{
              const tempValue = Number(record.value).toFixed(2);
              this.viewOverallBacsReportAmount = Number(this.viewOverallBacsReportAmount) + Number(tempValue)
              tempTable.data.push(record);
            });
          }
          this.tapsMembersBacsReportData.data = tempTable.data;
          this.viewOverallBacsReportAmount = Number(this.viewOverallBacsReportAmount).toFixed(2);
        } 
      } else {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
      }
    }).then(()=>{
      this.pageLoaded = true;
    });
  }

  // Redirects to selected Supplier Page
  viewSupplierPage(supplier:any){
    this.viewSupplierName = "";
    this.viewSupplierTradeCode = "";
    this.viewSupplierAddress = "";
    this.viewSupplierTaps = "";
    this.viewSupplierDenyAmendments = "";
    this.viewSupplierTransactionFee = 0
    this.viewSupplierPaymentDays = 0
    this.viewSupplierEmail = "";
    this.viewSupplierContact = "";
    this.viewSupplierTelephone = "";
    this.setupViewSupplier(supplier);
    this.switchView("supplierPage");
  }

  // Setup View Supplier Page Data 
  setupViewSupplier(supplier:any){
    this.viewSupplierName = supplier.supplierNameM;
    this.viewSupplierTradeCode = supplier.misttaCode;
    if (supplier.addressLine1 != null){
      this.viewSupplierAddress = supplier.addressLine1;
    }
    if (supplier.addressLine2 != null){
      this.viewSupplierAddress = this.viewSupplierAddress + "\n" + supplier.addressLine2;
    }
    if (supplier.postCode != null){
      this.viewSupplierAddress = this.viewSupplierAddress + "\n" + supplier.postCode;
    }
    if (supplier.country != null){
      this.viewSupplierAddress = this.viewSupplierAddress + "\n" + supplier.country;
    }
    this.viewSupplierTaps = supplier.isTapsReg;
    this.viewSupplierDenyAmendments = supplier.tapsDenyAmendments;
    this.viewSupplierTransactionFee = Number(supplier.tapsTransactionFee).toFixed(0);
    this.viewSupplierPaymentDays = supplier.tapsPaymentDays;
  }

  // Redirects to view selected Supplier Report (Goes to Admin Report Page)
  viewSupplierHistory(){
    this.switchView("reportingPage");
    this.adminReportSupplier = this.viewSupplierTradeCode;
    this.adminReportingDatePeriod = this.adminReportingDatePeriods[0];
    this.adminCameFromSupplierManagement = true;
    this.setupDatesForAdminReportingPeriod();
  }

  // Redirects to view selected Supplier Status Report (Goest to Supplier Status Page)
  viewSupplierStatus(){
    this.switchView("supplierStatusPage");
    this.selectedSupplierForStatusReport = this.viewSupplierTradeCode;
  }

  // Calls :: listUnCommittedImportedTapsUploads
  // To list all 'Imported' tapsUploads for the given period to delete if needed
  setupDeleteUploadPage() {
    let request:any = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      uploadPeriodFrom: this.getUploadPeriod(true),
      uploadPeriodTo: this.getUploadPeriod(false),
      token: Session.mySession.get('user').token
    }
    this.tapsService.listUnCommittedImportedTapsUploads(request).then((output: any) => {
      if (output.status == "OK"){
        if (output.res.length > 0) {
          let tempList:any = new MatTableDataSource<any>();
          output.res.forEach((transaction:any)=>{
            let uploadPeriod = new Date(transaction["uploadPeriod"]);
            transaction.collectionDate = this.addDays(7,uploadPeriod).toLocaleDateString();
            transaction.viewUploadDate = new Date(transaction["uploadDate"]).toLocaleDateString();
            tempList.data.push(transaction);
          });
          this.adminDeleteUploadListData.data = tempList.data;
        } else {
          this.adminDeleteUploadListData.data = [];
        }
      } else {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
      }          
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
    });        
  }

  // Calls :: deleteTapsFile 
  // To change the status of a file to 'Deleted'
  deleteReviewFile(transaction:any){
    if (confirm("Are you sure you want to delete this uploaded file (transactionID : " + transaction.transactionID + ")?")) {
      let request:any = {
        company: Session.mySession.getUser().company,
        tradeCode: Session.mySession.getUser().tradeCode,
        operation: Session.mySession.getUser().operation,
        transactionID: String(transaction.transactionID),
        supplierTradeCode: transaction.supplierTradeCode,
        supplierName: transaction.supplierName,
        uploadPeriod: this.convertDate(transaction.uploadPeriod),
        token: Session.mySession.get('user').token
      };
      this.tapsService.deleteTapsFile(request).then((output: any) => {
        if (output.status == "OK"){
          this.setupDeleteUploadPage();
          this.sendMessageToDialog("Successfully deleted supplier's upload (transactionID : " + transaction.transactionID + ")", '', '');
        } else {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
        }          
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
      });  
    }
  }

  // Calls :: listAllTapsUploads 
  // To list all available TAPSUPLOADS for the given period
  setupDeleteTapsUploadDataPage() { 
    let request:any = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      supplierTradeCode: this.adminReportSupplier,
      uploadState: this.adminReportUploadState,
      paymentState: this.adminReportPaymentState,
      startDate: this.convertDate(this.adminReportingDateFrom),
      endDate: this.convertDate(this.adminReportingDateTo),
      token: Session.mySession.get('user').token
    }
    this.tapsService.listAllTapsUploads(request).then((output: any) => {
      if (output.status == "OK"){
        if (output.res.length > 0) {
          let tempDataList:any = new MatTableDataSource<any>();
          output.res.forEach((tapsUpload:any)=>{
            tempDataList.data.push(tapsUpload)
          })
          this.adminDeleteTapsUploadListData.data = tempDataList.data;
        } else {
          this.adminDeleteTapsUploadListData.data = [];
        }
      } else {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
      }          
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
    });        
  }

  // Calls :: deleteTapsUploadData 
  // To erase all related records for the selected transactionID
  deleteTapsUploadData(tapsUpload:any) {
    if (confirm("Are you sure you want to begin the delete process for TapsUpload with transactionID : " + tapsUpload.transactionID + "?")) {
      if (confirm("Do you want to delete all related records (Yes) or just the TapsUpload with transactionID : " + tapsUpload.transactionID + " (Cancel) ?")) {
        if (confirm("Are you really sure you want to delete all related records for TapsUpload with transactionID : " + tapsUpload.transactionID + "?")) {
          let request:any = {
            company: Session.mySession.getUser().company,
            tradeCode: Session.mySession.getUser().tradeCode,
            operation: Session.mySession.getUser().operation,
            transactionID: tapsUpload.transactionID,
            token: Session.mySession.get('user').token
          }
          this.tapsService.deleteTapsUploadData(request).then((output: any) => {
            if (output.status == "OK"){
              this.setupDeleteTapsUploadDataPage();
              this.sendMessageToDialog(output.res, '', '');
            } else {
              this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
            }          
          }).catch((error: any) => {
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
          });
        }
      } else{
        if (confirm("Are you really sure sure you want to delete TapsUpload with transactionID : " + tapsUpload.transactionID + "?")) {
          let request:any = {
            company: Session.mySession.getUser().company,
            tradeCode: Session.mySession.getUser().tradeCode,
            operation: Session.mySession.getUser().operation,
            transactionID: tapsUpload.transactionID,
            onlyOneRecord: "yes",
            token: Session.mySession.get('user').token
          }
          this.tapsService.deleteTapsUploadData(request).then((output: any) => {
            if (output.status == "OK"){
              this.setupDeleteTapsUploadDataPage();
              this.sendMessageToDialog(output.res, '', '');
            } else {
              this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
            }          
          }).catch((error: any) => {
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
          }); 
        }
      }
    }
  }

  // This is to clear the 'selectedSupplierForStatusReport' if admin goes to all Page
  setupSupplierStatusPage(){
    this.selectedSupplierForStatusReport = "";
    this.adminSupplierStatusListData.data = [];
    this.reportingDatePeriod = {};
    this.reportingDateFrom = "";
    this.reportingDateTo = "";
  }

  //Calls :: getTapsSupplierStatusReport 
  setupSupplierStatusReport(){
    if ((this.reportingDateFrom != null && this.reportingDateFrom != "") && (this.reportingDateTo != null && this.reportingDateTo != "")){
      if (this.reportingDateFrom > this.reportingDateTo) {
        this.sendMessageToDialog("","Please ensure that the 'Date-From' date is earlier than 'Date-To' date","");
      } else {
        let request:any = {
          company: Session.mySession.getUser().company,
          tradeCode: Session.mySession.getUser().tradeCode,
          operation: Session.mySession.getUser().operation,
          startDate: this.convertDate(this.reportingDateFrom),
          endDate: this.convertDate(this.reportingDateTo),
          token: Session.mySession.get('user').token
        }
        if (this.selectedSupplierForStatusReport != "") {
          request.supplierTradeCode = this.selectedSupplierForStatusReport;
        }         
        this.tapsService.getTapsSupplierStatusReport(request).then((output: any) => {
          if (output.status == "OK"){
            let tempListData:any = new MatTableDataSource<any>();
            output.res.forEach((supplier:any)=>{
              tempListData.data.push(supplier);
            });            
            this.adminSupplierStatusListData.data = tempListData.data;   
          } else {
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
          }          
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
        });
      }
    } else {
      this.sendMessageToDialog('', 'Please fill in all the required data','');
    }
  }

  // Calls :: listReviewedTapsUploadsToBeCommitted 
  // LIST ALL REVIEW UPLOADS THAT ARE READY TO BE COMMIT (Group by the ')
  setupCommitUploadPage(){
    let request:any = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      uploadPeriod: this.getUploadPeriod(true),
      token: Session.mySession.get('user').token
    }
    this.tapsService.listReviewedTapsUploadsToBeCommitted(request).then((output: any) => {
      if (output.status != "OK") {
        this.sendMessageToDialog("", "Error with request status: " + output.res, output);
      } else if (output != null && typeof output.res !== 'undefined' ) {
        let tempList:any = new MatTableDataSource<any>();
        output.res.forEach((reviewFile:any)=>{
          reviewFile.viewUploadDate = new Date(reviewFile["uploadDate"]).toLocaleDateString();
          reviewFile.viewCollectionDate = new Date(reviewFile["collectionDate"]).toLocaleDateString();
          tempList.data.push(reviewFile);
        });
        if (output.notReviewRes){
          if (output.notReviewRes.length > 0){
            output.notReviewRes.forEach((tempNotReviewFile:any)=>{
              const notReviewFile = {
                supplierName: tempNotReviewFile.supplierName,
                supplierTradeCode: tempNotReviewFile.supplierTradeCode,
                viewUploadDate: new Date(tempNotReviewFile.uploadDate).toLocaleDateString(), 
                viewCollectionDate: new Date(tempNotReviewFile.collectionDate).toLocaleDateString(),
                state: tempNotReviewFile.tranState,
                transactionOriginalID: tempNotReviewFile.transactionOriginalID,
                numOfTransactions: tempNotReviewFile.numOfTransactions, 
                overallImportedValue: tempNotReviewFile.tranValue,
                overallAmount: tempNotReviewFile.tranCollected
              }
              tempList.data.push(notReviewFile);
            })
          }
        }
        this.adminCommitUploadListData.data = tempList.data;
      } else {
        this.adminCommitUploadListData.data = [];
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
    });
  }

  // It opens up Confirmation Dialog with a few calculations for a Review or NotReview Upload
  showCommitDialog(tempUpload:any){
    this.adminSelectedCommitUpload = null;
    this.adminCommitSelectedUpload = ""
    this.adminCommitNumOfTransactions = 0;
    this.adminCommitOriginalTotal = 0;
    this.adminCommitAdjustments = 0;
    this.adminCommitCollectionTotal = 0;
    this.adminCommitState = "";
    if (this.adminCommitUploadListData.data.length > 0){
      this.adminSelectedCommitUpload = tempUpload;
      this.adminCommitSelectedUpload = tempUpload.transactionOriginalID;
      this.adminCommitNumOfTransactions = Number(tempUpload.numOfTransactions)
      this.adminCommitOriginalTotal = Number(tempUpload.overallImportedValue)
      this.adminCommitAdjustments = Number(tempUpload.overallImportedValue) - Number(tempUpload.overallAmount)
      this.adminCommitCollectionTotal = Number(tempUpload.overallAmount)
      this.adminCommitState = tempUpload.state;
      this.dialog.open(this.commitUpload);
    } else {
      this.sendMessageToDialog('', 'There are no transactions to commit', '');
    }
  }

  // Calls :: commitTapsUpload 
  // Single Commit Request (All accepted transactions of all the review files (*of an imported tranasaction) to new "Commited" one)
  commitReviewFile(){
    this.checkTransactionCommitAvailability();
    if (this.transactionAvailability == true && this.adminSelectedCommitUpload != null){
        this.pageLoaded = false;
        let request:any = {
          company: Session.mySession.getUser().company,
          tradeCode: Session.mySession.getUser().tradeCode,
          operation: Session.mySession.getUser().operation,
          transactionOriginalID: this.adminSelectedCommitUpload.transactionOriginalID,
          supplierName: this.adminSelectedCommitUpload.supplierName,
          supplierTradeCode: this.adminSelectedCommitUpload.supplierTradeCode,
          supplierOperation: this.adminSelectedCommitUpload.operation,
          supplierCompany: this.adminSelectedCommitUpload.company,
          uploadPeriod: this.convertDate(new Date(this.adminSelectedCommitUpload.uploadPeriod)),
          collectionDate: this.convertDate(new Date(this.adminSelectedCommitUpload.collectionDate)),
          paymentDate: this.convertDate(new Date(this.adminSelectedCommitUpload.paymentDate)),
          token: Session.mySession.get('user').token
        };
        this.tapsService.commitTapsUpload(request).then((output: any) => {
          if (output.status == "OK"){
            this.pageLoaded = true;
            this.sendMessageToDialog("Successfully committed transaction (TransactionID : " + output['res'] + ")","","");
            this.setupCommitUploadPage();
          } else {
            this.pageLoaded = true;
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
          }          
        }).catch((error: any) => {
          this.pageLoaded = true;
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
        });  
    } else {
      this.sendMessageToDialog('', 'You can commit transactions from Wednesday 16:00 PM until Saturday 9:00 AM', '');
    }
  }

  // It opens up Confirmation Dialog with a few calculations for All Uploads
  showCommitAllDialog(){
    this.adminCommitSelectedUpload = "";
    this.adminCommitNumOfTransactions = 0;
    this.adminCommitOriginalTotal = 0;
    this.adminCommitAdjustments = 0;
    this.adminCommitCollectionTotal = 0;
    if (this.adminCommitUploadListData.data.length > 0){
      this.adminCommitSelectedUpload = "All";
      this.adminCommitUploadListData.data.forEach((tempTapsUpload:any)=>{
        this.adminCommitNumOfTransactions = Number(this.adminCommitNumOfTransactions) + Number(tempTapsUpload.numOfTransactions)
        this.adminCommitOriginalTotal = Number(this.adminCommitOriginalTotal) + Number(tempTapsUpload.overallImportedValue)
        this.adminCommitAdjustments = Number(this.adminCommitAdjustments) + Number(Number(tempTapsUpload.overallImportedValue) - Number(tempTapsUpload.overallAmount))
        this.adminCommitCollectionTotal = Number(this.adminCommitCollectionTotal) + Number(tempTapsUpload.overallAmount)
      })
      this.dialog.open(this.commitAll);
    } else {
      this.sendMessageToDialog('', 'There are no transactions to commit', '');
    }
  }

  // Calls :: commitMultipleTapsUploads 
  // Multiple Commit Request (All accepted transactions of all the review files (*of an imported tranasaction) to new "Commited" ones)
  commitAllReviewFiles(){
    this.checkTransactionCommitAvailability();
    if (this.transactionAvailability == true) {     
      this.pageLoaded = false;
      let finalRequest:any = {
        company: Session.mySession.getUser().company,
        tradeCode: Session.mySession.getUser().tradeCode,
        operation: Session.mySession.getUser().operation,
        uploadPeriod: this.getUploadPeriod(true),
        token: Session.mySession.get('user').token
      }      
      this.tapsService.commitMultipleTapsUploads(finalRequest).then((output: any) => {
          this.pageLoaded = true;
          this.switchView("homePage");
          if (output.status == "OK"){
            this.commitAllInProgress = true;
            Session.mySession.resetTimersOnBookingValues().then((res:any)=>{
              this.sendMessageToDialog('Committing transactions in progress. Please wait..','','');
              AsynchRequests.myAsynchReq.addJobToWatch(finalRequest, output.jobID)
              this.checkIfJobIsDone().then((res:any)=>{
                this.commitAllInProgress = false;
              })
            })
          } else {
            this.sendMessageToDialog('', 'Commit multiple uploads failed (' + output.status + ')', output);
          }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
      }); 
    } else {
      this.sendMessageToDialog('', 'You can commit transactions from Wednesday 16:00 PM until Saturday 9:00 AM', '');
    }
  }

  // Refresh all the necessary data of the Transaction Approval Page
  clearViewTransactionApprovalDataForAdmin(){
    this.currertUploadPeriod = "";
    this.listOfExportedAgents.data = [];
  }

  // Calls :: exportToExcelMembersTransactionsForReview
  adminExportToExcelTransactionsForReview(){
    if (this.selectedAgentForAdminExport != "" && this.selectedAgentForAdminExport != null){
      this.setupAgentParent()  
      let request:any = {
        company: Session.mySession.getUser().company,
        tradeCode: Session.mySession.getUser().tradeCode,
        operation: Session.mySession.getUser().operation,
        agentTradeCode: this.selectedAgentForAdminExport,
        uploadPeriod: this.getUploadPeriod(true),
        onBookingPlatform: String(this.onBookingPlatform),
        agentParent: this.selectedAgentParent,
        token: Session.mySession.get('user').token,
      }
      this.pageLoaded = false;
      this.tapsService.exportToExcelMembersTransactionsForReview(request).then((res:any)=>{
        if (res["status"] == "OK"){
          if (res["transactionsCount"] > 0){
            var byteString = atob(res["excelContent"]);
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            const data = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
            saveAs(data, res["reportName"]); 
          } else {
            this.sendMessageToDialog('', 'Agent  (' + this.selectedAgentForAdminExport + ') has no transactions for review during this week period', '');
          }
          this.pageLoaded = true;
        } else {
          this.pageLoaded = true;
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2001S)', res);
        }
      }).catch((error: any) => {
        this.pageLoaded = true;
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2001S)', error);
      });
    } else {
      this.sendMessageToDialog("","You need to select an Agent before 'Export to Excel' or 'Import File'","")
    }
  }

  // Used to filter the Agent's list for Admin's Export or Import Transaction Approval page
  filterAdminAgentListSelect(): void {
    this.filteredAdminAgentsListData.data = []; 
    const filter = this.filterAdminAgentsListString.toLowerCase(); 
    for (let i = 0; i < this.backUpAgentListData.data.length; i++) {
      const option = this.backUpAgentListData.data[i];
      if (option.fullName.toLowerCase().indexOf(filter) >= 0) {
        this.filteredAdminAgentsListData.data.push(option);
      }
    }
  }

  // Used to filter the Agent's list for Supplier and Admin Reporting
  filterSupplierAgentListSelect(): void {
    this.filteredAdminAgentsListData.data = []; 
    const filter = this.filterAdminAgentsListString.toLowerCase(); 
    for (let i = 0; i < this.tapsAgentPageListData.data.length; i++) {
      const option = this.tapsAgentPageListData.data[i];
      if (option.fullName.toLowerCase().indexOf(filter) >= 0) {
        this.filteredAdminAgentsListData.data.push(option);
      }
    }
  }

  // Used to filter the Supplier's list for Member and Admin Reporting
  filterMemberSupplierListSelect(): void {
    this.backUpSupplierListData.data = this.suppliersListData.data;
    this.filteredAdminSupplierListData.data = []; 
    const filter = this.filterAdminSupplierListString.toLowerCase(); 
    for (let i = 0; i < this.backUpSupplierListData.data.length; i++) {
      const option = this.backUpSupplierListData.data[i];
      if (option.misttaCode){
        if (option.misttaCode.toLowerCase().indexOf(filter) >= 0 || option.supplierNameM.toLowerCase().indexOf(filter) >= 0) {
          this.filteredAdminSupplierListData.data.push(option);
        }
      } else {
        if (option.supplierNameM.toLowerCase().indexOf(filter) >= 0) {
          this.filteredAdminSupplierListData.data.push(option);
        }
      }
    }
  }

  // Calls :: getTransactionsInvoicingReport
  // Find button at 'Transactions Invoicing Page' 
  setupTransactionsInvoicingList(){
    if ((this.invoicingDateFrom != null && this.invoicingDateFrom != 0) && (this.invoicingDateTo != null && this.invoicingDateTo != 0)){
      if (this.invoicingDateFrom > this.invoicingDateTo) {
        this.sendMessageToDialog("","Please ensure that the 'Date-From' date is earlier than 'Date-To' date","");
      } else {
        this.pageLoaded = false;
        let request:any = {
          company: Session.mySession.getUser().company,
          tradeCode: Session.mySession.getUser().tradeCode,
          operation: Session.mySession.getUser().operation,
          uploadDateFrom: this.convertDate(this.invoicingDateFrom),
          uploadDateTo: this.convertDate(this.invoicingDateTo),
          token: Session.mySession.get('user').token
        }
        this.tapsService.getTransactionsInvoicingReport(request).then((output:any)=>{
          if (output["status"] == "OK"){
            if (output["res"].length > 0) {
              this.tranInvoiceListData.data = [...output["res"]]
            } else {
              this.tranInvoiceListData.data = [];
            }
          } else {
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
          }
        });
        this.pageLoaded = true;
      }
    } else {
      this.sendMessageToDialog('', 'Please fill in all the required data','');
    }
  }

  // Exports to XLSX format the list of tranInvoiceListData
  // Export to Excel button at 'Transactions Invoicing Page' 
  exportTransactionsInvoicingReport(){
    if (this.tranInvoiceListData.data.length > 0){
      let reportName = this.getReportName();
      const exportMe: any = [];
      let oneLine: any = {};
      this.tranInvoiceListData.data.forEach((item: any) => {
        if (item.checked == true) {
          oneLine = {};
          oneLine["Supplier"] = item.tapsIdent;
          oneLine["Name"] = item.name;
          oneLine["Quantity"] = item.quantity;
          oneLine["Rate"] = item.rate;
          oneLine["Value"] = this.getValidNumberToExcel(item.value);
          oneLine["Created"] = new Date().toLocaleDateString();
          exportMe.push(oneLine);
        }
      });
      this.exportTapsReportAsExcelFile(exportMe, reportName, "E", "", false);
    } else {
      this.sendMessageToDialog("","There are no transactions listed to export","");
    }
  }

  // Authentication before calling commitTransactionsInvoicingReport()
  authenticateCommitForInvoicingTransactions(form: NgForm) {
    if (confirm("Are you sure you want to commit the selected Invoicing Transactions?")){
      let request = { email: form.value.email, password: form.value.password };
      this.userService.login(request).then(async (output: any) => {
        if (output.status === 'OK') {
          this.session.set('user', { id: output.data.id, token: output.data.token });
          this.commitTransactionsInvoicingReport()
        } else {
          this.sendMessageToDialog('', output.status, ''); // Display error status from the back
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2009S) - please try again', error);
      })
    }
  }

  // Calls :: commitTransactionsInvoicingReport
  // Commit button at 'Transactions Invoicing Page'
  commitTransactionsInvoicingReport(){
    this.transactionInvoicingListOfSuppliers = []
    if (this.tranInvoiceListData.data.length > 0){
      this.tranInvoiceListData.data.forEach((invoiceRecord:any)=>{
        if (invoiceRecord.checked == true){
          this.transactionInvoicingListOfSuppliers.push(String(invoiceRecord.tapsIdent));
        }
      })
    }
    if ((this.invoicingDateFrom != null && this.invoicingDateFrom != 0) && (this.invoicingDateTo != null && this.invoicingDateTo != 0)){
      if (this.invoicingDateFrom > this.invoicingDateTo) {
        this.sendMessageToDialog("","Please ensure that the 'Date-From' date is earlier than 'Date-To' date","");
      } else {
        if (this.transactionInvoicingListOfSuppliers.length == 0){
          this.sendMessageToDialog("","Please select at least one record 'To Export' before commit","");
        } else {
          this.pageLoaded = false;
          let request:any = {
            company: Session.mySession.getUser().company,
            tradeCode: Session.mySession.getUser().tradeCode,
            operation: Session.mySession.getUser().operation,
            uploadDateFrom: this.convertDate(this.invoicingDateFrom),
            uploadDateTo: this.convertDate(this.invoicingDateTo),
            selectedSuppliers: this.transactionInvoicingListOfSuppliers,
            token: Session.mySession.get('user').token
          }
          this.tapsService.commitTransactionsInvoicingReport(request).then((output:any)=>{
            if (output["status"] == "OK"){
                this.tranInvoiceListData.data = [];
                this.sendMessageToDialog('Successfully committed (' + output["res"] + ') invoicing transaction(s) (' + new Date(this.invoicingDateFrom).toLocaleDateString() + ' - ' + new Date(this.invoicingDateTo).toLocaleDateString() + ')', '', '');
            } else {
              this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
            }
          });
          this.pageLoaded = true;
        }
      }
    } else {
      this.sendMessageToDialog('', 'Please fill in all the required data','');
    }
  }

  // Checking the file type to navigate the loading process accordingly [ TXT or CSV or XLSX ]
  lateAdjustmentPreviewPage(fileInput: any){ 
    this.checkUploadAvailabilityForAdmin();
    this.lateAdjustmentFile = fileInput;
    let actualFile = fileInput.target.files[0];
    if (actualFile.size > 4194304) {
       this.sendMessageToDialog('', 'This file is too big. Maximum file upload is 4MB', '');
    } else {
      if (actualFile.size > 0){
        if (actualFile.type == "text/plain") {
          this.clearViewUploadFile();     
          this.loadLateAdjustmentTxtFile(actualFile);
        } else if (actualFile.type == "text/csv") { 
          this.clearViewUploadFile();       
          this.loadLateAdjustmentCSVFile(actualFile);
        } else if (actualFile.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || actualFile.type == "application/vnd.ms-excel") {
          this.clearViewUploadFile();       
          this.loadLateAdjustmentXLSXFile(actualFile);
        } else {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (Invalid File Format)', "");
        }
      } else {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (Empty File)', "");
      }
    }
  }

  // If uploaded file from Late Adjustment page is TXT, we load it from here
  loadLateAdjustmentTxtFile(actualFile:any){
    this.pageLoaded = false; 
    this.typeOfFileUpload = "TXT";
    let allTransactions:any = [];
    let rejectedTransactions:any = [];
    let fileText:any = [];    
    let overallSumOfValue:any = 0;
    let numOfTransactions:any = 0;
    let tempCurrentDate:any = new Date();
    let currentDate:any = new Date();
    if (this.uploadAvailability == true){
      currentDate = this.subtrackDays(7,tempCurrentDate);
    }
    let tempAvailableFrom:any = this.getNextDayOfTheWeek('sat',true,currentDate);
    let tempUploadPeriod = this.subtrackDays(1,tempAvailableFrom);
    let uploadPeriod = this.convertDate(tempUploadPeriod);
    let tranState = "Imported";
    let tranFailure = "None";
    let fileFormatCheck:any = {};
    let tempUploadTransactions = new MatTableDataSource<any>();
    let tempUploadRejectedTransactions = new MatTableDataSource<any>();
    this.readTxtFile(actualFile).then((output:any)=>{
          fileText = output;
          if (fileText.length == 1){
            fileText = String(fileText).split('\r');
            if (fileText[fileText.length-1].trim() == ""){
              fileText.splice(-1);
            }
          }
          fileFormatCheck = this.checkIfValidFileFormatTxtAndReturn(fileText[0],fileText[fileText.length-1]);
          if (fileFormatCheck.validFormat == true){
            if (fileFormatCheck.validSupplier == true){
              // Load Page
              this.pageLoaded = true;
              this.lateAdjustmentUploadedFile = true;
              // Setup allTransactions
              fileText.forEach((row:any)=>{
                let transactionInfo:any = this.convertTxtTransactionToCsv(row);
                if (transactionInfo.validTransaction == true && transactionInfo.rejectedTransaction == false){
                  let transactionRow = transactionInfo.transactionRow.split(",");
                  let acceptedTransaction = this.setupAcceptedTransactionRow(transactionRow,true,fileFormatCheck.supplierName,fileFormatCheck.supplierMisttaCode,transactionInfo.agentName);
                  allTransactions.push(acceptedTransaction);
                  overallSumOfValue = Number(overallSumOfValue) + Number(acceptedTransaction.amount);
                  numOfTransactions = numOfTransactions + 1;  
                } else if (transactionInfo.validTransaction == true && transactionInfo.rejectedTransaction == true){
                  let transactionRow = transactionInfo.transactionRow.split(",");
                  let transactionFailureMessage = this.checkTransactionRowForFailures(transactionRow);
                  // Adding a check in case of not found failure to be received by the transactionInfo
                  if (transactionFailureMessage == ""){
                    transactionFailureMessage = transactionInfo.rejectedReason
                  }
                  transactionRow.push(transactionFailureMessage);
                  rejectedTransactions.push(transactionRow);
                  let rejectedTransaction = this.setupRejectedTransactionRow(transactionRow,true,fileFormatCheck.supplierName,fileFormatCheck.supplierMisttaCode,transactionInfo.agentName,transactionFailureMessage);
                  allTransactions.push(rejectedTransaction);
                  if (this.isAFloatNumber(rejectedTransaction.amount)){
                    overallSumOfValue = Number(overallSumOfValue) + Number(rejectedTransaction.amount);
                  }
                  numOfTransactions = numOfTransactions + 1; 
                }
              });
              
              // Check if there are any available transactions
              if (allTransactions.length > 0){
    
                // Then check for duplicates within the list
                let sizeOfList:any = allTransactions.length;
                if (sizeOfList > 0){
                  for (let counterX = 0; counterX < sizeOfList; counterX++){
                    let tranCounter:any = 0;
                    let tempTransactionX:any = allTransactions[counterX];
                    for (let counterY = 0; counterY < sizeOfList; counterY++){
                      let tempTransactionY:any = allTransactions[counterY];
                      if ((tempTransactionX.reference == tempTransactionY.reference) 
                          && (tempTransactionX.departure == tempTransactionY.departure)
                          && (tempTransactionX.amount == tempTransactionY.amount)
                          && (tempTransactionX.client == tempTransactionY.client)
                          && (tempTransactionX.memberTradeCode == tempTransactionY.memberTradeCode) 
                          && (tempTransactionX.state == tempTransactionY.state)){
                        tranCounter = Number(tranCounter) + 1;
                      }
                    }
                    if (tranCounter >= 2){
                      tranFailure = "Some Duplicated Transactions";
                      allTransactions[counterX].state = 'Rejected'
                      allTransactions[counterX].warning = 'Duplicated Transaction'
                      rejectedTransactions.push(allTransactions[counterX]);
                    }
                  }
                }
    
                // Check if tranState will be 'Imported' or 'Failed' 
                let rejectedTransactionsCounter = 0;
                let acceptedTransactionsCounter = 0;
                allTransactions.forEach((tempTrans:any)=>{
                  if (tempTrans.state != 'Accepted') {
                    rejectedTransactionsCounter = rejectedTransactionsCounter + 1
                  } else {
                    acceptedTransactionsCounter = acceptedTransactionsCounter + 1
                  }
                })
                if (rejectedTransactionsCounter > 0 && acceptedTransactionsCounter > rejectedTransactionsCounter){
                  if (tranFailure == "Some Duplicated Transactions"){
                    tranFailure = 'Some Rejected / Duplicated Transactions'
                  } else {
                    tranFailure = 'Some Rejected Transactions'
                  }
                } 
                if (rejectedTransactionsCounter > acceptedTransactionsCounter){
                  if (tranFailure == "Some Duplicated Transactions"){
                    tranFailure = 'Some Rejected / Duplicated Transactions'
                  } else {
                    tranFailure = 'Some Rejected Transactions'
                  }
                }
                if (acceptedTransactionsCounter == 0){
                  tranState = 'Failed'
                  tranFailure = 'Rejected Transactions'
                }
    
                // Setup the transaction report page
                allTransactions.forEach((row:any)=>{
                  // ONE FINAL CHECK OF DEPARTURE DATE
                  if (this.isABritishDateFormat(row.departure) == false){
                    const convertedDeptDate = this.convertDateToBritishFormat(row.departure);
                    if (convertedDeptDate.startsWith('Invalid date')) {
                      const tempInvalidDeptDate = String(row.departure).slice(0, 15);
                      if (row.warning === "None") {
                        // Attempt conversion or set to default invalid date
                        row.departure = "01/01/2000";
                        row.state = "Rejected";
                        row.warning = `Invalid Dept Date Format (${tempInvalidDeptDate})`;
                      } else {
                        row.departure = "01/01/2000";
                        row.state = "Rejected";
                        row.warning += ` and Dept Date (${row.departure})`;
                      }
                    } else {
                      row.departure = convertedDeptDate; // Set the converted date if valid
                    }
                  }
                  let tempRow:any = {
                    tranAgent: row.memberName,
                    tranTapsId: String(row.memberTradeCode).toUpperCase(),
                    tranRef: row.reference,
                    tranDepart: row.departure,
                    tranClient: row.client, 
                    tranWarning: row.warning,
                    tranValue: parseFloat(row.amount).toFixed(2), 
                    tranAdjust: this.checkIfAdjusted(row.approval), 
                    tranCollectedValue: row.collected, 
                    tranCode: row.code, 
                    tranName: row.reason, 
                    tranState: row.state
                  };
                   //Seperate Rejected and Accepted Transactions 
                   if (tempRow.tranState == "Accepted" || tempRow.tranState == "Paid" || tempRow.tranState == "Collected"){   
                     tempUploadTransactions.data.push(tempRow);  
                     // Check for approved and unapproved amount
                     if (this.isAFloatNumber(tempRow.tranValue)){
                      this.viewOverallAcceptedAmount = Number(this.viewOverallAcceptedAmount) + Number(tempRow.tranValue);
                       if (tempRow.tranAdjust == "Yes"){
                         this.viewAdjustments = Number(this.viewAdjustments) + Number(tempRow.tranValue);
                        } else {
                         this.viewToPay =  Number(this.viewToPay) + Number(tempRow.tranValue);
                        }   
                     }                           
                   } else if (tempRow.tranState == "Rejected"){                  
                     tempRow.tranFailure = tempRow.tranWarning;
                     if (this.isAFloatNumber(tempRow.tranValue))
                     {                        
                       this.viewOverallRejectedAmount = Number(this.viewOverallRejectedAmount) + Number(tempRow.tranValue);                    
                     }            
                     tempUploadRejectedTransactions.data.push(tempRow);
                   }        
                });
                // Setup overall stat values
                this.tapsUploadTransactionData.data = tempUploadTransactions.data;
                this.tapsUploadTransactionRejectedData.data = tempUploadRejectedTransactions.data;
                this.viewAdjustmentUploadTapsId = fileFormatCheck.supplierMisttaCode;
                this.viewAdjustmentUploadSupplierName = fileFormatCheck.supplierName;
                this.viewAllTransactions = [...allTransactions];
                this.viewRejectedTransactions = rejectedTransactions
                this.viewTransactionState = tranState;
                this.viewTranFailureState = tranFailure;
                this.viewUploadDate = new Date().toLocaleDateString();
                this.viewNumOfTransactions = numOfTransactions;
                this.viewAdjustments = parseFloat(this.viewAdjustments).toFixed(2);
                this.viewOverallRejectedAmount = parseFloat(this.viewOverallRejectedAmount).toFixed(2);
                this.viewOverallAcceptedAmount = parseFloat(this.viewOverallAcceptedAmount).toFixed(2);    
                this.viewValue = Number(this.viewOverallAcceptedAmount) + Number(this.viewOverallRejectedAmount);
                this.viewValue = parseFloat(this.viewValue).toFixed(2);  
                this.setupToPayAmount(tranState); 
                this.generateAgentReviewPeriodAndPaymentDue(uploadPeriod,fileFormatCheck.tapsPaymentDays);  
                const preTranOverallValue = this.viewValue;
                const preNumOfTransactions = this.viewNumOfTransactions;

                const preTempCurrentDate = moment();
                const preCurrentDate:any = this.subtrackDays(7,preTempCurrentDate.toDate());
                const preTempAvailableFrom:any = this.getNextDayOfTheWeek('sat',true,preCurrentDate);
                const preTempUploadPeriod = this.subtrackDays(1,preTempAvailableFrom);
                const preUploadPeriod = this.convertDate(preTempUploadPeriod);        
                const preCollectionDate = this.convertDate(this.addDays(7,preTempUploadPeriod))
                const prePaymentDate = this.convertDate(this.addDays(this.userSupplierPaymentDays + 7,preTempUploadPeriod));

                const preTranState = "Importing";
                const preTranFailure = "Rejected by User";
                const preUploadSupplierMisttaCode = this.viewAdjustmentUploadTapsId;
                const preUploadSupplierName = this.viewAdjustmentUploadSupplierName;
                const preOriginalID = "Original";   
                const preTranCollected = 0;
                const preAgentName = "";
                const preAgentTradeCode = "";
                const preRequest: any = {             
                  company: Session.mySession.getUser().company,
                  tradeCode: Session.mySession.getUser().tradeCode,
                  operation: Session.mySession.getUser().operation,
                  agentName: preAgentName,
                  agentTradeCode: preAgentTradeCode,
                  supplierName: preUploadSupplierName,
                  supplierTradeCode: preUploadSupplierMisttaCode,
                  misttaCode: preUploadSupplierMisttaCode,
                  uploadPeriod: preUploadPeriod,
                  collectionDate: preCollectionDate,
                  paymentDate: prePaymentDate,
                  tranValue: preTranOverallValue,
                  tranCollected: preTranCollected,
                  tranFailure: preTranFailure,
                  tranState: preTranState,
                  numOfTransactions: preNumOfTransactions,
                  listOfTransactions: [],
                  transactionOriginalID: preOriginalID,
                  token: Session.mySession.get('user').token
                }
                this.pageLoaded = false;
                this.tapsService.preCreateTapsUpload(preRequest).then((output:any)=>{
                  this.pageLoaded = true;
                  if (output.status == "OK"){
                    // Setup response message
                    if (tranFailure == "None" && rejectedTransactions.length == 0){
                      this.sendMessageToDialog("Successfully loaded with the following transaction State : " + tranState,"","");
                    } else if (tranFailure == "Some Duplicated Transactions") {
                      let outputMessage = "Successfully loaded with the following transaction State : " + tranState;
                      let rejectedTransactionsList = "There are duplicated transactions in the uploaded file.";
                      let finalMessage:any = outputMessage + '<br><br>' + rejectedTransactionsList;
                      this.viewTransactionFailure = rejectedTransactionsList;
                      this.sendMessageToDialog(finalMessage,"","");
                    } else {
                      let outputMessage = "Successfully loaded with the following transaction State : " + tranState;
                      let rejectedTransactionsList = "Warning : " + tranFailure + "<br>Not all records were successfully accepted.<br>The following were rejected:";
                      rejectedTransactions.forEach((transaction:any)=>{
                        rejectedTransactionsList = rejectedTransactionsList + '<br><br>Transaction Failed<br>' + this.returnListedTransactionValues(transaction);
                      });
                      let finalMessage:any = outputMessage + '<br><br>' + rejectedTransactionsList;
                      this.viewTransactionFailure = rejectedTransactionsList;
                      this.sendMessageToDialog(finalMessage,"","");
                    }
                    this.preImportedTransactionID = output["res"].transactionID;
                  } else {
                    this.sendMessageToDialog("",output["res"],"");
                    this.clearViewUploadFile()
                    this.switchView("homePage");
                  }
                });
            } else {
              this.pageLoaded = true;
              this.sendMessageToDialog('', 'SinGS could not complete your request at this time (No Transactions Found)', "");
            }
            } else {
              this.pageLoaded = true;
              this.sendMessageToDialog('SinGS could not complete your request at this time (Invalid Supplier Format)<br><br>Reason: ' + this.uploadFailedReason + '', "", "");
            } 
          } else {
            this.pageLoaded = true;
            this.sendMessageToDialog('SinGS could not complete your request at this time (Invalid Header/Footer or File Format)<br><br>Reason: ' + this.uploadFailedReason + '', '', "");
          }
    });
  }
 
  // If uploaded file from Late Adjustment page is CSV, we load it from here
  loadLateAdjustmentCSVFile(actualFile:any){
    this.pageLoaded = false;
    this.typeOfFileUpload = "CSV";
    let allTransactions:any = [];
    let rejectedTransactions:any = [];
    let validHeaderFormat:any = {};   
    let overallSumOfValue:any = 0;
    let numOfTransactions:any = 0;
    let tempCurrentDate:any = new Date();
    let currentDate:any = new Date();
    if (this.uploadAvailability == true){
      currentDate = this.subtrackDays(7,tempCurrentDate);
    }
    let tempAvailableFrom:any = this.getNextDayOfTheWeek('sat',true,currentDate);
    let tempUploadPeriod = this.subtrackDays(1,tempAvailableFrom);
    let uploadPeriod = this.convertDate(tempUploadPeriod);
    let tranState = "Imported";
    let tranFailure = "None";
    let tempUploadTransactions = new MatTableDataSource<any>();
    let tempUploadRejectedTransactions = new MatTableDataSource<any>();
    this.ngxCsvParser
    .parse(actualFile, { header: false, delimiter: ',' })
    .pipe(
      map((result: any) => result.filter((row:any) => row.some((cell:any) => cell.trim() !== '')))
    )
    .subscribe(
      (result:any) => {
        validHeaderFormat = this.checkIfValidFileFormatAndReturnSupplier(result);
        if (validHeaderFormat.validFormat == true)
        {
          if (validHeaderFormat.validSupplier == true){

            //Setup allTransactions list
            result.forEach((row:any)=>{
              let checkForTransactionRow = this.isATransactionRow(row);
              if (checkForTransactionRow == true) {
                  let checkTransactionRowForFailures:any = this.checkTransactionRowForFailures(row);
                  if (checkTransactionRowForFailures == ""){

                  let tempAgentName:any = "N/A" 
                  if (row?.length > 2){
                    tempAgentName = this.checkIfMemberExist(row[1]);
                    if (tempAgentName == false){
                      tempAgentName = "N/A"
                    }
                  } 

                  let newTempTransaction = this.setupAcceptedTransactionRow(row,true,validHeaderFormat.supplierName,validHeaderFormat.supplierMisttaCode,tempAgentName);
                  allTransactions.push(newTempTransaction);
                  overallSumOfValue = Number(overallSumOfValue) + Number(newTempTransaction.amount);
                  numOfTransactions = numOfTransactions + 1;  
                } else {                  

                  let tempAgentName:any = "N/A" 
                  if (row?.length > 2){
                    tempAgentName = this.checkIfMemberExist(row[1]);
                    if (tempAgentName == false){
                      tempAgentName = "N/A"
                    }
                  }

                  row.push(checkTransactionRowForFailures);
                  rejectedTransactions.push(row);
                  let newTempTransaction = this.setupRejectedTransactionRow(row,true,validHeaderFormat.supplierName,validHeaderFormat.supplierMisttaCode,tempAgentName,checkTransactionRowForFailures);
                  allTransactions.push(newTempTransaction);
                  if (this.isAFloatNumber(newTempTransaction.amount)){
                    overallSumOfValue = Number(overallSumOfValue) + Number(newTempTransaction.amount);
                  }
                  numOfTransactions = numOfTransactions + 1;  
                }        
              } else if (checkForTransactionRow == "Missing Data"){
              
                //  KP : We skip 'Missing Data' Transactions as they will cause issues to DB
                //
                // let tempAgentName:any = "N/A" 
                //       if (row?.length > 2){
                //         tempAgentName = this.checkIfMemberExist(row[1]);
                //         if (tempAgentName == false){
                //           tempAgentName = "N/A"
                //         }
                //       } 
                // let checkTransactionRowForFailures:any = this.checkTransactionRowForFailures(row);
                // row.push(checkTransactionRowForFailures);
                // rejectedTransactions.push(row);
                // let newTempTransaction = this.setupRejectedTransactionRow(row,true,validHeaderFormat.supplierName,validHeaderFormat.supplierMisttaCode,tempAgentName,checkTransactionRowForFailures);
                // allTransactions.push(newTempTransaction);
                // if (this.isAFloatNumber(newTempTransaction.amount)){
                //   overallSumOfValue = Number(overallSumOfValue) + Number(newTempTransaction.amount);
                // }
                // numOfTransactions = numOfTransactions + 1;

              }                                 
            });

            // Then check for duplicates within the list
            let sizeOfList:any = allTransactions.length;
            if (sizeOfList > 0){
              for (let counterX = 0; counterX < sizeOfList; counterX++){
                let tranCounter:any = 0;
                let tempTransactionX:any = allTransactions[counterX];
                for (let counterY = 0; counterY < sizeOfList; counterY++){
                  let tempTransactionY:any = allTransactions[counterY];
                  if ((tempTransactionX.reference == tempTransactionY.reference) 
                      && (tempTransactionX.departure == tempTransactionY.departure)
                      && (tempTransactionX.amount == tempTransactionY.amount)
                      && (tempTransactionX.client == tempTransactionY.client)
                      && (tempTransactionX.memberTradeCode == tempTransactionY.memberTradeCode) 
                      && (tempTransactionX.state == tempTransactionY.state)){
                    tranCounter = Number(tranCounter) + 1;
                  }
                }
                if (tranCounter >= 2){
                  tranFailure = "Some Duplicated Transactions";
                  allTransactions[counterX].state = 'Rejected'
                  allTransactions[counterX].warning = 'Duplicated Transaction'
                  rejectedTransactions.push(allTransactions[counterX]);
                }
              }
            }

            // Check if tranState will be 'Imported' or 'Failed' 
            let rejectedTransactionsCounter = 0;
            let acceptedTransactionsCounter = 0;
            allTransactions.forEach((tempTrans:any)=>{
              if (tempTrans.state != 'Accepted') {
                rejectedTransactionsCounter = rejectedTransactionsCounter + 1
              } else {
                acceptedTransactionsCounter = acceptedTransactionsCounter + 1
              }
            })
            if (rejectedTransactionsCounter > 0 && acceptedTransactionsCounter > rejectedTransactionsCounter){
              if (tranFailure == "Some Duplicated Transactions"){
                tranFailure = 'Some Rejected / Duplicated Transactions'
              } else {
                tranFailure = 'Some Rejected Transactions'
              }
            } 
            if (rejectedTransactionsCounter > acceptedTransactionsCounter){
              if (tranFailure == "Some Duplicated Transactions"){
                tranFailure = 'Some Rejected / Duplicated Transactions'
              } else {
                tranFailure = 'Some Rejected Transactions'
              }
            }
            if (acceptedTransactionsCounter == 0){
              tranState = 'Failed'
              tranFailure = 'Rejected Transactions'
            } 

            // Check once again if there are any available transactions
            if (allTransactions.length > 0){

              // Setup the transaction report page
              allTransactions.forEach((row:any)=>{
                // ONE FINAL CHECK OF DEPARTURE DATE
                if (this.isABritishDateFormat(row.departure) == false){
                  const convertedDeptDate = this.convertDateToBritishFormat(row.departure);
                  if (convertedDeptDate.startsWith('Invalid date')) {
                    const tempInvalidDeptDate = String(row.departure).slice(0, 15);
                    if (row.warning === "None") {
                      // Attempt conversion or set to default invalid date
                      row.departure = "01/01/2000";
                      row.state = "Rejected";
                      row.warning = `Invalid Dept Date Format (${tempInvalidDeptDate})`;
                    } else {
                      row.departure = "01/01/2000";
                      row.state = "Rejected";
                      row.warning += ` and Dept Date (${row.departure})`;
                    }
                  } else {
                    row.departure = convertedDeptDate; // Set the converted date if valid
                  }
                }
                let tempRow:any = {
                  tranAgent: row.memberName,
                  tranTapsId: String(row.memberTradeCode).toUpperCase(),
                  tranRef: row.reference,
                  tranDepart: row.departure,
                  tranClient: row.client, 
                  tranWarning: row.warning,
                  tranValue: parseFloat(row.amount).toFixed(2), 
                  tranAdjust: this.checkIfAdjusted(row.approval), 
                  tranCollectedValue: row.collected, 
                  tranCode: row.code, 
                  tranName: row.reason, 
                  tranState: row.state
                };

                 //Seperate Rejected and Accepted Transactions 
                 if (tempRow.tranState == "Accepted" || tempRow.tranState == "Paid" || tempRow.tranState == "Collected"){   
                   tempUploadTransactions.data.push(tempRow);  
                   // Check for approved and unapproved amount
                   if (this.isAFloatNumber(tempRow.tranValue)){
                    this.viewOverallAcceptedAmount = Number(this.viewOverallAcceptedAmount) + Number(tempRow.tranValue);
                     if (tempRow.tranAdjust == "Yes"){
                       this.viewAdjustments = Number(this.viewAdjustments) + Number(tempRow.tranValue);
                      } else {
                       this.viewToPay =  Number(this.viewToPay) + Number(tempRow.tranValue);
                      }   
                   }                           
                 } else if (tempRow.tranState == "Rejected"){                  
                   tempRow.tranFailure = tempRow.tranWarning;
                   if (this.isAFloatNumber(tempRow.tranValue))
                   {                        
                     this.viewOverallRejectedAmount = Number(this.viewOverallRejectedAmount) + Number(tempRow.tranValue);                    
                   }            
                   tempUploadRejectedTransactions.data.push(tempRow);
                 }        
              });

              // Setup overall stat values
              this.tapsUploadTransactionData.data = tempUploadTransactions.data;
              this.tapsUploadTransactionRejectedData.data = tempUploadRejectedTransactions.data;
              this.viewAdjustmentUploadTapsId = validHeaderFormat.supplierMisttaCode;
              this.viewAdjustmentUploadSupplierName = validHeaderFormat.supplierName;
              this.viewAllTransactions = [...allTransactions];
              this.viewRejectedTransactions = rejectedTransactions
              this.viewTransactionState = tranState;
              this.viewTranFailureState = tranFailure;
              this.viewUploadDate = new Date().toLocaleDateString();
              this.viewNumOfTransactions = numOfTransactions;
              this.viewAdjustments = parseFloat(this.viewAdjustments).toFixed(2);
              this.viewOverallRejectedAmount = parseFloat(this.viewOverallRejectedAmount).toFixed(2);
              this.viewOverallAcceptedAmount = parseFloat(this.viewOverallAcceptedAmount).toFixed(2);    
              this.viewValue = Number(this.viewOverallAcceptedAmount) + Number(this.viewOverallRejectedAmount);  
              this.viewValue = parseFloat(this.viewValue).toFixed(2);
              this.setupToPayAmount(tranState); 
              this.generateAgentReviewPeriodAndPaymentDue(uploadPeriod,validHeaderFormat.tapsPaymentDays);          
              this.pageLoaded = true;
              this.lateAdjustmentUploadedFile = true;
              const preTranOverallValue = this.viewValue;
              const preNumOfTransactions = this.viewNumOfTransactions;

              const preTempCurrentDate = moment();
              const preCurrentDate:any = this.subtrackDays(7,preTempCurrentDate.toDate());
              const preTempAvailableFrom:any = this.getNextDayOfTheWeek('sat',true,preCurrentDate);
              const preTempUploadPeriod = this.subtrackDays(1,preTempAvailableFrom);
              const preUploadPeriod = this.convertDate(preTempUploadPeriod);        
              const preCollectionDate = this.convertDate(this.addDays(7,preTempUploadPeriod))
              const prePaymentDate = this.convertDate(this.addDays(this.userSupplierPaymentDays + 7,preTempUploadPeriod));

              const preTranState = "Importing"
              const preTranFailure = "Rejected by User";
              const preUploadSupplierMisttaCode = this.viewAdjustmentUploadTapsId;
              const preUploadSupplierName = this.viewAdjustmentUploadSupplierName;
              const preOriginalID = "Original";   
              const preTranCollected = 0;
              const preAgentName = "";
              const preAgentTradeCode = "";
              const preRequest: any = {             
                company: Session.mySession.getUser().company,
                tradeCode: Session.mySession.getUser().tradeCode,
                operation: Session.mySession.getUser().operation,
                agentName: preAgentName,
                agentTradeCode: preAgentTradeCode,
                supplierName: preUploadSupplierName,
                supplierTradeCode: preUploadSupplierMisttaCode,
                misttaCode: preUploadSupplierMisttaCode,
                uploadPeriod: preUploadPeriod,
                collectionDate: preCollectionDate,
                paymentDate: prePaymentDate,
                tranValue: preTranOverallValue,
                tranCollected: preTranCollected,
                tranFailure: preTranFailure,
                tranState: preTranState,
                numOfTransactions: preNumOfTransactions,
                listOfTransactions: [],
                transactionOriginalID: preOriginalID,
                token: Session.mySession.get('user').token
              }
              this.pageLoaded = false;
              this.tapsService.preCreateTapsUpload(preRequest).then((output:any)=>{
                this.pageLoaded = true;
                if (output.status == "OK"){
                  // Setup response message
                  if (tranFailure == "None" && rejectedTransactions.length == 0){
                    this.sendMessageToDialog("Successfully loaded with the following transaction State : " + tranState,"","");
                  } else if (tranFailure == "Some Duplicated Transactions") {
                    let outputMessage = "Successfully loaded with the following transaction State : " + tranState;
                    let rejectedTransactionsList = "There are duplicated transactions in the uploaded file.";
                    let finalMessage:any = outputMessage + '<br><br>' + rejectedTransactionsList;
                    this.viewTransactionFailure = rejectedTransactionsList;
                    this.sendMessageToDialog(finalMessage,"","");
                  } else {
                    let outputMessage = "Successfully loaded with the following transaction State : " + tranState;
                    let rejectedTransactionsList = "Warning : " + tranFailure + "<br>Not all records were successfully accepted.<br>The following were rejected:";
                    rejectedTransactions.forEach((transaction:any)=>{
                      rejectedTransactionsList = rejectedTransactionsList + '<br><br>Transaction Failed<br>' + this.returnListedTransactionValues(transaction);
                    });
                    let finalMessage:any = outputMessage + '<br><br>' + rejectedTransactionsList;
                    this.viewTransactionFailure = rejectedTransactionsList;
                    this.sendMessageToDialog(finalMessage,"","");
                  }
                  this.preImportedTransactionID = output["res"].transactionID;
                } else {
                  this.sendMessageToDialog("",output["res"],"");
                  this.clearViewUploadFile()
                  this.switchView("homePage");
                }
              });
            } else {
              this.sendMessageToDialog('', 'SinGS could not complete your request at this time (No Transactions Found)', "");
            }
          } else {
            this.sendMessageToDialog('SinGS could not complete your request at this time (Invalid Supplier Format)<br><br>Reason: ' + this.uploadFailedReason + '', '', "");
          }
        } else {
          this.sendMessageToDialog('SinGS could not complete your request at this time (Invalid Header/Footer or File Format)<br><br>Reason: ' + this.uploadFailedReason + '', '', "");
        }          
      },
      (error: NgxCSVParserError) => {
        this.sendMessageToDialog('', 'Invalid file format. You need to upload a CSV file.', error);
      }
    );     
  }

  // If uploaded file from Late Adjustment page is XLSX, we load it from here
  loadLateAdjustmentXLSXFile(actualFile:any){
    this.pageLoaded = false;
    this.typeOfFileUpload = "XLSX";
    let allTransactions:any = [];
    let rejectedTransactions:any = [];
    let validHeaderFormat:any = {};      
    let overallSumOfValue:any = 0;
    let numOfTransactions:any = 0;
    let tempCurrentDate:any = new Date();
    let currentDate:any = new Date();
    if (this.uploadAvailability == true){
      currentDate = this.subtrackDays(7,tempCurrentDate);
    }
    let tempAvailableFrom:any = this.getNextDayOfTheWeek('sat',true,currentDate);
    let tempUploadPeriod = this.subtrackDays(1,tempAvailableFrom);
    let uploadPeriod = this.convertDate(tempUploadPeriod);
    let tranState = "Imported";
    let tranFailure = "None";
    let tempUploadTransactions = new MatTableDataSource<any>();
    let tempUploadRejectedTransactions = new MatTableDataSource<any>();
    this.convertXLSXFileToCSV(actualFile).then((csvData:any) => {
      validHeaderFormat = this.checkIfValidFileFormatAndReturnSupplier(csvData);
        if (validHeaderFormat.validFormat == true) {
          if (validHeaderFormat.validSupplier == true) {

            // Setup allTransactions
            csvData.forEach((row:any)=>{
              let checkForTransactionRow = this.isATransactionRow(row);
              if (checkForTransactionRow == true) {
                  let checkTransactionRowForFailures:any = this.checkTransactionRowForFailures(row);
                  if (checkTransactionRowForFailures == ""){

                  let tempAgentName:any = "N/A" 
                  if (row?.length > 2){
                    tempAgentName = this.checkIfMemberExist(row[1]);
                    if (tempAgentName == false){
                      tempAgentName = "N/A"
                    }
                  } 

                  let newTempTransaction = this.setupAcceptedTransactionRow(row,true,validHeaderFormat.supplierName,validHeaderFormat.supplierMisttaCode,tempAgentName);
                  allTransactions.push(newTempTransaction);
                  overallSumOfValue = Number(overallSumOfValue) + Number(newTempTransaction.amount);
                  numOfTransactions = numOfTransactions + 1;  
                } else {                  

                  let tempAgentName:any = "N/A" 
                  if (row?.length > 2){
                    tempAgentName = this.checkIfMemberExist(row[1]);
                    if (tempAgentName == false){
                      tempAgentName = "N/A"
                    }
                  }

                  row.push(checkTransactionRowForFailures);
                  rejectedTransactions.push(row);
                  let newTempTransaction = this.setupRejectedTransactionRow(row,true,validHeaderFormat.supplierName,validHeaderFormat.supplierMisttaCode,tempAgentName,checkTransactionRowForFailures);
                  allTransactions.push(newTempTransaction);
                  if (this.isAFloatNumber(newTempTransaction.amount)){
                    overallSumOfValue = Number(overallSumOfValue) + Number(newTempTransaction.amount);
                  }
                  numOfTransactions = numOfTransactions + 1;  
                }        
              } else if (checkForTransactionRow == "Missing Data"){
            
                //  KP : We skip 'Missing Data' Transactions as they will cause issues to DB
                //
                // let tempAgentName:any = "N/A" 
                //       if (row?.length > 2){
                //         tempAgentName = this.checkIfMemberExist(row[1]);
                //         if (tempAgentName == false){
                //           tempAgentName = "N/A"
                //         }
                //       } 
                // let checkTransactionRowForFailures:any = this.checkTransactionRowForFailures(row);
                // row.push(checkTransactionRowForFailures);
                // rejectedTransactions.push(row);
                // let newTempTransaction = this.setupRejectedTransactionRow(row,true,validHeaderFormat.supplierName,validHeaderFormat.supplierMisttaCode,tempAgentName,checkTransactionRowForFailures);
                // allTransactions.push(newTempTransaction);
                // if (this.isAFloatNumber(newTempTransaction.amount)){
                //   overallSumOfValue = Number(overallSumOfValue) + Number(newTempTransaction.amount);
                // }
                // numOfTransactions = numOfTransactions + 1;

              }  
            });

            // Then check for duplicates within the list
            let sizeOfList:any = allTransactions.length;
            if (sizeOfList > 0){
              for (let counterX = 0; counterX < sizeOfList; counterX++){
                let tranCounter:any = 0;
                let tempTransactionX:any = allTransactions[counterX];
                for (let counterY = 0; counterY < sizeOfList; counterY++){
                  let tempTransactionY:any = allTransactions[counterY];
                  if ((tempTransactionX.reference == tempTransactionY.reference) 
                      && (tempTransactionX.departure == tempTransactionY.departure)
                      && (tempTransactionX.amount == tempTransactionY.amount)
                      && (tempTransactionX.client == tempTransactionY.client)
                      && (tempTransactionX.memberTradeCode == tempTransactionY.memberTradeCode) 
                      && (tempTransactionX.state == tempTransactionY.state)){
                    tranCounter = Number(tranCounter) + 1;
                  }
                }
                if (tranCounter >= 2){
                  tranFailure = "Some Duplicated Transactions";
                  allTransactions[counterX].state = 'Rejected'
                  allTransactions[counterX].warning = 'Duplicated Transaction'                
                  rejectedTransactions.push(allTransactions[counterX]);
                }
              }
            }

            // Check if tranState will be 'Imported' or 'Failed' 
            let rejectedTransactionsCounter = 0;
            let acceptedTransactionsCounter = 0;
            allTransactions.forEach((tempTrans:any)=>{
              if (tempTrans.state != 'Accepted') {
                rejectedTransactionsCounter = rejectedTransactionsCounter + 1
              } else {
                acceptedTransactionsCounter = acceptedTransactionsCounter + 1
              }
            })
            if (rejectedTransactionsCounter > 0 && acceptedTransactionsCounter > rejectedTransactionsCounter){
              if (tranFailure == "Some Duplicated Transactions"){
                tranFailure = 'Some Rejected / Duplicated Transactions'
              } else {
                tranFailure = 'Some Rejected Transactions'
              }
            } 
            if (rejectedTransactionsCounter > acceptedTransactionsCounter){
              if (tranFailure == "Some Duplicated Transactions"){
                tranFailure = 'Some Rejected / Duplicated Transactions'
              } else {
                tranFailure = 'Some Rejected Transactions'
              }
            }
            if (acceptedTransactionsCounter == 0){
              tranState = 'Failed'
              tranFailure = 'Rejected Transactions'
            }

            // Setup the transaction report page
            allTransactions.forEach((row:any)=>{
              // ONE FINAL CHECK OF DEPARTURE DATE
              if (this.isABritishDateFormat(row.departure) == false){
                const convertedDeptDate = this.convertDateToBritishFormat(row.departure);
                if (convertedDeptDate.startsWith('Invalid date')) {
                  const tempInvalidDeptDate = String(row.departure).slice(0, 15);
                  if (row.warning === "None") {
                    // Attempt conversion or set to default invalid date
                    row.departure = "01/01/2000";
                    row.state = "Rejected";
                    row.warning = `Invalid Dept Date Format (${tempInvalidDeptDate})`;
                  } else {
                    row.departure = "01/01/2000";
                    row.state = "Rejected";
                    row.warning += ` and Dept Date (${row.departure})`;
                  }
                } else {
                  row.departure = convertedDeptDate; // Set the converted date if valid
                }
              }
              let tempRow:any = {
                tranAgent: row.memberName,
                tranTapsId: String(row.memberTradeCode).toUpperCase(),
                tranRef: row.reference,
                tranDepart: row.departure,
                tranClient: row.client, 
                tranWarning: row.warning,
                tranValue: parseFloat(row.amount).toFixed(2), 
                tranAdjust: this.checkIfAdjusted(row.approval), 
                tranCollectedValue: row.collected, 
                tranCode: row.code, 
                tranName: row.reason, 
                tranState: row.state
              };

               //Seperate Rejected and Accepted Transactions 
               if (tempRow.tranState == "Accepted" || tempRow.tranState == "Paid" || tempRow.tranState == "Collected"){ 
                 tempUploadTransactions.data.push(tempRow);  
                 // Check for approved and unapproved amount
                 if (this.isAFloatNumber(tempRow.tranValue)){
                  this.viewOverallAcceptedAmount = Number(this.viewOverallAcceptedAmount) + Number(tempRow.tranValue);
                   if (tempRow.tranAdjust == "Yes"){
                     this.viewAdjustments = Number(this.viewAdjustments) + Number(tempRow.tranValue);
                    } else {
                     this.viewToPay =  Number(this.viewToPay) + Number(tempRow.tranValue);
                    }   
                 }                           
               } else if (tempRow.tranState == "Rejected"){                  
                 tempRow.tranFailure = tempRow.tranWarning;
                 if (this.isAFloatNumber(tempRow.tranValue))
                 {                        
                   this.viewOverallRejectedAmount = Number(this.viewOverallRejectedAmount) + Number(tempRow.tranValue);                    
                 }            
                 tempUploadRejectedTransactions.data.push(tempRow);
               }        
             });

            // Setup overall stat values
            this.tapsUploadTransactionData.data = tempUploadTransactions.data;
            this.tapsUploadTransactionRejectedData.data = tempUploadRejectedTransactions.data;
            this.viewAdjustmentUploadTapsId = validHeaderFormat.supplierMisttaCode;
            this.viewAdjustmentUploadSupplierName = validHeaderFormat.supplierName;
            this.viewAllTransactions = [...allTransactions];
            this.viewRejectedTransactions = rejectedTransactions
            this.viewTransactionState = tranState;
            this.viewTranFailureState = tranFailure;
            this.viewUploadDate = new Date().toLocaleDateString();
            this.viewNumOfTransactions = numOfTransactions;
            this.viewAdjustments = parseFloat(this.viewAdjustments).toFixed(2);
            this.viewOverallRejectedAmount = parseFloat(this.viewOverallRejectedAmount).toFixed(2);
            this.viewOverallAcceptedAmount = parseFloat(this.viewOverallAcceptedAmount).toFixed(2);    
            this.viewValue = Number(this.viewOverallAcceptedAmount) + Number(this.viewOverallRejectedAmount);
            this.viewValue = parseFloat(this.viewValue).toFixed(2);
            this.setupToPayAmount(tranState); 
            this.generateAgentReviewPeriodAndPaymentDue(uploadPeriod,validHeaderFormat.tapsPaymentDays);         
            this.lateAdjustmentUploadedFile = true;
            this.pageLoaded = true;
            const preTranOverallValue = this.viewValue;
            const preNumOfTransactions = this.viewNumOfTransactions;

            const preTempCurrentDate = moment();
            const preCurrentDate:any = this.subtrackDays(7,preTempCurrentDate.toDate());
            const preTempAvailableFrom:any = this.getNextDayOfTheWeek('sat',true,preCurrentDate);
            const preTempUploadPeriod = this.subtrackDays(1,preTempAvailableFrom);
            const preUploadPeriod = this.convertDate(preTempUploadPeriod);        
            const preCollectionDate = this.convertDate(this.addDays(7,preTempUploadPeriod))
            const prePaymentDate = this.convertDate(this.addDays(this.userSupplierPaymentDays + 7,preTempUploadPeriod));

            const preTranState = "Importing"
            const preTranFailure = "Rejected by User";
            const preUploadSupplierMisttaCode = this.viewAdjustmentUploadTapsId;
            const preUploadSupplierName = this.viewAdjustmentUploadSupplierName;
            const preOriginalID = "Original";   
            const preTranCollected = 0;
            const preAgentName = "";
            const preAgentTradeCode = "";
            const preRequest: any = {             
              company: Session.mySession.getUser().company,
              tradeCode: Session.mySession.getUser().tradeCode,
              operation: Session.mySession.getUser().operation,
              agentName: preAgentName,
              agentTradeCode: preAgentTradeCode,
              supplierName: preUploadSupplierName,
              supplierTradeCode: preUploadSupplierMisttaCode,
              misttaCode: preUploadSupplierMisttaCode,
              uploadPeriod: preUploadPeriod,
              collectionDate: preCollectionDate,
              paymentDate: prePaymentDate,
              tranValue: preTranOverallValue,
              tranCollected: preTranCollected,
              tranFailure: preTranFailure,
              tranState: preTranState,
              numOfTransactions: preNumOfTransactions,
              listOfTransactions: [],
              transactionOriginalID: preOriginalID,
              token: Session.mySession.get('user').token
            }
            this.pageLoaded = false;
            this.tapsService.preCreateTapsUpload(preRequest).then((output:any)=>{
              this.pageLoaded = true;
              if (output.status == "OK"){
                // Setup response message
                if (tranFailure == "None" && rejectedTransactions.length == 0){
                  this.sendMessageToDialog("Successfully loaded with the following transaction State : " + tranState,"","");
                } else if (tranFailure == "Some Duplicated Transactions") {
                  let outputMessage = "Successfully loaded with the following transaction State : " + tranState;
                  let rejectedTransactionsList = "There are duplicated transactions in the uploaded file.";
                  let finalMessage:any = outputMessage + '<br><br>' + rejectedTransactionsList;
                  this.viewTransactionFailure = rejectedTransactionsList;
                  this.sendMessageToDialog(finalMessage,"","");
                } else {
                  let outputMessage = "Successfully loaded with the following transaction State : " + tranState;
                  let rejectedTransactionsList = "Warning : " + tranFailure + "<br>Not all records were successfully accepted.<br>The following were rejected:";
                  rejectedTransactions.forEach((transaction:any)=>{
                    rejectedTransactionsList = rejectedTransactionsList + '<br><br>Transaction Failed<br>' + this.returnListedTransactionValues(transaction);
                  });
                  let finalMessage:any = outputMessage + '<br><br>' + rejectedTransactionsList;
                  this.viewTransactionFailure = rejectedTransactionsList;
                  this.sendMessageToDialog(finalMessage,"","");
                }
                this.preImportedTransactionID = output["res"].transactionID;
              } else {
                this.sendMessageToDialog("",output["res"],"");
                this.clearViewUploadFile()
                this.switchView("homePage");
              }
            });
          } else {
            this.pageLoaded = true;
            this.sendMessageToDialog('SinGS could not complete your request at this time (Invalid Supplier Format)<br><br>Reason: ' + this.uploadFailedReason + '', '', "");
          }
        } else {
          this.pageLoaded = true;
          this.sendMessageToDialog('SinGS could not complete your request at this time (Invalid Header/Footer or File Format)<br><br>Reason: ' + this.uploadFailedReason + '', '', "");
        }  
    });
  }
 
  // Uploads the Late Adjustment Upload and returns to main page
  acceptLateUpload(){
    if (confirm("Are you sure you want to accept this file?")) {
      if (this.lateAdjustmentFile != ""){
        this.adminCreateTapsUpload();
        this.clearViewUploadFile();
        this.lateAdjustmentUploadedFile = false;
        this.switchView("homePage");
      }
    }
  }

  // Rejects the uploaded file from Late Adjustment Page and returns to main page
  rejectLateUpload(){
    if (confirm("Are you sure you want to reject this file?")) {
      this.rejectTapsUpload(this.viewAdjustmentUploadSupplierName, this.viewAdjustmentUploadTapsId);
      this.lateAdjustmentFile == "";
      this.clearViewUploadFile();
      this.lateAdjustmentUploadedFile = false;
      this.switchView("homePage");
    }
  }

  // Send email rejection to Supplier
  rejectTapsUpload(supplierName: String, supplierTradeCode: String){
    const request: any = {             
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      supplierName: supplierName,
      supplierTradeCode: supplierTradeCode, 
      token: Session.mySession.get('user').token
    }
    if (this.preImportedTransactionID != ""){
      request.transactionId = this.preImportedTransactionID;
    }
    this.pageLoaded = false;
    this.tapsService.rejectTapsUpload(request).then((output:any)=>{
    this.pageLoaded = true;
    if (output.status != "OK"){
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time ('+ output.status +')', "");
    } 
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
    })
  }

  // Calls :: createTapsUpload (Admin)
  // Generates a record of 'Taps_Upload' 
  // Then we match all of it's transactions with Bookings
  // Record all transactions to 'Taps_Supplier_Transactions'
  // Email the supplier that their upload process was 'Imported' or 'Failed'
  adminCreateTapsUpload(){
    const tranOverallValue = this.viewValue;
    const numOfTransactions = this.viewNumOfTransactions;
    const tempCurrentDate:any = new Date();
    let currentDate:any = new Date();
    if (this.uploadAvailability == true){
      currentDate = this.subtrackDays(7,tempCurrentDate);
    }
    const tempAvailableFrom:any = this.getNextDayOfTheWeek('sat',true,currentDate);
    const tempUploadPeriod = this.subtrackDays(1,tempAvailableFrom);
    const uploadPeriod = this.convertDate(tempUploadPeriod);        
    const collectionDate = this.convertDate(this.addDays(7,tempUploadPeriod))
    const paymentDate = this.convertDate(this.addDays(this.userSupplierPaymentDays + 7,tempUploadPeriod));
    const tranState = this.viewTransactionState;
    const tranFailure = this.viewTranFailureState;
    const uploadSupplierMisttaCode = this.viewAdjustmentUploadTapsId;
    const uploadSupplierName = this.viewAdjustmentUploadSupplierName;
    const originalID = "Original";    
    const listOfTransactions = this.viewAllTransactions.map(({ approval, code, collected, collection, paid, reason, payment, supplierName, supplierTradeCode, ...rest }: any) => rest);
    const tranCollected = 0;
    const agentName = "";
    const agentTradeCode = "";
    let request: any = {             
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      agentName: agentName,
      agentTradeCode: agentTradeCode,
      supplierName: uploadSupplierName,
      supplierTradeCode: uploadSupplierMisttaCode,
      misttaCode: uploadSupplierMisttaCode,
      uploadPeriod: uploadPeriod,
      collectionDate: collectionDate,
      paymentDate: paymentDate,
      tranValue: tranOverallValue,
      tranCollected: tranCollected,
      tranFailure: tranFailure,
      tranState: tranState,
      numOfTransactions: numOfTransactions,
      listOfTransactions: listOfTransactions,
      transactionOriginalID: originalID,
      token: Session.mySession.get('user').token,
      hideDialog: false
    }
    if (this.preImportedTransactionID != ""){
      request.preImportedTransactionID = this.preImportedTransactionID;
    }
    this.pageLoaded = false;
    this.tapsService.createTapsUpload(request).then((output: any) => {
      if (output.status == "OK"){
        this.uploadFileBlocked = true;
        Session.mySession.resetTimersOnBookingValues().then((res:any)=>{
          AsynchRequests.myAsynchReq.addJobToWatch(request, output.jobID)
          this.checkIfJobIsDone().then((res:any)=>{
            this.uploadFileBlocked = false; 
            if (this.uploadFileFtpRequest != null){
              this.deleteFtpFile(this.uploadFileFtpRequest);
            }
          })
        })
      } else {
        this.sendMessageToDialog('', 'Uploading document has failed (' + output.status + ')', output);
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
    })
  }

  // Check if supplier exists on TAPS
  checkIfSupplierExist(supplierMisttaCode:any){
    let supplierFound:boolean = false;
    this.suppliersListData.data.forEach((supplier:any)=>{
      if (String(supplier.misttaCode) == String(supplierMisttaCode) && (String(supplier.isTapsReg) == "yes"))
      {
        supplierFound = true;
      }
    });
    return supplierFound;
  }

  // Check if member exists on TAPS
  checkIfMemberExist(memberTradeCode:any){
    let memberFound:any = false;
    if (this.loggedInUserType == "Supplier"){
      this.tapsAgentPageListData.data.forEach((agent:any)=>{
        if (String(agent.tradeCode).toUpperCase() == String(memberTradeCode).toUpperCase() && agent.isTapsReg == "yes"){
          memberFound = agent.branchName;
        }
      });
    } else if (this.loggedInUserType == "Admin") {
      this.adminAgentListData.data.forEach((agent:any)=>{
        if (String(agent.tradeCode).toUpperCase() == String(memberTradeCode).toUpperCase() && agent.isTapsReg == "yes"){
          memberFound = agent.branchName; 
        }
      });
    }
    return memberFound;
  }

  // Checks if the current day is less than the next From and To Upload Deadlines.
  // In case we want to do previous week (testings)
  checkUploadAvailabilityForAdmin(){
    this.uploadAvailability = this.checkLateAdjustmentUploadAvailability();
    let currentDate:any = new Date();
    if (this.uploadAvailability == true){
      let tempAvailableFrom:any = this.getNextDayOfTheWeek('sat',true,currentDate);
      this.uploadFromDateAdmin = this.convertDateToBritishFormat(this.subtrackDays(7,tempAvailableFrom).toLocaleDateString());
      let tempAvailableTo:any = this.getNextDayOfTheWeek('wed',true,tempAvailableFrom);
      this.uploadToDateAdmin = this.convertDateToBritishFormat(this.subtrackDays(7,tempAvailableTo).toLocaleDateString());
    } else {
      let tempAvailableFrom:any = this.getNextDayOfTheWeek('sat',true,currentDate);
      this.uploadFromDateAdmin = this.convertDateToBritishFormat(tempAvailableFrom.toLocaleDateString());
      let tempAvailableTo:any = this.getNextDayOfTheWeek('wed',true,tempAvailableFrom);
      this.uploadToDateAdmin = this.convertDateToBritishFormat(tempAvailableTo.toLocaleDateString());
    }
  }

  // Calls :: getTapsAgentsForReminder
  // Setup Agent's Reminder Page
  setupAgentsReminderList(){
    this.pageLoaded = false;
    this.tapsAgentRemindersListData.data = [];
    let request:any = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      uploadPeriod: this.getUploadPeriod(true), 
      token: Session.mySession.get('user').token
    }
    this.tapsService.getTapsAgentsForReminder(request).then((output:any)=>{
      if (output['status'] == "OK"){
        if (output['res'] == "No reminders found."){
          console.log("No reminders found.");
        } else {
          let tempTable:any = new MatTableDataSource<any>();
          for (let key in output['res']) {
            if (this.isAFloatNumber(output["res"][key]["overallValue"])){
              output["res"][key]["overallValue"] = Number(output["res"][key]["overallValue"]).toFixed(2);
            }
            tempTable.data.push(output["res"][key]);
          }
          this.tapsAgentRemindersListData.data = tempTable.data; 
        }
      } else {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
      }
    }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
    }).then(()=>{
      this.pageLoaded = true;
    });
  }

  // Calls :: sendTapsAgentsReminder
  // Send Reminders for all the Agents Review period Amount
  sendAllAgentReminders(){
  this.pageLoaded = false;
  this.tapsAgentRemindersListData.data = [];
  let request:any = {
    company: Session.mySession.getUser().company,
    tradeCode: Session.mySession.getUser().tradeCode,
    operation: Session.mySession.getUser().operation,
    uploadPeriod: this.getUploadPeriod(true),
    token: Session.mySession.get('user').token
  }
  this.tapsService.sendAllTapsAgentsReminder(request).then((output:any)=>{
    if (output.status == "OK"){
      Session.mySession.resetTimersOnBookingValues().then((res:any)=>{
        this.sendMessageToDialog('Sending reminders to agents in progress. Please wait..','','');
        AsynchRequests.myAsynchReq.addJobToWatch(request, output.jobID)
      })
    } else {
      this.sendMessageToDialog('', 'Send all agent reminders failed (' + output.status + ')', output);
    }
  }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
  }).then(()=>{
    this.pageLoaded = true;
  });
  }

  // Calls :: sendTapsAgentReminder
  // Send Reminders for all the Agents Review period Amount
  sendAgentReminder(givenAgent:any){
    this.pageLoaded = false;
    this.tapsAgentRemindersListData.data = this.tapsAgentRemindersListData.data.filter((agent: any) => agent.tradeCode !== givenAgent.tradeCode);
    let request:any = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      uploadPeriod: this.getUploadPeriod(true),
      agentTradeCode: givenAgent.tradeCode,
      agentName: givenAgent.branchName,
      amount: givenAgent.overallValue,
      agentEmail: givenAgent.email,
      token: Session.mySession.get('user').token
    }
    this.tapsService.sendTapsAgentReminder(request).then((output:any)=>{
      if (output.status == "OK"){
        let messageResponse = `Reminder was successfully sent to ${givenAgent.branchName} (${givenAgent.tradeCode})`;
        this.sendMessageToDialog(messageResponse, '', '');
      } else {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
      }
    }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
    }).then(()=>{
      this.pageLoaded = true;
    });
  }

  // Returns uploadPeriod value (e.g 2022-09-16) 
  // If parameter is true, it will return the week prior to the current one
  getUploadPeriod(weekBefore:boolean) {
    let currentDate:any = moment().toDate();
    let tempAvailableFrom:any = this.getNextDayOfTheWeek('sat',true,currentDate);
    let tempUploadPeriod = this.subtrackDays(1,tempAvailableFrom);
    if (weekBefore == true){
      let uploadPeriod = this.convertDate(this.subtrackDays(7,tempUploadPeriod))
      return uploadPeriod;
    } else {
      let uploadPeriod = this.convertDate(tempUploadPeriod);
      return uploadPeriod;
    }    
  }

  // Restores all necessary data to initial form before executing the 'setupCollectionFromAgents()'
  clearCollectionFromAgentsData(){
    this.tapsCollectionAgentAcceptedListData.data = [];
    this.tapsCollectionAgentExcelAcceptedData.data = [];
    this.tapsCollectionAgentAcceptedOverall = 0;
    this.tapsCollectionAgentRejectedListData.data = [];
    this.tapsCollectionAgentRejectedOverall = 0;
  }

  // Calls :: listCollectionFromAgents 
  // Requires {company, operation, tradeCode, startDate, endDate}
  setupCollectionFromAgents(){
    this.clearCollectionFromAgentsData();
    if ((this.collectionDueDateFrom != null && this.collectionDueDateFrom != "") && (this.collectionDueDateTo != null && this.collectionDueDateTo != "")){
      if (this.collectionDueDateFrom > this.collectionDueDateTo) {
        this.sendMessageToDialog("","Please ensure that the 'From' date is earlier than 'To' date","");
      } else {
        this.collectionViewDueDate = new Date(this.collectionDueDateTo).toLocaleDateString();
        this.pageLoaded = false;
        let tempListOfAcceptedData:any = new MatTableDataSource<any>();
        let tempListOfAcceptedExcel:any = new MatTableDataSource<any>();
        let tempListOfRejectedData:any = new MatTableDataSource<any>();  
        let request:any = {
          company: Session.mySession.getUser().company,
          tradeCode: Session.mySession.getUser().tradeCode,
          operation: Session.mySession.getUser().operation,
          startDate: this.convertDate(this.collectionDueDateFrom),
          endDate: this.convertDate(this.collectionDueDateTo),
          token: Session.mySession.get('user').token
        }
        this.tapsService.listCollectionFromAgents(request).then((output:any)=>{
          if (output.status == "OK"){
            // Accepted Transactions
            Object.keys(output['res'].listOfAcceptedTransactions).map((agent: string) => {
              this.tapsCollectionAgentAcceptedOverall = Number(this.tapsCollectionAgentAcceptedOverall) + Number(output['res'].listOfAcceptedTransactions[agent].overallValue);
              let agentRecord:any = {}
              let accountNumber = "N/A"; 
              let sortCode = "N/A";
              let accountName = "N/A";
              if (output['res'].listOfAcceptedTransactions[agent].bankDetails['status'] == "OK"){
                if (output['res'].listOfAcceptedTransactions[agent].bankDetails['res'][0].accountNumber != null && 
                   output['res'].listOfAcceptedTransactions[agent].bankDetails['res'][0].sortCode != null){
                    accountNumber = output['res'].listOfAcceptedTransactions[agent].bankDetails['res'][0].accountNumber;
                    sortCode = output['res'].listOfAcceptedTransactions[agent].bankDetails['res'][0].sortCode;
                    accountName = output['res'].listOfAcceptedTransactions[agent].bankDetails['res'][0].accountName;
                }
              }  
              agentRecord.collectionDate = output['res'].listOfAcceptedTransactions[agent].collectionDate
              agentRecord.agentTradeCode = agent
              agentRecord.accountName = accountName;
              agentRecord.sortCode = sortCode;
              agentRecord.accountNumber = accountNumber;
              agentRecord.tranValue = Number(output['res'].listOfAcceptedTransactions[agent].overallCollected).toFixed(2);
              if (Number(output['res'].listOfAcceptedTransactions[agent].overallCollected) < 0){
                agentRecord.type = "Credit"
              } else {
                agentRecord.type = "Debit"
              }              
              agentRecord.collection = "Successful";                                                    
              output['res'].listOfAcceptedTransactions[agent].transactionsList.forEach((transaction:any)=>{       
                if (agentRecord.accountName == "N/A"){
                  agentRecord.accountName = transaction.agentName;
                }
                if (transaction.collection != 'Successful'){
                  agentRecord.collection = "NotCollected";
                }              
                transaction.sortCode = sortCode
                transaction.accountNumber = accountNumber
                tempListOfAcceptedExcel.data.push(transaction)
              });            
              tempListOfAcceptedData.data.push(agentRecord);
            });
            this.tapsCollectionAgentAcceptedOverall = parseFloat(this.tapsCollectionAgentAcceptedOverall).toFixed(2);
            this.tapsCollectionAgentAcceptedListData.data = tempListOfAcceptedData.data;
            this.tapsCollectionAgentExcelAcceptedData.data = tempListOfAcceptedExcel.data;
            // Rejected Transactions
            Object.keys(output['res'].listOfRejectedTransactions).map((agent: string) => {
              this.tapsCollectionAgentRejectedOverall = Number(this.tapsCollectionAgentRejectedOverall) + 
                                                        Number(output['res'].listOfRejectedTransactions[agent].overallValue);

              output['res'].listOfRejectedTransactions[agent].transactionsList.forEach((transaction:any)=>{
                transaction.value = Number(transaction.value).toFixed(2);
                tempListOfRejectedData.data.push(transaction);
              })
            });
            this.tapsCollectionAgentRejectedOverall = parseFloat(this.tapsCollectionAgentRejectedOverall).toFixed(2);
            this.tapsCollectionAgentRejectedListData.data = tempListOfRejectedData.data;
          } else {
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
          }
        }).catch((error: any) => {
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
        }).then(()=>{
          this.pageLoaded = true;
        });
      }
    } else {
      this.sendMessageToDialog('', 'Please fill in all the required data','');
    }
  }

  // Two scenarios of authentication
  // TapsAdmin - Collection From Agents Export
  // TapsAdmin - Delivery to Suppliers
  authenticateExport(form: NgForm,type: any) {
    if (confirm("Are you sure you want to generate the 'BACS File'?")){
      let request = { email: form.value.email, password: form.value.password };
      this.userService.login(request).then(async (output: any) => {
        if (output.status === 'OK') {
          this.session.set('user', { id: output.data.id, token: output.data.token });
          if (type == "Collection") {
            this.commitBACSCollection();
          } 
          if (type == "Delivery"){
            this.commitBACSDelivery();
          }
        } else {
          this.sendMessageToDialog('', output.status, ''); // Display error status from the back
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2009S) - please try again', error);
      })
    }
  }

  // Calls :: submitCollectionFromAgents
  // It's used in the 'Generate BACS File' button at 'Collection From Agents' Page
  // Then generates the BACS report for the listed transactions
  commitBACSCollection(){
    if(this.tapsCollectionAgentAcceptedListData.data.length == 0){
      this.sendMessageToDialog("","There are no listed 'Accepted Transactions'","")
    } else {
      let approvedCheck = true;
      for (let counter = 0; counter < this.tapsCollectionAgentAcceptedListData.data.length; counter++){
        if (this.tapsCollectionAgentAcceptedListData.data[counter].collection != 'Successful'){
          approvedCheck = false;
          break;
        }
      }
      if (approvedCheck == false){
        this.pageLoaded = false;
        let request:any = {
          company: Session.mySession.getUser().company,
          tradeCode: Session.mySession.getUser().tradeCode,
          operation: Session.mySession.getUser().operation,
          startDate: this.convertDate(this.collectionDueDateFrom),
          endDate: this.convertDate(this.collectionDueDateTo),
          token: Session.mySession.get('user').token
        }
        this.tapsService.submitCollectionFromAgents(request).then((output: any) => {
            this.pageLoaded = true;
            this.clearCollectionFromAgentsData();
            if (output.status == "OK"){
              Session.mySession.resetTimersOnBookingValues().then((res:any)=>{
                this.sendMessageToDialog('Collecting transactions in progress. Please wait..','','');
                AsynchRequests.myAsynchReq.addJobToWatch(request, output.jobID)
              })
            } else {
              this.sendMessageToDialog('', 'Submit Collection from Agents failed (' + output.status + ')', output);
            }
        }).catch((error: any) => {
          this.pageLoaded = true;
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
        });
      } else {
        this.sendMessageToDialog("All of the 'Accepted Transactions' have been approved","","")
      }     
    }
  }

  // Restores all necessary data to initial form before executing the 'setupDeliveryToSuppliers()'
  clearDeliveryToSuppliersData(){
    this.tapsDeliveryToSupplierAcceptedListData.data = [];
    this.tapsDeliveryToSupplierExcelAcceptedData.data = [];
    this.tapsDeliveryToSupplierAcceptedOverall = 0;
    this.tapsDeliveryToSupplierRejectedListData.data = [];
    this.tapsDeliveryToSupplierRejectedOverall = 0;
  }

  // Calls :: listDeliveryToSuppliers
  // Requires {company, operation, tradeCode, startDate, endDate}
  setupDeliveryToSuppliers(){
    this.clearDeliveryToSuppliersData();
    if ((this.deliveryDueDateFrom != null && this.deliveryDueDateFrom != "") && (this.deliveryDueDateTo != null && this.deliveryDueDateTo != "")){
      if (this.deliveryDueDateFrom > this.deliveryDueDateTo) {
        this.sendMessageToDialog("","Please ensure that the 'From' date is earlier than 'To' date","");
      } else {
        this.pageLoaded = false;
        let tempListOfAcceptedData:any = new MatTableDataSource<any>();
        let tempListOfAcceptedExcel:any = new MatTableDataSource<any>();
        let tempListOfRejectedData:any = new MatTableDataSource<any>();  
        let request:any = {
          company: Session.mySession.getUser().company,
          tradeCode: Session.mySession.getUser().tradeCode,
          operation: Session.mySession.getUser().operation,
          startDate: this.convertDate(this.deliveryDueDateFrom),
          endDate: this.convertDate(this.deliveryDueDateTo),
          token: Session.mySession.get('user').token
        }
        this.tapsService.listDeliveryToSuppliers(request).then((output:any)=>{
          if (output.status == "OK"){
             // // Accepted Transactions
            Object.keys(output['res'].listOfAcceptedTransactions).map((supplier: string) => {
              this.tapsDeliveryToSupplierAcceptedOverall = Number(this.tapsDeliveryToSupplierAcceptedOverall) + Number(output['res'].listOfAcceptedTransactions[supplier].overallValue);
              let supplierRecord:any = {}
              let accountNumber = "N/A"; 
              let sortCode = "N/A";
              let accountName = "N/A";
              if (output['res'].listOfAcceptedTransactions[supplier].bankDetails['status'] == "OK"){
                if (output['res'].listOfAcceptedTransactions[supplier].bankDetails['res'][0].accountNumber != null && 
                 output['res'].listOfAcceptedTransactions[supplier].bankDetails['res'][0].sortCode != null){
                  accountNumber = output['res'].listOfAcceptedTransactions[supplier].bankDetails['res'][0].accountNumber;
                  sortCode = output['res'].listOfAcceptedTransactions[supplier].bankDetails['res'][0].sortCode;
                  accountName = output['res'].listOfAcceptedTransactions[supplier].bankDetails['res'][0].accountName;
                }
              }
              supplierRecord.supplierTradeCode = supplier
              supplierRecord.accountName = accountName;
              supplierRecord.paymentDate = ""
              supplierRecord.sortCode = sortCode
              supplierRecord.accountNumber = accountNumber
              supplierRecord.value = Number(output['res'].listOfAcceptedTransactions[supplier].overallCollected).toFixed(2);
              if (Number(output['res'].listOfAcceptedTransactions[supplier].overallCollected) < 0){
                supplierRecord.type = "Debit"
              } else {
                supplierRecord.type = "Credit"
              } 
              supplierRecord.paid = "Successful";   
              output['res'].listOfAcceptedTransactions[supplier].transactionsList.forEach((transaction:any)=>{   
                if (supplierRecord.paymentDate == ""){
                  supplierRecord.paymentDate = transaction.formattedPaymentDate;
                }    
                if (supplierRecord.accountName == "N/A"){
                  supplierRecord.accountName = transaction.supplierName;
                }
                if (transaction.paid != 'Successful'){
                  supplierRecord.paid = "NotPaid";
                }
                transaction.sortCode = sortCode;
                transaction.accountNumber = accountNumber;
                tempListOfAcceptedExcel.data.push(transaction)
              });            
              tempListOfAcceptedData.data.push(supplierRecord);
            });
            this.tapsDeliveryToSupplierAcceptedOverall = parseFloat(this.tapsDeliveryToSupplierAcceptedOverall).toFixed(2);
            this.tapsDeliveryToSupplierAcceptedListData.data = tempListOfAcceptedData.data;
            this.tapsDeliveryToSupplierExcelAcceptedData.data = tempListOfAcceptedExcel.data;
            // Rejected Transactions
            Object.keys(output['res'].listOfRejectedTransactions).map((supplier: string) => {
              this.tapsDeliveryToSupplierRejectedOverall = Number(this.tapsDeliveryToSupplierRejectedOverall) + 
                                                        Number(output['res'].listOfRejectedTransactions[supplier].overallValue);
              output['res'].listOfRejectedTransactions[supplier].transactionsList.forEach((transaction:any)=>{
                transaction.value = Number(transaction.value).toFixed(2);
                tempListOfRejectedData.data.push(transaction);
              })
            });
            this.tapsDeliveryToSupplierRejectedOverall = parseFloat(this.tapsDeliveryToSupplierRejectedOverall).toFixed(2);
            this.tapsDeliveryToSupplierRejectedListData.data = tempListOfRejectedData.data;
          } else {
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
          }         
        }).catch((error: any) => {
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
        }).then(()=>{
          this.pageLoaded = true;
        });
      }
    } else {
      this.sendMessageToDialog('', 'Please fill in all the required data','');
    }
  }

  // Calls :: submitDeliveryToSuppliers
  // It's used in the 'Generate BACS File' button at 'Delivery To Suppliers' Page
  // Then generates the BACS report for the listed transactions 
  commitBACSDelivery(){
    if(this.tapsDeliveryToSupplierAcceptedListData.data.length == 0){
      this.sendMessageToDialog("","There are no listed 'Accepted Transactions'","")
    } else {
      let approvedCheck = true;
      for (let counter = 0; counter < this.tapsDeliveryToSupplierAcceptedListData.data.length; counter++){
        if (this.tapsDeliveryToSupplierAcceptedListData.data[counter].paid != 'Successful'){
          approvedCheck = false;
          break;
        }
      }
      if (approvedCheck == false){
        this.pageLoaded = false;
        let request:any = {
          company: Session.mySession.getUser().company,
          tradeCode: Session.mySession.getUser().tradeCode,
          operation: Session.mySession.getUser().operation,
          startDate: this.convertDate(this.deliveryDueDateFrom),
          endDate: this.convertDate(this.deliveryDueDateTo),
          token: Session.mySession.get('user').token
        }
        this.tapsService.submitDeliveryToSuppliers(request).then((output: any) => {
            this.pageLoaded = true;
            this.clearDeliveryToSuppliersData();
            if (output.status == "OK"){
              Session.mySession.resetTimersOnBookingValues().then((res:any)=>{
                this.sendMessageToDialog('Delivering transactions in progress. Please wait..','','');
                AsynchRequests.myAsynchReq.addJobToWatch(request, output.jobID)
              })
            } else {
              this.sendMessageToDialog('', 'Submit Delivery to Suppliers failed (' + output.status + ')', output);
            }
        }).catch((error: any) => {
          this.pageLoaded = true;
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
        }); 
      } else {
        this.sendMessageToDialog("All of the 'Accepted Transactions' have been approved","","")
      }  
    }
  }

  // Sort by Name (Admin - Agent List)
  sortAdminAgentByName(){
    if (this.supplierAgentListSortedbyName == false){
      this.supplierAgentListSortedbyName = true;
      let sorted = this.adminAgentListData.data.sort((a: any, b: any) => (a.branchName < b.branchName) ? 1 : -1); 
      sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['branchName'] === e['branchName']) === i); 
      this.adminAgentListData.data = sorted; 
    } else {
      this.supplierAgentListSortedbyName = false;
      let sorted = this.adminAgentListData.data.sort((a: any, b: any) => (a.branchName > b.branchName) ? 1 : -1); 
      sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['branchName'] === e['branchName']) === i); 
      this.adminAgentListData.data = sorted; 
    }
  }

  // Sort by TradeCode (Admin - Agent List)
  sortAdminAgentByTradeCode(){
    if (this.supplierAgentListSortedbyTradeCode == false){
      this.supplierAgentListSortedbyTradeCode = true;
      let sorted = this.adminAgentListData.data.sort((a: any, b: any) => (a.tradeCode < b.tradeCode) ? 1 : -1); 
      sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['tradeCode'] === e['tradeCode']) === i); 
      this.adminAgentListData.data = sorted; 
    } else {
      this.supplierAgentListSortedbyTradeCode = false;
      let sorted = this.adminAgentListData.data.sort((a: any, b: any) => (a.tradeCode > b.tradeCode) ? 1 : -1); 
      sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['tradeCode'] === e['tradeCode']) === i); 
      this.adminAgentListData.data = sorted; 
    }
  }

  // Sort by Name (Admin - Supplier Status)
  sortSupplierStatusReportByName(){
    if (this.supplierStatusListSortedbyName == false){
      this.supplierStatusListSortedbyName = true;
      let sorted = this.adminSupplierStatusListData.data.sort((a: any, b: any) => (a.supplierName < b.supplierName) ? 1 : -1); 
      sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['supplierName'] === e['supplierName']) === i); 
      this.adminSupplierStatusListData.data = sorted; 
    } else {
      this.supplierStatusListSortedbyName = false;
      let sorted = this.adminSupplierStatusListData.data.sort((a: any, b: any) => (a.supplierName > b.supplierName) ? 1 : -1); 
      sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['supplierName'] === e['supplierName']) === i); 
      this.adminSupplierStatusListData.data = sorted; 
    }
  }

  // Sort by TradeCode (Admin - Supplier Status)
  sortSupplierStatusReportByTradeCode(){
    if (this.supplierStatusListSortedbyTradeCode == false){
      this.supplierStatusListSortedbyTradeCode = true;
      let sorted = this.adminSupplierStatusListData.data.sort((a: any, b: any) => (a.supplierTradeCode < b.supplierTradeCode) ? 1 : -1); 
      sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['supplierTradeCode'] === e['supplierTradeCode']) === i); 
      this.adminSupplierStatusListData.data = sorted; 
    } else {
      this.supplierStatusListSortedbyTradeCode = false;
      let sorted = this.adminSupplierStatusListData.data.sort((a: any, b: any) => (a.supplierTradeCode > b.supplierTradeCode) ? 1 : -1); 
      sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['supplierTradeCode'] === e['supplierTradeCode']) === i); 
      this.adminSupplierStatusListData.data = sorted; 
    }
  }

  // Sort by Name (Admin - Transactions Invoicing)
  sortTransactionsInvoicingByName(){
    if (this.transactionInvoicingListSortedbyName == false){
      this.transactionInvoicingListSortedbyName = true;
      let sorted = this.tranInvoiceListData.data.sort((a: any, b: any) => (a.name < b.name) ? 1 : -1); 
      sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['name'] === e['name']) === i); 
      this.tranInvoiceListData.data = sorted; 
    } else {
      this.transactionInvoicingListSortedbyName = false;
      let sorted = this.tranInvoiceListData.data.sort((a: any, b: any) => (a.name > b.name) ? 1 : -1); 
      sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['name'] === e['name']) === i); 
      this.tranInvoiceListData.data = sorted; 
    }
  }

  // Sort by TradeCode (Admin - Transactions Invoicing)
  sortTransactionsInvoicingByTradeCode(){
    if (this.transactionInvoicingListSortedbyTradeCode == false){
      this.transactionInvoicingListSortedbyTradeCode = true;
      let sorted = this.tranInvoiceListData.data.sort((a: any, b: any) => (a.tapsIdent < b.tapsIdent) ? 1 : -1); 
      sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['tapsIdent'] === e['tapsIdent']) === i); 
      this.tranInvoiceListData.data = sorted; 
    } else {
      this.transactionInvoicingListSortedbyTradeCode = false;
      let sorted = this.tranInvoiceListData.data.sort((a: any, b: any) => (a.tapsIdent > b.tapsIdent) ? 1 : -1); 
      sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['tapsIdent'] === e['tapsIdent']) === i); 
      this.tranInvoiceListData.data = sorted; 
    }
  }

  // Calls :: getUnmatchedTapsPayments 
  getUnMatchedTapsPayments(){
    let request:any = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      token: Session.mySession.get('user').token
    }
    if (this.unmatchedCollectionDate != '' && this.unmatchedCollectionDate != null){
      request.collectionDate = this.convertDate(this.unmatchedCollectionDate)
    }
    if (this.unmatchedAgent != '' && this.unmatchedAgent != 'All'){
      request.agentTradeCode = this.unmatchedAgent;
    }
    this.clearUnMatchedTapsPayments();
    this.pageLoaded = false;
    this.tapsService.getUnMatchedTapsPayments(request).then((output:any)=>{
      this.pageLoaded = true;
      if (output.status == "OK"){
        this.unmatchedTapsPaymentsListData.data = [...output["res"]]
      } else {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (' + output.res +')', output);
      }
    }).catch((error: any) => {
      this.pageLoaded = true;
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
    }); 
  }

  // Exports to Excel the results of the unmatched payments report
  adminExportUnMatchedReport(){
      if (this.unmatchedTapsPaymentsListData.data.length == 0) {
        this.sendMessageToDialog("","No records found to export","");
      } else { 
        if (this.unmatchedTapsPaymentsListData.data.length > 0){
          let reportName = this.getReportName();
          const exportMe: any = [];
          let oneLine: any = {};
          this.unmatchedTapsPaymentsListData.data.forEach((item: any) => {
            oneLine = {};
            oneLine["Transaction ID"] = item.transactionID;
            oneLine["Collection Date"] = item.formattedCollectionDate;
            oneLine["Supplier Name"] = item.supplierName;
            oneLine["Supplier TradeCode"] = item.supplierTradeCode;
            oneLine["Agent Name"] = item.agentName;
            oneLine["Agent TradeCode"] = item.agentTradeCode;
            oneLine["Operator Ref"] = item.operatorReference;
            oneLine["Client Name"] = item.clientName;
            oneLine["Dept Date"] = item.formattedDepartureDate;
            oneLine["Amount"] = this.getValidNumberToExcel(item.tranValue);
            oneLine["Booking Ref Other"] = item.bookingRefOther;
            oneLine["Booking Reference"] = item.bookingReference;
            oneLine["Match Type"] = item.matchType;
            exportMe.push(oneLine);
          });
          this.exportTapsReportAsExcelFile(exportMe, reportName, "J", "", false);
        } else {
          this.sendMessageToDialog("","There are no transactions listed to export","");
        }
      }
  }

  // Reset all data for 'UnMatched TAPS Payments' page
  clearUnMatchedTapsPayments(){
    this.unmatchedCollectionDate != ""
    this.unmatchedTapsPaymentsListData.data = [];
    this.filterAdminAgentsListString = ''; 
    this.filterAdminAgentListSelect();
  }

  // Exports to Excel the list of 'Agents'
  adminExportAgentManagement(){
    if (this.adminAgentListData.data.length == 0) {
      this.sendMessageToDialog("","No records found to export","");
    } else { 
        let reportName = Session.mySession.getUser().tradeCode + "_Sings_TAPS_Agents"
        const exportMe: any = [];
        let oneLine: any = {};
        this.adminAgentListData.data.forEach((item: any) => {
          oneLine = {};
          oneLine["Name"] = item.branchName;
          oneLine["TAPS Identi."] = item.tradeCode;
          oneLine["TAPS"] = item.isTapsReg.toUpperCase();
          oneLine["Address"] = String(item.address1) + "," + String(item.address2) + "," + String(item.postcode) + "," + String(item.country)
          oneLine["Contact"] = item.mainContactTAPS;
          oneLine["Telephone"] = item.telephoneTAPS;
          oneLine["Email"] = item.emailTAPS;
          oneLine["Threshold"] = item.tapsThreshold;
          oneLine["Head Office"] = item.tapsMaster;
          exportMe.push(oneLine);
        });
        this.exportTapsReportAsExcelFile(exportMe, reportName, "", "", false);
    }
  }

  // Calls :: getAllTapsMembersData
  downloadAllTapsMembersData(){
    let request:any = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      format: "XLSX",
      token: Session.mySession.get('user').token,
    }
    this.pageLoaded = false;
    this.tapsService.getAllTapsMembersData(request).then((res:any)=>{
      if (res["status"] == "OK"){
        if (res["res"] != "No records found."){
          var byteString = atob(res["res"]);
          var ab = new ArrayBuffer(byteString.length);
          var ia = new Uint8Array(ab);
          for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const data = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
          saveAs(data, res["reportName"]); 
        } else {
          this.sendMessageToDialog('', 'No records found. (Please report this to IT)', '');
        }
        this.pageLoaded = true;
      } else {
        this.pageLoaded = true;
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2001S)', res);
      }
    }).catch((error: any) => {
      this.pageLoaded = true;
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2001S)', error);
    });
  }

  // Exports to Excel the list of 'Suppliers'
  adminExportSupplierManagement(){
    if (this.backUpSupplierListData.data.length == 0) {
      this.sendMessageToDialog("","No records found to export","");
    } else { 
        let reportName = Session.mySession.getUser().tradeCode + "_Sings_TAPS_Suppliers"
        const exportMe: any = [];
        let oneLine: any = {};
        this.backUpSupplierListData.data.forEach((item: any) => {
          oneLine = {};
          oneLine["Name"] = item.supplierNameM;
          oneLine["TAPS Identi."] = item.misttaCode;
          oneLine["TAPS"] = item.isTapsReg;
          oneLine["Address"] = String(item.addressLine1) + "," + String(item.postcode) + "," + String(item.country)
          if (item.contactTAPS) {
            if (item.contactTAPS.length > 0){
              item.contactTAPS.forEach((tempContact:any)=>{
                if (tempContact.active = "yes" && tempContact.contactType == "taps"){
                  // Telephone
                  if (oneLine["Telephone"] == null){
                    if (tempContact.phoneNumber1 != null){
                      oneLine["Telephone"] = tempContact.phoneNumber1
                    } else {
                      if (tempContact.phoneNumber2 != null){
                        oneLine["Telephone"] = tempContact.phoneNumber2
                      }
                    }
                  }
                  // Contact
                  if (oneLine["Contact"] == null){
                    if (tempContact.contactName != null){
                      oneLine["Contact"] = tempContact.contactName
                    }
                  }
                  // Email
                  if (oneLine["Email"] == null){
                    if (tempContact.emailAddress != null){
                      oneLine["Email"] = tempContact.emailAddress
                    }
                  } else {
                    if (tempContact.emailAddress != null){
                      oneLine["Email"] = oneLine["Email"] + ";" + tempContact.emailAddress
                    }
                  }
                }
              })
            }
          }
          oneLine["Deny Amendments"] = item.tapsDenyAmendments;
          oneLine["Transaction Fee"] = item.tapsTransactionFee;
          oneLine["Payment Days"] = item.tapsPaymentDays;
          if (oneLine["Email"] == null || oneLine["Contact"] == null || oneLine["Telephone"] == null){
            console.log("Check for Supplier's Contact Details : " + oneLine["TAPS Identi."])
            console.log(item);
          }
          exportMe.push(oneLine);
        });
        this.exportTapsReportAsExcelFile(exportMe, reportName, "", "", false);
    }
  }

  // Calls :: getAllTapsSuppliersData
  downloadAllTapsSuppliersData(){
      let request:any = {
        company: Session.mySession.getUser().company,
        tradeCode: Session.mySession.getUser().tradeCode,
        operation: Session.mySession.getUser().operation,
        format: "XLSX",
        token: Session.mySession.get('user').token,
      }
      this.pageLoaded = false;
      this.tapsService.getAllTapsSuppliersData(request).then((res:any)=>{
        if (res["status"] == "OK"){
          if (res["res"] != "No records found."){
            var byteString = atob(res["res"]);
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            const data = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
            saveAs(data, res["reportName"]); 
          } else {
            this.sendMessageToDialog('', 'No records found. (Please report this to IT)', '');
          }
          this.pageLoaded = true;
        } else {
          this.pageLoaded = true;
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2001S)', res);
        }
      }).catch((error: any) => {
        this.pageLoaded = true;
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2001S)', error);
      });
  }
  
  // Calls :: getS3files with pathType of "tapsCollectFtp"
  setupCollectFtpPage(): Promise<any> {
    this.clearViewUploadFile(); 
    this.lateAdjustmentUploadedFile = false;
    this.attachedDocs.data = [];
    return new Promise((resolve, reject) => { // Check if the booking is in the session variable, It not / expired - reload by calling API
      const listRequest = {
        company: Session.mySession.getUser().company,
        tradeCode: Session.mySession.getUser().tradeCode,
        operation: Session.mySession.getUser().operation,
        pathType: "tapsCollectFtp", 
        token: Session.mySession.get('user').token
      };
      this.pageLoaded = false;
      this.reportService.getS3files(listRequest).then((output: any) => {
        if (output.status === 'OK') {
          if (output.data !== undefined) {
            this.attachedDocs.data = output.data; 
            this.attachedDocs.data.forEach((file: any) => {
              file.name = file.contents.key.split('/').pop(); 
              file.supplierName = file.supplierName;
              file.supplierCode = file.supplierCode;
            });
          } else {
            this.attachedDocs.data = []; // Contents not found - set empty array in the data
          }
          this.pageLoaded = true; 
          resolve('');
        } else {
          this.sendMessageToDialog('', output.status, ''); this.pageLoaded = true; resolve(''); // Print error..
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0829S)', error); resolve(''); // Print error..
      });
    }).catch((err: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request (' + err + ')', '') // Display error message here..
    });
  }

  // Calls :: downloadS3file with pathType of "tapsCollectFtp"
  collectFtpFile(data:any){
    let downloadRequest = {
      company: Session.mySession.getUser().company,
      tradeCode: data.supplierCode,
      operation: Session.mySession.getUser().operation, 
      pathType: "tapsCollectFtp", 
      fileName: data.name,
      token: Session.mySession.get('user').token
    };
    this.uploadFileFtpRequest = downloadRequest;
    // Get file's extension and prepare for assigning MIME type below..
    const extension = data.name.substr(data.name.lastIndexOf('.') + 1); let type = '';
    // Depending on the extension, we'll assign the right MIME type below..
    if (extension === 'csv') { type = 'text/csv'; }
    else if (extension === 'txt') { type = 'text/plain'; }
    else if (extension === 'xls') { type = 'application/vnd.ms-excel'; }
    else if (extension === 'xlsx') { type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; }
    // Call downloadS3file method
    this.pageLoaded = false;
    this.reportService.downloadS3file(downloadRequest).then((output: any) => {
      if (output.status === 'OK') {
        try {
            const byteString = atob(output.fileContent);
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            const ftpUploadFile = new Blob([ab], { type });
            const ftpFile = new File([ftpUploadFile], data.name, { type });
            this.lateAdjustmentFile = ftpFile;
            // You can now use this `file` object to perform further operations, such as validating its size and type
            if (ftpFile.size > 4194304) {
              this.sendMessageToDialog('', 'This file is too big. Maximum file upload is 4MB', '');
            } else {
              if (ftpFile.size > 0) {
                if (ftpFile.type === "text/plain") {
                  this.clearViewUploadFile();     
                  this.loadLateAdjustmentTxtFile(ftpFile);
                } else if (ftpFile.type === "text/csv") { 
                  this.clearViewUploadFile();       
                  this.loadLateAdjustmentCSVFile(ftpFile);
                } else if (ftpFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                  this.clearViewUploadFile();       
                  this.loadLateAdjustmentXLSXFile(ftpFile);
                } else {
                  this.sendMessageToDialog('', 'SinGS could not complete your request at this time (Invalid File Format)', "");
                }
              } else {
                this.sendMessageToDialog('', 'SinGS could not complete your request at this time (Empty File)', "");
              }
            }
            this.pageLoaded = true;
        } catch (error) {
          this.sendMessageToDialog('', error, ''); // File download OK but failed to convert Base64 to whatever
        }
      } else {
        this.sendMessageToDialog('', output.status, ''); // File download failed at the back-end
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0830S)', error);
    });
  }

  // Calls :: removeS3file when an FTP upload is successfully collected
  deleteFtpFile(deleteRequest:any){
    this.uploadFileFtpRequest = null;
    deleteRequest.token = Session.mySession.get('user').token;
    this.pageLoaded = false;
    this.reportService.removeS3file(deleteRequest).then((output: any) => {
      if (output.status != 'OK') {
        this.sendMessageToDialog('', output.status, ''); 
      }
      this.pageLoaded = true;
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0831S)', error);
    });
  }

  //////////////////////////////////
  //
  // Admin Functionalities Above
  //
  //////////////////////////////////


  //////////////////////////////////
  //
  // Member Functionalities Below
  //
  //////////////////////////////////


  // INITIAL SETUP FOR MEMBER
  setUpMemberPage(){
    this.loggedInUserType = "Member";
    this.userFullCompanyName = Session.mySession.getBranch().fullName;
    this.setSuppliersList();
    this.setUpDatePeriods();
    this.checkTransactionApprovalAvailability();  
    this.setupAdminAgentsList();
    // if (this.liveVersion == false){
    //   // It means that the transaction approval page is open to view
    //   this.transactionAvailability = true; 
    // }
    if (Session.mySession.getUser().userType == 'wcManager' || Session.mySession.getUser().userType == 'wcMember') {
      this.onBookingPlatform = false;
    } 
    if (Session.mySession.getUser()?.tradeCode === "GB001") {
      this.onBookingPlatform = false;
      const user = Session.mySession.getUser();
      const request = { tradeCode: user?.tradeCode, token: Session.mySession.get('user')?.token };
      this.tapsService.getTapsMasterAgents(request).then((output: any) => {
        if (output?.status === "OK") {
          const listOfChildrenAgents = output.data;
          if (listOfChildrenAgents.length > 0) {
            let tempListOfChildrenTradeCode:any = []
            listOfChildrenAgents.forEach((tempChildAgentTradeCode: any) => {
              tempListOfChildrenTradeCode.push(tempChildAgentTradeCode)
            });
            tempListOfChildrenTradeCode = tempListOfChildrenTradeCode.sort((a: any, b: any) => a.branchName.localeCompare(b.branchName));
            this.listOfChildrenAgentsTradeCode = tempListOfChildrenTradeCode;
          }
        } else {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
        }
      });
    } else {
      if (Session.mySession.getBranch()?.tapsMaster != "no"){
        const user = Session.mySession.getUser();
        const request = { tradeCode: user?.tradeCode, token: Session.mySession.get('user')?.token };
        this.tapsService.getTapsMasterAgents(request).then((output: any) => {
          if (output?.status === "OK") {
            const listOfChildrenAgents = output.data;
            if (listOfChildrenAgents.length > 0) {
              let tempListOfChildrenTradeCode:any = []
              listOfChildrenAgents.forEach((tempChildAgentTradeCode: any) => {
                tempListOfChildrenTradeCode.push(tempChildAgentTradeCode)
              });
              tempListOfChildrenTradeCode = tempListOfChildrenTradeCode.sort((a: any, b: any) => a.branchName.localeCompare(b.branchName));
              this.listOfChildrenAgentsTradeCode = tempListOfChildrenTradeCode;
            }
          } else {
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
          }
        });
      }
    }
  }

  // Checks if the current day is less than the next From and To Upload Deadlines.
  checkTransactionApprovalAvailability(){
    let currentDate:any = new Date();
    if (currentDate.getDay() == 3){
      // Wednesday (Available before 16:00)
      if (currentDate.getHours() < 16){
        this.transactionAvailability = true;
      } else {
        this.transactionAvailability = false;
      }
    } else if (currentDate.getDay() >= 4 && currentDate.getDay() <= 5) {
      // Thursday and Friday (Not Available)
      this.transactionAvailability = false;
    } else if (currentDate.getDay() == 6) {
      // Saturday (Available after 9:00)
      if (currentDate.getHours() > 8 || (currentDate.getHours() == 8 && currentDate.getMinutes() >= 50)){
        this.transactionAvailability = true;
      } else {
        this.transactionAvailability = false;
      }
    } else {
      // Sunday - Tuesday (Available)
      this.transactionAvailability = true;
    }
  }

  // Setups 'Transaction Approval'
  setupTransactionApprovalPage(){
    this.checkTransactionApprovalAvailability();
    if (this.transactionAvailability == true){
      this.clearViewTransactionApprovalData();  
      this.setupRequestPeriod();
      this.listTransactionsForApprovalPage(this.membersRequestPeriod)
    }
  }

  // Setups Export or Import Transactions' page
  setupExportOrImportTransactionsPage(){
    this.checkTransactionApprovalAvailability();
    if (this.transactionAvailability == true){
      this.clearViewTransactionApprovalData();  
      this.setupRequestPeriod();
      this.transactionViewIfAvailable = true;
    }
  }
  
  // Refresh all the necessary data of the Transaction Approval Page
  clearViewTransactionApprovalData(){
    this.needsToBeSaved = false;
    this.transAprCollectionDate = "";
    this.tranAprTotalTransactions = 0
    this.tranAprOriginalTotal = 0
    this.tranAprAdjustmentsTotal = 0
    this.tranAprAdjustmentsPercentage = 0
    this.tranAprCollectionTotal = 0  
    this.currertUploadPeriod = "";
    this.tapsTransactionApprovalData.data = [];
    this.tapsTransactionApprovalBackUpData.data = [];
  }

  // Setups members upload period when we call to view the reviewed transactions
  setupRequestPeriod(){
    let tempDate:any = new Date();
    tempDate = this.getNextDayOfTheWeek("Friday",false,tempDate);
    this.transAprCollectionDate = tempDate.toLocaleDateString();
    let requestPeriod:string = this.convertDate(this.subtrackDays(7,tempDate));
    this.membersRequestPeriod = requestPeriod;
  }

  // Calls :: getTransactionApprovalUploads
  // Loads weekly supplier transactions for member's to review 
  listTransactionsForApprovalPage(requestPeriod: any) {
    this.currertUploadPeriod = requestPeriod;
    this.setupAgentParent();
    const request = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      uploadPeriod: requestPeriod,
      agentName: Session.mySession.getBranch().branchName,
      agentTradeCode: Session.mySession.getUser().tradeCode,
      agentParent: this.selectedAgentParent,
      token: Session.mySession.get('user').token,
    };
    this.pageLoaded = false;
    this.tapsService.getTransactionApprovalUploads(request).then((output:any)=>{
      if (output.status === 'OK') {
        if (output.res.length > 0) {
          if (output.summary.length > 0) {
            this.transactionViewIfAvailable = true;
            const summary = output.summary[0];
            this.tranAprTotalTransactions = summary.TotalTransactions;
            this.tranAprOriginalTotal = summary.OriginalTotal
            this.tranAprAdjustmentsTotal = summary.Adjustments;
            this.tranAprAdjustmentsPercentage = summary.AdjustmentsPercentage;
            this.tranAprCollectionTotal = summary.CollectionTotal
            this.tapsTransactionApprovalData.data = output.res;
            this.tapsTransactionApprovalBackUpData.data = [...output.res];
          } else {
            this.sendMessageToDialog('', 'Unable to set up transaction approval summary values (Report this to IT)', output);
            this.transactionViewIfAvailable = false;
          }
        } else {
          this.transactionViewIfAvailable = false;
        }
      } else {
        this.sendMessageToDialog('', 'Error with request status: ' + output.res, output);
      }
      this.pageLoaded = true;
    }).catch((error)=>{
      this.pageLoaded = true;
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2001S)', error);
    });
  }

  // Receives aprTransaction.tranAprViewState to check if it will display the transaction value
  checkIfApprovedOrNotReviewed(transaction:any){
    if (transaction.tranAprViewState == "PENDING APPROVAL"){
      return true;
    }
    if (transaction.tranAprViewState == "TRANSACTION ACCEPTED"){
      return true;
    }
    return false;
  }

  // Returns current transaction's code and reason
  getCodeAndReason(givenAprovalState:any){
    if (givenAprovalState != "" && givenAprovalState.includes("-")){
      let currentCode:any = givenAprovalState.split("-")[0];
      let currentReason:any = givenAprovalState.split("-")[1];
      return {code: currentCode, reason: currentReason};
    } else 
    {
      return {code: "", reason: ""}
    }    
  }

  // Add a Booking Reference to a transaction's "bookingReference" in 'Transaction Approval' Page
  // This will adjust the matchType to 'manMatch'
  addBookingReference(transaction:any){
    if (transaction.matchType != "manMatch"){
      transaction.matchType = "manMatch"
    }
    transaction.bookingReference = "";
    transaction.tranChanged = true;
  }

  // Select a Booking Reference to a transaction's "bookingReference" in 'Transaction Approval' Page
  // This will adjust the matchType to 'manMatch'
  selectedBookingReference(transaction:any, selectedBookingReference:any){
    if (transaction.originalBookingReference == selectedBookingReference || transaction.bookingReference ==  selectedBookingReference){
      transaction.tranChanged = false;
      transaction.bookingReference = transaction.originalBookingReference;
      transaction.matchType = transaction.originalMatchType;
    } else {
      transaction.tranChanged = true;
      transaction.bookingReference = selectedBookingReference;
      transaction.matchType = "manMatch";
    }
  }

  // Reset to the original form of the 'Booking Reference' for a transaction in 'Transaction Approval' Page
  // This will adjust the matchType to it's original version
  resetBookingReference(transaction:any){
    transaction.tranChanged = true;
    transaction.bookingReference = transaction.originalBookingReference;
    transaction.matchType = transaction.originalMatchType;
  }

  // Calls :: saveMemberTransactionsForReview
  // Saves all transaction status changes / added bookings 
  saveAndCloseTransactionApproval(){
    if (confirm("Are you sure you want to save your changes?")) {
      if (this.tapsTransactionApprovalData.data.length > 0){
        this.setupAgentParent();
        let tempAgentTradeCode = Session.mySession.getUser().tradeCode;
        let tempAgentCompany = Session.mySession.getUser().company;
        let tempAgentOperation = Session.mySession.getUser().operation;
        let tempChildAgent = "None";
        if (this.selectedAgentParent != "yes"){
          tempChildAgent = tempAgentTradeCode;
          const requestAgentTradeCode:any = this.checkIfAgentIsChild();
          if (requestAgentTradeCode != "None" && requestAgentTradeCode != "Failed") {
            tempAgentTradeCode = requestAgentTradeCode.tradeCode
            this.selectedAgentParent = "yes"
          }
        }
        let transactionsMap = this.mapTransactionsWithTapsUploads();
        let request:any = {
          company: Session.mySession.getUser().company,
          tradeCode: Session.mySession.getUser().tradeCode,
          operation: Session.mySession.getUser().operation,
          uploadPeriod: this.currertUploadPeriod,
          agentTradeCode: tempAgentTradeCode,
          agentCompany: tempAgentCompany,
          agentOperation: tempAgentOperation,
          tranState: "Review",
          tranFailure: "None",
          listOfTransactions: transactionsMap,
          onBookingPlatform: String(this.onBookingPlatform),
          agentParent: this.selectedAgentParent,
          agentChild: tempChildAgent,
          token: Session.mySession.get('user').token,
          hideDialog: true
        }
        this.tapsService.saveMemberTransactionsForReview(request).then((output:any)=>{
          if (output.status == "OK"){
            if (output.res == "Another running job found."){
              this.needsToBeSaved = false;
              this.pageLoaded = true;
              this.switchView("homePage");
              this.sendMessageToDialog('', 'Another user is currently updating these transactions. Please try again.', "");
            } else {
              this.needsToBeSaved = false;
              this.switchView("homePage");
              this.transactionApprovalBlocked = true;
              this.pageLoaded = true;
              Session.mySession.resetTimersOnBookingValues().then((res:any)=>{
                AsynchRequests.myAsynchReq.addJobToWatch(request, output.jobID)
                this.checkIfJobIsDone().then((res:any)=>{
                  this.transactionApprovalBlocked = false;
                })
              })
            }
          } else {
            this.sendMessageToDialog('', 'Transactions update has failed (' + output.status + ')', output);
          }
        })
      } else {
        this.sendMessageToDialog("","There were no transactions listed to be review","");
      }
    }    
  }

  async checkIfJobIsDone(): Promise<boolean> {
    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
    let isDone = false; 
    while (!isDone) {
        let tempCurrentJobs: any[] = Session.mySession.get('currentJobs');
        if (tempCurrentJobs.length === 0) {
            isDone = true;
        } else {
          await delay(1000);
        }
    }
    return true;
  }

  // Maps all the different supplier upload IDs into a {key,list} object 
  mapTransactionsWithTapsUploads(){
    let transactionsMap:any = [];
    let tempListOfOriginalTransactionIDs = "";
    this.tapsTransactionApprovalData.data.forEach((transaction:any)=>{ 
      let newTransactionFormat:any = {
        tranAprViewState: transaction.tranAprViewState,
        transactionID: transaction.transactionID,
        transactionOriginalID: transaction.transactionOriginalID,
        tranAprValue: transaction.tranAprValue,
        tranAprCollected: transaction.tranAprCollected,
        tranAprRef: transaction.tranAprRef,
        tranAprId: transaction.tranAprId,
        tranAprAgentTapsId: transaction.tranAprAgentTapsId,
        tranChanged: true,
        matchType: transaction.matchType,
        bookingReference: transaction.bookingReference,
        originalBookingReference: transaction.originalBookingReference
      }
      if (typeof newTransactionFormat.tranAprValue == "string"){
        newTransactionFormat.tranAprValue = Number(parseFloat(transaction.tranAprValue.replace(/,/g, '')));
      }
      if (typeof newTransactionFormat.tranAprCollected == "string"){
        newTransactionFormat.tranAprCollected = Number(parseFloat(transaction.tranAprCollected.replace(/,/g, '')));
      }
      // Check if matchType or bookingReference is different from the originals
      if (transaction.originalMatchType != newTransactionFormat.matchType){
        newTransactionFormat.tranChanged = true;
        transaction.tranChanged = true;
      }
      if (newTransactionFormat.bookingReference == transaction.originalBookingReference){
        newTransactionFormat.matchType = transaction.originalMatchType;
      } else {
        newTransactionFormat.tranChanged = true;
        transaction.tranChanged = true;
      }
      if (tempListOfOriginalTransactionIDs == ""){
        tempListOfOriginalTransactionIDs = String(transaction.transactionID);
        transactionsMap.push({
          key: transaction.transactionID, 
          supplierName:transaction.tranAprSupplier, 
          supplierTradeCode: transaction.tranAprSupTapsId,
          transactionOriginalID: transaction.transactionOriginalID,
          collectionDate: transaction.tranAprCollectionDate,
          paymentDate: transaction.tranAprPaymentDate,
          requiresUpdate: true,
          listOfTransactions: [newTransactionFormat]
        });        
      } else {
        if (!tempListOfOriginalTransactionIDs.includes(String(transaction.transactionID))){
          tempListOfOriginalTransactionIDs = String(tempListOfOriginalTransactionIDs + "," + transaction.transactionID);
          transactionsMap.push({
            key: transaction.transactionID, 
            supplierName: transaction.tranAprSupplier, 
            supplierTradeCode: transaction.tranAprSupTapsId,
            transactionOriginalID: transaction.transactionOriginalID,
            collectionDate: transaction.tranAprCollectionDate,
            paymentDate: transaction.tranAprPaymentDate,
            requiresUpdate: true,
            listOfTransactions: [newTransactionFormat]
          });
        } else {
          transactionsMap.forEach((transactionCategory:any)=>{
            if (transactionCategory.key == transaction.transactionID){
              if (transaction.tranChanged == true){
                transactionCategory.requiresUpdate = true;
              }
              transactionCategory.listOfTransactions.push(newTransactionFormat);
            }
          });
        }
      }
      // In case of late adjustment uploads
      if (transaction.transactionTradeCode == "Q0000"){
        transactionsMap[transactionsMap.length-1].listOfTransactions.forEach((tempTransaction:any)=>{
          if (tempTransaction.tranAprViewState == "PENDING APPROVAL"){
            transactionsMap[transactionsMap.length-1].lateAdjustment = true;
          }
        });  
      }
    });
    return transactionsMap;
  }

  // When a member adjust the state of a listed transaction (Switch case)
  changeTransactionState(transaction:any){    
    let newState : any = transaction.tranAprViewState;
    transaction.tranChanged = true;
    this.needsToBeSaved = true;
    if (newState == "TRANSACTION ACCEPTED"){
      let tempCollectedValue:String = transaction.tranAprValue;
      transaction.tranAprCollected = tempCollectedValue;
    } else {
      transaction.tranAprCollected = "0.00";
    }
  }

  // Change all listed transaction state's to "TRANSACTION ACCEPTED" etc
  acceptAllTransactions(){
    this.needsToBeSaved = true;
    this.pageLoaded = false;
    this.tapsTransactionApprovalData.data.forEach((transaction:any) => {
      if (transaction.tranAprViewState != "TRANSACTION ACCEPTED"){
        transaction.tranAprViewState = "TRANSACTION ACCEPTED";
        let tempCollectedValue:String = transaction.tranAprValue;
        transaction.tranAprCollected = tempCollectedValue;
        transaction.tranChanged = true;
      }
    });
    this.pageLoaded = true;
  }

  // Change all listed transaction state's to "A - BOOKING NOT YET DUE" etc
  rejectAllTransactions(){
    this.needsToBeSaved = true;
    this.pageLoaded = false;
    this.tapsTransactionApprovalData.data.forEach((transaction:any) => {
      if (transaction.tranAprViewState != "A - BOOKING NOT YET DUE"){
        transaction.tranAprViewState = "A - BOOKING NOT YET DUE";
        transaction.tranAprCollected = "0.00";
        transaction.tranChanged = true;
      }
    });
    this.pageLoaded = true;
  }

  // Reload all transactions to be back to their original (*saved) form
  cancelTheseChanges(){
    this.setupTransactionApprovalPage();     
  }

  // Change all listed transaction state's to "PENDING APPROVAL" etc
  cancelAllChanges(){
    this.needsToBeSaved = true;
    this.pageLoaded = false;
    this.tapsTransactionApprovalData.data.forEach((transaction:any) => {
      if (transaction.tranAprViewState != "PENDING APPROVAL"){
        transaction.tranAprViewState = "PENDING APPROVAL";
        transaction.tranAprCollected = "0.00";
        transaction.bookingReference = transaction.originalBookingReference;
        transaction.tranChanged = true;
      }
    });
    this.pageLoaded = true;
  }  

  // Calls :: exportToExcelMembersTransactionsForReview
  // This is the 'Export to Excel' button of 'Export or Import Transactions' - Member
  memberExportToExcelTransactionsForReview(){
    this.setupAgentParent();
    let request:any = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      agentTradeCode: Session.mySession.getUser().tradeCode,
      uploadPeriod: this.membersRequestPeriod,
      onBookingPlatform: String(this.onBookingPlatform),
      agentParent: this.selectedAgentParent,
      token: Session.mySession.get('user').token,
    }
    this.tapsService.exportToExcelMembersTransactionsForReview(request).then((res:any)=>{
      if (res["status"] == "OK"){
        if (res["transactionsCount"] > 0) {
          var byteString = atob(res["excelContent"]);
          var ab = new ArrayBuffer(byteString.length);
          var ia = new Uint8Array(ab);
          for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const data = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
          saveAs(data, res["reportName"]); 
        } else {
          this.sendMessageToDialog('', 'No transactions for review during this week period', '');
        } 
        this.pageLoaded = true;
      } else {
        this.pageLoaded = true;
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2001S)', res);
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2001S)', error);
    });
  }

  // This is the 'Export to Excel' button of 'Transactions Approval' - Member
  memberExportToExcelTransactionsForReviewList():void {
    if (this.tapsTransactionApprovalData.data.length > 0) {
      let reportName = this.getReportName();
      const exportMe: any = [];
      this.tapsTransactionApprovalData.data.forEach((item: any) => {
          let oneLine:any = {};
          oneLine["Supplier"] = item.tranAprSupTapsId;
          oneLine["Supplier Name"] = item.tranAprSupplier;
          oneLine["Member"] = item.tranAprAgentTapsId;
          oneLine["Member Name"] = item.tranAprAgent;
          oneLine["Bkg Reference"] = item.tranAprRef;
          oneLine["Departure Date"] = item.tranAprDepart;
          oneLine["Client Name"] = item.tranAprClient;
          oneLine["Collection Date"] = item.tranAprCollectionDate;
          oneLine["Value"] = this.getValidNumberToExcel(item.tranAprValue);
          oneLine["Collected"] = this.getValidNumberToExcel(item.tranAprCollected);
          oneLine["Code"] = item.tranAprCode;
          oneLine["Reason"] = item.tranAprReason;
          oneLine["Approval"] = item.tranAprApproval;
          oneLine["State"] = item.tranAprState;
          oneLine["Collection"] = item.tranAprCollection;
          oneLine["Paid"] = item.tranAprPaid;
          exportMe.push(oneLine);
      });
      this.exportTapsReportAsExcelFile(exportMe, reportName, "I", "J", false);
    } else {
      this.sendMessageToDialog("", "There are no transactions listed to export", "");
    }
  }


  // Calls :: exportToPdfMembersTransactionsForReview
  // This is the 'Print' button of 'Transaction Approval' - Member
  memberPrintTransactionsForReviewList() {
    let request:any = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      agentTradeCode: Session.mySession.getUser().tradeCode,
      uploadPeriod: this.membersRequestPeriod,
      onBookingPlatform: String(this.onBookingPlatform),
      token: Session.mySession.get('user').token,
    }
    this.tapsService.exportToPdfMembersTransactionsForReview(request).then((res:any)=>{
      if (res["status"] == "OK"){
        if (res["transactionsCount"] > 0) {
          
          const reportName: string = res["reportName"];
          const fileExtension = reportName.split('.').pop(); 

          if (fileExtension === 'zip') {
            var byteString = atob(res["pdfContent"]); // res["pdfContent"] now contains the base64 encoded ZIP file
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            const data = new Blob([ab], { type: 'application/zip' });
            saveAs(data, reportName);
          } else {
            var byteString = atob(res["pdfContent"]);
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            const data = new Blob([ab], { type: 'application/pdf' });
            saveAs(data, reportName);
          }
        
        } else {
          this.sendMessageToDialog('', 'No transactions for review during this week period', '');
        } 
        this.pageLoaded = true;
      } else {
        this.pageLoaded = true;
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2001S)', res);
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2001S)', error);
    });
  }

  // Removes (digit) from the uploaded filename at the end
  normalizeFileName(fileName: any) {
    let baseName = fileName.replace(/\(\d+\)/, '');
    baseName = baseName.replace(/\s/g, '');
    return baseName;
  }

  // This is the 'Import File' button of 'Export or Import Transactions Page' - Member / Admin
  importAgentReviewedTransactions(fileInput: any, admin: any){
    let importAgentTradeCode:any = ""
    this.setupAgentParent();
    let preImportRequest:any = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      agentTradeCode: "",
      fromAdmin: admin,
      agentParent: this.selectedAgentParent,
      uploadPeriod: this.getUploadPeriod(true),
      token: Session.mySession.get('user').token,
    }
    // CHECK USER TYPE (ADMIN OR MEMBER)
    if (admin == true){
      importAgentTradeCode = this.selectedAgentForAdminExport;
    } else {
      importAgentTradeCode = Session.mySession.getUser().tradeCode;
    }
    // CHECK IF WEEKLY TRANSACTIONS EXIST FOR (SELECTED) AGENT
    if (importAgentTradeCode == "") {
      this.sendMessageToDialog('', 'You need to select an Agent before "Export to Excel" or "Import File"', '');
    } else {
      preImportRequest.agentTradeCode = importAgentTradeCode;
      this.pageLoaded = false;
      this.tapsService.getPreImportOfTapsTransactionsForMemberReview(preImportRequest).then((preImportRes:any)=>{
        if (preImportRes["status"] == "OK"){
          if (preImportRes["transactionsCount"] > 0){
            this.importedTFAFile = fileInput;
            let actualFile = fileInput.target.files[0];
            if (actualFile.size > 4194304) {
              this.sendMessageToDialog('', 'This file is too big. Maximum file upload is 4MB', '');
            } else {
              // Check file type
              if (actualFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                // Check file name
                if (this.normalizeFileName(actualFile.name) === preImportRes["reportName"]) {
                  // XLSX to JSON conversion starts here
                  const reader = new FileReader();
                  reader.onload = ((event:any) => {
                      const data = event.target.result;
                      const workBook = XLSX.read(data, { type: 'binary' });
                      const jsonData = workBook.SheetNames.reduce((initial: any, name: string) => {
                        const sheet = workBook.Sheets[name];
                        initial[name] = XLSX.utils.sheet_to_json(sheet);
                        return initial;
                      }, {});
                      if (jsonData["Report"] == undefined) {
                        this.sendMessageToDialog('', 'Invalid File Format. The imported file format does not match the the exported one.', '');
                      } else {
                        // Remove the fooder part of the import
                        if (jsonData["Report"] && jsonData["Report"].length > 0) {
                          jsonData["Report"].pop();
                        }
                        // Check if importedFile matches current exportedFile 
                        if (jsonData["Report"].length == preImportRes["listOfTransactions"].length){
                          let transactionsMap:any = [];
                          let tempListOfOriginalTransactionIDs = "";
                          for (let tranCounter = 0; tranCounter < jsonData["Report"].length; tranCounter++) {
                            let newTransactionFormat:any = {
                              tranAprViewState: preImportRes["listOfTransactions"][tranCounter]["tranAprViewState"],
                              transactionID: preImportRes["listOfTransactions"][tranCounter]["transactionID"],
                              transactionOriginalID: preImportRes["listOfTransactions"][tranCounter]["transactionOriginalID"],
                              tranAprValue: preImportRes["listOfTransactions"][tranCounter]["tranAprValue"],
                              tranAprCollected: 0.00,
                              tranAprRef: preImportRes["listOfTransactions"][tranCounter]["tranAprRef"],
                              tranAprId: preImportRes["listOfTransactions"][tranCounter]["tranAprId"],
                              tranAprAgentTapsId: preImportRes["listOfTransactions"][tranCounter]["tranAprAgentTapsId"],
                              tranChanged: true,
                              matchType: preImportRes["listOfTransactions"][tranCounter]["matchType"],
                              bookingReference: "",
                              originalBookingReference: preImportRes["listOfTransactions"][tranCounter]["bookingReference"],
                            }
                            if (jsonData["Report"][tranCounter]["Rejection Reason"] == "TRANSACTION ACCEPTED"){
                              let tempCollectedValue:String = preImportRes["listOfTransactions"][tranCounter]["tranAprValue"];
                              newTransactionFormat.tranAprCollected = Number(parseFloat(tempCollectedValue.replace(/,/g, '')));
                              newTransactionFormat.tranAprViewState = jsonData["Report"][tranCounter]["Rejection Reason"]
                            } else {
                              if (jsonData["Report"][tranCounter]["Rejection Reason"] == "PENDING APPROVAL" || 
                                  jsonData["Report"][tranCounter]["Rejection Reason"] == "A - BOOKING NOT YET DUE" || 
                                  jsonData["Report"][tranCounter]["Rejection Reason"] == "B - BOOKING ALREADY PAID" || 
                                  jsonData["Report"][tranCounter]["Rejection Reason"] == "C - CANCELLED BOOKING" || 
                                  jsonData["Report"][tranCounter]["Rejection Reason"] == "D - DUPLICATE TRANSACTION" || 
                                  jsonData["Report"][tranCounter]["Rejection Reason"] == "E - INCORRECT COMMISSION LEVEL" || 
                                  jsonData["Report"][tranCounter]["Rejection Reason"] == "F - CLIENT NON-PAYMENT OR CLEARING" ||
                                  jsonData["Report"][tranCounter]["Rejection Reason"] == "G - BOOKING NOT RECOGNISED" || 
                                  jsonData["Report"][tranCounter]["Rejection Reason"] == "H - AWAITING AMENDED INVOICE"){
                                newTransactionFormat.tranAprViewState = jsonData["Report"][tranCounter]["Rejection Reason"]
                              }
                            }
                            if (jsonData["Report"][tranCounter]["SinGS Ref"] != undefined){
                              newTransactionFormat.bookingReference = jsonData["Report"][tranCounter]["SinGS Ref"]
                            }
                            if (tempListOfOriginalTransactionIDs == ""){
                              tempListOfOriginalTransactionIDs = String(preImportRes["listOfTransactions"][tranCounter]["transactionID"]);
                              transactionsMap.push({
                                key: preImportRes["listOfTransactions"][tranCounter]["transactionID"], 
                                supplierName: preImportRes["listOfTransactions"][tranCounter]["tranAprSupplier"], 
                                supplierTradeCode: preImportRes["listOfTransactions"][tranCounter]["tranAprSupTapsId"],
                                transactionOriginalID: preImportRes["listOfTransactions"][tranCounter]["transactionOriginalID"],
                                collectionDate: preImportRes["listOfTransactions"][tranCounter]["tranCollectionDate"],
                                paymentDate: preImportRes["listOfTransactions"][tranCounter]["tranPaymentDate"],
                                requiresUpdate: true,
                                listOfTransactions: [newTransactionFormat]
                              });        
                            } else {
                              if (!tempListOfOriginalTransactionIDs.includes(String(preImportRes["listOfTransactions"][tranCounter]["transactionID"]))){
                                tempListOfOriginalTransactionIDs = String(tempListOfOriginalTransactionIDs + "," + preImportRes["listOfTransactions"][tranCounter]["transactionID"]);
                                transactionsMap.push({
                                  key: preImportRes["listOfTransactions"][tranCounter]["transactionID"], 
                                  supplierName: preImportRes["listOfTransactions"][tranCounter]["tranAprSupplier"], 
                                  supplierTradeCode: preImportRes["listOfTransactions"][tranCounter]["tranAprSupTapsId"],
                                  transactionOriginalID: preImportRes["listOfTransactions"][tranCounter]["transactionOriginalID"],
                                  collectionDate: preImportRes["listOfTransactions"][tranCounter]["tranCollectionDate"],
                                  paymentDate: preImportRes["listOfTransactions"][tranCounter]["tranPaymentDate"],
                                  requiresUpdate: true,
                                  listOfTransactions: [newTransactionFormat]
                                });
                              } else {
                                transactionsMap.forEach((transactionCategory:any)=>{
                                  if (transactionCategory.key == preImportRes["listOfTransactions"][tranCounter]["transactionID"]){
                                    transactionCategory.listOfTransactions.push(newTransactionFormat);
                                  }
                                });
                              }
                            }
                          } 
                          let importRequest:any = {}
                          if (admin == true){
                            let tempAgentTradeCode = this.selectedAgentForAdminExport;
                            let tempAgentCompany = preImportRes["agentCompany"];
                            let tempAgentOperation = preImportRes["agentOperation"];
                            let tempChildAgent = "None";
                            if (this.selectedAgentParent != "yes"){
                              tempChildAgent = tempAgentTradeCode;
                              const requestAgentTradeCode:any = this.checkIfAgentIsChild();
                              if (requestAgentTradeCode != "None" && requestAgentTradeCode != "Failed") {
                                tempAgentTradeCode = requestAgentTradeCode.tradeCode
                                this.selectedAgentParent = "yes"
                              }
                            }
                            importRequest = {
                              company: Session.mySession.getUser().company,
                              tradeCode: Session.mySession.getUser().tradeCode,
                              operation: Session.mySession.getUser().operation,
                              uploadPeriod: this.getUploadPeriod(true),
                              agentTradeCode: tempAgentTradeCode,
                              agentCompany: tempAgentCompany,
                              agentOperation: tempAgentOperation,
                              tranState: "Review",
                              tranFailure: "None",
                              listOfTransactions: transactionsMap,
                              onBookingPlatform: String(this.onBookingPlatform),
                              agentParent: this.selectedAgentParent,
                              agentChild: tempChildAgent,
                              token: Session.mySession.get('user').token,
                              hideDialog: true
                            }
                          } else {
                            let tempAgentTradeCode = Session.mySession.getUser().tradeCode;
                            let tempAgentCompany = Session.mySession.getUser().company;
                            let tempAgentOperation = Session.mySession.getUser().operation;
                            let tempChildAgent = "None";
                            if (this.selectedAgentParent != "yes"){
                              tempChildAgent = tempAgentTradeCode;
                              const requestAgentTradeCode:any = this.checkIfAgentIsChild();
                              if (requestAgentTradeCode != "None" && requestAgentTradeCode != "Failed") {
                                tempAgentTradeCode = requestAgentTradeCode.tradeCode
                                this.selectedAgentParent = "yes"
                              }
                            }
                            importRequest = {
                              company: Session.mySession.getUser().company,
                              tradeCode: Session.mySession.getUser().tradeCode,
                              operation: Session.mySession.getUser().operation,
                              uploadPeriod: this.getUploadPeriod(true),
                              agentTradeCode: tempAgentTradeCode,
                              agentCompany: tempAgentCompany,
                              agentOperation: tempAgentOperation,
                              tranState: "Review",
                              tranFailure: "None",
                              listOfTransactions: transactionsMap,
                              onBookingPlatform: String(this.onBookingPlatform),
                              agentParent: this.selectedAgentParent,
                              agentChild: tempChildAgent,
                              token: Session.mySession.get('user').token,
                              hideDialog: true
                            }
                          }
                          this.tapsService.saveMemberTransactionsForReview(importRequest).then((output:any)=>{
                            if (output.status == "OK"){
                              if (output.res == "Another running job found."){
                                this.needsToBeSaved = false;
                                this.pageLoaded = true;
                                this.switchView("homePage");
                                this.sendMessageToDialog('', 'Another user is currently updating these transactions. Please try again.', "");
                              } else {
                                this.needsToBeSaved = false;
                                this.switchView("homePage");
                                this.transactionApprovalBlocked = true;
                                this.pageLoaded = true;
                                Session.mySession.resetTimersOnBookingValues().then((res:any)=>{
                                  AsynchRequests.myAsynchReq.addJobToWatch(importRequest, output.jobID)
                                  this.checkIfJobIsDone().then((res:any)=>{
                                    this.transactionApprovalBlocked = false;
                                  })
                                })
                              }
                            } else {
                              this.sendMessageToDialog('', 'Transactions update has failed (' + output.status + ')', output);
                            }
                          })
                        } else {
                          this.sendMessageToDialog('', 'Invalid File Format. The imported file transactions amount does not match the exported one. (Try "Export to Excel")', '');
                        }
                      }
                  })
                  reader.readAsArrayBuffer(actualFile);
                } else {
                  this.sendMessageToDialog('', 'Invalid File Format. The imported file name does not match the exported one.', '');
                }
              } else {
                this.sendMessageToDialog('', 'Invalid File Format. The imported file needs to be an XLSX file.', '');
              }
            }
          } else {
            this.sendMessageToDialog('', 'Agent  (' + importAgentTradeCode + ') has no transactions for review during this week period', '');
          }
          this.pageLoaded = true;
        } else {
          this.pageLoaded = true;
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2001S)', preImportRes);
        }
      }).catch((error: any) => {
        this.pageLoaded = true;
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2001S)', error);
      });
    }
  }

  // Requests all available suppliers to be listed (Name|TAPS Identity|Telephone|Email)
  setSuppliersList(){
      let tempViewAgentTradeCode = "";
      if (this.adminCameFromAgentManagement == true) {
          tempViewAgentTradeCode = this.viewAgentTradeCode;
      } else {
          tempViewAgentTradeCode = Session.mySession.getUser().tradeCode;
      }
      let request = { 
        token: Session.mySession.get('user').token 
      };
      this.supplierService.getSupplierList(request).then((suppliers: any) => {
        if (suppliers.status != "OK") {
          this.sendMessageToDialog('', 'SinGS could not load suppliers (' + suppliers.status + ')', '');
        } else {
          let supplierMap: { [key: string]: any } = {};
          // MAPPING SUPPLIERS EMAILS FROM CONTACTS
          suppliers.data.forEach((supplier: any) => {
              if (supplier.contactTAPS) {
                if (supplier.contactTAPS.length > 0){
                  supplier.contactTAPS.forEach((tempContact:any)=>{
                    if (tempContact.active == "yes" && tempContact.contactType == "taps"){
                      if (!supplierMap[supplier.supplierNameM]) {
                        if (tempContact.emailAddress) {
                          supplierMap[supplier.supplierNameM] = { ...supplier, emailTAPS: new Set(tempContact.emailAddress ? [tempContact.emailAddress] : []) };
                        }
                      } else {
                        if (tempContact.emailAddress) {
                            supplierMap[supplier.supplierNameM].emailTAPS.add(tempContact.emailAddress);
                        }
                      }
                    }
                  })
                } else {
                  if (!supplierMap[supplier.supplierNameM]) {
                    if (supplier.emailTAPS) {
                      supplierMap[supplier.supplierNameM] = { ...supplier, emailTAPS: new Set(supplier.emailTAPS ? [supplier.emailTAPS] : []) };
                    }
                  } else {
                    if (supplier.emailTAPS) {
                        supplierMap[supplier.supplierNameM].emailTAPS.add(supplier.emailTAPS);
                    }
                  }
                }
              }
          });
          // Convert Set to a single string with emails separated by ';'
          for (let key in supplierMap) {
              supplierMap[key].emailTAPS = Array.from(supplierMap[key].emailTAPS).join(';');
          }
          let tempAllSuppliers = new MatTableDataSource<any>();
          let tempActiveSuppliers = new MatTableDataSource<any>();
          let tempInactiveSuppliers = new MatTableDataSource<any>();
          Object.values(supplierMap).forEach((supplier: any) => {
                if (supplier.misttaCode != "" && supplier.misttaCode != null){
                  tempAllSuppliers.data.push(supplier);
                }
                if (supplier.misttaCode != "" && supplier.misttaCode != null && supplier.isTapsReg == 'yes') {
                    if (!supplier.tapsTradeCode || !supplier.tapsTradeCode.includes(tempViewAgentTradeCode) || supplier.tapsExcludeFlag != 'yes') {
                        tempActiveSuppliers.data.push(supplier);
                    } else {
                        tempInactiveSuppliers.data.push(supplier);
                    }
                }
          });
          // Sort active and inactive suppliers
          let allSuppliersSorted = tempAllSuppliers.data.sort((a: any, b: any) => a.supplierNameM.localeCompare(b.supplierNameM));
          let activeSorted = tempActiveSuppliers.data.sort((a: any, b: any) => a.supplierNameM.localeCompare(b.supplierNameM));
          let inactiveSorted = tempInactiveSuppliers.data.sort((a: any, b: any) => a.supplierNameM.localeCompare(b.supplierNameM));
          this.suppliersListData.data = allSuppliersSorted;
          this.filteredAdminSupplierListData.data = allSuppliersSorted;
          this.activeSuppliersListData.data = activeSorted;
          this.inactiveSuppliersListData.data = inactiveSorted;
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2001S)', error);
      }).then(()=>{this.pageLoaded=true;});
  }

  // Sort by Name (Member/Admin - Supplier List)
  sortSupplierListByName(excluded:any){
    if (excluded == true){
      if (this.memberSupplierListSortedbyName == false){
        this.memberSupplierListSortedbyName = true;
        let sorted = this.inactiveSuppliersListData.data.sort((a: any, b: any) => (a.supplierNameM < b.supplierNameM) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['supplierNameM'] === e['supplierNameM']) === i); 
        this.inactiveSuppliersListData.data = sorted; 
      } else {
        this.memberSupplierListSortedbyName = false;
        let sorted = this.inactiveSuppliersListData.data.sort((a: any, b: any) => (a.supplierNameM > b.supplierNameM) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['supplierNameM'] === e['supplierNameM']) === i); 
        this.inactiveSuppliersListData.data = sorted; 
      }
    } else if (excluded == false) {
      if (this.memberSupplierListSortedbyName == false){
        this.memberSupplierListSortedbyName = true;
        let sorted = this.activeSuppliersListData.data.sort((a: any, b: any) => (a.supplierNameM < b.supplierNameM) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['supplierNameM'] === e['supplierNameM']) === i); 
        this.activeSuppliersListData.data = sorted; 
      } else {
        this.memberSupplierListSortedbyName = false;
        let sorted = this.activeSuppliersListData.data.sort((a: any, b: any) => (a.supplierNameM > b.supplierNameM) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['supplierNameM'] === e['supplierNameM']) === i); 
        this.activeSuppliersListData.data = sorted; 
      }
    } else if (excluded == 'admin'){
      if (this.memberSupplierListSortedbyName == false){
        this.memberSupplierListSortedbyName = true;
        let sorted = this.backUpSupplierListData.data.sort((a: any, b: any) => (a.supplierNameM < b.supplierNameM) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['supplierNameM'] === e['supplierNameM']) === i); 
        this.backUpSupplierListData.data = sorted; 
      } else {
        this.memberSupplierListSortedbyName = false;
        let sorted = this.backUpSupplierListData.data.sort((a: any, b: any) => (a.supplierNameM > b.supplierNameM) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['supplierNameM'] === e['supplierNameM']) === i); 
        this.backUpSupplierListData.data = sorted; 
      }
    }
  }

  // Sort by Trade Code (Member/Admin - Supplier List)
  sortSupplierListByTradeCode(excluded:any){
    if (excluded == true){
      if (this.memberSupplierListSortedbyTradeCode == false){
        this.memberSupplierListSortedbyTradeCode = true;
        let sorted = this.inactiveSuppliersListData.data.sort((a: any, b: any) => (a.misttaCode < b.misttaCode) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['misttaCode'] === e['misttaCode']) === i); 
        this.inactiveSuppliersListData.data = sorted; 
      } else {
        this.memberSupplierListSortedbyTradeCode = false;
        let sorted = this.inactiveSuppliersListData.data.sort((a: any, b: any) => (a.misttaCode > b.misttaCode) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['misttaCode'] === e['misttaCode']) === i); 
        this.inactiveSuppliersListData.data = sorted; 
      }
    } else if (excluded == false) {
      if (this.memberSupplierListSortedbyTradeCode == false){
        this.memberSupplierListSortedbyTradeCode = true;
        let sorted = this.activeSuppliersListData.data.sort((a: any, b: any) => (a.misttaCode < b.misttaCode) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['misttaCode'] === e['misttaCode']) === i); 
        this.activeSuppliersListData.data = sorted; 
      } else {
        this.memberSupplierListSortedbyTradeCode = false;
        let sorted = this.activeSuppliersListData.data.sort((a: any, b: any) => (a.misttaCode > b.misttaCode) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['misttaCode'] === e['misttaCode']) === i); 
        this.activeSuppliersListData.data = sorted; 
      }
    } else if (excluded == 'admin'){
      if (this.memberSupplierListSortedbyTradeCode == false){
        this.memberSupplierListSortedbyTradeCode = true;
        let sorted = this.backUpSupplierListData.data.sort((a: any, b: any) => (a.misttaCode < b.misttaCode) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['misttaCode'] === e['misttaCode']) === i); 
        this.backUpSupplierListData.data = sorted; 
      } else {
        this.memberSupplierListSortedbyTradeCode = false;
        let sorted = this.backUpSupplierListData.data.sort((a: any, b: any) => (a.misttaCode > b.misttaCode) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['misttaCode'] === e['misttaCode']) === i); 
        this.backUpSupplierListData.data = sorted; 
      }
    }    
  }

  // Sort by Agent (Member - Transaction Approval)
  sortTransactionsByAgent(){   
    if (this.memberTransactionListSortAgent == false){      
      this.memberTransactionListSortAgent = true;
      let sorted = this.tapsTransactionApprovalData.data.sort((a: any, b: any) => {
        if (a.tranAprAgent < b.tranAprAgent) {
          return -1;
        } else if (a.tranAprAgent > b.tranAprAgent) {
          return 1;
        } else {
          return 0;
        }
      });
      this.tapsTransactionApprovalData.data = sorted; 
    } else {
      this.memberTransactionListSortAgent = false;
      let sortedByAgent = this.tapsTransactionApprovalData.data.sort((a: any, b: any) => {
        if (a.tranAprAgent < b.tranAprAgent) {
          return 1;
        } else if (a.tranAprAgent > b.tranAprAgent) {
          return -1;
        } else {
          return 0;
        }
      });
      this.tapsTransactionApprovalData.data = sortedByAgent; 
    }    
  }

  // Sort by Depart (Member - Transaction Approval)
  sortTransactionsByDepart() {
    if (this.memberTransactionListSortDepart == false){ 
      this.memberTransactionListSortDepart = true;
      let sorted = this.tapsTransactionApprovalData.data.sort((a: any, b: any) => {
        const arr1 = a.tranAprDepart.split('/');
        const arr2 = b.tranAprDepart.split('/');
        let aDate = new Date(parseInt(arr1[2]), parseInt(arr1[1]), parseInt(arr1[0])).getTime()
        let bDate = new Date(parseInt(arr2[2]), parseInt(arr2[1]), parseInt(arr2[0])).getTime()
        if (aDate < bDate) {
          return -1;
        } else if (aDate > bDate) {
          return 1;
        } else {
          return 0;
        }
      });
      this.tapsTransactionApprovalData.data = sorted; 
    } else {
      this.memberTransactionListSortDepart = false;
      let sortedByDepart = this.tapsTransactionApprovalData.data.sort((a: any, b: any) => {
        const arr1 = a.tranAprDepart.split('/');
        const arr2 = b.tranAprDepart.split('/');
        let aDate = new Date(parseInt(arr1[2]), parseInt(arr1[1]), parseInt(arr1[0])).getTime()
        let bDate = new Date(parseInt(arr2[2]), parseInt(arr2[1]), parseInt(arr2[0])).getTime()
        if (aDate < bDate) {
          return 1;
        } else if (aDate > bDate) {
          return -1;
        } else {
          return 0;
        }
      }); 
      this.tapsTransactionApprovalData.data = sortedByDepart; 
    }   
  }  

  // Sort by Agent Taps (Member - Transaction Approval)
  sortTransactionsByAgentTaps(){    
    if (this.memberTransactionListSortAgentTaps == false){
      this.memberTransactionListSortAgentTaps = true;
      let sorted = this.tapsTransactionApprovalData.data.sort((a: any, b: any) =>  {
        if (a.tranAprAgentTapsId < b.tranAprAgentTapsId) {
          return -1;
        } else if (a.tranAprAgentTapsId > b.tranAprAgentTapsId) {
          return 1;
        } else {
          return 0;
        }
      });
      this.tapsTransactionApprovalData.data = sorted; 
    } else {
      this.memberTransactionListSortAgentTaps = false;
      let sorted = this.tapsTransactionApprovalData.data.sort((a: any, b: any) => {
        if (a.tranAprAgentTapsId < b.tranAprAgentTapsId) {
          return 1;
        } else if (a.tranAprAgentTapsId > b.tranAprAgentTapsId) {
          return -1;
        } else {
          return 0;
        }
      }); 
      this.tapsTransactionApprovalData.data = sorted; 
    }
  }

  // Sort by Supplier (Member - Transaction Approval)
  sortTransactionsBySupplier(){    
    if (this.memberTransactionListSortSupplier == false){
      this.memberTransactionListSortSupplier = true;
      let sorted = this.tapsTransactionApprovalData.data.sort((a: any, b: any) => {
        if (a.tranAprSupplier < b.tranAprSupplier) {
          return -1;
        } else if (a.tranAprSupplier > b.tranAprSupplier) {
          return 1;
        } else {
          return 0;
        }
      });
      this.tapsTransactionApprovalData.data = sorted; 
    } else {
      this.memberTransactionListSortSupplier = false;
      let sorted = this.tapsTransactionApprovalData.data.sort((a: any, b: any) => {
        if (a.tranAprSupplier < b.tranAprSupplier) {
          return 1;
        } else if (a.tranAprSupplier > b.tranAprSupplier) {
          return -1;
        } else {
          return 0;
        }
      }); 
      this.tapsTransactionApprovalData.data = sorted; 
    }
  }

  // Sort by Supplier Taps (Member - Transaction Approval)
  sortTransactionsBySupplierTaps(){    
    if (this.memberTransactionListSortSupplierTaps == false){
      this.memberTransactionListSortSupplierTaps = true;
      let sorted = this.tapsTransactionApprovalData.data.sort((a: any, b: any) => {
        if (a.tranAprSupTapsId < b.tranAprSupTapsId) {
          return -1;
        } else if (a.tranAprSupTapsId > b.tranAprSupTapsId) {
          return 1;
        } else {
          return 0;
        }
      });
      this.tapsTransactionApprovalData.data = sorted; 
    } else {
      this.memberTransactionListSortSupplierTaps = false;
      let sorted = this.tapsTransactionApprovalData.data.sort((a: any, b: any) =>  {
        if (a.tranAprSupTapsId < b.tranAprSupTapsId) {
          return 1;
        } else if (a.tranAprSupTapsId > b.tranAprSupTapsId) {
          return -1;
        } else {
          return 0;
        }
      });
      this.tapsTransactionApprovalData.data = sorted; 
    }
  }

  // Sort by Ref (Member - Transaction Approval)
  sortTransactionsByRef(){    
    if (this.memberTransactionListSortRef == false){
      this.memberTransactionListSortRef = true;
      let sorted = this.tapsTransactionApprovalData.data.sort((a: any, b: any) => {
        if (a.tranAprRef < b.tranAprRef) {
          return -1;
        } else if (a.tranAprRef > b.tranAprRef) {
          return 1;
        } else {
          return 0;
        }
      }); 
      this.tapsTransactionApprovalData.data = sorted; 
    } else {
      this.memberTransactionListSortRef = false;
      let sorted = this.tapsTransactionApprovalData.data.sort((a: any, b: any) => {
        if (a.tranAprRef < b.tranAprRef) {
          return 1;
        } else if (a.tranAprRef > b.tranAprRef) {
          return -1;
        } else {
          return 0;
        }
      }); 
      this.tapsTransactionApprovalData.data = sorted; 
    }
  }

  // Sort by Client (Member - Transaction Approval)
  sortTransactionsByClient(){    
    if (this.memberTransactionListSortClient == false){
      this.memberTransactionListSortClient = true;
      let sorted = this.tapsTransactionApprovalData.data.sort((a: any, b: any) => {
        if (a.tranAprClient < b.tranAprClient) {
          return -1;
        } else if (a.tranAprClient > b.tranAprClient) {
          return 1;
        } else {
          return 0;
        }
      }); 
      this.tapsTransactionApprovalData.data = sorted; 
    } else {
      this.memberTransactionListSortClient = false;
      let sorted = this.tapsTransactionApprovalData.data.sort((a: any, b: any) => {
        if (a.tranAprClient < b.tranAprClient) {
          return 1;
        } else if (a.tranAprClient > b.tranAprClient) {
          return -1;
        } else {
          return 0;
        }
      }); 
      this.tapsTransactionApprovalData.data = sorted; 
    }
  }

  // Sort by Value (Member - Transaction Approval)
  sortTransactionsByValue(){ 
    if (this.memberTransactionListSortValue == false){
      this.memberTransactionListSortValue = true;
      let sorted = this.tapsTransactionApprovalData.data.sort((a: any, b: any) => {
        let firstValue = Number(parseFloat(a.tranAprValue.replace(/,/g, '')));
        let secondValue = Number(parseFloat(b.tranAprValue.replace(/,/g, '')));
        if (a.tranAprViewState == "TRANSACTION ACCEPTED" || b.tranAprViewState == "TRANSACTION ACCEPTED"){
          if (a.tranAprViewState != "TRANSACTION ACCEPTED"){
            firstValue = 0;
          }
          if (b.tranAprViewState != "TRANSACTION ACCEPTED"){
            secondValue = 0;
          }
        }
        if (Number(firstValue) < Number(secondValue)) {
            return -1;
        } else if (Number(firstValue) > Number(secondValue)) {
            return 1;
        } else {
            return 0;
        }
    }); 
      this.tapsTransactionApprovalData.data = sorted; 
    } else {
      this.memberTransactionListSortValue = false;
      let sorted = this.tapsTransactionApprovalData.data.sort((a: any, b: any) => {
        let firstValue = Number(parseFloat(a.tranAprValue.replace(/,/g, '')));
        let secondValue = Number(parseFloat(b.tranAprValue.replace(/,/g, '')));
        if (a.tranAprViewState == "TRANSACTION ACCEPTED" || b.tranAprViewState == "TRANSACTION ACCEPTED"){
          if (a.tranAprViewState != "TRANSACTION ACCEPTED"){
            firstValue = 0;
          }
          if (b.tranAprViewState != "TRANSACTION ACCEPTED"){
            secondValue = 0;
          }
        }
        if (Number(firstValue) < Number(secondValue)) {
            return 1;
        } else if (Number(firstValue) > Number(secondValue)) {
            return -1;
        } else {
            return 0;
        }
      });
      this.tapsTransactionApprovalData.data = sorted; 
    }
  }

  // Sort by State (Member - Transaction Approval)
  sortTransactionsByState(){    
    if (this.memberTransactionListSortState == false){
      this.memberTransactionListSortState = true;
      let sorted = this.tapsTransactionApprovalData.data.sort((a: any, b: any) => {
        if (a.tranAprViewState < b.tranAprViewState) {
          return -1;
        } else if (a.tranAprViewState > b.tranAprViewState) {
          return 1;
        } else {
          return 0;
        }
      }); 
      this.tapsTransactionApprovalData.data = sorted; 
    } else {
      this.memberTransactionListSortState = false;
      let sorted = this.tapsTransactionApprovalData.data.sort((a: any, b: any) => {
        if (a.tranAprViewState < b.tranAprViewState) {
          return 1;
        } else if (a.tranAprViewState > b.tranAprViewState) {
          return -1;
        } else {
          return 0;
        }
      }); 
      this.tapsTransactionApprovalData.data = sorted; 
    }
  }

  // Sort by Booking Reference (Member - Transaction Approval)
  sortTransactionsByBookingReference(){
    if (this.memberTransactionListSortBookingReference == false){
      this.memberTransactionListSortBookingReference = true;
      let sorted = this.tapsTransactionApprovalData.data.sort((a: any, b: any) => {
        if (a.bookingReference < b.bookingReference) {
          return -1;
        } else if (a.bookingReference > b.bookingReference) {
          return 1;
        } else {
          return 0;
        }
      }); 
      this.tapsTransactionApprovalData.data = sorted; 
    } else {
      this.memberTransactionListSortBookingReference = false;
      let sorted = this.tapsTransactionApprovalData.data.sort((a: any, b: any) => {
        if (a.bookingReference < b.bookingReference) {
          return 1;
        } else if (a.bookingReference > b.bookingReference) {
          return -1;
        } else {
          return 0;
        }
      }); 
      this.tapsTransactionApprovalData.data = sorted; 
    }
  }

  // Sort by Match Type (Member - Transaction Approval)
  sortTransactionsByMatchType(){
    if (this.memberTransactionListSortMatchType == false){
      this.memberTransactionListSortMatchType = true;
      let sorted = this.tapsTransactionApprovalData.data.sort((a: any, b: any) => {
        if (a.matchType < b.matchType) {
          return -1;
        } else if (a.matchType > b.matchType) {
          return 1;
        } else {
          return 0;
        }
      }); 
      this.tapsTransactionApprovalData.data = sorted; 
    } else {
      this.memberTransactionListSortMatchType = false;
      let sorted = this.tapsTransactionApprovalData.data.sort((a: any, b: any) => {
        if (a.matchType < b.matchType) {
          return 1;
        } else if (a.matchType > b.matchType) {
          return -1;
        } else {
          return 0;
        }
      }); 
      this.tapsTransactionApprovalData.data = sorted; 
    }
  }


  //////////////////////////////////
  //
  // Member Functionalities Above 
  //
  //////////////////////////////////


  //////////////////////////////////
  //
  // Supplier Functionalities Below 
  //
  //////////////////////////////////


  // INITIAL SETUP FOR SUPPLIER
  setUpSupplierPage(){
    this.loggedInUserType = "Supplier";    
    this.setupSupplierData();
    this.checkUploadAvailability();
    this.setupSuppliersAgentsList();
    this.setUpDatePeriods();
  }

  // Setups the current logged in supplier with their supplierName and  misttaCode
  setupSupplierData(){
    if (Session.mySession.getUser().supplierName != null && Session.mySession.getUser().supplierName != ""){
      this.userSupplierName = Session.mySession.getUser().supplierName;
    } else {
      this.userSupplierName = this.userName;
    }
    let request = { 
      supplierName: this.userSupplierName,
      token: Session.mySession.get('user').token 
    };
    this.supplierService.getSupplierList(request).then((suppliers: any) => {
      if (suppliers.status != "OK") {
        this.sendMessageToDialog('', 'SinGS could not load suppliers (' + suppliers.status + ')', '');
      } else {
        this.userSupplierMisttaCode = suppliers.data[0].misttaCode;
        this.userSupplierPaymentDays = suppliers.data[0].tapsPaymentDays;
        if (suppliers.data[0].atolNo != null && suppliers.data[0].atolNo != ""){
          this.userSupplierOnAtol = true;
        }
        if (suppliers.data[0].tapsTradeCode != null && suppliers.data[0].tapsTradeCode != ""){
          this.userSupplierTapsTradeCode = suppliers.data[0].tapsTradeCode;
        }
        this.userSupplierTapsExcludeFlag = suppliers.data[0].tapsExcludeFlag;
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2001S)', error);
    }).then(()=>{this.pageLoaded=true;});
  }

  // Checks if the current day is less than the next From and To Upload Deadlines.
  checkUploadAvailability(){
    let currentDate = moment();
    let tempAvailableFrom = moment(this.getNextDayOfTheWeek('sat', true, currentDate.toDate()));
    this.uploadFromDate = tempAvailableFrom.format('DD/MM/YYYY');
    let tempAvailableTo = moment(this.getNextDayOfTheWeek('wed', true, tempAvailableFrom.toDate()));
    this.uploadToDate = tempAvailableTo.format('DD/MM/YYYY');
    if (this.uploadDateCheck(tempAvailableFrom.toDate(), tempAvailableTo.toDate(), currentDate.toDate())) {
      this.uploadAvailability = true;
    } else {
      this.uploadAvailability = false;
    }
  }

  checkLateAdjustmentUploadAvailability(): boolean {
    const now = moment(); // Get the current date and time with Moment.js
    const dayOfWeek = now.day(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
    const hours = now.hour(); // Get the current hour (24-hour format)

    // Check if the current day is Saturday (6), Sunday (0), Monday (1), Tuesday (2),
    // or Wednesday (3) before 16:00 (4 PM)
    if (dayOfWeek === 6 || dayOfWeek === 0 || dayOfWeek === 1 || dayOfWeek === 2 || (dayOfWeek === 3 && hours < 16)) {
      return true;
    }
    return false;
  }

  // If uploaded file from Supplier Upload page is TXT, we load it from here
  loadSupplierTxtFile(actualFile: any){
    this.pageLoaded = false; 
    this.typeOfFileUpload = "TXT";
    let allTransactions:any = [];
    let rejectedTransactions:any = [];
    let fileText:any = [];    
    let overallSumOfValue:any = 0;
    let numOfTransactions:any = 0;

    let currentDate = moment();
    let tempAvailableFrom = this.getNextDayOfTheWeek('sat', true, currentDate.toDate());
    let tempUploadPeriod = this.subtrackDays(1, tempAvailableFrom);
    let uploadPeriod = this.convertDate(tempUploadPeriod);

    let tranState = "Imported";
    let tranFailure = "None";
    let fileFormatCheck = false;
    let tempUploadTransactions = new MatTableDataSource<any>();
    let tempUploadRejectedTransactions = new MatTableDataSource<any>();
    this.readTxtFile(actualFile).then((output:any)=>{
      fileText = [...output];
      if (fileText.length == 1){
        fileText = String(fileText).split('\r');
        if (fileText[fileText.length-1].trim() == ""){
          fileText.splice(-1);
        }
      }
      fileFormatCheck = this.checkIfValidFileFormatTxt(fileText[0],fileText[fileText.length-1]);
      if (fileFormatCheck == true){
        fileText.forEach((row:any)=>{
          let transactionInfo:any = this.convertTxtTransactionToCsv(row);
          if (transactionInfo.validTransaction == true && transactionInfo.rejectedTransaction == false){
            let acceptedTransaction = this.setupAcceptedTransactionRow(transactionInfo.transactionRow.split(","),false,false,false,transactionInfo.agentName);
            allTransactions.push(acceptedTransaction);
            overallSumOfValue = Number(overallSumOfValue) + Number(acceptedTransaction.amount);
            numOfTransactions = numOfTransactions + 1;  
          } else if (transactionInfo.validTransaction == true && transactionInfo.rejectedTransaction == true){
            let transactionRow = transactionInfo.transactionRow.split(",");
            let transactionFailureMessage = this.checkTransactionRowForFailures(transactionRow);
            // Adding a check in case of not found failure to be received by the transactionInfo
            if (transactionFailureMessage == ""){
              transactionFailureMessage = transactionInfo.rejectedReason
            }
            transactionRow.push(transactionFailureMessage);
            rejectedTransactions.push(transactionRow);
            let rejectedTransaction = this.setupRejectedTransactionRow(transactionRow,false,false,false,transactionInfo.agentName,transactionFailureMessage);
            allTransactions.push(rejectedTransaction);
            if (this.isAFloatNumber(rejectedTransaction.amount)){
              overallSumOfValue = Number(overallSumOfValue) + Number(rejectedTransaction.amount);
            }
            numOfTransactions = numOfTransactions + 1; 
          }
        });
      }
    }).then(()=>{
      if (fileFormatCheck == true) {
        // Then check for duplicates within the list
        let sizeOfList:any = allTransactions.length;
        if (sizeOfList > 0){
          for (let counterX = 0; counterX < sizeOfList; counterX++){
            let tranCounter:any = 0;
            let tempTransactionX:any = allTransactions[counterX];
            for (let counterY = 0; counterY < sizeOfList; counterY++){
              let tempTransactionY:any = allTransactions[counterY];
              if ((tempTransactionX.reference == tempTransactionY.reference) 
                  && (tempTransactionX.departure == tempTransactionY.departure)
                  && (tempTransactionX.amount == tempTransactionY.amount)
                  && (tempTransactionX.client == tempTransactionY.client)
                  && (tempTransactionX.memberTradeCode == tempTransactionY.memberTradeCode) 
                  && (tempTransactionX.state == tempTransactionY.state)){
                tranCounter = Number(tranCounter) + 1;
              }
            }
            if (tranCounter >= 2){
              tranFailure = "Some Duplicated Transactions";
              allTransactions[counterX].state = 'Rejected'
              allTransactions[counterX].warning = 'Duplicated Transaction'              
              rejectedTransactions.push(allTransactions[counterX]);
            }
          }
        }
        // Check if tranState will be 'Imported' or 'Failed' 
        let rejectedTransactionsCounter = 0;
        let acceptedTransactionsCounter = 0;
        allTransactions.forEach((tempTrans:any)=>{
          if (tempTrans.state != 'Accepted') {
            rejectedTransactionsCounter = rejectedTransactionsCounter + 1
          } else {
            acceptedTransactionsCounter = acceptedTransactionsCounter + 1
          }
        })
        if (rejectedTransactionsCounter > 0 && acceptedTransactionsCounter > rejectedTransactionsCounter){
          if (tranFailure == "Some Duplicated Transactions"){
            tranFailure = 'Some Rejected / Duplicated Transactions'
          } else {
            tranFailure = 'Some Rejected Transactions'
          }
        } 
        if (rejectedTransactionsCounter > acceptedTransactionsCounter){
          if (tranFailure == "Some Duplicated Transactions"){
            tranFailure = 'Some Rejected / Duplicated Transactions'
          } else {
            tranFailure = 'Some Rejected Transactions'
          }
        }
        if (acceptedTransactionsCounter == 0){
          tranState = 'Failed'
          tranFailure = 'Rejected Transactions'
        }
        // Check once again if there are any available transactions
        if (allTransactions.length > 0){

          // Setup the transaction report page
          allTransactions.forEach((row:any)=>{
            // ONE FINAL CHECK OF DEPARTURE DATE
            if (this.isABritishDateFormat(row.departure) == false){
              const convertedDeptDate = this.convertDateToBritishFormat(row.departure);
              if (convertedDeptDate.startsWith('Invalid date')) {
                const tempInvalidDeptDate = String(row.departure).slice(0, 15);
                if (row.warning === "None") {
                  // Attempt conversion or set to default invalid date
                  row.departure = "01/01/2000";
                  row.state = "Rejected";
                  row.warning = `Invalid Dept Date Format (${tempInvalidDeptDate})`;
                } else {
                  row.departure = "01/01/2000";
                  row.state = "Rejected";
                  row.warning += ` and Dept Date (${row.departure})`;
                }
              } else {
                row.departure = convertedDeptDate; // Set the converted date if valid
              }
            }
            let tempRow:any = {
              tranAgent: row.memberName,
              tranTapsId: String(row.memberTradeCode).toUpperCase(),
              tranRef: row.reference,
              tranDepart: row.departure,
              tranClient: row.client, 
              tranWarning: row.warning,
              tranValue: parseFloat(row.amount).toFixed(2), 
              tranAdjust: this.checkIfAdjusted(row.approval), 
              tranCollectedValue: row.collected, 
              tranCode: row.code, 
              tranName: row.reason, 
              tranState: row.state
            };

             //Seperate Rejected and Accepted Transactions 
             if (tempRow.tranState == "Accepted" || tempRow.tranState == "Paid" || tempRow.tranState == "Collected"){   
               tempUploadTransactions.data.push(tempRow);  
               // Check for approved and unapproved amount
               if (this.isAFloatNumber(tempRow.tranValue)){
                this.viewOverallAcceptedAmount = Number(this.viewOverallAcceptedAmount) + Number(tempRow.tranValue);
                 if (tempRow.tranAdjust == "Yes"){
                   this.viewAdjustments = Number(this.viewAdjustments) + Number(tempRow.tranValue);
                  } else {
                   this.viewToPay =  Number(this.viewToPay) + Number(tempRow.tranValue);
                  }   
               }                           
             } else if (tempRow.tranState == "Rejected"){                  
               tempRow.tranFailure = tempRow.tranWarning;
               if (this.isAFloatNumber(tempRow.tranValue))
               {                        
                 this.viewOverallRejectedAmount = Number(this.viewOverallRejectedAmount) + Number(tempRow.tranValue);                    
               }            
               tempUploadRejectedTransactions.data.push(tempRow);
             }        
           });

          // Setup overall stat values
          this.tapsUploadTransactionData.data = tempUploadTransactions.data;
          this.tapsUploadTransactionRejectedData.data = tempUploadRejectedTransactions.data;
          this.viewAdjustmentUploadTapsId = this.userSupplierMisttaCode;
          this.viewAdjustmentUploadSupplierName = this.userSupplierName;
          this.viewAllTransactions = [...allTransactions];
          this.viewRejectedTransactions = rejectedTransactions
          this.viewTransactionState = tranState;
          this.viewTranFailureState = tranFailure;

          this.viewUploadDate = moment().format('L');

          this.viewNumOfTransactions = numOfTransactions;
          this.viewAdjustments = parseFloat(this.viewAdjustments).toFixed(2);
          this.viewOverallRejectedAmount = parseFloat(this.viewOverallRejectedAmount).toFixed(2);
          this.viewOverallAcceptedAmount = parseFloat(this.viewOverallAcceptedAmount).toFixed(2);    
          this.viewValue = Number(this.viewOverallAcceptedAmount) + Number(this.viewOverallRejectedAmount);
          this.viewValue = parseFloat(this.viewValue).toFixed(2);    
          this.setupToPayAmount(tranState); 
          if (this.userSupplierPaymentDays == ""){
            this.suppliersListData.data.forEach((supplier:any)=>{
              if (supplier.misttaCode == this.userSupplierMisttaCode){
                this.generateAgentReviewPeriodAndPaymentDue(uploadPeriod,supplier.tapsPaymentDays);
                if (supplier.atolNo != null && supplier.atolNo != ""){
                  this.userSupplierOnAtol = true;
                } else {
                  this.userSupplierOnAtol = false;
                }
              }
            });
          } else {
            this.generateAgentReviewPeriodAndPaymentDue(uploadPeriod,this.userSupplierPaymentDays);
          }          
          this.pageLoaded = true;
          this.lateAdjustmentUploadedFile = true;
          this.supplierUploadedFile = true;
          const preTranOverallValue = this.viewValue;
          const preNumOfTransactions = this.viewNumOfTransactions;

          const preTempCurrentDate = moment();
          const preTempAvailableFrom = this.getNextDayOfTheWeek('sat', true, preTempCurrentDate.toDate());
          const preTempUploadPeriod = this.subtrackDays(1, preTempAvailableFrom);
          const preUploadPeriod = this.convertDate(preTempUploadPeriod);        
          const preCollectionDate = this.convertDate(this.addDays(7, preTempUploadPeriod));
          const prePaymentDate = this.convertDate(this.addDays(this.userSupplierPaymentDays + 7, preTempUploadPeriod));

          const preTranState = "Importing";
          const preTranFailure = "Rejected by User";
          const preUploadSupplierMisttaCode = this.viewAdjustmentUploadTapsId;
          const preUploadSupplierName = this.viewAdjustmentUploadSupplierName;
          const preOriginalID = "Original";   
          const preTranCollected = 0;
          const preAgentName = "";
          const preAgentTradeCode = "";
          const preRequest: any = {             
            company: Session.mySession.getUser().company,
            tradeCode: Session.mySession.getUser().tradeCode,
            operation: Session.mySession.getUser().operation,
            agentName: preAgentName,
            agentTradeCode: preAgentTradeCode,
            supplierName: preUploadSupplierName,
            supplierTradeCode: preUploadSupplierMisttaCode,
            misttaCode: preUploadSupplierMisttaCode,
            uploadPeriod: preUploadPeriod,
            collectionDate: preCollectionDate,
            paymentDate: prePaymentDate,
            tranValue: preTranOverallValue,
            tranCollected: preTranCollected,
            tranFailure: preTranFailure,
            tranState: preTranState,
            numOfTransactions: preNumOfTransactions,
            listOfTransactions: [],
            transactionOriginalID: preOriginalID,
            token: Session.mySession.get('user').token
          }
          this.pageLoaded = false;
          this.tapsService.preCreateTapsUpload(preRequest).then((output:any)=>{
            this.pageLoaded = true;
            if (output.status == "OK"){
              // Setup response message
              if (tranFailure == "None" && rejectedTransactions.length == 0){
                this.sendMessageToDialog("Successfully loaded with the following transaction State : " + tranState,"","");
              } else if (tranFailure == "Some Duplicated Transactions") {
                let outputMessage = "Successfully loaded with the following transaction State : " + tranState;
                let rejectedTransactionsList = "There are duplicated transactions in the uploaded file.";
                let finalMessage:any = outputMessage + '<br><br>' + rejectedTransactionsList;
                this.viewTransactionFailure = rejectedTransactionsList;
                this.sendMessageToDialog(finalMessage,"","");
              } else {
                let outputMessage = "Successfully loaded with the following transaction State : " + tranState;
                let rejectedTransactionsList = "Warning : " + tranFailure + "<br>Not all records were successfully accepted.<br>The following were rejected:";
                rejectedTransactions.forEach((transaction:any)=>{
                  rejectedTransactionsList = rejectedTransactionsList + '<br><br>Transaction Failed<br>' + this.returnListedTransactionValues(transaction);
                });
                let finalMessage:any = outputMessage + '<br><br>' + rejectedTransactionsList;
                this.viewTransactionFailure = rejectedTransactionsList;
                this.sendMessageToDialog(finalMessage,"","");
              }
              this.preImportedTransactionID = output["res"].transactionID;
            } else {
              this.sendMessageToDialog("",output["res"],"");
              this.clearViewUploadFile()
              this.switchView("homePage");
            }
          });
        } else {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (No Transactions Found)', "");
        }
      } else {
        this.pageLoaded = true;
        this.sendMessageToDialog('SinGS could not complete your request at this time (Invalid Header/Footer Format)<br><br>Reason: ' + this.uploadFailedReason + '', '', "");
      } 
    });    
  }

  // If uploaded file from Supplier Upload page is CSV, we load it from here
  loadSupplierCSVFile(actualFile: any){
    this.pageLoaded = false; 
    this.typeOfFileUpload = "CSV";
    let allTransactions:any = [];
    let rejectedTransactions:any = [];
    let validHeaderFormat = false;      
    let overallSumOfValue:any = 0;
    let numOfTransactions:any = 0;

    let currentDate = moment();
    let tempAvailableFrom = this.getNextDayOfTheWeek('sat', true, currentDate.toDate());
    let tempUploadPeriod = this.subtrackDays(1, tempAvailableFrom);
    let uploadPeriod = this.convertDate(tempUploadPeriod);

    let tranState = "Imported";
    let tranFailure = "None";
    let tempUploadTransactions = new MatTableDataSource<any>();
    let tempUploadRejectedTransactions = new MatTableDataSource<any>();
    this.ngxCsvParser
    .parse(actualFile, { header: false, delimiter: ',' })
    .pipe(
      map((result: any) => result.filter((row:any) => row.some((cell:any) => cell.trim() !== '')))
    )
    .subscribe(
      (result:any) => {
        validHeaderFormat = this.checkIfValidFileFormatCsv(result);
        if (validHeaderFormat == true) {
          result.forEach((row:any)=>{
            let checkForTransactionRow = this.isATransactionRow(row);
            if (checkForTransactionRow == true) {
              let checkTransactionRowForFailures:any = this.checkTransactionRowForFailures(row);
              if (checkTransactionRowForFailures == ""){
                let tempAgentName:any = "N/A" 
                if (row?.length > 2){
                  tempAgentName = this.checkIfMemberExist(row[1]);
                  if (tempAgentName == false){
                    tempAgentName = "N/A"
                  }
                }
                let newTempTransaction = this.setupAcceptedTransactionRow(row,false,false,false,tempAgentName);
                allTransactions.push(newTempTransaction);
                overallSumOfValue = Number(overallSumOfValue) + Number(newTempTransaction.amount);
                numOfTransactions = numOfTransactions + 1;  
              } else {    
                let tempAgentName:any = "N/A" 
                if (row?.length > 2){
                  tempAgentName = this.checkIfMemberExist(row[1]);
                  if (tempAgentName == false){
                    tempAgentName = "N/A"
                  }
                }
                row.push(checkTransactionRowForFailures);
                rejectedTransactions.push(row);
                let newTempTransaction = this.setupRejectedTransactionRow(row,false,false,false,tempAgentName,checkTransactionRowForFailures);
                allTransactions.push(newTempTransaction);
                if (this.isAFloatNumber(newTempTransaction.amount)){
                  overallSumOfValue = Number(overallSumOfValue) + Number(newTempTransaction.amount);
                }
                numOfTransactions = numOfTransactions + 1;  
              }       
            }
            // else if (checkForTransactionRow == "Missing Data"){
            //   //  KP : We skip 'Missing Data' Transactions as they will cause issues to DB
            //   //
            //   // let tempAgentName:any = "N/A"
            //   // if (row?.length > 2){
            //   //   tempAgentName = this.checkIfMemberExist(row[1]);
            //   //   if (tempAgentName == false){
            //   //     tempAgentName = "N/A"
            //   //   }
            //   // } 
            //   // let checkTransactionRowForFailures:any = this.checkTransactionRowForFailures(row);
            //   // row.push(checkTransactionRowForFailures);
            //   // rejectedTransactions.push(row);
            //   // let newTempTransaction = this.setupRejectedTransactionRow(row,false,false,false,tempAgentName,checkTransactionRowForFailures);
            //   // allTransactions.push(newTempTransaction);
            //   // if (this.isAFloatNumber(newTempTransaction.amount)){
            //   //   overallSumOfValue = Number(overallSumOfValue) + Number(newTempTransaction.amount);
            //   // }
            //   // numOfTransactions = numOfTransactions + 1;
            // }                             
          });

          // Then check for duplicates within the list
          let sizeOfList:any = allTransactions.length;
          if (sizeOfList > 0){
            for (let counterX = 0; counterX < sizeOfList; counterX++){
              let tranCounter:any = 0;
              let tempTransactionX:any = allTransactions[counterX];
              for (let counterY = 0; counterY < sizeOfList; counterY++){
                let tempTransactionY:any = allTransactions[counterY];
                if ((tempTransactionX.reference == tempTransactionY.reference) 
                    && (tempTransactionX.departure == tempTransactionY.departure)
                    && (tempTransactionX.amount == tempTransactionY.amount)
                    && (tempTransactionX.client == tempTransactionY.client)
                    && (tempTransactionX.memberTradeCode == tempTransactionY.memberTradeCode) 
                    && (tempTransactionX.state == tempTransactionY.state)){
                  tranCounter = Number(tranCounter) + 1;
                }
              }
              if (tranCounter >= 2){
                tranFailure = "Some Duplicated Transactions";
                allTransactions[counterX].state = 'Rejected'
                allTransactions[counterX].warning = 'Duplicated Transaction'                
                rejectedTransactions.push(allTransactions[counterX]);
              }
            }
          }

          // Check if tranState will be 'Imported' or 'Failed' 
          let rejectedTransactionsCounter = 0;
          let acceptedTransactionsCounter = 0;
          allTransactions.forEach((tempTrans:any)=>{
            if (tempTrans.state != 'Accepted') {
              rejectedTransactionsCounter = rejectedTransactionsCounter + 1
            } else {
              acceptedTransactionsCounter = acceptedTransactionsCounter + 1
            }
          })
          if (rejectedTransactionsCounter > 0 && acceptedTransactionsCounter > rejectedTransactionsCounter){
            if (tranFailure == "Some Duplicated Transactions"){
              tranFailure = 'Some Rejected / Duplicated Transactions'
            } else {
              tranFailure = 'Some Rejected Transactions'
            }
          } 
          if (rejectedTransactionsCounter > acceptedTransactionsCounter){
            if (tranFailure == "Some Duplicated Transactions"){
              tranFailure = 'Some Rejected / Duplicated Transactions'
            } else {
              tranFailure = 'Some Rejected Transactions'
            }
          }
          if (acceptedTransactionsCounter == 0){
            tranState = 'Failed'
            tranFailure = 'Rejected Transactions'
          }

          // Setup the transaction report page
          allTransactions.forEach((row:any)=>{
            // ONE FINAL CHECK OF DEPARTURE DATE
            if (this.isABritishDateFormat(row.departure) == false){
              const convertedDeptDate = this.convertDateToBritishFormat(row.departure);
              if (convertedDeptDate.startsWith('Invalid date')) {
                const tempInvalidDeptDate = String(row.departure).slice(0, 15);
                if (row.warning === "None") {
                  // Attempt conversion or set to default invalid date
                  row.departure = "01/01/2000";
                  row.state = "Rejected";
                  row.warning = `Invalid Dept Date Format (${tempInvalidDeptDate})`;
                } else {
                  row.departure = "01/01/2000";
                  row.state = "Rejected";
                  row.warning += ` and Dept Date (${row.departure})`;
                }
              } else {
                row.departure = convertedDeptDate; // Set the converted date if valid
              }
            }
            let tempRow:any = {
              tranAgent: row.memberName,
              tranTapsId: String(row.memberTradeCode).toUpperCase(),
              tranRef: row.reference,
              tranDepart: row.departure,
              tranClient: row.client, 
              tranWarning: row.warning,
              tranValue: parseFloat(row.amount).toFixed(2), 
              tranAdjust: this.checkIfAdjusted(row.approval), 
              tranCollectedValue: row.collected, 
              tranCode: row.code, 
              tranName: row.reason, 
              tranState: row.state
            };
            
             //Seperate Rejected and Accepted Transactions 
             if (tempRow.tranState == "Accepted" || tempRow.tranState == "Paid" || tempRow.tranState == "Collected"){
               tempUploadTransactions.data.push(tempRow);  
               // Check for approved and unapproved amount
               if (this.isAFloatNumber(tempRow.tranValue)){
                this.viewOverallAcceptedAmount = Number(this.viewOverallAcceptedAmount) + Number(tempRow.tranValue);
                 if (tempRow.tranAdjust == "Yes"){
                   this.viewAdjustments = Number(this.viewAdjustments) + Number(tempRow.tranValue);
                  } else {
                   this.viewToPay =  Number(this.viewToPay) + Number(tempRow.tranValue);
                  }   
               }                           
             } else if (tempRow.tranState == "Rejected"){                  
               tempRow.tranFailure = tempRow.tranWarning;
               if (this.isAFloatNumber(tempRow.tranValue))
               {                        
                 this.viewOverallRejectedAmount = Number(this.viewOverallRejectedAmount) + Number(tempRow.tranValue);                    
               }            
               tempUploadRejectedTransactions.data.push(tempRow);
             }        
           });

          // Setup overall stat values
          this.tapsUploadTransactionData.data = tempUploadTransactions.data;
          this.tapsUploadTransactionRejectedData.data = tempUploadRejectedTransactions.data;
          this.viewAdjustmentUploadTapsId = this.userSupplierMisttaCode;
          this.viewAdjustmentUploadSupplierName = this.userSupplierName;
          this.viewAllTransactions = [...allTransactions];
          this.viewRejectedTransactions = rejectedTransactions
          this.viewTransactionState = tranState;
          this.viewTranFailureState = tranFailure;

          this.viewUploadDate = moment().format('L');

          this.viewNumOfTransactions = numOfTransactions;
          this.viewAdjustments = parseFloat(this.viewAdjustments).toFixed(2);
          this.viewOverallRejectedAmount = parseFloat(this.viewOverallRejectedAmount).toFixed(2);
          this.viewOverallAcceptedAmount = parseFloat(this.viewOverallAcceptedAmount).toFixed(2);    
          this.viewValue = Number(this.viewOverallAcceptedAmount) + Number(this.viewOverallRejectedAmount);
          this.viewValue = parseFloat(this.viewValue).toFixed(2);    
          this.setupToPayAmount(tranState);  
          if (this.userSupplierPaymentDays == ""){
            this.suppliersListData.data.forEach((supplier:any)=>{
              if (supplier.misttaCode == this.userSupplierMisttaCode){
                this.generateAgentReviewPeriodAndPaymentDue(uploadPeriod,supplier.tapsPaymentDays);
                if (supplier.atolNo != null && supplier.atolNo != ""){
                  this.userSupplierOnAtol = true;
                } else {
                  this.userSupplierOnAtol = false;
                }
              }
            });
          } else {
            this.generateAgentReviewPeriodAndPaymentDue(uploadPeriod,this.userSupplierPaymentDays);
          }          
          this.pageLoaded = true;
          this.lateAdjustmentUploadedFile = true;
          this.supplierUploadedFile = true;
          const preTranOverallValue = this.viewValue;
          const preNumOfTransactions = this.viewNumOfTransactions;

          const preTempCurrentDate = moment();
          const preTempAvailableFrom = this.getNextDayOfTheWeek('sat', true, preTempCurrentDate.toDate());
          const preTempUploadPeriod = this.subtrackDays(1, preTempAvailableFrom);
          const preUploadPeriod = this.convertDate(preTempUploadPeriod);         
          const preCollectionDate = this.convertDate(this.addDays(7, preTempUploadPeriod));
          const prePaymentDate = this.convertDate(this.addDays(this.userSupplierPaymentDays + 7, preTempUploadPeriod));

          const preTranState = "Importing";
          const preTranFailure = "Rejected by User";
          const preUploadSupplierMisttaCode = this.viewAdjustmentUploadTapsId;
          const preUploadSupplierName = this.viewAdjustmentUploadSupplierName;
          const preOriginalID = "Original";   
          const preTranCollected = 0;
          const preAgentName = "";
          const preAgentTradeCode = "";
          const preRequest: any = {             
            company: Session.mySession.getUser().company,
            tradeCode: Session.mySession.getUser().tradeCode,
            operation: Session.mySession.getUser().operation,
            agentName: preAgentName,
            agentTradeCode: preAgentTradeCode,
            supplierName: preUploadSupplierName,
            supplierTradeCode: preUploadSupplierMisttaCode,
            misttaCode: preUploadSupplierMisttaCode,
            uploadPeriod: preUploadPeriod,
            collectionDate: preCollectionDate,
            paymentDate: prePaymentDate,
            tranValue: preTranOverallValue,
            tranCollected: preTranCollected,
            tranFailure: preTranFailure,
            tranState: preTranState,
            numOfTransactions: preNumOfTransactions,
            listOfTransactions: [],
            transactionOriginalID: preOriginalID,
            token: Session.mySession.get('user').token
          }
          this.pageLoaded = false;
          this.tapsService.preCreateTapsUpload(preRequest).then((output:any)=>{
            this.pageLoaded = true;
            if (output.status == "OK"){
              // Setup response message
              if (tranFailure == "None" && rejectedTransactions.length == 0){
                this.sendMessageToDialog("Successfully loaded with the following transaction State : " + tranState,"","");
              } else if (tranFailure == "Some Duplicated Transactions") {
                let outputMessage = "Successfully loaded with the following transaction State : " + tranState;
                let rejectedTransactionsList = "There are duplicated transactions in the uploaded file.";
                let finalMessage:any = outputMessage + '<br><br>' + rejectedTransactionsList;
                this.viewTransactionFailure = rejectedTransactionsList;
                this.sendMessageToDialog(finalMessage,"","");
              } else {
                let outputMessage = "Successfully loaded with the following transaction State : " + tranState;
                let rejectedTransactionsList = "Warning : " + tranFailure + "<br>Not all records were successfully accepted.<br>The following were rejected:";
                rejectedTransactions.forEach((transaction:any)=>{
                  rejectedTransactionsList = rejectedTransactionsList + '<br><br>Transaction Failed<br>' + this.returnListedTransactionValues(transaction);
                });
                let finalMessage:any = outputMessage + '<br><br>' + rejectedTransactionsList;
                this.viewTransactionFailure = rejectedTransactionsList;
                this.sendMessageToDialog(finalMessage,"","");
              }
              this.preImportedTransactionID = output["res"].transactionID;
            } else {
              this.sendMessageToDialog("",output["res"],"");
              this.clearViewUploadFile()
              this.switchView("homePage");
            }
          });
        } else {
          this.sendMessageToDialog('SinGS could not complete your request at this time (Invalid Header/Footer or File Format)<br><br>Reason: ' + this.uploadFailedReason + '', '', "");
        }          
      },
      (error: NgxCSVParserError) => {
        this.sendMessageToDialog('', 'Invalid file format. You need to upload a CSV file.', error);
      });  
  }

  // If uploaded file from Supplier Upload page is XSLX, we convert it to CSV from here
  convertXLSXFileToCSV(actualFile: any): Promise<string[]> {
    return new Promise((resolve, reject) => {
    
      const reader = new FileReader();
  
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[sheetName];

        const aWeirdDateFormat = /^(\d{1,2})-[a-zA-Z]{3}-\d{2}$/;
        const americanDateFormat = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/(\d{2})$/;
        const digitDateFormat = /^\d+(\.\d+)?$/;
        const numberTextFormat = /^(\d{1,3}(,\d{3})*|\d{1,3}(,\d{3})*\.\d+)$/;

        const formatDateToDDMMYYYY = (date: Date | string): string => {
          if (date instanceof Date) {
            return moment(date).format('DD/MM/YYYY');
          } else {
            return moment(date, this.possibleDateFormats).format('DD/MM/YYYY');
          }
        };

        // Initialize an array to hold non-empty rows
        let nonEmptyRows:any = [];
        
        XLSX.utils.sheet_to_json(worksheet, { header: 1 }).forEach((row: any, index: number) => {
          if (row.join('').trim() !== '') {
              if (index === 0) {
                  // Setup Header Calendar Date From XLSX 
                  if (row[2]){
                    const dateAttribute = row[2];
                    let tempHeaderDate: moment.Moment | null = null;
                    if (aWeirdDateFormat.test(dateAttribute)) {
                      tempHeaderDate = moment(dateAttribute, 'DD-MMM-YY', true);
                    } else if (americanDateFormat.test(dateAttribute)) {
                      tempHeaderDate = moment(dateAttribute, 'MM/DD/YY', true);
                    } else if (digitDateFormat.test(dateAttribute)) {
                      tempHeaderDate = moment('1900-01-01').add(parseInt(dateAttribute)-2, 'days');
                    } else {
                      tempHeaderDate = moment(dateAttribute, moment.ISO_8601, true);
                    }
                    if (tempHeaderDate.isValid()) {
                      row[2] = formatDateToDDMMYYYY(tempHeaderDate.toDate());
                    }
                  }
              } else if (index === XLSX.utils.sheet_to_json(worksheet, { header: 1 }).length - 1) {
                  // For the last row, set the first column to 'ZZZ'
              } else {
                  // FOR TRA Rows
                  // Setup Departure Date From XLSX 
                  if (row[4]){
                    const dateAttribute = row[4];
                    let tempTRADate: moment.Moment | null = null;
                    if (aWeirdDateFormat.test(dateAttribute)) {
                      tempTRADate = moment(dateAttribute, 'DD-MMM-YY', true);
                    } else if (americanDateFormat.test(dateAttribute)) {
                      tempTRADate = moment(dateAttribute, 'MM/DD/YY', true);
                    } else if (digitDateFormat.test(dateAttribute)) {
                      tempTRADate = moment('1900-01-01').add(parseInt(dateAttribute)-2, 'days');
                    } else if (typeof dateAttribute === 'string') {  
                      tempTRADate = moment(dateAttribute, moment.ISO_8601, true);
                    }
                    if (tempTRADate?.isValid()) {
                      row[4] = formatDateToDDMMYYYY(tempTRADate.toDate());
                    }
                  }
                  // Clean up specific number text format (e.g., "1,234,567" or "13,230.34")
                  if (row[5]){
                    let tempTraNumber:any = String(row[5])
                    if (numberTextFormat.test(row[5])) {
                      const numberPattern:any  = /[\d.]/g;
                      const cleanedNumber:any = tempTraNumber.match(numberPattern).join("");
                      row[5] = cleanedNumber;
                    }
                  }
              }
              nonEmptyRows.push(row);
          }
        });

        // Convert the non-empty rows to CSV format
        const csvData = nonEmptyRows.map((row:any) => row.join(',')).join('\n');

        // Split the CSV data into an array of lines
        const lines = csvData.split('\n');

        // Split each line into an array using a comma as the delimiter
        const resultArray = lines.map((line:any) => line.split(','));

        resolve(resultArray);
      }
  
      reader.onerror = (error) => {
        reject(error);
      };
  
      reader.readAsArrayBuffer(actualFile);
    });
  }

  // If uploaded file from Supplier Upload page is XSLX, we load it from here
  loadSupplierXLSXFile(actualFile: any){
    this.pageLoaded = false;
    this.typeOfFileUpload = "XLSX";
    let allTransactions:any = [];
    let rejectedTransactions:any = [];
    let validHeaderFormat = false;      
    let overallSumOfValue:any = 0;
    let numOfTransactions:any = 0;

    let currentDate = moment();
    let tempAvailableFrom = this.getNextDayOfTheWeek('sat', true, currentDate.toDate());
    let tempUploadPeriod = this.subtrackDays(1, tempAvailableFrom);
    let uploadPeriod = this.convertDate(tempUploadPeriod);

    let tranState = "Imported";
    let tranFailure = "None";
    let tempUploadTransactions = new MatTableDataSource<any>();
    let tempUploadRejectedTransactions = new MatTableDataSource<any>();
    this.convertXLSXFileToCSV(actualFile).then((csvData:any) => {
      validHeaderFormat = this.checkIfValidFileFormatCsv(csvData);
        if (validHeaderFormat == true) {
          csvData.forEach((row:any)=>{
            let checkForTransactionRow = this.isATransactionRow(row);
            if (checkForTransactionRow == true) {
              let checkTransactionRowForFailures:any = this.checkTransactionRowForFailures(row);
              if (checkTransactionRowForFailures == ""){
                let tempAgentName:any = "N/A" 
                if (row?.length > 2){
                  tempAgentName = this.checkIfMemberExist(row[1]);
                  if (tempAgentName == false){
                    tempAgentName = "N/A"
                  }
                }
                let newTempTransaction = this.setupAcceptedTransactionRow(row,false,false,false,tempAgentName);
                allTransactions.push(newTempTransaction);
                overallSumOfValue = Number(overallSumOfValue) + Number(newTempTransaction.amount);
                numOfTransactions = numOfTransactions + 1;  
              } else {    
                let tempAgentName:any = "N/A" 
                if (row?.length > 2){
                  tempAgentName = this.checkIfMemberExist(row[1]);
                  if (tempAgentName == false){
                    tempAgentName = "N/A"
                  }
                }
                row.push(checkTransactionRowForFailures);
                rejectedTransactions.push(row);
                let newTempTransaction = this.setupRejectedTransactionRow(row,false,false,false,tempAgentName,checkTransactionRowForFailures);
                allTransactions.push(newTempTransaction);
                if (this.isAFloatNumber(newTempTransaction.amount)){
                  overallSumOfValue = Number(overallSumOfValue) + Number(newTempTransaction.amount);
                }
                numOfTransactions = numOfTransactions + 1;  
              }       
            }
            // else if (checkForTransactionRow == "Missing Data"){
            //   //  KP : We skip 'Missing Data' Transactions as they will cause issues to DB
            //   //
            //   // let tempAgentName:any = "N/A"
            //   // if (row?.length > 2){
            //   //   tempAgentName = this.checkIfMemberExist(row[1]);
            //   //   if (tempAgentName == false){
            //   //     tempAgentName = "N/A"
            //   //   }
            //   // } 
            //   // let checkTransactionRowForFailures:any = this.checkTransactionRowForFailures(row);
            //   // row.push(checkTransactionRowForFailures);
            //   // rejectedTransactions.push(row);
            //   // let newTempTransaction = this.setupRejectedTransactionRow(row,false,false,false,tempAgentName,checkTransactionRowForFailures);
            //   // allTransactions.push(newTempTransaction);
            //   // if (this.isAFloatNumber(newTempTransaction.amount)){
            //   //   overallSumOfValue = Number(overallSumOfValue) + Number(newTempTransaction.amount);
            //   // }
            //   // numOfTransactions = numOfTransactions + 1;
            // }                             
          });

          // Then check for duplicates within the list
          let sizeOfList:any = allTransactions.length;
          if (sizeOfList > 0){
            for (let counterX = 0; counterX < sizeOfList; counterX++){
              let tranCounter:any = 0;
              let tempTransactionX:any = allTransactions[counterX];
              for (let counterY = 0; counterY < sizeOfList; counterY++){
                let tempTransactionY:any = allTransactions[counterY];
                if ((tempTransactionX.reference == tempTransactionY.reference) 
                    && (tempTransactionX.departure == tempTransactionY.departure)
                    && (tempTransactionX.amount == tempTransactionY.amount)
                    && (tempTransactionX.client == tempTransactionY.client)
                    && (tempTransactionX.memberTradeCode == tempTransactionY.memberTradeCode) 
                    && (tempTransactionX.state == tempTransactionY.state)){
                  tranCounter = Number(tranCounter) + 1;
                }
              }
              if (tranCounter >= 2){
                tranFailure = "Some Duplicated Transactions";
                allTransactions[counterX].state = 'Rejected'
                allTransactions[counterX].warning = 'Duplicated Transaction'                
                rejectedTransactions.push(allTransactions[counterX]);
              }
            }
          }

          // Check if tranState will be 'Imported' or 'Failed' 
          let rejectedTransactionsCounter = 0;
          let acceptedTransactionsCounter = 0;
          allTransactions.forEach((tempTrans:any)=>{
            if (tempTrans.state != 'Accepted') {
              rejectedTransactionsCounter = rejectedTransactionsCounter + 1
            } else {
              acceptedTransactionsCounter = acceptedTransactionsCounter + 1
            }
          })
          if (rejectedTransactionsCounter > 0 && acceptedTransactionsCounter > rejectedTransactionsCounter){
            if (tranFailure == "Some Duplicated Transactions"){
              tranFailure = 'Some Rejected / Duplicated Transactions'
            } else {
              tranFailure = 'Some Rejected Transactions'
            }
          } 
          if (rejectedTransactionsCounter > acceptedTransactionsCounter){
            if (tranFailure == "Some Duplicated Transactions"){
              tranFailure = 'Some Rejected / Duplicated Transactions'
            } else {
              tranFailure = 'Some Rejected Transactions'
            }
          }
          if (acceptedTransactionsCounter == 0){
            tranState = 'Failed'
            tranFailure = 'Rejected Transactions'
          }

          // Setup the transaction report page
          allTransactions.forEach((row:any)=>{
            // ONE FINAL CHECK OF DEPARTURE DATE
            if (this.isABritishDateFormat(row.departure) == false){
              const convertedDeptDate = this.convertDateToBritishFormat(row.departure);
              if (convertedDeptDate.startsWith('Invalid date')) {
                const tempInvalidDeptDate = String(row.departure).slice(0, 15);
                if (row.warning === "None") {
                  // Attempt conversion or set to default invalid date
                  row.departure = "01/01/2000";
                  row.state = "Rejected";
                  row.warning = `Invalid Dept Date Format (${tempInvalidDeptDate})`;
                } else {
                  row.departure = "01/01/2000";
                  row.state = "Rejected";
                  row.warning += ` and Dept Date (${row.departure})`;
                }
              } else {
                row.departure = convertedDeptDate; // Set the converted date if valid
              }
            }
            let tempRow:any = {
              tranAgent: row.memberName,
              tranTapsId: String(row.memberTradeCode).toUpperCase(),
              tranRef: row.reference,
              tranDepart: row.departure,
              tranClient: row.client, 
              tranWarning: row.warning,
              tranValue: parseFloat(row.amount).toFixed(2), 
              tranAdjust: this.checkIfAdjusted(row.approval), 
              tranCollectedValue: row.collected, 
              tranCode: row.code, 
              tranName: row.reason, 
              tranState: row.state
            };
            
             //Seperate Rejected and Accepted Transactions 
             if (tempRow.tranState == "Accepted" || tempRow.tranState == "Paid" || tempRow.tranState == "Collected"){ 
               tempUploadTransactions.data.push(tempRow);  
               // Check for approved and unapproved amount
               if (this.isAFloatNumber(tempRow.tranValue)){
                this.viewOverallAcceptedAmount = Number(this.viewOverallAcceptedAmount) + Number(tempRow.tranValue);
                 if (tempRow.tranAdjust == "Yes"){
                   this.viewAdjustments = Number(this.viewAdjustments) + Number(tempRow.tranValue);
                  } else {
                   this.viewToPay =  Number(this.viewToPay) + Number(tempRow.tranValue);
                  }   
               }                           
             } else if (tempRow.tranState == "Rejected"){                  
               tempRow.tranFailure = tempRow.tranWarning;
               if (this.isAFloatNumber(tempRow.tranValue))
               {                        
                 this.viewOverallRejectedAmount = Number(this.viewOverallRejectedAmount) + Number(tempRow.tranValue);                    
               }            
               tempUploadRejectedTransactions.data.push(tempRow);
             }        
           });

          // Setup overall stat values
          this.tapsUploadTransactionData.data = tempUploadTransactions.data;
          this.tapsUploadTransactionRejectedData.data = tempUploadRejectedTransactions.data;
          this.viewAdjustmentUploadTapsId = this.userSupplierMisttaCode;
          this.viewAdjustmentUploadSupplierName = this.userSupplierName;
          this.viewAllTransactions = [...allTransactions];
          this.viewRejectedTransactions = rejectedTransactions
          this.viewTransactionState = tranState;
          this.viewTranFailureState = tranFailure;

          this.viewUploadDate = moment().format('L');

          this.viewNumOfTransactions = numOfTransactions;
          this.viewAdjustments = parseFloat(this.viewAdjustments).toFixed(2);
          this.viewOverallRejectedAmount = parseFloat(this.viewOverallRejectedAmount).toFixed(2);
          this.viewOverallAcceptedAmount = parseFloat(this.viewOverallAcceptedAmount).toFixed(2);    
          this.viewValue = Number(this.viewOverallAcceptedAmount) + Number(this.viewOverallRejectedAmount);
          this.viewValue = parseFloat(this.viewValue).toFixed(2);    
          this.setupToPayAmount(tranState); 
          if (this.userSupplierPaymentDays == ""){
            this.suppliersListData.data.forEach((supplier:any)=>{
              if (supplier.misttaCode == this.userSupplierMisttaCode){
                this.generateAgentReviewPeriodAndPaymentDue(uploadPeriod,supplier.tapsPaymentDays);
                if (supplier.atolNo != null && supplier.atolNo != ""){
                  this.userSupplierOnAtol = true;
                } else {
                  this.userSupplierOnAtol = false;
                }
              }
            });
          } else {
            this.generateAgentReviewPeriodAndPaymentDue(uploadPeriod,this.userSupplierPaymentDays);
          }
          this.pageLoaded = true;
          this.lateAdjustmentUploadedFile = true;
          this.supplierUploadedFile = true;
          const preTranOverallValue = this.viewValue;
          const preNumOfTransactions = this.viewNumOfTransactions;

          const preTempCurrentDate = moment();
          const preTempAvailableFrom = this.getNextDayOfTheWeek('sat', true, preTempCurrentDate.toDate());
          const preTempUploadPeriod = this.subtrackDays(1, preTempAvailableFrom);
          const preUploadPeriod = this.convertDate(preTempUploadPeriod);       
          const preCollectionDate = this.convertDate(this.addDays(7, preTempUploadPeriod));
          const prePaymentDate = this.convertDate(this.addDays(this.userSupplierPaymentDays + 7, preTempUploadPeriod));

          const preTranState = "Importing";
          const preTranFailure = "Rejected by User";
          const preUploadSupplierMisttaCode = this.viewAdjustmentUploadTapsId;
          const preUploadSupplierName = this.viewAdjustmentUploadSupplierName;
          const preOriginalID = "Original";   
          const preTranCollected = 0;
          const preAgentName = "";
          const preAgentTradeCode = "";
          const preRequest: any = {             
            company: Session.mySession.getUser().company,
            tradeCode: Session.mySession.getUser().tradeCode,
            operation: Session.mySession.getUser().operation,
            agentName: preAgentName,
            agentTradeCode: preAgentTradeCode,
            supplierName: preUploadSupplierName,
            supplierTradeCode: preUploadSupplierMisttaCode,
            misttaCode: preUploadSupplierMisttaCode,
            uploadPeriod: preUploadPeriod,
            collectionDate: preCollectionDate,
            paymentDate: prePaymentDate,
            tranValue: preTranOverallValue,
            tranCollected: preTranCollected,
            tranFailure: preTranFailure,
            tranState: preTranState,
            numOfTransactions: preNumOfTransactions,
            listOfTransactions: [],
            transactionOriginalID: preOriginalID,
            token: Session.mySession.get('user').token
          }
          this.pageLoaded = false;
          this.tapsService.preCreateTapsUpload(preRequest).then((output:any)=>{
            this.pageLoaded = true;
            if (output.status == "OK"){
              // Setup response message
              if (tranFailure == "None" && rejectedTransactions.length == 0){
                this.sendMessageToDialog("Successfully loaded with the following transaction State : " + tranState,"","");
              } else if (tranFailure == "Some Duplicated Transactions") {
                let outputMessage = "Successfully loaded with the following transaction State : " + tranState;
                let rejectedTransactionsList = "There are duplicated transactions in the uploaded file.";
                let finalMessage:any = outputMessage + '<br><br>' + rejectedTransactionsList;
                this.viewTransactionFailure = rejectedTransactionsList;
                this.sendMessageToDialog(finalMessage,"","");
              } else {
                let outputMessage = "Successfully loaded with the following transaction State : " + tranState;
                let rejectedTransactionsList = "Warning : " + tranFailure + "<br>Not all records were successfully accepted.<br>The following were rejected:";
                rejectedTransactions.forEach((transaction:any)=>{
                  rejectedTransactionsList = rejectedTransactionsList + '<br><br>Transaction Failed<br>' + this.returnListedTransactionValues(transaction);
                });
                let finalMessage:any = outputMessage + '<br><br>' + rejectedTransactionsList;
                this.viewTransactionFailure = rejectedTransactionsList;
                this.sendMessageToDialog(finalMessage,"","");
              } 
              this.preImportedTransactionID = output["res"].transactionID;
            } else {
              this.sendMessageToDialog("",output["res"],"");
              this.clearViewUploadFile()
              this.switchView("homePage");
            }
          });
        } else {
          this.sendMessageToDialog('SinGS could not complete your request at this time (Invalid Header/Footer or File Format)<br><br>Reason: ' + this.uploadFailedReason + '', '', "");
        }  

    });
  }

  // Check for file type 
  uploadFilePreviewPage(fileInput: any){    
    this.supplierUploadFile = fileInput;
    let actualFile = fileInput.target.files[0];
    if (actualFile.size > 4194304) {
       this.sendMessageToDialog('', 'This file is too big. Maximum file upload is 4MB', '');
    } else {
      if (actualFile.size > 0){
        if (actualFile.type == "text/plain") {
          this.clearViewUploadFile();       
          this.loadSupplierTxtFile(actualFile);
        } else if (actualFile.type == "text/csv") {
          this.clearViewUploadFile();       
          this.loadSupplierCSVFile(actualFile);
        } else if (actualFile.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || actualFile.type == "application/vnd.ms-excel") {
          this.clearViewUploadFile();       
          this.loadSupplierXLSXFile(actualFile);
        } else {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (Invalid File Format)', "");
        }
      } else{
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (Empty File)', "");
      }
    }
  }

  // Uploads the Supplier Uploaded file and returns to main page
  acceptSupplierUpload(){
    if (confirm("Are you sure you want to accept this file?")) {
      if (this.supplierUploadFile != ""){
        this.supplierCreateTapsUpload()
        this.clearViewUploadFile();        
        this.supplierUploadedFile = false;
        this.switchView("homePage");
      }
    }
  }

  // Rejects the uploaded file from Supplier Upload Page and returns to main page
  rejectSupplierUpload(){
    if (confirm("Are you sure you want to reject this file?")) {
      this.rejectTapsUpload(this.userSupplierName, this.userSupplierMisttaCode);
      this.supplierUploadFile == "";
      this.clearViewUploadFile();
      this.supplierUploadedFile  = false;
      this.switchView("homePage");
    }
  }

  // Calls :: createTapsUpload (Supplier)
  // Generates a record of 'Taps_Upload' 
  // Then we match all of it's transactions with Bookings
  // Record all transactions to 'Taps_Supplier_Transactions'
  // Email the supplier that their upload process was 'Imported' or 'Failed'
  supplierCreateTapsUpload(){
    const tranOverallValue = this.viewValue;
    const numOfTransactions = this.viewNumOfTransactions;

    const currentDate = moment();
    const tempAvailableFrom:any = this.getNextDayOfTheWeek('sat',true,currentDate.toDate());
    const tempUploadPeriod = this.subtrackDays(1,tempAvailableFrom);
    const uploadPeriod = this.convertDate(tempUploadPeriod);        
    const collectionDate = this.convertDate(this.addDays(7,tempUploadPeriod))
    const paymentDate = this.convertDate(this.addDays(this.userSupplierPaymentDays,this.addDays(7,tempUploadPeriod)));

    const tranState = this.viewTransactionState;
    const tranFailure = this.viewTranFailureState;
    const originalID = "Original";
    const listOfTransactions = this.viewAllTransactions.map(({ approval, code, collected, collection, paid, reason, payment, supplierName, supplierTradeCode, ...rest }: any) => rest);
    const tranCollected = 0;
    const agentName = "";
    const agentTradeCode = "";
    let request: any = {             
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      agentName: agentName,
      agentTradeCode: agentTradeCode,
      supplierName: this.userSupplierName,
      supplierTradeCode: this.userSupplierMisttaCode,
      misttaCode: this.userSupplierMisttaCode,
      uploadPeriod: uploadPeriod,
      collectionDate: collectionDate,
      paymentDate: paymentDate,
      tranValue: tranOverallValue,
      tranCollected: tranCollected,
      tranFailure: tranFailure,
      tranState: tranState,
      numOfTransactions: numOfTransactions,
      listOfTransactions: listOfTransactions,
      transactionOriginalID: originalID,
      token: Session.mySession.get('user').token,
      hideDialog: false
    }
    if (this.preImportedTransactionID != ""){
      request.preImportedTransactionID = this.preImportedTransactionID;
    }
    this.pageLoaded = false;
    this.tapsService.createTapsUpload(request).then((output: any) => {
      if (output.status == "OK"){
        this.uploadFileBlocked = true;
        Session.mySession.resetTimersOnBookingValues().then((res:any)=>{
          AsynchRequests.myAsynchReq.addJobToWatch(request, output.jobID)
          this.checkIfJobIsDone().then((res:any)=>{
            this.uploadFileBlocked = false; 
          })
        })
      } else {
        this.sendMessageToDialog('', 'Uploading document has failed (' + output.status + ')', output);
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
    })
  }

  // Checks if is a transaction row
  isATransactionRow(row:any){
    if (row[0] == "TRA"){
      if (row.length > 5){
        return true;
      } else {
        return "Missing Data";
      }
    } else {
      return false;
    }
  }

  // The file Header ... HHH | 5-digit string | Date
  // The file Fooder ... ZZZ
  checkIfValidFileFormatCsv(file:any){
    if (file.length < 2){
      return false;
    }
    let includesHeaderFormat = true;
    let includesFooderFormat = false;
    let includesATransaction = false;
    if (file[0][0] != "HHH")
    {
      this.uploadFailedReason = "Header Issue : HHH Missing"
      includesHeaderFormat = false;
    }
    if (file[0][1] != this.userSupplierMisttaCode){
      if (file[0][1].length < 5){
        // For XLSX files because it removes zeros sometimes
        const tempFileCodeWithZeroAtStart = "0" + file[0][1]
        if (tempFileCodeWithZeroAtStart != this.userSupplierMisttaCode) {
          this.uploadFailedReason = "Header Issue : Supplier Code (" + tempFileCodeWithZeroAtStart + ")"
          includesHeaderFormat = false;
        }
      } else {
        this.uploadFailedReason = "Header Issue : Supplier Code (" + file[0][1] + ")"
        includesHeaderFormat = false;
      }
    }
    
    const tempUploadDate = moment(file[0][2], this.possibleDateFormats, true);
    if (tempUploadDate != null){
      const today = moment();
      if (!tempUploadDate.isSame(today, 'day')) {
        this.uploadFailedReason = `Header Issue : Not Calendar Date (${tempUploadDate.format('DD/MM/YYYY')})`;
        includesHeaderFormat = false;
      }
    } else {
      this.uploadFailedReason = "Header Issue : Invalid Date (" + file[0][2] + ")"
      includesHeaderFormat = false;
    }
    
    file.forEach((transaction:any)=>{
      if (transaction[0] == 'ZZZ'){
        includesFooderFormat = true;
      }
      if (transaction[0] == 'TRA'){
        includesATransaction = true;
      }
    });
    if (includesFooderFormat && includesHeaderFormat){
      if (includesATransaction){
        return true;
      } else {
        return false
      }
    } else {
      if (includesFooderFormat == false){
        this.uploadFailedReason = "Footer Issue : ZZZ Missing"
      }
      return false;
    }
  }

  // The file Header ... HHH | 5-digit string (Supplier) | Date
  // The file Fooder ... ZZZ
  checkIfValidFileFormatTxt(header:any,fooder:any){
    let validHeaderFormat = true;
    let validFooderFormat = true;
    let headerText = header.replace(/\s/g, '');
    let fooderText = fooder.replace(/\s/g, '');

    // Check for header "HHH"
    if (headerText.substring(0,3) != "HHH"){
      this.uploadFailedReason = "Header Issue : HHH Missing"
      validHeaderFormat = false;
    } 

    // Check for supplier code
    if (headerText.substring(3,8) != this.userSupplierMisttaCode){
      this.uploadFailedReason = "Header Issue : Supplier Code (" + headerText.substring(3,8) + ")"
      validHeaderFormat = false;
    }

    // Check for valid date format
    const dateStr = headerText.substring(8);
    const formattedDateStr = moment(dateStr, this.possibleDateFormats, true);
    if (!formattedDateStr.isValid()) {
      this.uploadFailedReason = `Header Issue : Invalid Date (${dateStr})`;
      validHeaderFormat = false;
    } else {
      const today = moment();
      if (!formattedDateStr.isSame(today, 'day')) {
        this.uploadFailedReason = `Header Issue : Not Calendar Date (${formattedDateStr})`;
        validHeaderFormat = false;
      }
    }

    // Check for footer "ZZZ"
    if (fooderText.substring(0,3) != "ZZZ"){
      this.uploadFailedReason = "Footer Issue : ZZZ Missing"
      validFooderFormat = false;
    }

    // Return based on checks
    if (validHeaderFormat && validFooderFormat){
      return true;
    } else {
      return false;
    }
  }

  // Used for 'Late Adjustmnet' (TXT Format)
  checkIfValidFileFormatTxtAndReturn(header:any,fooder:any){
    let validHeaderFormat = true;
    let validFooderFormat = true;
    let headerText = header.replace(/\s/g, '');
    let fooderText = fooder.replace(/\s/g, '');
    // Header Check
    if (headerText.substring(0,3) != "HHH"){
      this.uploadFailedReason = "Header Issue : HHH Missing"
      validHeaderFormat = false;
    } else {
        const supplierCode = headerText.substring(3, 8);
        const dateStr = headerText.substring(8);
        const supplierCodePattern = /^[A-Z0-9]{5}$/;
        if (!supplierCodePattern.test(supplierCode)) {
            this.uploadFailedReason = "Header Issue : Supplier Code (" + supplierCode + ")"
            validHeaderFormat = false;
        }

        // Check for valid date format
        const formattedDateStr = moment(dateStr, this.possibleDateFormats, true);
        if (!formattedDateStr.isValid()) {
          this.uploadFailedReason = `Header Issue : Invalid Date (${dateStr})`;
          validHeaderFormat = false;
        } else {
          const today = moment();
          if (!formattedDateStr.isSame(today, 'day')) {
            this.uploadFailedReason = `Header Issue : Not Calendar Date (${formattedDateStr})`;
            validHeaderFormat = false;
          }
        }

    }
    // Footer Check
    if (fooderText.substring(0,3) != "ZZZ"){
      this.uploadFailedReason = "Footer Issue : ZZZ Missing"
      validFooderFormat = false;
    }
    if (validHeaderFormat && validFooderFormat){
      let returnObject = {
        validFormat: true,
        validSupplier: false,
        supplierMisttaCode: "",
        supplierName: "",
        paymentDate: "",
        tapsPaymentDays: 7,
        onAtol: false
      }
      this.suppliersListData.data.forEach((supplier:any)=>{
        if(String(headerText).includes(supplier.misttaCode) && (supplier.misttaCode != "" && supplier.misttaCode != null && supplier.isTapsReg == "yes")) {
         returnObject.validSupplier = true;
         returnObject.supplierMisttaCode = supplier.misttaCode;
         returnObject.supplierName = supplier.supplierNameM;
         returnObject.paymentDate = this.generateLateAdjustmentPaymentDueDate(new Date(this.getUploadPeriod(true)),supplier.tapsPaymentDays);
         returnObject.tapsPaymentDays = supplier.tapsPaymentDays;
         this.userSupplierPaymentDays = supplier.tapsPaymentDays;
         if (supplier.atolNo != null && supplier.atolNo != ""){
          this.userSupplierOnAtol = true;
          returnObject.onAtol = true;
         } else {
           this.userSupplierOnAtol = false;
           returnObject.onAtol = false;
         }
         if (supplier.tapsTradeCode != null && supplier.tapsTradeCode != ""){
           this.userSupplierTapsTradeCode = supplier.tapsTradeCode;
         }
         this.userSupplierTapsExcludeFlag = supplier.tapsExcludeFlag;
        }
      }); 
      if (returnObject.validSupplier == false){
        this.uploadFailedReason = "Supplier Issue : The given Supplier Code is not Active."
      }
      return returnObject;    
    } else {
      return false;
    }
  }

  // Used for validation check
  isNumeric(val:any) {
    return /^-?\d+$/.test(val);
  }

  // The file Header ... HHH | 5-digit string (supplier tradeCode) | Date
  // The file Fooder ... ZZZ
  // Return supplier tradeCode (Used for Late Adjustment Upload)
  checkIfValidFileFormatAndReturnSupplier(file:any){
    let includesATransaction = false;
    let returnObject = {
      validFormat: false,
      validSupplier: false,
      supplierMisttaCode: "",
      supplierName: "",
      paymentDate: "",
      tapsPaymentDays: 7,
      onAtol: false
    }
    let includesHeaderFormat = true;
    let includesFooderFormat = false;
    if (file[0][0] != "HHH" && !this.isADate(file[0][2]))
    {
      if (file[0][0] != "HHH"){
        this.uploadFailedReason = "Header Issue : HHH Missing"
      }
      if (!this.isADate(file[0][2])){
        this.uploadFailedReason = "Header Issue : Invalid Date (" + file[0][2] + ")"
      }
      includesHeaderFormat = false;
    } else {
      const tempUploadDate = moment(file[0][2], this.possibleDateFormats, true);
      if (tempUploadDate != null){
        const today = moment();
        if (!tempUploadDate.isSame(today, 'day')) {
          this.uploadFailedReason = `Header Issue : Not Calendar Date (${tempUploadDate.format('DD/MM/YYYY')})`;
          includesHeaderFormat = false;
        }
      } else {
        this.uploadFailedReason = "Header Issue : Invalid Date (" + file[0][2] + ")"
        includesHeaderFormat = false;
      }
    }
    file.forEach((transaction:any)=>{
      if (transaction[0] == 'ZZZ'){
        includesFooderFormat = true;
      }
      if (transaction[0] == 'TRA'){
        includesATransaction = true;
      }
    });
    if (includesFooderFormat && includesHeaderFormat){
      returnObject.validFormat = true;
      if (includesATransaction) {
        let tempSupplierMisttaCode = file[0][1];
        if (tempSupplierMisttaCode.length == 4){
          tempSupplierMisttaCode = "0" + tempSupplierMisttaCode;
        }
        for (let counter = 0; counter < this.suppliersListData.data.length; counter++) {
          if (tempSupplierMisttaCode == this.suppliersListData.data[counter].misttaCode && this.suppliersListData.data[counter].isTapsReg.toLowerCase() == "yes") {
            returnObject.validSupplier = true;
            returnObject.supplierMisttaCode = this.suppliersListData.data[counter].misttaCode;
            returnObject.supplierName = this.suppliersListData.data[counter].supplierNameM;
            returnObject.paymentDate = this.generateLateAdjustmentPaymentDueDate(new Date(this.getUploadPeriod(true)),this.suppliersListData.data[counter].tapsPaymentDays);
            returnObject.tapsPaymentDays = this.suppliersListData.data[counter].tapsPaymentDays;
            this.userSupplierPaymentDays = this.suppliersListData.data[counter].tapsPaymentDays;
            if (this.suppliersListData.data[counter].atolNo != null && this.suppliersListData.data[counter].atolNo != ""){
              this.userSupplierOnAtol = true;
              returnObject.onAtol = true;
            } else {
              this.userSupplierOnAtol = false;
              returnObject.onAtol = false;
            }
            if (this.suppliersListData.data[counter].tapsTradeCode != null && this.suppliersListData.data[counter].tapsTradeCode != ""){
              this.userSupplierTapsTradeCode = this.suppliersListData.data[counter].tapsTradeCode;
            }
            this.userSupplierTapsExcludeFlag = this.suppliersListData.data[counter].tapsExcludeFlag;
            break;
          }
        }
        if (returnObject.validSupplier == false){
          this.uploadFailedReason = "Supplier Issue : The given Supplier Code is not Active."
        }
      } else {
        this.uploadFailedReason = "Transaction Issue : No Transactions Found"
        returnObject.validFormat = false;
      }
      return returnObject;
    } else {
      if (includesFooderFormat == false){
        this.uploadFailedReason = "Footer Issue : ZZZ Missing"
      }
      return returnObject;
    }
  }

  // Used to generate the CSV format of a TXT transaction 
  // As well as validated it and split it for rejected or accepted transaction
  // returns object with the following attributes:
  // {validTransaction | rejectedTransaction | transactionRow}
  convertTxtTransactionToCsv(tempRow:any){
    let transactionInfo:any = {
      validTransaction: true,
      rejectedTransaction: false,
      rejectedReason: "",
      transactionRow: "",
      agentName: "N/A"
    };  

    const specialCharsRegex = /[!@#$%^&*()_+[\]{};':"\\|,.<>/?]+/g;
    tempRow = tempRow.replace(specialCharsRegex, "");

    if (tempRow.substring(0,3) == "TRA"){
      
      // TRA
      transactionInfo.transactionRow = tempRow.substring(0,3) + ",";

      // TRADECODE & AGENTNAME
      let memberTradeCode = tempRow.substring(3,8);
      let validMemberFound = this.checkIfMemberExist(memberTradeCode);
      transactionInfo.transactionRow = String(transactionInfo.transactionRow) + String(memberTradeCode) + ",";
      if (validMemberFound == false){
        transactionInfo.rejectedTransaction = true;
        transactionInfo.agentName = 'N/A'
        if (transactionInfo.rejectedReason === ""){
          transactionInfo.rejectedReason = "Invalid Agent"
        } else {
          transactionInfo.rejectedReason = transactionInfo.rejectedReason + " and Agent"
        }
      } else {
        transactionInfo.agentName = validMemberFound;
      }

      // OPERATOR REF
      let operatorRefRaw = String(tempRow.substring(8, 18));
      let operatorWhiteSpaceAtStart = 0;
      while (operatorWhiteSpaceAtStart < operatorRefRaw.length && operatorRefRaw.charAt(operatorWhiteSpaceAtStart) === ' ') {
        operatorWhiteSpaceAtStart++;
      }
      operatorRefRaw = operatorRefRaw.substring(operatorWhiteSpaceAtStart);
      let spaceIndex = operatorRefRaw.indexOf(' ');
      let operatorRef = operatorRefRaw.replace(/\s+/g, '');
      if (spaceIndex !== -1 && spaceIndex + 1 < operatorRefRaw.length && /[a-zA-Z]/.test(operatorRefRaw.charAt(spaceIndex + 1))) {
          operatorRef = operatorRefRaw.substring(0, spaceIndex).replace(/\s+/g, '');
      } else {
        if (spaceIndex !== -1 && spaceIndex + 1 < operatorRefRaw.length){
          operatorRef = operatorRefRaw.substring(0, spaceIndex).replace(/\s+/g, '');
        }
      }
      transactionInfo.transactionRow = String(transactionInfo.transactionRow) + String(operatorRef) + ",";
      let tempInvalidOperatorRef = false
      if (operatorRef.length === 0 || operatorRef.length > 10) {
        transactionInfo.rejectedTransaction = true;
        tempInvalidOperatorRef = true;
      }
      if (tempInvalidOperatorRef) {
        if (transactionInfo.rejectedReason === ""){
            transactionInfo.rejectedReason = "Invalid Operator Ref";
        } else {
            transactionInfo.rejectedReason += " and Operator Ref";
        }
      }

      // CLIENT NAME
      let clientName = "";
      let clientNameCounter:any = 0;
      let clientNameStopCounter:any = 0;
      let clientNameAndTheRest = "";

      if (spaceIndex !== -1) {
        clientNameAndTheRest = tempRow.substring(20-spaceIndex).trim();
      } else {
        clientNameAndTheRest = tempRow.substring(18).trim();
      }

      let stopAddingCN = false;
      for (clientNameCounter; clientNameCounter < clientNameAndTheRest.length; clientNameCounter++){
        const char = clientNameAndTheRest.charAt(clientNameCounter);
        if (this.isNumeric(char) == false && stopAddingCN == false){
          clientName = String(clientName) + String(char);
        } else {
          if (clientName.length > 1){
            if (stopAddingCN == false){
              clientNameStopCounter = clientNameCounter;
            }
            stopAddingCN = true;
          }
        }
      }
      clientName = String(clientName).trim();
      transactionInfo.transactionRow = String(transactionInfo.transactionRow) + String(clientName) + ",";
      let tempInvalidLeadName = false
      if (clientName.length > 7){
        transactionInfo.rejectedTransaction = true;
        tempInvalidLeadName = true
      }
      if (clientName.replace(/\s/g, '') == ""){
        transactionInfo.rejectedTransaction = true;
        tempInvalidLeadName = true
      }
      if (tempInvalidLeadName == true){
        if (transactionInfo.rejectedReason === ""){
          transactionInfo.rejectedReason = "Invalid Lead Name"
        } else {
          transactionInfo.rejectedReason = transactionInfo.rejectedReason + " and Lead Name"
        }
      }

      // DEPARTURE DATE
      let departureDate = "";
      let departureDateOnly = clientNameAndTheRest.substring(clientNameStopCounter);
      departureDateOnly = departureDateOnly.substring(0,7)
      const formattedDateStr = moment(departureDateOnly, this.possibleDateFormats, true);
      if (!formattedDateStr.isValid()) {
        transactionInfo.rejectedTransaction = true;
        if (transactionInfo.rejectedReason === ""){
          transactionInfo.rejectedReason = "Invalid Dept Date (" + String(departureDateOnly) + ")";
        } else {
          transactionInfo.rejectedReason = transactionInfo.rejectedReason + " and Dept Date (" + String(departureDateOnly) + ")";
        }
        transactionInfo.transactionRow = String(transactionInfo.transactionRow) + String("01/01/2000") + ",";
      } else {
          departureDate = formattedDateStr.format('DD/MM/YYYY');
          // Additional check for future dates if not on ATOL
          if (!this.userSupplierOnAtol) {
            const today = moment();
            if (formattedDateStr.isAfter(today, 'day')) {
              transactionInfo.rejectedTransaction = true;
              transactionInfo.rejectedReason += (transactionInfo.rejectedReason ? " and " : "") + "NotOnATOL";
            }
          }
          transactionInfo.transactionRow += `${departureDate},`;
      }
      
      
      // AMOUNT
      let amount:any = "";
      let amountOnly = clientNameAndTheRest.substring(Number(clientNameStopCounter) + Number(7));
      amountOnly = amountOnly.replace(/[^-\d.]/g, '');
      let normalNumber:any = amountOnly.slice(0,-2);
      normalNumber = Number(normalNumber);
      let floatingPoint:any = amountOnly.slice(-2);
      floatingPoint = Number("0." + String(floatingPoint));
      let finalNumber:any = 0;
      if (normalNumber < 0){
        finalNumber = Number(normalNumber) - Number(floatingPoint);
      } else {
        finalNumber = Number(normalNumber) + Number(floatingPoint);
      }
      amount = String(finalNumber.toFixed(2));
      let tempInvalidAmount = false;
      if (amount != 'NaN'){
        if (amount == 0){
          transactionInfo.rejectedTransaction = true;
          tempInvalidAmount = true
        }
        if (this.typeOfFileUpload == "TXT"){
          if (finalNumber < 100000){ 
            // Accepted format
          } else {
            transactionInfo.rejectedTransaction = true;
            tempInvalidAmount = true
          }
          if (normalNumber < 0 && finalNumber < -10000){
            transactionInfo.rejectedTransaction = true;
            tempInvalidAmount = true
          }
        } else {
          if (finalNumber < 100000){   
            // Accepted format
           } else {
             transactionInfo.rejectedTransaction = true;
             tempInvalidAmount = true
           }
           if (normalNumber < 0 && finalNumber < -100000){
            transactionInfo.rejectedTransaction = true;
            tempInvalidAmount = true
          }
        }
      } else {
        transactionInfo.rejectedTransaction = true;
        tempInvalidAmount = true
      }  
      if (tempInvalidAmount == true){
        if (transactionInfo.rejectedReason === ""){
          transactionInfo.rejectedReason = "Invalid Amount"
        } else {
          transactionInfo.rejectedReason = transactionInfo.rejectedReason + " and Amount"
        }
      }
      transactionInfo.transactionRow = String(transactionInfo.transactionRow) + amount;   
    } else {
      transactionInfo.validTransaction = false;
    }
    return transactionInfo;
  }
  
  // Standard TRA Row setup (tradeCode/reference/client/departure/amount)
  setupAcceptedTransactionRow(transactionRow:any,lateAdjustment:boolean,supplierName:any,supplierTradeCode:any,agentName:any){
    let transactionObject:any = {}
    if (this.userSupplierMisttaCode != ""){
      transactionObject.supplierTradeCode = this.userSupplierMisttaCode;
      transactionObject.supplierName = this.userSupplierName;
    } else {
      if (supplierName != false){
        transactionObject.supplierName = supplierName
      } else {
        transactionObject.supplierName = this.userName;
      }
      if (supplierTradeCode != false){
        transactionObject.supplierTradeCode = supplierTradeCode;
      } else {
        transactionObject.supplierTradeCode = this.userTradeCode;
      }
    }
    transactionObject.memberTradeCode = transactionRow[1];
    transactionObject.memberName = agentName;
    transactionObject.reference = transactionRow[2];
    if (transactionRow[3].length > 20){
      transactionObject.client = transactionRow[3].substring(0, 20);
    } else {
      transactionObject.client = transactionRow[3];
    }
    transactionObject.departure = transactionRow[4];
    transactionObject.amount = transactionRow[5];
    transactionObject.warning = "None";
    transactionObject.payment = this.generatePaymentDueDate(new Date(),lateAdjustment);
    transactionObject.collected = "0.00";
    transactionObject.code = "J";
    transactionObject.reason = "PENDING APPROVAL";
    transactionObject.approval = "Adjusted"
    transactionObject.state = "Accepted";
    transactionObject.collection = "NotCollected";
    transactionObject.paid = "NotPaid";
    return transactionObject;
  }

  // Standard TRA Row setup (tradeCode/reference/client/departure/amount)
  setupRejectedTransactionRow(transactionRow:any,lateAdjustment:boolean,supplierName:any,supplierTradeCode:any,agentName:any,warningMessage:any){
    let transactionObject:any = {}
    if (this.userSupplierMisttaCode != ""){
      transactionObject.supplierTradeCode = this.userSupplierMisttaCode;
      transactionObject.supplierName = this.userSupplierName;
    } else {
      transactionObject.supplierTradeCode = this.userTradeCode;
      transactionObject.supplierName = this.userName;
      if (supplierName != false){
        transactionObject.supplierName = supplierName
      } else {
        transactionObject.supplierName = this.userName;
      }
      if (supplierTradeCode != false){
        transactionObject.supplierTradeCode = supplierTradeCode;
      } else {
        transactionObject.supplierTradeCode = this.userTradeCode;
      }
    }
    if (transactionRow[1].length > 5){
      transactionObject.memberTradeCode = "N/A";
    } else {
      transactionObject.memberTradeCode = transactionRow[1];
    }
    transactionObject.memberName = agentName;
    transactionObject.reference = transactionRow[2];
    if (transactionRow[3].length > 20){
      transactionObject.client = transactionRow[3].substring(0, 20);
    } else {
      transactionObject.client = transactionRow[3];
    }
    transactionObject.departure = transactionRow[4];
    transactionObject.amount = transactionRow[5];
    if (warningMessage == ""){
      transactionObject.warning = this.checkTransactionRowForFailures(transactionRow);
    } else {
      transactionObject.warning = warningMessage;
    }
    transactionObject.payment = this.generatePaymentDueDate(new Date(),lateAdjustment);
    transactionObject.collected = "0.00";
    transactionObject.code = "J";
    transactionObject.reason = "PENDING APPROVAL"
    transactionObject.approval = "Adjusted"
    transactionObject.state = "Rejected"
    transactionObject.collection = "NotCollected";
    transactionObject.paid = "NotPaid";
    return transactionObject;
  }

  // Listed rejected transactions for the successful upload message
  returnListedTransactionValues(transaction:any){
    let output = "";
    for (let key in transaction) {
      if (transaction.hasOwnProperty(key)) {
        if (output == ""){
          output = `${key}: ${transaction[key]}` 
        } else {
          output = output + '<br>' + `${key}: ${transaction[key]}`; 
        }
      }
    }
    return output;  
  }

  // Calls :: getTapsUploadHistoryList
  // List all the uploaded files by the current operator (requires Dates From & To)
  listUploadedFilesOfSupplier(fromAdmin:boolean){
    this.clearViewUploadFile();
    if ((this.selectedDateFrom != null && this.selectedDateFrom != 0) && (this.selectedDateTo != null && this.selectedDateTo != 0)){
      let request = {
        company: Session.mySession.getUser().company,
        tradeCode: Session.mySession.getUser().tradeCode,
        operation: Session.mySession.getUser().operation,
        supplierTradeCode: this.userSupplierMisttaCode,
        uploadFrom: this.convertDate(this.selectedDateFrom), 
        uploadTo: this.convertDate(this.selectedDateTo),
        token: Session.mySession.get('user').token
      }
      if (fromAdmin == true){
        request.supplierTradeCode = this.adminReportSupplier;
      }
      if (request.supplierTradeCode == "all"){
        this.sendMessageToDialog('', 'Please fill in all the required data','');
      } else {
        this.pageLoaded = false;
        this.tapsService.getTapsUploadHistoryList(request).then((output: any) => {
          if (output.status != "OK") {
            this.pageLoaded = true;
            this.sendMessageToDialog("", "Error with request status: " + output.res, "");
          } else if (output != null && typeof output.res !== 'undefined') {
            let tempUploadTransactions = new MatTableDataSource<any>();
            if (output.res.length != 0){
              output.res.forEach((upload: any) => {
                upload.viewUploadDate = moment(upload.uploadDate).format('L');
                upload.tranValue = Number(upload.tranValue).toFixed(2);
                tempUploadTransactions.data.push(upload);
              });
              this.tapsUploadHistoryListData.data = tempUploadTransactions.data;            
            } else {
              this.tapsUploadHistoryListData.data = [];
            }
            this.pageLoaded = true;
          } 
        }).catch((error: any) => {
          this.pageLoaded = true;
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
        });
      }
    } else {
      this.sendMessageToDialog('', 'Please fill in all the required data','');
    }
  }

  // Sort by Date (Supplier - Upload History - tapsUploadHistoryListData)
  sortTapsUploadHistoryListDataByDate() {
    if (this.supplierUploadHistorySortedbyDate == false){
      this.supplierUploadHistorySortedbyDate = true;
      let sorted = this.tapsUploadHistoryListData.data.sort((a: any, b: any) => {
        const arr1 = a.viewUploadDate.split('/');
        const arr2 = b.viewUploadDate.split('/');
        let aDate = new Date(parseInt(arr1[2]), parseInt(arr1[1]), parseInt(arr1[0])).getTime()
        let bDate = new Date(parseInt(arr2[2]), parseInt(arr2[1]), parseInt(arr2[0])).getTime()
        if (aDate < bDate) {
          return -1;
        } else if (aDate > bDate) {
          return 1;
        } else {
          return 0;
        }
      });
      this.tapsUploadHistoryListData.data = sorted; 
    } else {
      this.supplierUploadHistorySortedbyDate = false;
      let sorted = this.tapsUploadHistoryListData.data.sort((a: any, b: any) => {
        const arr1 = a.viewUploadDate.split('/');
        const arr2 = b.viewUploadDate.split('/');
        let aDate = new Date(parseInt(arr1[2]), parseInt(arr1[1]), parseInt(arr1[0])).getTime()
        let bDate = new Date(parseInt(arr2[2]), parseInt(arr2[1]), parseInt(arr2[0])).getTime()
        if (aDate < bDate) {
          return 1;
        } else if (aDate > bDate) {
          return -1;
        } else {
          return 0;
        }
      }); 
      this.tapsUploadHistoryListData.data = sorted; 
    }
  }

  // Sort by Value (Supplier - Upload History - tapsUploadHistoryListData)
  sortTapsUploadHistoryListDataByValue() {
    if (this.supplierUploadHistorySortedbyValue == false){
      this.supplierUploadHistorySortedbyValue = true;
      let sorted = this.tapsUploadHistoryListData.data.sort((a: any, b: any) => { 
        let firstValue = Number(a.tranValue); let secondValue = Number(b.tranValue);
        if(firstValue < secondValue){
          return 1
        } else if (firstValue > secondValue) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsUploadHistoryListData.data = sorted; 
    } else {
      this.supplierUploadHistorySortedbyValue = false;
      let sorted = this.tapsUploadHistoryListData.data.sort((a: any, b: any) => { 
        let firstValue = Number(a.tranValue); let secondValue = Number(b.tranValue);
        if(firstValue > secondValue){
          return 1
        } else if (firstValue < secondValue) {
          return -1
        } else {
          return 0
        }});  
      this.tapsUploadHistoryListData.data = sorted; 
    }
  }

  // Sort by State (Supplier - Upload History - tapsUploadHistoryListData)
  sortTapsUploadHistoryListDataByState() {
    if (this.supplierUploadHistorySortedbyState == false){
      this.supplierUploadHistorySortedbyState = true;
      let sorted = this.tapsUploadHistoryListData.data.sort((a: any, b: any) => {
        if(a.tranState < b.tranState){
          return 1
        } else if (a.tranState > b.tranState) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsUploadHistoryListData.data = sorted; 
    } else {
      this.supplierUploadHistorySortedbyState = false;
      let sorted = this.tapsUploadHistoryListData.data.sort((a: any, b: any) => {
        if(a.tranState > b.tranState){
          return 1
        } else if (a.tranState < b.tranState) {
          return -1
        } else {
          return 0
        }});
      this.tapsUploadHistoryListData.data = sorted; 
    }
  }

  // Sort by Failure (Supplier - Upload History - tapsUploadHistoryListData)
  sortTapsUploadHistoryListDataByFailure() {
    if (this.supplierUploadHistorySortedbyFailure == false){
      this.supplierUploadHistorySortedbyFailure = true;
      let sorted = this.tapsUploadHistoryListData.data.sort((a: any, b: any) => {
        if(a.tranFailure < b.tranFailure){
          return 1
        } else if (a.tranFailure > b.tranFailure) {
          return -1
        } else {
          return 0
        }});
      this.tapsUploadHistoryListData.data = sorted; 
    } else {
      this.supplierUploadHistorySortedbyFailure = false;
      let sorted = this.tapsUploadHistoryListData.data.sort((a: any, b: any) => {
        if(a.tranFailure > b.tranFailure){
          return 1
        } else if (a.tranFailure < b.tranFailure) {
          return -1
        } else {
          return 0
        }});
      this.tapsUploadHistoryListData.data = sorted; 
    }
  }

  // Sort by Transactions (Supplier - Upload History - tapsUploadHistoryListData)
  sortTapsUploadHistoryListDataByTransactions() {
    if (this.supplierUploadHistorySortedbyTransactions == false){
      this.supplierUploadHistorySortedbyTransactions = true;
      let sorted = this.tapsUploadHistoryListData.data.sort((a: any, b: any) => {
        let firstValue = Number(a.numOfTransactions); let secondValue = Number(b.numOfTransactions);
        if(firstValue < secondValue){
          return 1
        } else if (firstValue > secondValue) {
          return -1
        } else {
          return 0
        }});
      this.tapsUploadHistoryListData.data = sorted; 
    } else {
      this.supplierUploadHistorySortedbyTransactions = false;
      let sorted = this.tapsUploadHistoryListData.data.sort((a: any, b: any) => {
        let firstValue = Number(a.numOfTransactions); let secondValue = Number(b.numOfTransactions);
        if(firstValue > secondValue){
          return 1
        } else if (firstValue < secondValue) {
          return -1
        } else {
          return 0
        }});
      this.tapsUploadHistoryListData.data = sorted; 
    }
  }

  // When a user selects a row from the upload period's list, 
  // It will change the values of the date ranges (From-To)
  setupDatesForUploadedPeriod(fromAdmin:boolean){
    this.selectedDateFrom = this.selectedDatePeriod.fromDate;
    this.selectedDateTo = this.selectedDatePeriod.toDate;
    this.listUploadedFilesOfSupplier(fromAdmin);
  }

  // Clears all the data related to viewing a Upload File Page (Transaction)
  clearViewUploadFile(){
    this.uploadFailedReason = "";
    this.tapsUploadTransactionData.data = [];
    this.tapsUploadTransactionRejectedData.data = [];
    this.viewTransactionID = "";
    this.viewOriginalTransactionID = "";
    this.viewUploadDate = "";
    this.viewNumOfTransactions = "0";
    this.viewNumOfRejectedTransactions = "0";
    this.viewValue = parseFloat("0").toFixed(2);
    this.viewAdjustments = parseFloat("0").toFixed(2);
    this.viewOverallAdjustments = parseFloat("0").toFixed(2);
    this.viewAgentReview = "";
    this.viewToPay = parseFloat("0").toFixed(2);
    this.viewPaymentDue = "";
    this.viewOverallAcceptedAmount = 0;
    this.viewOverallRejectedAmount = 0;
    this.viewDownloadFile.data = [];
    this.viewAdjustmentUploadTapsId = "";
    this.viewAdjustmentUploadSupplierName = "";
    this.viewAllTransactions = [];
    this.viewRejectedTransactions = [];
    this.viewTransactionState = "";
    this.viewTranFailureState = "";
    this.viewTransactionFailure = "";
    this.supplierUploadTransactionSortedbyAgent = false;
    this.supplierUploadTransactionSortedbyTapsId = false;
    this.supplierUploadTransactionSortedbyRef = false;
    this.supplierUploadTransactionSortedbyDepart = false;
    this.supplierUploadTransactionSortedbyClient = false;
    this.supplierUploadTransactionSortedbyWarning = false;
    this.supplierUploadTransactionSortedbyValue = false;
    this.supplierUploadTransactionSortedbyAdjust = false;
    this.supplierUploadTransactionSortedbyCollection = false;
    this.supplierUploadTransactionSortedbyCode = false;
    this.supplierUploadTransactionSortedbyName = false;
  }

  // Calls :: getTapsUploadTransactions
  // Setting up the upload file (Assisted by the functions below this one)
  setupViewUploadFile(upload:any,uploadFileFailure:any){
    let request = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      uploadPeriod: this.convertDate(upload.uploadPeriod),
      transactionID: upload.transactionID,
      token: Session.mySession.get('user').token
    };
    // If 'Review' or 'Committed' then we use the original TransactionID (from Imported) to find the Supplier Transactions
    if (upload.tranState == "Review" || upload.tranState == "Committed" && upload.transactionOriginalID != 'Original'){
      request.transactionID = upload.transactionOriginalID
    }
    this.viewTransactionID = upload.transactionID;
    this.viewOriginalTransactionID = upload.transactionOriginalID;
    this.viewUploadDate = new Date(upload.uploadDate).toLocaleDateString();
    this.generateAgentReviewPeriodAndPaymentDue(upload.uploadPeriod,this.userSupplierPaymentDays);
    this.pageLoaded = false;
    this.tapsService.getTapsUploadTransactions(request).then((output: any) => {
      if (output["status"] == "OK") {    
         this.viewOverallAcceptedAmount = output["originalValue"]
         this.viewAdjustments = output["adjustments"];
         if (uploadFileFailure == false){
          this.viewToPay = output["toPay"]
         }
         if (upload.tranState == "Committed" && this.viewToPay == 0.0){
          this.viewToPay = output["collectedValue"]
         }
         this.viewOverallRejectedAmount = output["valueOfRejected"]
         this.viewNumOfTransactions = output["numOfAcceptedTransactions"];
         this.viewNumOfRejectedTransactions = output["numOfRejectedTransactions"];
         if (this.viewNumOfTransactions == "0") {
          this.viewNumOfTransactions = this.viewNumOfRejectedTransactions;
         } else {
          this.viewNumOfTransactions = String(Number(this.viewNumOfTransactions) + Number(this.viewNumOfRejectedTransactions))
         }
         this.viewOverallAdjustments = Number(output["adjustments"]) + Number(output["valueOfRejected"])
         this.viewValue = Number(output["originalValue"]) + Number(output["valueOfRejected"]);
         this.tapsUploadTransactionData.data = [...output["listOfAccepted"]];
         this.tapsUploadTransactionRejectedData.data = [...output["listOfRejected"]];
         this.pageLoaded = true;
      } else {
        this.pageLoaded = true;
        this.sendMessageToDialog("", "Error with request status: " + output["status"], output);
      }
    }).catch((error: any) => {
      this.pageLoaded = true;
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
    });    
  }

  // Sort by Agent (Supplier - Upload File Screen)
  sortUploadTransactionByAgent(){
    if (this.supplierUploadTransactionSortedbyAgent == false){
      this.supplierUploadTransactionSortedbyAgent = true;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => { 
        if(a.tranAgent < b.tranAgent){
          return 1
        } else if (a.tranAgent > b.tranAgent) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsUploadTransactionData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyAgent = false;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => { 
        if(a.tranAgent > b.tranAgent){
          return 1
        } else if (a.tranAgent < b.tranAgent) {
          return -1
        } else {
          return 0
        }});  
      this.tapsUploadTransactionData.data = sorted; 
    }
  }

  // Sort by Taps ID (Supplier - Upload File Screen)
  sortUploadTransactionByTapsId(){
    if (this.supplierUploadTransactionSortedbyTapsId == false){
      this.supplierUploadTransactionSortedbyTapsId = true;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => { 
        if(a.tranTapsId < b.tranTapsId){
          return 1
        } else if (a.tranTapsId > b.tranTapsId) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsUploadTransactionData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyTapsId = false;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => { 
        if(a.tranTapsId > b.tranTapsId){
          return 1
        } else if (a.tranTapsId < b.tranTapsId) {
          return -1
        } else {
          return 0
        }});  
      this.tapsUploadTransactionData.data = sorted; 
    }
  }
  
  // Sort by Ref (Supplier - Upload File Screen)
  sortUploadTransactionByRef(){
    if (this.supplierUploadTransactionSortedbyRef == false){
      this.supplierUploadTransactionSortedbyRef = true;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => { 
        if(a.tranRef < b.tranRef){
          return 1
        } else if (a.tranRef > b.tranRef) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsUploadTransactionData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyRef = false;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => { 
        if(a.tranRef > b.tranRef){
          return 1
        } else if (a.tranRef < b.tranRef) {
          return -1
        } else {
          return 0
        }});  
      this.tapsUploadTransactionData.data = sorted; 
    }
  }

  // Sort by Depart (Supplier - Upload File Screen)
  sortUploadTransactionByDepart(){
    if (this.supplierUploadTransactionSortedbyDepart == false){ 
      this.supplierUploadTransactionSortedbyDepart = true;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => {
        const arr1 = a.tranDepart.split('/');
        const arr2 = b.tranDepart.split('/');
        let aDate = new Date(parseInt(arr1[2]), parseInt(arr1[1]), parseInt(arr1[0])).getTime()
        let bDate = new Date(parseInt(arr2[2]), parseInt(arr2[1]), parseInt(arr2[0])).getTime()
        if (aDate < bDate) {
          return -1;
        } else if (aDate > bDate) {
          return 1;
        } else {
          return 0;
        }
      });
      this.tapsUploadTransactionData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyDepart = false;
      let sortedByDepart = this.tapsUploadTransactionData.data.sort((a: any, b: any) => {
        const arr1 = a.tranDepart.split('/');
        const arr2 = b.tranDepart.split('/');
        let aDate = new Date(parseInt(arr1[2]), parseInt(arr1[1]), parseInt(arr1[0])).getTime()
        let bDate = new Date(parseInt(arr2[2]), parseInt(arr2[1]), parseInt(arr2[0])).getTime()
        if (aDate < bDate) {
          return 1;
        } else if (aDate > bDate) {
          return -1;
        } else {
          return 0;
        }
      }); 
      this.tapsUploadTransactionData.data = sortedByDepart; 
    }   
  }

  // Sort by Client (Supplier - Upload File Screen)
  sortUploadTransactionByClient(){
    if (this.supplierUploadTransactionSortedbyClient == false){
      this.supplierUploadTransactionSortedbyClient = true;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => { 
        if(a.tranClient < b.tranClient){
          return 1
        } else if (a.tranClient > b.tranClient) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsUploadTransactionData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyClient = false;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => { 
        if(a.tranClient > b.tranClient){
          return 1
        } else if (a.tranClient < b.tranClient) {
          return -1
        } else {
          return 0
        }});  
      this.tapsUploadTransactionData.data = sorted; 
    }
  }

  // Sort by Warning (Supplier - Upload File Screen)
  sortUploadTransactionByWarning(){
    if (this.supplierUploadTransactionSortedbyWarning == false){
      this.supplierUploadTransactionSortedbyWarning = true;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => { 
        if(a.tranWarning < b.tranWarning){
          return 1
        } else if (a.tranWarning > b.tranWarning) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsUploadTransactionData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyWarning = false;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => { 
        if(a.tranWarning > b.tranWarning){
          return 1
        } else if (a.tranWarning < b.tranWarning) {
          return -1
        } else {
          return 0
        }});  
      this.tapsUploadTransactionData.data = sorted; 
    }
  }

  // Sort by Value (Supplier - Upload File Screen)
  sortUploadTransactionByValue(){
    if (this.supplierUploadTransactionSortedbyValue == false){
      this.supplierUploadTransactionSortedbyValue = true;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => { 
        let firstValue = Number(a.tranValue); let secondValue = Number(b.tranValue);
        if(firstValue < secondValue){
          return 1
        } else if (firstValue > secondValue) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsUploadTransactionData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyValue = false;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => { 
        let firstValue = Number(a.tranValue); let secondValue = Number(b.tranValue);
        if(firstValue > secondValue){
          return 1
        } else if (firstValue < secondValue) {
          return -1
        } else {
          return 0
        }});  
      this.tapsUploadTransactionData.data = sorted; 
    }
  }

  // Sort by Adjust (Supplier - Upload File Screen)
  sortUploadTransactionByAdjust(){
    if (this.supplierUploadTransactionSortedbyAdjust == false){
      this.supplierUploadTransactionSortedbyAdjust = true;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => { 
        if(a.tranAdjust < b.tranAdjust){
          return 1
        } else if (a.tranAdjust > b.tranAdjust) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsUploadTransactionData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyAdjust = false;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => { 
        if(a.tranAdjust > b.tranAdjust){
          return 1
        } else if (a.tranAdjust < b.tranAdjust) {
          return -1
        } else {
          return 0
        }});  
      this.tapsUploadTransactionData.data = sorted; 
    }
  }

  // Sort by Collection (Supplier - Upload File Screen)
  sortUploadTransactionByCollection(){
    if (this.supplierUploadTransactionSortedbyCollection == false){
      this.supplierUploadTransactionSortedbyCollection = true;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => { 
        let firstValue = Number(a.tranCollectedValue); let secondValue = Number(b.tranCollectedValue);
        if(firstValue < secondValue){
          return 1
        } else if (firstValue > secondValue) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsUploadTransactionData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyCollection = false;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => { 
        let firstValue = Number(a.tranCollectedValue); let secondValue = Number(b.tranCollectedValue);
        if(firstValue > secondValue){
          return 1
        } else if (firstValue < secondValue) {
          return -1
        } else {
          return 0
        }});  
      this.tapsUploadTransactionData.data = sorted; 
    }
  }
  
  // Sort by Code (Supplier - Upload File Screen)
  sortUploadTransactionByCode(){
    if (this.supplierUploadTransactionSortedbyCode == false){
      this.supplierUploadTransactionSortedbyCode = true;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => { 
        if(a.tranCode < b.tranCode){
          return 1
        } else if (a.tranCode > b.tranCode) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsUploadTransactionData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyCode = false;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => { 
        if(a.tranCode > b.tranCode){
          return 1
        } else if (a.tranCode < b.tranCode) {
          return -1
        } else {
          return 0
        }});  
      this.tapsUploadTransactionData.data = sorted; 
    }
  }
  
  // Sort by Name (Supplier - Upload File Screen)
  sortUploadTransactionByName(){
    if (this.supplierUploadTransactionSortedbyName == false){
      this.supplierUploadTransactionSortedbyName = true;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => { 
        if(a.tranName < b.tranName){
          return 1
        } else if (a.tranName > b.tranName) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsUploadTransactionData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyName = false;
      let sorted = this.tapsUploadTransactionData.data.sort((a: any, b: any) => { 
        if(a.tranName > b.tranName){
          return 1
        } else if (a.tranName < b.tranName) {
          return -1
        } else {
          return 0
        }});  
      this.tapsUploadTransactionData.data = sorted; 
    }
  }

  // Checks if the transaction row is adjusted or not
  checkIfAdjusted(givenRow:any){
    if (givenRow == " ")
    {
      return "Yes"
    } else 
    {
      return "No"
    }
  }

  // Detects an empty line in a CSV file
  IsAnEmptyRow(transaction:any){
    let counter = 0;
    for (let transValue = 0; transValue < transaction.length; transValue++){
      if (transaction[transValue] == ""){
        counter = counter + 1;
      }
    }
    if (Object.keys(transaction).length == counter)
    {
      return true;
    } else {
      return false;
    }
  }

  // Must be checked with Carol that all the failure statements match the old systems
  checkTransactionRowForFailures(transaction:any){
    let returnedOutput = "";
    if (transaction.length < 5){
      returnedOutput = "Missing Data";
      return returnedOutput;
    }
    transaction[1] = transaction[1].replace(/\s+/g, '');
    if (transaction[1] == "" || transaction[1].length != 5){
        if (transaction[1].length > 5) {
          if (returnedOutput == ""){ 
            returnedOutput = "Invalid Agent (" + String(transaction[1]) + ")";
          } else {
            returnedOutput = returnedOutput + " and Agent (" + String(transaction[1]) + ")";
          }
        } else {
          if (returnedOutput == ""){ 
            returnedOutput = "Invalid Agent";
          } else {
            returnedOutput = returnedOutput + " and Agent";
          }
        }
    } else {
      if (this.checkIfMemberExist(transaction[1]) == false){
        if (returnedOutput == ""){ 
          returnedOutput = "Invalid Agent";
        } else {
          returnedOutput = returnedOutput + " and Agent";
        }
      } else {
        if (this.userSupplierTapsTradeCode != ""){
          if (this.userSupplierTapsExcludeFlag == "yes" && this.userSupplierTapsTradeCode.includes(String(transaction[1]))){
            if (returnedOutput == ""){ 
              returnedOutput = "Invalid Agent";
            } else {
              returnedOutput = returnedOutput + " and Agent";
            }
          }
        }
      }
    } 
    if (transaction[2] == ""){
        if (returnedOutput == ""){ 
          returnedOutput = "Invalid Operator Ref";
        } else {
          returnedOutput = returnedOutput + " and Operator Ref";
        }
    } else {
      if (transaction[2]?.length > 50){
        transaction[2] = "More than 50 chars"
        if (returnedOutput == ""){ 
          returnedOutput = "Invalid Operator Ref";
        } else {
          returnedOutput = returnedOutput + " and Operator Ref";
        }
      }
    }
    if (transaction[3] == "" || String(transaction[3]).replace(/\s/g, '') == ""){
        if (returnedOutput == ""){ 
          returnedOutput = "Invalid Lead Name";
        } else {
          returnedOutput = returnedOutput + " and Lead Name";
        }
    } else {
      if (transaction[3]?.length > 20){
        transaction[3] = transaction[3].substring(0, 20);
      }
    }
    if (transaction[4] == "" || transaction[4] == "Invalid Date" || this.isValidDate(transaction[4]) == false){
        if (returnedOutput == ""){ 
          returnedOutput = "Invalid Dept Date (" + String(transaction[4]) + ")";
        } else {
          returnedOutput = returnedOutput + " and Dept Date (" + String(transaction[4]) + ")";
        }
        transaction[4] = "01/01/2000";
    } else {
      if (this.userSupplierOnAtol == false) {
        const tempToday = new Date()
        const dateParts = transaction[4].split("/");
        if (dateParts.length == 3) {
          const day = parseInt(dateParts[0], 10);
          const month = parseInt(dateParts[1], 10);
          const year = parseInt(dateParts[2], 10);
          const tempDeptDate = new Date(year, month-1, day);
          if (tempDeptDate > tempToday){
            if (returnedOutput == ""){ 
              returnedOutput = "NotOnATOL";
            } else {
              returnedOutput = returnedOutput + " and NotOnATOL";
            }
          }
        } 
      } 
    }
    

    // Based on the file type we have different limit because of the format of the file 
    // TXT limit is 9999.99
    // CSV or XLSX limit is 999,999.99
    // Now we need to test it with real data to see if it passes to remove
    if (this.typeOfFileUpload == "TXT"){
      if (transaction[5] == "" || !this.isAFloatNumber(transaction[5]) || Number(transaction[5]) >= 100000  || Number(transaction[5]) <= -10000 || Number(transaction[5]) == 0 || transaction[5] == 'NaN'){
        if (returnedOutput == ""){ 
          returnedOutput = "Invalid Amount";
        } else {
          returnedOutput = returnedOutput + " and Amount";
        }
      }
    } else {
      if (transaction[5] == "" || !this.isAFloatNumber(transaction[5]) || Number(transaction[5]) >= 1000000  || Number(transaction[5]) <= -1000000 || Number(transaction[5]) == 0 || transaction[5] == 'NaN'){
        if (returnedOutput == ""){ 
          returnedOutput = "Invalid Amount";
        } else {
          returnedOutput = returnedOutput + " and Amount";
        }
      }
    }

    return returnedOutput;        
  }

  // Checks if Valid Date from uploaded TRA
  isValidDate(dateString: string): boolean {

    // Parse the date using Moment.js in strict mode to ensure exact format match
    const parsedDate = moment(dateString, this.possibleDateFormats, true);

    // Check if the parsed date is valid
    if (!parsedDate.isValid()) {
      return false; // Date is not valid according to Moment.js
    }

    // Define a reasonable range for the year
    const currentYear = moment().year();
    const reasonableRange = 100; // Considering the year is within 100 years from the current year

    // Extract the year from the parsed date
    const year = parsedDate.year();

    // Check if the year is within the reasonable range
    if (year < currentYear - reasonableRange || year > currentYear + reasonableRange) {
      return false; // Year is not within the reasonable range
    }

    // All checks passed, the date is valid
    return true;
  }

  // Check for duplicate transactions in the supplier uploaded file
  checkTransactionsForDuplicates(transactionsList:any){
    let sizeOfList:any = transactionsList.length;
    if (sizeOfList > 0){
      for (let counterX = 0; counterX < sizeOfList; counterX++){
        let tranCounter:any = 0;
        let tempTransactionX:any = transactionsList[counterX];
        for (let counterY = 0; counterY < sizeOfList; counterY++){
          let tempTransactionY:any = transactionsList[counterY];
          if ((tempTransactionX.reference == tempTransactionY.reference) 
              && (tempTransactionX.departure == tempTransactionY.departure)
              && (tempTransactionX.amount == tempTransactionY.amount)
              && (tempTransactionX.client == tempTransactionY.client)
              && (tempTransactionX.memberTradeCode == tempTransactionY.memberTradeCode)){
            tranCounter = Number(tranCounter) + 1;
          }
        }
        if (tranCounter >= 2){

          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  // This is a check to see if it's just imported file, if not then the viewToPay will be setup
  setupToPayAmount(uploadState:any){
    if (uploadState == "Imported"){
      this.viewToPay = parseFloat("0").toFixed(2);
    } else {
      this.viewToPay = Number(this.viewOverallAcceptedAmount) - Number(this.viewAdjustments);
      this.viewToPay = parseFloat(this.viewToPay).toFixed(2);
    }
  }

  // Used to generate uploaded file's values
  generateAgentReviewPeriodAndPaymentDue(date: any, paymentDays: any) {
    let tempDate = moment(date);
    let nextSaturday = moment(this.getNextDayOfTheWeek("Saturday", true, tempDate.toDate()));
    let rangeOfDates = nextSaturday.format('DD/MM/YYYY');
    let tempFriday = nextSaturday.clone().subtract(1, 'days');
    let collectionDate = tempFriday.clone().add(7, 'days');
    let paymentDueDate = collectionDate.clone().add(paymentDays, 'days');
    this.viewPaymentDue = paymentDueDate.format('DD/MM/YYYY');
    let nextWednesday = moment(this.getNextDayOfTheWeek("Wednesday", true, tempDate.toDate()));
    rangeOfDates += " - " + nextWednesday.format('DD/MM/YYYY');
    this.viewAgentReview = rangeOfDates;
  }

  // Generate just the payment due date (Operator-Upload File)
  generatePaymentDueDate(date:any,lateAdjustment:boolean){
    let tempDate = moment(date);
    let nextSaturday = moment(this.getNextDayOfTheWeek("Saturday", true, tempDate.toDate()));
    let uploadPeriod = nextSaturday.clone().subtract(1, 'days');
    let collectionDate = uploadPeriod.clone().add(7, 'days');
    let paymentDueDate;
    if (!lateAdjustment) {
      paymentDueDate = collectionDate.clone().add(this.userSupplierPaymentDays, 'days');
    } else {
      paymentDueDate = collectionDate.clone().add(7, 'days');
    }
    return paymentDueDate.format('DD/MM/YYYY');
  }

  // returns the payment due date (Admin-Late Adjustment)
  generateLateAdjustmentPaymentDueDate(date:any,paymentDays:any)
  {
    let tempDate = moment(date);
    let nextSaturday = moment(this.getNextDayOfTheWeek("Saturday", true, tempDate.toDate()));
    let uploadPeriod = nextSaturday.clone().subtract(1, 'days');
    let collectionDate = uploadPeriod.clone().add(7, 'days');
    let paymentDueDate;
    if (paymentDays === 7 || paymentDays === 14 || paymentDays === 28 || paymentDays === 39) {
      paymentDueDate = collectionDate.clone().add(paymentDays, 'days');
    } else {
      paymentDueDate = collectionDate.clone().add(7, 'days');
    }
    return paymentDueDate.format('DD/MM/YYYY');
  }

  // Download the Supplier File (Already loaded table in the "Upload File Screen")
  downloadSupplierUploadFile(){
    if (this.tapsUploadTransactionData.data.length > 0 || this.tapsUploadTransactionRejectedData.data.length > 0){
      try {     
        const exportMe: any = [];
        let oneLine: any = {};
        if (this.tapsUploadTransactionData.data.length > 0){
          this.tapsUploadTransactionData.data.forEach((item: any) => {
            oneLine = {};
            oneLine["Supplier"] = item.tranSupTapsId;
            oneLine["Supplier Name"] = item.tranSupplier;
            oneLine["Member"] = item.tranTapsId;
            oneLine["Member Name"] = item.tranAgent;
            oneLine["Bkg Reference"] = item.tranRef;
            oneLine["Departure Date"] = item.tranDepart;
            oneLine["Client Name"] = item.tranClient;
            oneLine["Warning"] = item.tranWarning;
            oneLine["Payment Date"] = item.tranPayment;
            oneLine["Value"] = this.getValidNumberToExcel(item.tranValue);
            oneLine["Collected"] = this.getValidNumberToExcel(item.tranCollectedValue);
            oneLine["Code"] = item.tranCode;
            oneLine["Reason"] = item.tranName;
            oneLine["Approval"] = item.tranApproval;
            oneLine["State"] =  item.tranState;
            oneLine["Collection"] = item.tranCollection;
            oneLine["Paid"] = item.tranPaid;
            exportMe.push(oneLine);
          });
        }
        if (this.tapsUploadTransactionRejectedData.data.length > 0){
          this.tapsUploadTransactionRejectedData.data.forEach((item: any) => {
            oneLine = {};
            oneLine["Supplier"] = item.tranSupTapsId;
            oneLine["Supplier Name"] = item.tranSupplier;
            oneLine["Member"] = item.tranTapsId;
            oneLine["Member Name"] = item.tranAgent;
            oneLine["Bkg Reference"] = item.tranRef;
            oneLine["Departure Date"] = item.tranDepart;
            oneLine["Client Name"] = item.tranClient;
            oneLine["Warning"] = item.tranWarning;
            oneLine["Payment Date"] = item.tranPayment;
            oneLine["Value"] = this.getValidNumberToExcel(item.tranValue);
            oneLine["Collected"] = this.getValidNumberToExcel(item.tranCollectedValue);
            oneLine["Code"] = item.tranCode;
            oneLine["Reason"] = item.tranName;
            oneLine["Approval"] = item.tranApproval;
            oneLine["State"] =  item.tranState;
            oneLine["Collection"] = item.tranCollection;
            oneLine["Paid"] = item.tranPaid;
            exportMe.push(oneLine);
          });
        }
        this.exportTapsReportAsExcelFile(exportMe, this.viewTransactionID, "J", "K", false);
      } catch (error) {
        this.sendMessageToDialog('', error, '');
      }
    }
  }

  // Open ups the file screen from the Supplier/Upload History/File
  openSupplierUploadedFilePage(upload:any){
    this.clearViewUploadFile();
    this.switchView('uploadFileScreenPage');
    if (upload.tranFailure == 'Deleted'){
      let fileFailureType = true;
      this.setupViewUploadFile(upload,fileFailureType);
    } else {
      let fileFailureType = false;
      this.setupViewUploadFile(upload,fileFailureType);
    }  
  }

  // Calls :: listPullDownFiles
  // Get all the commited files of the last few weeks (11 weeks) 
  setupPullDownFilePage(){
    this.includesPullDownMultipleTransactionFiles = false;
    this.pullDownFilePeriods = [];
    this.selectedPullDownFilePeriod = {};
    this.pullDownCommittedFiles = [];
    this.selectedPullDownCommittedFile = "";
    this.generatePullDownPeriods();
    let request = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation, 
      supplierTradeCode: this.userSupplierMisttaCode,
      uploadPeriodFrom: this.pullDownFilePeriods[this.pullDownFilePeriods.length-1].uploadPeriod,
      uploadPeriodTo: this.pullDownFilePeriods[0].uploadPeriod,
      token: Session.mySession.get('user').token
    };
    this.tapsService.listPullDownFiles(request).then((output: any) => {
      if (output["status"] == 'OK') {
        if (output["res"].length > 0){
          output["res"].forEach((transaction:any)=>{
            let indexOfUploadPeriod = this.pullDownFilePeriods.findIndex((period:any) => period.uploadPeriod == this.convertDate(new Date(transaction.uploadPeriod)));  
            if (indexOfUploadPeriod >= 0){ 
              this.pullDownFilePeriods[indexOfUploadPeriod].displayRow += " (" + transaction["committedTransactionIDs"] +")";
              if (transaction["committedTransactionIDs"].includes(',')){
                const tempListOfCommitedTransactionIDs = transaction["committedTransactionIDs"].split(',')
                tempListOfCommitedTransactionIDs.forEach((tempTransactionID:any)=>{
                  const tempInputTransaction:any = {
                    transactionID: tempTransactionID,
                    tradeCode: transaction["tradeCode"],
                    uploadPeriod: transaction["uploadPeriod"],
                  }
                  this.pullDownFilePeriods[indexOfUploadPeriod].transactions.push(tempInputTransaction);
                })
              } else {
                if (transaction["committedTransactionIDs"] != ""){
                  const tempCommittedTransactionID = transaction["committedTransactionIDs"]
                  const tempInputTransaction:any = {
                    transactionID: tempCommittedTransactionID,
                    tradeCode: transaction["tradeCode"],
                    uploadPeriod: transaction["uploadPeriod"],
                  }
                  this.pullDownFilePeriods[indexOfUploadPeriod].transactions.push(tempInputTransaction);
                }
              }
            }
          });
        }
      } else {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
    });  
  }

  // Generates the list of periods for the pull down page
  generatePullDownPeriods() {
    let generatedUploadPeriod: any = [];
    
    // Calculate the last Wednesday
    let today = moment();
    let daysSinceLastWednesday = (today.day() >= 3) ? today.day() - 3 : 7 - (3 - today.day());
    let tempLastWednesday = moment(today).subtract(daysSinceLastWednesday, 'days').startOf('day');
    
    // Calculate the last Saturday before this Wednesday
    let tempLastSaturday = moment(tempLastWednesday).subtract(4, 'days');

    // Prepare the first upload period row (Week Zero)
    let weekZero = this.prepareUploadPeriodRow(tempLastWednesday.toDate(), tempLastSaturday.toDate());
    generatedUploadPeriod.push(weekZero);

    // Generate the remaining periods (11 more weeks)
    for (let counter = 0; counter < 11; counter++) {
      let tempNextWed = moment(generatedUploadPeriod[generatedUploadPeriod.length - 1].period).subtract(7, 'days');
      let tempNextSat = moment(tempNextWed).subtract(4, 'days');
      let nextWeek = this.prepareUploadPeriodRow(tempNextWed.toDate(), tempNextSat.toDate());
      generatedUploadPeriod.push(nextWeek);
    }

    // Assign the generated periods to the pullDownFilePeriods variable
    this.pullDownFilePeriods = generatedUploadPeriod;
  }
  
  // Assists generatePullDownPeriods, to fill in the list
  prepareUploadPeriodRow(wed: any, sat: any) {
    // Convert the input dates to Moment.js objects for consistency
    let wedMoment = moment(wed);
    let satMoment = moment(sat);

    return {
      displayRow: satMoment.format('L') + " - " + wedMoment.format('L'), 
      period: wedMoment.toDate(),
      uploadPeriod: this.convertDate(this.subtrackDays(1, satMoment.toDate())), 
      fromDate: this.convertDate(satMoment.toDate()), 
      toDate: this.convertDate(wedMoment.toDate()),
      transactions: []
    };
  }

  // Check's if the given upload period includes multiple committed files
  checkIfMultipleCommittedFiles(){
    this.includesPullDownMultipleTransactionFiles = false;
    this.pullDownCommittedFiles = [];
    this.selectedPullDownCommittedFile = "";
    if (this.selectedPullDownFilePeriod.transactions.length > 0){
      this.includesPullDownMultipleTransactionFiles = true;
      for (let counter = 0; counter < this.selectedPullDownFilePeriod.transactions.length; counter ++){
        let tempTransactionRow:any = {};
        tempTransactionRow.transactionID = this.selectedPullDownFilePeriod.transactions[counter].transactionID;
        tempTransactionRow.tradeCode = this.selectedPullDownFilePeriod.transactions[counter].tradeCode;
        tempTransactionRow.uploadPeriod = this.selectedPullDownFilePeriod.transactions[counter].uploadPeriod;
        this.pullDownCommittedFiles.push(tempTransactionRow);
      }
    } 
  }

  // Calls :: getPullDownFile 
  // check's the download format (e.g xlsx) & It could also have multiple committed files (for each week) so we check for that as well 
  getPullDownFile(fileFormat:any){
    if (this.selectedPullDownFilePeriod.displayRow != "" && this.selectedPullDownFilePeriod.displayRow != null){
      if (this.includesPullDownMultipleTransactionFiles == true){
        if (this.selectedPullDownCommittedFile == ""){
          this.sendMessageToDialog('', 'Please fill in all the required data', '');
        } else {
          let request:any = {
            company: Session.mySession.getUser().company,
            tradeCode: Session.mySession.getUser().tradeCode,
            operation: Session.mySession.getUser().operation, 
            transactionID: this.selectedPullDownCommittedFile.transactionID,
            token: Session.mySession.get('user').token
          };
          this.tapsService.getPullDownFile(request).then((output: any) => {
            if (output["status"] == "OK") {
              this.downloadPullDownFile(output['res'],request.transactionID,fileFormat,output['collectionTotal'],output['numberOfTransactions']);
            } else {
              this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
            }      
          });
        }
      } else {
        if (this.selectedPullDownFilePeriod.transactions.length > 0){
          let request:any = {
            company: Session.mySession.getUser().company,
            tradeCode: Session.mySession.getUser().tradeCode,
            operation: Session.mySession.getUser().operation, 
            transactionID: this.selectedPullDownFilePeriod.transactions[0].committedTransactionIDs,
            token: Session.mySession.get('user').token
          };
          this.tapsService.getPullDownFile(request).then((output: any) => {
            if (output["status"] == "OK") {
              this.downloadPullDownFile(output['res'],request.transactionID,fileFormat,output['collectionTotal'],output['numberOfTransactions']);
            } else {
              this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
            }      
          });
        } else {
          this.sendMessageToDialog('', 'No committed files were found for that review period', '');
        }
      }      
    } else {
      this.sendMessageToDialog('', 'Please fill in all the required data', '');
    } 
  }

  // Downloads the selected format for the given file
  downloadPullDownFile(tapsUpload:any,fileName:any,fileFormat:any,collectionTotal:any, numberOfTransactions:any){    
    if (fileFormat == "Legacy"){         
      let finalStringOfFile = "HHH" + String(this.userSupplierMisttaCode).toUpperCase() + this.formatBankStatementDate(this.selectedPullDownFilePeriod.uploadPeriod);
      finalStringOfFile = finalStringOfFile.padEnd(39, ' ');
      tapsUpload.forEach((row:any)=>{
        let tempTransactionRow =  "TRA" + row.agentTradeCode +  
                                  String(row.operatorReference).toUpperCase().padEnd(9, " ") + ' ' +
                                  String(row.clientName).substring(0,6).toUpperCase().padEnd(6, " ") +
                                  this.formatBankStatementDate(row.tranDepartureDate) +
                                  this.formatBankStatementNumber(row.tranCollected,7) + 
                                  (row.code ? row.code : " ");
        finalStringOfFile = finalStringOfFile + "\r\n" + tempTransactionRow;
      });
      let footerString = "ZZZ" + this.formatBankStatementCounter(numberOfTransactions, 5) + this.formatBankStatementNumber(collectionTotal, 9);
      footerString = footerString.padEnd(39, ' ');
      finalStringOfFile = finalStringOfFile + "\r\n" + footerString;   
      finalStringOfFile = finalStringOfFile + "\r\n";
      let blob = new Blob([finalStringOfFile], { type: "text/plain;charset=utf-8" });
      saveAs(blob, fileName);
    } else { 
      const exportMe: any = [];
      let oneLine: any = {};
      tapsUpload.forEach((item: any) => {
        oneLine = {};
        oneLine["ABTA"] = item.agentTradeCode;
        oneLine["Name"] = item.agentName;
        oneLine["Reference"] = item.operatorReference;
        oneLine["Client"] = item.clientName;
        oneLine["Departure"] = item.tranDepartureDate;
        oneLine["Value"] = this.getValidNumberToExcel(item.tranCollected);
        oneLine["Code"] = item.code;
        exportMe.push(oneLine);
      });
      this.exportTapsReportAsExcelFile(exportMe, fileName, "F", "", true);
    }
    this.pageLoaded = true;    
  }

  // Convert file (reader) to list 
  readTxtFile(givenFile:any){
    return new Promise((resolve, _reject) => {
      let reader = new FileReader();
      reader.onload = (_e:any) => {
        let txt:any = reader.result;   
        let lines:any = txt.split(/\r?\n/);  
        lines = lines.filter((line:any) => line.trim() !== '');
        resolve(lines);
      }
      reader.readAsText(givenFile); 
    });    
  }
  
  // Calls :: getTapsAgentsList
  // List of all the branches (All members *except Q0000)
  // Also used to supply the Reporting Agents List
  setupSuppliersAgentsList(){
    this.tapsIncludedAgentPageListData.data = [];
    this.tapsExcludedAgentPageListData.data = [];
    this.tapsAgentPageListData.data = [];
    this.filteredAdminAgentsListData.data = [];
    this.pageLoaded = false;
    let request = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      token: Session.mySession.get('user').token
    }
    this.tapsService.getTapsAgentsList(request).then((output:any)=>{
      if (output.status == "OK"){
        let tempAllAgentList = new MatTableDataSource<any>();
        let tempIncludedAgentList = new MatTableDataSource<any>();
        let tempExcludedAgentList = new MatTableDataSource<any>();
        output.data.forEach((agent:any) => {
          if (agent.tradeCode != "Q0000"){
            tempAllAgentList.data.push(agent);
            if (agent.isTapsReg == 'no'){
              // tempExcludedAgentList.data.push(agent);
            } else {
              if (this.userSupplierTapsTradeCode == "" || !this.userSupplierTapsTradeCode.includes(agent.tradeCode) || this.userSupplierTapsExcludeFlag != 'yes'){
                tempIncludedAgentList.data.push(agent);
              } else {
                tempExcludedAgentList.data.push(agent);
              }
            }
          }
        });
        let sortedIncluded = tempIncludedAgentList.data.sort((a: any, b: any) => (a.branchName > b.branchName) ? 1 : -1); 
        sortedIncluded = sortedIncluded.filter((e: any, i: any) => sortedIncluded.findIndex((a: any) => a['branchName'] === e['branchName']) === i); 
        this.tapsIncludedAgentPageListData.data = sortedIncluded; 
        let sortedExcluded = tempExcludedAgentList.data.sort((a: any, b: any) => (a.branchName > b.branchName) ? 1 : -1); 
        sortedExcluded = sortedExcluded.filter((e: any, i: any) => sortedExcluded.findIndex((a: any) => a['branchName'] === e['branchName']) === i); 
        this.tapsExcludedAgentPageListData.data = sortedExcluded;
        let sortedAll = tempAllAgentList.data.sort((a: any, b: any) => (a.branchName > b.branchName) ? 1 : -1); 
        sortedAll = sortedAll.filter((e: any, i: any) => sortedAll.findIndex((a: any) => a['branchName'] === e['branchName']) === i); 
        this.tapsAgentPageListData.data = sortedAll;
        this.filteredAdminAgentsListData.data = sortedAll;
      } else {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
      }
      this.pageLoaded = true;
    }).catch((error: any) => {
        this.pageLoaded = true;
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
    }); 
  }

  // Sort by Name (Supplier - Agent List) 
  sortAgentByName(excluded:boolean){
    if (excluded == false) {
      if (this.supplierAgentListSortedbyName == false){
        this.supplierAgentListSortedbyName = true;
        let sorted = this.tapsIncludedAgentPageListData.data.sort((a: any, b: any) => (a.branchName < b.branchName) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['branchName'] === e['branchName']) === i); 
        this.tapsIncludedAgentPageListData.data = sorted; 
      } else {
        this.supplierAgentListSortedbyName = false;
        let sorted = this.tapsIncludedAgentPageListData.data.sort((a: any, b: any) => (a.branchName > b.branchName) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['branchName'] === e['branchName']) === i); 
        this.tapsIncludedAgentPageListData.data = sorted; 
      }
    } else {
      if (this.supplierAgentListSortedbyName == false){
        this.supplierAgentListSortedbyName = true;
        let sorted = this.tapsExcludedAgentPageListData.data.sort((a: any, b: any) => (a.branchName < b.branchName) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['branchName'] === e['branchName']) === i); 
        this.tapsExcludedAgentPageListData.data = sorted; 
      } else {
        this.supplierAgentListSortedbyName = false;
        let sorted = this.tapsExcludedAgentPageListData.data.sort((a: any, b: any) => (a.branchName > b.branchName) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['branchName'] === e['branchName']) === i); 
        this.tapsExcludedAgentPageListData.data = sorted; 
      }
    }
  }

  // Sort by TradeCode (Supplier - Agent List)
  sortAgentByTradeCode(excluded:boolean){
    if (excluded == false) {
      if (this.supplierAgentListSortedbyTradeCode == false){
        this.supplierAgentListSortedbyTradeCode = true;
        let sorted = this.tapsIncludedAgentPageListData.data.sort((a: any, b: any) => (a.tradeCode < b.tradeCode) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['tradeCode'] === e['tradeCode']) === i); 
        this.tapsIncludedAgentPageListData.data = sorted; 
      } else {
        this.supplierAgentListSortedbyTradeCode = false;
        let sorted = this.tapsIncludedAgentPageListData.data.sort((a: any, b: any) => (a.tradeCode > b.tradeCode) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['tradeCode'] === e['tradeCode']) === i); 
        this.tapsIncludedAgentPageListData.data = sorted; 
      }
    } else {
      if (this.supplierAgentListSortedbyTradeCode == false){
        this.supplierAgentListSortedbyTradeCode = true;
        let sorted = this.tapsExcludedAgentPageListData.data.sort((a: any, b: any) => (a.tradeCode < b.tradeCode) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['tradeCode'] === e['tradeCode']) === i); 
        this.tapsExcludedAgentPageListData.data = sorted; 
      } else {
        this.supplierAgentListSortedbyTradeCode = false;
        let sorted = this.tapsExcludedAgentPageListData.data.sort((a: any, b: any) => (a.tradeCode > b.tradeCode) ? 1 : -1); 
        sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a['tradeCode'] === e['tradeCode']) === i); 
        this.tapsExcludedAgentPageListData.data = sorted; 
      }
    }
  }

  // Exports to Excel the list of 'Suppliers'
  memberExportSupplierList(){
    if (this.activeSuppliersListData.data.length == 0) {
      this.sendMessageToDialog("","No records found to export","");
    } else { 
        let reportName = Session.mySession.getUser().tradeCode + "_Sings_TAPS_Suppliers"
        const exportMe: any = [];
        let oneLine: any = {};
        this.activeSuppliersListData.data.forEach((item: any) => {
          oneLine = {};
          oneLine["Name"] = item.supplierNameM;
          oneLine["TAPS Identi."] = item.misttaCode;
          oneLine["Telephone"] = item.telephoneTAPS;
          oneLine["Email"] = item.emailTAPS;
          exportMe.push(oneLine);
        });
      this.exportTapsReportAsExcelFile(exportMe, reportName, "", "", false);
    }
  }


  //////////////////////////////////
  //
  // Supplier Functionalities Above
  //
  //////////////////////////////////


  //////////////////////////////////
  //
  // Reporting Functionalities Below
  //
  //////////////////////////////////


  // When user selects a period the dates will adjust (Admin/Suppliers/Members)
  setupDatesForReportingPeriod(){
    this.reportingDateFrom = this.reportingDatePeriod.fromDate;
    this.reportingDateTo = this.reportingDatePeriod.toDate;
  }

  // When user selects a period the dates will adjust (Admin)
  setupDatesForAnalysisReportingPeriod(){
    this.adminAnalysisStartDate = this.reportingDatePeriod.fromDate;
    this.adminAnalysisEndDate = this.reportingDatePeriod.toDate;
  }

  // Clears all supplier report data to then call for new request
  clearSupplierReportData(){
    this.switchToGeneratedReportsView = false;
    this.tapsSupplierReportListData.data = [];
    this.supplierReportOriginalTotal = 0;
    this.supplierReportAdjustmentsTotal = 0;
    this.supplierReportCollectionTotal = 0;
    this.supplierUploadTransactionSortedbyAgent = false;
    this.supplierUploadTransactionSortedbyTapsId = false;
    this.supplierUploadTransactionSortedbyRef = false;
    this.supplierUploadTransactionSortedbyDepart = false;
    this.supplierUploadTransactionSortedbyClient = false;
    this.supplierUploadTransactionSortedbyWarning = false;
    this.supplierUploadTransactionSortedbyValue = false;
    this.supplierUploadTransactionSortedbyAdjust = false;
    this.supplierUploadTransactionSortedbyCollection = false;
    this.supplierUploadTransactionSortedbyCode = false;
    this.supplierUploadTransactionSortedbyName = false;
    this.supplierUploadTransactionSortedbyPayment = false;
  }

  // Calls :: getTapsSupplierReport 
  // Request attributes: { agentTradeCode | uploadPeriodFrom | uploadPeriodTo | failedTransactions }
  supplierReport(){
    this.clearSupplierReportData()
    if ((this.selectedAgent != "" && this.selectedAgent != null) && (this.reportingDateFrom != null && this.reportingDateFrom != "") && 
        (this.reportingDateTo != null && this.reportingDateTo != "")){
          if (this.reportingDateFrom > this.reportingDateTo) {
            this.sendMessageToDialog("","Please ensure that the 'Date-From' date is earlier than 'Date-To' date","");
          } else {
            this.pageLoaded = false;
            let request:any = {
              company: Session.mySession.getUser().company,
              tradeCode: Session.mySession.getUser().tradeCode,
              operation: Session.mySession.getUser().operation,
              supplierTradeCode: this.userSupplierMisttaCode,
              agentTradeCode: this.selectedAgent,
              startDate: this.convertDate(this.reportingDateFrom),
              endDate: this.convertDate(this.reportingDateTo),
              rejectedTransactions: this.onlyRejectedTransactions,
              format: "csv",
              token: Session.mySession.get('user').token,
              hideDialog: true
            }
            const divideBy = 86400000;
            const dateFrom = new Date(this.reportingDateFrom);
            const dateTo = new Date(this.reportingDateTo);
            const rangeOfDays = (dateTo.getTime() - dateFrom.getTime()) / (divideBy);
            if (rangeOfDays > 6) { // MORE THAN 7 DAYS REPORT
              request.format = "csv";
              this.tapsService.generateTapsSupplierReport(request).then((output: any) => {
                if (output.status == "OK"){
                  Session.mySession.resetTimersOnBookingValues().then((res:any)=>{
                    this.pageLoaded = true;
                    this.generatedReportTitle = "Generating report in progress. Please wait .. "
                    this.generatedReportBadgeHidden = true;
                    AsynchRequests.myAsynchReq.addJobToWatch(request, output.jobID)
                    this.checkIfJobIsDone().then((res:any)=>{
                      this.checkIfGeneratedReportHasTransactions(output.jobID,'tapsSupplierReport')
                    })
                  })
                } else {
                  this.sendMessageToDialog('', 'Generating report has failed (' + output.status + ')', output);
                }
              }).catch((error: any) => {
                this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
              })
            } else { // LESS THAN 7 DAYS REPORT
              request.format = "list";
              this.tapsService.getTapsSupplierReport(request).then((output:any)=>{
                if (output.status == "OK"){
                  if (output.format == "csv" || output.format == "xlsx"){
                    var byteString = atob(output["fileContent"]);
                    var ab = new ArrayBuffer(byteString.length);
                    var ia = new Uint8Array(ab);
                    for (var i = 0; i < byteString.length; i++) {
                      ia[i] = byteString.charCodeAt(i);
                    }
                    const data = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
                    saveAs(data, output["reportName"]); 
                  } else {
                    this.supplierReportOriginalTotal = output.originalTotal
                    this.supplierReportAdjustmentsTotal = output.adjustments;
                    this.supplierReportCollectionTotal = output.collectionTotal;
                    this.tapsSupplierReportListData.data = [...output.res];
                  }
                } else {
                  this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
                }
                this.pageLoaded = true;
              }).catch((error: any) => {
                  this.pageLoaded = true;
                  this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
              });
            }
            
          }
    } else {
      this.sendMessageToDialog("","Please fill in all the required data","");
    }
  }

  // Exports the listed report in an .xlsx format
  supplierExportReport(){
    if (this.tapsSupplierReportListData.data.length > 0){
      let reportName = this.getReportName();
      const exportMe: any = [];
      let oneLine: any = {};
      this.tapsSupplierReportListData.data.forEach((item: any) => {
        oneLine = {};
        oneLine["Supplier"] = item.tranSupTapsId;
        oneLine["Supplier Name"] = item.tranSupplier;
        oneLine["Member"] = item.tranTapsId;
        oneLine["Member Name"] = item.tranAgent;
        oneLine["Bkg Reference"] = item.tranRef;
        oneLine["Departure Date"] = item.tranDepart;
        oneLine["Client Name"] = item.tranClient;
        oneLine["Warning"] = item.tranWarning;
        oneLine["Payment Date"] = item.tranPayment;
        oneLine["Value"] = this.getValidNumberToExcel(item.tranValue);
        oneLine["Collected"] = this.getValidNumberToExcel(item.tranCollectedValue);
        oneLine["Code"] = item.tranCode;
        oneLine["Reason"] = item.tranName;
        oneLine["Approval"] = item.tranApproval;
        oneLine["State"] = item.tranState;
        oneLine["Collection"] = item.tranCollection;
        oneLine["Paid"] = item.tranPaid;
        exportMe.push(oneLine);
      });
      this.exportTapsReportAsExcelFile(exportMe, reportName, "J", "K", false);
    } else {
      this.sendMessageToDialog("","There are no transactions listed to export","");
    }
  }

  // Sort by Agent (Supplier - Report)
  sortSupplierReportByAgent(){
    if (this.supplierUploadTransactionSortedbyAgent == false){
      this.supplierUploadTransactionSortedbyAgent = true;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => { 
        if(a.tranAgent < b.tranAgent){
          return 1
        } else if (a.tranAgent > b.tranAgent) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsSupplierReportListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyAgent = false;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => { 
        if(a.tranAgent > b.tranAgent){
          return 1
        } else if (a.tranAgent < b.tranAgent) {
          return -1
        } else {
          return 0
        }});  
      this.tapsSupplierReportListData.data = sorted; 
    }
  }

  // Sort by Taps ID (Supplier - Report)
  sortSupplierReportByTapsId(){
    if (this.supplierUploadTransactionSortedbyTapsId == false){
      this.supplierUploadTransactionSortedbyTapsId = true;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => { 
        if(a.tranTapsId < b.tranTapsId){
          return 1
        } else if (a.tranTapsId > b.tranTapsId) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsSupplierReportListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyTapsId = false;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => { 
        if(a.tranTapsId > b.tranTapsId){
          return 1
        } else if (a.tranTapsId < b.tranTapsId) {
          return -1
        } else {
          return 0
        }});  
      this.tapsSupplierReportListData.data = sorted; 
    }
  }
  
  // Sort by Ref (Supplier - Report)
  sortSupplierReportByRef(){
    if (this.supplierUploadTransactionSortedbyRef == false){
      this.supplierUploadTransactionSortedbyRef = true;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => { 
        if(a.tranRef < b.tranRef){
          return 1
        } else if (a.tranRef > b.tranRef) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsSupplierReportListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyRef = false;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => { 
        if(a.tranRef > b.tranRef){
          return 1
        } else if (a.tranRef < b.tranRef) {
          return -1
        } else {
          return 0
        }});  
      this.tapsSupplierReportListData.data = sorted; 
    }
  }

  // Sort by Depart (Supplier - Report)
  sortSupplierReportByDepart(){
    if (this.supplierUploadTransactionSortedbyDepart == false){ 
      this.supplierUploadTransactionSortedbyDepart = true;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => {
        const arr1 = a.tranDepart.split('/');
        const arr2 = b.tranDepart.split('/');
        let aDate = new Date(parseInt(arr1[2]), parseInt(arr1[1]), parseInt(arr1[0])).getTime()
        let bDate = new Date(parseInt(arr2[2]), parseInt(arr2[1]), parseInt(arr2[0])).getTime()
        if (aDate < bDate) {
          return -1;
        } else if (aDate > bDate) {
          return 1;
        } else {
          return 0;
        }
      });
      this.tapsSupplierReportListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyDepart = false;
      let sortedByDepart = this.tapsSupplierReportListData.data.sort((a: any, b: any) => {
        const arr1 = a.tranDepart.split('/');
        const arr2 = b.tranDepart.split('/');
        let aDate = new Date(parseInt(arr1[2]), parseInt(arr1[1]), parseInt(arr1[0])).getTime()
        let bDate = new Date(parseInt(arr2[2]), parseInt(arr2[1]), parseInt(arr2[0])).getTime()
        if (aDate < bDate) {
          return 1;
        } else if (aDate > bDate) {
          return -1;
        } else {
          return 0;
        }
      }); 
      this.tapsSupplierReportListData.data = sortedByDepart; 
    }   
  }

  // Sort by Client (Supplier - Report)
  sortSupplierReportByClient(){
    if (this.supplierUploadTransactionSortedbyClient == false){
      this.supplierUploadTransactionSortedbyClient = true;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => { 
        if(a.tranClient < b.tranClient){
          return 1
        } else if (a.tranClient > b.tranClient) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsSupplierReportListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyClient = false;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => { 
        if(a.tranClient > b.tranClient){
          return 1
        } else if (a.tranClient < b.tranClient) {
          return -1
        } else {
          return 0
        }});  
      this.tapsSupplierReportListData.data = sorted; 
    }
  }

  // Sort by Payment (Supplier - Report)
  sortSupplierReportByPayment() {
    if (this.supplierUploadTransactionSortedbyPayment == false){ 
      this.supplierUploadTransactionSortedbyPayment = true;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => {
        const arr1 = a.tranPayment.split('/');
        const arr2 = b.tranPayment.split('/');
        let aDate = new Date(parseInt(arr1[2]), parseInt(arr1[1]), parseInt(arr1[0])).getTime()
        let bDate = new Date(parseInt(arr2[2]), parseInt(arr2[1]), parseInt(arr2[0])).getTime()
        if (aDate < bDate) {
          return -1;
        } else if (aDate > bDate) {
          return 1;
        } else {
          return 0;
        }
      });
      this.tapsSupplierReportListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyPayment = false;
      let sortedByDepart = this.tapsSupplierReportListData.data.sort((a: any, b: any) => {
        const arr1 = a.tranPayment.split('/');
        const arr2 = b.tranPayment.split('/');
        let aDate = new Date(parseInt(arr1[2]), parseInt(arr1[1]), parseInt(arr1[0])).getTime()
        let bDate = new Date(parseInt(arr2[2]), parseInt(arr2[1]), parseInt(arr2[0])).getTime()
        if (aDate < bDate) {
          return 1;
        } else if (aDate > bDate) {
          return -1;
        } else {
          return 0;
        }
      }); 
      this.tapsSupplierReportListData.data = sortedByDepart; 
    }   
  }

  // Sort by Warning (Supplier - Report)
  sortSupplierReportByWarning(){
    if (this.supplierUploadTransactionSortedbyWarning == false){
      this.supplierUploadTransactionSortedbyWarning = true;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => { 
        if(a.tranWarning < b.tranWarning){
          return 1
        } else if (a.tranWarning > b.tranWarning) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsSupplierReportListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyWarning = false;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => { 
        if(a.tranWarning > b.tranWarning){
          return 1
        } else if (a.tranWarning < b.tranWarning) {
          return -1
        } else {
          return 0
        }});  
      this.tapsSupplierReportListData.data = sorted; 
    }
  }

  // Sort by Value (Supplier - Report)
  sortSupplierReportByValue(){
    if (this.supplierUploadTransactionSortedbyValue == false){
      this.supplierUploadTransactionSortedbyValue = true;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => { 
        let firstValue = Number(a.tranValue); let secondValue = Number(b.tranValue);
        if(firstValue < secondValue){
          return 1
        } else if (firstValue > secondValue) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsSupplierReportListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyValue = false;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => { 
        let firstValue = Number(a.tranValue); let secondValue = Number(b.tranValue);
        if(firstValue > secondValue){
          return 1
        } else if (firstValue < secondValue) {
          return -1
        } else {
          return 0
        }});  
      this.tapsSupplierReportListData.data = sorted; 
    }
  }

  // Sort by Adjust (Supplier - Report)
  sortSupplierReportByAdjust(){
    if (this.supplierUploadTransactionSortedbyAdjust == false){
      this.supplierUploadTransactionSortedbyAdjust = true;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => { 
        if(a.tranAdjust < b.tranAdjust){
          return 1
        } else if (a.tranAdjust > b.tranAdjust) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsSupplierReportListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyAdjust = false;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => { 
        if(a.tranAdjust > b.tranAdjust){
          return 1
        } else if (a.tranAdjust < b.tranAdjust) {
          return -1
        } else {
          return 0
        }});  
      this.tapsSupplierReportListData.data = sorted; 
    }
  }

  // Sort by Collection (Supplier - Report)
  sortSupplierReportByCollection(){
    if (this.supplierUploadTransactionSortedbyCollection == false){
      this.supplierUploadTransactionSortedbyCollection = true;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => { 
        let firstValue = Number(a.tranCollectedValue); let secondValue = Number(b.tranCollectedValue);
        if(firstValue < secondValue){
          return 1
        } else if (firstValue > secondValue) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsSupplierReportListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyCollection = false;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => { 
        let firstValue = Number(a.tranCollectedValue); let secondValue = Number(b.tranCollectedValue);
        if(firstValue > secondValue){
          return 1
        } else if (firstValue < secondValue) {
          return -1
        } else {
          return 0
        }});  
      this.tapsSupplierReportListData.data = sorted; 
    }
  }
  
  // Sort by Code (Supplier - Report)
  sortSupplierReportByCode(){
    if (this.supplierUploadTransactionSortedbyCode == false){
      this.supplierUploadTransactionSortedbyCode = true;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => { 
        if(a.tranCode < b.tranCode){
          return 1
        } else if (a.tranCode > b.tranCode) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsSupplierReportListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyCode = false;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => { 
        if(a.tranCode > b.tranCode){
          return 1
        } else if (a.tranCode < b.tranCode) {
          return -1
        } else {
          return 0
        }});  
      this.tapsSupplierReportListData.data = sorted; 
    }
  }
  
  // Sort by Name (Supplier - Report)
  sortSupplierReportByName(){
    if (this.supplierUploadTransactionSortedbyName == false){
      this.supplierUploadTransactionSortedbyName = true;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => { 
        if(a.tranName < b.tranName){
          return 1
        } else if (a.tranName > b.tranName) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsSupplierReportListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyName = false;
      let sorted = this.tapsSupplierReportListData.data.sort((a: any, b: any) => { 
        if(a.tranName > b.tranName){
          return 1
        } else if (a.tranName < b.tranName) {
          return -1
        } else {
          return 0
        }});  
      this.tapsSupplierReportListData.data = sorted; 
    }
  }

  // Resets the data in Reporting & Analysis Reporting (Member)
  resetMemberReportData(){
    this.switchToGeneratedReportsView = false;
    this.reportingDateFrom = "";
    this.reportingDateTo = "";
    this.reportingDatePeriod = ""
    this.selectedAgentTradeCode = Session.mySession.getUser().tradeCode;
    this.selectedSupplierTradeCode = "all";
    this.memberAnalysisReportYear = "";
    this.memberIncludeBranches = false;
  }

  // Initialise report data for Reporting (Member)
  clearMemberReportData(){
    this.memberReportOriginalTotal = 0;
    this.memberReportAdjustmentsTotal = 0;
    this.memberReportCollectionTotal = 0;
    this.tapsReportMemberListData.data = [];
    this.supplierUploadTransactionSortedbySupplier = false;
    this.supplierUploadTransactionSortedbySupId = false;
    this.supplierUploadTransactionSortedbyAgent = false;
    this.supplierUploadTransactionSortedbyTapsId = false;
    this.supplierUploadTransactionSortedbyRef = false;
    this.supplierUploadTransactionSortedbyDepart = false;
    this.supplierUploadTransactionSortedbyClient = false;
    this.supplierUploadTransactionSortedbyWarning = false;
    this.supplierUploadTransactionSortedbyValue = false;
    this.supplierUploadTransactionSortedbyAdjust = false;
    this.supplierUploadTransactionSortedbyCollection = false;
    this.supplierUploadTransactionSortedbyCode = false;
    this.supplierUploadTransactionSortedbyName = false;
    this.supplierUploadTransactionSortedbyPayment = false;
  }

  // Calls :: getTapsMemberReport 
  // Request attributes: { agentName | supplierTradeCode (e.g 'all') | uploadPeriodFrom | uploadPeriodTo }
  memberReport(){
    this.clearMemberReportData();
    if (this.selectedAgentTradeCode != "" && this.selectedAgentTradeCode != null && this.selectedSupplierTradeCode != "" && this.selectedSupplierTradeCode != null &&
       (this.reportingDateFrom != "" && this.reportingDateFrom != null) && 
       (this.reportingDateTo != "" && this.reportingDateTo != null)){
        if (this.reportingDateFrom > this.reportingDateTo) {
          this.sendMessageToDialog("","Please ensure that the 'Date-From' date is earlier than 'Date-To' date","");
        } else {
          let validSupplier:boolean = false;
          let validAgent:boolean = false;
          if (this.selectedSupplierTradeCode != "all"){
            this.suppliersListData.data.forEach((tempSupplier:any)=>{
              if (tempSupplier.misttaCode == this.selectedSupplierTradeCode){
                validSupplier = true;
              }
            })
          } else {
            validSupplier = true;
          }
          if (this.selectedAgentTradeCode != Session.mySession.getUser().tradeCode){
            this.listOfChildrenAgentsTradeCode.forEach((tempAgent:any)=>{
              if (tempAgent.tradeCode == this.selectedAgentTradeCode){
                validAgent = true;
              }
            })
          } else {
            validAgent = true;
          }
          if (validSupplier != false) {
            if (validAgent != false) {
              this.pageLoaded = false;
              let request:any = {
                company: Session.mySession.getUser().company,
                tradeCode: Session.mySession.getUser().tradeCode,
                operation: Session.mySession.getUser().operation,
                supplierTradeCode: this.selectedSupplierTradeCode,
                agentTradeCode: this.selectedAgentTradeCode,
                startDate: this.convertDate(this.reportingDateFrom),
                endDate: this.convertDate(this.reportingDateTo),
                futurePreview: false,
                format: "csv",
                includeBranches: this.memberIncludeBranches,
                token: Session.mySession.get('user').token,
                hideDialog: true
              }
              if (this.reportingDatePeriod.displayRow == "Preview Future Transactions (for review only)") 
              {
                request.futurePreview = "true";
              }
              const divideBy = 86400000;
              const dateFrom = new Date(this.reportingDateFrom);
              const dateTo = new Date(this.reportingDateTo);
              const rangeOfDays = (dateTo.getTime() - dateFrom.getTime()) / (divideBy);
              if (rangeOfDays > 6) { // MORE THAN 7 DAYS REPORT 
                request.format = "csv";
                this.tapsService.generateTapsMemberReport(request).then((output: any) => {
                  if (output.status == "OK"){
                    Session.mySession.resetTimersOnBookingValues().then((res:any)=>{
                      this.pageLoaded = true;
                      this.generatedReportTitle = "Generating report in progress. Please wait .. "
                      this.generatedReportBadgeHidden = true;
                      AsynchRequests.myAsynchReq.addJobToWatch(request, output.jobID)
                      this.checkIfJobIsDone().then((res:any)=>{
                        this.checkIfGeneratedReportHasTransactions(output.jobID,'tapsMemberReport')
                      })
                    })
                  } else {
                    this.sendMessageToDialog('', 'Generating report has failed (' + output.status + ')', output);
                  }
                }).catch((error: any) => {
                  this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
                })
              } else { // LESS THAN 7 DAYS REPORT 
                request.format = "list";
                this.tapsService.getTapsMemberReport(request).then((output:any)=>{
                  if (output.status == "OK"){ 
                    this.pageLoaded = true;
                    if (output.format == "csv" || output.format == "xlsx"){
                      var byteString = atob(output["fileContent"]);
                      var ab = new ArrayBuffer(byteString.length);
                      var ia = new Uint8Array(ab);
                      for (var i = 0; i < byteString.length; i++) {
                        ia[i] = byteString.charCodeAt(i);
                      }
                      const data = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
                      saveAs(data, output["reportName"]); 
                    } else{
                      if (output["res"].length > 0) {
                        this.viewToPay = output["collectionTotal"];
                        this.viewAdjustments = output["adjustments"];
                        this.memberReportOriginalTotal = output["originalTotal"];
                        this.memberReportAdjustmentsTotal = output["adjustments"];
                        this.memberReportCollectionTotal = output["collectionTotal"];
                        this.tapsReportMemberListData.data = [...output["res"]];
                      }
                    }
                  } else {
                    this.pageLoaded = true;
                    this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
                  }
                }).catch((error: any) => {
                    this.pageLoaded = true;
                    this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
                });
              }
            
            } else {
              this.sendMessageToDialog("","Please fill in a valid agent from the Agent's list","");
            }
          } else {
            this.sendMessageToDialog("","Please fill in a valid supplier from the Supplier's list","");
          }
      }
    } else {
      this.sendMessageToDialog("","Please fill in all the required data","");
    }
  }

  // Sort by Supplier (Member - Report)
  sortMemberReportBySupplier(){
    if (this.supplierUploadTransactionSortedbySupplier == false){
      this.supplierUploadTransactionSortedbySupplier = true;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        if(a.tranSupplier < b.tranSupplier){
          return 1
        } else if (a.tranSupplier > b.tranSupplier) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportMemberListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbySupplier = false;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        if(a.tranSupplier > b.tranSupplier){
          return 1
        } else if (a.tranSupplier < b.tranSupplier) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportMemberListData.data = sorted; 
    }
  }

  // Sort by Supplier Taps ID (Member - Report)
  sortMemberReportBySupplierTapsId(){
    if (this.supplierUploadTransactionSortedbySupId == false){
      this.supplierUploadTransactionSortedbySupId = true;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        if(a.tranSupTapsId < b.tranSupTapsId){
          return 1
        } else if (a.tranSupTapsId > b.tranSupTapsId) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportMemberListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbySupId = false;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        if(a.tranSupTapsId > b.tranSupTapsId){
          return 1
        } else if (a.tranSupTapsId < b.tranSupTapsId) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportMemberListData.data = sorted; 
    }
  }

  // Sort by Taps ID (Member - Report)
  sortMemberReportByTapsId(){
    if (this.supplierUploadTransactionSortedbyTapsId == false){
      this.supplierUploadTransactionSortedbyTapsId = true;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        if(a.tranTapsId < b.tranTapsId){
          return 1
        } else if (a.tranTapsId > b.tranTapsId) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportMemberListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyTapsId = false;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        if(a.tranTapsId > b.tranTapsId){
          return 1
        } else if (a.tranTapsId < b.tranTapsId) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportMemberListData.data = sorted; 
    }
  }

  // Sort by Agent (Member - Report)
  sortMemberReportByAgent(){
    if (this.supplierUploadTransactionSortedbyAgent == false){
      this.supplierUploadTransactionSortedbyAgent = true;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        if(a.tranAgent < b.tranAgent){
          return 1
        } else if (a.tranAgent > b.tranAgent) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportMemberListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyAgent = false;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        if(a.tranAgent > b.tranAgent){
          return 1
        } else if (a.tranAgent < b.tranAgent) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportMemberListData.data = sorted; 
    }
  }
  
  // Sort by Ref (Member - Report)
  sortMemberReportByRef(){
    if (this.supplierUploadTransactionSortedbyRef == false){
      this.supplierUploadTransactionSortedbyRef = true;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        if(a.tranRef < b.tranRef){
          return 1
        } else if (a.tranRef > b.tranRef) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportMemberListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyRef = false;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        if(a.tranRef > b.tranRef){
          return 1
        } else if (a.tranRef < b.tranRef) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportMemberListData.data = sorted; 
    }
  }

  // Sort by Depart (Member - Report)
  sortMemberReportByDepart(){
    if (this.supplierUploadTransactionSortedbyDepart == false){ 
      this.supplierUploadTransactionSortedbyDepart = true;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => {
        const arr1 = a.tranDepart.split('/');
        const arr2 = b.tranDepart.split('/');
        let aDate = new Date(parseInt(arr1[2]), parseInt(arr1[1]), parseInt(arr1[0])).getTime()
        let bDate = new Date(parseInt(arr2[2]), parseInt(arr2[1]), parseInt(arr2[0])).getTime()
        if (aDate < bDate) {
          return -1;
        } else if (aDate > bDate) {
          return 1;
        } else {
          return 0;
        }
      });
      this.tapsReportMemberListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyDepart = false;
      let sortedByDepart = this.tapsReportMemberListData.data.sort((a: any, b: any) => {
        const arr1 = a.tranDepart.split('/');
        const arr2 = b.tranDepart.split('/');
        let aDate = new Date(parseInt(arr1[2]), parseInt(arr1[1]), parseInt(arr1[0])).getTime()
        let bDate = new Date(parseInt(arr2[2]), parseInt(arr2[1]), parseInt(arr2[0])).getTime()
        if (aDate < bDate) {
          return 1;
        } else if (aDate > bDate) {
          return -1;
        } else {
          return 0;
        }
      }); 
      this.tapsReportMemberListData.data = sortedByDepart; 
    }   
  }

  // Sort by Client (Member - Report)
  sortMemberReportByClient(){
    if (this.supplierUploadTransactionSortedbyClient == false){
      this.supplierUploadTransactionSortedbyClient = true;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        if(a.tranClient < b.tranClient){
          return 1
        } else if (a.tranClient > b.tranClient) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportMemberListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyClient = false;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        if(a.tranClient > b.tranClient){
          return 1
        } else if (a.tranClient < b.tranClient) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportMemberListData.data = sorted; 
    }
  }

  // Sort by Collection Date (Member - Report)
  sortMemberReportByCollectionDate() {
    if (this.supplierUploadTransactionSortedbyPayment == false){ 
      this.supplierUploadTransactionSortedbyPayment = true;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => {
        const arr1 = a.tranCollectionDate.split('/');
        const arr2 = b.tranCollectionDate.split('/');
        let aDate = new Date(parseInt(arr1[2]), parseInt(arr1[1]), parseInt(arr1[0])).getTime()
        let bDate = new Date(parseInt(arr2[2]), parseInt(arr2[1]), parseInt(arr2[0])).getTime()
        if (aDate < bDate) {
          return -1;
        } else if (aDate > bDate) {
          return 1;
        } else {
          return 0;
        }
      });
      this.tapsReportMemberListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyPayment = false;
      let sortedByDepart = this.tapsReportMemberListData.data.sort((a: any, b: any) => {
        const arr1 = a.tranCollectionDate.split('/');
        const arr2 = b.tranCollectionDate.split('/');
        let aDate = new Date(parseInt(arr1[2]), parseInt(arr1[1]), parseInt(arr1[0])).getTime()
        let bDate = new Date(parseInt(arr2[2]), parseInt(arr2[1]), parseInt(arr2[0])).getTime()
        if (aDate < bDate) {
          return 1;
        } else if (aDate > bDate) {
          return -1;
        } else {
          return 0;
        }
      }); 
      this.tapsReportMemberListData.data = sortedByDepart; 
    }   
  }

  // Sort by Value (Member - Report)
  sortMemberReportByValue(){
    if (this.supplierUploadTransactionSortedbyValue == false){
      this.supplierUploadTransactionSortedbyValue = true;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        let firstValue = Number(a.tranValue); let secondValue = Number(b.tranValue);
        if(firstValue < secondValue){
          return 1
        } else if (firstValue > secondValue) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportMemberListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyValue = false;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        let firstValue = Number(a.tranValue); let secondValue = Number(b.tranValue);
        if(firstValue > secondValue){
          return 1
        } else if (firstValue < secondValue) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportMemberListData.data = sorted; 
    }
  }

  // Sort by Adjust (Member - Report)
  sortMemberReportByAdjust(){
    if (this.supplierUploadTransactionSortedbyAdjust == false){
      this.supplierUploadTransactionSortedbyAdjust = true;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        if(a.tranAdjust < b.tranAdjust){
          return 1
        } else if (a.tranAdjust > b.tranAdjust) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportMemberListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyAdjust = false;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        if(a.tranAdjust > b.tranAdjust){
          return 1
        } else if (a.tranAdjust < b.tranAdjust) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportMemberListData.data = sorted; 
    }
  }

  // Sort by Collection (Member - Report)
  sortMemberReportByCollection(){
    if (this.supplierUploadTransactionSortedbyCollection == false){
      this.supplierUploadTransactionSortedbyCollection = true;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        let firstValue = Number(a.tranCollectedValue); let secondValue = Number(b.tranCollectedValue);
        if(firstValue < secondValue){
          return 1
        } else if (firstValue > secondValue) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportMemberListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyCollection = false;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        let firstValue = Number(a.tranCollectedValue); let secondValue = Number(b.tranCollectedValue);
        if(firstValue > secondValue){
          return 1
        } else if (firstValue < secondValue) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportMemberListData.data = sorted; 
    }
  }
  
  // Sort by Code (Member - Report)
  sortMemberReportByCode(){
    if (this.supplierUploadTransactionSortedbyCode == false){
      this.supplierUploadTransactionSortedbyCode = true;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        if(a.tranCode < b.tranCode){
          return 1
        } else if (a.tranCode > b.tranCode) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportMemberListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyCode = false;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        if(a.tranCode > b.tranCode){
          return 1
        } else if (a.tranCode < b.tranCode) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportMemberListData.data = sorted; 
    }
  }
  
  // Sort by Name (Member - Report)
  sortMemberReportByName(){
    if (this.supplierUploadTransactionSortedbyName == false){
      this.supplierUploadTransactionSortedbyName = true;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        if(a.tranName < b.tranName){
          return 1
        } else if (a.tranName > b.tranName) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportMemberListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyName = false;
      let sorted = this.tapsReportMemberListData.data.sort((a: any, b: any) => { 
        if(a.tranName > b.tranName){
          return 1
        } else if (a.tranName < b.tranName) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportMemberListData.data = sorted; 
    }
  }

  // Generates an XLSX file with the format of the report data (tradeCode_timestamp.csv) 
  memberExportReport(){
    if (this.tapsReportMemberListData.data.length > 0){
      let reportName = this.getReportName();
      const exportMe: any = [];
      let oneLine: any = {};
      this.tapsReportMemberListData.data.forEach((item: any) => {
        oneLine = {};
        oneLine["Supplier"] = item.tranSupTapsId;
        oneLine["Supplier Name"] = item.tranSupplier;
        oneLine["Member"] = item.tranTapsId;
        oneLine["Member Name"] = item.tranAgent;
        oneLine["Bkg Reference"] = item.tranRef;
        oneLine["Departure Date"] = item.tranDepart;
        oneLine["Client Name"] = item.tranClient;
        oneLine["Collection Date"] = item.tranCollectionDate;
        oneLine["Value"] = this.getValidNumberToExcel(item.tranValue);
        oneLine["Collected"] = this.getValidNumberToExcel(item.tranCollectedValue);
        oneLine["Code"] = item.tranCode;
        oneLine["Reason"] = item.tranName;
        oneLine["Approval"] = item.tranApproval;
        oneLine["State"] = item.tranState;
        oneLine["Collection"] = item.tranCollection;
        oneLine["Payment"] = item.tranPaid;
        exportMe.push(oneLine);
      });
      this.exportTapsReportAsExcelFile(exportMe, reportName, "I", "J", false);
    } else {
      this.sendMessageToDialog("","There are no transactions listed to export","");
    }
  }

  // Calls :: getTapsMemberAnalysisReport
  setupMemberAnalysisReport(){
    let request:any = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      agent: this.selectedAgentTradeCode,
      supplier: this.selectedSupplierTradeCode,
      year: this.memberAnalysisReportYear,
      includeBranches: this.memberIncludeBranches,
      token: Session.mySession.get('user').token
    }
    this.tapsService.getTapsMemberAnalysisReport(request).then((output: any) => {
      if (output["status"] == "OK") {
        if (output["res"].length > 0){
          var byteString = atob(output["res"]);
          var ab = new ArrayBuffer(byteString.length);
          var ia = new Uint8Array(ab);
          for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const data = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
          saveAs(data, output["reportName"]); 
        } else {
          this.sendMessageToDialog('There were no transactions found for the given year', '', output);
        }
      } else {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);
      }      
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error)
    });
  }

  // Validation check and then setupMemberAnalysisReport() to get the report
  memberExportAnalysisReport(){
    if (this.selectedAgentTradeCode == "" || this.selectedSupplierTradeCode == "" || this.memberAnalysisReportYear == ""){
      this.sendMessageToDialog('', 'Please fill in all the required data', "");
    } else {
      if (this.isYear(this.memberAnalysisReportYear) == true){
        this.setupMemberAnalysisReport();
      } else {  
        this.sendMessageToDialog('', 'SinGS could not complete your request (Invalid Year Format - YYYY)', "");
      }
    }
  }

  // Returns the current "userTradeCode_timeStampt" for the exported report name
  getReportName(){
    let fileTime:any = new Date();
    let fileTimeStr = fileTime.toLocaleTimeString().split(":").join("");
    let fileDateStr = fileTime.toLocaleDateString().split("/").join("");
    let reportName = this.userTradeCode + "_" + fileDateStr + fileTimeStr;
    return reportName;
  }

  // Clears all related variables when you access the Admin Report page
  clearAdminReportData(){
    this.adminCameFromSupplierManagement = false;
    this.adminCameFromAgentManagement = false;
    this.adminReportingDatePeriod = {};
    this.adminReportingDateFrom = "";
    this.adminReportingDateTo = "";
    this.adminReportSupplier = "all";
    this.adminReportAgent = "all";
    this.adminReportUploadState = "Succeeded";
    this.adminReportPaymentState = "Review";
    this.tapsReportAdminListData.data = [];
    this.adminIncludeBranches = false;
    this.alreadyListedReport = false;
    this.supplierUploadTransactionSortedbySupplier = false;
    this.supplierUploadTransactionSortedbySupId = false;
    this.supplierUploadTransactionSortedbyAgent = false;
    this.supplierUploadTransactionSortedbyTapsId = false;
    this.supplierUploadTransactionSortedbyRef = false;
    this.supplierUploadTransactionSortedbyDepart = false;
    this.supplierUploadTransactionSortedbyClient = false;
    this.supplierUploadTransactionSortedbyWarning = false;
    this.supplierUploadTransactionSortedbyValue = false;
    this.supplierUploadTransactionSortedbyAdjust = false;
    this.supplierUploadTransactionSortedbyCollection = false;
    this.supplierUploadTransactionSortedbyCode = false;
    this.supplierUploadTransactionSortedbyName = false;
    this.supplierUploadTransactionSortedbyPayment = false;
    this.filterAdminAgentsListString = ''; 
    this.switchToGeneratedReportsView = false;
    this.filterAdminAgentListSelect();
  }

  // Setup Admin Report Page date from-to when a period is selected
  setupDatesForAdminReportingPeriod(){
    this.adminReportingDateFrom = this.adminReportingDatePeriod.fromDate;
    this.adminReportingDateTo = this.adminReportingDatePeriod.toDate;
  }

  // Setup Delete S3 Uploads Report Page date from-to when a period is selected
  setupDatesForTapsAdminReportingPeriod(){
    this.adminReportingDateFrom = this.adminReportingDatePeriod.fromDate;
    this.adminReportingDateTo = this.adminReportingDatePeriod.toDate;
  }

  // Will be executed every time an admin changes the filters of the report
  // Calls :: getTapsAdminReport
  adminReport(){
    if ((this.adminReportingDateFrom != "" && this.adminReportingDateFrom != null) && 
    (this.adminReportingDateTo != "" && this.adminReportingDateTo != null)){
        if (this.adminReportingDateFrom > this.adminReportingDateTo) {
          if (this.alreadyListedReport == true){
            this.sendMessageToDialog("","Please ensure that the 'Date-From' date is earlier than 'Date-To' date","");
          } 
        } else { 
          let request:any = {
            company: Session.mySession.getUser().company,
            tradeCode: Session.mySession.getUser().tradeCode,
            operation: Session.mySession.getUser().operation,
            supplierTradeCode: this.adminReportSupplier,
            agentTradeCode: this.adminReportAgent,
            uploadState: this.adminReportUploadState,
            paymentState: this.adminReportPaymentState,
            startDate: this.convertDate(this.adminReportingDateFrom),
            endDate: this.convertDate(this.adminReportingDateTo),
            includeBranches: this.adminIncludeBranches,
            format: "csv",
            token: Session.mySession.get('user').token,
            hideDialog: true
          }
          // If less than 7 days 
          const divideBy = 86400000;
          const dateFrom = new Date(this.adminReportingDateFrom);
          const dateTo = new Date(this.adminReportingDateTo);
          const rangeOfDays = (dateTo.getTime() - dateFrom.getTime()) / (divideBy);
          this.pageLoaded = false;
          if (rangeOfDays > 6){ // MORE THAN 7 DAYS REPORT
            request.format = "csv";
            this.tapsService.generateTapsAdminReport(request).then((output: any) => {
              if (output.status == "OK"){
                Session.mySession.resetTimersOnBookingValues().then((res:any)=>{
                  this.pageLoaded = true;
                  this.generatedReportTitle = "Generating report in progress. Please wait .. "
                  this.generatedReportBadgeHidden = true;
                  AsynchRequests.myAsynchReq.addJobToWatch(request, output.jobID)
                  this.checkIfJobIsDone().then((res:any)=>{
                    this.checkIfGeneratedReportHasTransactions(output.jobID,'tapsAdminReport')
                  })
                })
              } else {
                this.sendMessageToDialog('', 'Generating report has failed (' + output.status + ')', output);
              }
            }).catch((error: any) => {
              this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
            })
          } else {  // LESS THAN 7 DAYS REPORT 
            request.format = "list";
            this.tapsService.getTapsAdminReport(request).then((output: any) => {
              this.pageLoaded = true;
              if (output["status"] == "OK"){
                if (output.format == "csv" || output.format == "xlsx"){
                  var byteString = atob(output["fileContent"]);
                  var ab = new ArrayBuffer(byteString.length);
                  var ia = new Uint8Array(ab);
                  for (var i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                  }
                  const data = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
                  saveAs(data, output["reportName"]); 
                } else {
                  this.tapsReportAdminListData.data = [...output["res"]];
                }
              } else {
                this.sendMessageToDialog('', 'Generating report has failed (' + output.status + ')', output);
              }
            }).catch((error: any) => {
              this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);
            })
          }
        } 
    } else {
      if (this.alreadyListedReport == true){
        this.sendMessageToDialog("","Please fill in all the required data","");   
      } 
    }    
  }

  // Turns Reporting Page into the Generated Report list to download from AWS
  switchToGeneratedReports(pathType:any){
    this.generatedReportBadgeHidden = true;
    this.generatedReportBadge = 0;
    this.switchToGeneratedReportsView = !this.switchToGeneratedReportsView;
    if (this.switchToGeneratedReportsView == true){
      this.setupListOfTapsReporting(pathType)
    }
  }

  // Setup Generated TAPS Reports Page - Admin / Supplier / Member
  setupListOfTapsReporting(pathType:any): Promise<any> {
    this.attachedDocs.data = [];
    return new Promise((resolve, reject) => { // Check if the booking is in the session variable, It not / expired - reload by calling API
      const listRequest = {
        company: Session.mySession.getUser().company,
        tradeCode: Session.mySession.getUser().tradeCode,
        operation: Session.mySession.getUser().operation,
        pathType: pathType, 
        token: Session.mySession.get('user').token
      };
      if (pathType == "tapsSupplierReport"){
        listRequest.tradeCode = this.userSupplierMisttaCode;
      }
      this.pageLoaded = false;
      this.reportService.getS3files(listRequest).then((output: any) => {
        if (output.status === 'OK') {
          if (output.data.contents !== undefined) {
            // Sort files by date
            output.data.contents = output.data.contents.sort((a: any, b: any) => +new Date(b.last_modified) - +new Date(a.last_modified));
            this.attachedDocs.data = output.data.contents; // If contents exist within S3 buckets, put them into data property
            this.attachedDocs.data.forEach((file: any) => {
              file.name = file.key.split('/').pop(); // Get only file name, remove path it is located in
            });
          } else {
            this.attachedDocs.data = []; // Contents not found - set empty array in the data
          }
          this.pageLoaded = true; resolve('');
        } else {
          this.sendMessageToDialog('', output.status, ''); resolve(''); // Print error..
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0829S)', error); resolve(''); // Print error..
      });
    }).catch((err: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request (' + err + ')', '') // Display error message here..
    });
  }

  // Sort by Supplier (Admin - Report)
  sortAdminReportBySupplier(){
    if (this.supplierUploadTransactionSortedbySupplier == false){
      this.supplierUploadTransactionSortedbySupplier = true;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        if(a.tranSupplier < b.tranSupplier){
          return 1
        } else if (a.tranSupplier > b.tranSupplier) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportAdminListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbySupplier = false;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        if(a.tranSupplier > b.tranSupplier){
          return 1
        } else if (a.tranSupplier < b.tranSupplier) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportAdminListData.data = sorted; 
    }
  }

  // Sort by Supplier Taps ID (Admin - Report)
  sortAdminReportBySupplierTapsId(){
    if (this.supplierUploadTransactionSortedbySupId == false){
      this.supplierUploadTransactionSortedbySupId = true;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        if(a.tranSupTapsId < b.tranSupTapsId){
          return 1
        } else if (a.tranSupTapsId > b.tranSupTapsId) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportAdminListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbySupId = false;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        if(a.tranSupTapsId > b.tranSupTapsId){
          return 1
        } else if (a.tranSupTapsId < b.tranSupTapsId) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportAdminListData.data = sorted; 
    }
  }

  // Sort by Taps ID (Admin - Report)
  sortAdminReportByTapsId(){
    if (this.supplierUploadTransactionSortedbyTapsId == false){
      this.supplierUploadTransactionSortedbyTapsId = true;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        if(a.tranTapsId < b.tranTapsId){
          return 1
        } else if (a.tranTapsId > b.tranTapsId) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportAdminListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyTapsId = false;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        if(a.tranTapsId > b.tranTapsId){
          return 1
        } else if (a.tranTapsId < b.tranTapsId) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportAdminListData.data = sorted; 
    }
  }

  // Sort by Agent (Admin - Report)
  sortAdminReportByAgent(){
    if (this.supplierUploadTransactionSortedbyAgent == false){
      this.supplierUploadTransactionSortedbyAgent = true;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        if(a.tranAgent < b.tranAgent){
          return 1
        } else if (a.tranAgent > b.tranAgent) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportAdminListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyAgent = false;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        if(a.tranAgent > b.tranAgent){
          return 1
        } else if (a.tranAgent < b.tranAgent) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportAdminListData.data = sorted; 
    }
  }
  
  // Sort by Ref (Admin - Report)
  sortAdminReportByRef(){
    if (this.supplierUploadTransactionSortedbyRef == false){
      this.supplierUploadTransactionSortedbyRef = true;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        if(a.tranRef < b.tranRef){
          return 1
        } else if (a.tranRef > b.tranRef) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportAdminListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyRef = false;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        if(a.tranRef > b.tranRef){
          return 1
        } else if (a.tranRef < b.tranRef) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportAdminListData.data = sorted; 
    }
  }

  // Sort by Depart (Admin - Report)
  sortAdminReportByDepart(){
    if (this.supplierUploadTransactionSortedbyDepart == false){ 
      this.supplierUploadTransactionSortedbyDepart = true;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => {
        const arr1 = a.tranDepart.split('/');
        const arr2 = b.tranDepart.split('/');
        let aDate = new Date(parseInt(arr1[2]), parseInt(arr1[1]), parseInt(arr1[0])).getTime()
        let bDate = new Date(parseInt(arr2[2]), parseInt(arr2[1]), parseInt(arr2[0])).getTime()
        if (aDate < bDate) {
          return -1;
        } else if (aDate > bDate) {
          return 1;
        } else {
          return 0;
        }
      });
      this.tapsReportAdminListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyDepart = false;
      let sortedByDepart = this.tapsReportAdminListData.data.sort((a: any, b: any) => {
        const arr1 = a.tranDepart.split('/');
        const arr2 = b.tranDepart.split('/');
        let aDate = new Date(parseInt(arr1[2]), parseInt(arr1[1]), parseInt(arr1[0])).getTime()
        let bDate = new Date(parseInt(arr2[2]), parseInt(arr2[1]), parseInt(arr2[0])).getTime()
        if (aDate < bDate) {
          return 1;
        } else if (aDate > bDate) {
          return -1;
        } else {
          return 0;
        }
      }); 
      this.tapsReportAdminListData.data = sortedByDepart; 
    }   
  }

  // Sort by Client (Admin - Report)
  sortAdminReportByClient(){
    if (this.supplierUploadTransactionSortedbyClient == false){
      this.supplierUploadTransactionSortedbyClient = true;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        if(a.tranClient < b.tranClient){
          return 1
        } else if (a.tranClient > b.tranClient) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportAdminListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyClient = false;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        if(a.tranClient > b.tranClient){
          return 1
        } else if (a.tranClient < b.tranClient) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportAdminListData.data = sorted; 
    }
  }

  // Sort by Collection Date (Admin - Report)
  sortAdminReportByCollectionDate() {
    if (this.supplierUploadTransactionSortedbyPayment == false){ 
      this.supplierUploadTransactionSortedbyPayment = true;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => {
        const arr1 = a.tranCollectionDate.split('/');
        const arr2 = b.tranCollectionDate.split('/');
        let aDate = new Date(parseInt(arr1[2]), parseInt(arr1[1]), parseInt(arr1[0])).getTime()
        let bDate = new Date(parseInt(arr2[2]), parseInt(arr2[1]), parseInt(arr2[0])).getTime()
        if (aDate < bDate) {
          return -1;
        } else if (aDate > bDate) {
          return 1;
        } else {
          return 0;
        }
      });
      this.tapsReportAdminListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyPayment = false;
      let sortedByDepart = this.tapsReportAdminListData.data.sort((a: any, b: any) => {
        const arr1 = a.tranCollectionDate.split('/');
        const arr2 = b.tranCollectionDate.split('/');
        let aDate = new Date(parseInt(arr1[2]), parseInt(arr1[1]), parseInt(arr1[0])).getTime()
        let bDate = new Date(parseInt(arr2[2]), parseInt(arr2[1]), parseInt(arr2[0])).getTime()
        if (aDate < bDate) {
          return 1;
        } else if (aDate > bDate) {
          return -1;
        } else {
          return 0;
        }
      }); 
      this.tapsReportAdminListData.data = sortedByDepart; 
    }   
  }

  // Sort by Warning (Admin - Report)
  sortAdminReportByWarning(){
    if (this.supplierUploadTransactionSortedbyWarning == false){
      this.supplierUploadTransactionSortedbyWarning = true;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        if(a.tranWarning < b.tranWarning){
          return 1
        } else if (a.tranWarning > b.tranWarning) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportAdminListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyWarning = false;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        if(a.tranWarning > b.tranWarning){
          return 1
        } else if (a.tranWarning < b.tranWarning) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportAdminListData.data = sorted; 
    }
  }

  // Sort by Value (Admin - Report)
  sortAdminReportByValue(){
    if (this.supplierUploadTransactionSortedbyValue == false){
      this.supplierUploadTransactionSortedbyValue = true;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        let firstValue = Number(a.tranValue); let secondValue = Number(b.tranValue);
        if(firstValue < secondValue){
          return 1
        } else if (firstValue > secondValue) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportAdminListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyValue = false;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        let firstValue = Number(a.tranValue); let secondValue = Number(b.tranValue);
        if(firstValue > secondValue){
          return 1
        } else if (firstValue < secondValue) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportAdminListData.data = sorted; 
    }
  }

  // Sort by Adjust (Admin - Report)
  sortAdminReportByAdjust(){
    if (this.supplierUploadTransactionSortedbyAdjust == false){
      this.supplierUploadTransactionSortedbyAdjust = true;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        if(a.tranAdjust < b.tranAdjust){
          return 1
        } else if (a.tranAdjust > b.tranAdjust) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportAdminListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyAdjust = false;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        if(a.tranAdjust > b.tranAdjust){
          return 1
        } else if (a.tranAdjust < b.tranAdjust) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportAdminListData.data = sorted; 
    }
  }

  // Sort by Collection (Admin - Report)
  sortAdminReportByCollection(){
    if (this.supplierUploadTransactionSortedbyCollection == false){
      this.supplierUploadTransactionSortedbyCollection = true;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        let firstValue = Number(a.tranCollectedValue); let secondValue = Number(b.tranCollectedValue);
        if(firstValue < secondValue){
          return 1
        } else if (firstValue > secondValue) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportAdminListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyCollection = false;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        let firstValue = Number(a.tranCollectedValue); let secondValue = Number(b.tranCollectedValue);
        if(firstValue > secondValue){
          return 1
        } else if (firstValue < secondValue) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportAdminListData.data = sorted; 
    }
  }
  
  // Sort by Code (Admin - Report)
  sortAdminReportByCode(){
    if (this.supplierUploadTransactionSortedbyCode == false){
      this.supplierUploadTransactionSortedbyCode = true;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        if(a.tranCode < b.tranCode){
          return 1
        } else if (a.tranCode > b.tranCode) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportAdminListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyCode = false;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        if(a.tranCode > b.tranCode){
          return 1
        } else if (a.tranCode < b.tranCode) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportAdminListData.data = sorted; 
    }
  }
  
  // Sort by Name (Admin - Report)
  sortAdminReportByName(){
    if (this.supplierUploadTransactionSortedbyName == false){
      this.supplierUploadTransactionSortedbyName = true;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        if(a.tranName < b.tranName){
          return 1
        } else if (a.tranName > b.tranName) {
          return -1
        } else {
          return 0
        }}); 
      this.tapsReportAdminListData.data = sorted; 
    } else {
      this.supplierUploadTransactionSortedbyName = false;
      let sorted = this.tapsReportAdminListData.data.sort((a: any, b: any) => { 
        if(a.tranName > b.tranName){
          return 1
        } else if (a.tranName < b.tranName) {
          return -1
        } else {
          return 0
        }});  
      this.tapsReportAdminListData.data = sorted; 
    }
  }

  // Exports the given report when user click "Export to Excel"
  adminExportReport(){
    if ((this.adminReportingDateFrom != "" && this.adminReportingDateFrom != null) && 
        (this.adminReportingDateTo != "" && this.adminReportingDateTo != null)) {
      if (this.tapsReportAdminListData.data.length > 0){
        let reportName = this.getReportName();
        const exportMe: any = [];
        let oneLine: any = {};
        this.tapsReportAdminListData.data.forEach((item: any) => {
          oneLine = {};
          oneLine["Supplier"] = item.tranSupTapsId;
          oneLine["Supplier Name"] = item.tranSupplier;
          oneLine["Member"] = item.tranTapsId;
          oneLine["Member Name"] = item.tranAgent;
          oneLine["Bkg Reference"] = item.tranRef;
          oneLine["Departure Date"] = item.tranDepart;
          oneLine["Client Name"] = item.tranClient;
          oneLine["Warning"] = item.tranWarning;
          oneLine["Collection Date"] = item.tranCollectionDate;
          oneLine["Payment Date"] = item.tranPayment;
          oneLine["Value"] = this.getValidNumberToExcel(item.tranValue);
          oneLine["Collected"] = this.getValidNumberToExcel(item.tranCollectedValue);
          oneLine["Code"] = item.tranCode;
          oneLine["Reason"] = item.tranName;
          oneLine["Approval"] = item.tranApproval;
          oneLine["State"] = item.tranState;
          oneLine["Collection"] = item.tranCollection;
          oneLine["Paid"] = item.tranPaid;
          exportMe.push(oneLine);
        });
        this.exportTapsReportAsExcelFile(exportMe, reportName, "K", "L", false);
      } else {
        this.sendMessageToDialog("","There are no transactions listed to export","");
      }
    } else {
      this.sendMessageToDialog("","Please fill in all the required data","");
    }   
  }

  // Calls :: getTapsAdminAnalysisReport
  adminTapsAnalysisReport(request:any){
    this.pageLoaded = false;
    this.tapsService.getTapsAdminAnalysisReport(request).then((output:any)=>{
      if (output["status"] == 'OK'){
        if (output["res"] == 'No records found.'){
          this.sendMessageToDialog(output['res'], '', output);
        } else {
          var byteString = atob(output["res"]);
          var ab = new ArrayBuffer(byteString.length);
          var ia = new Uint8Array(ab);
          for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const data = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
          saveAs(data, output["reportName"]);
        }
        this.pageLoaded = true;
      } else {
        this.pageLoaded = true;
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', output);  
      }
    }).catch((error: any) => {
      this.pageLoaded = true;
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2007S)', error);              
    });
  }

  // Export Admin Analysis Report (Validation Check)
  adminExportAnalysisReport(){
    if (this.adminAnalysisReport != ""){
      let request:any = {};
      let checkIfValidData:any = false;
      if (this.adminAnalysisReport.format == "1"){
        if (this.isYear(this.adminAnalysisYear) && this.isWeek(this.adminAnalysisWeek)){
          checkIfValidData = true;
          request = {
              company: Session.mySession.getUser().company,
              tradeCode: Session.mySession.getUser().tradeCode,
              operation: Session.mySession.getUser().operation,
              agent: this.adminReportAgent,
              supplier: this.adminReportSupplier,
              reportType: this.adminAnalysisReport.type,
              year: this.adminAnalysisYear,
              week: this.adminAnalysisWeek,
              includeBranches: this.adminIncludeBranches,
              token: Session.mySession.get('user').token
          }
        }
      }
      if (this.adminAnalysisReport.format == "2"){
        if (this.isYear(this.adminAnalysisYear) && this.isMonth(this.adminAnalysisMonth)){
          checkIfValidData = true;
          request = {
            company: Session.mySession.getUser().company,
            tradeCode: Session.mySession.getUser().tradeCode,
            operation: Session.mySession.getUser().operation,
            agent: this.adminReportAgent,
            supplier: this.adminReportSupplier,
            reportType: this.adminAnalysisReport.type,
            year: this.adminAnalysisYear,
            month: this.adminAnalysisMonth,   
            includeBranches: this.adminIncludeBranches,         
            token: Session.mySession.get('user').token
          }
        }
      }
      if (this.adminAnalysisReport.format == "3"){
        if (this.isYear(this.adminAnalysisYear)){
          checkIfValidData = true;
          request = {
            company: Session.mySession.getUser().company,
            tradeCode: Session.mySession.getUser().tradeCode,
            operation: Session.mySession.getUser().operation,
            agent: this.adminReportAgent,
            supplier: this.adminReportSupplier,
            reportType: this.adminAnalysisReport.type,
            year: this.adminAnalysisYear,
            includeBranches: this.adminIncludeBranches,
            token: Session.mySession.get('user').token
          }
        }
      }
      if (this.adminAnalysisReport.format == "4"){
        if ((this.adminAnalysisStartDate != "" && this.adminAnalysisStartDate != null) && 
            (this.adminAnalysisEndDate != "" && this.adminAnalysisEndDate != null)){
          if (this.adminAnalysisStartDate < this.adminAnalysisEndDate) {
            checkIfValidData = true;
            request = {
              company: Session.mySession.getUser().company,
              tradeCode: Session.mySession.getUser().tradeCode,
              operation: Session.mySession.getUser().operation,
              agent: this.adminReportAgent,
              supplier: this.adminReportSupplier,
              reportType: this.adminAnalysisReport.type,
              startDate: this.convertDate(this.adminAnalysisStartDate),
              endDate: this.convertDate(this.adminAnalysisEndDate),
              includeBranches: this.adminIncludeBranches,
              token: Session.mySession.get('user').token
            }
          }
        }
      }
      if (this.adminAnalysisReport.format == "5"){
        if (this.isYear(this.adminAnalysisYearOne) && this.isMonth(this.adminAnalysisMonthOne) && 
            this.isYear(this.adminAnalysisYearTwo) && this.isMonth(this.adminAnalysisMonthTwo) && 
            this.isMonth(this.adminAnalysisMonths)){
          checkIfValidData = true;
          request = {            
            company: Session.mySession.getUser().company,
            tradeCode: Session.mySession.getUser().tradeCode,
            operation: Session.mySession.getUser().operation,
            agent: this.adminReportAgent,
            supplier: this.adminReportSupplier,
            reportType: this.adminAnalysisReport.type,
            periodOne: this.adminAnalysisYearOne + "-" + this.adminAnalysisMonthOne,
            periodTwo: this.adminAnalysisYearTwo + "-" + this.adminAnalysisMonthTwo,
            months: this.adminAnalysisMonths,
            includeBranches: this.adminIncludeBranches,
            token: Session.mySession.get('user').token
          }
        }
      } 
      if (checkIfValidData == true){
        this.adminTapsAnalysisReport(request);
      } else {
        this.sendMessageToDialog("","Please fill in all the required data with the appropriate format","")
      }
    } else {
      this.sendMessageToDialog("","Please fill in all the required data","")
    }
  }

  // Setup BACS Reporting Page - Admin
  setupListOfBacsReporting(type:any): Promise<any> {
    this.adminBacsReporting = type;
    this.attachedDocs.data = [];
    return new Promise((resolve, reject) => { // Check if the booking is in the session variable, It not / expired - reload by calling API
      const listRequest = {
        company: Session.mySession.getUser().company,
        tradeCode: Session.mySession.getUser().tradeCode,
        operation: Session.mySession.getUser().operation,
        pathType: this.adminBacsReporting, 
        token: Session.mySession.get('user').token
      };
      this.pageLoaded = false;
      this.reportService.getS3files(listRequest).then((output: any) => {
        if (output.status === 'OK') {
          if (output.data.contents !== undefined) {
            // Sort files by date
            if (this.showArchived == false) {
              const today = new Date();
              today.setHours(0, 0, 0, 0);
              output.data.contents = output.data.contents
                .filter((match: any) => {
                  const matchDate = new Date(match.last_modified);
                  matchDate.setHours(0, 0, 0, 0); 
                  return matchDate.getTime() === today.getTime();
                }).sort((a: any, b: any) => +new Date(b.last_modified) - +new Date(a.last_modified));
            } else { 
              output.data.contents = output.data.contents.sort((a: any, b: any) => +new Date(b.last_modified) - +new Date(a.last_modified));
            }
            this.attachedDocs.data = output.data.contents; // If contents exist within S3 buckets, put them into data property
            this.attachedDocs.data.forEach((file: any) => {
              file.name = file.key.split('/').pop(); // Get only file name, remove path it is located in
            });
          } else {
            this.attachedDocs.data = []; // Contents not found - set empty array in the data
          }
          this.pageLoaded = true; resolve('');
        } else {
          this.sendMessageToDialog('', output.status, ''); resolve(''); // Print error..
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0829S)', error); resolve(''); // Print error..
      });
    }).catch((err: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request (' + err + ')', '') // Display error message here..
    });
  }

  // Refresh BACS Reporting Page - Admin
  refreshBacsReporting(){
    this.setupListOfBacsReporting(this.adminBacsReporting);
  }

  // Show archives of BACS Reporting Page - Admin
  showArchivedBacs(){
    this.showArchived = true;
    this.setupListOfBacsReporting(this.adminBacsReporting);
  }

  // Show today's reports of BACS Reporting Page - Admin
  backToToday(){
    this.showArchived = false;
    this.setupListOfBacsReporting(this.adminBacsReporting);
  }

  // Download file from BACS Reporting Page - Admin
  downloadDocument(file: any, pathType:any, supplierCode:any): void {
    let downloadRequest = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation, 
      pathType: pathType, 
      fileName: file.name,
      token: Session.mySession.get('user').token
    };

    if (pathType == "tapsSupplierReport"){
      downloadRequest.tradeCode = this.userSupplierMisttaCode;
    }

    if (pathType == "tapsCollectFtp"){
      downloadRequest.tradeCode = supplierCode;
    }

    // Get file's extension and prepare for assigning MIME type below..
    const extension = file.name.substr(file.name.lastIndexOf('.') + 1); let type = '';
    // Depending on the extension, we'll assign the right MIME type below..
    if (extension === 'csv') { type = 'text/csv'; }
    else if (extension === 'txt') { type = 'text/plain'; }
    else if (extension === 'doc') { type = 'application/msword'; }
    else if (extension === 'docx') { type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; }
    else if (extension === 'pdf') { type = 'application/pdf'; }
    else if (extension === 'xls') { type = 'application/vnd.ms-excel'; }
    else if (extension === 'xlsx') { type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; }
    else if (extension === 'jpeg' || extension === 'jpg') { type = 'image/jpeg'; }

    // Call downloadS3file method
    this.pageLoaded = false;
    this.reportService.getS3presignedUrl(downloadRequest).then((output: any) => {
      if (output.status === 'OK') {
        try {
          window.open(output.presignedUrl, '_blank'); this.pageLoaded = true;
        } catch (error) {
          this.sendMessageToDialog('', error, ''); // File download OK but failed to convert Base64 to whatever
        }
      } else {
        this.sendMessageToDialog('', output.status, ''); // File download failed at the back-end
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0830S)', error);
    });
  }

  // Download file from BACS Reporting Page - Admin
  downloadTXTDocument(file: any, pathType:any): void {
    let downloadRequest = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation, 
      pathType: pathType, 
      fileName: file.name, 
      token: Session.mySession.get('user').token
    };

    if (pathType == "tapsSupplierReport"){
      downloadRequest.tradeCode = this.userSupplierMisttaCode;
    }

    // Get file's extension and prepare for assigning MIME type below..
    const extension = file.name.substr(file.name.lastIndexOf('.') + 1); let type = '';
    // Depending on the extension, we'll assign the right MIME type below..
    if (extension === 'csv') { type = 'text/csv'; }
    else if (extension === 'doc') { type = 'application/msword'; }
    else if (extension === 'docx') { type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; }
    else if (extension === 'pdf') { type = 'application/pdf'; }
    else if (extension === 'xls') { type = 'application/vnd.ms-excel'; }
    else if (extension === 'xlsx') { type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; }
    else if (extension === 'jpeg' || extension === 'jpg') { type = 'image/jpeg'; }

    // Call downloadS3file method
    this.pageLoaded = false;
    this.reportService.downloadS3file(downloadRequest).then((output: any) => {
      if (output.status === 'OK') {
        try {
            // convert base64 to raw binary data held in a string
            // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
            const byteString = atob(output.fileContent);

            // write the bytes of the string to an ArrayBuffer
            const ab = new ArrayBuffer(byteString.length);

            // create a view into the buffer
            const ia = new Uint8Array(ab);

            // set the bytes of the buffer to the correct values
            for (let i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }

            // Output BLOB needs to be transformed into an excel application file
            const data = new Blob([ab], { type });
            saveAs(data, file.name); // Call this function which opens browser's 'Save As..' window
            this.pageLoaded = true;
        } catch (error) {
          this.sendMessageToDialog('', error, ''); // File download OK but failed to convert Base64 to whatever
        }
      } else {
        this.sendMessageToDialog('', output.status, ''); // File download failed at the back-end
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0830S)', error);
    });
  }

  // Exports to Excel the list of 'Agents'
  supplierExportAgentsList(){
    if (this.tapsIncludedAgentPageListData.data.length == 0) {
      this.sendMessageToDialog("","No records found to export","");
    } else { 
        let reportName = this.userSupplierMisttaCode + "_Sings_TAPS_Agents"
        const exportMe: any = [];
        let oneLine: any = {};
        this.tapsIncludedAgentPageListData.data.forEach((item: any) => {
          oneLine = {};
          oneLine["Name"] = item.branchName;
          oneLine["TAPS Identi."] = item.tradeCode;
          oneLine["Telephone"] = item.telephoneTAPS;
          oneLine["Email"] = item.emailTAPS;
          exportMe.push(oneLine);
        });
        this.exportTapsReportAsExcelFile(exportMe, reportName, "", "", false)
    }
  }

  checkIfGeneratedReportHasTransactions(jobID:any,pathType:any){
    let request:any = {
      company: Session.mySession.getUser().company,
      tradeCode: Session.mySession.getUser().tradeCode,
      operation: Session.mySession.getUser().operation,
      id: jobID,
      token: Session.mySession.get('user').token
    }
    AsynchRequests.myAsynchReq.getJobStatus(request).then((res: any) => {
      if (res?.data?.status === 'OK') { 
        this.generatedReportTitle = "Generated Reports"
        if (!res?.data?.description.includes("No transactions found")) {
          let tempFile = { name : res.data.description }
          this.downloadDocument(tempFile,pathType,"")
          this.generatedReportBadge = this.generatedReportBadge + 1;
          this.generatedReportBadgeHidden = false;
        } else {
          if (this.generatedReportBadge == 0){
            this.generatedReportBadgeHidden = false;
          }
        }
      }
    })
  }


  //////////////////////////////////
  //
  // Reporting Functionalities Above
  //
  //////////////////////////////////


  //////////////////////////////////
  //
  // Date Periods Setup Below
  //
  //////////////////////////////////


  getNextFriday(date:any) {
    const nextFridayMoment = moment(date).day(5);
    if (nextFridayMoment.day() == 5) {
        nextFridayMoment.add(7, 'days');
    }
    return nextFridayMoment.toDate();
  }

  addDays(numOfDays: number, date = new Date()): Date {
    return moment(date).add(numOfDays, 'days').toDate();
  }

  subtrackDays(numOfDays: number, date = new Date()): Date {
    return moment(date).subtract(numOfDays, 'days').toDate();
  }

  prepareNextFridayRow(date: any) {
    let nextFriday = this.getNextFriday(date);
    let firstDayOfWeek = this.subtrackDays(5, nextFriday);
    let lastDayOfWeek = this.addDays(1, nextFriday);
    let nextFridayRow = "Friday " + moment(nextFriday).format('L') + " (Week " + moment(firstDayOfWeek).format('L') + " - " + moment(lastDayOfWeek).format('L') + ")";
    return { displayRow: nextFridayRow, period: nextFriday, fromDate: firstDayOfWeek, toDate: lastDayOfWeek };
  }

  prepareLastFridayRow(date: any) {
    let firstDayOfWeek = this.subtrackDays(5, date);
    let lastDayOfWeek = this.addDays(1, date);
    let lastFridayRow = "Friday " + moment(date).format('L') + " (Week " + moment(firstDayOfWeek).format('L') + " - " + moment(lastDayOfWeek).format('L') + ")";
    return { displayRow: lastFridayRow, period: date, fromDate: firstDayOfWeek, toDate: lastDayOfWeek };
  }

  prepareMonthRows(date: any) {
    let firstDay = moment(date).startOf('month').toDate();
    let lastDay = moment(date).endOf('month').toDate(); 
    this.datePeriods.push({
      displayRow: "This Month",
      period: date,
      fromDate: firstDay,
      toDate: lastDay
    });
    let firstDayofLastMonth = moment(date).subtract(1, 'month').startOf('month').toDate();
    let lastDayofLastMonth = moment(date).subtract(1, 'month').endOf('month').toDate();
    this.datePeriods.push({
      displayRow: "Last Month",
      period: lastDayofLastMonth,
      fromDate: firstDayofLastMonth,
      toDate: lastDayofLastMonth
    });
  }

  prepareQuarterRows(date: any) {
    // Determine the current month, year, and quarter
    const currentMonth = moment(date).month();
    const currentYear = moment(date).year();
    const currentQuarter = Math.ceil((currentMonth + 1) / 3);

    let previousQuarter: number;
    let previousYear: number;

    // Determine the previous quarter and year
    if (currentQuarter === 1) {
      previousQuarter = 4;
      previousYear = currentYear - 1;
    } else {
      previousQuarter = currentQuarter - 1;
      previousYear = currentYear;
    }

    // Calculate the start and end dates for the current quarter
    const currentQuarterStartDate = moment(date)
      .quarter(currentQuarter)
      .startOf('quarter')
      .toDate();

    const currentQuarterEndDate = moment(date)
      .quarter(currentQuarter)
      .endOf('quarter')
      .toDate();

    // Push current quarter data to datePeriods array
    this.datePeriods.push({
      displayRow: "This Quarter",
      period: currentQuarterEndDate,
      fromDate: currentQuarterStartDate,
      toDate: currentQuarterEndDate,
    });

    // Calculate the start and end dates for the last quarter
    const lastQuarterStartDate = moment([previousYear])
      .quarter(previousQuarter)
      .startOf('quarter')
      .toDate();

    const lastQuarterEndDate = moment([previousYear])
      .quarter(previousQuarter)
      .endOf('quarter')
      .toDate();

    // Push last quarter data to datePeriods array
    this.datePeriods.push({
      displayRow: "Last Quarter",
      period: lastQuarterEndDate,
      fromDate: lastQuarterStartDate,
      toDate: lastQuarterEndDate,
    });
  }

  prepareYearRows(date: any) {
    // Get the first and last days of the current year using Moment.js
    let firstDayOfThisYear = moment().startOf('year').toDate();
    let lastDayOfThisYear = moment().endOf('year').toDate();

    // Push current year data to datePeriods array
    this.datePeriods.push({
      displayRow: "This Year",
      period: date,
      fromDate: firstDayOfThisYear,
      toDate: lastDayOfThisYear
    });

    // Calculate the first and last days of the last year
    let firstDayOfLastYear = moment().subtract(1, 'year').startOf('year').toDate();
    let lastDayOfLastYear = moment().subtract(1, 'year').endOf('year').toDate(); 

    // Push last year data to datePeriods array
    this.datePeriods.push({
      displayRow: "Last Year",
      period: date,
      fromDate: firstDayOfLastYear,
      toDate: lastDayOfLastYear
    });
  }

  getFutureRows(date: any) {
    let nextDay = date;
    let lastDayOfPeriod = this.addDays(6, nextDay);
    let displayRow = "Preview Future Transactions (for review only)";
    return {
      displayRow: displayRow,
      period: nextDay,
      fromDate: nextDay,
      toDate: lastDayOfPeriod
    };
  }

  setUpAdminAnalysisDatePeriods() {
    let today = moment();  // Use Moment.js to get the current date
    let tempDatePeriods: any = [];
    
    // The last and next Friday (x2)
    let nextFridayObj = this.prepareNextFridayRow(this.getUploadPeriod(true));
    let fridayAfterNextObj = this.prepareNextFridayRow(this.getUploadPeriod(false)); 
    tempDatePeriods.push(fridayAfterNextObj);    
    tempDatePeriods.push(nextFridayObj); 

    // Past Fridays (x8)
    let tempLastFriday = moment(today).subtract(today.day() - 5 + 7, 'days').toDate(); // Calculate the last Friday
    let lastFridayObj = this.prepareLastFridayRow(tempLastFriday);
    tempDatePeriods.push(lastFridayObj); 

    for (let counter = 0; counter < 7; counter++) {
      let tempFriday = this.subtrackDays(7, tempDatePeriods[tempDatePeriods.length - 1].period);
      let newFridayAfterLast = this.prepareLastFridayRow(tempFriday);
      tempDatePeriods.push(newFridayAfterLast);    
    }

    let tempDatePeriodsFinal: any = tempDatePeriods;
    tempDatePeriodsFinal.shift(); // Remove the first element to match original logic
    this.adminAnalysisReportingDatePeriods = tempDatePeriodsFinal;
  }

  setUpAdminDatePeriods() {
    let today = moment();  // Use Moment.js to get the current date
    
    // The last and next Friday (x2)
    let nextFridayObj = this.prepareNextFridayRow(this.getUploadPeriod(true));
    let fridayAfterNextObj = this.prepareNextFridayRow(this.getUploadPeriod(false)); 
    this.datePeriods.push(fridayAfterNextObj);    
    this.datePeriods.push(nextFridayObj); 

    // Past Fridays (x8)
    let tempLastFriday = moment(today).subtract(today.day() - 5 + 7, 'days').toDate();  // Calculate the last Friday
    let lastFridayObj = this.prepareLastFridayRow(tempLastFriday);
    this.datePeriods.push(lastFridayObj);

    for (let counter = 0; counter < 7; counter++) {
      let tempFriday = this.subtrackDays(7, this.datePeriods[this.datePeriods.length - 1].period);
      let newFridayAfterLast = this.prepareLastFridayRow(tempFriday);
      this.datePeriods.push(newFridayAfterLast);   
    }

    // This and Last Month
    this.prepareMonthRows(today.toDate());

    // This and Last Quarter
    this.prepareQuarterRows(today.toDate());

    // This and Last Year
    this.prepareYearRows(today.toDate());  

    // Prepare Reporting Periods as well
    let tempDatePeriods: any = this.datePeriods.slice(); // Clone the array
    tempDatePeriods.shift();  // Remove the first element
    this.adminReportingDatePeriods = tempDatePeriods;
  }

  // Setup all the necessary date periods rows/values
  setUpDatePeriods() {
    let today = moment(); // Use Moment.js to get the current date
    
    // The last and next Friday (x2)
    let nextFridayObj = this.prepareNextFridayRow(this.getUploadPeriod(true));
    let fridayAfterNextObj = this.prepareNextFridayRow(this.getUploadPeriod(false)); 
    this.datePeriods.push(fridayAfterNextObj);    
    this.datePeriods.push(nextFridayObj); 

    // Prepare future transactions row
    let futureRow = this.getFutureRows(fridayAfterNextObj.period);

    // Past Fridays (x8)
    let tempLastFriday = moment(today).subtract(today.day() - 5 + 7, 'days').toDate(); // Calculate the last Friday
    let lastFridayObj = this.prepareLastFridayRow(tempLastFriday);
    this.datePeriods.push(lastFridayObj); 

    for (let counter = 0; counter < 7; counter++) {
      let tempFriday = this.subtrackDays(7, this.datePeriods[this.datePeriods.length - 1].period);
      let newFridayAfterLast = this.prepareLastFridayRow(tempFriday);
      this.datePeriods.push(newFridayAfterLast);    
    }

    // This and Last Month
    this.prepareMonthRows(today.toDate());

    // This and Last Quarter
    this.prepareQuarterRows(today.toDate());

    // This and Last Year
    this.prepareYearRows(today.toDate());  

    // Prepare Future Transactions
    if (this.loggedInUserType == 'Member') {
      this.datePeriods.push(futureRow); 
    }

    // Prepare Reporting Periods as well
    let tempDatePeriods: any = this.datePeriods.slice(); // Clone the array
    tempDatePeriods.shift();  // Remove the first element

    this.reportingDatePeriods = tempDatePeriods;
  }

  // You give the date of the week (e.g "Sunday") and then it returns the next one excluding the same day
  // Will be used for Suppliers, Upload File Page
  getNextDayOfTheWeek(dayName: string, excludeToday: boolean, refDate: Date) {
    const dayOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"].indexOf(dayName.slice(0, 3).toLowerCase());
    if (dayOfWeek < 0) return;

    // Convert refDate to a Moment object
    let refMoment = moment(refDate).startOf('day');

    // Calculate the offset for the next occurrence of the specified day of the week
    let currentDay = refMoment.day();
    let daysUntilNext = (dayOfWeek + 7 - currentDay) % 7;

    if (excludeToday && daysUntilNext === 0) {
      daysUntilNext = 7; // Skip to the next week if excluding today and it's today
    }

    // Add the calculated days to the reference date
    refMoment.add(daysUntilNext, 'days');

    // Convert the Moment object back to a native JavaScript Date object
    return refMoment.toDate();
  }

  // Used to compare dates (fromDate & toDate > checkDate)
  uploadDateCheck(fromDate:any, toDate:any, checkDate:any) {
    if(checkDate < fromDate && checkDate < toDate){
      return true;
    } else {
      if (checkDate.toLocaleDateString() == toDate.toLocaleDateString() && checkDate.getHours() < 16){
        return true;
      }
      return false;
    } 
  }

  // Check if it's a year YYYY
  isYear(value: string): boolean {
    const yearFormat = /^[0-9]{4}$/;
    return yearFormat.test(value);
  }

  // Check if it's a Month MM, [1-12]
  isMonth(value: string): boolean {
    const monthFormat = /^(1[0-2]|[1-9])$/;
    return monthFormat.test(value);
  }

  // Check if it's a Week WW, [0-53]
  isWeek(value: string): boolean {
    const weekFormat = /^([1-9]|[1-4][0-9]|5[0-3])$/;
    return weekFormat.test(value);
  }

  // Year YYYY
  getCurrentYear(){
    return moment().format('YYYY');
  }
  
  // Week [1-53]
  getCurrentWeek(){
    return String(moment().isoWeek());
  }

  // Month [1-12]
  getCurrentMonth(){
    return String(moment().month() + 1);
  }  

  // Convert date to this format --> YYYY-MM-DD (Create/Update purposes)
  convertDate(inputFormat: Date) {
    return moment(inputFormat).format('YYYY-MM-DD');
  }

  // Check if it'a a valid date (e.g 22/03/2022)
  isADate(dateText:any) {
    const parsedDate = moment(dateText, this.possibleDateFormats, true);
    return parsedDate.isValid();
  }

  // Check if it'a a valid date (e.g 22/03/22)
  isAnAmericanDate(dateText:any) {
      let dateRegex:any = /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/[0-9]{4}$/;
      return dateRegex.test(dateText);
  }

  convertAmericanToBritish(dateText: string): string {
    const [month, day, year] = dateText.split('/');
    return `${day}/${month}/${year}`;
  }

  isShortDateFormat(dateText: string): boolean {
    const shortDateFormat = /^(0[1-9]|[12][0-9]|3[01])(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)\d{2}$/;
    return shortDateFormat.test(dateText.toUpperCase());
  }

  convertShortDateFormat(dateText: string): string {
    const day = dateText.substring(0, 2);
    const monthStr = dateText.substring(2, 5);
    const year = `20${dateText.substring(5, 7)}`;

    const months: { [key: string]: string } = {
        'JAN': '01',
        'FEB': '02',
        'MAR': '03',
        'APR': '04',
        'MAY': '05',
        'JUN': '06',
        'JUL': '07',
        'AUG': '08',
        'SEP': '09',
        'OCT': '10',
        'NOV': '11',
        'DEC': '12'
    };

    const month = months[monthStr];
    if (!month) {
      return ""
    }
    return `${day}/${month}/${year}`;
}

  // Check if date is a valid british format date (dd/mm/yyyy)
  isABritishDateFormat(dateText:any){
      const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/[0-9]{4}$/;
      return dateRegex.test(dateText);
  }
  
  // Check if it's a float number
  isAFloatNumber(number:any){
      let floatRegex = /^[+-]?[0-9]+([.][0-9]+)?([eE][+-]?[0-9]+)?$/;
      return floatRegex.test(number);
  }

  // Give a date and returns the following 02/11/2021 or 2022-04-24 (e.g 02NOV21 , 24APR22)
  formatBankStatementDate(givenDate:any) {
    let [tempMonth, tempDay, tempYear] = "";
    if (givenDate.includes("/")){
      [tempMonth, tempDay, tempYear] = givenDate.split('/');
    } else {
      [tempYear, tempMonth, tempDay] = givenDate.split('-');
    }
    const date = new Date(+tempYear, +tempMonth - 1, +tempDay);
    let {day, month, year} = new Intl.DateTimeFormat('en', {
      day:'2-digit',
      month: 'short',
      year: '2-digit'
    }).formatToParts(date).reduce((acc, part) => {
      if (part.type != 'literal') {
        acc[part.type] = part.value;
      }
      return acc;
    }, Object.create(null));
    return `${day}${month.toUpperCase()}${year}`;
  }

  convertDateToBritishFormat(date: string): string {
    const momentDate = moment(date, this.possibleDateFormats, true); // Parse with multiple formats
    if (momentDate.isValid()) {
        return momentDate.format('DD/MM/YYYY');
    } else {
        return `Invalid date: ${date}`;
    }
  }

  //////////////////////////////////
  //
  // Date Periods Setup Above
  //
  //////////////////////////////////

  getValidNumberToExcel(value:any) {
    const num = parseFloat(value);
    return isNaN(num) ? 0 : Number(num);
  }

  getValidDateToExcel(dateString:any) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) { // Check if the date is valid
      return ""; // Return an empty string or some default value for invalid dates
    }
    return date.toISOString().split('T')[0]; // Formats as 'YYYY-MM-DD'
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(_event: any) {
    this.innerWidth = window.innerWidth;
  }

  // Give a bank statement number value (e.g 7880.40 => 0788040)
  formatBankStatementNumber(value:any,lengthOfNumber:any) {
    let parsedValue:any = parseFloat(value).toFixed(2);
    let splitDecimalPoint = String(parsedValue).split('.');
    let finalString = String(splitDecimalPoint[0] + splitDecimalPoint[1]);

    // Handle negative values separately
    let isNegative = finalString.startsWith('-');
    if (isNegative) {
        finalString = finalString.substring(1); // Remove the negative sign for length calculation
    }

    if (finalString.length < lengthOfNumber - (isNegative ? 1 : 0)) {
        let counterOfZeros = lengthOfNumber - finalString.length - (isNegative ? 1 : 0);
        for (let counter = 0; counter < counterOfZeros; counter++) {
            finalString = "0" + finalString;
        }
    } else if (finalString.length > lengthOfNumber - (isNegative ? 1 : 0)) {
        // Trim the string if it exceeds the length
        finalString = finalString.substring(0, lengthOfNumber - (isNegative ? 1 : 0));
    }

    if (isNegative) {
        finalString = "-" + finalString; // Add the negative sign back if it was removed
    }

    return finalString;
  }

  parseDate(dateText: string): Date | null {
    const dateParts = dateText.split('/');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; 
    const year = parseInt(dateParts[2], 10);
    return new Date(year, month, day);
}

  // Gives the amount of transactions to the following format (e.g 4 -> 00004)
  formatBankStatementCounter(value:any,lengthOfNumber:any){
    let finalString = String(value);
    if (finalString.length < lengthOfNumber){
      let counterOfZeros = lengthOfNumber - finalString.length
      for (let counter = 0; counter < counterOfZeros; counter++){
          finalString = "0" + finalString;
      }
    } 
    return finalString;
  }

  // Open (default) Email Window 
  openEmail(email:any){
    let mailToLink = `mailto:${email}?`;
    window.open(mailToLink, '_self');
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any) {
    this.successMessage = successMessage;
    if (this.successMessage == '') {
      if (environment.production && error != '') {
        this.errorMessage = failureMessage;
        let request = { errCode: failureMessage.match(/\(([^)]+)\)/)[1], error: error, token: this.session.get('user').token };
        this.userService.writeError(request).then((_output: any) => { })
      } else if (error == '') {
        this.errorMessage = failureMessage;
      } else if (!environment.production && JSON.stringify(error) === '{}') {
        this.errorMessage = failureMessage;
        console.log(error);
      } else if (!environment.production) {
        this.errorMessage = failureMessage;
        console.log(JSON.stringify(error));
      }
    } else {
      this.errorMessage = '';
    }
    this.pageLoaded = true;
    this.dialog.open(this.statusDialog);
  }

  showHelp() {
    this.dialog.open(this.helpDialog);
  }

  // Used to support exportTapsReportAsExcelFile() to calculate the column index for formating and summary functions
  excelColumnToIndex(column: string): number {
    let index = 0;
    if (column == ""){
      return index;
    }
    for (let i = 0; i < column.length; i++) {
      index *= 26;
      index += column.charCodeAt(i) - 'A'.charCodeAt(0) + 1;
    }
    return index - 1; // Convert to zero-based index
  }

  // New export to EXCEL that was design to accomodate decimal rows and sum of decimal columns
  exportTapsReportAsExcelFile(json: any[], excelFileName: string, valueColumn: string, collectionColumn: string, includesTotal: boolean): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const lastRow = json.length + 1; 

    if (valueColumn != "" || collectionColumn != "") {

      const valueColumnIndex = this.excelColumnToIndex(valueColumn); 
      const collectionColumnIndex = this.excelColumnToIndex(collectionColumn); 

      // Setup Decimal Formating for 'Value' / 'Collection'
      for (let R = 1; R < lastRow; ++R) { 
        if(valueColumn != "") {
          const valueCellRef = XLSX.utils.encode_cell({r: R, c: valueColumnIndex});
          worksheet[valueCellRef].z = "0.00;-0.00;0.00";
        }
        if(collectionColumn != "") {
            const collectedCellRef = XLSX.utils.encode_cell({r: R, c: collectionColumnIndex}); 
            worksheet[collectedCellRef].z = "0.00;-0.00;0.00";
        }
      }

      if (includesTotal) {
        // Insert an empty row after the last data row
        const totalRow = lastRow + 1; // New total row index
    
        if (valueColumn != ""){
            // Adjust cell references for 'Total:' label and sum formula in the value column
            const labelCellRef = XLSX.utils.encode_cell({r: totalRow, c: 0});
            const sumCellRef = XLSX.utils.encode_cell({r: totalRow, c: valueColumnIndex});
            worksheet[labelCellRef] = {t: 's', v: `Total:`}; // Set 'Total:' label
            worksheet[sumCellRef] = {t: 'n', f: `SUM(${valueColumn}2:${valueColumn}${lastRow})`}; // Adjust sum formula to exclude the new empty row
            worksheet[sumCellRef].z = "0.00;-0.00;0.00";
        }
        if (collectionColumn != ""){
            // Adjust cell reference for sum formula in the collection column
            const sumCellRef = XLSX.utils.encode_cell({r: totalRow, c: collectionColumnIndex});
            worksheet[sumCellRef] = {t: 'n', f: `SUM(${collectionColumn}2:${collectionColumn}${lastRow})`}; // Adjust sum formula to exclude the new empty row
            worksheet[sumCellRef].z = "0.00;-0.00;0.00";
        }
    
        // Adjust the worksheet's reference range to include the new empty row and total row
        if(worksheet['!ref']) {
            const range = XLSX.utils.decode_range(worksheet['!ref']);
            range.e.r = Math.max(range.e.r, totalRow); // Adjust to include the total row
            worksheet['!ref'] = XLSX.utils.encode_range(range);
        }
      } else {
        // Setup SUM Functions for 'Value' / 'Collection'
        if (valueColumn != ""){
            const valueCellRef = XLSX.utils.encode_cell({r: lastRow, c: valueColumnIndex});
            worksheet[valueCellRef] = {t: 'n', f: `SUM(${valueColumn}2:${valueColumn}${lastRow})`};
            worksheet[valueCellRef].z = "0.00;-0.00;0.00";
        }
        if (collectionColumn != ""){
          const collectedCellRef = XLSX.utils.encode_cell({r: lastRow, c: collectionColumnIndex}); 
          worksheet[collectedCellRef] = {t: 'n', f: `SUM(${collectionColumn}2:${collectionColumn}${lastRow})`};
          worksheet[collectedCellRef].z = "0.00;-0.00;0.00";
        }
      }

      // Must for SUM Functions
      if(worksheet['!ref']) {
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        range.e.r = Math.max(range.e.r, lastRow);
        worksheet['!ref'] = XLSX.utils.encode_range(range);
      }

    }

    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, excelFileName + ".xlsx");
  }

  isLeapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  }

  setupAgentParent(){
    this.selectedAgentParent = "no";
    if (this.loggedInUserType == "Member"){
      if (Session.mySession.getBranch().tapsMaster != "no") {
        this.selectedAgentParent = "yes";
      } 
    }
    if (this.loggedInUserType == "Admin"){
      if (this.backUpAgentListData.data){
        this.backUpAgentListData.data.forEach((tempAgent:any)=>{
          if (tempAgent.tradeCode === this.selectedAgentForAdminExport){
            if (tempAgent.tapsMaster != "no"){
              this.selectedAgentParent = "yes";
            }
          }
        })
      }
    }
  }

  checkIfAgentIsChild():any{
    if (!this.backUpAgentListData?.data) return "Failed";
    let targetTradeCode: string | null = null;
    let returnAgent: any = "None";
    if (this.loggedInUserType === "Member") {
        targetTradeCode = Session.mySession.getUser().tradeCode;
    } else if (this.loggedInUserType === "Admin") {
        targetTradeCode = this.selectedAgentForAdminExport;
    }
    if (!targetTradeCode) return "Failed";
    this.backUpAgentListData.data.forEach((tempAgent: { tapsMaster: string; tradeCode: string }) => {
        if (tempAgent.tapsMaster !== "no" && tempAgent.tapsMaster.includes(targetTradeCode!)) {
            console.log(`Found parent ${tempAgent.tradeCode} of ${targetTradeCode}`);
            returnAgent = tempAgent
        }
    });
    return returnAgent;
  }

  // // Switch from Live to Dev (Pin:123456789)
  // showSwitchContent:boolean = false;
  // liveVersion:boolean = true;
  // pinToSwitch:any = "123456789"
  // pinGiven:any = ""

  // // Used to view switch between dev and live settings
  // toggleToViewSwitch() {
  //   this.showSwitchContent = !this.showSwitchContent;
  // }

  // // Switch to Dev Version (Used by the Dev team for testing)
  // switchToDevSite(){
  //   if (this.pinGiven == this.pinToSwitch){
  //     if (this.liveVersion == true){
  //       if (confirm("Are you sure you want to switch to the dev site?")) {
  //         this.sendMessageToDialog("Successfully switched to the 'Dev' site", "", "");
  //         this.liveVersion = false;
  //         this.transactionAvailability = true;    
  //       }
  //     } else {
  //       if (confirm("Are you sure you want to switch to the live site?")) {
  //         this.sendMessageToDialog("Successfully switched to the 'Live' site", "", "");
  //         this.liveVersion = true; 
  //         this.checkTransactionApprovalAvailability();     
  //       }
  //     }      
  //   } else {
  //     this.sendMessageToDialog("", "Please enter a valid pin to switch.", "");
  //   }    
  // }

}


