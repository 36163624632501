import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from '../../common/global-constants';
import { environment } from './../../../environments/environment';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Session } from '../../common/session';
import { HostListener } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgForm } from '@angular/forms';

import { CustomerService } from '../../services/customer.service';
import { BookingService } from '../../services/booking.service';
import { UserService } from '../../services/user.service';
import { FellohService } from '../../services/felloh.service';
import { BjornService } from '../../services/bjorn.service';
import { SupplierService } from '../../services/supplier.service';

import { AppComponent } from '../../app.component';

import * as moment from 'moment';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-create-link',
  templateUrl: './create-link.component.html',
  styleUrls: ['./create-link.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class CreateLinkComponent implements OnInit {
  // Import stuff from outside
  constants = new GlobalConstants();
  titles = GlobalConstants.titles;
  singsCustomerURL = environment.singsCustomerURL;
  innerWidth = AppComponent.myapp.innerWidth;

  // Data tables below
  customerData: any = new MatTableDataSource<any>();
  customer: any = {};
  duplicatePayment: any = {};

  // Regex for dropping requirement for booking prefix
  noLongerIbos = /^()$/;

  // Global variables for this file only
  userType = '';
  errorMessage: any = '';
  successMessage: any = '';
  userCompany = '';
  bookingPrefix: any = '';
  customerErrorMessage: any = '';
  allowedPaymentMethods: any = 'ALL';
  selectedCurrency: any = 'GBP';

  // Variables to do with autocomplete (TTA members only)
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  bookingRefString: any = '';
  bookRefList: any = [];
  bookingReference: any = '';
  supplierNames: any = [];
  departureDate: any = '';
  rtnDate: any = '';
  autocompleteClicked: any = false;
  branchListData: any = [];
  selectedBranch: any = {};

  // Access variables
  fxOpenView = false;
  haveAccess = false;
  pageLoaded = false;

  // View variables
  formCustomerDisable = false;
  newCustomer = false;
  existingCustomer = false;
  showSelectedCustomer = false;
  fellohView = true;
  fxView = false;

  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;
  @ViewChild('helpDialog') helpDialog!: TemplateRef<any>;

  constructor(private router: Router, private route: ActivatedRoute, private bookingService: BookingService,
              private customerService: CustomerService, private userService: UserService, private supplierService: SupplierService,
              private fellohService: FellohService, private bjornService: BjornService, public dialog: MatDialog) { }

  ngOnInit(): void {
    if (sessionStorage.length === 0 || Session.mySession === undefined) {
      // If the browser refreshed, SinGS redirects the page to the main page (components/home)
      AppComponent.myapp.fellohNav = true;
      this.router.navigate(['/']);
    } else {
      // If the page has been accessed by clicking a button - get userType & userCompany and load the page
      this.userType = Session.mySession.getUser().userType;
      this.userCompany = Session.mySession.getUser().company;

      this.route.params.subscribe(params => {
        this.branchListData = Session.mySession.getUsersGroup(); // Get the userGroup array from session
        // Check the window history for parsed payment from Felloh home site
        if (window.history.state.payment !== undefined) {
          // Retrieve duplicate payment from window history
          this.duplicatePayment = window.history.state.payment;

          // Retrieve the branch from window history and filter it out from the branch list data
          if (this.branchListData.length > 0) {
            this.changeBranch(this.branchListData.filter((branch: any) => branch.tradeCode === window.history.state.selectedBranch.tradeCode)[0], 'script');
          } else {
            this.changeBranch(Session.mySession.getBranch(), 'script'); // Branch list array empty - meaning we need to use users' current branch details
          }

          // All payments must have transactionId - therefore if undefined, create an object and call getCustomer with it
          const request = { value: { email: this.duplicatePayment.customer.email, lastName: '', telNo: '' } };
          this.loadDuplicate(request.value);
        } else {
          this.loadPage();
        }
      });
    }
  }

  loadPage(): void {
    if (this.userType === 'wcMember' || this.userType === 'wcManager' || this.userType === 'memberManager' || this.userType === 'memberStaff') {
      // This page is only reserved for wcMember users and admins
      // Logged in user is in the group and branch list already exists within the session variable.
      if (this.branchListData.length > 0) {
        this.changeBranch(this.branchListData.filter((branch: any) => branch.tradeCode === Session.mySession.getUser().tradeCode)[0], 'script');
      } else {
        this.changeBranch(Session.mySession.getBranch(), 'script'); // Load user's current branch
      }
      this.haveAccess = true;
    }
    this.pageLoaded = true;
  }

  getCustomer(form: NgForm): void {
    // Validate characters entered in the form
    if (this.constants.validateFormCharacters(form) !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else {
      // Assign the trade code and token to form object
      form.value.tradeCode = this.selectedBranch.tradeCode;
      form.value.token = Session.mySession.get('user').token;

      // Apply exclusiveToId filter if user is not permitted to see others' bookings
      if (Session.mySession.getUser().othersBookingAccess === 'no') {
        form.value.exclusiveToId = Session.mySession.getUser().id.toString();
      }

      this.pageLoaded = false;
      // Call getCustomer API and assign its output to 'customers'
      this.customerService.getCustomer(form.value).then((customers: any) => {
        if (customers.status === 'OK') {
          // Assign the data then to data table variable
          // Update CRF75 - need to filter out all customers without emails
          this.customerData.data = customers.data.filter((cust: any) => cust.email != null && cust.email !== '');
          this.pageLoaded = true;
          if (customers.data.length === 0) {
            this.sendMessageToDialog('', 'No customers have been found', '', '');
          }
        } else {
          // Print out the message and clear out data table variable
          this.sendMessageToDialog('', customers.status, '', '');
          this.customerData.data = [];
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0101S)', error, form.value);
      });
    }
  }

  loadDuplicate(form: any): void {
    this.pageLoaded = false;
    // Create new session object - easy getter and setter
    form.token = Session.mySession.get('user').token;
    form.tradeCode = this.selectedBranch.tradeCode;
    // Apply exclusiveToId filter if user is not permitted to see others' bookings
    if (Session.mySession.getUser().othersBookingAccess === 'no') {
      form.value.exclusiveToId = Session.mySession.getUser().id.toString();
    }
    // Call getCustomer API and assign its output to 'customers'
    this.customerService.getCustomer(form).then((customers: any) => {
      if (customers.status === 'OK' && customers.data.length > 0) {
        // Switch every view-based booleans so the page looks as if human selected / typed in all information
        const changeRadioBooking = { value: 'existing' };
        const changeRadioCustomer = { value: 'existing' };
        this.radioChangeCustomer(changeRadioCustomer);
        this.autocompleteClicked = true;
        // Select the first customer from an array. It will actually be the ONLY customer since the email
        // addresses are unique for all customers (within the trade code)
        // Update CRF75 - need to filter out all customers without emails
        this.customerData.data = customers.data.filter((cust: any) => cust.email != null && cust.email !== '');
        this.customerSelected(0);
        this.radioChangeBooking(changeRadioBooking);
      } else {
        // Print out the message and fill in all available fields
        this.sendMessageToDialog('', 'Please fill in missing details', '', '');
        // Switch every view-based booleans so the page looks as if human selected / typed in all information
        const changeRadioBooking = { value: 'existing' };
        const changeRadioCustomer = { value: 'new' };
        this.radioChangeCustomer(changeRadioCustomer);
        // Parse all attributes to the customer object which will be printed out in view page
        const custNameSplit = this.duplicatePayment.customer.name.split(' ');
        this.customer.firstName = custNameSplit[0];
        this.customer.lastName = custNameSplit[custNameSplit.length - 1];
        this.customer.email = this.duplicatePayment.customer.email;
        this.radioChangeBooking(changeRadioBooking);
      }
      // Booking data auto-completed before the page loads
      this.duplicateAutocomplete();
      this.pageLoaded = true; this.haveAccess = true;
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0102S)', error, form);
      this.haveAccess = true;
    });
  }

  customerSelected(index: any): void {
    // When selecting customer from the select list, index is used to map the object within the array list
    // That object is then assigned to the customer object and displayed to the user
    this.customer = this.customerData.data[index];
    this.showSelectedCustomer = true;
  }

  radioChangeBooking(event: any): void {
    if (event.value === 'existing' && Session.mySession.getUser().company === 'ttng') {
      // If booking existing and company = ttng -> Booking prefix will be NWG-
      this.bookingPrefix = 'NWG-';
    } else if (event.value === 'existing' && Session.mySession.getUser().company === 'gtg') {
      // If booking existing and company = gtg -> Booking prefix will be GTG-
      this.bookingPrefix = 'GTG-';
    } else if (event.value === 'new') {
      // If the booking doesn't exist in iSell just yet, the bookingPrefix will be set to temp-
      this.bookingPrefix = 'temp-';
    }
  }

  radioChangeCustomer(event: any): void {
    if (event.value === 'existing') {
      // If customer exists, set view controllers to show search functionality and make fields read-only
      this.existingCustomer = true;
      this.newCustomer = false;
      this.customerErrorMessage = '';
      this.formCustomerDisable = true;
      this.showSelectedCustomer = false;
    } else if (event.value === 'new') {
      // If customer is new, set view controllers to show fields which need to be filled in
      this.existingCustomer = false;
      this.newCustomer = true;
      this.customerErrorMessage = 'Customer has been created but ';
      this.formCustomerDisable = false;
      this.showSelectedCustomer = true;
    }
    // No matter what have been chosen, both customer object and customer data array are cleared up every time change is made
    this.customer = {};
    this.customerData.data = [];
    // Any time radio button is being changed, we need to reset below variables to empty string
    this.bookingPrefix = '';
  }

  duplicateAutocomplete(): void {
    if (this.duplicatePayment.merchantRequestId.substring(0, 4) === 'NWG-' || this.duplicatePayment.merchantRequestId.substring(0, 4) === 'GTG-') {
      // global bookingReference variable cannot have any prefix. That's where we're getting rid of it
      this.bookingReference = this.duplicatePayment.merchantRequestId.substring(4, this.duplicatePayment.merchantRequestId.length);
      this.bookingRefString = this.duplicatePayment.merchantRequestId.substring(4, this.duplicatePayment.merchantRequestId.length);
    } else {
      // tta members don't have prefixes hence we're just copying whole reference here
      this.bookingReference = this.duplicatePayment.merchantRequestId;
      this.bookingRefString = this.duplicatePayment.merchantRequestId;
    }
    if (this.duplicatePayment.additionalProperties.departDate !== undefined) { this.departureDate = this.duplicatePayment.additionalProperties.departDate; }
    else if (this.duplicatePayment.additionalProperties.departAt !== undefined) { this.departureDate = this.duplicatePayment.additionalProperties.departAt; }

    if (this.duplicatePayment.additionalProperties.returnDate !== undefined) { this.rtnDate = this.duplicatePayment.additionalProperties.returnDate; }
    if (this.duplicatePayment.additionalProperties.supplierName !== undefined) { this.supplierNames = this.duplicatePayment.additionalProperties.supplierName; }
  }

  createFellohLink(form: NgForm): void {
    this.pageLoaded = false;
    if (this.bookingPrefix === '') {
      // In case any of the radio buttons haven't been selected - display it for the agent
      this.sendMessageToDialog('', 'Please select either New or Existing Booking', '', '');
    } else if (form.value.title === undefined) {
      // Select lists don't support 'required' attirubte..
      this.sendMessageToDialog('', 'Please select customer title from the list', '', '');
    } else if (form.value.title === '' || form.value.firstName === '' || form.value.lastName === '' ||
      form.value.bookingReference === '' || form.value.amountDue === '' || form.value.notes === '') {
      // In case any of the required fields are empty, throw an error message
      this.sendMessageToDialog('', 'Please fill in all of the required fields', '', '');
    } else if (this.bookingPrefix !== 'temp-' && (form.value.bookingReference.match(/^-?\d+$/) == null || form.value.bookingReference <= 0)) {
      // Throw an error message if the booking reference is less than 0 or if it contains non-numeric character
      this.sendMessageToDialog('', 'Booking reference is in the wrong format', '', '');
    } else if (this.constants.validateFormCharacters(form) !== true) {
      // Validate characters entered in the form
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else {
      // Below happens if the 'New Customer' radio button have been selected
      if (this.formCustomerDisable === false) {
        form.value.token = Session.mySession.get('user').token;
        form.value.tradeCode = this.selectedBranch.tradeCode;
        // Below are object attributes needed for creating new customer in DB
        // If not provided, all of the below will be 'null' rather than empty string ''
        form.value.middleName = '';
        form.value.telNo = '';
        form.value.homeNo = '';
        form.value.postcode = '';
        form.value.addressLine1 = '';
        form.value.addressLine2 = '';
        form.value.addressLine3 = '';
        form.value.addressLine4 = '';
        form.value.county = '';
        form.value.country = '';

        this.createCustomer(form, 'ttngGtg');
      } else if (this.formCustomerDisable === true) {
        // Below happens if the customer already exists within SinGS - no need to call several APIs to create customer
        this.getTempOrValidateBooking(form);
      }
    }
  }

  createFX(form: NgForm): void {
    this.pageLoaded = false;
    if (form.value.title === undefined) {
      // Select lists don't support 'required' attirubte..
      this.sendMessageToDialog('', 'Please select customer title from the list', '', '');
    } else if (form.value.title === '' || form.value.firstName === '' || form.value.lastName === '' ||
      form.value.bookingReference === '' || form.value.amountDue === '') {
      // In case any of the required fields are empty, throw an error message
      this.sendMessageToDialog('', 'Please fill in all of the required fields', '', '');
    } else if (this.constants.validateFormCharacters(form) !== true) {
      // Validate characters entered in the form
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else {
      this.bookingPrefix = 'FX-';
      if (this.formCustomerDisable === false) {
        // Below happens if the 'New Customer' radio button have been selected.
        form.value.token = Session.mySession.get('user').token;
        form.value.tradeCode = this.selectedBranch.tradeCode;
        // Below are object attributes needed for creating new customer in DB
        // If not provided, all of the below will be 'null' rather than empty string ''
        form.value.middleName = '';
        form.value.telNo = '';
        form.value.homeNo = '';
        form.value.postcode = '';
        form.value.addressLine1 = '';
        form.value.addressLine2 = '';
        form.value.addressLine3 = '';
        form.value.addressLine4 = '';
        form.value.county = '';
        form.value.country = '';

        this.createCustomer(form, 'fx');
      } else if (this.formCustomerDisable === true) {
        // Below happens if the customer already exists within SinGS - no need to call several APIs to create customer
        this.createFellohLinkPt2(form);
      }
    }
  }

  getTempOrValidateBooking(form: NgForm): void {
    if (this.bookingPrefix === 'temp-') {
      // If 'New Booking' radio button checked, SinGS calls AWS which returns a new temporary reference number
      this.bjornService.getTempBookingRef(Session.mySession.get('user').token).then((output: any) => {
        // Remove 'temp-' characters and assign the number to the form.value object (e.g 0000123)
        form.value.bookingReference = output.bookingRef.substring(5, output.bookingRef.length);
        this.createFellohLinkPt2(form);
      }).catch((error: any) => {
        this.sendMessageToDialog('', this.customerErrorMessage + 'SinGS could not complete your request at this time (E0105S)', error, Session.mySession.get('user').token);
      });
    } else if (this.bookingPrefix === 'GTG-' || this.bookingPrefix === 'NWG-') {
      // Create request hash which is then used in the isValidBookingRef() - Front calls SinGS, which then calls Bjorn for verification.
      const request = {
        company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation, tradeCode: this.selectedBranch.tradeCode,
        bookingRef: this.bookingPrefix + form.value.bookingReference, token: Session.mySession.get('user').token
      };

      this.bjornService.isValidBookingRef(request).then((output: any) => {
        if (output.result === 'exists') {
          // Booking exists in Bjorn - good to go!
          this.createFellohLinkPt2(form);
        } else if (output?.status === 'Access denied') {
          this.sendMessageToDialog('', 'Access denied - please refresh your page', '', '');
        } else {
          // Bjorn may return the message saying that it couldn't find the booking. If that's the case, script stops here
          this.sendMessageToDialog('', 'This booking doesn\'t exist', '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', this.customerErrorMessage + 'Bjorn could not complete your request at this time (E0107S)', error, request);
      });
    }
  }

  createFellohLinkPt2(form: NgForm): void {
    // Create Felloh request hash to request access token and call the API
    Session.mySession.fetchFellohAuthorisation(this.fellohService).then((tokenOut: any) => {
      // tempRequest is used in paymentRequest hash when creating new payment link
      // If true (temporary reference), it will allow to change payments' booking reference
      let tempRequest = false;
      // const travelGroup = this.constants.translateMembership(this.selectedBranch.membershipType); // No longer needed..
      let logoURL = 'null';
      if (this.selectedBranch.logoRef !== '') {
        logoURL = encodeURI(this.selectedBranch.logoRef);
      }

      if (this.bookingPrefix === 'temp-') {
        tempRequest = true;
      }
      // Create new hash variable which hold all sorts of data required by Felloh before creating new payment link. Parameters worth noting are
      // merchantRequestId -> booking reference | successURL - page which will display after payment complete (TBD)
      // cancelURL -> page which will display after payment cancelled(? TBD) | paymentStatusCallbackUrl -> API which will be called after successful payment (TBD)
      // logoUrl -> logo which will appear in the top-left corner (TBD - need links for each branch)
      const successURL = environment.apiURL + 'fellohRedirect/uid=' + form.value.customerID + '/u=' + Session.mySession.getEmail().emailU + '/p=' + Session.mySession.getEmail().emailP + '/';

      const cancelURL = this.singsCustomerURL + 'cancelFelloh/' + encodeURI(this.selectedBranch.branchName) +
        '/' + logoURL + '/error';

      const paymentRequest = {
        connectedAccountId: Session.mySession.getFelloh().connectedAccID, merchantRequestId: this.bookingPrefix + form.value.bookingReference,
        // merchantName: this.selectedBranch.branchName, // No longer needed..
        customer: { name: form.value.firstName + ' ' + form.value.lastName, email: form.value.email },
        amount: form.value.amountDue.toFixed(2), currency: this.selectedCurrency, paymentDescription: form.value.notes, successUrl: successURL,
        cancelUrl: cancelURL, isTemporaryRequestId: tempRequest, requestCreator: Session.mySession.getUser().email, allowedPaymentMethods: this.allowedPaymentMethods,
        logoUrl: 'https://sings-media.s3.eu-west-2.amazonaws.com/logo/' + logoURL, additionalProperties: {
          // travelGroup // No longer needed..
        }
      };

      this.fellohService.createFellohPaymentRequest(paymentRequest, tokenOut).then(() => {
        // If success, set session variable and navigate to the main page
        Session.mySession.set('createdPayment', true);
        Session.mySession.set('paymentBranch', this.selectedBranch.tradeCode);
        this.router.navigate(['/main']);
      }).catch((error: any) => {
        this.sendMessageToDialog('', this.customerErrorMessage + 'Felloh could not complete your request at this time (E0101F)', error, paymentRequest);
      });
    }).catch((error: any) => {
      this.sendMessageToDialog('', this.customerErrorMessage + 'Felloh could not complete your request at this time (E0104F)', error, '');
    });
  }

  createFellohLinkTTA(form: NgForm): void {
    this.pageLoaded = false;
    if (form.value.title === undefined) {
      // Select lists don't support 'required' attirubte..
      this.sendMessageToDialog('', 'Please select customer title from the list', '', '');
    } else if (form.value.title === '' || form.value.firstName === '' || form.value.lastName === '' || form.value.deptDate === '' ||
      form.value.returnDate === '' || form.value.amountDue === '' || form.value.notes === '') {
      // In case any of the required fields are empty, throw an error message
      this.sendMessageToDialog('', 'Please fill in all of the required fields', '', '');
    } else if (this.bookingReference.toUpperCase().includes('TTAS') || this.bookingReference.toUpperCase().includes('TTNG') || this.bookingReference.toUpperCase().includes('GTGS')) {
      // Users from the booking system should still be able to pay for bookings from previous system (unless transfered?)
      // However, they won't be able to create a new temporary references. Nothing will stop them from paying for another booking ref
      // Though there will be validation so they cannot create payment links for SinGS bookings here
      this.sendMessageToDialog('', 'Payment links for SinGS bookings need to be created from Booking Portfolio', '', '');
    } else if (this.constants.validateFormCharacters(form) !== true) {
      // Validate characters entered in the form
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else {
      if (this.formCustomerDisable === false) {
        // Below happens if the 'New Customer' radio button have been selected.
        form.value.token = Session.mySession.get('user').token;
        form.value.tradeCode = this.selectedBranch.tradeCode;
        // Below are object attributes needed for creating new customer in DB
        // If not provided, all of the below will be 'null' rather than empty string ''
        form.value.middleName = '';
        form.value.telNo = '';
        form.value.homeNo = '';
        form.value.postcode = '';
        form.value.addressLine1 = '';
        form.value.addressLine2 = '';
        form.value.addressLine3 = '';
        form.value.addressLine4 = '';
        form.value.county = '';
        form.value.country = '';

        this.createCustomer(form, 'tta');
      } else if (this.formCustomerDisable === true) {
        // Below happens if the customer already exists within SinGS - no need to call several APIs to create customer
        this.getTempBookingTTA(form);
      }
    }
  }

  getTempBookingTTA(form: NgForm): void {
    form.value.bookingReference = this.bookingRefString;
    // Felloh-only users will be able to create payment with empty reference
    // tmeporary payment will be then created which will be 'transferable' to actual reference from their system
    // no validation is done if a reference is provided
    if (form.value.bookingReference === '' || form.value.bookingReference === undefined || form.value.bookingReference == null) {
      // If 'New Booking' radio button checked, SinGS calls AWS which returns a new temporary reference number
      this.bjornService.getTempBookingRef(Session.mySession.get('user').token).then((output: any) => {
        // We don't need to remove 'temp-' bit from here as we're not playing around with prefixes for TTA members
        this.bookingRefString = output.bookingRef;
        this.createFellohLinkPt2TTA(form);
      }).catch((error: any) => {
        this.sendMessageToDialog('', this.customerErrorMessage + 'SinGS could not complete your request at this time (E0106S)', error, Session.mySession.get('user').token);
      });
    } else {
      this.createFellohLinkPt2TTA(form);
    }
  }

  createFellohLinkPt2TTA(form: NgForm): void {
    // Create Felloh request hash to request access token and call the API
    Session.mySession.fetchFellohAuthorisation(this.fellohService).then((tokenOut: any) => {
      form.value.bookingReference = this.bookingRefString;
      let tempRequest = false;
      // If getTempBookingTTA function assigned a temporary reference to the payment link, we need to mark it
      // for Felloh with a tempRequest boolean
      if (form.value.bookingReference.toString().substring(0, 5) === 'temp-') {
        tempRequest = true;
      }

      // const travelGroup = this.constants.translateMembership(this.selectedBranch.membershipType); // No longer needed..
      let logoURL = 'null';
      if (this.selectedBranch.logoRef !== '') {
        logoURL = encodeURI(this.selectedBranch.logoRef);
      }

      // Create new hash variable which hold all sorts of data required by Felloh before creating new payment link. Parameters worth noting are
      // merchantRequestId -> booking reference | successURL - page which will display after payment complete (TBD)
      // cancelURL -> page which will display after payment cancelled(? TBD) | paymentStatusCallbackUrl -> API which will be called after successful payment (TBD)
      // logoUrl -> logo which will appear in the top-left corner (TBD - need links for each branch)
      const successURL = environment.apiURL + 'fellohRedirect/uid=' + form.value.customerID + '/u=' + Session.mySession.getEmail().emailU + '/p=' + Session.mySession.getEmail().emailP + '/';

      const cancelURL = this.singsCustomerURL + 'cancelFelloh/' + encodeURI(this.selectedBranch.branchName) +
        '/' + logoURL + '/error';

      const paymentRequest = {
        connectedAccountId: Session.mySession.getFelloh().connectedAccID, merchantRequestId: form.value.bookingReference,
        // merchantName: this.selectedBranch.branchName, // No longer needed..
        customer: { name: form.value.firstName + ' ' + form.value.lastName, email: form.value.email },
        amount: form.value.amountDue.toFixed(2), currency: this.selectedCurrency, paymentDescription: form.value.notes, successUrl: successURL,
        cancelUrl: cancelURL, isTemporaryRequestId: tempRequest, requestCreator: Session.mySession.getUser().email, allowedPaymentMethods: this.allowedPaymentMethods,
        logoUrl: 'https://sings-media.s3.eu-west-2.amazonaws.com/logo/' + logoURL, additionalProperties: {
          // travelGroup, // No longer needed..
          supplierName: this.supplierNames,
          departDate: this.constants.convertDateMoment(form.value.deptDate),
          returnDate: this.constants.convertDateMoment(form.value.returnDate),
        }
      };

      this.fellohService.createFellohPaymentRequest(paymentRequest, tokenOut).then(() => {
        // If success, set session variable and navigate to the main page
        Session.mySession.set('createdPayment', true);
        Session.mySession.set('paymentBranch', this.selectedBranch.tradeCode);
        this.router.navigate(['/main']);
      }).catch((error: any) => {
        this.sendMessageToDialog('', this.customerErrorMessage + 'Felloh could not complete your request at this time (E0102F)', error, paymentRequest);
      });
    }).catch((error: any) => {
      this.sendMessageToDialog('', this.customerErrorMessage + 'Felloh could not complete your request at this time (E0105F)', error, '');
    });
  }

  createCustomer(form: NgForm, whatCalling: any): void {
    // Apply exclusiveToId filter if user is not permitted to see others' bookings
    if (Session.mySession.getUser().othersBookingAccess === 'no') {
      form.value.exclusiveToId = Session.mySession.getUser().id.toString();
    }
    // If 'New Customer' selected BUT Customer did not provide email address
    // We are creating a 'fake' email address for them
    if (form.value.email === undefined || form.value.email === '') {
      let fakeNumber = 0;
      fakeNumber = Math.floor(Math.random() * 9999999) + 1;
      form.value.email = 'cust' + fakeNumber + '@thetravelnetworkgroup.co.uk';

      this.customerService.createCustomer(form.value).then((output: any) => {
        if (output.status === 'OK') {
          // This happens only if the customer has been created successfully
          form.value.customerID = output.id;
          this.customer.id = output.id;
          this.customer.email = 'cust' + fakeNumber + '@thetravelnetworkgroup.co.uk';
          if (whatCalling === 'ttngGtg') {
            this.getTempOrValidateBooking(form);
          } else if (whatCalling === 'tta') {
            this.getTempBookingTTA(form);
          } else if (whatCalling === 'fx') {
            this.createFellohLinkPt2(form);
          }
        } else if (output.status === 'Email has already been taken') {
          // Customer haven't been created successfully - this is where the script ends
          this.sendMessageToDialog('', 'SinGS could not generate Customer email at this time - please try again', '', '');
        } else {
          this.sendMessageToDialog('', output.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0103S)', error, form.value);
      });
    } else {
      // Email address has been provided by the agent
      this.customerService.createCustomer(form.value).then((output: any) => {
        if (output.status === 'OK') {
          // This happens only if the customer has been created successfully
          form.value.customerID = output.id;
          if (whatCalling === 'ttngGtg') {
            this.getTempOrValidateBooking(form);
          } else if (whatCalling === 'tta') {
            this.getTempBookingTTA(form);
          } else if (whatCalling === 'fx') {
            this.createFellohLinkPt2(form);
          }
        } else if (output.status === 'Email has already been taken') {
          // Customer haven't been created successfully - this is where the script ends
          this.sendMessageToDialog('', output.status + ' - please select Existing Customer option', '', '');
        } else {
          this.sendMessageToDialog('', output.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0104S)', error, form.value);
      });
    }
  }

  bookingRefLookup(event: any, whatCalling: any): void {
    // Any user input fills in the search string which is used later on
    if (whatCalling === 'input') {
      this.bookingRefString = event.target.value.trim();
      if (this.autocompleteClicked === true) { this.clearTTAboxes(); this.autocompleteClicked = false; }
    }
    // Only search button can invoke below if statement
    if (this.bookingRefString.length > 4 && whatCalling === 'button') {
      // Create Felloh request hash to request access token and call the API
      Session.mySession.fetchFellohAuthorisation(this.fellohService).then((tokenOut: any) => {
        const paymentRequest = { bookingReference: this.bookingRefString };
        // Get the reference number from the user and look it up in Felloh
        this.fellohService.getBookingsFellohPayments(paymentRequest, tokenOut).then((fellohList: any) => {
          // Ignore payments without departDate, returnDate and supplierName (won't show in the autofill)
          // Show only unique payments in autofill (don't want 10 rows with the same details)
          const list: any = [];
          fellohList.filter((item: any): any => {
            if (item.hasOwnProperty('additionalProperties') &&
              (item.additionalProperties.departDate !== undefined || item.additionalProperties.departAt !== undefined) &&
              item.additionalProperties.returnDate !== undefined && item.additionalProperties.supplierName !== undefined) {
              const i = list.findIndex((x: any) => (
                (x.additionalProperties.departDate === item.additionalProperties.departDate ||
                x.additionalProperties.departAt === item.additionalProperties.departAt) &&
                x.additionalProperties.returnDate === item.additionalProperties.returnDate &&
                x.additionalProperties.supplierName === item.additionalProperties.supplierName));
              if (i <= -1) {
                list.push(item);
              }
            }
          });
          this.bookRefList = list;
        }).catch((err) => {
          this.bookRefList = [];
          this.sendMessageToDialog('', 'No results found', '', '');
        });
      });
    } else if (this.bookingRefString.length < 5 && whatCalling === 'button') {
      this.sendMessageToDialog('', 'Booking reference needs to have at least 5 characters', '', '');
    } else {
      this.bookRefList = [];
    }
  }

  selectBookRef(event: any): void {
    const selectedRow = this.bookRefList[event];

    if (typeof selectedRow.additionalProperties.supplierName === 'string') {
      this.supplierNames = [selectedRow.additionalProperties.supplierName];
    } else {
      this.supplierNames = selectedRow.additionalProperties.supplierName;
    }

    if (selectedRow.additionalProperties.departDate !== undefined) { this.departureDate = selectedRow.additionalProperties.departDate; }
    else if (selectedRow.additionalProperties.departAt !== undefined) { this.departureDate = selectedRow.additionalProperties.departAt; }

    this.rtnDate = selectedRow.additionalProperties.returnDate;
    this.bookingReference = selectedRow.merchantRequestId;

    // tslint:disable-next-line:no-non-null-assertion
    (document.getElementById('bookingReference')! as HTMLInputElement).value = this.bookingRefString;
    this.autocompleteClicked = true;
  }

  changeBranch(branch: any, source: any): void {
    this.selectedBranch = branch; // Assign new trade code to the 'global' request
    Session.mySession.setFellohAdmin(branch.fellohAccountId); // Assign new felloh account ID
    const changeRadioCustomer = { value: 'existing' };
    // Switch the radios again to restart process
    if (source === 'view') { this.radioChangeCustomer(changeRadioCustomer); }
    this.validateFXopeness(branch.tradeCode); // Call this to validate FX openess
    this.selectedCurrency = 'GBP'; // Make sure the selected currency is GBP by default
  }

  validateFXopeness(tradeCode: any): void {
    const regex = /(Q0284|Q0655|Q0829|Q7211|Q7226|Q9039|Q9043|Q9058|Q9062|Q9077|Q9081|Q9096|Q9109|Q9113|Q9128|Q9166|Q9363)/;
    // Pretty straight forward - we're 'opening' the FX part for above members..
    if (regex.test(tradeCode)) { this.fxOpenView = true; }
  }

  // Reset autocomplete form
  clearTTAboxes(): void {
    this.bookRefList = [];
    this.supplierNames = [];
    this.departureDate = '';
    this.rtnDate = '';
  }

  addChip(event: any, supplierNames: any): void {
    const value = (event.value || '').trim();
    if (value) { supplierNames.push(value); }
    event.input.value = '';
  }

  addChipV2(event: any, supplierNames: any): void {
    const value = (event.value || '').trim();
    const request = { supplierNameX: value, token: Session.mySession.get('user').token };

    this.supplierService.getSupplierMaster(request).then((output: any) => {
      if (output !== false && output.status === 'OK' && output.suppMapList.length > 0) {
        if (supplierNames.indexOf(output.suppMapList[0].supplierNameM) === -1) {
          supplierNames.push(output.suppMapList[0].supplierNameM);
        } else {
          this.sendMessageToDialog('', 'You\'ve already added ' + output.suppMapList[0].supplierNameM, '', '');
        }
      } else {
        this.sendMessageToDialog('', 'This supplier does not exist', '', '');
      }
      event.input.value = '';
    });
  }

  removeChip(name: any, supplierNames: any): void {
    const index = supplierNames.indexOf(name);
    supplierNames.splice(index, 1);
  }

  switchView(view: any): void {
    this.existingCustomer = false;
    this.newCustomer = false;
    this.formCustomerDisable = false;
    this.showSelectedCustomer = false;

    // Reset autocomplete form
    this.clearTTAboxes();
    this.bookingReference = '';

    // Nothing special to add. Whenever button on left nav-bar is clicked, change the view variables values.
    if (view === 'felloh') {
      this.fellohView = true;
      this.fxView = false;
      this.bookingPrefix = '';
    } else if (view === 'fx') {
      this.fellohView = false;
      this.fxView = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  valiDate(event: any): void {
    if (event.value != null) {
      const dateIn = event.value._i;
      if (dateIn.year === undefined && // Whenever date is being changed, check if it's in one of three formats (backslash / dots / dash)
        !moment(dateIn, 'DD/MM/YYYY', true).isValid() && !moment(dateIn, 'DD.MM.YYYY', true).isValid() && !moment(dateIn, 'DD-MM-YYYY', true).isValid()) {
        this.sendMessageToDialog('', 'Please follow DD MM YYYY format', '', '');
        event.target.value = '';
      }
    }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // Check if the error comes from Felloh and if we can display it to the user
      // If we can then let's display it rather than give them weird code..
      if (error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
        failureMessage = error.error.message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      } else if (error.hasOwnProperty('error') && error.error.hasOwnProperty('error') && error.error.error.hasOwnProperty('message')) {
        failureMessage = error.error.error.message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      } else if (error.hasOwnProperty('error') && error.error.hasOwnProperty('errors') && error.error.errors.length > 0) {
        failureMessage = error.error.errors[0].message; // Assign error message here
        error = ''; // Error object gets muted so no mail is being sent to me
      }
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    this.pageLoaded = true; // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    // Pop-up message only appears if either success or error message is not empty
    if (this.successMessage !== '' || this.errorMessage !== '') { this.dialog.open(this.statusDialog); }
  }

  showHelp(): void {
    this.dialog.open(this.helpDialog);
  }
}
