import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  constructor(private http: HttpClient) { }

  async getSupplierList(request: any): Promise<object> {
    let results: any = []; // Array to hold all the results
    let promises = []; // Array to hold all promises
    let skip = 0;
    const take = 2000;

    const headers = { 'content-type': 'application/json' };

    while (true) {
        const currentRequest = { ...request, skip, take };
        const body = JSON.stringify(currentRequest);

        // Create a promise for each request and add it to the promises array
        const promise = this.http.post(environment.apiURL + 'getSupplierList', body, { headers }).toPromise();
        promises.push(promise);

        // Await the last promise to check status and decide if we need to keep fetching
        const output: any = await promise;

        // Check if the status is not OK
        if (output.status !== "OK") {
            return { status: output.status, data: [] }; // Return immediately with the current status and empty data
        }

        // If the status is OK, add the data to results
        results = results.concat(output.data); // Assuming data is in output.data

        // Check if there's more data to fetch
        if (output.taken < take) {
            break; // Break the loop if the returned data is less than requested (end of data)
        }

        // Increment skip for the next iteration
        skip += take;
    }

    return { status: "OK", data: results };
  }

  async getSupplierRequests(request: any): Promise<object> {
    let results: any[] = []; // Array to hold all the results
    let skip = 0;
    const take = 2000; // Assuming take is applicable, adjust if needed

    while (true) {
        // Construct the URL with the necessary parameters
        const url = `${environment.apiURL}getSupplierRequests?company=${request.company}&operation=${request.operation}&tradeCode=${request.tradeCode}&type=${request.type}&token=${request.token}&skip=${skip}&take=${take}`;

        // Make the HTTP GET request
        const output: any = await this.http.get(url).toPromise();

        // Check if the status is not OK
        if (output.status !== "OK") {
            return { status: output.status, data: [] }; // Return immediately with the current status and empty data
        }

        // If the status is OK, add the data to results
        results = results.concat(output.data); // Assuming data is in output.data

        // Check if there's more data to fetch
        if (output.taken < take) {
            break; // Break the loop if the returned data is less than requested (end of data)
        }

        // Increment skip for the next iteration
        skip += take;
    }

    return { status: "OK", data: results };
}

  async updateSupplier(supplier: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(supplier);
    const output = await this.http.post(environment.apiURL + 'updateSupplier', body, { headers }).toPromise();
    return output;
  }

  async removeSupplier(supplier: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(supplier);
    const output = await this.http.post(environment.apiURL + 'removeSupplier', body, { headers }).toPromise();
    return output;
  }

  async createSupplier(supplier: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(supplier);
    const output = await this.http.post(environment.apiURL + 'addSupplier', body, { headers }).toPromise();
    return output;
  }

  async sendApprovedSupplierRequest(supplier: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(supplier);
    const output = await this.http.post(environment.apiURL + 'sendApprovedSupplierRequest', body, { headers }).toPromise();
    return output;
  }

  async createSupplierRequest(supplier: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(supplier);
    const output = await this.http.post(environment.apiURL + 'createSupplierRequest', body, { headers }).toPromise();
    return output;
  }

  async updateSuppReqStatus(supplier: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(supplier);
    const output = await this.http.post(environment.apiURL + 'updateSuppReqStatus', body, { headers }).toPromise();
    return output;
  }

  async findMasterName(request: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'findMasterName?supplierName=' + encodeURIComponent(request.supplierName) +
    '&company=' + request.company + '&operation=' + request.operation + '&tradeCode=' + request.tradeCode + '&token=' + request.token).toPromise();
    return output;
  }

  async getSupplierMap(supplier: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'getSupplierMap?supplierNameM=' + encodeURIComponent(supplier.supplierNameM) + '&token=' + supplier.token).toPromise();
    return output;
  }

  async getSupplierMaster(supplier: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'getSupplierMap?supplierNameX=' + encodeURIComponent(supplier.supplierNameX) + '&token=' + supplier.token).toPromise();
    return output;
  }

  async addSupplierMap(supplier: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(supplier);
    const output = await this.http.post(environment.apiURL + 'addSupplierMap', body, { headers }).toPromise();
    return output;
  }

  async removeSupplierMap(supplier: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(supplier);
    const output = await this.http.post(environment.apiURL + 'removeSupplierMap', body, { headers }).toPromise();
    return output;
  }

  // Validation & upload of CSV data

  async validateSupplCSV(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'validateSupplCSV', body, { headers }).toPromise();
    return output;
  }

  async uploadSupplCSV(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'uploadSupplCSV', body, { headers }).toPromise();
    return output;
  }

  // SUPPLEMENTS PRICES BELOW

  async getSupplmPriceList(request: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'getSupplmPriceList?company=' + request.company + '&operation=' + request.operation + '&token=' + request.token).toPromise();
    return output;
  }

  async createSupplmPrice(supplier: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(supplier);
    const output = await this.http.post(environment.apiURL + 'createSupplmPrice', body, { headers }).toPromise();
    return output;
  }

  async unHideSupplmPrice(supplier: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(supplier);
    const output = await this.http.post(environment.apiURL + 'unHideSupplmPrice', body, { headers }).toPromise();
    return output;
  }

  async deleteSupplmPrice(supplier: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(supplier);
    const output = await this.http.post(environment.apiURL + 'deleteSupplmPrice', body, { headers }).toPromise();
    return output;
  }

  async getSupplmPrice(request: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'getSupplmPrice?company=' + request.company + '&operation=' + request.operation + '&supName=' + encodeURIComponent(request.supName) +
    '&applicnDate=' + request.applicnDate + '&token=' + request.token).toPromise();
    return output;
  }

  // SUPPLEMENTS BELOW

  async updateSafiCoverage(supplier: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(supplier);
    const output = await this.http.post(environment.apiURL + 'updateSafiCoverage', body, { headers }).toPromise();
    return output;
  }
}
