import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { BranchService } from '../../services/branch.service';
import { UserService } from '../../services/user.service';
import { GlobalConstants } from '../../common/global-constants';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Session } from '../../common/session';
import { NgForm } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { environment } from './../../../environments/environment';
import * as moment from 'moment';
import { AppComponent } from '../../app.component';


@Component({
  selector: 'app-branch-list',
  templateUrl: './branch-list.component.html',
  styleUrls: ['./branch-list.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', marginTop: '-1.25px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0 }),
            animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)',
              style({ height: 300 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 300 }),
            animate('250ms cubic-bezier(0.4, 0.0, 0.2, 1)',
              style({ height: 0 }))
          ]
        )
      ]
    ),
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class BranchListComponent implements OnInit {
  // Boolean deciding whether user has access or not
  userType = '';
  userEmail = '';
  pageLoaded = false;
  haveAccess = false;
  createView = false;
  editView = true;
  logoJustUploaded = false;

  // List of the table columns which are displayed in .html file
  displayedColumns = ['tradeCode', 'branchName', 'fullName', 'membershipType', 'expand'];
  tradingNamesColumns = ['tradingName', 'logoRef', 'footerLink', 'phoneNumber', 'emailAddress', 'website', 'active', 'update'];
  fellohConfigColumns = ['accountName', 'accountCode', 'surcharging', 'bankingPull', 'currencies', 'active', 'update'];

  // The branchData from API will be assign to this variable. The MatTableDataSource class
  // is used for to the whole Component to work (+ it has its own built-in functions)
  branchData: any = new MatTableDataSource<any>();
  tradNameData: any = new MatTableDataSource<any>();
  fellohConfData: any = new MatTableDataSource<any>();

  // Imported variables from outside
  constants = new GlobalConstants();
  innerWidth = AppComponent.myapp.innerWidth;
  countryList = GlobalConstants.countryList;
  countryFiltered = GlobalConstants.countryList;
  fellohCurrencies = GlobalConstants.fellohCurrencies;

  // Import the const arrays
  membershipTypes = [];

  // Other variables
  errorMessage: any = '';
  successMessage: any = '';
  countryStr: any = ''; // Used to filter stuff from array (country list at the moment)
  newBranch: any = {}; // Needed for correct checkbox yes/no funcitonality
  selectedTradeCode: any = {}; // Used mainly for Bank Accounts (parsed into new window)
  newTradingName: any = {};
  newFellohConfig: any = {};

  // Below are variables which hold the customer-view skeleton view (adjustable)
  itineraryOption: string = 'chronologically';
  bookingInfoOrder = [ { part: '0', name: '' } ];
  itineraryInfoOrder = [ { part: '0', name: '' } ];
  infoOrderExpanded = false;

  // Variable below is used to filter tapsMater
  filteredByMembership: any = [];
  selectedChildren: any = [];

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;
  @ViewChild('helpDialog') helpDialog!: TemplateRef<any>;
  @ViewChild('bankingExternalBox') bankingExternalBox!: TemplateRef<any>;
  @ViewChild('contactExternalBox') contactExternalBox!: TemplateRef<any>;
  @ViewChild('tradingNamesBox') tradingNamesBox!: TemplateRef<any>;
  @ViewChild('fellohConfigBox') fellohConfigBox!: TemplateRef<any>;

  // Stuff needed for the 'expandable rows' to work
  expandedElement: any;
  isExpansionDetailRow = (i: number, row: object) => row.hasOwnProperty('detailRow');

  constructor(private router: Router, private userService: UserService,
              private branchService: BranchService, public dialog: MatDialog) { }

  ngOnInit(): void {
    if (sessionStorage.length === 0 || Session.mySession === undefined) {
      // If the browser refreshed, SinGS redirects the page to the main page (components/home)
      this.router.navigate(['/']);
    } else {
      // If the page has been accessed by clicking a button - get userType & userCompany and load the page
      this.userType = Session.mySession.getUser().userType;
      this.userEmail = Session.mySession.getUser().email;
      this.membershipTypes = this.constants.getMembershipTypes();
      this.loadPage();
      this.resetNewTradingName(); // Set the new account 'form' fields
      this.resetNewFellohConfig(); // Set the new account 'form' fields
    }
  }

  loadPage(): void {
    if (this.userType === 'sinGSAdmin' || this.userType === 'wcManager' || this.userType === 'memberManager' || this.userType === 'tapsAdmin') {
      // Branch list available only for admins
      // Call getBranches API and assign its output to 'branches'
      this.haveAccess = true;
      if (Session.mySession.getUsersGroup().length > 0) {
        // Logged in user is in the group and branch list already exists within the session variable..
        this.branchData.data = Session.mySession.getUsersGroup();
        this.branchData.data.forEach((element: any) => { element.detailRow = true; });
        this.branchData.paginator = this.paginator;
        this.pageLoaded = true;
      } else {
        this.branchService.getBranches(Session.mySession.getUser()).then((branches: any) => {
          if (branches.status === 'OK') {
            // In order to make the table 'expandable', we need to add a new property to each User object
            // We then assign the modified output to the class variable
            branches.data.forEach((element: any) => { element.detailRow = true; element.fellohEdit = false; });
            const sorted = branches.data.sort((a: any, b: any) => (a.tradeCode > b.tradeCode) ? 1 : -1);
            Session.mySession.setBranchList(sorted);
            this.branchData.data = sorted;
            this.branchData.paginator = this.paginator;
            this.pageLoaded = true;
          } else {
            this.sendMessageToDialog('', branches.status, '', '');
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0301S)', error, Session.mySession.getUser());
        });
      }
    } else {
      this.pageLoaded = true;
    }
  }

  updateBranch(form: NgForm, branch: any): void {
    // Validate characters entered in the form
    if (this.constants.validateFormCharacters(form) !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else {
      form.value.tradeCode = branch.tradeCode; // Append the trade code
      // Convert opening date ONLY IF its been provided by the user
      if (form.value.openingDate !== undefined && form.value.openingDate !== '') {
        form.value.openingDate = this.constants.convertDateMoment(form.value.openingDate);
        // In case the date was entered in the wrong format, 'erase' it instead..
        if (moment(form.value.openingDate, 'YYYY-MM-DD', true).isValid() === false) { form.value.openingDate = null; }
      }

      // Because we replaced yes/no selects with a checkboxes, we'll need to assign it from the object..
      form.value.isVatReg = branch.isVatReg; form.value.isTapsReg = branch.isTapsReg;
      form.value.tapsMaster = branch.isTapsMaster; form.value.tapsSynchOverride = branch.tapsSynchOverride;
      form.value.isTAtol = branch.isTAtol; form.value.isPrfReg = branch.isPrfReg; form.value.isSmartPrf = branch.isSmartPrf;
      form.value.fellohSetup = branch.fellohSetup; form.value.fellohSurcharging = branch.fellohSurcharging;
      form.value.isOneTouchReg = branch.isOneTouchReg;

      // Set public variable property value below (based on what's been selected / moved in the UI)
      let bookingEleCount = ''; let itineraryEleCount = '';
      // Work out Booking Information first..
      this.bookingInfoOrder.forEach((element: any) => {
        bookingEleCount = bookingEleCount + element.part + ','; // Order number followed by comma
      });
      // Then work out Itinerary..
      this.itineraryInfoOrder.forEach((element: any) => {
        itineraryEleCount = itineraryEleCount + element.part + ','; // Order number followed by comma
      });
      // Add two string values. Each of last characters (comma) replace with semicolon
      form.value.publicElementsOrder = bookingEleCount.replace(/.$/, ';') + itineraryEleCount.replace(/.$/, ';');
      form.value.itineraryOption = this.itineraryOption; // Chrono / successive
      // Join selected currencies together with a semicolon..

      // If member is master, join selected children below
      if (form.value.tapsMaster === 'yes') {
        form.value.tapsMaster = this.selectedChildren.join(';');
      } else if (form.value.tapsMaster === 'no') {
        form.value.tapsMaster = 'no';
      }

      delete form.value.filterInput; // Remove filterInput property
      delete form.value.isTapsMaster; // Remove isTapsMaster property

      // Token needed for the request to 'go through'
      form.value.token = Session.mySession.get('user').token;

      this.pageLoaded = false;
      this.branchService.updateBranch(form.value).then((result: any) => {
        // Result is OK meaning everything went good..
        if (result.status === 'OK') {
          // If user uploaded logo SinGS will update it in Session (so future payments hold right logo link)
          if (this.logoJustUploaded === true) {
            Session.mySession.setBranchLogo(form.value.logoRef);
            this.logoJustUploaded = false;
          }
          // If user owns the branch which was just edited, then we make sure fees are updated accordingly
          if (Session.mySession.getUser().tradeCode === branch.tradeCode) {
            const request = { feeAmex: form.value.cardFeePercAmex, feeCredit: form.value.cardFeePercCredit, feeDebit: form.value.cardFeePercDebit };
            Session.mySession.setCardFees(request);
          }
          this.sendMessageToDialog('Branch has been updated', '', '', '');
        } else {
          // Return error status message to the user
          this.sendMessageToDialog('', result.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0302S)', error, form.value);
      });
    }
  }

  removeBranch(branch: any): void {
    if (confirm('Are you sure you want to delete ' + branch.fullName + '?')) {
      if (confirm('Still sure you want to delete ' + branch.fullName + '?')) {
        // If 100% sure user wants to delete branch, assign token into branch object and send the API
        branch.token = Session.mySession.get('user').token;

        this.pageLoaded = false;
        this.branchService.removeBranch(branch).then((result: any) => {
          if (result.status === 'OK') {
            // Status was OK meaning all went good.. now we need to filter selected branch out of the array
            this.branchData.data = this.branchData.filteredData.filter((item: any) => item.id !== branch.id);
            this.sendMessageToDialog('Branch has been removed', '', '', '');
          } else {
            // Return error status message back to the user
            this.sendMessageToDialog('', result.status, '', '');
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0303S)', error, branch);
        });
      }
    }
  }

  createBranch(form: NgForm): void {
    // Validate characters entered in the form
    if (this.constants.validateFormCharacters(form) !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else if (form.value.membershipType === '') {
      this.sendMessageToDialog('', 'Please choose membership type', '', '');
    } else {
      // Decided to hide fullName from the user. It'll be set automatically down below
      form.value.fullName = form.value.tradeCode + ' ' + form.value.branchName;
      // If openingDate provided, convert dateTime object into acceptable one back in Ruby
      if (form.value.openingDate !== undefined && form.value.openingDate !== '') {
        form.value.openingDate = this.constants.convertDateMoment(form.value.openingDate);
        // In case the date was entered in the wrong format, 'erase' it instead..
        if (moment(form.value.openingDate, 'YYYY-MM-DD', true).isValid() === false) { form.value.openingDate = null; }
      }
      delete form.value.filterInput; // Remove filterInput property

      // Because we replaced yes/no selects with a checkboxes, we'll need to assign it from the object..
      form.value.isVatReg = this.newBranch.isVatReg; form.value.isTapsReg = this.newBranch.isTapsReg;
      form.value.tapsMaster = this.newBranch.isTapsMaster; form.value.isTAtol = this.newBranch.isTAtol;
      form.value.isPrfReg = this.newBranch.isPrfReg; form.value.isSmartPrf = this.newBranch.isSmartPrf;
      form.value.fellohSetup = this.newBranch.fellohSetup; form.value.fellohSurcharging = this.newBranch.fellohSurcharging;
      form.value.tapsSynchOverride = this.newBranch.tapsSynchOverride; form.value.isOneTouchReg = this.newBranch.isOneTouchReg;

      // If member is master, join selected children below
      if (form.value.tapsMaster === 'yes') {
        form.value.tapsMaster = this.selectedChildren.join(';');
      } else if (form.value.tapsMaster === 'no') {
        form.value.tapsMaster = 'no';
      }
      // Set default customer-side element order below
      form.value.publicElementsOrder = '1,2,3,4;1,2,3,4,5,6,7,8;';
      form.value.itineraryOption = 'chronologically';
      // Set more default values velow
      form.value.fellohCurrencies = 'GBP';
      form.value.logoRef = ''; form.value.footerLink = '';
      form.value.styleSheet = 'stylesheet-sings.css';
      // Token needed for the request to 'go through'
      form.value.token = Session.mySession.get('user').token;

      this.pageLoaded = false;
      this.branchService.createBranch(form.value).then((result: any) => {
        if (result.status === 'OK') {
          // Branch was created - SinGS will now switch view to Branch List which will reload them
          this.sendMessageToDialog('Branch has been created!', '', '', '');
          this.switchView('edit');
        } else {
          // Display error message if unsuccessful
          this.sendMessageToDialog('', form.value.tradeCode + ' ' + result.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0304S)', error, form.value);
      });
    }
  }

  uploadFile(fileInputEvent: any, whatFile: any, tradingName: any): void {
    // Depending on what file is being uploaded, we create a variable name to be displayed in the inputs
    // The xxxFiles variables presumably are holding all selected files - will see if that's true
    let fileComment = 'none'; const arr = Array.from(fileInputEvent.target.files);

    arr.forEach((file: any) => {
      if (file.size > 1048576) {
        fileComment = 'File size limit exceeded - please allow maximum 1MB per file'; // Don't allow too big files in S3 I'm afraid..
      } else if (file.name.match(/[!@#+=$£%^&*(),?":;'{}|<>[\]/]/)) {
        fileComment = 'Invalid file name - please avoid using special characters'; // Don't allow special characters in the file name..
      } else if (file.name.length > 40) {
        fileComment = 'File name limit exceeded - please allow a maximum of 40 characters';
      } else {
        const request = {
          fileName: tradingName.tradeCode + tradingName.id + '-' + file.name,
          file, whatFile, token: Session.mySession.get('user').token
        };

        this.pageLoaded = false;
        this.branchService.updateBranchMedia(request).then((output: any) => {
          if (output.status === 'OK') {
            // Everyting went good as stauts is OK..
            if (whatFile === 'logo') {
              tradingName.logoRef = request.fileName; // Update logoRef for the branch which will be updated after pressing the button
              // Update global logoJustUploaded for when branch is updated, session logo is updated too
              if (this.userType === 'wcManager' || this.userType === 'memberManager') { this.logoJustUploaded = true; }
            } else if (whatFile === 'footer') {
              tradingName.footerLink = request.fileName; // Update logoRef for the branch which will be updated after pressing the button
            }
            this.amendTradingName(tradingName, false); // Update branch details afterwards..
          } else {
            this.sendMessageToDialog('', output.status, '', ''); // Display error message
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0305S)', error, '');
        });
      }
    });

    if (fileComment !== 'none') { this.sendMessageToDialog('', fileComment, '', ''); }
  }

  openMedia(whatFile: any, branch: any): void {
    // Depending on what file is requested, use appropiate folder in the s3 directory
    if (whatFile === 'logo') {
      window.open('https://sings-media.s3.eu-west-2.amazonaws.com/' + whatFile + '/' + branch.logoRef, '_blank');
    } else if (whatFile === 'footer') {
      window.open('https://sings-media.s3.eu-west-2.amazonaws.com/' + whatFile + '/' + branch.footerLink, '_blank');
    }
  }

  doFilter(event: Event): void {
    // Function used to Filter the table
    const filterValue = (event.target as HTMLInputElement).value;
    this.branchData.filter = filterValue.trim().toLowerCase();
  }

  setFellohCurrencies(): void {
    // We need to convert it to array so the whole mini-system works as designed
    this.fellohConfData.data.forEach((config: any) => {
      if (config.currencies !== null && config.currencies !== '') {
        config.selectedCurrencies = config.currencies.split(';');
      } else {
        config.selectedCurrencies = []; // Anything non-expected - set as empty
      }
    });
  }

  addRemoveFellohCurrencies(event: any, fellohConfig: any): void {
    if (!event.target.checked) {
      fellohConfig.selectedCurrencies.splice(fellohConfig.selectedCurrencies.indexOf(event.target.value), 1);
    } else {
      fellohConfig.selectedCurrencies.push(event.target.value);
    }
  }

  switchView(view: any): void {
    // Simple controller to change the page view ports
    this.expandedElement = null;
    if (view === 'create') {
      this.createView = true;
      this.editView = false;
      this.newBranch = { membershipType: '', isVatReg: 'no', isTapsReg: 'no', isTAtol: 'no',
      tapsMaster: 'no', isTapsMaster: 'no', tapsSynchOverride: 'no', isPrfReg: 'no', isSmartPrf: 'yes',
      fellohSetup: 'no', fellohSurcharging: 'no', isOneTouchReg: 'no' };
    } else if (view === 'edit') {
      this.createView = false;
      this.editView = true;
      this.pageLoaded = false;
      this.loadPage();
    }
  }

  generateListElement(branch: any): void {
    // Variable(s) holding elements orders
    const bookingInfoOrder: any = [];
    const itineraryInfoOrder: any = [];
    // Default order of booking information page (customer-side)
    const defaultBookingOrder = [
      { part: '1', name: 'Booking Reference' },
      { part: '2', name: 'Departure Cooldown' },
      { part: '3', name: 'Booking Details' },
      { part: '4', name: 'Active Payment Links' },
      { part: '5', name: 'Charts' }
    ];
    // Default order of itinerary page (customer-side)
    const defaultItineraryOrder = [
      { part: '1', name: 'Attractions' },
      { part: '2', name: 'Car Hires' },
      { part: '3', name: 'Car Parks' },
      { part: '4', name: 'Cruises' },
      { part: '5', name: 'Flights' },
      { part: '6', name: 'Accommodations' },
      { part: '7', name: 'Miscellaneous' },
      { part: '8', name: 'Transfers' },
      { part: '9', name: 'Trains' }
    ];
    // Split publicElementsOrder property string to calculate current orders
    try {
      branch.publicElementsOrder.split(';')[0].split(',').forEach((orderNo: any) => {
        bookingInfoOrder.push(defaultBookingOrder[orderNo - 1]);
      });
      branch.publicElementsOrder.split(';')[1].split(',').forEach((orderNo: any) => {
        itineraryInfoOrder.push(defaultItineraryOrder[orderNo - 1]);
      });
      this.bookingInfoOrder = bookingInfoOrder;
      this.itineraryInfoOrder = itineraryInfoOrder;
      this.itineraryOption = branch.itineraryOption;
    } catch {
      // In case something goes wrong - simply assign default order to selected branch
      this.bookingInfoOrder = defaultBookingOrder;
      this.itineraryInfoOrder = defaultItineraryOrder;
    }
  }

  dropListElement(event: CdkDragDrop<string[]>, infoOrderArray: any): void {
    moveItemInArray(infoOrderArray, event.previousIndex, event.currentIndex);
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  filterMemberships(selBranch: any): void {
    // We need to filter member by its membership (do not allow mixing)
    this.filteredByMembership = this.branchData.data.filter((item: any) => item.membershipType === selBranch.membershipType && item.tradeCode !== selBranch.tradeCode);

    // We need to convert string array so the whole mini-system works as designed
    if (selBranch.tapsMaster !== null && selBranch.tapsMaster !== '' && selBranch.tapsMaster !== 'no') {
      selBranch.isTapsMaster = 'yes'; // We need separate property for the select list
      this.selectedChildren = selBranch.tapsMaster.split(';'); // Turn string into an array
    } else {
      selBranch.isTapsMaster = 'no'; // We need separate property for the select list
      this.selectedChildren = []; // Anything non-expected - set as empty
    }
  }

  exportToExcel(): void {
    const exportMe: any = [];
    // For each object, oneLine will be generated with appropiate values
    // And then pushed to exportMe which will be.. exported
    this.branchData.data.forEach((data: any) => {
      exportMe.push(data);
    });
    this.constants.exportAsExcelFile(exportMe, 'branchList');
  }

  addRemoveMemberChildren(event: any): void {
    if (!event.target.checked) {
      // Remove selected holiday interest from the array list
      this.selectedChildren.splice(this.selectedChildren.indexOf(event.target.value), 1);
    } else {
      // Add selected holiday interest into the list (end of the array but so what..?)
      this.selectedChildren.push(event.target.value);
    }
  }

  filterCountries(): void {
    this.countryFiltered = []; // Empty filtered array first
    const filter = this.countryStr.toLowerCase(); // Get the string we filter with here

    // Loop through our MAIN array and add whatever matches our search string
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.countryList.length; i++) {
      const option = this.countryList[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.countryFiltered.push(option);
      }
    }
  }

  valiDate(event: any): void {
    if (event.value != null) {
      const dateIn = event.value._i;
      if (dateIn.year === undefined && // Whenever date is being changed, check if it's in one of three formats (backslash / dots / dash)
        !moment(dateIn, 'DD/MM/YYYY', true).isValid() && !moment(dateIn, 'DD.MM.YYYY', true).isValid() && !moment(dateIn, 'DD-MM-YYYY', true).isValid()) {
        this.sendMessageToDialog('', 'Please follow DD MM YYYY format', '', '');
        event.target.value = '';
      }
    }
  }

  checkboxYesNo(event: any, object: any, property: any): void {
    if (!event.target.checked) { object[property] = 'no'; }
    else { object[property] = 'yes'; }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    this.pageLoaded = true; this.dialog.open(this.statusDialog);
  }

  showHelp(): void {
    this.dialog.open(this.helpDialog);
  }

  openBankingAccounts(tradeCode: any): void {
    this.selectedTradeCode = tradeCode;
    this.dialog.open(this.bankingExternalBox, {panelClass: 'bankingExternalBox'});
  }

  openContactDetails(tradeCode: any): void {
    this.selectedTradeCode = tradeCode;
    this.dialog.open(this.contactExternalBox, {panelClass: 'bankingExternalBox'});
  }

  openTradingNames(tradeCode: any): void {
    this.selectedTradeCode = tradeCode;
    this.getTradingNames(true);
  }

  openFellohConfig(tradeCode: any): void {
    this.selectedTradeCode = tradeCode;
    this.getFellohConfigs(true);
  }

  getTradingNames(openDialog: boolean): void {
    const request = { company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation,
      tradeCode: this.selectedTradeCode, token: Session.mySession.get('user').token };
    
    this.pageLoaded = true;
    this.branchService.getTradingNames(request).then((res: any) => {
      if (res.status === 'OK') {
        this.tradNameData.data = res.data;
        if (openDialog) { this.dialog.open(this.tradingNamesBox, { panelClass: 'tradingNameBox', autoFocus: false }); }
        this.pageLoaded = true;
      } else {
        this.sendMessageToDialog('', res.status, '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0306S)', error, '');
    });
  }

  addTradingName(): void {
    if (!this.newTradingName.tradingName || !this.newTradingName.phoneNumber || !this.newTradingName.emailAddress) {
      this.sendMessageToDialog('', 'Trading name, phone number and email address are mandatory', '', '');
    } else if (!this.newTradingName.emailAddress.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      this.sendMessageToDialog('', 'Please enter a valid email address', '', '');
    } else {
      const request: any = Object.assign({}, this.newTradingName);
      request.company = Session.mySession.getUser().company;
      request.operation = Session.mySession.getUser().operation;
      request.tradeCode = this.selectedTradeCode;
      request.token = Session.mySession.get('user').token;

      this.pageLoaded = false; // Duplicate object and use it to create new account
      this.branchService.addTradingName(request).then((output: any) => {
        if (output.status === 'OK') {
          // Reset new account fields and reload account list
          this.resetNewTradingName(); this.getTradingNames(false);
        } else {
          this.sendMessageToDialog('', output.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0307S)', error, request);
      });
    }
  }

  amendTradingName(tradingName: any, active: any): void {
    if (!tradingName.tradingName || !tradingName.phoneNumber || !tradingName.emailAddress) {
      this.sendMessageToDialog('', 'Trading name, phone number and email address are mandatory', '', '');
    } else if (!tradingName.emailAddress.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      this.sendMessageToDialog('', 'Please enter a valid email address', '', '');
    } else {
      const request: any = Object.assign({}, tradingName);
      request.company = Session.mySession.getUser().company;
      request.operation = Session.mySession.getUser().operation;
      request.tradeCode = this.selectedTradeCode;
      request.token = Session.mySession.get('user').token;
      this.pageLoaded = false; // Duplicate object and change its active state..

      if (active === 'yes' || active === 'no') { request.active = active; }
      else { request.active = tradingName.active; }

      this.branchService.amendTradingName(request).then((output: any) => {
        if (output.status === 'OK') {
          this.sendMessageToDialog('Trading name has been updated - please refresh the page', '', '', '');
          this.getTradingNames(false); // Reload contacts..
        } else {
          this.sendMessageToDialog('', output.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0308S)', error, request);
      });
    }
  }

  getFellohConfigs(openDialog: boolean): void {
    const request = { company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation,
      tradeCode: this.selectedTradeCode, token: Session.mySession.get('user').token };
    
    this.pageLoaded = true;
    this.branchService.getFellohConfig(request).then((res: any) => {
      if (res.status === 'OK') {
        this.fellohConfData.data = res.data;
        this.setFellohCurrencies();
        if (openDialog) { this.dialog.open(this.fellohConfigBox, { panelClass: 'tradingNameBox', autoFocus: false }); }        
        this.pageLoaded = true;
      } else {
        this.sendMessageToDialog('', res.status, '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0309S)', error, '');
    });
  }

  addFellohConfig(): void {
    if (!this.newFellohConfig.accountName || !this.newFellohConfig.accountCode) {
      this.sendMessageToDialog('', 'Account name and code are mandatory', '', '');
    } else {
      const request: any = Object.assign({}, this.newFellohConfig);
      request.company = Session.mySession.getUser().company;
      request.operation = Session.mySession.getUser().operation;
      request.tradeCode = this.selectedTradeCode;
      request.token = Session.mySession.get('user').token;

      this.pageLoaded = false; // Duplicate object and use it to create new account
      this.branchService.addFellohConfig(request).then((output: any) => {
        if (output.status === 'OK') {
          // Reset new account fields and reload account list
          this.resetNewFellohConfig(); this.getFellohConfigs(false);
        } else {
          this.sendMessageToDialog('', output.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0310S)', error, request);
      });
    }
  }

  amendFellohConfig(fellohConfig: any): void {
    if (!fellohConfig.accountName || !fellohConfig.accountCode) {
      this.sendMessageToDialog('', 'Account name and code are mandatory', '', '');
    } else {
      const request: any = Object.assign({}, fellohConfig);
      request.company = Session.mySession.getUser().company;
      request.operation = Session.mySession.getUser().operation;
      request.tradeCode = this.selectedTradeCode;
      request.token = Session.mySession.get('user').token;
      request.currencies = request.selectedCurrencies.join(';')
      this.pageLoaded = false; // Duplicate object and change its active state..

      this.branchService.amendFellohConfig(request).then((output: any) => {
        if (output.status === 'OK') {
          this.sendMessageToDialog('Felloh account has been updated', '', '', '');
          this.getFellohConfigs(false); // Reload contacts..
        } else {
          this.sendMessageToDialog('', output.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0311S)', error, request);
      });
    }
  }

  resetNewTradingName(): void {
    this.newTradingName = { tradingName: '', phoneNumber: '', emailAddress: '', website: '' };
  }

  resetNewFellohConfig(): void {
    this.newFellohConfig = { accountName: '', accountCode: '', surcharging: 'no', bankingPull: 'no', currencies: 'GBP' };
  }
}
