<div *ngIf="!pageLoaded" class="loading-indicator">
  <mat-spinner style="margin:0 auto;"></mat-spinner>
</div>

<mat-card-content>
  <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
    <div [@inAnimation] *ngIf="bookingAccess && bookingExists && innerWidth > 1179" class="buttonBar">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" style="vertical-align: middle; display: inline-block; margin: auto;" (click)="tabChanged('graphsView', 'main')" mat-button [disabled]="graphsView">
          <svg-icon src="assets/icons/graph.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)','margin-left.px':'-5'}"></svg-icon> Graphs
        </button>

        <button class="navButtons" style="vertical-align: middle; display: inline-block; margin: auto;" (click)="tabChanged('generalView', 'main')" mat-button [disabled]="generalView">
          <svg-icon src="assets/icons/report.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)','margin-left.px':'-5'}"></svg-icon> Summary
        </button>

        <button class="navButtons" style="vertical-align: middle; display: inline-block; margin: auto;" (click)="tabChanged('passengerView', 'main')" mat-button [disabled]="passengerView">
          <svg-icon *ngIf="passengersData.data.length > 0" src="assets/icons/group.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)','margin-left.px':'-5'}"></svg-icon>
          <svg-icon *ngIf="passengersData.data.length == 0" src="assets/icons/group.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)','margin-left.px':'-5','opacity':0.3}">
          </svg-icon> Passengers
        </button>

        <button class="navButtons" style="vertical-align: middle; display: inline-block; margin: auto;" (click)="tabChanged('suppliersView', 'main')" mat-button [disabled]="suppliersView">
          <svg-icon src="assets/icons/clipboard.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)','margin-left.px':'-5'}"></svg-icon> Costs
        </button>

        <!--<button class="navButtons" style="vertical-align: middle; display: inline-block; margin: auto;" (click)="tabChanged('insuranceView', 'main')" mat-button [disabled]="insuranceView">
          <svg-icon src="assets/icons/insurance.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)','margin-left.px':'-5'}"></svg-icon> Insurance
        </button>-->

        <button class="navButtons" style="vertical-align: middle; display: inline-block; margin: auto;" (click)="tabChanged('receiptsView', 'main')" mat-button [disabled]="receiptsView">
          <svg-icon *ngIf="(customerBalanceDue > 0 || customerBalanceDue < 0) && bookingData.totalReceiptedCharged != 0" src="assets/icons/piggy-bank.svg"
            [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)','margin-left.px':'-5','opacity':0.6}"></svg-icon>
          <svg-icon *ngIf="customerBalanceDue == 0 && bookingData.totalReceiptedCharged != 0" src="assets/icons/piggy-bank.svg"
            [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)','margin-left.px':'-5'}"></svg-icon>
          <svg-icon *ngIf="bookingData.totalReceiptedCharged == 0" src="assets/icons/piggy-bank.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)','margin-left.px':'-5','opacity':0.3}">
          </svg-icon>
          Receipts
        </button>

        <button class="navButtons" style="vertical-align: middle; display: inline-block; margin: auto;" (click)="tabChanged('paymentsView', 'main')" mat-button [disabled]="paymentsView">
          <svg-icon *ngIf="(dueToSuppliers > 0 || dueToSuppliers < 0) && bookingData.totalSuppPayments != 0" src="assets/icons/pay.svg"
            [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)','margin-left.px':'-5','opacity':0.6}"></svg-icon>
          <svg-icon *ngIf="dueToSuppliers == 0 && bookingData.totalSuppPayments != 0" src="assets/icons/pay.svg"
            [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)','margin-left.px':'-5'}"></svg-icon>
          <svg-icon *ngIf="bookingData.totalSuppPayments == 0" src="assets/icons/pay.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)','margin-left.px':'-5','opacity':0.3}">
          </svg-icon>
          Payments
        </button>

        <button class="navButtons" style="vertical-align: middle; display: inline-block; margin: auto;" (click)="tabChanged('documentationView', 'main')" mat-button [disabled]="documentationView">
          <svg-icon src="assets/icons/file.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)','margin-left.px':'-5'}"></svg-icon> Docs.
        </button>

        <button class="navButtons" style="vertical-align: middle; display: inline-block; margin: auto;" (click)="tabChanged('notesView', 'main')" mat-button [disabled]="notesView">
          <svg-icon src="assets/icons/note.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)','margin-left.px':'-5'}"></svg-icon> Notes
        </button>

        <button class="navButtons" style="vertical-align: middle; display: inline-block; margin: auto;" (click)="tabChanged('historyView', 'main')" mat-button [disabled]="historyView">
          <svg-icon src="assets/icons/clock.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)','margin-left.px':'-5'}"></svg-icon> History
        </button>
      </div>
    </div>

    <div [@inAnimation] *ngIf="bookingAccess && bookingExists && innerWidth < 1180" class="buttonBar">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" style="vertical-align: middle; display: inline-block;" (click)="tabChanged('graphsView', 'main')" mat-button [disabled]="graphsView">
          <svg-icon src="assets/icons/graph.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)'}"></svg-icon>
        </button>

        <button class="navButtons" style="vertical-align: middle; display: inline-block;" (click)="tabChanged('generalView', 'main')" mat-button [disabled]="generalView">
          <svg-icon src="assets/icons/report.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)'}"></svg-icon>
        </button>

        <button class="navButtons" style="vertical-align: middle; display: inline-block;" (click)="tabChanged('passengerView', 'main')" mat-button [disabled]="passengerView">
          <svg-icon *ngIf="passengersData.data.length > 0" src="assets/icons/group.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)'}"></svg-icon>
          <svg-icon *ngIf="passengersData.data.length == 0" src="assets/icons/group.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)','opacity':0.3}"></svg-icon>
        </button>

        <button class="navButtons" style="vertical-align: middle; display: inline-block;" (click)="tabChanged('suppliersView', 'main')" mat-button [disabled]="suppliersView">
          <svg-icon src="assets/icons/clipboard.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)'}"></svg-icon>
        </button>

        <!--<button class="navButtons" style="vertical-align: middle; display: inline-block;" (click)="tabChanged('insuranceView', 'main')" mat-button [disabled]="insuranceView">
          <svg-icon src="assets/icons/insurance.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)'}"></svg-icon>
        </button>-->

        <button class="navButtons" style="vertical-align: middle; display: inline-block;" (click)="tabChanged('receiptsView', 'main')" mat-button [disabled]="receiptsView">
          <svg-icon *ngIf="(customerBalanceDue > 0 || customerBalanceDue < 0) && bookingData.totalReceiptedCharged != 0" src="assets/icons/piggy-bank.svg"
            [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)','opacity':0.3}"></svg-icon>
          <svg-icon *ngIf="customerBalanceDue == 0 && bookingData.totalReceiptedCharged != 0" src="assets/icons/piggy-bank.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)'}"></svg-icon>
          <svg-icon *ngIf="bookingData.totalReceiptedCharged == 0" src="assets/icons/piggy-bank.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)'}"></svg-icon>
        </button>

        <button class="navButtons" style="vertical-align: middle; display: inline-block;" (click)="tabChanged('paymentsView', 'main')" mat-button [disabled]="paymentsView">
          <svg-icon *ngIf="(dueToSuppliers > 0 || dueToSuppliers < 0) && bookingData.totalSuppPayments != 0" src="assets/icons/pay.svg"
            [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)','opacity':0.3}"></svg-icon>
          <svg-icon *ngIf="dueToSuppliers == 0 && bookingData.totalSuppPayments != 0" src="assets/icons/pay.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)'}">
          </svg-icon>
          <svg-icon *ngIf="bookingData.totalSuppPayments == 0" src="assets/icons/pay.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)'}"></svg-icon>
        </button>

        <button class="navButtons" style="vertical-align: middle; display: inline-block;" (click)="tabChanged('documentationView', 'main')" mat-button [disabled]="documentationView">
          <svg-icon src="assets/icons/file.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)'}"></svg-icon>
        </button>

        <button class="navButtons" style="vertical-align: middle; display: inline-block;" (click)="tabChanged('notesView', 'main')" mat-button [disabled]="notesView">
          <svg-icon src="assets/icons/note.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)'}"></svg-icon>
        </button>

        <button class="navButtons" style="vertical-align: middle; display: inline-block;" (click)="tabChanged('historyView', 'main')" mat-button [disabled]="historyView">
          <svg-icon src="assets/icons/clock.svg" [svgStyle]="{'width.px':24,'height.px':24,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)'}"></svg-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #drawer mode="side" closed></mat-sidenav>

    <mat-sidenav-content>
      <mat-card [style.overflow]="'auto'">
        <div style="width: 100%; height: 100%;">
          <div *ngIf="graphsView && bookingData?.bookingStatus && !bookingData?.status">
            <div>
              <div style="float: left;">
                <svg-icon src="assets/icons/calendar.svg" [svgStyle]="{'width.px':30,'height.px':30,'fill':'#4D5FD1','padding.px':1,'margin.px':1,'margin-top.px':5}" matTooltipPosition="after" matTooltip="Booking Date"
                  matTooltipClass="line-normal-tooltip"></svg-icon>
              </div>
              <mat-progress-bar mode="determinate" color="primary" value="{{progressBarValue}}" style="width: calc(100% - 80px); margin: auto; margin-top: 20px; float: left;" matTooltip="Days to Departure Date: {{daysToDeptDate}}"
                matTooltipClass="line-normal-tooltip"></mat-progress-bar>
              <div>
                <svg-icon src="assets/icons/plane-taking-off.svg" [svgStyle]="{'width.px':30,'height.px':30,'fill':'#4D5FD1','padding.px':1,'margin.px':1,'margin-top.px':5}" matTooltipPosition="before" matTooltip="Departure Date"
                  matTooltipClass="line-normal-tooltip"></svg-icon>
              </div>
            </div>
            <div style="clear: both;" fxLayout="row" fxLayoutAlign="space-between stretch">
              <div fxFlex="5 1 0">
                <!-- Chart 1 bars -->
                <div id="chart">
                  <h1 style="text-align: center;">
                    <mat-checkbox style="font-size: 14px; float: left;" [(ngModel)]="customerView" color="accent" (change)="customerFacingChange($event)">Customer View</mat-checkbox>
                    <span style="align-items: center; margin-left: -91.75px" id="travelTimeline">Travel Timeline</span>
                    <span style="float: right; font-size: 18px; color: #4D5FD1; display: flex; align-items: center; text-transform: lowercase;">
                      <span matTooltipPosition="below" matTooltip="{{passengersDataText}}" matTooltipClass="line-broken-tooltip">
                        <mat-icon>people</mat-icon>x{{ bookingData.paxNo }}
                      </span>
                      
                      <span style="color: grey; margin-left: 10px;" *ngIf="nonActivePaxNo > 0"
                        matTooltipPosition="below" matTooltip="Cancelled passengers" matTooltipClass="line-broken-tooltip">
                        <mat-icon>person_off</mat-icon>x{{ nonActivePaxNo }}</span>
                    </span>
                  </h1>
                  <apx-chart id="chart" [series]="chartOptions.series" [chart]="chartOptions.chart" [grid]="chartOptions.grid" [plotOptions]="chartOptions.plotOptions" [tooltip]="chartOptions.tooltip" [xaxis]="chartOptions.xaxis"
                    [yaxis]="chartOptions.yaxis" [legend]="chartOptions.legend" [colors]="chartOptions.colors" class="apexChartZoom">
                  </apx-chart>
                  <div style="text-align: center" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                    <button class="buttonClassWider" type="button" mat-flat-button color="primary" (click)="addElement('new')">Add Supplier</button>
                  </div>
                </div>
                <!-- Expanded information -->
              </div>
              <div *ngIf="innerWidth > 1023" fxLayout="column" fxLayoutAlign="space-evenly center">
                <!-- Chart 2 receipts -->
                <div id="pieChartReceipt">
                  <h1 style="text-align: center;">Customer Payments</h1>
                  <apx-chart [series]="pieChartOptions1.series" [chart]="pieChartOptions1.chart" [labels]="pieChartOptions1.labels" [colors]="pieChartOptions1.colors" [stroke]="pieChartOptions1.stroke" [yaxis]="pieChartOptions1.yaxis"
                    [plotOptions]="pieChartOptions1.plotOptions" [legend]="pieChartOptions1.legend" [dataLabels]="pieChartOptions1.dataLabels" class="apexChartZoom"></apx-chart>
                </div>
                <!-- Chart 3 payments -->
                <div id="pieChartPayment">
                  <h1 style="text-align: center;" *ngIf="customerView">Customer Access</h1>
                  <a [href]="qrVar.value" *ngIf="customerView" target="_blank">
                    <ngx-qrcode *ngIf="customerView" [width]="296" [colorLight]="'#F9F9F9'" [elementType]="qrVar.type" [errorCorrectionLevel]="qrVar.corrLevel" [value]="qrVar.value"></ngx-qrcode>
                  </a>
                  
                  <h1 style="text-align: center;" *ngIf="!customerView">Supplier Payments</h1>
                  <apx-chart *ngIf="!customerView" [series]="pieChartOptions2.series" [chart]="pieChartOptions2.chart" [labels]="pieChartOptions2.labels" [colors]="pieChartOptions2.colors" [stroke]="pieChartOptions2.stroke"
                    [yaxis]="pieChartOptions2.yaxis" [plotOptions]="pieChartOptions2.plotOptions" [legend]="pieChartOptions2.legend" [dataLabels]="pieChartOptions2.dataLabels" class="apexChartZoom"></apx-chart>
                </div>
              </div>
            </div>
          </div>

          <div [@inAnimation] *ngIf="generalView && bookingData?.bookingStatus && !bookingData?.status">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div fxLayout="row" fxLayoutAlign="start center">
                <h1>
                  <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Summary
                </h1>
                <button style="margin-left: 25px;" mat-stroked-button color="primary" (click)="reloadBooking('generalView')" matTooltip="Reload Booking" matTooltipClass="line-normal-tooltip">
                  <mat-icon>sync</mat-icon>
                </button>
                <button style="margin-left: 25px;" mat-stroked-button color="primary" (click)="autoCalcBookingDates()" matTooltip="Recalculate Booking Values" matTooltipClass="line-normal-tooltip">
                  <mat-icon>tune</mat-icon>
                </button>
                <div *ngIf="['memberStaff', 'memberManager', 'sinGSAdmin'].includes(userType)">
                  <button mat-stroked-button color="accent" (click)="editToggleOnOff()" style="margin: 0px; margin-left: 25px; display: inline;" id="editModeBtn">
                    <mat-icon *ngIf="editToggle">toggle_on</mat-icon>
                    <mat-icon style="color: black;" *ngIf="!editToggle">toggle_off</mat-icon>
                  </button>
                  <b style="margin-left: 12.5px;">Edit mode</b>
                </div>
              </div>
              <div *ngIf="['memberStaff', 'memberManager'].includes(userType) && ['T0001', 'S1234'].includes(bookingData.tradeCode)">
                <button style="margin-left: 25px;" mat-stroked-button color="accent" (click)="duplicateBooking()" matTooltip="Clone Booking" matTooltipClass="line-normal-tooltip" id="cloneButton">
                  <mat-icon>content_copy</mat-icon>
                </button>
              </div>
            </div>

            <mat-divider></mat-divider>

            <div class="generalInformationTab">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="generalInformation" (click)="generalInformation = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!generalInformation" (click)="generalInformation = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  General Information
                </div>
              </div>

              <div [@customExpansionDetails]="generalInformation == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                <div class="generalInfoRow">
                  <div fxFlex="40" style="height: 40px;">
                    <div fxFlex="50" style="margin: auto;" matTooltip="Reference this booking with a reference from another system"><b>Your Reference</b></div>
                    <div fxFlex="50" id="externalReference" style="margin: auto; display: flex;justify-content: space-between;flex-direction: row;">
                      <div style="margin-top: auto; margin-bottom: auto;" *ngIf="!editToggle">
                        <span *ngIf="bookingData.extBookingSource != 'none'">{{bookingData.extBookingSource}}</span>
                      </div>
                      <div style="margin-top: auto; margin-bottom: auto; width: 100%;" *ngIf="editToggle">
                        <div style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
                          <div style="width: calc(100% - 74px);">
                            <mat-form-field style="max-width: unset; width: 100%; margin-top: -5.5px;">
                              <input matInput ngModel name="extBookingSource" [(ngModel)]="bookingData.extBookingSource" maxlength="25" class="customInput">
                            </mat-form-field>
                          </div>
                          <div>
                            <button class="matIcon" type="button" mat-button color="accent" style="margin: auto; margin-right: 10px;" (click)="editBookingExtRef()" id="editRefBtn">
                              <mat-icon>done</mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div fxFlex="60" style="height: 40px;">
                    <div fxFlex="37.5" style="margin: auto;" matTooltip="You can link two or more bookings by using the same group name"><b>Booking Group Name</b></div>
                    <div fxFlex="62.5" id="bookingGroup" style="margin: auto; display: flex;justify-content: space-between;flex-direction: row;">
                      <div style="margin-top: auto; margin-bottom: auto;" *ngIf="!editToggle">
                        <a (click)="showGroupBookings()">{{bookingData.bookingGroup}}</a>
                      </div>
                      <div style="margin-top: auto; margin-bottom: auto; width: 100%;" *ngIf="editToggle">
                        <div style="width: 100%; display: flex; align-items: center;">
                          <div style="width: 100%;">
                            <mat-form-field style="max-width: unset; width: 100%; margin-top: -5.5px;">
                              <input matInput ngModel name="bookingGroup" [(ngModel)]="bookingData.bookingGroup" maxlength="100" class="customInput">
                            </mat-form-field>
                          </div>
                          <div>
                            <button class="matIcon" type="button" mat-button color="accent" style="margin: auto; margin-right: 10px;" (click)="editBookingGroup()" id="editGroupBtn">
                              <mat-icon>done</mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="generalInfoRow">
                  <div fxFlex="40" style="height: 40px;">
                    <div fxFlex="50" style="margin: auto;"><b>Booking Reference</b></div>
                    <div fxFlex="50" id="bookingReference" style="margin: auto; display: flex;justify-content: space-between;flex-direction: row;">
                      <div style="margin-top: auto; margin-bottom: auto;">{{bookingData.bookingReference | uppercase}}</div>
                      <div><button *ngIf="publicAccess.wpPassword != ''" class="matIcon" type="button" mat-button color="accent" style="margin: auto; margin-right: 10px;"
                        [cdkCopyToClipboard]="publicAccess.wpPassword" matTooltip="Copy WordPress Password" matTooltipClass="line-normal-tooltip">
                        <mat-icon>key</mat-icon>
                      </button></div>
                    </div>
                  </div>
                  <div fxFlex="60" style="height: 40px;">
                    <div fxFlex="37.5" style="margin: auto;"><b>Contact Name</b></div>
                    <div fxFlex="62.5" id="leadName" style="margin: auto;">{{bookingData.leadName}}</div>
                  </div>
                </div>
                <div class="generalInfoRow">
                  <div fxFlex="40" style="height: 40px;">
                    <div fxFlex="50" style="margin: auto;"><b matTooltip="You can only edit that date up to 10 days after the booking status is set to 'Booked'" matTooltipClass="line-normal-tooltip">Booking Date</b></div>
                    <div fxFlex="50" id="bookingDate" style="margin: auto;">
                      <span class="editSpans">{{bookingData.bookingDate | date:'dd.MM.yyyy'}}</span>
                      <span *ngIf="!bookingDatesDisable && editToggle && !bookingDateDisable">
                        <input [matDatepicker]="bookingDate" style="width: 1px; height: 1px; visibility: hidden;" (dateChange)="editDate($event, 'Booking Date')" />
                        <mat-datepicker-toggle matSuffix [for]="bookingDate"></mat-datepicker-toggle>
                        <mat-datepicker #bookingDate [startAt]="bookingData.bookingDate"></mat-datepicker>
                      </span>
                    </div>
                  </div>
                  <div fxFlex="60">
                    <div fxFlex="37.5" style="margin: auto;"><b>Travel Agent</b></div>
                    <div fxFlex="62.5" id="agentEmail" style="margin: auto;">
                      <span *ngIf="!editToggle" id="travelAgent">{{bookingData.agentEmail}}</span>
                      <span *ngIf="editToggle">
                        <mat-select style="width: calc(100% - 15px)" ngModel name="agentEmail" [(ngModel)]="bookingData.agentEmail" #agentEmail="ngModel" (selectionChange)="editBookingSelects('Travel agent')">
                          <mat-option *ngFor="let user of userList" [value]="user.email">
                            {{user.fullName}}
                          </mat-option>
                        </mat-select>
                      </span> 
                    </div>
                  </div>
                </div>
                <div class="generalInfoRow">
                  <div fxFlex="40" style="height: 40px;">
                    <div fxFlex="50" style="margin: auto;"><b>Departure Date</b></div>
                    <div fxFlex="50" id="deptDate" style="margin: auto;">
                      <span class="editSpans">{{bookingData.deptDate | date:'dd.MM.yyyy'}}</span>
                      <span *ngIf="!bookingDatesDisable && editToggle">
                        <input [matDatepicker]="deptDate" style="width: 1px; height: 1px; visibility: hidden;" (dateChange)="editDate($event, 'Departure Date')" />
                        <mat-datepicker-toggle matSuffix [for]="deptDate"></mat-datepicker-toggle>
                        <mat-datepicker #deptDate [startAt]="bookingData.deptDate"></mat-datepicker>
                      </span>
                    </div>
                  </div>
                  <div fxFlex="60" style="height: 40px;">
                    <div fxFlex="37.5" style="margin: auto;"><b>Booking Status</b></div>
                    <div fxFlex="62.5" id="bookingStatus" style="margin: auto;">
                      <mat-radio-group color="primary" (change)="changeBookingStatus($event)" *ngIf="editToggle">
                        <mat-radio-button *ngIf="bookingData.bookingStatus == 'enquiry'" [checked]="true" style="margin-right: 15px;" value="enquiry">
                          <span style="color: #ce983a;">Enquiry</span>
                        </mat-radio-button>

                        <mat-radio-button *ngIf="!bookingDatesDisable" [checked]="bookingData.bookingStatus == 'booking'" style="margin-right: 15px;" value="booking">
                          <span *ngIf="bookingData.bookingStatus == 'booking'" style="color: #4D5FD1;">Booked</span>
                          <span *ngIf="bookingData.bookingStatus != 'booking'">Booked</span>
                        </mat-radio-button>

                        <mat-radio-button [checked]="bookingData.bookingStatus == 'cancelled'" style="margin-right: 15px;" value="cancelled">
                          <span *ngIf="bookingData.bookingStatus == 'cancelled'" style="color: #a6a6a6;">Cancelled</span>
                          <span *ngIf="bookingData.bookingStatus != 'cancelled'">Cancelled</span>
                        </mat-radio-button>
                      </mat-radio-group>

                      <span class="editSpans" *ngIf="!editToggle">{{bookingData.bookingStatus | titlecase }}</span>
                    </div>
                  </div>
                </div>
                <div class="generalInfoRow">
                  <div fxFlex="40" style="height: 40px;">
                    <div fxFlex="50" style="margin: auto;"><b matTooltip="Supplier Due Date - 7 days" matTooltipClass="line-normal-tooltip">Customer Due Date</b></div>
                    <div fxFlex="50" id="balanceDueDate" style="margin: auto;">
                      <span class="editSpans">{{bookingData.balanceDueDate | date:'dd.MM.yyyy'}}</span>
                      <span *ngIf="!bookingDatesDisable && editToggle"><input [matDatepicker]="balanceDueDate" style="width: 1px; height: 1px; visibility: hidden;" (dateChange)="editDate($event, 'Customer Due Date')" />
                        <mat-datepicker-toggle matSuffix [for]="balanceDueDate"></mat-datepicker-toggle>
                        <mat-datepicker #balanceDueDate [startAt]="bookingData.balanceDueDate"></mat-datepicker>
                      </span>
                    </div>
                  </div>
                  <div fxFlex="60" style="height: 40px;">
                    <div fxFlex="37.5" style="margin: auto;"><b>Branch Name</b></div>
                    <div fxFlex="62.5" id="branchName" style="margin: auto;">
                      <span *ngIf="!bookingData.branchName && !editToggle">
                        <mat-icon style="color: #ce983a; margin-left: -10px;" matTooltipClass="line-normal-tooltip" matTooltip="Please choose the trading name for this booking">priority_high</mat-icon>
                      </span>
                      <span *ngIf="bookingData.branchName && (!editToggle || selectedBranch?.tradingNames?.length <= 1)">{{bookingData.branchName}}</span>
                      <span *ngIf="editToggle && selectedBranch?.tradingNames?.length > 1">
                        <mat-select style="width: calc(100% - 15px)" ngModel name="tradingNameId" [(ngModel)]="bookingData.tradingNameId" #tradingNameId="ngModel" (selectionChange)="editBookingSelects('Branch name')">
                          <mat-option *ngFor="let tradingName of selectedBranch?.tradingNames" [value]="tradingName.id">
                            {{tradingName.tradingName}}
                          </mat-option>
                        </mat-select>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="generalInfoRow">
                  <div fxFlex="40" style="height: 40px;">
                    <div fxFlex="50" style="margin: auto;"><b matTooltip="Departure - Supplier::PaymentDueDays" matTooltipClass="line-normal-tooltip">Supplier Due Date</b></div>
                    <div fxFlex="50" id="dueDate" style="margin: auto;">
                      <span class="editSpans">{{bookingData.dueDate | date:'dd.MM.yyyy'}}</span>
                      <span *ngIf="!bookingDatesDisable && editToggle"><input [matDatepicker]="dueDate" style="width: 1px; height: 1px; visibility: hidden;" (dateChange)="editDate($event, 'Supplier Due Date')" />
                        <mat-datepicker-toggle matSuffix [for]="dueDate"></mat-datepicker-toggle>
                        <mat-datepicker #dueDate [startAt]="bookingData.dueDate"></mat-datepicker>
                      </span>
                    </div>
                  </div>
                  <div fxFlex="60" style="height: 40px;">
                    <div fxFlex="37.5" style="margin: auto;"><b>Trade Code</b></div>
                    <div fxFlex="62.5" id="tradeCode" style="margin: auto;">{{bookingData.tradeCode}}</div>
                  </div>
                </div>
                <div class="generalInfoRow">
                  <div fxFlex="40" style="height: 40px;">
                    <div fxFlex="50" style="margin: auto;"><b>Return Date</b></div>
                    <div fxFlex="50" id="returnDate" style="margin: auto;">
                      <span class="editSpans">{{bookingData.returnDate | date:'dd.MM.yyyy'}}</span>
                      <span *ngIf="!bookingDatesDisable && editToggle"><input [matDatepicker]="returnDate" style="width: 1px; height: 1px; visibility: hidden;" (dateChange)="editDate($event, 'Return Date')" />
                        <mat-datepicker-toggle matSuffix [for]="returnDate"></mat-datepicker-toggle>
                        <mat-datepicker #returnDate [startAt]="bookingData.returnDate"></mat-datepicker>
                      </span>
                    </div>
                  </div>
                  <div fxFlex="60" style="height: 40px;">
                    <div fxFlex="37.5" style="margin: auto;"><b>Number of Passengers</b></div>
                    <div fxFlex="62.5" id="bookingPaxNo" style="margin: auto;">{{ bookingData.paxNo }}</div>
                  </div>
                </div>
                <div class="generalInfoRow">
                  <div fxFlex="40" style="height: 40px;">
                    <div fxFlex="50" style="margin: auto;"><b matTooltip="Booking Date + 7 days" matTooltipClass="line-normal-tooltip">Deposit Due Date</b></div>
                    <div fxFlex="50" id="depositDueDate" style="margin: auto;">
                      <span class="editSpans">{{bookingData.depositDueDate | date:'dd.MM.yyyy'}}</span>
                      <span *ngIf="!bookingDatesDisable && editToggle"><input [matDatepicker]="depositDueDate" style="width: 1px; height: 1px; visibility: hidden;" (dateChange)="editDate($event, 'Deposit Due Date')" />
                        <mat-datepicker-toggle matSuffix [for]="depositDueDate"></mat-datepicker-toggle>
                        <mat-datepicker #depositDueDate [startAt]="bookingData.depositDueDate"></mat-datepicker>
                      </span>
                    </div>
                  </div>
                  <div fxFlex="60" style="height: 40px;">
                    <div fxFlex="37.5" style="margin: auto;"><b>Lead Supplier</b></div>
                    <div fxFlex="62.5" id="externalNames" style="margin: auto;">
                      <span *ngIf="!editToggle" id="leadSupplier">{{ bookingData.leadSupplier }}</span>
                      <span *ngIf="editToggle">
                        <mat-select style="width: calc(100% - 15px)" ngModel name="leadSupplier" [(ngModel)]="bookingData.leadSupplier" #leadSupplier="ngModel" (selectionChange)="editBookingSelects('Lead supplier')">
                          <mat-option *ngFor="let costing of costingsData.data" [value]="costing.supplierName">
                            {{costing.supplierName}}
                          </mat-option>
                        </mat-select>
                      </span>                    
                    </div>
                  </div>
                </div>
                <div class="generalInfoRow">
                  <div fxFlex="40" style="min-height: 40px;">
                    <div fxFlex="50" style="margin: auto;"><b>Country of Destination</b></div>
                    <div fxFlex="50" style="margin: auto;">
                      <span *ngIf="!editToggle" id="leadSupplier">{{ bookingData.destCountry }}</span>
                      <span *ngIf="editToggle">
                        <mat-select style="width: calc(100% - 15px)" ngModel name="destCountry" [(ngModel)]="bookingData.destCountry" #destCountry="ngModel" (selectionChange)="editBookingSelects('Country of destination')">
                          <mat-form-field appearance="fill" class="filterSelectList">
                            <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                          </mat-form-field>

                          <mat-option *ngFor="let country of countryFiltered" [value]="country" (click)="filterString = ''; filterSelect();">
                            {{country}}
                          </mat-option>
                        </mat-select>
                      </span>
                    </div>
                  </div>
                  <div fxFlex="60" style="height: 40px;">
                    <div fxFlex="37.5" style="margin: auto;"><b matTooltip="Send to the customer for Mobile Info Access" matTooltipClass="line-normal-tooltip">External Booking Link</b></div>
                    <div fxFlex="62.5" id="externalNames" style="margin: auto;">
                      <button *ngIf="publicAccess.request != ''" class="matIcon" type="button" mat-stroked-button color="accent" style="margin-right: 10px;"
                        [cdkCopyToClipboard]="singsCustomerURL + 'booking/' + publicAccess.request + '/' + publicAccess.key" matTooltip="Copy URL" matTooltipClass="line-normal-tooltip">
                        <mat-icon>content_copy</mat-icon>
                      </button>
                      <a *ngIf="publicAccess.request != ''" href="{{singsCustomerURL}}booking/{{publicAccess.request}}/{{publicAccess.key}}" target="_blank"> <button class="matIcon" type="button" mat-stroked-button color="accent"
                          style="margin-right: 10px;" matTooltip="Open Booking" matTooltipClass="line-normal-tooltip">
                          <mat-icon>open_in_new</mat-icon>
                        </button></a>
                      <button *ngIf="(userType == 'memberManager' || userType == 'memberStaff') && publicAccess.request != ''" class="matIcon" mat-stroked-button color="accent" (click)="regeneratePublicKey()" matTooltip="Regenerate Link"
                        matTooltipClass="line-normal-tooltip">
                        <mat-icon>refresh</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div [@customExpansionDetails]="generalInformation == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="generalInformation = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>

            <div class="financialDetailsTab">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft" style="width: 100%;">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="financialDetails" (click)="financialDetails = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!financialDetails" (click)="financialDetails = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  Financial Details
                </div>
              </div>

              

              <div [@customExpansionDetails]="financialDetails == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                <div class="generalInfoRow">
                  <div fxFlex="50" style="height: 40px;">
                    <div fxFlex="45" style="margin: auto;"><b>Total Gross</b></div>
                    <div fxFlex="55" id="sellingPrice" style="margin: auto;">{{bookingData.custPrice | currency:'GBP'}}</div>
                  </div>
                  <div fxFlex="50" style="height: 40px;">
                    <div fxFlex="50" style="margin: auto;"><b>Customer Due</b></div>
                    <div fxFlex="50" id="customerBalanceDue" style="margin: auto;">{{customerBalanceDue | currency:'GBP'}}</div>
                  </div>
                </div>
                <div class="generalInfoRow">
                  <div fxFlex="50" style="height: 40px;">
                    <div fxFlex="45" style="margin: auto;"><b>Total Net</b></div>
                    <div fxFlex="55" id="netPrice" style="margin: auto;">{{bookingData.net | currency:'GBP'}}</div>
                  </div>
                  <div fxFlex="50" style="height: 40px;">
                    <div fxFlex="50" style="margin: auto;"><b>Paid by Customers</b></div>
                    <div fxFlex="50" id="totalReceipted" style="margin: auto;">{{bookingData.totalReceiptedCharged | currency:'GBP'}}</div>
                  </div>
                </div>
                <div class="generalInfoRow">
                  <div fxFlex="50" style="height: 40px;">
                    <div fxFlex="45" style="margin: auto;"><b>Commission</b></div>
                    <div fxFlex="55" id="commission" style="margin: auto;">{{bookingData.commission | currency:'GBP'}} ({{profitPerc}}%)</div>
                  </div>
                  <div fxFlex="50" style="height: 40px;">
                    <div fxFlex="50" style="margin: auto;"><b>Money Received</b></div>
                    <div fxFlex="50" id="totalReceipted" style="margin: auto;">{{bookingData.totalReceipted | currency:'GBP'}}</div>
                  </div>
                </div>
                <div class="generalInfoRow">
                  <div fxFlex="50" style="height: 40px;">
                    <div fxFlex="45" style="margin: auto;"><b>VAT</b></div>
                    <div fxFlex="55" id="vat" style="margin: auto; text-align: left;">
                      <span *ngIf="editToggle">
                          <mat-form-field style="float: left; width: 70px; margin: auto;">
                            <input matInput ngModel placeholder="Final VAT" required type="number" step="0.01" name="finalTax" #finalTax="ngModel" [(ngModel)]="finalVAT">
                          </mat-form-field>
                          <button class="matIcon" style="width: 30px; transform: scale(0.8);" mat-icon-button color="primary" type="submit" (click)="editMarkupVATdeposit('VAT', false)">
                            <mat-icon>check</mat-icon>
                          </button>
                          <button class="matIcon" style="width: 30px; transform: scale(0.8);" mat-icon-button color="accent" type="submit" (click)="editMarkupVATdeposit('VAT', true)"
                                  *ngIf="bookingData.finalTax != 0">
                            <mat-icon>restore</mat-icon>
                          </button>
                      </span>
                      <span class="editSpans" *ngIf="!editToggle">{{finalVAT | currency:'GBP'}}</span>
                    </div>
                  </div>
                  <div fxFlex="50" style="height: 40px;">
                    <div fxFlex="50" style="margin: auto;"><b>Supplier Due</b></div>
                    <div fxFlex="50" id="dueToSuppliers" style="margin: auto;">{{dueToSuppliers | currency:'GBP'}}</div>
                  </div>
                </div>
                <div class="generalInfoRow">
                  <div fxFlex="50" style="height: 40px;">
                    <div fxFlex="45" style="margin: auto;"><b>Supplements</b></div>
                    <div fxFlex="55" id="supplementSum" style="margin: auto;">{{supplementsTotalSums.grossCost | currency:'GBP'}}</div>
                  </div>
                  <div fxFlex="50" style="height: 40px;">
                    <div fxFlex="50" style="margin: auto;"><b>Paid To Supplier</b></div>
                    <div fxFlex="50" id="paidToSuppliers" style="margin: auto;">{{bookingData.totalSuppPayments | currency:'GBP'}}</div>
                  </div>
                </div>
                <div class="generalInfoRow">
                  <div fxFlex="50" style="height: 40px;">
                    <div fxFlex="45" style="margin: auto;"><b>Total Discounts</b></div>
                    <div fxFlex="55" id="discounts" style="margin: auto;">{{bookingData.discount | currency:'GBP'}}</div>
                  </div>
                  <div fxFlex="50" style="height: 40px;">
                    <div fxFlex="50" style="margin: auto;"><b>Deposit Value</b></div>
                    <div fxFlex="50" id="depositAmount" style="margin: auto;">
                      <span *ngIf="editToggle">
                        <mat-form-field style="float: left; width: 70px; margin: auto;">
                          <input matInput ngModel required placeholder="Final Deposit" type="number" min="0" step="0.01" name="depositValue" #depositValue="ngModel" [(ngModel)]="finalDeposit">
                        </mat-form-field>
                        <button class="matIcon" style="width: 30px; transform: scale(0.8);" mat-icon-button color="primary" type="submit" (click)="editMarkupVATdeposit('Deposit', false)">
                          <mat-icon>check</mat-icon>
                        </button>
                        <button class="matIcon" style="width: 30px; transform: scale(0.8);" mat-icon-button color="accent" type="submit" (click)="editMarkupVATdeposit('Deposit', true)"
                                *ngIf="bookingData.finalDeposit != 0">
                        <mat-icon>restore</mat-icon>
                          </button>
                      </span>
                      <span class="editSpans" id="depositValue" *ngIf="!editToggle">{{finalDeposit | currency:'GBP'}}</span>
                    </div>
                  </div>
                </div>

                <div class="generalInfoRow">
                  <div fxFlex="50" style="height: 40px;">
                    <div fxFlex="45" style="margin: auto;"><b>Mark Up/Down</b></div>
                    <div fxFlex="55" id="markUpDown" style="margin: auto; text-align: left;">
                      <span *ngIf="editToggle">
                        <mat-form-field style="float: left; width: 70px; margin: auto;">
                          <input matInput ngModel placeholder="Markup" required type="number" step="0.01" name="markup" #markup="ngModel" [(ngModel)]="bookingData.markUpDown">
                        </mat-form-field>
                        <button class="matIcon" style="width: 30px; transform: scale(0.8);" mat-icon-button color="primary" type="submit" (click)="editMarkupVATdeposit('Markup', true)">
                          <mat-icon>check</mat-icon>
                        </button>
                      </span>
                      <span class="editSpans" *ngIf="!editToggle">{{bookingData.markUpDown | currency:'GBP'}}</span>
                    </div>
                  </div>
                  <div fxFlex="50" style="height: 40px;">
                    <div fxFlex="50" style="margin: auto;"><b>Deposit Paid</b></div>
                    <div fxFlex="50" id="depositPaid" style="margin: auto; text-align: left">
                      <input type="checkbox" [(ngModel)]="bookingData.depositPaid" class="customCheckbox" style="transform: scale(1.3)" [disabled]="!editToggle" (click)="editMarkupVATdeposit('Deposit Paid', !bookingData.depositPaid)">
                    </div>
                  </div>
                </div>

                <div class="generalInfoRow">
                  <div fxFlex="50" style="height: 40px;">
                    <div fxFlex="45" style="margin: auto;">
                      <b>ATOL Protected</b>
                    </div>
                    <div fxFlex="55" id="atolProtected" style="margin: auto; text-align: left;">
                      <span *ngIf="!editToggle" id="underATOL">
                        {{bookingData.underATOL}}
                        <span *ngIf="atolExists && bookingData.underATOL == 'No' || !atolExists && bookingData.underATOL != 'No'">
                          <mat-icon style="color: #ce983a; margin-left: 10px;" matTooltipClass="line-normal-tooltip"
                          matTooltip="You should ensure that both the ATOL supplement is included in the booking and that the booking is registered as ATOL Protected">
                            priority_high
                          </mat-icon>
                        </span>
                      </span>
                      <span *ngIf="editToggle">
                        <mat-select style="width: calc(100% - 15px)" ngModel name="underATOL" [(ngModel)]="bookingData.underATOL" #underATOL="ngModel" (selectionChange)="editBookingSelects('ATOL coverage')"
                                  matTooltipClass="line-normal-tooltip" matTooltip="Note: Changing 'No' to any other value will create an ATOL supplement in the booking if it doesn't already exist">
                          <mat-option value="No">No</mat-option>
                          <mat-option value="Retail Agent of ATOL" [disabled]="(!meetsATOLcriteria.atolPackage && !meetsATOLcriteria.flightOnly)" matTooltipClass="line-normal-tooltip"
                              [matTooltip]="!meetsATOLcriteria.atolPackage && !meetsATOLcriteria.flightOnly ? 'Your booking does not meet the ATOL criteria' : 'Choose this option if you are acting as a retail agent of an ATOL holder. The ATOL holder will provide ATOL protection to customers, and you will not issue ATOL certificates directly'">
                              Retail Agent of ATOL</mat-option>
                          <mat-option value="T-ATOL Protection" [disabled]="(!meetsATOLcriteria.atolPackage && !meetsATOLcriteria.flightOnly) || selectedBranch.isTAtol == 'no'" matTooltipClass="line-normal-tooltip"
                              [matTooltip]="!meetsATOLcriteria.atolPackage && !meetsATOLcriteria.flightOnly ? 'Your booking does not meet the ATOL criteria' : (selectedBranch.isTAtol == 'no' ? 'You are not registered as T-ATOL holder in the system' : 'Select this option if you are an ATOL holder and will provide ATOL protection to customers under your own ATOL number. You will issue ATOL certificates directly to customers')">
                              T-ATOL Protection</mat-option>
                        </mat-select>
                      </span> 
                    </div>
                  </div>
                </div>
              </div>

              <div [@customExpansionDetails]="financialDetails == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="financialDetails = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>

            <div *ngIf="costingsData.data.length > 0" style="clear: both; width: 100%; margin-bottom: 3.5%;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="elementsDetails" (click)="elementsDetails = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!elementsDetails" (click)="elementsDetails = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  Supplier List
                </div>
              </div>

              <div [@customExpansionDetails]="elementsDetails == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                <mat-table multiTemplateDataRows [dataSource]="costingsData" class="tableWithinExpansV2">
                  <ng-container matColumnDef="supplierName">
                    <mat-header-cell *matHeaderCellDef> Supplier Name </mat-header-cell>
                    <mat-cell *matCellDef="let costing"> {{costing.supplierName }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="ref">
                    <mat-header-cell *matHeaderCellDef> Reference No. </mat-header-cell>
                    <mat-cell *matCellDef="let costing"> <b>{{ costing.supplierReference }}</b> </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="bookingDate">
                    <mat-header-cell *matHeaderCellDef> Booking Date </mat-header-cell>
                    <mat-cell *matCellDef="let costing"> {{ costing.bookingDate | date:'dd.MM.yyyy' }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="deptDate">
                    <mat-header-cell *matHeaderCellDef> Departure Date </mat-header-cell>
                    <mat-cell *matCellDef="let costing"> {{ costing.deptDate | date:'dd.MM.yyyy' }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="returnDate">
                    <mat-header-cell *matHeaderCellDef> Return Date </mat-header-cell>
                    <mat-cell *matCellDef="let costing"> {{ costing.returnDate | date:'dd.MM.yyyy' }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="suppDueDate">
                    <mat-header-cell *matHeaderCellDef> Due Date </mat-header-cell>
                    <mat-cell *matCellDef="let costing">
                      <span *ngIf="!editToggle">{{costing.suppDueDate | date:'dd.MM.yyyy'}}</span>
                      <span *ngIf="editToggle" id="suppDueDate">
                        <form ngNativeValidate #editSuppDueDateForm="ngForm" (ngSubmit)="editSuppsDueDate(costing)">
                          <mat-form-field style="max-width: 105px;">
                            <input ngModel required maxlength="50" matInput name="suppDueDate" [matDatepicker]="suppDueDate" [(ngModel)]="costing.suppDueDate" (dateChange)="valiDate($event)">
                            <mat-datepicker-toggle matSuffix [for]="suppDueDate"></mat-datepicker-toggle>
                            <mat-datepicker #suppDueDate></mat-datepicker>
                          </mat-form-field>

                          <button *ngIf="editToggle" class="matIcon" style="transform: scale(0.8);" mat-icon-button color="primary" type="submit">
                            <mat-icon>check</mat-icon>
                          </button>
                        </form>
                      </span>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="gross">
                    <mat-header-cell *matHeaderCellDef> Gross </mat-header-cell>
                    <mat-cell *matCellDef="let costing"> {{ costing.gross | currency:'GBP' }} </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="elementsColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns : elementsColumns;" class="trueRow"></mat-row>
                </mat-table>
              </div>

              <div [@customExpansionDetails]="elementsDetails == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="elementsDetails = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>
          </div>

          <div [@inAnimation] *ngIf="passengerView && bookingData?.bookingStatus && !bookingData?.status">
            <div fxLayout="row" fxLayoutAlign="start center">
              <h1>
                <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Passengers
              </h1>
              <button style="margin-left: 25px;" mat-stroked-button color="primary" (click)="reloadBooking('passengerView')" matTooltip="Reload Booking" matTooltipClass="line-normal-tooltip">
                <mat-icon>sync</mat-icon>
              </button>
            </div>

            <div class="divider">
              <mat-divider></mat-divider>
            </div>

            <div style="width: 100%; margin-bottom: 20px;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="passengersDetails" (click)="passengersDetails = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!passengersDetails" (click)="passengersDetails = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  Passenger List
                </div>
              </div>

              <div [@customExpansionDetails]="passengersDetails == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                <mat-table class="tableWithinExpansV2" multiTemplateDataRows *ngIf="passengersData.data.length > 0" [dataSource]="passengersData">
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>Full Name</mat-header-cell>
                    <mat-cell *matCellDef="let customer" (click)="expandedElement = expandedElement === customer ? null : customer; setHolidayInterests(customer);">
                        <span>{{ customer.title }} {{ customer.firstName }} {{ customer.middleName }} {{customer.lastName }}</span>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="paxDoc">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let customer">
                      <input type="checkbox" (change)="changeShowOnDocs(customer)" [checked]="customer.showOnDocs == 'yes'" value="{{customer.showOnDocs}}"
                                    style="width: 16px; height: 16px;" [disabled]="customer.active == 'no' || customer.isLead || !['memberStaff', 'memberManager'].includes(userType)"
                                    matTooltip="Show in documents" matTooltipClass="line-normal-tooltip">
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="operationsPax">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let customer">
                      <span *ngIf="customer.isLead" style="width: 65px;"></span>

                      <button style="margin-right: 25px;" matTooltip="Bring passenger back" mat-icon-button color="accent" matTooltipClass="line-normal-tooltip"
                      (click)="changePaxActive(customer, 'yes')" *ngIf="customer.active == 'no' && !customer.isLead"
                      [style.visibility]="!['memberStaff', 'memberManager', 'sinGSAdmin'].includes(userType) ? 'hidden' : 'visible'">
                        <mat-icon>person_add</mat-icon>
                      </button>

                      <span *ngIf="customer.active == 'no' && !customer.isLead" style="width: 65px;"></span>

                      <button style="margin-right: 25px;" matTooltip="Cancel passenger" mat-icon-button color="accent" matTooltipClass="line-normal-tooltip"
                      (click)="changePaxActive(customer, 'no')" *ngIf="customer.active == 'yes' && !customer.isLead"
                      [style.visibility]="!['memberStaff', 'memberManager', 'sinGSAdmin'].includes(userType) ? 'hidden' : 'visible'">
                        <mat-icon>person_remove</mat-icon>
                      </button>

                      <button style="margin-right: 25px;" matTooltip="Search previous companions" mat-icon-button color="primary" matTooltipClass="line-normal-tooltip"
                      (click)="getBookCustCompanions(customer.id)" *ngIf="customer.active == 'yes'">
                        <mat-icon>person_search</mat-icon>
                      </button>
                      
                      <span *ngIf="customer.isLead" matTooltip="Lead passenger" matTooltipClass="line-normal-tooltip">
                        <svg-icon src="assets/icons/viking-helmet.svg" [svgStyle]="{'width.px':40,'height.px':25,'fill':'rgb(77,95,209)'}"></svg-icon>
                      </span>

                      <span *ngIf="customer.active == 'no'" matTooltip="Cancelled passenger" matTooltipClass="line-normal-tooltip" style="text-align: center;">
                        <mat-icon style="width: 40px; color: rgb(209 77 77);">person_off</mat-icon>
                      </span>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="dobPax">
                    <mat-header-cell *matHeaderCellDef>DoB</mat-header-cell>
                    <mat-cell *matCellDef="let customer" (click)="expandedElement = expandedElement === customer ? null : customer; setHolidayInterests(customer);">
                      {{ customer.dob | date: 'dd.MM.yyyy' }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
                    <mat-cell *matCellDef="let customer" (click)="expandedElement = expandedElement === customer ? null : customer; setHolidayInterests(customer);">
                      {{ customer.email }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="receipted">
                    <mat-header-cell *matHeaderCellDef>Paid</mat-header-cell>
                    <mat-cell *matCellDef="let customer" (click)="expandedElement = expandedElement === customer ? null : customer; setHolidayInterests(customer);" style="justify-content: space-between;">
                      <span style="font-weight: 600; color: #005900;">{{ customer.receipted | currency: 'GBP' }}</span>
                      <span *ngIf="bookingData.totalReceiptedCharged != 0">{{ (customer.receipted / bookingData.totalReceiptedCharged) * 100 | number : '1.2-2' }}%</span>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="expandedDetail">
                    <mat-cell class="expandedDetail" *matCellDef="let customer" [attr.colspan]="5">
                      <form ngNativeValidate #editPassengerForm="ngForm" (ngSubmit)="customer.id ? editPassenger(editPassengerForm, customer) : createAndAddPassenger(editPassengerForm, customer.bookCustID)">
                        <h3>Customer Name</h3>
                        <mat-form-field class="passengerExpandField">
                          <mat-select ngModel [(ngModel)]="customer.title" name="title" #title="ngModel" placeholder="Title" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                            <mat-option *ngFor="let title of titles" [value]="title['value']">
                              {{title['value']}}
                            </mat-option>
                          </mat-select>

                          <input matInput ngModel placeholder="Title" *ngIf="userType != 'memberManager' && userType != 'memberStaff'" name="title" #title="ngModel" [(ngModel)]="customer.title" readonly="true">
                        </mat-form-field>

                        <mat-form-field class="passengerExpandField">
                          <input matInput ngModel required placeholder="First Name" maxlength="50" [readonly]="userType != 'memberManager' && userType != 'memberStaff'" name="firstName" #firstName="ngModel" [(ngModel)]="customer.firstName">
                        </mat-form-field>

                        <mat-form-field class="passengerExpandField">
                          <input matInput ngModel placeholder="Middle Name" maxlength="50" [readonly]="userType != 'memberManager' && userType != 'memberStaff'" name="middleName" #middleName="ngModel" [(ngModel)]="customer.middleName">
                        </mat-form-field>

                        <mat-form-field class="passengerExpandField">
                          <input matInput required ngModel placeholder="Last Name" maxlength="50" [readonly]="userType != 'memberManager' && userType != 'memberStaff'" name="lastName" #lastName="ngModel" [(ngModel)]="customer.lastName">
                        </mat-form-field>

                        <h3>Address</h3>
                          <mat-form-field>
                            <input matInput ngModel placeholder="Post Code" maxlength="10" [readonly]="userType != 'memberManager' && userType != 'memberStaff'"
                              (keyup)="postCodeLookup($event)" [matAutocomplete]="auto" name="postcode"
                              #postcode="ngModel" [(ngModel)]="customer.postcode" id="postCode{{customer.id}}">
                            <mat-autocomplete #auto="matAutocomplete" style="margin-top: -25px;" [panelWidth]="'auto'"
                              (optionSelected)='selectPostcode($event.option.value, "existing", customer)'>
                              <mat-option *ngFor="let address of addressList; let i = index" [value]="i">
                                {{ address }}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Address Line 1" maxlength="50" name="addressLine1" [readonly]="userType != 'memberManager' && userType != 'memberStaff'"
                              #addressLine1="ngModel" [(ngModel)]="customer.addressLine1">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Address Line 2" maxlength="50" name="addressLine2" [readonly]="userType != 'memberManager' && userType != 'memberStaff'"
                              #addressLine2="ngModel" [(ngModel)]="customer.addressLine2">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Address Line 3" maxlength="50" name="addressLine3" [readonly]="userType != 'memberManager' && userType != 'memberStaff'"
                              #addressLine3="ngModel" [(ngModel)]="customer.addressLine3">
                          </mat-form-field>

                          <br>

                          <mat-form-field>
                            <input matInput ngModel placeholder="Address Line 4" maxlength="50" name="addressLine4" [readonly]="userType != 'memberManager' && userType != 'memberStaff'"
                              #addressLine4="ngModel" [(ngModel)]="customer.addressLine4">
                          </mat-form-field>

                          <mat-form-field>
                            <input matInput ngModel placeholder="County" maxlength="50" name="county" #county="ngModel" [readonly]="userType != 'memberManager' && userType != 'memberStaff'"
                              [(ngModel)]="customer.county">
                          </mat-form-field>

                          <mat-form-field>
                            <mat-select ngModel [(ngModel)]="customer.country" name="country" #country="ngModel"
                              placeholder="Country" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                              <mat-form-field appearance="fill" class="filterSelectList">
                                <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel"
                                  [(ngModel)]="filterString" (keyup)="filterSelect()">
                              </mat-form-field>
                              <mat-option *ngFor="let country of countryFiltered" [value]="country"
                                (click)="filterString = ''; filterSelect();">
                                {{country}}
                              </mat-option>
                            </mat-select>

                            <input matInput ngModel required placeholder="Country" *ngIf="userType != 'memberManager' && userType != 'memberStaff'" name="country" #country="ngModel" [(ngModel)]="customer.country" readonly="true">
                          </mat-form-field>

                        <h3>DOB & Contact Details</h3>
                        <mat-form-field class="passengerExpandField">
                          <mat-label>Date of Birth</mat-label>
                          <input matInput [(ngModel)]="customer.dob" name="dob" [matDatepicker]="dob" [readonly]="userType != 'memberManager' && userType != 'memberStaff'" (dateChange)="valiDate($event)">
                          <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                          <mat-datepicker #dob></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field class="passengerExpandField">
                          <input matInput ngModel placeholder="Mobile Number" maxlength="15" [readonly]="userType != 'memberManager' && userType != 'memberStaff'" name="telNo" #telNo="ngModel" type="number" [(ngModel)]="customer.telNo">
                        </mat-form-field>

                        <mat-form-field class="passengerExpandField">
                          <input matInput ngModel placeholder="Home Number" maxlength="15" [readonly]="userType != 'memberManager' && userType != 'memberStaff'" name="homeNo" #homeNo="ngModel" type="number" [(ngModel)]="customer.homeNo">
                        </mat-form-field>

                        <mat-form-field class="passengerExpandField">
                          <input matInput ngModel placeholder="Email" maxlength="100" [readonly]="userType != 'memberManager' && userType != 'memberStaff'" name="email" #email="ngModel" [(ngModel)]="customer.email"
                                 pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$">
                        </mat-form-field>

                        <h3>Holiday Interests</h3>

                        <div class="checkboxContainer" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                          <div class="checkboxElement" *ngFor="let interest of holidayInterests">
                            <label>
                              <input type="checkbox" (change)="addRemoveHolInterest($event)"
                                  [checked]="selHolidayInterests.indexOf(interest.value) > -1"
                                  value="{{interest.value}}">
                                  {{interest.viewValue}}
                            </label>
                          </div>
                        </div>

                        <div class="checkboxContainer" *ngIf="userType != 'memberManager' && userType != 'memberStaff' && selHolidayInterests.length > 0">
                          <div class="checkboxElement" *ngFor="let interest of selHolidayInterests">
                            <li>{{ interest }}</li>
                          </div>
                        </div>

                        <h3>Company Name & Notes</h3>

                        <mat-form-field class="passengerExpandField">
                          <input matInput ngModel placeholder="Company Name" maxlength="100" [readonly]="userType != 'memberManager' && userType != 'memberStaff'" name="companyName" #companyName="ngModel" [(ngModel)]="customer.companyName">
                        </mat-form-field>

                        <mat-form-field style="width: 560px; max-width: unset;">
                          <textarea style="min-width: 50%; overflow: hidden;" matInput maxlength="500"
                            cdkTextareaAutosize ngModel name="customerNotes" #customerNotes="ngModel"
                            [(ngModel)]="customer.customerNotes" #autosize="cdkTextareaAutosize"
                            [readonly]="userType != 'memberManager' && userType != 'memberStaff'"
                            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10" placeholder="Customer Notes"></textarea>
                        </mat-form-field>

                        <h5 *ngIf="showDupliBox">
                          <mat-checkbox [checked]="createConfirm" color="accent" (change)="createConfirm = !createConfirm">
                            It seems like this customer already exist. Do you want to create it anyway?
                          </mat-checkbox>
                        </h5>

                        <div *ngIf="['memberStaff', 'memberManager', 'sinGSAdmin'].includes(userType)">
                          <input matInput hidden="true" ngModel [(ngModel)]="customer.id" name="id" #id="ngModel">
                          <button class="buttonClass" style="margin-right: 25px;" mat-raised-button color="primary" type="submit" *ngIf="userType !== 'sinGSAdmin'"
                                  [disabled]="editPassengerForm.invalid">Save</button>

                          <button class="buttonClass" style="margin-right: 25px;" mat-raised-button color="accent" type="button" *ngIf="userType !== 'sinGSAdmin'"
                                  [disabled]="editPassengerForm.invalid || !customer.emailDone || customer.isLead" (click)="setLeadPassenger(customer)">Set As Lead</button>

                          <button class="buttonClass" mat-raised-button color="warn" type="button" (click)="removePassenger(customer)" [disabled]="(bookingDateDisable && ['memberStaff', 'memberManager'].includes(userType)) || customer.isLead" matTooltipClass="line-normal-tooltip"
                                  [matTooltip]="bookingDateDisable && ['memberStaff', 'memberManager'].includes(userType) ? 'You can no longer remove passengers in this booking. Please contact the operations team for any amendments.' : customer.isLead ? 'Cannot remove lead passengers' : ''">
                                  Remove</button>
                        </div>
                      </form>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="passengerColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns : passengerColumns;" [ngClass]="{'trueRow': row.active === 'yes', 'inactiveRow': row.active === 'no'}"></mat-row>
                  <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden"></mat-row>
                </mat-table>

                <div *ngIf="passengersData.data.length == 0">
                  <h3>There are no passengers in this booking</h3>
                </div>
              </div>

              <div [@customExpansionDetails]="passengersDetails == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="passengersDetails = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>

            <div style="width: 100%; margin-bottom: 150px;" *ngIf="userType == 'memberManager' || userType == 'memberStaff' || userType == 'sinGSAdmin'">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="addPassengers" (click)="addPassengers = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!addPassengers" (click)="addPassengers = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  Add Passenger
                </div>
              </div>

              <div [@customExpansionDetails]="addPassengers == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                <h3>
                  <mat-radio-group style="vertical-align: middle;" color="primary" [(ngModel)]="newPaxRadioValue" (change)="radioChangeCustomer($event.value)">
                    <mat-radio-button value="new" [checked]="newPaxRadioValue === 'new'">New Customer</mat-radio-button>
                    <mat-radio-button style="margin-left: 1%;" value="existing" [checked]="newPaxRadioValue === 'existing'">Existing Customer</mat-radio-button>
                    <mat-radio-button style="margin-left: 1%;" value="ghost" [checked]="newPaxRadioValue === 'ghost'" [disabled]="passengersData.data.length === 0"
                                      [matTooltip]="passengersData.data.length == 0 ? 'Booking needs to have a lead passenger first' : ''">
                                      Unknown Passenger
                    </mat-radio-button>
                  </mat-radio-group>
                </h3>

                <form ngNativeValidate #getPassengerForm="ngForm" (ngSubmit)="getPassenger(getPassengerForm)" [@inAnimation] *ngIf="newPaxRadioValue === 'existing'">
                  <h5><i>Fill in surname or email below and click 'Search'. If customer exists, you'll be able to select it from the list.</i></h5>
                  <mat-form-field>
                    <input matInput ngModel placeholder="Surname" maxlength="50" name="lastName" #lastName="ngModel">
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput ngModel placeholder="Email Address" minlength="5" type="email" name="email" #email="ngModel" maxlength="100">
                  </mat-form-field>

                  <button class="buttonClass" mat-raised-button color="primary" type="submit" id="searchPax">Search</button>
                </form>

                <form ngNativeValidate #passengerForm="ngForm" (ngSubmit)="createAndAddPassenger(passengerForm, false)" [@inAnimation] *ngIf="newPaxRadioValue === 'new'">
                  <h5><i>Please enter customer details below.</i></h5>

                  <h3>Customer Name</h3>
                  <mat-form-field class="passengerExpandField">
                    <mat-select ngModel name="title" #title="ngModel" placeholder="Title">
                      <mat-option *ngFor="let title of titles" [value]="title['value']">
                        {{title['value']}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <input matInput ngModel required placeholder="First Name" maxlength="50" name="firstName" #firstName="ngModel">
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <input matInput ngModel placeholder="Middle Name" maxlength="50" name="middleName" #middleName="ngModel">
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <input matInput ngModel required placeholder="Last Name" maxlength="50" name="lastName" #lastName="ngModel">
                  </mat-form-field>

                  <h3>DOB & Contact Details</h3>
                  <mat-form-field>
                    <mat-label>Date of Birth</mat-label>
                    <input matInput ngModel name="dob" [matDatepicker]="dob" (dateChange)="valiDate($event)">
                    <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                    <mat-datepicker #dob></mat-datepicker>
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <input placeholder="Mobile Number" matInput ngModel name="telNo" #telNo="ngModel" type="number" maxlength="15">
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <input placeholder="Home Number" matInput ngModel name="homeNo" #homeNo="ngModel" type="number" maxlength="15">
                  </mat-form-field>

                  <mat-form-field class="customerLastEdit">
                    <input matInput ngModel placeholder="Email Address" minlength="5" maxlength="100" name="email" #email="ngModel"
                           pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$">
                  </mat-form-field>

                  <h3>Address</h3>

                  <mat-form-field>
                    <mat-autocomplete #auto="matAutocomplete" style="margin-top: -25px;" [panelWidth]="'auto'"
                      (optionSelected)='selectPostcode($event.option.value, "new", "")'>
                      <mat-option *ngFor="let address of addressList; let i = index" [value]="i">
                        {{ address }}
                      </mat-option>
                    </mat-autocomplete>
                    <input placeholder="Post Code" matInput id="postCode" ngModel name="postcode" #postcode="ngModel"
                      maxlength="10" (keyup)="postCodeLookup($event)" [matAutocomplete]="auto">
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <input placeholder="Address Line 1" matInput ngModel [(ngModel)]="addressLookup.address1" name="addressLine1"
                    #addressLine1="ngModel" maxlength="50">
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <input placeholder="Address Line 2" matInput ngModel [(ngModel)]="addressLookup.address2" name="addressLine2"
                    #addressLine2="ngModel" maxlength="50">
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <input placeholder="Address Line 3" matInput ngModel [(ngModel)]="addressLookup.address3" name="addressLine3"
                    #addressLine3="ngModel" maxlength="50">
                  </mat-form-field>

                  <br>

                  <mat-form-field class="passengerExpandField">
                    <input placeholder="Address Line 4" matInput ngModel [(ngModel)]="addressLookup.address4" name="addressLine4"
                    #addressLine4="ngModel" maxlength="50">
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <input placeholder="County" matInput ngModel [(ngModel)]="addressLookup.countyLine" name="county" #county="ngModel" maxlength="50">
                  </mat-form-field>

                  <mat-form-field class="passengerExpandField">
                    <mat-select placeholder="Country" ngModel [(ngModel)]="addressLookup.countryLine" name="country" #country="ngModel">
                      <mat-form-field appearance="fill" class="filterSelectList">
                        <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                      </mat-form-field>
                      <mat-option *ngFor="let country of countryFiltered" [value]="country" (click)="filterString = ''; filterSelect();">
                        {{country}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <h3>Holiday Interests</h3>

                  <div class="checkboxContainer">
                    <div class="checkboxElement" *ngFor="let interest of holidayInterests">
                      <label>
                        <input type="checkbox" (change)="addRemoveHolInterest($event)"
                            [checked]="selHolidayInterests.indexOf(interest.value) > -1"
                            value="{{interest.value}}">
                            {{interest.viewValue}}
                    </label>
                    </div>
                  </div>

                  <h3>Company Name & Notes</h3>

                  <mat-form-field>
                    <input placeholder="Company Name" matInput ngModel name="companyName" #companyName="ngModel"
                      maxlength="100">
                  </mat-form-field>

                  <mat-form-field style="width: 560px; max-width: unset;">
                    <textarea style="min-width: 50%; overflow: hidden;" matInput maxlength="500"
                      placeholder="Customer Notes" cdkTextareaAutosize ngModel name="customerNotes" #customerNotes="ngModel"
                      #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10"></textarea>
                  </mat-form-field>

                  <br>

                  <h5 *ngIf="showDupliBox">
                    <mat-checkbox [checked]="createConfirm" color="accent" (change)="createConfirm = !createConfirm">
                      It seems like this customer already exist. Do you want to create it anyway?
                    </mat-checkbox>
                  </h5>

                  <button class="buttonClass" mat-raised-button color="primary" type="submit" [disabled]="passengerForm.invalid || !createConfirm">Add</button>
                </form>

                <mat-table class="tableWithinExpansV2" multiTemplateDataRows *ngIf="newPaxRadioValue === 'existing'" [dataSource]="customerData">
                  <!-- Username Column -->
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> Full Name </mat-header-cell>
                    <mat-cell *matCellDef="let customer"> {{ customer.title }} {{ customer.firstName }} {{ customer.middleName }} {{customer.lastName }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="dob">
                    <mat-header-cell *matHeaderCellDef> Date of Birth </mat-header-cell>
                    <mat-cell *matCellDef="let customer"> {{ customer.dob | date: 'dd.MM.yyyy' }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="telephone">
                    <mat-header-cell *matHeaderCellDef> Mobile Number </mat-header-cell>
                    <mat-cell *matCellDef="let customer"> {{ customer.telNo }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                    <mat-cell *matCellDef="let customer"> {{ customer.email }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="addElement">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let customer">
                      <button type="button" mat-button color="primary" class="addElementButton" style="margin: auto; width: 125px;" (click)="addSelectedPassenger(customer)" *ngIf="!customer.disabled">
                        <mat-icon class="iconInButton">add</mat-icon>Add Passenger
                      </button>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="passengerNewColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns : passengerNewColumns;" class="trueRow"></mat-row>
                </mat-table>

                <form ngNativeValidate #ghostPaxForm="ngForm" (ngSubmit)="addGhostPassengers(ghostPaxForm)" [@inAnimation] *ngIf="newPaxRadioValue === 'ghost'">
                  <h5><i>Unknown passengers are like any other passengers, but without any details. Useful when passengers are not known at the time.</i></h5>
                  <mat-form-field>
                    <input matInput ngModel placeholder="Number Of Passengers" type="number" min="1" name="ghostPaxNo" #ghostPaxNo="ngModel">
                  </mat-form-field>

                  <button class="buttonClass" mat-raised-button color="primary" type="submit" id="addGhostPax">Add</button>
                </form>
                
              </div>

              <div [@customExpansionDetails]="addPassengers == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="addPassengers = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>
          </div>

          <div [@inAnimation] *ngIf="suppliersView && bookingData?.bookingStatus && !bookingData?.status">
            <div fxLayout="row" fxLayoutAlign="start center">
              <h1>
                <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Costs
              </h1>
              <button style="margin-left: 25px;" mat-stroked-button color="primary" (click)="reloadBooking('suppliersView')" matTooltip="Reload Booking" matTooltipClass="line-normal-tooltip">
                <mat-icon>sync</mat-icon>
              </button>
            </div>

            <div class="divider">
              <mat-divider></mat-divider>
            </div>

            <div style="width: 100%; margin-bottom: 20px;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="supplierList" (click)="supplierList = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!supplierList" (click)="supplierList = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  Supplier List
                </div>
              </div>

              <div [@customExpansionDetails]="supplierList == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                <mat-table class="tableWithinExpansV2" *ngIf="costingsData.data.length > 0" multiTemplateDataRows [dataSource]="costingsData">
                  <ng-container matColumnDef="supplierName">
                    <mat-header-cell *matHeaderCellDef> Supp. Name </mat-header-cell>
                    <mat-cell *matCellDef="let costing" style="display: flex; justify-content: space-between;">
                      <div>
                        {{costing.supplierName }}
                      </div>
                      <div style="margin-left: 10px; margin-right: 10px;">
                        <mat-icon *ngIf="costing?.isUnderSFC" matTooltip="Under SFC" style="margin-right: 10px;">security</mat-icon>
                        <mat-icon *ngIf="costing?.isUnderSafi" matTooltip="Under SAFI" style="margin-right: 10px;">airlines</mat-icon>
                      </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef>
                      <button *ngIf="userType == 'memberManager' || userType == 'memberStaff'" type="button" mat-button color="primary" class="addElementButton" (click)="addElement('new')">
                        <mat-icon class="iconInButton">add</mat-icon>Add Supplier
                      </button>
                    </mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="ref">
                    <mat-header-cell *matHeaderCellDef> Reference </mat-header-cell>
                    <mat-cell *matCellDef="let costing"> <b>{{ costing.supplierReference }}</b> </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="gross">
                    <mat-header-cell *matHeaderCellDef> Gross </mat-header-cell>
                    <mat-cell *matCellDef="let costing"> {{ costing.gross | currency:'GBP' }} </mat-cell>
                    <mat-footer-cell *matFooterCellDef><b>{{ suppliersTotalSums.grossCost | currency:'GBP' }}</b></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="net">
                    <mat-header-cell *matHeaderCellDef> Net </mat-header-cell>
                    <mat-cell *matCellDef="let costing"> {{ costing.net | currency:'GBP' }} </mat-cell>
                    <mat-footer-cell *matFooterCellDef><b>{{ suppliersTotalSums.netCost | currency:'GBP' }}</b></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="commission">
                    <mat-header-cell *matHeaderCellDef> Commission </mat-header-cell>
                    <mat-cell *matCellDef="let costing"> {{ costing.commission | currency:'GBP' }} </mat-cell>
                    <mat-footer-cell *matFooterCellDef><b>{{ suppliersTotalSums.commission | currency:'GBP' }}</b></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="vat">
                    <mat-header-cell *matHeaderCellDef> VAT </mat-header-cell>
                    <mat-cell *matCellDef="let costing"> {{ costing.tax | currency:'GBP' }} </mat-cell>
                    <mat-footer-cell *matFooterCellDef><b>{{ suppliersTotalSums.tax | currency:'GBP' }}</b></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="discount">
                    <mat-header-cell *matHeaderCellDef> Discount </mat-header-cell>
                    <mat-cell *matCellDef="let costing"> {{ costing.discount | currency:'GBP' }} </mat-cell>
                    <mat-footer-cell *matFooterCellDef><b>{{ suppliersTotalSums.discount | currency:'GBP' }}</b></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef> Supp. Status </mat-header-cell>
                    <mat-cell *matCellDef="let costing">
                      <span *ngIf="costing.elementStatus == 'enquiry'">
                        <mat-icon class="statusIcon" style="color: #ce983a;" matTooltip="Enquiry" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                        <button matTooltipPosition="below" matTooltip="Book Element" matTooltipClass="line-normal-tooltip" *ngIf="userType == 'memberManager' || userType == 'memberStaff'" name="cancelButton" type="button" mat-icon-button
                          color="primary" (click)="changeElementStatus(costing, null, null, 'booking', 'mainElement', 'suppliersView')">
                          <mat-icon>done</mat-icon>
                        </button>
                      </span>
                      <span *ngIf="costing.elementStatus == 'booking'">
                        <mat-icon class="statusIcon" style="color: #4D5FD1;" matTooltip="Booked" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                        <button matTooltipPosition="below" matTooltip="Cancel Element" matTooltipClass="line-normal-tooltip" *ngIf="userType == 'memberManager' || userType == 'memberStaff'" name="cancelButton" type="button" mat-icon-button
                          color="primary" (click)="changeElementStatus(costing, null, null, 'cancelled', 'mainElement', 'suppliersView')">
                          <mat-icon>close</mat-icon>
                        </button>
                      </span>
                      <span *ngIf="costing.elementStatus == 'cancelled'">
                        <mat-icon class="statusIcon" style="color: #a6a6a6;" matTooltip="Cancelled" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                        <button matTooltipPosition="below" matTooltip="Re-Book Element" matTooltipClass="line-normal-tooltip" *ngIf="userType == 'memberManager' || userType == 'memberStaff'" name="cancelButton" type="button" mat-icon-button
                          color="primary" (click)="changeElementStatus(costing, null, null, 'booking', 'mainElement', 'suppliersView')">
                          <mat-icon>replay</mat-icon>
                        </button>
                      </span>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="addElement">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let costing">
                      <button *ngIf="(userType == 'memberManager' || userType == 'memberStaff') && costing.elementType != 'package'" type="button" mat-button class="addElementButton" color="primary" (click)="addElement(costing)">
                        <mat-icon class="iconInButton">add</mat-icon>Add Element
                      </button>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <!-- Expansion text -->
                  <ng-container matColumnDef="expandedDetail">
                    <mat-cell class="expandedDetail" *matCellDef="let costing" [attr.colspan]="8">
                      <div>
                        <div class="costings-breakdown-main">
                          <div class="costings-breakdown-row-header">
                            <div fxFlex="4"></div>
                            <div fxFlex="12">Supplier Gross*</div>
                            <div fxFlex="12">Gross</div>
                            <div fxFlex="12">Net</div>
                            <div fxFlex="12">Commission</div>
                            <div fxFlex="12">VAT</div>
                            <div fxFlex="12">Discount</div>
                            <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">Edit Element</div>
                            <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">Status</div>
                          </div>

                          <div *ngIf="costing.elementType != 'package' && costing.attractions.length > 0">
                            <div class="costings-breakdown-row" *ngFor="let attraction of costing.attractions">
                              <div fxFlex="4" class="costings-breakdown-column"><mat-icon style="margin-left: 3.5px; margin-right: 12.5px; margin-top: -5px;">attractions</mat-icon></div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ attraction.grossCost*1 + attraction.discount*1 | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ attraction.grossCost | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ attraction.netCost | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ attraction.commission | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ attraction.tax | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ attraction.discount | currency: 'GBP' }}</div>
                              <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                                <button matTooltipPosition="below" matTooltip="Edit Attraction" matTooltipClass="line-normal-tooltip" style="transform: scale(0.75)" type="button"
                                mat-icon-button color="primary" (click)="editElement('attraction', costing)">
                                <mat-icon>edit</mat-icon>
                              </button>
                              </div>
                              <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                                <span *ngIf="attraction.attractionStatus == 'enquiry'">
                                  <mat-icon class="statusIcon" style="color: #ce983a; transform: scale(0.85);" matTooltip="Enquiry" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Book Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)" name="cancelButton"
                                    type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, attraction, 'attraction', 'booking', 'subElement', 'suppliersView')">
                                    <mat-icon>done</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="attraction.attractionStatus == 'booking'">
                                  <mat-icon class="statusIcon" style="color: #4D5FD1; transform: scale(0.85);" matTooltip="Booked" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Cancel Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)"
                                    name="cancelButton" type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, attraction, 'attraction', 'cancelled', 'subElement', 'suppliersView')">
                                    <mat-icon>close</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="attraction.attractionStatus == 'cancelled'">
                                  <mat-icon class="statusIcon" style="color: #a6a6a6; transform: scale(0.85);" matTooltip="Cancelled" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Re-Book Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)"
                                    name="cancelButton" type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, attraction, 'attraction', 'booking', 'subElement', 'suppliersView')">
                                    <mat-icon>replay</mat-icon>
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div *ngIf="costing.elementType != 'package' && costing.carhires.length > 0">
                            <div class="costings-breakdown-row" *ngFor="let carHire of costing.carhires">
                              <div fxFlex="4" class="costings-breakdown-column"><mat-icon style="margin-left: 3.5px; margin-right: 12.5px; margin-top: -5px;">drive_eta</mat-icon></div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ carHire.grossCost*1 + carHire.discount*1 | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ carHire.grossCost | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ carHire.netCost | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ carHire.commission | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ carHire.tax | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ carHire.discount | currency: 'GBP' }}</div>
                              <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                                <button matTooltipPosition="below" matTooltip="Edit Car Hire" matTooltipClass="line-normal-tooltip" style="transform: scale(0.75)" type="button"
                                mat-icon-button color="primary" (click)="editElement('carHire', costing)">
                                <mat-icon>edit</mat-icon>
                              </button>
                              </div>
                              <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                                <span *ngIf="carHire.carHireStatus == 'enquiry'">
                                  <mat-icon class="statusIcon" style="color: #ce983a; transform: scale(0.85);" matTooltip="Enquiry" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Book Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)" name="cancelButton"
                                    type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, carHire, 'carHire', 'booking', 'subElement', 'suppliersView')">
                                    <mat-icon>done</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="carHire.carHireStatus == 'booking'">
                                  <mat-icon class="statusIcon" style="color: #4D5FD1; transform: scale(0.85);" matTooltip="Booked" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Cancel Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)"
                                    name="cancelButton" type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, carHire, 'carHire', 'cancelled', 'subElement', 'suppliersView')">
                                    <mat-icon>close</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="carHire.carHireStatus == 'cancelled'">
                                  <mat-icon class="statusIcon" style="color: #a6a6a6; transform: scale(0.85);" matTooltip="Cancelled" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Re-Book Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)"
                                    name="cancelButton" type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, carHire, 'carHire', 'booking', 'subElement', 'suppliersView')">
                                    <mat-icon>replay</mat-icon>
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div *ngIf="costing.elementType != 'package' && costing.carparks.length > 0">
                            <div class="costings-breakdown-row" *ngFor="let carParking of costing.carparks">
                              <div fxFlex="4" class="costings-breakdown-column"><mat-icon style="margin-left: 3.5px; margin-right: 12.5px; margin-top: -5px;">local_parking</mat-icon></div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ carParking.grossCost*1 + carParking.discount*1 | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ carParking.grossCost | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ carParking.netCost | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ carParking.commission | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ carParking.tax | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ carParking.discount | currency: 'GBP' }}</div>
                              <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                                <button matTooltipPosition="below" matTooltip="Edit Car Park" matTooltipClass="line-normal-tooltip" style="transform: scale(0.75)" type="button"
                                mat-icon-button color="primary" (click)="editElement('carParking', costing)">
                                <mat-icon>edit</mat-icon>
                              </button>
                              </div>
                              <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                                <span *ngIf="carParking.carparkStatus == 'enquiry'">
                                  <mat-icon class="statusIcon" style="color: #ce983a; transform: scale(0.85);" matTooltip="Enquiry" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Book Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)" name="cancelButton"
                                    type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, carParking, 'carParking', 'booking', 'subElement', 'suppliersView')">
                                    <mat-icon>done</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="carParking.carparkStatus == 'booking'">
                                  <mat-icon class="statusIcon" style="color: #4D5FD1; transform: scale(0.85);" matTooltip="Booked" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Cancel Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)"
                                    name="cancelButton" type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, carParking, 'carParking', 'cancelled', 'subElement', 'suppliersView')">
                                    <mat-icon>close</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="carParking.carparkStatus == 'cancelled'">
                                  <mat-icon class="statusIcon" style="color: #a6a6a6; transform: scale(0.85);" matTooltip="Cancelled" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Re-Book Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)"
                                    name="cancelButton" type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, carParking, 'carParking', 'booking', 'subElement', 'suppliersView')">
                                    <mat-icon>replay</mat-icon>
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div *ngIf="costing.elementType != 'package' && costing.cruises.length > 0">
                            <div class="costings-breakdown-row" *ngFor="let cruise of costing.cruises">
                              <div fxFlex="4" class="costings-breakdown-column"><mat-icon style="margin-left: 3.5px; margin-right: 12.5px; margin-top: -5px;">directions_boat</mat-icon></div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ cruise.grossCost*1 + cruise.discount*1 | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ cruise.grossCost | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ cruise.netCost | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ cruise.commission | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ cruise.tax | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ cruise.discount | currency: 'GBP' }}</div>
                              <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                                <button matTooltipPosition="below" matTooltip="Edit Cruise" matTooltipClass="line-normal-tooltip" style="transform: scale(0.75)" type="button"
                                mat-icon-button color="primary" (click)="editElement('cruise', costing)">
                                <mat-icon>edit</mat-icon>
                              </button>
                              </div>
                              <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                                <span *ngIf="cruise.cruiseStatus == 'enquiry'">
                                  <mat-icon class="statusIcon" style="color: #ce983a; transform: scale(0.85);" matTooltip="Enquiry" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Book Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)" name="cancelButton"
                                    type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, cruise, 'cruise', 'booking', 'subElement', 'suppliersView')">
                                    <mat-icon>done</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="cruise.cruiseStatus == 'booking'">
                                  <mat-icon class="statusIcon" style="color: #4D5FD1; transform: scale(0.85);" matTooltip="Booked" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Cancel Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)"
                                    name="cancelButton" type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, cruise, 'cruise', 'cancelled', 'subElement', 'suppliersView')">
                                    <mat-icon>close</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="cruise.cruiseStatus == 'cancelled'">
                                  <mat-icon class="statusIcon" style="color: #a6a6a6; transform: scale(0.85);" matTooltip="Cancelled" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Re-Book Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)"
                                    name="cancelButton" type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, cruise, 'cruise', 'booking', 'subElement', 'suppliersView')">
                                    <mat-icon>replay</mat-icon>
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div *ngIf="costing.elementType != 'package' && costing.flights.length > 0">
                            <div class="costings-breakdown-row" *ngFor="let flight of costing.flights">
                              <div fxFlex="4" class="costings-breakdown-column"><mat-icon style="margin-left: 3.5px; margin-right: 12.5px; margin-top: -5px;">flight</mat-icon></div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ flight.grossCost*1 + flight.discount*1 | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ flight.grossCost | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ flight.netCost | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ flight.commission | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ flight.tax | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ flight.discount | currency: 'GBP' }}</div>
                              <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                                <button matTooltipPosition="below" matTooltip="Edit Flight" matTooltipClass="line-normal-tooltip" style="transform: scale(0.75)" type="button"
                                mat-icon-button color="primary" (click)="editElement('flight', costing)">
                                <mat-icon>edit</mat-icon>
                              </button>
                              </div>
                              <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                                <span *ngIf="flight.flightStatus == 'enquiry'">
                                  <mat-icon class="statusIcon" style="color: #ce983a; transform: scale(0.85);" matTooltip="Enquiry" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Book Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)" name="cancelButton"
                                    type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, flight, 'flight', 'booking', 'subElement', 'suppliersView')">
                                    <mat-icon>done</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="flight.flightStatus == 'booking'">
                                  <mat-icon class="statusIcon" style="color: #4D5FD1; transform: scale(0.85);" matTooltip="Booked" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Cancel Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)"
                                    name="cancelButton" type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, flight, 'flight', 'cancelled', 'subElement', 'suppliersView')">
                                    <mat-icon>close</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="flight.flightStatus == 'cancelled'">
                                  <mat-icon class="statusIcon" style="color: #a6a6a6; transform: scale(0.85);" matTooltip="Cancelled" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Re-Book Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)"
                                    name="cancelButton" type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, flight, 'flight', 'booking', 'subElement', 'suppliersView')">
                                    <mat-icon>replay</mat-icon>
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div *ngIf="costing.elementType != 'package' && costing.accoms.length > 0">
                            <div class="costings-breakdown-row" *ngFor="let hotel of costing.accoms">
                              <div fxFlex="4" class="costings-breakdown-column"><mat-icon style="margin-left: 3.5px; margin-right: 12.5px; margin-top: -5px;">hotel</mat-icon></div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ hotel.grossCost*1 + hotel.discount*1 | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ hotel.grossCost | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ hotel.netCost | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ hotel.commission | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ hotel.tax | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ hotel.discount | currency: 'GBP' }}</div>
                              <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                                <button matTooltipPosition="below" matTooltip="Edit Accommodation" matTooltipClass="line-normal-tooltip" style="transform: scale(0.75)" type="button"
                                mat-icon-button color="primary" (click)="editElement('accommodation', costing)">
                                <mat-icon>edit</mat-icon>
                              </button>
                              </div>
                              <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                                <span *ngIf="hotel.accomStatus == 'enquiry'">
                                  <mat-icon class="statusIcon" style="color: #ce983a; transform: scale(0.85);" matTooltip="Enquiry" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Book Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)" name="cancelButton"
                                    type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, hotel, 'accommodation', 'booking', 'subElement', 'suppliersView')">
                                    <mat-icon>done</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="hotel.accomStatus == 'booking'">
                                  <mat-icon class="statusIcon" style="color: #4D5FD1; transform: scale(0.85);" matTooltip="Booked" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Cancel Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)"
                                    name="cancelButton" type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, hotel, 'accommodation', 'cancelled', 'subElement', 'suppliersView')">
                                    <mat-icon>close</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="hotel.accomStatus == 'cancelled'">
                                  <mat-icon class="statusIcon" style="color: #a6a6a6; transform: scale(0.85);" matTooltip="Cancelled" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Re-Book Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)"
                                    name="cancelButton" type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, hotel, 'accommodation', 'booking', 'subElement', 'suppliersView')">
                                    <mat-icon>replay</mat-icon>
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div *ngIf="costing.elementType != 'package' && costing.miscs.length > 0">
                            <div class="costings-breakdown-row" *ngFor="let misc of costing.miscs">
                              <div fxFlex="4" class="costings-breakdown-column"><mat-icon style="margin-left: 3.5px; margin-right: 12.5px; margin-top: -5px;">miscellaneous_services</mat-icon></div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ misc.grossCost*1 + misc.discount*1 | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ misc.grossCost | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ misc.netCost | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ misc.commission | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ misc.tax | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ misc.discount | currency: 'GBP' }}</div>
                              <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                                <button matTooltipPosition="below" matTooltip="Edit Miscellaneous" matTooltipClass="line-normal-tooltip" style="transform: scale(0.75)" type="button"
                                mat-icon-button color="primary" (click)="editElement('miscellaneous', costing)">
                                <mat-icon>edit</mat-icon>
                              </button>
                              </div>
                              <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                                <span *ngIf="misc.miscStatus == 'enquiry'">
                                  <mat-icon class="statusIcon" style="color: #ce983a; transform: scale(0.85);" matTooltip="Enquiry" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Book Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)" name="cancelButton"
                                    type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, misc, 'miscellaneous', 'booking', 'subElement', 'suppliersView')">
                                    <mat-icon>done</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="misc.miscStatus == 'booking'">
                                  <mat-icon class="statusIcon" style="color: #4D5FD1; transform: scale(0.85);" matTooltip="Booked" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Cancel Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)"
                                    name="cancelButton" type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, misc, 'miscellaneous', 'cancelled', 'subElement', 'suppliersView')">
                                    <mat-icon>close</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="misc.miscStatus == 'cancelled'">
                                  <mat-icon class="statusIcon" style="color: #a6a6a6; transform: scale(0.85);" matTooltip="Cancelled" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Re-Book Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)"
                                    name="cancelButton" type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, misc, 'miscellaneous', 'booking', 'subElement', 'suppliersView')">
                                    <mat-icon>replay</mat-icon>
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div *ngIf="costing.elementType != 'package' && costing.trains.length > 0">
                            <div class="costings-breakdown-row" *ngFor="let train of costing.trains">
                              <div fxFlex="4" class="costings-breakdown-column"><mat-icon style="margin-left: 3.5px; margin-right: 12.5px; margin-top: -5px;">train</mat-icon></div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ train.grossCost*1 + train.discount*1 | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ train.grossCost | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ train.netCost | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ train.commission | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ train.tax | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ train.discount | currency: 'GBP' }}</div>
                              <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                                <button matTooltipPosition="below" matTooltip="Edit Train" matTooltipClass="line-normal-tooltip" style="transform: scale(0.75)" type="button"
                                mat-icon-button color="primary" (click)="editElement('train', costing)">
                                <mat-icon>edit</mat-icon>
                              </button>
                              </div>
                              <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                                <span *ngIf="train.trainStatus == 'enquiry'">
                                  <mat-icon class="statusIcon" style="color: #ce983a; transform: scale(0.85);" matTooltip="Enquiry" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Book Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)" name="cancelButton"
                                    type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, train, 'train', 'booking', 'subElement', 'suppliersView')">
                                    <mat-icon>done</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="train.trainStatus == 'booking'">
                                  <mat-icon class="statusIcon" style="color: #4D5FD1; transform: scale(0.85);" matTooltip="Booked" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Cancel Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)"
                                    name="cancelButton" type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, train, 'train', 'cancelled', 'subElement', 'suppliersView')">
                                    <mat-icon>close</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="train.trainStatus == 'cancelled'">
                                  <mat-icon class="statusIcon" style="color: #a6a6a6; transform: scale(0.85);" matTooltip="Cancelled" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Re-Book Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)"
                                    name="cancelButton" type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, train, 'train', 'booking', 'subElement', 'suppliersView')">
                                    <mat-icon>replay</mat-icon>
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div *ngIf="costing.elementType != 'package' && costing.transfers.length > 0">
                            <div class="costings-breakdown-row" *ngFor="let transfer of costing.transfers">
                              <div fxFlex="4" class="costings-breakdown-column"><mat-icon style="margin-left: 3.5px; margin-right: 12.5px; margin-top: -5px;">directions_bus</mat-icon></div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ transfer.grossCost*1 + transfer.discount*1 | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ transfer.grossCost | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ transfer.netCost | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ transfer.commission | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ transfer.tax | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ transfer.discount | currency: 'GBP' }}</div>
                              <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                                <button matTooltipPosition="below" matTooltip="Edit Transfer" matTooltipClass="line-normal-tooltip" style="transform: scale(0.75)" type="button"
                                mat-icon-button color="primary" (click)="editElement('transfer', costing)">
                                <mat-icon>edit</mat-icon>
                              </button>
                              </div>
                              <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                                <span *ngIf="transfer.transferStatus == 'enquiry'">
                                  <mat-icon class="statusIcon" style="color: #ce983a; transform: scale(0.85);" matTooltip="Enquiry" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Book Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)" name="cancelButton"
                                    type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, transfer, 'transfer', 'booking', 'subElement', 'suppliersView')">
                                    <mat-icon>done</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="transfer.transferStatus == 'booking'">
                                  <mat-icon class="statusIcon" style="color: #4D5FD1; transform: scale(0.85);" matTooltip="Booked" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Cancel Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)"
                                    name="cancelButton" type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, transfer, 'transfer', 'cancelled', 'subElement', 'suppliersView')">
                                    <mat-icon>close</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="transfer.transferStatus == 'cancelled'">
                                  <mat-icon class="statusIcon" style="color: #a6a6a6; transform: scale(0.85);" matTooltip="Cancelled" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Re-Book Sub-Element" matTooltipClass="line-normal-tooltip" style="transform: scale(0.85)"
                                    name="cancelButton" type="button" mat-icon-button color="primary" (click)="changeElementStatus(costing, transfer, 'transfer', 'booking', 'subElement', 'suppliersView')">
                                    <mat-icon>replay</mat-icon>
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div *ngIf="costing.elementType == 'package' && costing.packages.length > 0">
                            <div class="costings-breakdown-row" *ngFor="let package of costing.packages">
                              <div fxFlex="4" class="costings-breakdown-column"><mat-icon style="margin-left: 3.5px; margin-right: 12.5px; margin-top: -5px;">inventory_2</mat-icon></div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ package.grossCost*1 + package.discount*1 | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ package.grossCost | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ package.netCost | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ package.commission | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ package.tax | currency: 'GBP' }}</div>
                              <div fxFlex="12" class="costings-breakdown-column">{{ package.discount | currency: 'GBP' }}</div>
                              <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                                <button matTooltipPosition="below" matTooltip="Edit Package" matTooltipClass="line-normal-tooltip" style="transform: scale(0.75)" type="button"
                                mat-icon-button color="primary" (click)="editElement('package', costing)">
                                <mat-icon>edit</mat-icon>
                              </button>
                              </div>
                              <div fxFlex="12" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                                <span *ngIf="package.packageStatus == 'enquiry'">
                                  <mat-icon class="statusIcon" style="color: #ce983a;" matTooltip="Enquiry" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Book Element" matTooltipClass="line-normal-tooltip" name="cancelButton" type="button" mat-icon-button
                                    color="primary" (click)="changeElementStatus(costing, package, 'package', 'booking', 'mainElement', 'suppliersView')">
                                    <mat-icon>done</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="package.packageStatus == 'booking'">
                                  <mat-icon class="statusIcon" style="color: #4D5FD1;" matTooltip="Booked" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Cancel Element" matTooltipClass="line-normal-tooltip" name="cancelButton" type="button" mat-icon-button
                                    color="primary" (click)="changeElementStatus(costing, package, 'package', 'cancelled', 'mainElement', 'suppliersView')">
                                    <mat-icon>close</mat-icon>
                                  </button>
                                </span>
                                <span *ngIf="package.packageStatus == 'cancelled'">
                                  <mat-icon class="statusIcon" style="color: #a6a6a6;" matTooltip="Cancelled" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                                  <button matTooltipPosition="below" matTooltip="Re-Book Element" matTooltipClass="line-normal-tooltip" name="cancelButton" type="button" mat-icon-button
                                    color="primary" (click)="changeElementStatus(costing, package, 'package', 'booking', 'mainElement', 'suppliersView')">
                                    <mat-icon>replay</mat-icon>
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="costings-breakdown-row-totals">
                            <div fxFlex="4">Total</div>
                            <div fxFlex="12">{{ costing.gross*1 + costing.discount*1 | currency: 'GBP' }}</div>
                            <div fxFlex="12">{{ costing.gross | currency:'GBP' }}</div>
                            <div fxFlex="12">{{ costing.net | currency:'GBP' }}</div>
                            <div fxFlex="12">{{ costing.commission | currency:'GBP' }}</div>
                            <div fxFlex="12">{{ costing.tax | currency:'GBP' }}</div>
                            <div fxFlex="12">{{ costing.discount | currency:'GBP' }}</div>
                            <div fxFlex="12"></div>
                            <div fxFlex="12"></div>
                          </div>

                          <div class="costings-breakdown-row-legend">
                            <div><i>*Supplier Gross = Gross + Discount</i></div>
                          </div>
                        </div>
                      </div>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="costingColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns : costingColumns;" class="trueRow" (click)="expandedElement = expandedElement === row ? null : row;"></mat-row>
                  <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden"></mat-row>
                  <mat-footer-row class="rowNotClick" *matFooterRowDef="['supplierName', 'ref', 'gross', 'net', 'commission', 'vat', 'discount', 'status', 'addElement']"></mat-footer-row>
                </mat-table>

                <div *ngIf="costingsData.data.length == 0">
                  <h3>There are no suppliers in this booking</h3>
                  <button *ngIf="userType == 'memberManager' || userType == 'memberStaff'" type="button" mat-button color="primary" class="addElementButton" (click)="addElement('new')">
                    Add Supplier <mat-icon color="primary">add</mat-icon>
                  </button>
                </div>
              </div>

              <div [@customExpansionDetails]="supplierList == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="supplierList = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>

            <div style="width: 100%; margin-bottom: 150px;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft" style="width: 100%;">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="supplementAdd" (click)="supplementAdd = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!supplementAdd" (click)="supplementAdd = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  Supplement List
                  <span *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                    <button mat-raised-button (click)="supplementCreate = false; expandedElement = null;" color="primary" matTooltip="Supplement List" matTooltipClass="line-normal-tooltip" [disabled]="!supplementCreate" style="margin-left: 100px;">
                      <mat-icon style="zoom: 1; margin-left: 0px;">reorder</mat-icon>
                    </button>
                    <button mat-raised-button (click)="supplementCreate = true; resetSupplmPrices('all')" color="primary" matTooltip="Create Supplement" matTooltipClass="line-normal-tooltip" [disabled]="supplementCreate">
                      <mat-icon style="zoom: 1; margin-left: 0px;">add</mat-icon>
                    </button>
                  </span>
                </div>
              </div>

              <div [@customExpansionDetails]="supplementAdd == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">

                <div *ngIf="supplementCreate" style="width: 510px;">
                  <h5><i>Please select a supplement you wish to add from the list below</i></h5>
                  <div>
                    <mat-form-field [@inOutAnimationSupplement] style="max-width: unset; width: 100%;">
                      <mat-select ngModel required name="supplementType" #supplementType="ngModel" (selectionChange)="getSupplementPrice($event.value)" placeholder="Supplement" [(ngModel)]="supplementSelect" panelClass="supplementSelect">
                        <ng-container *ngFor="let supplement of supplementList">
                          <mat-option [value]="supplement" *ngIf="supplement.hidden !== 'yes'" matTooltipClass="line-normal-tooltip"
                          [disabled]="(supplement.supName == 'SAFI' && (flightsOnly.length == 0 || safiExists)) || (supplement.supName == 'SFC' && (!sfcCovered || sfcExists)) || (supplement.supName == 'Safe Seat Plan Guarantee - Standalone' && standaloneExists) ||
                          (supplement.supName == 'APC - ATOL Protection Contribution' && (!meetsATOLcriteria.flightOnly && !meetsATOLcriteria.atolPackage))"
                          [matTooltip]="supplement.supName === 'SAFI' && flightsOnly.length === 0 ? 'There are no \'Flight Only\' elements with a SAFI supplier in the booking' : supplement.supName === 'SFC' && !sfcCovered ? 'There are no suppliers covered under SFC in the booking' :
                          supplement.supName == 'APC - ATOL Protection Contribution' && (!meetsATOLcriteria.flightOnly && !meetsATOLcriteria.atolPackage) ? 'Your booking does not meet the ATOL criteria' : null">
                            {{supplement['supName']}}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div>
                    <div [@inAnimation] *ngIf="selectedSupplement.hidden == 'no' && selectedSupplement.autoPricing">
                      <h5 style="margin-top: 0px;"><i>Below are fixed costs for this Supplement applied automatically to the booking.<br/>Costs will be recalculated when the booking is updated.</i></h5>
                      
                      <span *ngIf="selectedSupplement.supName == 'SFC'">
                        <span *ngIf="newSfcInfo.costBasedOn == 'gross'">Cost per passenger: <b>{{ newSfcInfo.sfcBookGrossPrice | currency: 'GBP' }}</b></span>
                        <span *ngIf="newSfcInfo.costBasedOn == 'net'">Cost per passenger: <b>{{ newSfcInfo.sfcBookNetPrice | currency: 'GBP' }}</b></span>

                        <span style="float: right;">
                          Covered rate(s): {{bookingData.paxNo}} x 
                          <span *ngIf="newSfcInfo.costBasedOn == 'gross'">{{ newSfcInfo.sfcGrossPrice | currency: 'GBP' }}</span>
                          <span *ngIf="newSfcInfo.costBasedOn == 'net'">{{ newSfcInfo.sfcNetPrice | currency: 'GBP' }}</span>
                        </span>

                        <!--<h4 style="font-size: 14px; margin-bottom: 0px; margin-top: 5px;">
                        <mat-radio-group ngModel [(ngModel)]="newSfcInfo.costBasedOn" name="costBasedOn" class="costBasedOn">
                          <mat-radio-button value="gross">Based on gross</mat-radio-button>
                          <mat-radio-button value="net">Based on net</mat-radio-button>
                        </mat-radio-group>
                        </h4>-->
                      </span>

                      <span *ngIf="selectedSupplement.supName == 'SAFI' && !oldSafi">
                        <div class="warningSafi" *ngIf="flightsOnly.length > 0">Please remember to select the flights you wish to be covered by SAFI</div>
                        <div *ngFor="let flight of flightsOnly; index as i" style="display: flex; justify-content: space-between; align-items: center;">
                          <div class="sfcMainDiv">
                            <div class="sfcSubDiv">
                              <div>
                                {{ flight.departDateTime | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}
                              </div>
                              <div>
                                {{ flight.arriveDateTime | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}
                              </div>
                            </div>
                            <div class="sfcSubDiv">
                              <div>
                                {{ flight.departAirName }}
                              </div>
                              <div>
                                {{ flight.arriveAirName }}
                              </div>
                            </div>
                          </div>

                          <div style="text-align: center; margin-bottom: 10px;">
                            <div><span [style.visibility]="i > 0 ? 'hidden' : 'visible'">Covered</span></div>
                            <input type="checkbox" [checked]="flight.underSafi == 'yes'" value="{{flight.underSafi}}" (change)="changeFlightSafi($event, flight)">
                          </div>
                        </div>

                        <div style="margin-top: 10px;">
                          <span>Cost per passenger: <b>{{ newSafiInfo.safiBookGrossPrice | currency: 'GBP' }}</b></span>
                          <span style="float: right;">Covered rate(s): {{bookingData.paxNo}} x {{ newSafiInfo.safiGrossPrice | currency: 'GBP' }}</span>
                        </div>
                      </span>

                      <span *ngIf="selectedSupplement.supName != 'SFC' && (selectedSupplement.supName != 'SAFI' || oldSafi)">
                        <span *ngIf="selectedSupplement.costFixPerPx > 0">Cost per passenger: <span *ngIf="selectedSupplement.supName == 'APC - ATOL Protection Contribution'"> (2 years or older): </span>
                          <b>{{ selectedSupplement.costFixPerPx | currency: 'GBP' }}</b><br></span>
                        
                        <span *ngIf="selectedSupplement.costFixPerBk > 0">One-off cost: <b>{{ selectedSupplement.costFixPerBk | currency: 'GBP' }}</b><br></span>
                      
                        <span *ngIf="selectedSupplement.costPercPerBkGross > 0">Bookings gross rate: <b>{{ selectedSupplement.costPercPerBkGross  | number : '1.2-2'}}% (
                            {{ bookingData.custPrice * (selectedSupplement.costPercPerBkGross / 100) | currency: 'GBP' }} )</b><br></span>
                        
                        <span *ngIf="selectedSupplement.costFixPerMx > 0">One-off cost: <b>{{ selectedSupplement.costFixPerMx | currency: 'GBP' }}</b><br></span>
                      </span>

                      <div style="margin-top: 15px; margin-bottom: 10px;">
                        <button class="buttons" mat-raised-button color="accent" type="submit" (click)="updateSafiCoverage(true)" *ngIf="selectedSupplement.supName == 'SAFI'">
                          <mat-icon class="iconInButton">check</mat-icon>Add
                        </button>

                        <button class="buttons" mat-raised-button color="accent" type="submit" (click)="addNewSupplement(null, 'autoPricing')" *ngIf="selectedSupplement.supName != 'SAFI'">
                            <mat-icon class="iconInButton">check</mat-icon>Add
                        </button>

                        <button color="primary" mat-raised-button (click)="downloadDocument('SFCrates.pdf', 'templates')" style="float: right;" *ngIf="selectedSupplement.supName == 'SFC'">
                          <mat-icon class="iconInButton">file_download</mat-icon>Rates
                        </button>
                      </div>
                    </div>

                    <div [@inAnimation] *ngIf="selectedSupplement.hidden == 'no' && !selectedSupplement.autoPricing">
                      <h5 style="margin-top: 0px;"><i>Please provide the cost of selected Supplement below</i></h5>
                      <form ngNativeValidate #addSupplementForm="ngForm" (ngSubmit)="addNewSupplement(addSupplementForm, 'manualPricing')"
                            style="margin-bottom: 5px;" [ngClass]="selectedSupplement.highlight ? 'highlightRow' : ''">
                        <mat-form-field class="costingNumReadOnly">
                          <input matInput required ngModel placeholder="Gross" name="grossCost" [(ngModel)]="suppManPrice.grossCost" type="number" step="0.01" (ngModelChange)="changeGross($event, suppManPrice)">
                          <mat-hint matTooltip="Actual Gross (applied discount)" matTooltipClass="line-normal-tooltip">{{ suppManPrice.realGross | currency: 'GBP' }}</mat-hint>
                        </mat-form-field>

                        <mat-form-field class="costingNumReadOnly">
                          <input matInput required ngModel placeholder="Net" name="netCost" [(ngModel)]="suppManPrice.netCost" type="number" step="0.01" (ngModelChange)="changeNet($event, suppManPrice)">
                        </mat-form-field>

                        <mat-form-field class="costingNumReadOnly">
                          <input matInput required ngModel placeholder="Commission" name="commission" [(ngModel)]="suppManPrice.commission" type="number" step="0.01" readonly>
                        </mat-form-field>

                        <mat-form-field class="costingNumReadOnly">
                          <input matInput required ngModel placeholder="VAT" name="vat" [(ngModel)]="suppManPrice.tax" type="number" step="0.01" (ngModelChange)="changeVAT($event, suppManPrice)">
                        </mat-form-field>

                        <mat-form-field class="costingNumReadOnly">
                          <input matInput required ngModel placeholder="Discount" name="discount" [(ngModel)]="suppManPrice.discount" type="number" step="0.01" (ngModelChange)="changeDiscount($event, suppManPrice)">
                        </mat-form-field>

                        <button class="buttonToRight" mat-raised-button color="accent" type="submit">
                          <mat-icon class="iconInButton">check</mat-icon>Add
                        </button>
                      </form>
                    </div>
                  </div>
                </div>

                <div *ngIf="!supplementCreate && supplementsData.data.length == 0">
                  <h3>There are no supplements in this booking</h3>
                </div>

                <div *ngIf="!supplementCreate && supplementsData.data.length > 0">
                  <mat-table class="tableWithinExpansV2" multiTemplateDataRows [dataSource]="supplementsData">
                    <ng-container matColumnDef="supplementName">
                      <mat-header-cell *matHeaderCellDef> Supplement Name </mat-header-cell>
                      <mat-cell *matCellDef="let costing" style="justify-content: space-between;">
                        {{costing.supplierName }}
                        <mat-icon color="warn" *ngIf="costing.supplierName == 'SAFI' && flightsOnly.length == 0" matTooltip="There are no 'Flight Only' elements with a SAFI supplier in the booking"
                        matTooltipClass="line-normal-tooltip" style="margin-right: 18px;">
                          priority_high
                        </mat-icon>
                        <mat-icon color="warn" *ngIf="costing.supplierName == 'SFC' && !sfcCovered" style="margin-right: 18px;"
                        matTooltip="There are no suppliers covered under SFC in the booking" matTooltipClass="line-normal-tooltip">
                          priority_high
                        </mat-icon>

                        <button mat-icon-button color="primary" (click)="downloadCertificate(costing, 'standalone')" *ngIf="costing.supplierName == 'Safe Seat Plan Guarantee - Standalone'"
                                [matTooltip]="bookingData.bookingStatus == 'booking' ? 'Get certificate' : 'Enquiries and cancelled bookings cannot generate SASSPG certificates'"
                                matTooltipClass="line-normal-tooltip" class="normalDisabled" style="margin-right: 10px;" [disabled]="bookingData.bookingStatus != 'booking'">
                                <mat-icon>description</mat-icon>
                        </button>

                        <button mat-icon-button color="primary" (click)="downloadCertificate(costing, 'sfc')" *ngIf="costing.supplierName == 'SFC' && sfcCovered"
                                [matTooltip]="bookingData.bookingStatus == 'booking' ? 'Get certificate for Legacy PRF' : 'Enquiries and cancelled bookings cannot generate SFC certificates'"
                                matTooltipClass="line-normal-tooltip" class="normalDisabled" style="margin-right: 10px;" [disabled]="bookingData.bookingStatus != 'booking'">
                                <mat-icon>description</mat-icon>
                        </button>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="gross">
                      <mat-header-cell *matHeaderCellDef> Gross </mat-header-cell>
                      <mat-cell *matCellDef="let costing">
                        <span *ngIf="costing.supplierName != 'SFC'">{{ costing.gross | currency:'GBP' }}</span>
                        <span *ngIf="costing.supplierName == 'SFC'" [matTooltip]="'Based on ' + costing.supplements[0].costBasedOn + ' values'" matTooltipClass="line-normal-tooltip">{{ costing.gross | currency:'GBP' }}</span>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef><b>{{ supplementsTotalSums.grossCost | currency:'GBP' }}</b></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="net">
                      <mat-header-cell *matHeaderCellDef> Net </mat-header-cell>
                      <mat-cell *matCellDef="let costing"> {{ costing.net | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef><b>{{ supplementsTotalSums.netCost | currency:'GBP' }}</b></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="commission">
                      <mat-header-cell *matHeaderCellDef> Commission </mat-header-cell>
                      <mat-cell *matCellDef="let costing"> {{ costing.commission | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef><b>{{ supplementsTotalSums.commission | currency:'GBP' }}</b></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="vat">
                      <mat-header-cell *matHeaderCellDef> VAT </mat-header-cell>
                      <mat-cell *matCellDef="let costing"> {{ costing.tax | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef><b>{{ supplementsTotalSums.tax | currency:'GBP' }}</b></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="discount">
                      <mat-header-cell *matHeaderCellDef> Discount </mat-header-cell>
                      <mat-cell *matCellDef="let costing"> {{ costing.discount | currency:'GBP' }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef><b>{{ supplementsTotalSums.discount | currency:'GBP' }}</b></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="status">
                      <mat-header-cell *matHeaderCellDef> Supp. Status </mat-header-cell>
                      <mat-cell *matCellDef="let costing">
                        <span *ngIf="costing.elementStatus == 'enquiry'">
                          <mat-icon class="statusIcon" style="color: #ce983a;" matTooltip="Enquiry" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                          <button matTooltipPosition="below" matTooltip="Book Element" matTooltipClass="line-normal-tooltip" *ngIf="userType == 'memberManager' || userType == 'memberStaff'" name="cancelButton" type="button" mat-icon-button
                            color="primary" (click)="editSupplement(costing.supplements[0], 'booking', costing.elementCount)">
                            <mat-icon>done</mat-icon>
                          </button>
                        </span>
                        <span *ngIf="costing.elementStatus == 'booking'">
                          <mat-icon class="statusIcon" style="color: #4D5FD1;" matTooltip="Booked" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                          <button matTooltipPosition="below" matTooltip="Cancel Element" matTooltipClass="line-normal-tooltip" *ngIf="userType == 'memberManager' || userType == 'memberStaff'" name="cancelButton" type="button" mat-icon-button
                            color="primary" (click)="editSupplement(costing.supplements[0], 'cancelled', costing.elementCount)">
                            <mat-icon>close</mat-icon>
                          </button>
                        </span>
                        <span *ngIf="costing.elementStatus == 'cancelled'">
                          <mat-icon class="statusIcon" style="color: #a6a6a6;" matTooltip="Cancelled" matTooltipClass="line-normal-tooltip">trip_origin</mat-icon>
                          <button matTooltipPosition="below" matTooltip="Re-Book Element" matTooltipClass="line-normal-tooltip" *ngIf="userType == 'memberManager' || userType == 'memberStaff'" name="cancelButton" type="button" mat-icon-button
                            color="primary" (click)="editSupplement(costing.supplements[0], 'booking', costing.elementCount)">
                            <mat-icon>replay</mat-icon>
                          </button>
                        </span>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="addElement">
                      <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                      <mat-cell *matCellDef="let costing"> </mat-cell>
                      <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
                    </ng-container>

                    <!-- Expansion text -->
                    <ng-container matColumnDef="expandedDetail">
                      <mat-cell class="expandedDetail" *matCellDef="let costing" [attr.colspan]="8" [ngStyle]="{'min-height.px': userType !== 'memberManager' && userType !== 'memberStaff' ? 70 : 180}">
                        <div fxLayout="row" fxLayoutAlign="space-between start">
                          <div>
                            <div *ngIf="costing.supplements.length > 0" style="width: 100%; margin-top: 5px;">
                              <div *ngFor="let supplement of costing.supplements" class="formsRow">

                                <h5 style="margin-top: 0px;" *ngIf="!costing.autoPricing"><i>Below you can manually adjust costs for this supplement to tailor it to your booking needs.</i></h5>

                                <form ngNativeValidate #editSupplementForm="ngForm" *ngIf="!costing.autoPricing">
                                  <mat-icon style="margin-left: 3.5px; margin-right: 12.5px; margin-top: -5px;">add_shopping_cart</mat-icon>
                                  <mat-form-field class="costingNumReadOnly">
                                    <input matInput ngModel placeholder="Gross Cost" step="0.01" type="number" name="grossCost" #grossCost="ngModel" [(ngModel)]="supplement.grossCost"
                                      [disabled]="supplement.supplementStatus == 'cancelled'" (ngModelChange)="changeGross($event, supplement)">
                                    <mat-hint matTooltip="Actual Gross (applied discount)" matTooltipClass="line-normal-tooltip">{{ supplement.realGross | currency: 'GBP' }}</mat-hint>
                                  </mat-form-field>

                                  <mat-form-field class="costingNumReadOnly">
                                    <input matInput ngModel placeholder="Net Cost" step="0.01" type="number" name="netCost" #netCost="ngModel" [(ngModel)]="supplement.netCost"
                                      [disabled]="supplement.supplementStatus == 'cancelled'" (ngModelChange)="changeNet($event, supplement)">
                                  </mat-form-field>

                                  <mat-form-field class="costingNumReadOnly">
                                    <input matInput ngModel placeholder="Commission" step="0.01" type="number" name="commission" #commission="ngModel" [(ngModel)]="supplement.commission" readonly="true"
                                      [disabled]="supplement.supplementStatus == 'cancelled'">
                                  </mat-form-field>

                                  <mat-form-field class="costingNumReadOnly">
                                    <input matInput ngModel placeholder="VAT" step="0.01" type="number" name="tax" #tax="ngModel" [(ngModel)]="supplement.tax" [readonly]="supplement.supplementTypeClient.indexOf('no VAT') >= 0"
                                      [disabled]="supplement.supplementStatus == 'cancelled'" (ngModelChange)="changeVAT($event, supplement)" >
                                  </mat-form-field>

                                  <mat-form-field class="costingNumReadOnly">
                                    <input matInput ngModel placeholder="Discount" step="0.01" type="number" name="discount" #discount="ngModel" [(ngModel)]="supplement.discount"
                                      [disabled]="supplement.supplementStatus == 'cancelled'" (ngModelChange)="changeDiscount($event, supplement)">
                                  </mat-form-field>

                                  <button *ngIf="(userType == 'memberManager' || userType == 'memberStaff') && supplement.supplementStatus != 'cancelled'"
                                          name="editButton" type="button" mat-button color="primary" style="margin-top: 0px; font-size: 14px"
                                          (click)="editSupplement(supplement, supplement.supplementStatus, costing.elementCount)">
                                    <mat-icon>edit</mat-icon> Edit supplement
                                  </button>
                                </form>

                                <div *ngIf="costing.autoPricing">
                                  <div *ngIf="selectedSupplement.supName != undefined || costing.supplierName == 'SFC' || (costing.supplierName == 'SAFI' && !oldSafi)">
                                    <h5 style="margin-top: 0px;"><i>Below are fixed costs for this Supplement applied automatically to the booking.<br/>Costs will be recalculated when the booking is updated.</i></h5>
                                    
                                    <span *ngIf="costing.supplierName == 'SFC'">
                                      <span *ngIf="costing.supplements[0].costBasedOn == 'gross'">Cost per passenger: <b>{{ newSfcInfo.sfcBookGrossPrice | currency: 'GBP' }}</b></span>
                                      <span *ngIf="costing.supplements[0].costBasedOn == 'net'">Cost per passenger: <b>{{ newSfcInfo.sfcBookNetPrice | currency: 'GBP' }}</b></span>
                                      (based on {{ supplement.costBasedOn}} values)
                                    </span>

                                    <span *ngIf="costing.supplierName == 'SAFI' && !oldSafi">
                                      <div class="warningSafi" *ngIf="flightsOnly.length > 0">Please remember to select the flights you wish to be covered by SAFI</div>
                                      <div *ngFor="let flight of flightsOnly; index as i" style="display: flex; justify-content: space-between; align-items: center;">
                                          <div class="sfcMainDiv">
                                            <div class="sfcSubDiv">
                                              <div>
                                                {{ flight.departDateTime | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}
                                              </div>
                                              <div>
                                                {{ flight.arriveDateTime | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}
                                              </div>
                                            </div>
                                            <div class="sfcSubDiv">
                                              <div>
                                                {{ flight.departAirName }}
                                              </div>
                                              <div>
                                                {{ flight.arriveAirName }}
                                              </div>
                                            </div>
                                          </div>

                                          <div style="text-align: center; margin-bottom: 10px;">
                                            <div><span [style.visibility]="i > 0 ? 'hidden' : 'visible'">Covered</span></div>
                                            <input type="checkbox" [checked]="flight.underSafi == 'yes'" value="{{flight.underSafi}}" (change)="changeFlightSafi($event, flight)">
                                          </div>
                                      </div>
                                      
                                      <div style="margin-top: 10px;">Cost per passenger: <b>{{ newSafiInfo.safiBookGrossPrice | currency: 'GBP' }}</b></div>
                                    </span>

                                    <span *ngIf="costing.supplierName != 'SFC' && (costing.supplierName != 'SAFI' || oldSafi)">
                                      <span *ngIf="selectedSupplement.costFixPerPx > 0">Cost per passenger: <span *ngIf="costing.supplierName == 'APC - ATOL Protection Contribution'"> (2 years or older): </span> 
                                        <b>{{ selectedSupplement.costFixPerPx | currency: 'GBP' }}</b><br></span>
                                    
                                      <span *ngIf="selectedSupplement.costFixPerBk > 0">One-off cost: <b>{{ selectedSupplement.costFixPerBk | currency: 'GBP' }}</b><br></span>
                                      
                                      <span *ngIf="selectedSupplement.costPercPerBkGross > 0">Bookings gross rate: <b>{{ selectedSupplement.costPercPerBkGross  | number : '1.2-2'}}% (
                                          {{ bookingData.custPrice * (selectedSupplement.costPercPerBkGross / 100) | currency: 'GBP' }} )</b><br></span>
                                      
                                      <span *ngIf="selectedSupplement.costFixPerMx > 0">One-off cost: <b>{{ selectedSupplement.costFixPerMx | currency: 'GBP' }}</b><br></span>
                                    </span>

                                    <div style="margin-top: 5px; display: flex; justify-content: space-between;" *ngIf="(userType == 'memberManager' || userType == 'memberStaff')">
                                      <button type="button" mat-button color="primary" *ngIf="!costing.supplements[0].applyDiscount"
                                      (click)="changeAppliedDiscount(costing.supplements[0], costing.elementCount, true)" style="padding: 0px; font-size: 14px">
                                        <mat-icon>redeem</mat-icon> Apply discount
                                      </button>

                                      <button type="button" mat-button color="primary" *ngIf="costing.supplements[0].applyDiscount"
                                      (click)="changeAppliedDiscount(costing.supplements[0], costing.elementCount, false)" style="padding: 0px; font-size: 14px">
                                        <mat-icon>money_off</mat-icon> Remove discount
                                      </button>

                                      <!--<button type="button" mat-button color="primary" *ngIf="costing.supplierName == 'SFC'"
                                      (click)="changeSFCbasis(costing.supplements[0], costing.elementCount)" style="padding: 0px; margin-left: 10px; font-size: 14px">
                                        <mat-icon>autorenew</mat-icon> Change SFC basis
                                      </button>-->

                                      <button type="button" mat-button color="primary" *ngIf="costing.supplierName == 'SAFI' && !oldSafi"
                                      (click)="updateSafiCoverage(false)" style="padding: 0px; margin-left: 10px; font-size: 14px">
                                        <mat-icon>update</mat-icon> Update SAFI coverage
                                      </button>
                                      
                                      <button type="button" mat-button color="primary" *ngIf="costing.supplierName == 'SFC'"
                                      (click)="downloadDocument('SFCrates.pdf', 'templates')" style="padding: 0px; margin-left: 10px; font-size: 14px">
                                        <mat-icon class="iconInButton">file_download</mat-icon>Rates
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="supplementColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns : supplementColumns;" class="trueRow" (click)="expandedElement = expandedElement === row ? null : row; calcSupplementCosts(row)"></mat-row>
                    <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden"></mat-row>
                    <mat-footer-row class="rowNotClick" *matFooterRowDef="['supplementName', 'gross', 'net', 'commission', 'vat', 'discount', 'status', 'addElement']"></mat-footer-row>
                  </mat-table>
                </div>
              </div>

              <div [@customExpansionDetails]="supplementAdd == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="supplementAdd = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>
          </div>
          
          <div [@inAnimation] *ngIf="receiptsView && bookingData?.bookingStatus && !bookingData?.status">
            <div fxLayout="row" fxLayoutAlign="start center">
              <h1>
                <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Receipts
              </h1>
              <button style="margin-left: 25px;" mat-stroked-button color="primary" (click)="reloadBooking('receiptsView')" matTooltip="Reload Booking" matTooltipClass="line-normal-tooltip">
                <mat-icon>sync</mat-icon>
              </button>
            </div>

            <div class="divider">
              <mat-divider></mat-divider>
            </div>

            <div style="width: 100%; margin-bottom: 20px;">
              <div class="ownExpansionHeader" style="display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: space-between;">
                <div class="headerTopLeft" style="width: 500px;">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="receiptList" (click)="receiptList = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!receiptList" (click)="receiptList = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  Receipts

                  <span *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                    <button mat-raised-button (click)="receiptCreate = false;" color="primary" matTooltip="Receipt List" matTooltipClass="line-normal-tooltip" [disabled]="!receiptCreate" style="margin-left: 100px;">
                      <mat-icon style="zoom: 1; margin-left: 0px;">reorder</mat-icon>
                    </button>
                    <button mat-raised-button (click)="receiptCreate = true;" color="primary" matTooltip="Create Receipt" matTooltipClass="line-normal-tooltip" [disabled]="receiptCreate">
                      <mat-icon style="zoom: 1; margin-left: 0px;">add</mat-icon>
                    </button>
                  </span>
                </div>

                <div style="margin-right: 25px;">Show Hidden
                  <mat-checkbox style="font-size: 15px; margin-left: 10px;" [(ngModel)]="showMistakes" color="primary"
                  (change)="showMistakesOnOff($event.checked)"></mat-checkbox>
                </div>
              </div>

              <div [@customExpansionDetails]="receiptList == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                <div *ngIf="!receiptCreate">
                  <mat-table class="tableWithinExpansV2" *ngIf="receiptsData.data.length > 0" multiTemplateDataRows [dataSource]="receiptsData">

                    <ng-container matColumnDef="receiptDate">
                      <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                      <mat-cell *matCellDef="let receipt">
                        <span style="width: 73px;">{{receipt.receiptDate | date : "dd.MM.y" }}</span>
                        <span *ngIf="userType == 'memberManager' && receipt.receiptCategory == 'receipt'">
                          <input [matDatepicker]="receiptDate" style="width: 1px; height: 1px; visibility: hidden;" (dateChange)="editReceiptDate($event, receipt)" />
                          <mat-datepicker-toggle matSuffix [for]="receiptDate" class="picker-toggle"></mat-datepicker-toggle>
                          <mat-datepicker #receiptDate [startAt]="receipt.receiptDate"></mat-datepicker>
                        </span>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="totalCharge">
                      <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                      <mat-cell *matCellDef="let receipt">
                        <span matTooltip="Including {{ receipt.merchantFee | currency: 'GBP' }} merchant fee" matTooltipClass="line-normal-tooltip">
                          <span *ngIf="receipt.receiptCategory == 'receipt'">{{ receipt.totalCharge | currency:'GBP' }}</span>
                          <span *ngIf="receipt.receiptCategory == 'mistake'"><s>{{ receipt.totalCharge | currency:'GBP' }}</s></span>
                        </span>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef style="font-size: 13px;"><b>{{ receiptTotalSum.totalCharge | currency: 'GBP' }}</b></mat-footer-cell>
                    </ng-container>

                    <!--<ng-container matColumnDef="merchantFee">
                      <mat-header-cell *matHeaderCellDef> Merchant Fee </mat-header-cell>
                      <mat-cell *matCellDef="let receipt">
                        <span *ngIf="receipt.receiptCategory == 'receipt'">{{ receipt.merchantFee | currency:'GBP' }}</span>
                        <span *ngIf="receipt.receiptCategory == 'mistake'"><s>{{ receipt.merchantFee | currency:'GBP' }}</s></span>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef style="font-size: 13px;"><b>{{ receiptTotalSum.merchantFee | currency: 'GBP' }}</b></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="creditValue">
                      <mat-header-cell *matHeaderCellDef> Receipt Value </mat-header-cell>
                      <mat-cell *matCellDef="let receipt">
                        <span *ngIf="receipt.receiptCategory == 'receipt'">{{ receipt.creditValue | currency:'GBP' }}</span>
                        <span *ngIf="receipt.receiptCategory == 'mistake'"><s>{{ receipt.creditValue | currency:'GBP' }}</s></span>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef style="font-size: 13px;"><b>{{ receiptTotalSum.creditValue | currency: 'GBP' }}</b></mat-footer-cell>
                    </ng-container>-->

                    <ng-container matColumnDef="paymentMethod">
                      <mat-header-cell *matHeaderCellDef> Method </mat-header-cell>
                      <mat-cell *matCellDef="let receipt"> {{ receipt.paymentMethod | titlecase }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="reference">
                      <mat-header-cell *matHeaderCellDef> Reference </mat-header-cell>
                      <mat-cell *matCellDef="let receipt"> {{ receipt.reference }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="payerRef">
                      <mat-header-cell *matHeaderCellDef> Payer </mat-header-cell>
                      <mat-cell *matCellDef="let receipt">
                        <div style="display: flex; align-items: center;">
                          <div>{{ receipt.payerName }}</div>
                          <div *ngIf="(receipt.payerId == null || receipt.payerId == undefined || receipt.payerId == '') && receipt.receiptCategory == 'receipt'">
                            <mat-icon style="margin-left: 10px; color: #ce983a;">priority_high</mat-icon>
                          </div>
                          <div *ngIf="receipt.missingPax && receipt.receiptCategory == 'receipt'">
                            <button class="matIcon" type="button" mat-mini-fab color="accent" (click)="addMissingPaxFromReceipt(receipt)" [class.mat-elevation-z0]="true"
                                    style="margin-left: 10px;" matTooltip="Add missing passenger" matTooltipClass="line-normal-tooltip">
                              <mat-icon>person_add</mat-icon>
                            </button>
                          </div>
                        </div>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="receiptStatus">
                      <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                      <mat-cell *matCellDef="let receipt">
                        {{ receipt.receiptCategory | titlecase }}
                        <span *ngIf="userType == 'memberManager' && receipt.receiptCategory == 'receipt'">
                          <button class="matIcon" type="button" mat-stroked-button color="warn" (click)="cancelReceipt(receipt)" [class.mat-elevation-z0]="true">
                            <mat-icon>undo</mat-icon>
                          </button>
                        </span>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="printAndSend">
                      <mat-header-cell *matHeaderCellDef>
                        <button class="matIcon" type="button" mat-mini-fab color="accent" [disabled]="userType != 'memberManager'" 
                          (click)="docTemplateSettings('receiptLetterV2', 'Receipt Letter', false)" [class.mat-elevation-z0]="true" style="margin: auto;">
                            <mat-icon>settings</mat-icon>
                          </button>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let receipt">
                        <div style="display: flex; width: 100%; justify-content: center;">
                          <button class="matIcon" type="button" mat-mini-fab color="primary" *ngIf="receipt.receiptCategory != 'mistake'"
                          (click)="printDocument('receiptLetterV2', 'Receipt Letter', receipt.receiptCount, 'no')" [class.mat-elevation-z0]="true">
                            <mat-icon>print</mat-icon>
                          </button>

                          <button class="matIcon" type="button" mat-mini-fab color="primary" *ngIf="receipt.receiptCategory != 'mistake'" style="margin-left: 10px;"
                          (click)="printDocument('receiptLetterV2', 'Receipt Letter', receipt.receiptCount, 'yes')" [disabled]="userType != 'memberManager' && userType != 'memberStaff'" [class.mat-elevation-z0]="true">
                            <mat-icon>email</mat-icon>
                          </button>
                        </div>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="expand">
                      <mat-header-cell *matHeaderCellDef></mat-header-cell>
                      <mat-cell *matCellDef="let receipt">
                        <button class="matIcon" type="button" style="color: #080000;" mat-icon-button *ngIf="receipt.receiptCategory == 'receipt'"
                                (click)="expandedElement = expandedElement === receipt ? null : receipt" [class.mat-elevation-z0]="true">
                          <mat-icon *ngIf="expandedElement != receipt">keyboard_arrow_down</mat-icon>
                          <mat-icon *ngIf="expandedElement === receipt">keyboard_arrow_up</mat-icon>
                        </button>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="expandedDetail">
                      <mat-cell class="expandedDetail" *matCellDef="let receipt" [attr.colspan]="8">
                        <div class="paymentInformation" *ngIf="receipt.payerId != null && receipt.payerId != undefined">
                          <div>
                            <div><h4>Payer linkage:</h4></div>
                            <div class="paymentLinkageDetails"><div><b>Passenger name:</b></div><div style="margin-left: 20px;">{{ receipt.payerName }}</div></div>
                          </div>
                          <div>
                            <div class="paymentLinkUnlink" *ngIf="['memberStaff', 'memberManager'].includes(userType)">
                              <div>
                                <h4>Unlink this passenger from the receipt</h4>
                              </div>
                              <div>
                                <button type="button" mat-button color="accent" style="width: 150px; margin: 0px;" (click)="changeReceiptLink('unlink', receipt, null)">
                                <mat-icon class="iconInButton">link_off</mat-icon>
                              </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="paymentInformation" *ngIf="receipt.payerId == null || receipt.payerId == undefined">
                          <div>
                            <p>This receipt has not yet been associated with any passenger</p>
                            <p *ngIf="['memberStaff', 'memberManager'].includes(userType)">Please select a passenger from the list on the right and link them with this receipt</p>
                          </div>

                          <div *ngIf="['memberStaff', 'memberManager'].includes(userType)" style="margin-left: 42px; margin-top: 20px;">
                            
                            <form ngNativeValidate #linkReceiptForm="ngForm" (ngSubmit)="changeReceiptLink('link', receipt, linkReceiptForm)"
                                style="display: flex; flex-direction: column; align-items: stretch;">
                              <mat-form-field class="passengerExpandField" style="max-width: unset; width: 450px; font-size: 12px;">
                                <mat-select ngModel required name="selectedPassenger" #selectedPassenger="ngModel" placeholder="Passenger">
                                    <ng-container *ngFor="let passenger of passengersData.data">
                                      <mat-option *ngIf="passenger.id !== null && passenger.id !== undefined" [value]="passenger">
                                        {{ passenger.firstName }} {{ passenger.lastName }}
                                      </mat-option>
                                    </ng-container>
                                </mat-select>
                              </mat-form-field>
                              
                              <button type="submit" mat-button color="accent" class="addElementButton normalDisabled" [disabled]="linkReceiptForm.invalid">
                                <mat-icon class="iconInButton">link</mat-icon> Link
                              </button>
                            </form>
                          </div>
                        </div>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="receiptColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns : receiptColumns;" class="rowNotClick"></mat-row>
                    <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden"></mat-row>
                    <mat-footer-row class="rowNotClick" *matFooterRowDef="receiptColumns"></mat-footer-row>
                  </mat-table>

                  <div *ngIf="receiptsData.data.length == 0">
                    <h3>There are no receipts for this booking yet</h3>
                  </div>
                </div>

                <div *ngIf="receiptCreate">
                  <form ngNativeValidate #addReceiptForm="ngForm" (ngSubmit)="addNewReceipt(addReceiptForm)">
                    <h2>Create Manual Receipt</h2>
                    <h5><i>To manually add a receipt, please select the customer below and complete all necessary details</i></h5>

                    <mat-form-field class="passengerSelect" style="width: 560px; max-width: unset;">
                      <mat-select ngModel name="customer" placeholder="Customer" #customer="ngModel">
                        <ng-container *ngFor="let data of passengersData.data">
                          <mat-option *ngIf="data.id !== null && data.id !== undefined" [value]="data">
                            {{ data.firstName }} {{ data.middleName }} {{ data.lastName }}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                    <br />

                    <mat-form-field class="passengerExpandField">
                      <mat-label>Receipt Date</mat-label>
                      <input ngModel required matInput name="receiptDate" [matDatepicker]="receiptDate" [(ngModel)]="today" (dateChange)="valiDate($event)">
                      <mat-datepicker-toggle matSuffix [for]="receiptDate"></mat-datepicker-toggle>
                      <mat-datepicker #receiptDate></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="passengerExpandField">
                      <mat-select  ngModel required name="paymentMethod" [(ngModel)]="newReceipt.paymentMethod"
                      #paymentMethod="ngModel" placeholder="Payment Method" (selectionChange)="newReceiptCalc('')">
                        <mat-option [value]="'Amex'">Amex</mat-option>
                        <mat-option [value]="'Bank Transfer'">Bank Transfer</mat-option>
                        <mat-option [value]="'Cash'">Cash</mat-option>
                        <mat-option [value]="'Cheque'">Cheque</mat-option>
                        <mat-option [value]="'Credit Card'">Credit Card</mat-option>
                        <mat-option [value]="'Debit Card'">Debit Card</mat-option>
                        <mat-option [value]="'Direct To Op'">Direct To Op</mat-option>
                        <mat-option [value]="'Felloh - Manual'">Felloh - Manual</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="passengerExpandField">
                      <input matInput ngModel required placeholder="Reference" name="reference" #reference="ngModel" maxlength="50">
                    </mat-form-field>
                    <br />

                    <mat-form-field class="receiptValues">
                      <input matInput ngModel required placeholder="Credit Value" step="0.01" type="number" name="creditValue"
                             (change)="newReceiptCalc('creditValue')" [(ngModel)]="newReceipt.creditValue">
                    </mat-form-field>

                    <mat-form-field class="receiptValues">
                      <input matInput ngModel required placeholder="Merchant Fee" min="0.00" step="0.01" type="number"
                      name="merchantFee" (change)="newReceiptCalc('merchantFee')" [(ngModel)]="newReceipt.merchantFee"
                      [disabled]="!['Amex', 'Credit Card', 'Debit Card'].includes(newReceipt.paymentMethod)">
                    </mat-form-field>

                    <mat-form-field class="receiptValues">
                      <input matInput ngModel required placeholder="Total Charge" step="0.01" type="number" name="totalCharge"
                      (change)="newReceiptCalc('totalCharge')" [(ngModel)]="newReceipt.totalCharge">
                    </mat-form-field>

                    <div *ngIf="newReceipt.merchantFee > 0" class="cardFeeSupplementMessage">
                      Prepare '{{ newReceipt.paymentMethod }} Fee' supplement
                      <mat-checkbox style="margin-left: 15px;" ngModel name="createSupplement" [(ngModel)]="newReceipt.createSupplement" color="primary">
                      </mat-checkbox>
                    </div>

                    <h5>
                      <span *ngIf="['Amex', 'Credit Card', 'Debit Card'].includes(newReceipt.paymentMethod)">
                        <p>Consider the <u>Receipt Date</u> as an estimated deposit date for your bank account.</p>

                        <p>
                          As you're entering a new {{ newReceipt.paymentMethod }} transaction, please remember to include the charge that has been incurred:<br>
                          - Please enter what you have received in the <u>Credit Value</u>, and what the {{ newReceipt.paymentMethod }} charge was under <u>Merchant Fee</u>.<br>
                          - Alternatively, enter the amount paid by the customer under <u>Total Charge</u>, and the {{ newReceipt.paymentMethod }} charge under <u>Merchant Fee</u>.
                        </p>
                      </span>
                      <span *ngIf="newReceipt.paymentMethod == 'Felloh - Manual'">
                        <p>Consider the <u>Receipt Date</u> above as the completion date for the Felloh transaction.</p>

                        <p>Felloh receipt should be automatically created up to a minute after a successful payment.<br>
                        In case the system failed to create one, please use this option to add it manually instead.</p>
                      </span>

                      <span *ngIf="newReceipt.paymentMethod == 'Cash' || newReceipt.paymentMethod == 'Cheque'">
                        <p>Consider the <u>Receipt Date</u> as an estimated deposit date for your bank account.</p>

                        <p>Each {{ newReceipt.paymentMethod }} transaction should be banked into your conventional trust account.<br>
                        There should be a separate receipt created for each {{ newReceipt.paymentMethod}} transaction.</p>
                      </span>

                      <span *ngIf="newReceipt.paymentMethod == 'Bank Transfer'">
                        <p>Consider the <u>Receipt Date</u> as an estimated deposit date for your bank account.</p>
                      </span>

                      <p *ngIf="['Amex', 'Credit Card', 'Debit Card'].includes(newReceipt.paymentMethod)" class="cardFeeInfo">
                        Your current {{ newReceipt.paymentMethod }} transaction fee is 
                        <span *ngIf="newReceipt.paymentMethod == 'Amex'">{{ selectedBranch.cardFeePercAmex | number : '1.2-2' }}%, </span>
                        <span *ngIf="newReceipt.paymentMethod == 'Credit Card'">{{ selectedBranch.cardFeePercCredit | number : '1.2-2' }}%, </span>
                        <span *ngIf="newReceipt.paymentMethod == 'Debit Card'">{{ selectedBranch.cardFeePercDebit | number : '1.2-2' }}%, </span>
                        adjustable in your branch setup.
                      </p>
                    </h5>

                    <button class="buttonClass" mat-raised-button color="primary" type="submit" [disabled]="addReceiptForm.invalid">Submit</button>
                  </form>
                </div>
              </div>

              <div [@customExpansionDetails]="receiptList == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="receiptList = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>

            <div *ngIf="fellohReady" style="width: 100%; margin-bottom: 100px;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft" style="width: 100%; justify-content: space-between;">
                  <div style="width: 40%;">
                    <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="fellohList" (click)="fellohList = false;">
                      <mat-icon>expand_less</mat-icon>
                    </button>
                    <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!fellohList" (click)="fellohList = true;">
                      <mat-icon>expand_more</mat-icon>
                    </button>
                    Felloh

                    <span *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                      <button mat-raised-button (click)="fellohCreate = false;" color="primary" matTooltip="Felloh List" matTooltipClass="line-normal-tooltip" [disabled]="!fellohCreate" style="margin-left: 121px;">
                        <mat-icon style="zoom: 1; margin-left: 0px;">reorder</mat-icon>
                      </button>
                      <button mat-raised-button (click)="fellohCreate = true;" color="primary" matTooltip="Create Link" matTooltipClass="line-normal-tooltip" [disabled]="fellohCreate">
                        <mat-icon style="zoom: 1; margin-left: 0px;">add</mat-icon>
                      </button>
                    </span>
                  </div>

                  <div *ngIf="selectedBranch?.fellohConfig?.length > 1" id="fellohAccDiv">
                    <mat-select ngModel placeholder="Account Name" name="fellohConfig" [(ngModel)]="selectedFellohAccount" id="fellohAccount" #fellohConfig="ngModel"
                                panelClass="fellohAccSelect" (ngModelChange)="changeFellohAccount()">
                        <mat-option *ngFor="let fellohConfig of selectedBranch?.fellohConfig" [value]="fellohConfig">
                          {{fellohConfig['accountName']}} | {{fellohConfig['accountCode']}}
                        </mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>

              <div [@customExpansionDetails]="fellohList == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                <div *ngIf="!fellohCreate">
                  <mat-table class="tableWithinExpansV2" *ngIf="fellohData.data.length > 0" multiTemplateDataRows [dataSource]="fellohData">

                    <ng-container matColumnDef="custName">
                      <mat-header-cell *matHeaderCellDef> Customer Name </mat-header-cell>
                      <mat-cell *matCellDef="let data"> {{ data.customer_name }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="amount">
                      <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
                      <mat-cell *matCellDef="let data"> {{ data.amount | currency:data.currency }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="openBanking">
                      <mat-header-cell *matHeaderCellDef class="centeredColumn">Open<br>Banking</mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centeredColumn">
                        <mat-icon *ngIf="data.methods.open_banking">check</mat-icon>
                        <mat-icon *ngIf="!data.methods.open_banking">close</mat-icon>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="cardPayments">
                        <mat-header-cell *matHeaderCellDef class="centeredColumn">Card<br>Payments</mat-header-cell>
                        <mat-cell *matCellDef="let data" class="centeredColumn">
                            <mat-icon *ngIf="data.methods.card">check</mat-icon>
                            <mat-icon *ngIf="!data.methods.card">close</mat-icon>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="createdDate">
                      <mat-header-cell *matHeaderCellDef class="centeredColumn">Created</mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centeredColumn"> {{ data.created_at | date : "dd.MM.y hh:mm a" }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="paymentDate">
                      <mat-header-cell *matHeaderCellDef class="centeredColumn">Expires</mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centeredColumn">{{ data.expires_at | date : "dd.MM.y hh:mm a" }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="status">
                      <mat-header-cell *matHeaderCellDef class="centeredColumn">Status</mat-header-cell>
                      <mat-cell *matCellDef="let data" class="centeredColumn">
                        <span *ngIf="data.status == 'active'" style="color: rgb(128,180,221); font-weight: bold;">Active</span>
                        <span *ngIf="data.status == 'paid'" style="color: rgb(129,199,132); font-weight: bold;">Paid</span>
                        <span *ngIf="data.status == 'expired'" style="color: rgb(244,67,54); font-weight: bold;">Expired</span>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                      <mat-header-cell *matHeaderCellDef></mat-header-cell>
                      <mat-cell *matCellDef="let data">
                        <button class="matIcon" style="float: right;" *ngIf="data.status == 'active' && (userType == 'memberManager' || userType == 'memberStaff')" type="button" mat-mini-fab color="warn" (click)="deleteFellohLink(data)"
                          [class.mat-elevation-z0]="true">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="expandedDetail">
                      <mat-cell class="expandedDetail" *matCellDef="let data" [attr.colspan]="7">
                        <div style="width: 100%; margin-bottom: 5px;">
                          
                          <div class="fellohExpandRow" *ngIf="data.status == 'paid' || (userType != 'memberManager' && userType != 'memberStaff')">
                            <div>
                              <div>
                                <h3>
                                  <mat-icon style="color: #565656; margin-top: -1.5px;">info</mat-icon> Transaction Details
                                </h3>
                              </div>
                              <div class="fellohExpandRowColumn">
                                <div>ID</div><div>{{data.id}}</div>
                              </div>
                              <div class="fellohExpandRowColumn">
                                <div>Created By</div><div>{{data.metadata.requestCreator}}</div>
                              </div>
                              <div class="fellohExpandRowColumn">
                                <div>Payment Description</div><div>{{data.description}}</div>
                              </div>
                            </div>
  
                            <div>
                              <div>
                                <h3>
                                  <mat-icon style="color: #565656; margin-top: -1.5px;">payment</mat-icon> Payment Details
                                </h3>
                              </div>
                              <div class="fellohExpandRowColumn">
                                <div>Payment Type</div>
                                <div *ngIf="data.transactions && data.transactions.length > 0 && data.transactions[0].type == 'CARD'">Card</div>
                                <div *ngIf="data.transactions && data.transactions.length > 0 && data.transactions[0].type == 'OPEN_BANKING'">Open Banking</div>
                              </div>
                              <div *ngIf="data.transactions && data.transactions.length > 0 && data.transactions[0].type == 'CARD'">
                                <div class="fellohExpandRowColumn">
                                  <div>Cardholder Name</div>
                                  <div>{{ data.transactions && data.transactions.length > 0 ? data.transactions[0]?.metadata?.cardholder_name : '' }}</div>
                                </div>
                                <div class="fellohExpandRowColumn">
                                  <div>Card Type</div>
                                  <div>{{ data.transactions && data.transactions.length > 0 ? (data.transactions[0]?.metadata?.payment_brand | titlecase) + ' ' + (data.transactions[0]?.metadata?.card_type | titlecase) : '' }}</div>
                                </div>
                                <div class="fellohExpandRowColumn">
                                  <div>Last Four Digits</div>
                                  <div>{{ data.transactions && data.transactions.length > 0 ? data.transactions[0]?.metadata?.last_four_digits : '' }}</div>
                                </div>
                                <div class="fellohExpandRowColumn">
                                  <div>Issuing Country</div>
                                  <div>{{ data.transactions && data.transactions.length > 0 ? data.transactions[0]?.metadata?.issuing_country : '' }}</div>
                                </div>
                                <div class="fellohExpandRowColumn">
                                  <div>Payment Date</div>
                                  <div>{{ data.transactions && data.transactions.length > 0 ? (data.transactions[0]?.completed_at | date: 'dd.MM.y hh:mm a') : '' }}</div>
                                </div>
                              </div>
                              <div *ngIf="data.transactions && data.transactions.length > 0 && data.transactions[0].type == 'OPEN_BANKING'">
                                <div class="fellohExpandRowColumn">
                                  <div>Reference</div>
                                  <div>{{ data.transactions && data.transactions.length > 0 ? data.transactions[0]?.provider_reference : '' }}</div>
                                </div>
                                <div class="fellohExpandRowColumn">
                                  <div>Payment Date</div>
                                  <div>{{ data.transactions && data.transactions.length > 0 ? (data.transactions[0]?.completed_at | date: 'dd.MM.y hh:mm a') : '' }}</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="fellohExpandRow" *ngIf="data.status == 'active' && (userType == 'memberManager' || userType == 'memberStaff')">
                            <div style="display: flex; flex-direction: column; align-items: center;">
                              <div>
                                <h3>
                                  <mat-icon style="color: #565656; margin-top: -1.5px;">payment</mat-icon> Take Payment Now
                                </h3>
                              </div>
  
                              <div class="fellohExpandRowColumn">
                                <div *ngIf="data.allowedPaymentMethods == 'OPEN_BANKING'">
                                  <i><b>This option is not available as this is an Open Banking payment only</b></i>
                                </div>
                                <div *ngIf="data.allowedPaymentMethods != 'OPEN_BANKING'">
                                  <i><b>Click the button below and hand the device over to the customer to complete</b></i>
                                </div>
                              </div>
  
                              <div class="fellohExpandRowColumn">
                                <div>
                                  <button type="button" mat-stroked-button color="primary" [disabled]="data.allowedPaymentMethods == 'OPEN_BANKING' || restrictedUser"
                                  (click)="openFellohLink(data, 'manualInPerson')" class="fellohSettingButtons">
                                    <mat-icon>person</mat-icon> Payment In Person
                                  </button>
                                </div>
                              </div>
  
                              <div class="fellohExpandRowColumn">
                                <div *ngIf="data.allowedPaymentMethods == 'OPEN_BANKING'">
                                  <i><b>This option is not available as this is an Open Banking payment only</b></i>
                                </div>
                                <div *ngIf="data.allowedPaymentMethods != 'OPEN_BANKING'">
                                  <i><b>Click the button below and request your customer for the card details</b></i>
                                </div>
                              </div>
  
                              <div class="fellohExpandRowColumn">
                                <div>
                                  <button type="button" mat-stroked-button color="primary" [disabled]="data.allowedPaymentMethods == 'OPEN_BANKING' || restrictedUser"
                                  (click)="openFellohLink(data, 'manual')" class="fellohSettingButtons">
                                    <mat-icon>phone_in_talk</mat-icon> Payment Over Phone
                                  </button>
                                </div>
                              </div>
                              
                            </div>
  
                            <div style="display: flex; flex-direction: column; align-items: center;">
  
                              <div>
                                <h3>
                                  <mat-icon style="color: #565656; margin-top: -2.5px;">send</mat-icon> Send Payment Link
                                </h3>
                              </div>
  
                              <div class="fellohExpandRowColumn">
                                <div>
                                  <button type="button" mat-stroked-button color="primary" [cdkCopyToClipboard]="fellohPayGate + data.id" class="fellohSettingButtons">
                                    <mat-icon>content_copy</mat-icon> Copy Link
                                  </button>
                                </div>
                              </div>
  
                              <div class="fellohExpandRowColumn">
                                <div>
                                  <button type="button" mat-stroked-button color="primary" (click)="emailFellohLink(data, 'link')" class="fellohSettingButtons">
                                    <mat-icon>email</mat-icon> Email Customer
                                  </button>
                                </div>
                              </div>
  
                              <div class="fellohExpandRowColumn">
                                <div>
                                  <button type="button" mat-stroked-button color="primary" (click)="emailFellohLink(data, 'reminder')" class="fellohSettingButtons">
                                    <mat-icon>notifications</mat-icon> Email Reminder
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="fellohColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns : fellohColumns;" class="trueRow" (click)="expandedElement = expandedElement === row ? null : row"></mat-row>
                    <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden"></mat-row>
                  </mat-table>

                  <div *ngIf="fellohData.data.length == 0">
                    <h3>There are no Felloh payments for this booking yet</h3>
                  </div>
                </div>

                <div *ngIf="fellohCreate">
                  <form ngNativeValidate #createFellohForm="ngForm" (ngSubmit)="validateFellohBooking(createFellohForm)">
                    <div class="addReceiptForm">

                      <div *ngIf="passengersData.data.length > 0">
                        <h2>Create New Link</h2>
                        <h5>
                          Please select the customer you wish to create payment link for.<br>
                          A confirmation email will be sent to both you and the customer after a successful payment.<br><br>
                          <u>The system will automatically create a receipt after the payment is made.</u>
                        </h5>

                        <h2 class="fellohAdvButton" (click)="toggleFellohOptions()">Advanced Options</h2>

                        <div class="fellohTogglesParent" *ngIf="fellohOptions.opened">
                          <div class="togglesDetails">
                            <h3 style="margin-top: 0px; margin-bottom: 5px;">Allowed Card Types</h3>
                            <h5 style="margin-top: 0px;">Set which card types can be used for payment</h5>
                            
                            <div>
                              <div class="fellohToggles">
                                <div>
                                  Amex
                                </div>
                                <div>
                                  <mat-slide-toggle ngModel [(ngModel)]="fellohOptions.types.amex" #amex="ngModel" name="amex"></mat-slide-toggle>
                                </div>
                              </div>

                              <div class="fellohToggles">
                                <div>
                                  Mastercard
                                </div>
                                <div>
                                  <mat-slide-toggle ngModel [(ngModel)]="fellohOptions.types.mastercard" #mastercard="ngModel" name="mastercard"></mat-slide-toggle>
                                </div>
                              </div>

                              <div class="fellohToggles">
                                <div>
                                  Visa
                                </div>
                                <div>
                                  <mat-slide-toggle ngModel [(ngModel)]="fellohOptions.types.visa" #visa="ngModel" name="visa"></mat-slide-toggle>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          <div class="togglesDetails">
                            <h3 style="margin-top: 0px; margin-bottom: 5px;">Allowed Card Regions</h3>
                            <h5 style="margin-top: 0px;">Set which regions card payments can be accepted from</h5>
                            
                            <div>
                              <div class="fellohToggles">
                                <div>
                                  UK
                                </div>
                                <div>
                                  <mat-slide-toggle ngModel [(ngModel)]="fellohOptions.regions.uk" #uk="ngModel" name="uk"></mat-slide-toggle>
                                </div>
                              </div>

                              <div class="fellohToggles">
                                <div>
                                  Europe / EEA
                                </div>
                                <div>
                                  <mat-slide-toggle ngModel [(ngModel)]="fellohOptions.regions.europe" #europe="ngModel" name="europe"></mat-slide-toggle>
                                </div>
                              </div>

                              <div class="fellohToggles">
                                <div>
                                  Rest of World
                                </div>
                                <div>
                                  <mat-slide-toggle ngModel [(ngModel)]="fellohOptions.regions.world" #world="ngModel" name="world"></mat-slide-toggle>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          <div class="togglesDetails">
                            <h3 style="margin-bottom: 5px;">Payment Methods</h3>
                            <h5 style="margin-top: 0px;">Set which payment methods your customer will be able to use to pay with</h5>
                            
                            <div>
                              <div class="fellohToggles">
                                <div>
                                  Card Payments
                                </div>
                                <div>
                                  <mat-slide-toggle ngModel [(ngModel)]="fellohOptions.methods.card" #card="ngModel" name="card"></mat-slide-toggle>
                                </div>
                              </div>

                              <div class="fellohToggles">
                                <div>
                                  Open Banking
                                </div>
                                <div>
                                  <mat-slide-toggle ngModel [(ngModel)]="fellohOptions.methods.openBanking" #openBanking="ngModel" name="openBanking"></mat-slide-toggle>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="togglesDetails" [ngStyle]="{'visibility': selectedFellohAccount.surcharging === 'yes' ? 'visible' : 'hidden'}">
                            <h3 style="margin-bottom: 5px;">Surcharging</h3>
                            <h5 style="margin-top: 0px;">Whether customer should be surcharged on applicable card types</h5>
                            
                            <div>
                              <div class="fellohToggles">
                                <div>
                                  Enabled
                                </div>
                                <div>
                                  <mat-slide-toggle ngModel [(ngModel)]="fellohOptions.surcharging" #surcharging="ngModel" name="surcharging"></mat-slide-toggle>
                                </div>
                              </div>

                            </div>
                          </div>

                          <div class="togglesDetails">
                            <h3 style="margin-bottom: 5px;">Custom Expiration Date</h3>
                            <h5 style="margin-top: 0px;">Set a custom expiration date for the payment link</h5>
                          </div>

                          <div class="togglesDetails">
                            <div>
                              <div>
                                <mat-form-field class="passengerExpandField" style="width: 100%; max-width: unset; margin-top: 12px;">
                                  <mat-label>Expiration Date</mat-label>
                                  <input ngModel matInput name="expiryDate" [matDatepicker]="expiryDate" (dateChange)="valiDate($event)" [(ngModel)]="fellohOptions.expiryDate">
                                  <mat-datepicker-toggle matSuffix [for]="expiryDate"></mat-datepicker-toggle>
                                  <mat-datepicker #expiryDate></mat-datepicker>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </div>

                        <mat-form-field class="passengerSelect" style="max-width: unset; width: 275px; margin-right: 50px;">
                          <mat-select ngModel name="index" placeholder="Customer" #index (selectionChange)="passengerSelected(index.value)">
                            <ng-container *ngFor="let data of passengersData.data; index as i" [value]="i">
                              <mat-option *ngIf="data.email !== null && data.email !== undefined && data.email !== ''" [value]="i">
                                {{ data.firstName }} {{ data.middleName }} {{ data.lastName }}
                              </mat-option>
                            </ng-container>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field class="passengerExpandField" style="max-width: unset; width: 275px;" *ngIf="(selectedPassenger | keyvalue)!.length > 0">
                          <input type="email" ngModel required matInput name="customerEmail" placeholder="Customer Email" [(ngModel)]="selectedPassenger.email" readonly="true">
                        </mat-form-field>

                        <br>

                        <mat-form-field class="passengerExpandField" style="max-width: unset; width: 275px; margin-right: 50px;" *ngIf="(selectedPassenger | keyvalue)!.length > 0">
                          <input placeholder="Amount Due" type="number" step="0.01" min="1" ngModel required matInput name="amountDue" max="100000">
                          <mat-select matSuffix ngModel name="currency" [(ngModel)]="selectedCurrency" class="amountSuffix" panelClass="fellohCurrency">
                            <mat-option *ngFor="let currency of fellohCurrencies" [value]="currency">{{currency}}</mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field class="passengerExpandField" style="max-width: unset; width: 275px;" *ngIf="(selectedPassenger | keyvalue)!.length > 0">
                          <mat-label>Notes</mat-label>
                          <input placeholder="e.g Trip to Bali" ngModel required matInput pattern="[A-Za-z0-9 ]+" maxlength="50" title="Special characters are not allowed" name="notes">
                        </mat-form-field>

                        <br>

                        <mat-form-field *ngIf="!bookingData.tradingNameId" style="width: 600px; max-width: unset;">
                          <mat-select ngModel name="tradingNameId" [(ngModel)]="bookingData.tradingNameId" #tradingNameId="ngModel"
                          (selectionChange)="editBookingSelects('Branch name')" placeholder="Trading name for this booking" required>
                            <mat-option *ngFor="let tradingName of selectedBranch?.tradingNames" [value]="tradingName.id">
                              {{tradingName.tradingName}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <br *ngIf="!bookingData.tradingNameId">

                        <button *ngIf="(selectedPassenger | keyvalue)!.length > 0" class="buttonClass" mat-raised-button color="primary" type="submit" [disabled]="createFellohForm.invalid || !bookingData.tradingNameId">Create</button>
                      </div>

                      <div *ngIf="passengersData.data.length == 0">
                        <h3>You need to create a passenger first</h3>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div [@customExpansionDetails]="fellohList == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="fellohList = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>
          </div>

          <div [@inAnimation] *ngIf="paymentsView && bookingData?.bookingStatus && !bookingData?.status">
            <div fxLayout="row" fxLayoutAlign="start center">
              <h1>
                <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Payments
              </h1>
              <button style="margin-left: 25px;" mat-stroked-button color="primary" (click)="reloadBooking('paymentsView')" matTooltip="Reload Booking" matTooltipClass="line-normal-tooltip">
                <mat-icon>sync</mat-icon>
              </button>
            </div>

            <div class="divider">
              <mat-divider></mat-divider>
            </div>

            <div style="width: 100%; margin-bottom: 20px;">
              <div class="ownExpansionHeader" style="display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: space-between;">
                <div class="headerTopLeft" style="width: 390px;">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="paymentList" (click)="paymentList = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!paymentList" (click)="paymentList = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  Payments

                  <span *ngIf="(((userType == 'sinGSstaff' || userType == 'sinGSAdmin') && bookingData.company != 'tta' ) || bookingData.company == 'tta')">
                    <button mat-raised-button (click)="paymentCreate = false;" color="primary" matTooltip="Payment List" matTooltipClass="line-normal-tooltip" [disabled]="!paymentCreate" style="margin-left: 100px;">
                      <mat-icon style="zoom: 1; margin-left: 0px;">reorder</mat-icon>
                    </button>
                    <button mat-raised-button (click)="paymentCreate = true;" color="primary" matTooltip="Create Payment" matTooltipClass="line-normal-tooltip" [disabled]="paymentCreate">
                      <mat-icon style="zoom: 1; margin-left: 0px;">add</mat-icon>
                    </button>
                  </span>
                </div>

                <div style="margin-right: 25px; display: flex;">
                  <div style="margin-right: 25px;">Supplier Payments Only
                    <mat-checkbox style="font-size: 15px; margin-left: 10px;" [(ngModel)]="showPaymentsOnly" color="primary"
                    (change)="showSpecificPayments($event.checked, 'showSuppPayOnly')"></mat-checkbox>
                  </div>

                  <div style="margin-right: 25px;">Commission Only
                    <mat-checkbox style="font-size: 15px; margin-left: 10px;" [(ngModel)]="showCommOnly" color="primary"
                    (change)="showSpecificPayments($event.checked, 'showCommisisonOnly')"></mat-checkbox>
                  </div>

                  <div style="margin-right: 25px;">Show Hidden
                    <mat-checkbox style="font-size: 15px; margin-left: 10px;" [(ngModel)]="showMistakes" color="primary"
                    (change)="showMistakesOnOff($event.checked)"></mat-checkbox>
                  </div>
                </div>
              </div>

              <div [@customExpansionDetails]="paymentList == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                <div *ngIf="!paymentCreate">
                  <mat-table class="tableWithinExpansV2" *ngIf="paymentsData.data.length > 0" multiTemplateDataRows [dataSource]="paymentsData">

                    <ng-container matColumnDef="paymentDate">
                      <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                      <mat-cell *matCellDef="let payment">
                        <span style="width: 73px;">{{payment.paymentDate | date : "dd.MM.y" }}</span>
                        <span *ngIf="(((userType == 'sinGSstaff' || userType == 'sinGSAdmin') && bookingData.company != 'tta' ) || (bookingData.company == 'tta' && userType == 'memberManager')) && payment.paymentCategory == 'payment'">
                          <input [matDatepicker]="paidDate" style="width: 1px; height: 1px; visibility: hidden;" (dateChange)="editPaymentDate($event, payment)" />
                          <mat-datepicker-toggle matSuffix [for]="paidDate" class="picker-toggle"></mat-datepicker-toggle>
                          <mat-datepicker #paidDate [startAt]="payment.paymentDate"></mat-datepicker>
                        </span>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="paymentAmount">
                      <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                      <mat-cell *matCellDef="let payment">
                        <span *ngIf="payment.paymentCategory != 'mistake' && payment.paymentCategory != 'cancelled'">{{ payment.paymentAmount | currency:'GBP' }}</span>
                        <span *ngIf="payment.paymentCategory == 'mistake' || payment.paymentCategory == 'cancelled'"><s>{{ payment.paymentAmount | currency:'GBP' }}</s></span>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef style="font-size: 13px;"><b>{{ paymentTotalSum.paymentAmount | currency: 'GBP' }}</b></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="paymentMethod">
                      <mat-header-cell *matHeaderCellDef> Method </mat-header-cell>
                      <mat-cell *matCellDef="let payment"> {{ payment.paymentMethod }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="reference">
                      <mat-header-cell *matHeaderCellDef> Reference </mat-header-cell>
                      <mat-cell *matCellDef="let payment"> {{ payment.paymentReference }} </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="suppRef">
                      <mat-header-cell *matHeaderCellDef> Supplier </mat-header-cell>
                      <mat-cell *matCellDef="let payment"> 
                        <div style="display: flex; align-items: center;">
                          <div>
                            <i *ngIf="payment.supplierName">{{ payment.supplierName }}<br></i>
                            {{ payment.supplierRef }}
                          </div>
                          <div *ngIf="(payment.elementCount == null || payment.elementCount == undefined || payment.elementCount == '') && payment.paymentCategory == 'payment'">
                            <mat-icon style="margin-left: 10px; color: #ce983a;">priority_high</mat-icon>
                          </div>
                        </div>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="paymentStatus">
                      <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                      <mat-cell *matCellDef="let payment">
                        <span *ngIf="payment.paymentCategory != 'clientRefund'">{{ payment.paymentCategory | titlecase }}</span>
                        <span *ngIf="payment.paymentCategory == 'clientRefund'">Client Refund</span>
                        <span *ngIf="(((userType == 'sinGSstaff' || userType == 'sinGSAdmin') && bookingData.company != 'tta' ) || (bookingData.company == 'tta' && userType == 'memberManager')) && payment.paymentCategory == 'payment'">
                          <button class="matIcon" type="button" mat-stroked-button color="warn" (click)="cancelPayment(payment)" [class.mat-elevation-z0]="true">
                            <mat-icon>undo</mat-icon>
                          </button>
                        </span>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="printAndSend">
                      <mat-header-cell *matHeaderCellDef></mat-header-cell>
                      <mat-cell *matCellDef="let payment">
                        <button class="matIcon" type="button" mat-mini-fab color="primary" *ngIf="payment.paymentCategory == 'payment'" style="margin: auto;"
                                [disabled]="payment.supplierName == 'N/A'"
                                (click)="printDocument('remittanceLetterV2', 'Remittance Letter', payment.paymentCount, 'no')" [class.mat-elevation-z0]="true">
                          <mat-icon>print</mat-icon>
                        </button>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="expand">
                      <mat-header-cell *matHeaderCellDef></mat-header-cell>
                      <mat-cell *matCellDef="let payment">
                        <button class="matIcon" type="button" style="color: #080000;" mat-icon-button *ngIf="payment.paymentCategory == 'payment'"
                                (click)="expandedElement = expandedElement === payment ? null : payment" [class.mat-elevation-z0]="true">
                          <mat-icon *ngIf="expandedElement != payment">keyboard_arrow_down</mat-icon>
                          <mat-icon *ngIf="expandedElement === payment">keyboard_arrow_up</mat-icon>
                        </button>
                      </mat-cell>
                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="expandedDetail">
                      <mat-cell class="expandedDetail" *matCellDef="let payment" [attr.colspan]="8">
                        <div class="paymentInformation" *ngIf="payment.elementCount != null && payment.elementCount != undefined">
                          <div>
                            <div><h4>Payment linkage:</h4></div>
                            <div class="paymentLinkageDetails"><div><b>Supplier name:</b></div><div style="margin-left: 20px;">{{ payment.supplierName }}</div></div>
                            <div class="paymentLinkageDetails"><div><b>Supplier reference:</b></div><div style="margin-left: 20px;">{{ payment.supplierRef }}</div></div>
                          </div>
                          <div>
                            <div class="paymentLinkUnlink" *ngIf="(((userType == 'sinGSstaff' || userType == 'sinGSAdmin') && bookingData.company != 'tta') || (bookingData.company == 'tta' && userType == 'memberManager'))">
                              <div>
                                <h4>Unlink this payment from the element</h4>
                              </div>
                              <div>
                                <button type="button" mat-button color="accent" style="width: 150px; margin: 0px;" (click)="changePaymentLink('unlink', payment, null)">
                                <mat-icon class="iconInButton">link_off</mat-icon>
                              </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="paymentInformation" *ngIf="payment.elementCount == null || payment.elementCount == undefined">
                          <div>
                            <p>This payment has not yet been associated with any specific element</p>
                            <p *ngIf="(((userType == 'sinGSstaff' || userType == 'sinGSAdmin') && bookingData.company != 'tta') || (bookingData.company == 'tta' && userType == 'memberManager'))">
                              Please select a supplier from the list on the right and link it with this payment
                            </p>
                          </div>

                          <div *ngIf="(((userType == 'sinGSstaff' || userType == 'sinGSAdmin') && bookingData.company != 'tta') || (bookingData.company == 'tta' && userType == 'memberManager'))"
                                style="margin-left: 42px; margin-top: 20px;">
                            
                            <form ngNativeValidate #linkPaymentForm="ngForm" (ngSubmit)="changePaymentLink('link', payment, linkPaymentForm)"
                                style="display: flex; flex-direction: column; align-items: stretch;">
                              <mat-form-field class="passengerExpandField" style="max-width: unset; width: 450px; font-size: 12px;">
                                <mat-select ngModel required name="supplierCosting" #supplierCosting="ngModel" placeholder="Supplier">
                                  <ng-container *ngFor="let costing of costingsData.data">
                                    <mat-option *ngIf="costing.elementType != 'supplement'" [value]="costing">
                                      {{costing.supplierName}} ({{costing.supplierReference}})
                                    </mat-option>
                                    <mat-option *ngIf="costing.elementType == 'supplement'" [value]="costing" [disabled]="true">
                                      {{costing.supplierName}}
                                    </mat-option>
                                  </ng-container>
                                </mat-select>
                              </mat-form-field>
                              
                              <button type="submit" mat-button color="accent" class="addElementButton normalDisabled" [disabled]="linkPaymentForm.invalid">
                                <mat-icon class="iconInButton">link</mat-icon> Link
                              </button>
                            </form>
                          </div>
                        </div>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="paymentColumns"></mat-header-row>
                    <mat-row class="rowNotClick" *matRowDef="let row; columns : paymentColumns;"></mat-row>
                    <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden"></mat-row>
                    <mat-footer-row class="rowNotClick" *matFooterRowDef="paymentColumns"></mat-footer-row>
                  </mat-table>

                  <div *ngIf="paymentsData.data.length == 0">
                    <h3>There are no payments in this booking</h3>
                  </div>
                </div>

                <div *ngIf="paymentCreate">
                  <form ngNativeValidate #addPaymentForm="ngForm" (ngSubmit)="addNewPayment(addPaymentForm)">
                    <h2>Create New Payment</h2>
                    <h5><i>To manually add a payment, please select the supplier below and complete all necessary details</i></h5>

                    <div class="costings-container">
                      <ng-container *ngFor="let costing of costingsData.data">
                        <div class="costing-card" *ngIf="costing.elementType !== 'supplement'" (click)="selectPaymentSupplier(costing)" [ngClass]="{'costing-selected': costing.costingSelected}">
                          <div class="costing-section" style="min-width: 500px;">
                            <div class="supplier-name">{{ costing.supplierName }}</div>
                            <div class="supplier-reference">{{ costing.supplierReference }}
                              <span *ngIf="costing.elementStatus == 'cancelled'" style="color: #00000066;"><b>(CANCELLED)</b></span>
                            </div>
                          </div>
  
                          <div class="costing-values">
                            <div class="value" style="width: 50px;">
                              <div matTooltip="Deposit Balance"
                              [ngClass]="{'cancelled': costing.elementStatus == 'cancelled', 'balanced': toNumber(costing.supplierPayments) >= toNumber(costing.depositAmount), 'not-balanced': toNumber(costing.supplierPayments) < toNumber(costing.depositAmount)}">
                                <mat-icon class="icon">savings</mat-icon>
                              </div>
                              <div matTooltip="Full Balance"
                              [ngClass]="{'cancelled': costing.elementStatus == 'cancelled', 'balanced': toNumber(costing.supplierPayments) >= toNumber(costing.net), 'not-balanced': toNumber(costing.supplierPayments) < toNumber(costing.net)}">
                                <mat-icon class="icon">payments</mat-icon></div>
                            </div>
                            <div class="value">
                              <div *ngIf="toNumber(costing.supplierPayments) < toNumber(costing.depositAmount)"
                              [ngClass]="{'cancelled': costing.elementStatus == 'cancelled', 'balanced': toNumber(costing.supplierPayments) >= toNumber(costing.depositAmount), 'not-balanced': toNumber(costing.supplierPayments) < toNumber(costing.depositAmount)}">
                                {{ costing.supplierPayments | currency:'GBP' }}</div>
                              <div *ngIf="toNumber(costing.supplierPayments) >= toNumber(costing.depositAmount)"
                              [ngClass]="{'cancelled': costing.elementStatus == 'cancelled', 'balanced': toNumber(costing.supplierPayments) >= toNumber(costing.depositAmount), 'not-balanced': toNumber(costing.supplierPayments) < toNumber(costing.depositAmount)}">
                                {{ costing.depositAmount | currency:'GBP' }}</div>
                              
                              <div [ngClass]="{'cancelled': costing.elementStatus == 'cancelled', 'balanced': toNumber(costing.supplierPayments) >= toNumber(costing.net) || costing.elementStatus == 'cancelled', 'not-balanced': toNumber(costing.supplierPayments) < toNumber(costing.net)}">
                                {{ costing.supplierPayments | currency:'GBP' }}</div>
                            </div>
                            <div class="value" style="width: 50px;">
                              <div [ngClass]="{'cancelled': costing.elementStatus == 'cancelled', 'balanced': toNumber(costing.supplierPayments) >= toNumber(costing.depositAmount), 'not-balanced': toNumber(costing.supplierPayments) < toNumber(costing.depositAmount)}">/</div>
                              <div [ngClass]="{'cancelled': costing.elementStatus == 'cancelled', 'balanced': toNumber(costing.supplierPayments) >= toNumber(costing.net), 'not-balanced': toNumber(costing.supplierPayments) < toNumber(costing.net)}">/</div>
                            </div>
                            <div class="value">
                              <div [ngClass]="{'cancelled': costing.elementStatus == 'cancelled', 'balanced': toNumber(costing.supplierPayments) >= toNumber(costing.depositAmount), 'not-balanced': toNumber(costing.supplierPayments) < toNumber(costing.depositAmount)}">
                                {{ costing.depositAmount | currency:'GBP' }}</div>
                              
                              <div *ngIf="costing.elementStatus != 'cancelled'" [ngClass]="{'balanced': toNumber(costing.supplierPayments) >= toNumber(costing.net), 'not-balanced': toNumber(costing.supplierPayments) < toNumber(costing.net)}">
                                {{ costing.net | currency:'GBP' }}</div>
                              <div *ngIf="costing.elementStatus == 'cancelled'" class="cancelled">£0.00</div>
                            </div>
                            <div class="value">
                              <div *ngIf="toNumber(costing.supplierPayments) < toNumber(costing.depositAmount)"
                              [ngClass]="{'cancelled': costing.elementStatus == 'cancelled', 'balanced': toNumber(costing.supplierPayments) >= toNumber(costing.depositAmount), 'not-balanced': toNumber(costing.supplierPayments) < toNumber(costing.depositAmount)}">
                                ({{ toNumber(costing.depositAmount) - toNumber(costing.supplierPayments) | currency:'GBP' }})</div>
                              <div *ngIf="toNumber(costing.supplierPayments) >= toNumber(costing.depositAmount)"
                              [ngClass]="{'cancelled': costing.elementStatus == 'cancelled', 'balanced': toNumber(costing.supplierPayments) >= toNumber(costing.depositAmount), 'not-balanced': toNumber(costing.supplierPayments) < toNumber(costing.depositAmount)}">
                                ({{ toNumber(costing.depositAmount) - toNumber(costing.depositAmount) | currency:'GBP' }})</div>
                              
                              <div *ngIf="costing.elementStatus != 'cancelled'" [ngClass]="{'balanced': toNumber(costing.supplierPayments) == toNumber(costing.net), 'not-balanced': toNumber(costing.supplierPayments) != toNumber(costing.net)}">
                                ({{ toNumber(costing.net) - toNumber(costing.supplierPayments) | currency:'GBP' }})</div>
                              <div *ngIf="costing.elementStatus == 'cancelled'" class="cancelled">
                                ({{ toNumber(0) - toNumber(costing.supplierPayments) | currency:'GBP' }})</div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      
                      <div class="costing-card" (click)="selectPaymentCommission()" [ngClass]="{'costing-selected': commissionPaymentSelected}" *ngIf="userType == 'sinGSAdmin' || userType == 'sinGSstaff'">
                        <div class="costing-section" style="min-width: 500px;">
                          <div class="supplier-name">Commission</div>
                        </div>

                        <div class="costing-values" style="height: 100%; display: flex; flex-direction: row; align-items: flex-start;">
                          <div class="value" style="width: 50px;">
                            <div [ngClass]="{'balanced': toNumber(bookingData.commission) == toNumber(bookingData.commissionPaid), 'not-balanced': toNumber(bookingData.commission) != toNumber(bookingData.commissionPaid)}">
                              <mat-icon class="icon">payments</mat-icon></div>
                          </div>
                          <div class="value">
                            <div [ngClass]="{'balanced': toNumber(bookingData.commission) == toNumber(bookingData.commissionPaid), 'not-balanced': toNumber(bookingData.commission) != toNumber(bookingData.commissionPaid)}">
                              {{ bookingData.commissionPaid | currency:'GBP' }}</div>
                          </div>
                          <div class="value" style="width: 50px;">
                            <div [ngClass]="{'balanced': toNumber(bookingData.commission) == toNumber(bookingData.commissionPaid), 'not-balanced': toNumber(bookingData.commission) != toNumber(bookingData.commissionPaid)}">/</div>
                          </div>
                          <div class="value">
                            <div [ngClass]="{'balanced': toNumber(bookingData.commission) == toNumber(bookingData.commissionPaid), 'not-balanced': toNumber(bookingData.commission) != toNumber(bookingData.commissionPaid)}">
                              {{ bookingData.commission | currency:'GBP' }}</div>
                          </div>
                          <div class="value">
                      
                          <div [ngClass]="{'balanced': toNumber(bookingData.commission) == toNumber(bookingData.commissionPaid), 'not-balanced': toNumber(bookingData.commission) != toNumber(bookingData.commissionPaid)}">
                              ({{ toNumber(bookingData.commission) - toNumber(bookingData.commissionPaid) | currency:'GBP' }})</div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <mat-form-field class="passengerExpandField">
                      <input matInput ngModel required placeholder="Payment Value" step="0.01" type="number" name="paymentValue" #paymentValue="ngModel">
                    </mat-form-field>

                    <mat-form-field class="passengerExpandField">
                      <mat-select ngModel required name="paymentMethod" #paymentMethod="ngModel" placeholder="Payment Method">
                        <mat-option [value]="'ACAS'">ACAS</mat-option>
                        <mat-option [value]="'BACS'">BACS</mat-option>
                        <mat-option [value]="'Credit Card'">Credit Card</mat-option>
                        <mat-option [value]="'Direct'">Direct</mat-option>
                        <mat-option [value]="'Manual Bank Transfer'">Manual Bank Transfer</mat-option>
                        <mat-option [value]="'Manual Commission'" *ngIf="commissionPaymentSelected">Manual Commission</mat-option>
                        <mat-option [value]="'TAPS'">TAPS</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="passengerExpandField">
                      <mat-label>Payment Date</mat-label>
                      <input ngModel required matInput name="paymentDate" [matDatepicker]="paymentDate" [(ngModel)]="today" (dateChange)="valiDate($event)">
                      <mat-datepicker-toggle matSuffix [for]="paymentDate"></mat-datepicker-toggle>
                      <mat-datepicker #paymentDate></mat-datepicker>
                    </mat-form-field>

                    <p></p>

                    <mat-form-field class="passengerExpandField">
                      <mat-select ngModel required name="currency" [(ngModel)]="selectedCurrency" #currency="ngModel" placeholder="Currency">
                        <mat-option [value]="'GBP'">GBP</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="passengerExpandField" style="max-width: unset; width: 370px;">
                      <input matInput ngModel required placeholder="Payment Reference" name="paymentReference" #paymentReference="ngModel" maxlength="50">
                    </mat-form-field>

                    <p></p>
                    <button class="buttonClass" mat-raised-button color="primary" type="submit" [disabled]="addPaymentForm.invalid">Submit</button>
                  </form>
                </div>
              </div>

              <div [@customExpansionDetails]="paymentList == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="paymentList = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>
          </div>

          <div [@inAnimation] *ngIf="documentationView && bookingData?.bookingStatus && !bookingData?.status">
            <div fxLayout="row" fxLayoutAlign="start center">
              <h1>
                <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Documentation
              </h1>
              <button style="margin-left: 25px;" mat-stroked-button color="primary" (click)="reloadBooking('documentationView')" matTooltip="Reload Booking" matTooltipClass="line-normal-tooltip">
                <mat-icon>sync</mat-icon>
              </button>
            </div>

            <div class="divider">
              <mat-divider></mat-divider>
            </div>

            <div class="ownExpansionHeader">
              <div class="headerTopLeft" style="width: 36%; max-width: 350px;">
                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="s3Documents" (click)="s3Documents = false;">
                  <mat-icon>expand_less</mat-icon>
                </button>
                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!s3Documents" (click)="s3Documents = true;">
                  <mat-icon>expand_more</mat-icon>
                </button>
                <div>Attached Documents</div>
                <button mat-icon-button type="button" (click)="pageLoaded = false; reloadS3documents()" matTooltip="Reload Files" matTooltipClass="line-normal-tooltip" color="primary" style="margin-left: 20px; display: inline;">
                  <mat-icon style="zoom: 1; margin-left: 0px;">refresh</mat-icon>
                </button>
                <input #uploadInput hidden="true" type="file" onclick="this.value=null" (change)="uploadDocument($event)" accept=".csv,.doc,.docx,.txt,.pdf,.xls,.xlsx,.jpg,.jpeg,.eml" multiple="true" />
                <button mat-icon-button type="button" (click)="uploadInput.click()" matTooltip="Upload File" matTooltipClass="line-normal-tooltip" color="primary"
                        style="margin-left: 20px; display: inline;" *ngIf="['memberStaff', 'memberManager'].includes(userType)">
                  <mat-icon style="zoom: 1; margin-left: 0px;">file_upload</mat-icon>
                </button>
              </div>
            </div>

            <div [@customExpansionDetails]="s3Documents == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
              <mat-table *ngIf="attachedDocs.data.length > 0" class="tableWithinExpansV2" style="zoom: 1 !important;" [dataSource]="attachedDocs">
                <ng-container matColumnDef="fileName">
                  <mat-header-cell *matHeaderCellDef> File Name </mat-header-cell>
                  <mat-cell *matCellDef="let data"> {{ data.name }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="lastModified">
                  <mat-header-cell *matHeaderCellDef> Last Modified Date </mat-header-cell>
                  <mat-cell *matCellDef="let data"> {{ data.last_modified | date : "dd.MM.y hh:mm a" }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="size">
                  <mat-header-cell *matHeaderCellDef> Size </mat-header-cell>
                  <mat-cell *matCellDef="let data"> {{ data.size | filesize }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="download">
                  <mat-header-cell *matHeaderCellDef></mat-header-cell>
                  <mat-cell *matCellDef="let data">
                    <div style="display: flex; width: 100%; justify-content: center;">
                      <span *ngIf="['memberStaff', 'memberManager'].includes(userType)">
                        <button class="matIcon" style="float: right;" type="button" mat-mini-fab color="primary" (click)="downloadDocument(data, 'attachedBookDocs')" [class.mat-elevation-z0]="true">
                          <mat-icon>file_download</mat-icon>
                        </button>
                      </span>
  
                      <span *ngIf="['memberStaff', 'memberManager'].includes(userType)">
                        <button class="matIcon" style="float: right; margin-left: 10px;" type="button" mat-mini-fab color="warn" (click)="removeAttachedDocument(data)"
                          [class.mat-elevation-z0]="true">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </span>
                    </div>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="s3FilesColumns"></mat-header-row>
                <mat-row class="rowNotClick" *matRowDef="let row; columns : s3FilesColumns;"></mat-row>
              </mat-table>

              <div [@inAnimation] *ngIf="attachedDocs.data.length == 0" style="clear: both;">
                <h3>There is no data to show</h3>
              </div>
            </div>

            <div [@customExpansionDetails]="s3Documents == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="s3Documents = true;">
              <mat-icon>expand_more</mat-icon>
            </div>

            <div class="ownExpansionHeader" style="margin-top: 50px;">
              <div class="headerTopLeft">
                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="generateDocument" (click)="generateDocument = false;">
                  <mat-icon>expand_less</mat-icon>
                </button>
                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!generateDocument" (click)="generateDocument = true;">
                  <mat-icon>expand_more</mat-icon>
                </button>
                Generate Document
              </div>
            </div>

            <div [@customExpansionDetails]="generateDocument == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
              <mat-table class="tableWithinExpansV2" style="zoom: 1 !important;" [dataSource]="availableDocs">
                <ng-container matColumnDef="fileName">
                  <mat-header-cell *matHeaderCellDef> Document Name </mat-header-cell>
                  <mat-cell *matCellDef="let data">
                    {{ data.name }}
                    <mat-icon *ngIf="data.isNewTemplate" style="margin-left: 10px; color: #ce983a;">grade</mat-icon>
                    <mat-icon *ngIf="data.name.startsWith('ATOL Certificate') && bookingData.underATOL != 'T-ATOL Protection' && userType == 'memberManager'"
                    matTooltip="To print this document, please ensure that 'T-ATOL Protection' is selected in the 'ATOL Protected' option located in the Summary tab"
                    matTooltipClass="line-normal-tooltip" style="margin-left: 10px; color: #ce983a;">
                      question_mark
                    </mat-icon>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="lastModified">
                  <mat-header-cell *matHeaderCellDef> Version Date </mat-header-cell>
                  <mat-cell *matCellDef="let data"> {{ data.lastModified | date : "dd.MM.y" }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="version">
                  <mat-header-cell *matHeaderCellDef> Version </mat-header-cell>
                  <mat-cell *matCellDef="let data"> {{ data.version }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="download">
                  <mat-header-cell *matHeaderCellDef></mat-header-cell>
                  <mat-cell *matCellDef="let data">
                    <div style="display: flex; width: 100%; justify-content: center;">
                      <span *ngIf="!data.name.startsWith('ATOL Certificate')">
                        <button class="matIcon" type="button" mat-mini-fab color="accent" style="margin-right: 10px;"
                        [class.mat-elevation-z0]="true" [disabled]="userType != 'memberManager'" (click)="docTemplateSettings(data.tempRef, data.name, data.isNewTemplate)">
                          <mat-icon>settings</mat-icon>
                        </button>
                      </span>

                      <span *ngIf="!data.name.startsWith('ATOL Certificate')">
                        <button class="matIcon" type="button" mat-mini-fab color="primary" (click)="printDocument(data.tempRef, data.name, null, 'no')" [class.mat-elevation-z0]="true">
                          <mat-icon>print</mat-icon>
                        </button>
                      </span>

                      <span *ngIf="data.name.startsWith('ATOL Certificate')">
                        <button class="matIcon" type="button" mat-mini-fab color="primary" (click)="generateATOLpdf(data.tempRef)" [class.mat-elevation-z0]="true"
                                [disabled]="userType != 'memberManager' || bookingData.underATOL != 'T-ATOL Protection'">
                          <mat-icon>print</mat-icon>
                        </button>
                      </span>

                      <span *ngIf="!data.name.startsWith('ATOL Certificate')">
                        <button class="matIcon" type="button" mat-mini-fab color="primary" style="margin-left: 10px;"
                        [class.mat-elevation-z0]="true" [disabled]="userType != 'memberManager' && userType != 'memberStaff'" (click)="printDocument(data.tempRef, data.name, null, 'yes')">
                          <mat-icon>email</mat-icon>
                        </button>
                      </span>
                    </div>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="generateDocCol"></mat-header-row>
                <mat-row class="rowNotClick" *matRowDef="let row; columns : generateDocCol;"></mat-row>
              </mat-table>
            </div>

            <div [@customExpansionDetails]="generateDocument == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="generateDocument = true;">
              <mat-icon>expand_more</mat-icon>
            </div>
          </div>

          <div [@inAnimation] *ngIf="notesView && bookingData?.bookingStatus && !bookingData?.status">
            <div fxLayout="row" fxLayoutAlign="start center">
              <h1>
                <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Notes
              </h1>
              <button style="margin-left: 25px;" mat-stroked-button color="primary" (click)="reloadBooking('notesView')" matTooltip="Reload Booking" matTooltipClass="line-normal-tooltip">
                <mat-icon>sync</mat-icon>
              </button>
            </div>

            <div class="divider">
              <mat-divider></mat-divider>
            </div>

            <div class="ownExpansionHeader">
              <div class="headerTopLeft">
                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="bookingNotesExp" (click)="bookingNotesExp = false;">
                  <mat-icon>expand_less</mat-icon>
                </button>
                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!bookingNotesExp" (click)="bookingNotesExp = true;">
                  <mat-icon>expand_more</mat-icon>
                </button>
                Booking Notes
              </div>
            </div>

            <div [@customExpansionDetails]="bookingNotesExp == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
              <mat-table *ngIf="bookingNotes.data.length > 0" class="tableWithinExpansV2" style="zoom: 1 !important;" [dataSource]="bookingNotes">
                <ng-container matColumnDef="createdAt">
                  <mat-header-cell *matHeaderCellDef> Created At </mat-header-cell>
                  <mat-cell *matCellDef="let data">
                    <mat-icon *ngIf="data.important === 'yes'" style="color: red; margin-right: 5px;">
                      priority_high
                    </mat-icon>
                    {{ data.createTS | date : "dd.MM.y hh:mm a" }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="notes">
                  <mat-header-cell *matHeaderCellDef> Note </mat-header-cell>
                  <mat-cell *matCellDef="let data" style="white-space: pre-line; word-break: break-word; padding-top: 5px; padding-bottom: 5px;">
                    {{ data.note }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="operations">
                  <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                  <mat-cell *matCellDef="let data" style="padding-right: 0px !important;">
                    <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-evenly;" *ngIf="['memberStaff', 'memberManager'].includes(userType)">
                      <div>
                        <span class="custom-tooltip" *ngIf="data.showOnDocs === 'no'">
                          <button type="button" mat-icon-button color="primary" (click)="editBookingNote(data, 'to show on documents')">
                            <mat-icon>visibility</mat-icon>
                          </button>
                          <span class="tooltip-text">Display</span>
                        </span>

                        <span class="custom-tooltip" *ngIf="data.showOnDocs === 'yes'">
                          <button type="button" mat-icon-button color="primary" (click)="editBookingNote(data, 'to not show on documents')">
                            <mat-icon>visibility_off</mat-icon>
                          </button>
                          <span class="tooltip-text">Hide</span>
                        </span>
                      </div>
                      
                      <div>
                        <span class="custom-tooltip" *ngIf="data.important === 'no'">
                          <button type="button" mat-icon-button color="primary" (click)="editBookingNote(data, 'as important')">
                            <mat-icon>priority_high</mat-icon>
                          </button>
                          <span class="tooltip-text">Prioritise</span>
                        </span>

                        <span class="custom-tooltip" *ngIf="data.important === 'yes'">
                          <button type="button" mat-icon-button color="primary" (click)="editBookingNote(data, 'as non important')">
                            <mat-icon>low_priority</mat-icon>
                          </button>
                          <span class="tooltip-text">Deprioritise</span>
                        </span>
                      </div>
                      <div>
                        <span class="custom-tooltip">
                          <button type="button" mat-icon-button color="primary" (click)="editBookingNote(data, 'as archived')">
                            <mat-icon>delete_forever</mat-icon>
                          </button>
                          <span class="tooltip-text">Remove</span>
                        </span>
                      </div>
                    </div>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="bkgEleNotesCol"></mat-header-row>
                <mat-row class="rowNotClick" *matRowDef="let row; columns : bkgEleNotesCol;"
                [ngStyle]="{'background-color': row.important == 'no' ? '#F9F9F9' : '#DDDDDD'}"></mat-row>
              </mat-table>

              <div [@inAnimation] *ngIf="bookingNotes.data.length == 0" style="clear: both;">
                <h3>There are no notes to show</h3>
              </div>

              <div *ngIf="userType == 'memberManager' || userType == 'memberStaff'" style="margin-right: 22.5px; margin-top: 10px;">
                <form ngNativeValidate #addBookingNoteForm="ngForm" (ngSubmit)="addBookingNote(addBookingNoteForm)">
                  <mat-form-field style="width: 100%; max-width: unset;" class="notes" appearance="outline">
                    <mat-label>Add New Booking Note</mat-label>
                    <textarea style="min-width: 350px; overflow: hidden;" matInput maxlength="500" cdkTextareaAutosize ngModel name="comment" #comment="ngModel"
                    #autosize="cdkTextareaAutosize" required cdkAutosizeMinRows="5" cdkAutosizeMaxRows="25"></textarea>
                  </mat-form-field>
                  <div style="display: flex; margin-bottom: 10px; align-items: center; justify-content: space-between;">
                    <div>
                      <button color="primary" mat-raised-button class="buttonClass" type="submit" [disabled]="addBookingNoteForm.invalid" style="margin: 0px;">
                        Submit
                      </button>
                    </div>
                    <div style="display: flex; width: 325px; justify-content: space-between;">
                      <div style="display: flex; align-items: center;">
                        <span style="font-weight: 600; margin-right: 12px;">Show on documents</span>
                        <mat-checkbox name="showOnDocs" ngModel #showOnDocs="ngModel" [(ngModel)]="newNoteOptions.showOnDocs" color="accent">
                        </mat-checkbox>
                      </div>
                      <div style="display: flex; align-items: center;">
                        <span style="font-weight: 600; margin-right: 12px;">Important</span>
                        <mat-checkbox name="important" ngModel #important="ngModel" [(ngModel)]="newNoteOptions.important" color="accent">
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div [@customExpansionDetails]="bookingNotesExp == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="bookingNotesExp = true;">
              <mat-icon>expand_more</mat-icon>
            </div>


            <div class="ownExpansionHeader" style="margin-top: 50px;">
              <div class="headerTopLeft">
                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="elementNotesExp" (click)="elementNotesExp = false;">
                  <mat-icon>expand_less</mat-icon>
                </button>
                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!elementNotesExp" (click)="elementNotesExp = true;">
                  <mat-icon>expand_more</mat-icon>
                </button>
                Element Notes
              </div>
            </div>

            <div [@customExpansionDetails]="elementNotesExp == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
              <mat-table *ngIf="elementNotes.data.length > 0" class="tableWithinExpansV2" style="zoom: 1 !important;" [dataSource]="elementNotes">
                <ng-container matColumnDef="createdAt">
                  <mat-header-cell *matHeaderCellDef> Supplier Name </mat-header-cell>
                  <mat-cell *matCellDef="let data"> {{ data.supplierName }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="notes">
                  <mat-header-cell *matHeaderCellDef> Note </mat-header-cell>
                  <mat-cell *matCellDef="let data"> {{ data.note }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="operations">
                  <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                  <mat-cell *matCellDef="let data"> </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="bkgEleNotesCol"></mat-header-row>
                <mat-row class="rowNotClick" *matRowDef="let row; columns : bkgEleNotesCol;"></mat-row>
              </mat-table>

              <div [@inAnimation] *ngIf="elementNotes.data.length == 0" style="clear: both;">
                <h3>There are no notes to show</h3>
              </div>
            </div>

            <div [@customExpansionDetails]="elementNotesExp == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="elementNotesExp = true;">
              <mat-icon>expand_more</mat-icon>
            </div>
          </div>

          <div [@inAnimation] *ngIf="historyView && bookingData?.bookingStatus && !bookingData?.status">
            <div fxLayout="row" fxLayoutAlign="start center">
              <h1>
                <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>History
              </h1>
              <button style="margin-left: 25px;" mat-stroked-button color="primary" (click)="reloadBooking('historyView')" matTooltip="Reload Booking" matTooltipClass="line-normal-tooltip">
                <mat-icon>sync</mat-icon>
              </button>
            </div>

            <div class="divider">
              <mat-divider></mat-divider>
            </div>
            
            <div class="ownExpansionHeader" style="margin-top: 50px;">
              <div class="headerTopLeft">
                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="bookingHistoryExp" (click)="bookingHistoryExp = false;">
                  <mat-icon>expand_less</mat-icon>
                </button>
                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!bookingHistoryExp" (click)="bookingHistoryExp = true;">
                  <mat-icon>expand_more</mat-icon>
                </button>
                Booking History
              </div>
            </div>

            <div [@customExpansionDetails]="bookingHistoryExp == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
              <mat-table *ngIf="bookingHistory.data.length > 0" class="tableWithinExpansV2" style="zoom: 1 !important;" [dataSource]="bookingHistory">
                <ng-container matColumnDef="historyDate">
                  <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                  <mat-cell *matCellDef="let data"> {{ data.when | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="entity">
                  <mat-header-cell *matHeaderCellDef> Entity </mat-header-cell>
                  <mat-cell *matCellDef="let data">
                    <span *ngIf="data.type == 'Booking'">Booking</span>
                    <span *ngIf="data.type == 'Documents'">Document</span>
                    <span *ngIf="data.type == 'Passengers'">Passenger</span>
                    <span *ngIf="data.type == 'Element'">{{ data.where }}</span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="description">
                  <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
                  <mat-cell *matCellDef="let data">
                    <div *ngIf="data.type != 'Documents' && data.type != 'Passengers'">
                      <p *ngFor="let hist of data.changes">
                        <mat-icon *ngIf="hist.dataType == 'number'" style="margin-right: 5px;">price_change</mat-icon>
                        <mat-icon *ngIf="hist.dataType == 'deposit'" style="margin-right: 5px;">savings</mat-icon>
                        <mat-icon *ngIf="hist.dataType == 'payment'" style="margin-right: 5px;">payment</mat-icon>
                        <mat-icon *ngIf="hist.dataType == 'date'" style="margin-right: 5px;">calendar_month</mat-icon>
                        <mat-icon *ngIf="hist.dataType == 'string'" style="margin-right: 5px;">sync_alt</mat-icon>
                        <b>{{ hist.attribute }}</b>
                        <span *ngIf="hist.from != null">
                          changed from 
                          <span *ngIf="['number', 'payment', 'deposit'].includes(hist.dataType)">{{ hist.from | currency:'GBP' }}</span>
                          <span *ngIf="hist.dataType == 'date'">{{ hist.from | date:"dd.MM.yyyy" }}</span>
                          <span *ngIf="hist.dataType == 'string'">{{ hist.from | titlecase }}</span>
                          to 
                        </span>
                        <span *ngIf="hist.from == null"> set to </span>
                        <span *ngIf="['number', 'payment', 'deposit'].includes(hist.dataType)">{{ hist.to | currency:'GBP' }}</span>
                        <span *ngIf="hist.dataType == 'date'">{{ hist.to | date:"dd.MM.yyyy" }}</span>
                        <span *ngIf="hist.dataType == 'string'">{{ hist.to | titlecase }}</span>
                      </p>
                    </div>
                    <div *ngIf="data.type == 'Documents'">
                      <mat-icon *ngIf="data.changes.actionType == 'print'" style="margin-right: 5px;">print</mat-icon>
                      <mat-icon *ngIf="data.changes.actionType != 'print'" style="margin-right: 5px;">email</mat-icon>
                      <span>{{ data.changes.actionDesc }}</span>
                    </div>
                    <div *ngIf="data.type == 'Passengers'">
                      <mat-icon *ngIf="data.changes.actionType == 'paxAdd'" style="margin-right: 5px;">person_add</mat-icon>
                      <mat-icon *ngIf="data.changes.actionType == 'paxRemove'" style="margin-right: 5px;">person_remove</mat-icon>
                      <mat-icon *ngIf="data.changes.actionType == 'paxBack'" style="margin-right: 5px;">person</mat-icon>
                      <mat-icon *ngIf="data.changes.actionType == 'paxCancel'" style="margin-right: 5px;">person_off</mat-icon>
                      <mat-icon *ngIf="data.changes.actionType == 'paxLead' || data.changes.actionType == 'paxGhost'" style="margin-right: 5px;">manage_accounts</mat-icon>
                      <span>{{ data.changes.actionDesc }}</span>
                    </div>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="historyColumns"></mat-header-row>
                <mat-row class="rowNotClick" *matRowDef="let row; columns : historyColumns;"></mat-row>
              </mat-table>

              <div [@inAnimation] *ngIf="bookingHistory.data.length == 0" style="clear: both;">
                <h3>There is no booking history recorded yet</h3>
              </div>
            </div>

            <div [@customExpansionDetails]="bookingHistoryExp == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="bookingHistoryExp = true;">
              <mat-icon>expand_more</mat-icon>
            </div>
          </div>

          <div class="accessDenied" *ngIf="pageLoaded && !bookingAccess">
            <div class="errorMessage">
              <h2 style="text-align: center; margin-top: 2.5%;">Access denied</h2>
            </div>
          </div>

          <div class="accessDenied" *ngIf="pageLoaded && !bookingExists">
            <div class="errorMessage">
              <h2 style="text-align: center; margin-top: 2.5%;">Unable to locate booking</h2>
            </div>
          </div>
        </div>
      </mat-card>

    </mat-sidenav-content>
  </mat-sidenav-container>
</mat-card-content>

<ng-template #myDialog>
  <div *ngIf="errorMessage != ''" class="errorMessage">
    <p>{{errorMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>

  <div *ngIf="successMessage != ''" class="successMessage">
    <p>{{successMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>
</ng-template>

<ng-template #chartDialog>
  <mat-dialog-content style="min-width: 350px;">
    <div>
      <div>
        <h1 style="margin-top: 0px; display: inline;">{{ chartSelected.costing.supplierName }}</h1>
        <h3 style="margin-top: 0px;">
          <i>
            <span>{{ chartSelected.costing.supplierReference }}</span>
          </i>
        </h3>

        <h2 style="margin-bottom: 0px; display: inline;">Supplier Status</h2>
        <h3 *ngIf="userType == 'memberManager' || userType == 'memberStaff'" style="margin-bottom: 0px; margin-top: 6px;">
          <mat-radio-group color="primary" (change)="changeElementStatus(chartSelected.costing, null, null, $event.value, 'mainElement', 'graphsView')">
            <mat-radio-button *ngIf="chartSelected.costing.elementStatus == 'enquiry'" [checked]="true" style="margin-right: 15px;" value="enquiry"><span style="color: #ce983a;">Enquiry</span></mat-radio-button>

            <mat-radio-button *ngIf="chartSelected.costing.elementStatus == 'booking'" [checked]="true" style="margin-right: 15px;" value="booking"><span style="color: #4D5FD1;">Booked</span></mat-radio-button>
            <mat-radio-button *ngIf="chartSelected.costing.elementStatus != 'booking'" [checked]="false" style="margin-right: 15px;" value="booking"><span>Booked</span></mat-radio-button>

            <mat-radio-button *ngIf="chartSelected.costing.elementStatus == 'cancelled'" [checked]="true" style="margin-right: 15px;" value="cancelled"><span style="color: #a6a6a6;">Cancelled</span></mat-radio-button>
            <mat-radio-button *ngIf="chartSelected.costing.elementStatus != 'cancelled'" [checked]="false" style="margin-right: 15px;" value="cancelled"><span>Cancelled</span></mat-radio-button>
          </mat-radio-group>
        </h3>

        <h3 *ngIf="userType != 'memberManager' && userType != 'memberStaff'" style="margin-bottom: 0px; margin-top: 6px;">
          {{ chartSelected.costing.elementStatus | titlecase }}
        </h3>
        <br>
      </div>

      <h2 style="margin-top: 0px;">{{ chartSelected.niceType }} Details</h2>
      <mat-divider></mat-divider>

      <div style="font-size: 15px;">
        <div *ngIf="chartSelected.type === 'flight'">

          <div style="clear: both;">
            <h3>
              <div fxLayout="column" fxLayoutAlign="center stretch">
                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Flight Status</div>
                  <div fxFlex="60" style="margin: auto;">
                    <span class="dialogCostInfo" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                      <mat-radio-group color="primary" (change)="changeElementStatus(chartSelected.costing, chartSelected.element, 'flight', $event.value, 'subElement', 'graphsView')">
                        <mat-radio-button *ngIf="chartSelected.element.flightStatus == 'enquiry'" [checked]="true" style="margin-right: 15px;" value="enquiry"><span style="color: #ce983a;">Enquiry</span></mat-radio-button>

                        <mat-radio-button *ngIf="chartSelected.element.flightStatus == 'booking'" [checked]="true" style="margin-right: 15px;" value="booking"><span style="color: #4D5FD1;">Booked</span></mat-radio-button>
                        <mat-radio-button *ngIf="chartSelected.element.flightStatus != 'booking'" [checked]="false" style="margin-right: 15px;" value="booking"><span>Booked</span></mat-radio-button>

                        <mat-radio-button *ngIf="chartSelected.element.flightStatus == 'cancelled'" [checked]="true" style="margin-right: 15px;" value="cancelled"><span style="color: #a6a6a6;">Cancelled</span></mat-radio-button>
                        <mat-radio-button *ngIf="chartSelected.element.flightStatus != 'cancelled'" [checked]="false" style="margin-right: 15px;" value="cancelled"><span>Cancelled</span></mat-radio-button>
                      </mat-radio-group>
                    </span>
                    <span class="dialogCostInfo" *ngIf="userType != 'memberManager' && userType != 'memberStaff'">
                      {{ chartSelected.element.flightStatus | titlecase }}
                    </span>
                  </div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Departure</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.departAirCode }} {{ chartSelected.element.departAirName }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;"></div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{chartSelected.element.departDateTime | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Arrival</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.destinAirCode }} {{ chartSelected.element.arriveAirName }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;"></div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{chartSelected.element.arriveDateTime | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Carrier</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.carrier | titlecase }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Direction</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.direction | titlecase }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Class</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.flightClass }}</span></div>
                </div>
              </div>
            </h3>
          </div>
        </div>

        <div *ngIf="chartSelected.type === 'accommodation'">

          <div style="clear: both;">
            <mat-divider></mat-divider>
            <h3>
              <div fxLayout="column" fxLayoutAlign="center stretch">
                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Accommodation Status</div>
                  <div fxFlex="60" style="margin: auto;">
                    <span class="dialogCostInfo" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                      <mat-radio-group color="primary" (change)="changeElementStatus(chartSelected.costing, chartSelected.element, 'accommodation', $event.value, 'subElement', 'graphsView')">
                        <mat-radio-button *ngIf="chartSelected.element.accomStatus == 'enquiry'" [checked]="true" style="margin-right: 15px;" value="enquiry"><span style="color: #ce983a;">Enquiry</span></mat-radio-button>

                        <mat-radio-button *ngIf="chartSelected.element.accomStatus == 'booking'" [checked]="true" style="margin-right: 15px;" value="booking"><span style="color: #4D5FD1;">Booked</span></mat-radio-button>
                        <mat-radio-button *ngIf="chartSelected.element.accomStatus != 'booking'" [checked]="false" style="margin-right: 15px;" value="booking"><span>Booked</span></mat-radio-button>

                        <mat-radio-button *ngIf="chartSelected.element.accomStatus == 'cancelled'" [checked]="true" style="margin-right: 15px;" value="cancelled"><span style="color: #a6a6a6;">Cancelled</span></mat-radio-button>
                        <mat-radio-button *ngIf="chartSelected.element.accomStatus != 'cancelled'" [checked]="false" style="margin-right: 15px;" value="cancelled"><span>Cancelled</span></mat-radio-button>
                      </mat-radio-group>
                    </span>
                    <span class="dialogCostInfo" *ngIf="userType != 'memberManager' && userType != 'memberStaff'">
                      {{ chartSelected.element.accomStatus | titlecase }}
                    </span>
                  </div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Check-In Date</div>
                  <div fxFlex="70" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.checkInDate | date:'dd.MM.yyyy'}}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Nights</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.numNights }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Accommodation Name</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.accomName }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Address</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.address }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">City</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.city }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Country</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.country }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Rating</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.rating }}</span></div>
                </div>

                <div style="width: 100%;">
                  <h2 style="float: left;">Room {{ addnDataView + 1 }} Details</h2>
                  <span style="float: right; margin-top: 2.5px;">
                    <button [disabled]="addnDataView <= 0" class="addRemoveButtons"  mat-stroked-button color="primary" (click)="addnDataView = addnDataView - 1" type="button">
                      <mat-icon>navigate_before</mat-icon>
                    </button>
                    <button [disabled]="addnDataView >= chartSelected.element.addndata.length - 1" class="addRemoveButtons" mat-stroked-button color="primary" (click)="addnDataView = addnDataView + 1" type="button">
                      <mat-icon>navigate_next</mat-icon>
                    </button>
                  </span>
                </div>
                
                <mat-divider style="clear: both;"></mat-divider>
                <br>
                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Room Types</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.addndata[addnDataView]?.valueStr1 }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Room Views</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.addndata[addnDataView]?.valueStr2 }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Board Basis</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.addndata[addnDataView]?.valueStr3 }}</span></div>
                </div>
              </div>
            </h3>
          </div>
        </div>

        <div *ngIf="chartSelected.type === 'carHire'">

          <div style="clear: both;">
            <mat-divider></mat-divider>
            <h3>
              <div fxLayout="column" fxLayoutAlign="center stretch">
                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Car Hire Status</div>
                  <div fxFlex="60" style="margin: auto;">
                    <span class="dialogCostInfo" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                      <mat-radio-group color="primary" (change)="changeElementStatus(chartSelected.costing, chartSelected.element, 'carHire', $event.value, 'subElement', 'graphsView')">
                        <mat-radio-button *ngIf="chartSelected.element.carHireStatus == 'enquiry'" [checked]="true" style="margin-right: 15px;" value="enquiry"><span style="color: #ce983a;">Enquiry</span></mat-radio-button>

                        <mat-radio-button *ngIf="chartSelected.element.carHireStatus == 'booking'" [checked]="true" style="margin-right: 15px;" value="booking"><span style="color: #4D5FD1;">Booked</span></mat-radio-button>
                        <mat-radio-button *ngIf="chartSelected.element.carHireStatus != 'booking'" [checked]="false" style="margin-right: 15px;" value="booking"><span>Booked</span></mat-radio-button>

                        <mat-radio-button *ngIf="chartSelected.element.carHireStatus == 'cancelled'" [checked]="true" style="margin-right: 15px;" value="cancelled"><span style="color: #a6a6a6;">Cancelled</span></mat-radio-button>
                        <mat-radio-button *ngIf="chartSelected.element.carHireStatus != 'cancelled'" [checked]="false" style="margin-right: 15px;" value="cancelled"><span>Cancelled</span></mat-radio-button>
                      </mat-radio-group>
                    </span>
                    <span class="dialogCostInfo" *ngIf="userType != 'memberManager' && userType != 'memberStaff'">
                      {{ chartSelected.element.carHireStatus | titlecase }}
                    </span>
                  </div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Pick Up Date</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.pickUpDate | date:'dd.MM.yyyy'}}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Location</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.pickUpLocation }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Drop Off Date</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.dropOffDate | date:'dd.MM.yyyy'}}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Location</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.dropOffLocation }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Car Type</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.carType }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Child Seats</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.childseats }}</span></div>
                </div>
              </div>
            </h3>
          </div>
        </div>

        <div *ngIf="chartSelected.type === 'carParking'">

          <div style="clear: both;">
            <mat-divider></mat-divider>
            <h3>
              <div fxLayout="column" fxLayoutAlign="center stretch">
                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Car Park Status</div>
                  <div fxFlex="60" style="margin: auto;">
                    <span class="dialogCostInfo" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                      <mat-radio-group color="primary" (change)="changeElementStatus(chartSelected.costing, chartSelected.element, 'carParking', $event.value, 'subElement', 'graphsView')">
                        <mat-radio-button *ngIf="chartSelected.element.carparkStatus == 'enquiry'" [checked]="true" style="margin-right: 15px;" value="enquiry"><span style="color: #ce983a;">Enquiry</span></mat-radio-button>

                        <mat-radio-button *ngIf="chartSelected.element.carparkStatus == 'booking'" [checked]="true" style="margin-right: 15px;" value="booking"><span style="color: #4D5FD1;">Booked</span></mat-radio-button>
                        <mat-radio-button *ngIf="chartSelected.element.carparkStatus != 'booking'" [checked]="false" style="margin-right: 15px;" value="booking"><span>Booked</span></mat-radio-button>

                        <mat-radio-button *ngIf="chartSelected.element.carparkStatus == 'cancelled'" [checked]="true" style="margin-right: 15px;" value="cancelled"><span style="color: #a6a6a6;">Cancelled</span></mat-radio-button>
                        <mat-radio-button *ngIf="chartSelected.element.carparkStatus != 'cancelled'" [checked]="false" style="margin-right: 15px;" value="cancelled"><span>Cancelled</span></mat-radio-button>
                      </mat-radio-group>
                    </span>
                    <span class="dialogCostInfo" *ngIf="userType != 'memberManager' && userType != 'memberStaff'">
                      {{ chartSelected.element.carparkStatus | titlecase }}
                    </span>
                  </div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Parking Starts</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.startDate | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Parking Ends</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.endDate | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Location</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.location }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Parking Name</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.carParkName }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Parking Code</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.carParkCode }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Car Make</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.carMake }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Car Model</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.carModel }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Registration</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.carReg }}</span></div>
                </div>
              </div>
            </h3>
          </div>
        </div>

        <div *ngIf="chartSelected.type === 'attraction'">

          <div style="clear: both;">
            <mat-divider></mat-divider>
            <h3>
              <div fxLayout="column" fxLayoutAlign="center stretch">
                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Attraction Status</div>
                  <div fxFlex="60" style="margin: auto;">
                    <span class="dialogCostInfo" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                      <mat-radio-group color="primary" (change)="changeElementStatus(chartSelected.costing, chartSelected.element, 'attraction', $event.value, 'subElement', 'graphsView')">
                        <mat-radio-button *ngIf="chartSelected.element.attractionStatus == 'enquiry'" [checked]="true" style="margin-right: 15px;" value="enquiry"><span style="color: #ce983a;">Enquiry</span></mat-radio-button>

                        <mat-radio-button *ngIf="chartSelected.element.attractionStatus == 'booking'" [checked]="true" style="margin-right: 15px;" value="booking"><span style="color: #4D5FD1;">Booked</span></mat-radio-button>
                        <mat-radio-button *ngIf="chartSelected.element.attractionStatus != 'booking'" [checked]="false" style="margin-right: 15px;" value="booking"><span>Booked</span></mat-radio-button>

                        <mat-radio-button *ngIf="chartSelected.element.attractionStatus == 'cancelled'" [checked]="true" style="margin-right: 15px;" value="cancelled"><span style="color: #a6a6a6;">Cancelled</span></mat-radio-button>
                        <mat-radio-button *ngIf="chartSelected.element.attractionStatus != 'cancelled'" [checked]="false" style="margin-right: 15px;" value="cancelled"><span>Cancelled</span></mat-radio-button>
                      </mat-radio-group>
                    </span>
                    <span class="dialogCostInfo" *ngIf="userType != 'memberManager' && userType != 'memberStaff'">
                      {{ chartSelected.element.attractionStatus | titlecase }}
                    </span>
                  </div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Start Date</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.startDateTime | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">End Date</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.endDateTime | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Attraction Name</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.name }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Location</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.location }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Country</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.country }}</span></div>
                </div>
              </div>
            </h3>
          </div>
        </div>

        <div *ngIf="chartSelected.type === 'cruise'">

          <div style="clear: both;">
            <mat-divider></mat-divider>
            <h3>
              <div fxLayout="column" fxLayoutAlign="center stretch">
                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Cruise Status</div>
                  <div fxFlex="60" style="margin: auto;">
                    <span class="dialogCostInfo" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                      <mat-radio-group color="primary" (change)="changeElementStatus(chartSelected.costing, chartSelected.element, 'cruise', $event.value, 'subElement', 'graphsView')">
                        <mat-radio-button *ngIf="chartSelected.element.cruiseStatus == 'enquiry'" [checked]="true" style="margin-right: 15px;" value="enquiry"><span style="color: #ce983a;">Enquiry</span></mat-radio-button>

                        <mat-radio-button *ngIf="chartSelected.element.cruiseStatus == 'booking'" [checked]="true" style="margin-right: 15px;" value="booking"><span style="color: #4D5FD1;">Booked</span></mat-radio-button>
                        <mat-radio-button *ngIf="chartSelected.element.cruiseStatus != 'booking'" [checked]="false" style="margin-right: 15px;" value="booking"><span>Booked</span></mat-radio-button>

                        <mat-radio-button *ngIf="chartSelected.element.cruiseStatus == 'cancelled'" [checked]="true" style="margin-right: 15px;" value="cancelled"><span style="color: #a6a6a6;">Cancelled</span></mat-radio-button>
                        <mat-radio-button *ngIf="chartSelected.element.cruiseStatus != 'cancelled'" [checked]="false" style="margin-right: 15px;" value="cancelled"><span>Cancelled</span></mat-radio-button>
                      </mat-radio-group>
                    </span>
                    <span class="dialogCostInfo" *ngIf="userType != 'memberManager' && userType != 'memberStaff'">
                      {{ chartSelected.element.cruiseStatus | titlecase }}
                    </span>
                  </div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Departure Date</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.deptDate | date:'dd.MM.yyyy'}}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Duration</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.duration }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Cruise Name & Code</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.cruiseName }} <span *ngIf="chartSelected.element.voyageCode != ''">({{ chartSelected.element.voyageCode }})</span></span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Cruise Line & Ship</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.cruiseLine }} <span *ngIf="chartSelected.element.cruiseShip != ''">({{ chartSelected.element.cruiseShip }})</span></span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Departure Port</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.departurePort }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Visiting Port</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.visitingPort }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Disembark Port</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.disembarkPort }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Region</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.region }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">On Board Credit</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">
                  <span *ngIf="chartSelected.element.onBoardCreditCur !== ''">
                    {{ chartSelected.element.onBoardCreditVal | currency: chartSelected.element.onBoardCreditCur }}
                  </span>
                  <span *ngIf="chartSelected.element.onBoardCreditCur === ''">
                    {{ chartSelected.element.onBoardCreditVal }}
                  </span>
                  </span></div>
                </div>
                
                <div style="width: 100%;">
                  <h2 style="float: left;">Cabin {{ addnDataView + 1 }} Details</h2>
                  <span style="float: right; margin-top: 2.5px;">
                    <button [disabled]="addnDataView <= 0" class="addRemoveButtons"  mat-stroked-button color="primary" (click)="addnDataView = addnDataView - 1" type="button">
                      <mat-icon>navigate_before</mat-icon>
                    </button>
                    <button [disabled]="addnDataView >= chartSelected.element.addndata.length - 1" class="addRemoveButtons" mat-stroked-button color="primary" (click)="addnDataView = addnDataView + 1" type="button">
                      <mat-icon>navigate_next</mat-icon>
                    </button>
                  </span>
                </div>
                
                <mat-divider style="clear: both;"></mat-divider>
                <br>
                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Name & Number</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.addndata[addnDataView]?.valueStr1 }}
                    <span *ngIf="chartSelected.element.addndata[addnDataView]?.valueStr3 != ''">({{ chartSelected.element.addndata[addnDataView]?.valueStr3 }})</span>
                  </span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Deck & Grade</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.addndata[addnDataView]?.valueStr4 }}
                    <span *ngIf="chartSelected.element.addndata[addnDataView]?.valueStr2 != ''">({{ chartSelected.element.addndata[addnDataView]?.valueStr2 }})</span>
                  </span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Cabin Position</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.addndata[addnDataView]?.valueStr6 }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Ship Side</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.addndata[addnDataView]?.valueStr5 }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Bed Type</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.addndata[addnDataView]?.valueStr7 }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Seating</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.addndata[addnDataView]?.valueStr8 }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Table Size</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.addndata[addnDataView]?.valueStr9 }}</span></div>
                </div>
              </div>
            </h3>
          </div>
        </div>

        <div *ngIf="chartSelected.type === 'miscellaneous'">

          <div style="clear: both;">
            <mat-divider></mat-divider>
            <h3>
              <div fxLayout="column" fxLayoutAlign="center stretch">
                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Miscellaneous Status</div>
                  <div fxFlex="60" style="margin: auto;">
                    <span class="dialogCostInfo" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                      <mat-radio-group color="primary" (change)="changeElementStatus(chartSelected.costing, chartSelected.element, 'miscellaneous', $event.value, 'subElement', 'graphsView')">
                        <mat-radio-button *ngIf="chartSelected.element.miscStatus == 'enquiry'" [checked]="true" style="margin-right: 15px;" value="enquiry"><span style="color: #ce983a;">Enquiry</span></mat-radio-button>

                        <mat-radio-button *ngIf="chartSelected.element.miscStatus == 'booking'" [checked]="true" style="margin-right: 15px;" value="booking"><span style="color: #4D5FD1;">Booked</span></mat-radio-button>
                        <mat-radio-button *ngIf="chartSelected.element.miscStatus != 'booking'" [checked]="false" style="margin-right: 15px;" value="booking"><span>Booked</span></mat-radio-button>

                        <mat-radio-button *ngIf="chartSelected.element.miscStatus == 'cancelled'" [checked]="true" style="margin-right: 15px;" value="cancelled"><span style="color: #a6a6a6;">Cancelled</span></mat-radio-button>
                        <mat-radio-button *ngIf="chartSelected.element.miscStatus != 'cancelled'" [checked]="false" style="margin-right: 15px;" value="cancelled"><span>Cancelled</span></mat-radio-button>
                      </mat-radio-group>
                    </span>
                    <span class="dialogCostInfo" *ngIf="userType != 'memberManager' && userType != 'memberStaff'">
                      {{ chartSelected.element.miscStatus | titlecase }}
                    </span>
                  </div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Start Date</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.startDateTime | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">End Date</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.endDateTime | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Misc Name</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.name }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Location</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.location }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Country</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.country }}</span></div>
                </div>
              </div>
            </h3>
          </div>
        </div>

        <div *ngIf="chartSelected.type === 'train'">

          <div style="clear: both;">
            <mat-divider></mat-divider>
            <h3>
              <div fxLayout="column" fxLayoutAlign="center stretch">
                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Train Status</div>
                  <div fxFlex="60" style="margin: auto;">
                    <span class="dialogCostInfo" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                      <mat-radio-group color="primary" (change)="changeElementStatus(chartSelected.costing, chartSelected.element, 'train', $event.value, 'subElement', 'graphsView')">
                        <mat-radio-button *ngIf="chartSelected.element.trainStatus == 'enquiry'" [checked]="true" style="margin-right: 15px;" value="enquiry"><span style="color: #ce983a;">Enquiry</span></mat-radio-button>

                        <mat-radio-button *ngIf="chartSelected.element.trainStatus == 'booking'" [checked]="true" style="margin-right: 15px;" value="booking"><span style="color: #4D5FD1;">Booked</span></mat-radio-button>
                        <mat-radio-button *ngIf="chartSelected.element.trainStatus != 'booking'" [checked]="false" style="margin-right: 15px;" value="booking"><span>Booked</span></mat-radio-button>

                        <mat-radio-button *ngIf="chartSelected.element.trainStatus == 'cancelled'" [checked]="true" style="margin-right: 15px;" value="cancelled"><span style="color: #a6a6a6;">Cancelled</span></mat-radio-button>
                        <mat-radio-button *ngIf="chartSelected.element.trainStatus != 'cancelled'" [checked]="false" style="margin-right: 15px;" value="cancelled"><span>Cancelled</span></mat-radio-button>
                      </mat-radio-group>
                    </span>
                    <span class="dialogCostInfo" *ngIf="userType != 'memberManager' && userType != 'memberStaff'">
                      {{ chartSelected.element.trainStatus | titlecase }}
                    </span>
                  </div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Departure</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.departStation }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;"></div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{chartSelected.element.departDateTime | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Arrival</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.arriveStation }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;"></div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{chartSelected.element.arriveDateTime | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Train Name / Number</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.trainNameNo }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Carriage Class</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.carriageClass }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Seat Number</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.seat }}</span></div>
                </div>

              </div>
            </h3>
          </div>
        </div>

        <div *ngIf="chartSelected.type === 'transfer'">

          <div style="clear: both;">
            <mat-divider></mat-divider>
            <h3>
              <div fxLayout="column" fxLayoutAlign="center stretch">
                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Transfer Status</div>
                  <div fxFlex="60" style="margin: auto;">
                    <span class="dialogCostInfo" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
                      <mat-radio-group color="primary" (change)="changeElementStatus(chartSelected.costing, chartSelected.element, 'transfer', $event.value, 'subElement', 'graphsView')">
                        <mat-radio-button *ngIf="chartSelected.element.transferStatus == 'enquiry'" [checked]="true" style="margin-right: 15px;" value="enquiry"><span style="color: #ce983a;">Enquiry</span></mat-radio-button>

                        <mat-radio-button *ngIf="chartSelected.element.transferStatus == 'booking'" [checked]="true" style="margin-right: 15px;" value="booking"><span style="color: #4D5FD1;">Booked</span></mat-radio-button>
                        <mat-radio-button *ngIf="chartSelected.element.transferStatus != 'booking'" [checked]="false" style="margin-right: 15px;" value="booking"><span>Booked</span></mat-radio-button>

                        <mat-radio-button *ngIf="chartSelected.element.transferStatus == 'cancelled'" [checked]="true" style="margin-right: 15px;" value="cancelled"><span style="color: #a6a6a6;">Cancelled</span></mat-radio-button>
                        <mat-radio-button *ngIf="chartSelected.element.transferStatus != 'cancelled'" [checked]="false" style="margin-right: 15px;" value="cancelled"><span>Cancelled</span></mat-radio-button>
                      </mat-radio-group>
                    </span>
                    <span class="dialogCostInfo" *ngIf="userType != 'memberManager' && userType != 'memberStaff'">
                      {{ chartSelected.element.transferStatus | titlecase }}
                    </span>
                  </div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Pick Up Date</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.pickUpDateTime | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Instructions</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.pickUpInstructions }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Drop Off Date</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.dropOffDateTime | date:"dd.MM.yyyy hh:mm a":"GMT+0" }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Instructions</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.dropOffInstructions }}</span></div>
                </div>

                <p></p>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Vehicle Type</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.VehicleType }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Route</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.pickUpLocation }}
                      <mat-icon style="margin-top: -2px;">east</mat-icon> {{ chartSelected.element.dropOffLocation }}
                    </span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Country</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.country }}</span></div>
                </div>

                <div fxFlex="100">
                  <div fxFlex="40" style="margin: auto;">Emergency No.</div>
                  <div fxFlex="60" style="margin: auto;"><span class="dialogCostInfo">{{ chartSelected.element.emergencyResortContact }} <span
                        *ngIf="chartSelected.element.emergencyPhoneNo != ''">({{chartSelected.element.emergencyPhoneNo}})</span></span></div>
                </div>
              </div>
            </h3>
          </div>
        </div>
      </div>

      <div *ngIf="chartSelected.costing.elementType == 'package'" style="margin-bottom: 5px;">
        <mat-icon style="color: #ce983a;">inventory_2</mat-icon><i> - This element belongs to a Package</i>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-around end" *ngIf="userType == 'memberManager' || userType == 'memberStaff'">
        <div style="margin-bottom: 10px;" *ngIf="chartSelected.costing.elementType != 'package'">
          <button style="margin-bottom: 0px;" type="button" mat-raised-button color="accent" (click)="addElement(chartSelected.costing)">Add Element</button>
        </div>

        <div style="margin-bottom: 10px;">
          <button style="margin-bottom: 0px;" type="button" mat-raised-button color="accent" (click)="editElement(chartSelected.type, chartSelected.costing)">Edit Element</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #groupedBookingsDialog let-data>
  <mat-dialog-content>
    <div style="display: flex; flex-direction: column; align-items: center; margin: 10px;">
      <div>
        <div>
          <h2>{{ bookingData.bookingGroup}}</h2>
        </div>
      </div>
      
      <div class="groupTable" style="width: 100%;">
        <div class="groupHeader" style="display: flex; justify-content: space-around; flex-direction: row; margin: 10px; font-weight: bold;">
          <div style="width: 200px;">Booking Reference</div>
          <div style="width: 250px;">Lead Name</div>
          <div style="width: 150px;">Departure Date</div>
          <div style="width: 150px;">Return Date</div>
        </div>
        <div class="groupRow" style="display: flex; justify-content: space-around; flex-direction: row; margin: 10px;" *ngFor="let bkgData of data">
          <div style="width: 200px;">
            <a matDialogClose (click)="goToBooking(bkgData.bookingReference)" style="font-family: Azeret Mono;">{{bkgData.bookingReference | uppercase}}</a>
          </div>
          <div style="width: 250px;"><span *ngIf="bkgData.leadName">{{bkgData.leadName}}</span><span *ngIf="!bkgData.leadName">N/A</span></div>
          <div style="width: 150px;">{{bkgData.deptDate | date:'dd.MM.yyyy' }}</div>
          <div style="width: 150px;">{{bkgData.returnDate | date:'dd.MM.yyyy'}}</div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #emailPopUpDialog style="padding: 0px !important;">
  <app-email-popup [documentRequest]="documentRequest" [documentName]="documentName" [htmlPage]="htmlPage" [leadPax]="bookingData.leadName">
  </app-email-popup>
</ng-template>

<ng-template #docSettingsDialog style="padding: 0px !important;">
  <app-document-settings [templateName]="templateName" [documentName]="documentName" [isNewTemplate]="isNewTemplate">
  </app-document-settings>
</ng-template>