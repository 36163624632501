import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BankingService {

  constructor(private http: HttpClient) { }

  async pullRawFromFelloh(request: any): Promise<object> {
    // Append finished url below - it will differ depending on what's been entered
    const output = await this.http.get(environment.apiURL + 'pullRawFromFelloh?company=' + request.company + '&operation=' +
      request.operation + '&tradeCode=' + request.tradeCode + '&pullDate=' + request.pullDate + '&token=' + request.token).toPromise();
    return output;
  }

  async getBankStmntLines(request: any): Promise<object> {
    let url = environment.apiURL + 'getBankStmntLines?company=' + request.company + '&operation=' +
      request.operation + '&tradeCode=' + request.tradeCode + '&startDate=' + request.startDate +
      '&endDate=' + request.endDate + '&description=' + encodeURIComponent(request.descriptionFilter?.trim()) +
      '&stmntType=' + request.stmntType + '&limit=' + request.limit +
      '&offset=' + request.offset + '&token=' + request.token;

    // Mat-select does not support null in [value] - hence we're getting rid of the empty string here..
    if (request.approvedFilter !== '') { url = url + '&approved=' + request.approvedFilter; }

    // If rawID is present, then we're requesting bankStmntRaw data
    if (request.rawID !== undefined) { url = url + '&id=' + request.rawID; }

    // Append finished url below - it will differ depending on what's been entered
    // The output will differ depending on the required format (file / data)
    let output = null;

    if (request.stmntType === 'seq' || request.stmntType === 'raw') { output = await this.http.get(url).toPromise(); }
    else { output = await this.http.get(url, { responseType: 'arraybuffer' }).toPromise(); }

    return output;
  }

  async duplicateSelectedRow(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'duplicateSelectedRow', body, { headers }).toPromise();
    return output;
  }

  async updateBankStmntLine(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'updateBankStmntLineSeq', body, { headers }).toPromise();
    return output;
  }

  async showPossibleMatches(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'showPossibleMatches', body, { headers }).toPromise();
    return output;
  }

  async manMatchBankStmntLine(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'manMatchBankStmntLine', body, { headers }).toPromise();
    return output;
  }

  async unlinkBankStmntLine(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'unlinkBankStmntLine', body, { headers }).toPromise();
    return output;
  }
}
