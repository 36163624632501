import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { SupplierService } from '../../services/supplier.service';
import { UserService } from '../../services/user.service';
import { ReportsService } from '../../services/reports.service';
import { BranchService } from '../../services/branch.service';
import { GlobalConstants } from '../../common/global-constants';
import { MatPaginator } from '@angular/material/paginator';
import { AppComponent } from '../../app.component';
import { HostListener } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Session } from '../../common/session';
import { NgForm } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', marginTop: '-1.25px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0 }),
            animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)',
              style({ height: 300 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 300 }),
            animate('250ms cubic-bezier(0.4, 0.0, 0.2, 1)',
              style({ height: 0 }))
          ]
        )
      ]
    ),
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    ),
    trigger('customExpansionDetails', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', opacity: 0 })),
      state('expanded', style({ height: '*', opacity: 1 })),
      transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class SupplierListComponent implements OnInit {
  // Imported variables from outside
  constants = new GlobalConstants();
  branchData: any = [];
  branchDataFiltered: any = [];
  //supplierTypes = GlobalConstants.supplierTypes;
  vatRequirements = GlobalConstants.vatRequirements;
  countryList = GlobalConstants.countryList;
  countryFiltered = GlobalConstants.countryList;
  innerWidth = AppComponent.myapp.innerWidth;

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('paginatorSupp') paginatorSupp!: MatPaginator;
  @ViewChild('paginatorSfc') paginatorSfc!: MatPaginator;
  @ViewChild('paginatorReq') paginatorReq!: MatPaginator;
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;
  @ViewChild('requestDialog') requestDialog!: TemplateRef<any>;
  @ViewChild('existingDialog') existingDialog!: TemplateRef<any>;
  @ViewChild('helpDialog') helpDialog!: TemplateRef<any>;
  @ViewChild('contactExternalBox') contactExternalBox!: TemplateRef<any>;
  @ViewChild('bankingExternalBox') bankingExternalBox!: TemplateRef<any>;

  // Table data and table columns below
  columnsTAPS = ['supplierName', 'emailAddress', 'emailAddressTAPS', 'addressDetails', 'expand'];
  columnsAdmin = ['suppName', 'abta', 'atol', 'safi', 'sfc', 'na', 'hidden'];
  columnsAgent = ['suppName', 'abta', 'atol', 'safi', 'sfc', 'na'];
  columnsSfcAll = ['supplierName', 'branchName', 'reference', 'createTS', 'completeTS', 'status'];
  columnsSfc = ['supplierName', 'reference', 'createTS', 'completeTS', 'status'];
  columnsReqAll = ['supplierName', 'branchName', 'reference', 'createTS', 'completeTS', 'status'];
  columnsReq = ['supplierName', 'reference', 'createTS', 'completeTS', 'status'];
  supplierData: any = new MatTableDataSource<any>();
  sfcData: any = new MatTableDataSource<any>();
  suppReqData: any = new MatTableDataSource<any>();

  // Boolean deciding whether user has access or not
  userType = '';
  userEmail = '';
  pageLoaded = true;
  haveAccess = false;
  suppListDiv = true;
  suppReqDiv = false;
  sfcListDiv = false;
  createSuppDiv = false;

  // Boolean deciding whether box is open or not
  uploadSuppliersBox = false;
  createSupplierBox = true;
  filterHidden = false;
  sfcInfoOpen = false;

  // Variables for Supplier / SFC reqests
  currentCompany: any = '';
  currentOperation: any = '';
  currentTradeCode: any = '';
  requestMode: any = '';

  // Variables for current SFC
  selectedRequest: any = '';
  sfcDocumentation: any = '';
  selectedType: any = '';

  // Other variables
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  filterCountry: any = ''; // Used to filter country list (and maybe more in the future..)
  filterBranch: any = ''; // Used to filter branch list
  newBranches: any = []; // Holds a list of branches within SinGS
  newSupplier: any = {}; // Needed for correct supplier <--> branch mapping CRF68
  uploadedFileName: any = ''; // File name of the uploaded CSV (not uploaded at the end)
  csvSupplierData: any = []; // Holds uploaded Supplier data which is then sent to Ruby
  defaultFilter: any = ''; // Needed for filter + checkboxes
  errorMessage: any = '';
  successMessage: any = '';

  // Stuff needed for the 'expandable rows' to work
  expandedElement: any;
  isExpansionDetailRow = (i: number, row: object) => row.hasOwnProperty('detailRow');

  constructor(private router: Router, private userService: UserService, private branchService: BranchService,
              private supplierService: SupplierService, public dialog: MatDialog,
              private reportsService: ReportsService, private ngxCsvParser: NgxCsvParser) { }

  ngOnInit(): void {
    if (sessionStorage.length === 0 || Session.mySession === undefined) {
      // If the browser refreshed, SinGS redirects the page to the main page (components/home)
      this.router.navigate(['/']);
    } else {
      // If the page has been accessed by clicking a button - get userType and load the page
      this.currentCompany = Session.mySession.getUser().company;
      this.currentOperation = Session.mySession.getUser().operation;
      this.currentTradeCode = Session.mySession.getUser().tradeCode;
      this.userType = Session.mySession.getUser().userType;
      this.userEmail = Session.mySession.getUser().email;
      this.loadPage();
    }
  }

  loadPage(): void {
    if (['sinGSAdmin', 'supplier', 'tapsAdmin', 'memberManager', 'wcManager'].includes(this.userType)) {
      this.haveAccess = true;

      // Call getBranches API and assign its output to 'branches' (only if non-supplier type)
      if (['sinGSAdmin', 'tapsAdmin'].includes(this.userType)) {
        if (Session.mySession.getBranchList().expiryTime === 'EXPIRED') {
          this.branchService.getBranches(Session.mySession.getUser()).then((branches: any) => {
            if (branches.status === 'OK') {
              // Change Q0000 branch name to '-- All branches --' and sort once again..
              const allBranch = branches.data.find((branch: any) => (branch.tradeCode === 'Q0000')); allBranch.fullName = '-- All Branches --';
              // Assign sorted to global variable and session varaible (for later use) and call loadBookings()
              const sortedBranches = branches.data.sort((a: any, b: any) => (a.fullName > b.fullName) ? 1 : -1);
              this.branchData = sortedBranches; Session.mySession.setBranchList(sortedBranches);
              this.branchDataFiltered = this.branchData; // It needs to be the same list
              this.loadSuppliers();
            } else {
              // Status was not OK meaning something went wrong with it.. display it to the user
              this.sendMessageToDialog('', branches.status, '', ''); this.haveAccess = false;
            }
          }).catch((error: any) => {
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1508S)', error, Session.mySession.getUser());
          });
        } else {
          // Get branch list from the session varaible - no need to call API
          this.branchData = Session.mySession.getBranchList().branchList;
          const allBranch = this.branchData.find((branch: any) => (branch.tradeCode === 'Q0000')); allBranch.fullName = '-- All Branches --';
          this.branchData = this.branchData.sort((a: any, b: any) => (a.fullName > b.fullName) ? 1 : -1);
          this.branchDataFiltered = this.branchData; // It needs to be the same list
          this.loadSuppliers();
        }
      } else if (['memberManager', 'wcManager'].includes(this.userType)) {
        // Logged in user is in the group and branch list already exists within the session variable..
        this.branchData = Session.mySession.getUsersGroup();
        this.branchDataFiltered = this.branchData; // It needs to be the same list
        this.loadSuppliers();
      } else {
        this.loadSuppliers(); // Finish loading here if user type is supplier
      }
    } else {
      this.loadSuppliers(); // Loaded but no acces..
    }
  }

  loadSuppliers(): void {
    let request = { }; // Create a request variable for the API

    if (this.userType === 'supplier') { // Assign supplierName to the request variable below - only if supplier user type is logged in
      request = { supplierName: Session.mySession.getUser().supplierName, groupSuppliers: 'yes', token: Session.mySession.getUser().token };
      
    } else if (['memberManager', 'wcManager'].includes(this.userType) || this.currentTradeCode !== 'Q0000') {
      request = {
        company: this.currentCompany, operation: this.currentOperation, tradeCode: this.currentTradeCode,
        groupSuppliers: 'yes', token: Session.mySession.getUser().token
      };
      
    } else {
      request = { groupSuppliers: 'yes', token: Session.mySession.getUser().token };
    }

    this.pageLoaded = false;
    this.supplierService.getSupplierList(request).then((suppliers: any) => {
      if (suppliers.status !== 'OK') {
        this.haveAccess = false;
        this.sendMessageToDialog('', 'SinGS could not load suppliers (' + suppliers.status + ')', '', '');
      } else {
        this.supplierData.data = [];
        const myAsyncLoopFunction = async (array: any[]) => {
          const promises = array.map(async (element: any) => {
            element.supplierNameM = element[0].supplierNameM; // Make rows available for filtering
            //element.selectedType = element[0].supplierType; // Supplier selected by default in the interface
            element.misttaCode = element[0].misttaCode; // Allow filtering by misttaCode
            element.externalExpanded = false; // By default all external divs are not expanded
            element.typeExists = true; // By default, selected type will exist within the list (obviously..)
            element.detailRow = true; // Make rows expandable
            element = this.getSupplierSummary(element);
          });
          
          await Promise.all(promises);
          
          // Once all async tasks are complete, proceed with the subsequent code
          if (['memberManager', 'wcManager'].includes(this.userType)) {
            this.supplierData.data = array.filter(item => item.allUnhidden !== false); // Assign supplier data to the table data variable
          } else {
            this.supplierData.data = array; // Assign supplier data to the table data variable
          }
          this.supplierData.paginator = this.paginatorSupp; // Assign the paginator
          this.defaultFilter = this.supplierData.filterPredicate; // Store a reference to the default filter function
        }

        myAsyncLoopFunction(suppliers.data);
        this.pageLoaded = true;
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1501S)', error, request);
    });
  }

  loadRequests(type: any): void {
    const request = {
      company: this.currentCompany, operation: this.currentOperation,
      tradeCode: this.currentTradeCode, type,
      token: Session.mySession.getUser().token
    };

    this.sfcData.data = [];
    this.suppReqData.data = [];
    this.pageLoaded = false;
    this.supplierService.getSupplierRequests(request).then((sfcs: any) => {
      if (sfcs.status !== 'OK') {
        this.sendMessageToDialog('', 'SinGS could not load ' + type + ' list (' + sfcs.status + ')', '', '');
      } else {
        if (type === 'sfc') {
          this.sfcData.data = sfcs.data;
          this.sfcData.paginator = this.paginatorSfc; // Assign the paginator
        } else if (type === 'supplier') {
          this.suppReqData.data = sfcs.data;
          this.suppReqData.paginator = this.paginatorReq; // Assign the paginator
        }
        this.pageLoaded = true;
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1514S)', error, request);
    });
  }

  public reloadRequests(): void {
    if (this.suppListDiv) { this.loadRequests('supplier'); }
    else if (this.sfcListDiv) { this.loadRequests('sfc'); }
  }

  /*changeSelectedType(supplier: any, newType: any): void {
    if (supplier.find((i: any) => i.supplierType === newType) === undefined) {
      supplier.typeExists = false; // Check if the desired type exist within the list. Mark if not / yes
    } else { supplier.typeExists = true; }

    supplier.selectedType = newType; // Assign new supplier type
  }*/

  checkboxYesNo(event: any, object: any, property: any): void {
    if (!event.target.checked) { object[property] = 'no'; }
    else { object[property] = 'yes'; }
  }

  loadSupplierX(supplier: any): void {
    this.pageLoaded = false;
    supplier.externalNames = []; // Clear external names for selected supplier
    supplier.token = Session.mySession.getUser().token; // Assign user's token to the supplier request variable
    this.supplierService.getSupplierMap(supplier).then((output: any) => {
      if (output.status === 'OK') {
        output.suppMapList.forEach((exName: any) => {
          supplier.externalNames.push(exName.supplierNameX); // If status OK, I'll loop through the array to get external names here..
        });
        this.pageLoaded = true;
      } else {
        this.sendMessageToDialog('', 'Supplier mapping failed to load (' + output.status + ')', '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1502S)', error, supplier);
    });
  }

  addExternalName(event: any, supplier: any): void {
    const value = (event.value || '').trim();
    // Trim entered value and reate a request variable to add new supplier map
    const request = { supplierNameM: supplier.supplierNameM, supplierNameX: value, token: Session.mySession.getUser().token };

    // Validate characters entered in the form
    if (value && this.constants.validateVariableRegex(value, 'variable') !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateVariableRegex(value, 'variable'), '', '');
    } else if (value) {
      this.pageLoaded = false;
      this.supplierService.addSupplierMap(request).then((output: any) => {
        if (output.status === 'OK') {
          supplier.externalNames.push(value); // Add new map to the supplier group automatically (no refresh needed..)
          this.pageLoaded = true;
        } else {
          this.sendMessageToDialog('', 'This external name is invalid / already exists', '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1503S)', error, request);
      });
    }
    // Clear the input value afterwards
    event.input.value = '';
  }

  removeExternalName(name: any, supplier: any): void {
    const index = supplier.externalNames.indexOf(name);
    // Get the external name index and create a request variable..
    const request = { supplierNameM: supplier.supplierNameM, supplierNameX: name, token: Session.mySession.getUser().token };

    if (index >= 0) {
      this.pageLoaded = false;
      this.supplierService.removeSupplierMap(request).then((output: any) => {
        if (output.status === 'OK') {
          supplier.externalNames.splice(index, 1); // Remove external supplier name from the object here (no refresh needed..)
          this.pageLoaded = true;
        } else {
          this.sendMessageToDialog('', 'Faied to remove the external Supplier Name', '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1504S)', error, request);
      });
    }
  }

  doFilter(event: Event, dataSource: any): void {
    // Function used to Filter one of the tables below..
    const filterValue = (event.target as HTMLInputElement).value;
    dataSource.filter = filterValue.trim().toLowerCase();
  }

  editSupplier(form: NgForm, suppliers: any): void {
    // Validate characters entered in the form
    if (this.constants.validateFormCharacters(form) !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else if (suppliers[0].displayAvailability === 'selected' && suppliers[0].tradeCodes.length === 0) {
      this.sendMessageToDialog('', 'Member list cannot be empty', '', '');
    } else if (form.valid) { // Check if the form is valid first, all selects selected etc

      // Below conditional statements allow us to hide operation at the front
      if (form.value.company === 'ttng') { suppliers[0].company = 'ttng'; suppliers[0].operation = 'retail'; }
      else if (form.value.company === 'gtg') { suppliers[0].company = 'gtg'; suppliers[0].operation = 'member'; }
      else if (form.value.company === 'tta') { suppliers[0].company = 'tta'; suppliers[0].operation = 'tta'; }
      else if (form.value.company === undefined) { suppliers[0].company = null; suppliers[0].operation = null; }

      // Assign trade code array below (if array is more than 0 of course..)
      if (this.expandedElement[0].tradeCodes === undefined || this.expandedElement[0].tradeCodes.length === 0) { suppliers[0].tradeCode = null; }
      else { suppliers[0].tradeCode = this.expandedElement[0].tradeCodes; }

      // Assign trade code array below (if array is more than 0 of course..)
      if (this.expandedElement[0].tapsTradeCodes === undefined || this.expandedElement[0].tapsTradeCodes.length === 0) { suppliers[0].tapsTradeCode = null; }
      else { suppliers[0].tapsTradeCode = this.expandedElement[0].tapsTradeCodes; }

      const request = { token: Session.mySession.get('user').token, suppliers };

      this.pageLoaded = false;
      this.supplierService.updateSupplier(request).then((result: any) => {
        if (result.status === 'OK') {
          this.sendMessageToDialog('Supplier has been updated', '', '', ''); // Supplier updated - OK
        } else {
          this.sendMessageToDialog('', result.status, '', ''); // Return status - will it have status though?
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1505S)', error, request);
      });
    } else {
      this.sendMessageToDialog('', 'Please fill in all required fields', '', '');
    }
  }

  updateRequestStatus(supReq: any, status: any): void {
    if (confirm('Are you sure you want to change this SFC request status to ' + status + '?')) {
      const request: any = {
        id: supReq.id, reference: supReq.reference, status: status,
        comments: supReq.comments, token: Session.mySession.get('user').token
      };

      if (this.sfcListDiv) { request.type = 'sfc'; }
      else if (this.suppReqDiv) { request.type = 'supplier'}

      this.pageLoaded = false;
      this.supplierService.updateSuppReqStatus(request).then((res: any) => {
        if (res.status === 'OK') {
          this.dialog.closeAll(); // Close all dialogs first
          this.sendMessageToDialog('Status has been updated!', '', '', '');
          this.loadRequests(request.type);
        } else {
          this.sendMessageToDialog('', res.status, '', ''); // Return status - will it have status though?
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1515S)', error, request);
      });
    }
  }

  openSfcDoc(document: any): void {
    const request: any = {
      company: this.currentCompany, operation: this.currentOperation, tradeCode: this.currentTradeCode,
      bookingReference: this.selectedRequest.reference, pathType: 'sfcSupportingFiles', fileName: document.name,
      token: Session.mySession.get('user').token
    };

    // Call getS3presignedUrl method
    this.reportsService.getS3presignedUrl(request).then((output: any) => {
      if (output.status === 'OK') {
        try {
          window.open(output.presignedUrl, '_blank'); this.pageLoaded = true;
        } catch (error) {
          this.sendMessageToDialog('', error, '', ''); // File download OK but failed to convert Base64 to whatever
        }
      } else {
        this.sendMessageToDialog('', output.status, '', ''); // File download failed at the back-end
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1516S)', error, request);
    });
  }

  hideUnhideSupplier(suppArray: any): void {
    // Check if there are any other unhidden suppliers - mark if not
    if (suppArray.some((e: any) => e.hidden === 'yes')) { suppArray.allUnhidden = false; }
    else { suppArray.allUnhidden = true; }
  }

  removeSupplier(supplier: any): void {
    // Ask twice if user wants to remove supplier here..
    if (confirm('Are you sure you want to remove ' + supplier.supplierNameM + ' (' + supplier.supplierType + ') from the system?')) {
      if (confirm('Still sure you want to remove ' + supplier.supplierNameM + ' (' + supplier.supplierType + ') from the system?')) {
        // Request needs to contain both supplier name and its type to remove the correct name+type relation.
        // Otherwise, all of the supplier would be removed (we don't want that)
        const request = { supplierNameM: supplier.supplierNameM, supplierType: supplier.supplierType, token: Session.mySession.get('user').token };
        this.pageLoaded = false;
        this.supplierService.removeSupplier(request).then((result: any) => {
          if (result.status === 'OK') {
            this.switchView('supplierList'); // This will reload suppliers once again
            this.sendMessageToDialog('Supplier has been removed', '', '', ''); // Display error message
          } else {
            this.sendMessageToDialog('', result.status, '', ''); // Display error message
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1506S)', error, request);
        });
      }
    }
  }

  createSupplier(form: NgForm): void {
    const format = /[`!@#$%^*_+\=\[\]{}"\\|,<>?~]/; // These characters are not allowed in the fields..
    if (format.test(form.value.supplierNameM)) {
      this.sendMessageToDialog('', 'Please remove invalid characters from Supplier Name', '', ''); // Please do!
    } else if (form.valid) {
      // Check if the form is valid first, all selects selected etc
      // Below conditional statements allow us to hide operation at the front
      if (form.value.company === 'ttng') { form.value.operation = 'retail'; }
      else if (form.value.company === 'gtg') { form.value.operation = 'member'; }
      else if (form.value.company === 'tta') { form.value.operation = 'tta'; }
      else if (form.value.company === undefined) { form.value.company = null; form.value.operation = null; }

      // Assign trade code array below (if array is more than 0 of course..)
      if (form.value.displayAvailability !== 'selected' || (form.value.displayAvailability === 'selected' && this.newSupplier.tradeCodes.length === 0)) { form.value.tradeCode = null; }
      else if (form.value.displayAvailability === 'selected') { form.value.tradeCode = this.newSupplier.tradeCodes; }
      // Assign trade code array below (if array is more than 0 of course..)
      if (this.newSupplier.tapsTradeCodes.length === 0) { form.value.tapsTradeCode = null; }
      else { form.value.tapsTradeCode = this.newSupplier.tapsTradeCodes; }
      // If the user was unsure whether Branch is TAPS registered or not - we'll set it up as no by default
      if (form.value.isTapsReg !== 'yes' && form.value.isTapsReg !== 'no') { form.value.isTapsReg = 'no'; }
      if (form.value.underSafi !== 'yes' && form.value.underSafi !== 'no') { form.value.underSafi = 'no'; }

      form.value.supplierType = 'All'; // For the time being.. this will be removed..
      form.value.hidden = 'no'; // Assign all checkbox values below
      form.value.isTapsReg = this.newSupplier.isTapsReg; form.value.tapsSuspended = this.newSupplier.tapsSuspended;
      form.value.tapsSynchOverride = this.newSupplier.tapsSynchOverride; form.value.tapsDenyAmendments = this.newSupplier.tapsDenyAmendments;
      form.value.underSafi = this.newSupplier.underSafi;

      delete form.value.filterInput; // Remove filterInput property
      delete form.value.displayAvailability; // Remove displayAvailability property
      form.value.token = Session.mySession.get('user').token;

      this.pageLoaded = false;
      this.supplierService.createSupplier(form.value).then((result: any) => {
        if (result.status === 'OK') {
          this.switchView('supplierList'); // Supplier has been created. Instead of reseting the form, we just switch the view here
          this.sendMessageToDialog('Supplier has been created!', '', '', ''); // And then show the message to the user
        } else {
          this.sendMessageToDialog('', result.status, '', ''); // Show error message to the user
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1507S)', error, form.value);
      });
    } else {
      this.sendMessageToDialog('', 'Please fill in all required fields', '', '');
    }
  }

  /*createCloneSuppl(supplier: any): void {
    if (confirm('Are you sure you want to add ' + supplier.selectedType + ' to ' + supplier[0].supplierNameM + '?')) {
      const suppToAdd = supplier[0]; // Get the first instance of the Supplier
      suppToAdd.token = Session.mySession.get('user').token; // Set missing / required property
      suppToAdd.supplierType = supplier.selectedType; // Assign selected supplier type

      delete suppToAdd.id; // Remove unnecessary property
      delete suppToAdd.displayAvailability; // Remove unnecesary property

      suppToAdd.tradeCode = suppToAdd.tradeCodes; // Append multi trade codes here
      suppToAdd.tapsTradeCode = suppToAdd.tapsTradeCodes; // Append multi trade codes here

      this.pageLoaded = false;
      this.supplierService.createSupplier(suppToAdd).then((result: any) => {
        if (result.status === 'OK') {
          this.switchView('supplierList'); // Supplier has been created. Instead of reseting the form, we just switch the view here
          this.sendMessageToDialog('Supplier type has been added!', '', '', ''); // And then show the message to the user
        } else {
          this.sendMessageToDialog('', result.status, '', ''); // Show error message to the user
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1509S)', error, suppToAdd);
      });
    }
  }*/

  getSupplierSummary(supplier: any): any {
    const flags: { [key: string]: boolean } = {};
    const supplierTypes = ['Accommodation', 'Flight', 'CarHire', 'CarParking', 'Attraction', 'Package', 'Cruise', 'Transfer', 'Miscellaneous', 'Train'];

    // Check if all supplier types have been hidden; mark supplier accordingly
    supplierTypes.forEach((type) => {
      let isTypeHidden = false;
      let isTypeApproved = false;

      isTypeHidden = supplier.some((e: any) => e.supplierType === type && e.hidden === 'yes');
      isTypeApproved = supplier.some((e: any) => e.supplierType === type && e.hidden === 'no');

      flags[`is${type}Hidden`] = isTypeHidden;
      flags[`is${type}Approved`] = isTypeApproved;
    });

    // Set overall flags based on supplier types
    supplier.isUnderSFC = supplier[0].sfcs.some((e: any) => e.status === 'Approved');
    supplier.noUnhidden = supplier.every((e: any) => e.hidden === 'yes');
    supplier.allUnhidden = supplier.every((e: any) => e.hidden === 'no');

    // Merge flags into the supplier object
    Object.assign(supplier, flags);

    return supplier;
  }

  changeSuppAvailability(event: any): void {
    // Perform below operation only when user EXPANDS row
    if (this.expandedElement != null) {
      if (event !== '') {
        this.expandedElement[0].displayAvailability = event; // Assign display option based on selected radio button
      } else if (this.expandedElement[0].company == null && this.expandedElement[0].operation == null && this.expandedElement[0].tradeCode == null) {
        this.expandedElement[0].displayAvailability = 'everyone'; // Everything seems to be null - supplier available for everyone
      } else if (this.expandedElement[0].company != null && this.expandedElement[0].operation != null && this.expandedElement[0].tradeCode == null) {
        this.expandedElement[0].displayAvailability = 'membership'; // There is company and operation - supplier global membership
      } else if (this.expandedElement[0].company == null && this.expandedElement[0].operation == null && this.expandedElement[0].tradeCode != null) {
        this.expandedElement[0].displayAvailability = 'selected'; // Seems like it's for selected branches
      }

      // Whenever user clicks on a table row, we'll need to make sure the tradeCodes are being split into array (if they exist)
      if (event === '' && this.expandedElement[0].tradeCode !== undefined && this.expandedElement[0].tradeCode !== null) {
        this.expandedElement[0].tradeCodes = this.expandedElement[0].tradeCode.split(';'); // Transalte string to array here..
      } else if (event !== '') {
        this.expandedElement[0].tradeCodes = []; // Array stays empty..
      }

      // Whenever user clicks on a table row, we'll need to make sure the tradeCodes are being split into array (if they exist)
      if (event === '' && this.expandedElement[0].tapsTradeCode !== undefined && this.expandedElement[0].tapsTradeCode !== null) {
        this.expandedElement[0].tapsTradeCodes = this.expandedElement[0].tapsTradeCode.split(';'); // Transalte string to array here..
      } else if (event === '') {
        this.expandedElement[0].tapsTradeCodes = []; // Array stays empty..
      }
    }
  }

  editSuppGroup(group: any, tradeCode: any, operation: any): void {
    if (operation === 'remove' && group.tradeCodes.length === 1) {
      this.sendMessageToDialog('', 'Member list cannot be empty', '', '');
    } else if (operation === 'remove') {
      const index = group.tradeCodes.indexOf(tradeCode); // Get the index from tradeCode property first
      group.tradeCodes.splice(index, 1); // Remove selected trade code from the array..
    } else if (operation === 'add') {
      group.tradeCodes.push(tradeCode); // Add typed in trade code to the array..
    }
  }

  editTAPSsuppGroup(group: any, tradeCode: any, operation: any): void {
    if (operation === 'remove') {
      const index = group.tapsTradeCodes.indexOf(tradeCode); // Get the index from tradeCode property first
      group.tapsTradeCodes.splice(index, 1); // Remove selected trade code from the array..
    } else if (operation === 'add') {
      group.tapsTradeCodes.push(tradeCode); // Add typed in trade code to the array..
    }
  }

  checkboxFilter(event: any, source: any): void {
    // Define a custom filter function
    const customFilter = (data: any, filter: boolean): boolean => {
      // Implement your custom filtering logic here
      // Return true if the row should be included in the filtered result,
      // false otherwise

      // Example: Filter based on the 'allUnhidden' attribute
      return data.allUnhidden === !filter;
    };
    
    if (event.target.checked) {
      // Assign the custom filter function to the filterPredicate property of the MatTableDataSource
      this.supplierData.filterPredicate = customFilter;

      // Apply the filter by setting the filter property of the MatTableDataSource
      this.supplierData.filter = true; // or false, depending on whether you want to filter for true or false values of 'allUnhidden'
    } else {
      // Reset the filter predicate to null
      this.supplierData.filterPredicate = this.defaultFilter;
        
      // Clear the filter by setting the filter property of the MatTableDataSource to an empty string
      this.supplierData.filter = '';
    }
  }

  changeGroupBranch(tradeCode: any): void {
    const filteredBranch = this.branchData.filter((branch: any) => branch.tradeCode === tradeCode)[0];

    if (filteredBranch.membershipType === 'worldchoicePlus' || filteredBranch.membershipType === 'worldchoice') {
      this.currentCompany = 'ttng'; this.currentOperation = 'retail';
    } else if (filteredBranch.membershipType === 'globalTravel') {
      this.currentCompany = 'gtg'; this.currentOperation = 'member';
    } else if (filteredBranch.membershipType === 'tta') {
      this.currentCompany = 'tta'; this.currentOperation = 'tta';
    }

    this.currentTradeCode = tradeCode; // Assign new trade code to the 'global' request
    
    if (this.suppListDiv) { this.loadSuppliers(); } // Get user list for selected trade code
    else if (this.sfcListDiv) { this.loadRequests('sfc'); }
    else if (this.suppReqDiv) { this.loadRequests('supplier'); }
  }

  emailMemberApproved(tradeCode: any, supplierName: any): void {
    if (confirm('Are you sure you want to email ' + tradeCode + ' about ' + supplierName + ' being available to them?')) {

      const request = { tradeCode: tradeCode, supplierNameM: supplierName, token: Session.mySession.get('user').token };

      this.supplierService.sendApprovedSupplierRequest(request).then((output: any) => {
        if (output.status === 'OK') {
          this.sendMessageToDialog('Email has been sent', '', '', '');
        } else {
          this.sendMessageToDialog('', output.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1513S)', error, request);
      });
    }
  }

  getCSVtemplate(): void {
    // Create request variable and use it to get the tempalte file
    const request = { name: 'supplDataTemplate.csv', type: 'csv', directory: 'temple', token: Session.mySession.get('user').token };
    this.pageLoaded = false;

    this.reportsService.getFile(request).then((blob: any) => {
      // Output BLOB needs to be transformed into an excel application file
      const data = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      saveAs(data, 'supplDataTemplate.csv'); // Call this function which opens browser's 'Save As..' window
      this.pageLoaded = true;
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1510S)', error, request);
    });
  }

  validateSupplierFile(fileInputEvent: any): void {
    this.pageLoaded = false;
    // We use the ngxCsvParser here to read the data from CSV files. We want headers to false
    // beacuse that will 'translate' the data into the csv format we need at the back..
    this.ngxCsvParser.parse(fileInputEvent.srcElement.files[0], { header: false, delimiter: ',' })
      .pipe().subscribe((csvData: any) => {
        // Once we've got the data, we need to create a request variable which will hold it along with validation stuff
        const request = { token: Session.mySession.get('user').token, data: csvData };

        this.supplierService.validateSupplCSV(request).then((result: any) => {
          if (result.status === 'OK') {
            this.csvSupplierData = csvData; // Assign CSV data to global variable which will be used later on
            this.uploadedFileName = fileInputEvent.target.files[0].name; // Assign file name to the input
            this.sendMessageToDialog('File has been validated', '', '', '');
          } else {
            this.sendMessageToDialog('', result.status, '', ''); // Validation was not successfull - return status
            this.switchView('createSupplier'); // Resets both variables set by a success status
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1511S)', error, request);
          this.switchView('createSupplier'); // Resets both variables set by a success status
        });
      }, (error: NgxCSVParserError) => {
        this.sendMessageToDialog('', 'BETA version error: ' + error, '', '');
        this.switchView('createSupplier'); // Resets both variables set by a success status
      });
  }

  uploadSupplierData(): void {
    this.pageLoaded = false;
    // Create a request variable which will use all validation properties and takes csv customer data from global variable
    const request = { token: Session.mySession.get('user').token, data: this.csvSupplierData };

    this.supplierService.uploadSupplCSV(request).then((result: any) => {
      if (result.status === 'OK') {
        this.switchView('supplierList'); // Customers uploaded! Switch view to 'all'
        this.sendMessageToDialog('Total of uploaded suppliers: ' + result.dataRowsLoaded, '', '', '');
      } else {
        this.sendMessageToDialog('', result.status, '', ''); // Validation was not successfull
        this.switchView('createSupplier'); // Resets both variables set by a success status
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1512S)', error, request);
      this.switchView('createSupplier'); // Resets both variables set by a success status
    });
  }

  exportToExcel(source: any): void {
    const exportMe: any = [];
    let oneLine: any = {};
    
    if (source === 'suppliers') {
      // For each supplier object, oneLine will be generated with appropiate values
      // And then pushed to exportMe which will be.. exported
      this.supplierData.data.forEach((data: any) => {
        data.forEach((supplData: any) => { // Supplier data is nested
          oneLine = {};
          oneLine.supplierName = supplData.supplierNameM;
          oneLine.supplierType = supplData.supplierType;
          oneLine.contactName = supplData.contactName;
          oneLine.contactEmail = supplData.email;
          oneLine.phoneNumber = supplData.telephone;
          oneLine.website = supplData.website;
          oneLine.addressLine1 = supplData.addressLine1;
          oneLine.addressLine2 = supplData.addressLine2;
          oneLine.addressLine3 = supplData.addressLine3;
          oneLine.addressLine4 = supplData.addressLine4;
          oneLine.postCode = supplData.postCode;
          oneLine.country = supplData.country;
          // Non-suppliers (admins etc) will have extra lines appearing in the csv file
          if (this.userType !== 'supplier') {
            // Work out who this supplier is for
            if (supplData.company !== null) { oneLine.availableTo = supplData.company; }
            else if (supplData.tradeCode !== null) { oneLine.availableTo = supplData.tradeCode; }
            else { oneLine.availableTo = 'everyone'; }
            // All other columns
            oneLine.atolNo = supplData.atolNo;
            oneLine.abtaNo = supplData.abtaNo;
            oneLine.underSafi = supplData.underSafi;
            oneLine.vatRequirement = supplData.vatRequirement;
            oneLine.confirmationDays = supplData.confirmationDays;
            oneLine.paymentDueDays = supplData.paymentDueDays;
            oneLine.depositRate = supplData.depositRate;
            oneLine.isTapsReg = supplData.isTapsReg;
            oneLine.misttaCode = supplData.misttaCode;
            oneLine.tapsDenyAmendments = supplData.tapsDenyAmendments;
            oneLine.tapsSuspended = supplData.tapsSuspended;
            oneLine.tapsSuspensionReason = supplData.tapsSuspensionReason;
            oneLine.tapsTransactionFee = supplData.tapsTransactionFee;
            oneLine.tapsTransactionFee = supplData.tapsTransactionFee;
            oneLine.tapsPaymentDays = supplData.tapsPaymentDays;
            oneLine.tapsSynchOverride = supplData.tapsSynchOverride;
          }
          exportMe.push(oneLine);
        });
      });
      this.constants.exportAsExcelFile(exportMe, 'supplierList');
    } else if (source === 'supReq') {
      // For each sfc object, oneLine will be generated with appropiate values
      // And then pushed to exportMe which will be.. exported
      const arr = this.suppReqData.data;

      arr.forEach((data: any) => {
        oneLine = {};
        oneLine.supplierName = data.supplierNameM;
        oneLine.tradeCode = data.tradeCode;
        oneLine.branchName = data.branchName;
        oneLine.reference = data.reference;
        oneLine.requestName = data.requestedName;
        oneLine.requestEmail = data.requestedEmail;
        oneLine.createdAt = data.createTS;
        oneLine.reviewedAt = data.completeTS;
        oneLine.status = data.status;
        exportMe.push(oneLine);
      });
      this.constants.exportAsExcelFile(exportMe, 'supReqList');
    } else if (source === 'sfc') {
      // For each sfc object, oneLine will be generated with appropiate values
      // And then pushed to exportMe which will be.. exported
      const arr = this.sfcData.data;

      arr.forEach((data: any) => {
        oneLine = {};
        oneLine.supplierName = data.supplierNameM;
        oneLine.tradeCode = data.tradeCode;
        oneLine.branchName = data.branchName;
        oneLine.reference = data.reference;
        oneLine.requestName = data.requestedName;
        oneLine.requestEmail = data.requestedEmail;
        oneLine.tradingName = data.tradingName;
        oneLine.telephone = data.telephone;
        oneLine.email = data.email;
        oneLine.website = data.website;
        oneLine.address1 = data.address1;
        oneLine.contactName = data.contactName;
        oneLine.contactRole = data.contactRole;
        oneLine.bankName = data.bankName;
        oneLine.accountNo = data.accountNo;
        oneLine.sortCode = data.sortCode;
        oneLine.iban = data.iban;
        oneLine.swift = data.swift;
        oneLine.currency = data.currency;
        oneLine.createdAt = data.createTS;
        oneLine.reviewedAt = data.completeTS;
        oneLine.status = data.status;
        exportMe.push(oneLine);
      });
      this.constants.exportAsExcelFile(exportMe, 'sfcList');
    }
  }

  switchView(view: any): void {
    // Nothing special to add. Whenever button on left nav-bar is clicked, change the view variables values.
    this.expandedElement = false;
    if (view === 'supplierList') {
      this.suppListDiv = true; this.createSuppDiv = false; this.suppReqDiv = false; this.sfcListDiv = false;
      this.loadSuppliers();
    } else if (view === 'supplierRequests') {
      this.suppListDiv = false; this.createSuppDiv = false; this.suppReqDiv = true; this.sfcListDiv = false;
      this.loadRequests('supplier');
    } else if (view === 'sfcList') {
      this.suppListDiv = false; this.createSuppDiv = false; this.suppReqDiv = false; this.sfcListDiv = true;
      this.loadRequests('sfc');
    } else if (view === 'createSupplier') {
      this.newSupplier = { displayAvailability: 'everyone', tradeCodes: [], underSafi: 'no', isTapsReg: 'no',
      tapsSuspended: 'no', tapsSynchOverride: 'no', tapsDenyAmendments: 'no',
      tapsExcludeFlag: 'yes', tapsTradeCodes: [] };
      this.suppListDiv = false; this.createSuppDiv = true; this.suppReqDiv = false; this.sfcListDiv = false;
      this.csvSupplierData = []; // Reset supplier data (upload button must disappear)
    }
  }

  infoOpenClose(): void {
    if (this.sfcInfoOpen) { this.sfcInfoOpen = false; }
    else { this.sfcInfoOpen = true; }
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  filterSelect(filterVar: any): void {
    if (filterVar === 'country') {
      this.countryFiltered = []; // Empty filtered array first
      const filter = this.filterCountry.toLowerCase(); // Get the string we filter with here
  
      // Loop through our MAIN array and add whatever matches our search string
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.countryList.length; i++) {
        const option = this.countryList[i];
        if (option.toLowerCase().indexOf(filter) >= 0) {
          this.countryFiltered.push(option);
        }
      }
    } else if (filterVar === 'branch') {
      this.branchDataFiltered = []; // Empty filtered array first
      const filter = this.filterBranch.toLowerCase(); // Get the string we filter with here
  
      // Loop through our MAIN array and add whatever matches our search string
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.branchData.length; i++) {
        const option = this.branchData[i];
        if (option.fullName.toLowerCase().indexOf(filter) >= 0) {
          this.branchDataFiltered.push(option);
        }
      }
    }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    this.pageLoaded = true; this.dialog.open(this.statusDialog);
  }

  openExistingSfcDialog(sfc: any): void {
    this.sfcInfoOpen = false;
    this.selectedRequest = sfc; this.selectedRequest.comments = '';
    this.sfcDocumentation = []; this.selectedType = 'sfc';

    const request: any = {
      company: this.currentCompany, operation: this.currentOperation, tradeCode: this.currentTradeCode,
      bookingReference: sfc.reference, pathType: 'sfcSupportingFiles', token: Session.mySession.get('user').token
    };

    this.reportsService.getS3files(request).then((res: any) => {
      if (res.status === 'OK') {
        if (res.data.contents) {
          this.sfcDocumentation = res.data?.contents;
          this.sfcDocumentation.forEach((file: any) => {
            file.name = file.key.split('/').pop(); // Get only file name, remove path it is located in
          });
        }
        this.dialog.open(this.existingDialog, {panelClass: 'bankingExternalBox', autoFocus: false});
      } else {
        this.sendMessageToDialog('', res.status, '', '');
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1513S)', error, request);
    });
  }

  openExistingReqDialog(req: any): void {
    this.sfcInfoOpen = false;
    this.selectedRequest = req; this.selectedRequest.comments = '';
    this.sfcDocumentation = []; this.selectedType = 'req';

    this.dialog.open(this.existingDialog, {panelClass: 'bankingExternalBox', autoFocus: false});
  }

  openRequestDialog(mode: any): void {
    this.requestMode = mode;
    this.dialog.open(this.requestDialog, {panelClass: 'bankingExternalBox', disableClose: true, autoFocus: false});
  }

  showHelp(): void {
    this.dialog.open(this.helpDialog);
  }

  openBankingAccounts(): void {
    this.dialog.open(this.bankingExternalBox, {panelClass: 'bankingExternalBox'});
  }

  openContactDetails(): void {
    this.dialog.open(this.contactExternalBox, {panelClass: 'bankingExternalBox'});
  }
}
