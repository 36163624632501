import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient, private apiService: ApiService) { }

  async getReport(reportStructure: any, request: any, format: any): Promise<object> {
    let url = environment.apiURL + reportStructure.value + '?company=' + request.company + '&operation=' + request.operation + '&version=summary&dateType=' + request.dateType +
      '&startDate=' + request.startDate + '&endDate=' + request.endDate + '&format=' + format + '&template=' + reportStructure.template + '&paymentCategory=' + request.paymentCategory +
      '&commissionFilter=' + request.commissionFilter + '&countryFilter=' + request.countryFilter +'&bookingGroup=' + request.bookingGroup + '&token=' + request.token;
  
    if (request.tradeCode === 'groupAll') {  url += '&userGroup=' + request.userGroup; }
    else { url += '&tradeCode=' + request.tradeCode }
  
    if (request.agentEmail) { url += '&agentEmail=' + request.agentEmail; }
  
    return this.http.get(url).toPromise();
  }
  

  async getYtdReport(request: any): Promise<object> {
    const output = this.http.get(environment.apiURL + 'getYtdReport?company=' + request.company +
    '&operation=' + request.operation + '&tradeCode=' + request.tradeCode + '&token=' + request.token).toPromise();
    return output;
  }

  async uploadS3files(request: any, filesArray: any): Promise<object> {
    const formData = new FormData();
    filesArray.forEach((file: any) => { formData.append('files[]', file, file.name); });
  
    const url = `${environment.apiURL}uploadS3file?pathType=${request.pathType}&company=${request.company}&operation=${request.operation}&tradeCode=${request.tradeCode}&bookingRef=${request.bookingReference}&token=${request.token}`;
  
    const headers = new HttpHeaders(); // Optionally set headers if needed
    return this.apiService.makeHttpRequest('post', url, formData, headers);
  }

  async getS3files(request: any): Promise<object> {
    let url = environment.apiURL + 'getS3files?pathType=' + request.pathType + '&company=' + request.company + '&operation=' + request.operation + '&bookingRef=' + request.bookingReference + '&token=' + request.token;

    if (request.tradeCode === 'groupAll') {  url += '&userGroup=' + request.userGroup; }
    else { url += '&tradeCode=' + request.tradeCode }

    if (request.agentEmail) { url += '&agentEmail=' + request.agentEmail; }

    return this.http.get(url).toPromise();
  }
  

  async downloadS3file(request: any): Promise<object> {
    let url = environment.apiURL + 'downloadS3file?pathType=' + request.pathType + '&company=' + request.company + '&operation=' + request.operation + '&bookingRef=' + request.bookingReference +
    '&fileName=' + encodeURIComponent(request.fileName) + '&token=' + request.token;

    if (request.tradeCode === 'groupAll') {  url += '&userGroup=' + request.userGroup; }
    else { url += '&tradeCode=' + request.tradeCode }

    if (request.agentEmail) { url += '&agentEmail=' + request.agentEmail; }

    return this.http.get(url).toPromise();
  }

  async getS3presignedUrl(request: any): Promise<object> {
    let url = environment.apiURL + 'getS3presignedUrl?pathType=' + request.pathType + '&company=' + request.company + '&operation=' + request.operation + '&bookingRef=' + request.bookingReference +
    '&fileName=' + encodeURIComponent(request.fileName) + '&token=' + request.token;

    if (request.tradeCode === 'groupAll') {  url += '&userGroup=' + request.userGroup; }
    else { url += '&tradeCode=' + request.tradeCode }

    if (request.agentEmail) { url += '&agentEmail=' + request.agentEmail; }

    return this.http.get(url).toPromise();
  }

  async removeS3file(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'removeS3file', body, { headers }).toPromise();
    return output;
  }

  async getFile(request: any): Promise<object> {
    const output = this.http.get(environment.apiURL + 'getFile?fileName=' + encodeURIComponent(request.name) + '&fileType=' + request.type + '&directory=' + request.directory +
      '&token=' + request.token, { responseType: 'arraybuffer' }).toPromise();
    return output;
  }

  async generateATOLpdf(request: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'generateATOLpdf?company=' + request.company + '&operation=' + request.operation + '&tradeCode=' + request.tradeCode +
        '&bookingReference=' + request.bookingReference + '&atolType=' + request.atolType + '&actionedBy=' + request.actionedBy + '&token=' + request.token).toPromise();
    return output;
  }

  async generateSAG(request: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'generateSAG?company=' + request.company + '&operation=' + request.operation + '&tradeCode=' + request.tradeCode +
        '&bookingReference=' + request.bookingReference + '&actionedBy=' + request.actionedBy + '&supplementCount=' + request.supplementCount + '&type=' + request.type + '&token=' + request.token).toPromise();
    return output;
  }

  async getDocument(request: any): Promise<object> {
    const urlParams = `company=${request.company}&` +
                      `operation=${request.operation}&` +
                      `tradeCode=${request.tradeCode}&` +
                      `bookingReference=${request.bookingReference}&` +
                      `template=${request.template}&` +
                      `format=${request.format}&` +
                      `rtnData=${request.rtnData}&` +
                      `outputFileName=${request.outputFileName}&` +
                      `transcribe=${request.transcribe}&` +
                      `emailDocument=${request.emailDocument}&` +
                      `documentNiceName=${request.documentNiceName}&` +
                      `generatedBy=${encodeURIComponent(request.generatedBy)}&` +
                      `token=${request.token}`;
  
    const url = request.subRef == null ? environment.apiURL + 'generateDoc?' + urlParams : environment.apiURL + 'generateDoc?' + urlParams + '&subRef=' + request.subRef;

    return this.apiService.makeHttpRequest('get', url);
  }

  async genAndSendDoc(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'genAndSendDoc', body, { headers }).toPromise();
    return output;
  }

  async getMonthlyReturns(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'getMonthlyReturns', body, { headers }).toPromise();
    return output;
  }

  async confirmMonthlyReturn(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'confirmMonthlyReturn', body, { headers }).toPromise();
    return output;
  }

  async approveMonthlyReturn(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'approveMonthlyReturn', body, { headers }).toPromise();
    return output;
  }

  // DOCUMENT SETTINGS BELOW

  async getDocumentConfiguration(request: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'getDocumentConfiguration?tradeCode=' + request.tradeCode + '&templateName=' +
    request.templateName + '&token=' + request.token).toPromise();
    return output;
  }

  async createDocumentConfiguration(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'createDocumentConfiguration', body, { headers }).toPromise();
    return output;
  }

  async editDocumentConfiguration(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'editDocumentConfiguration', body, { headers }).toPromise();
    return output;
  }
}
