<div [@inAnimation] fxLayout="column" fxLayoutAlign="space-evenly center">
  <mat-card>
    <h1>SinGS Changelog</h1>
    <button mat-button [routerLink]="['/login']" style="text-align: left;">Back to login page</button>
    <p></p>
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <div fxFlex="33.33"><b>BP</b> - Booking Platform</div>
      <div fxFlex="33.33"><b>OT</b> - One Touch</div>
      <div fxFlex="33.33"><b>FP</b> - Felloh Platform</div>
      <div fxFlex="33.33"><b>AP</b> - All Platforms</div>
    </div>

    <!--
    <p>[<b>BP</b>] A WordPress plugin has been released. Click <a
         href="https://docs.google.com/document/d/1FxeuwUOav7bSFVy4RHqDNoQ91k7DfAoR1Lmq4VYuD84/edit?usp=sharing" target="_blank">here</a>
      to find out how you can integrate it on your WordPress site</p>
    -->

    <div>
      <div class="changes">
        <p class="version">
          v1.60a
          <span class="date">10 September 2024</span>
        </p>
        <p>[<b>PRF</b>] It is now possible to upload files in PNG format to support your PRFs.</p>
        <p>[<b>OT</b>] Added support for Groups in OneTouch. Users assigned to a Group can now switch between branches.</p>
        <p>[<b>OT</b>] Fixed a bug where the system would not update the passenger if a postcode was provided.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.60
          <span class="date">09 September 2024</span>
        </p>
        <p>[<b>PRF</b>] The system now attempts to locate receipts for each booking automatically.</p>
        <p>[<b>PRF</b>] A new filter has been added, allowing you to filter bookings by group name.</p>
        <p>[<b>PRF</b>] Various UI enhancements have been made for a better user experience.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.59b
          <span class="date">06 September 2024</span>
        </p>
        <p>[<b>OT</b>] All flights are now automatically marked under SAFI if SAFI is present when creating a booking. This ensures that SAFI costs display correctly from the start.</p>
        <p>[<b>OT</b>] The booking list now includes a new filter: Date Range & Type.</p>
        <p>[<b>OT</b>] It is now possible to edit passenger details at any time.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.59a
          <span class="date">05 September 2024</span>
        </p>
        <p>[<b>BP</b>] You can now edit "Your Reference" in the full booking system.</p>
        <p>[<b>AP</b>] The Booking Reference header is now clickable in certain tables, allowing you to toggle between the displayed reference (TTAS-/Your Reference).</p>
        <p>[<b>TAPS</b>] All supplier interactions with dates have been updated to use the Moment library. This resolves the issue with their system's dependency on the British date format.</p>
        <p>[<b>AP</b>] Several bug fixes have been applied across the system.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.59
          <span class="date">02 September 2024</span>
        </p>
        <p>[<b>AP</b>] The OneTouch system has been released, providing new functionality for managing and producing SFC and standalone certificates.</p>
        <p>[<b>AP</b>] A few tweaks and fixes have been made across the system.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.58c
          <span class="date">28 August 2024</span>
        </p>
        <p>[<b>AP</b>] A branch can now have multiple trading names, each with its own logo, contact details, etc.
          Members with two or more trading names can change the booking's trading name, which will affect what is printed on documents.</p>
        <p>[<b>AP</b>] Address details are no longer editable. To make any changes, please contact the Operations team.</p>
        <p>[<b>AP</b>] Trading Name has been added to all reports.</p>
        <p>[<b>BP</b>] Minor bug fixes across the system.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.58b
          <span class="date">23 August 2024</span>
        </p>
        <p>[<b>BP</b>] The "ATOL Protection" wording has been updated in all documents.</p>
        <p>[<b>BP</b>] The "Reminders" table on the Dashboard has been updated.</p>
        <p>[<b>BP</b>] Trading Name has been added to the Customer Balance Due Report.</p>
        <p>[<b>AP</b>] The customer title is no longer mandatory.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.58a
          <span class="date">22 August 2024</span>
        </p>
        <p>[<b>BP</b>] Booking notes can now be marked to show on documents. The documents that display these notes are: Booking Confirmation - Balance Outstanding and Booking Confirmation - Paid in Full.</p>
        <p>[<b>BP</b>] The "My Last 5" and "Daily Banking" tables in the Dashboard have been updated.</p>
        <p>[<b>BP</b>] Added Trading Name to all reports. Additionally, updated all report tables.</p>
        <p>[<b>AP</b>] Various TAPS amendments.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.58
          <span class="date">08 August 2024</span>
        </p>
        <p>[<b>BP</b>] The booking date can be amended up to 10 days after the booking status is set to 'Booked'.</p>
        <p>[<b>BP</b>] It is now possible to mark a booking with a 'Deposit Paid' checkbox.</p>
        <p>[<b>BP</b>] It is now possible to mark a booking with an 'ATOL Protected' value: Retail Agent of ATOL or T-ATOL Protection (for T-ATOL holders only). The booking needs to meet ATOL criteria first.</p>
        <p>[<b>BP</b>] Printing ATOL certificates requires the booking to be marked as 'ATOL Protected'.</p>
        <p>[<b>BP</b>] There can be only one SAFI and one SFC element per booking from now on.</p>
        <p>[<b>BP</b>] The Supplement Report has been updated with three new columns: Discount, Create TS, and Update TS.</p>
        <p>[<b>AP</b>] Various UI tweaks and typo corrections.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.57
          <span class="date">01 August 2024</span>
        </p>
        <p>[<b>AP</b>] The TAPS system has been released, providing new functionality for managing transactions and payments.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.56
          <span class="date">09 July 2024</span>
        </p>
        <p>[<b>AP</b>] Managers can no longer create or edit other managers. To do so, you will need to contact our support team.</p>
        <p>[<b>BP</b>] It is now possible to add any element type for any supplier when adding a new element to the booking.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.55a
          <span class="date">27 June 2024</span>
        </p>
        <p>[<b>BP</b>] It is now possible to amend the date indefinitely if the booking status is marked as 'Enquiry'.</p>
        <p>[<b>BP</b>] Supplier names will no longer be displayed within the QR code link.</p>
        <p>[<b>BP</b>] A new feature allows for the option to 'hide' passengers in documents that include a passenger list. In the Passengers tab, each passenger entry includes a checkbox labeled "Show in Documents" upon mouse hover. By default, all new and existing passengers are set to 'yes'.</p>
        <p>[<b>BP</b>] Cancelled elements can now be displayed and are greyed out in two specific documents: "Booking Confirmation - Balance Outstanding" and "Booking Confirmation - Paid in Full".</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.55
          <span class="date">25 June 2024</span>
        </p>
        <p>[<b>AP</b>] A new 'Suppliers' page is now available for managers, where they can view the current list of available suppliers, request the addition of a new supplier, and request a new SFC to be added to the system.</p>
        <p>[<b>AP</b>] Requesting a new SFC now requires a list of specific fields and files to be included in the application to align with the OneTouch system.</p>
        <p>[<b>AP</b>] An SFC will be disabled if there are no suppliers under the SFC present in the booking.</p>
        <p>[<b>AP</b>] SAFI will be disabled if there are no 'Flight Only' elements in the booking.</p>
        <p>[<b>AP</b>] Corrected SAFI and SFC cost calculations: if the total gross or net of elements equals £0, then SAFI and SFC costs will also be £0.</p>
        <p>[<b>BP</b>] It is now possible to add receipts and payments to our mini-booking system (OneTouch).</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.54b
          <span class="date">24 June 2024</span>
        </p>
        <p>[<b>BP</b>] Adjusted start date and end date validations for creating and amending bookings on the platform.</p>
        <p>[<b>AP</b>] All system components have been upgraded to integrate with Felloh endpoints, expanding functionality and improving interoperability. See version 1.54 for details.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.54a
          <span class="date">20 June 2024</span>
        </p>
        <p>[<b>AP</b>] Fixed a bug that caused a token mismatch when signing a PRF, resulting in 'Access Denied' messages.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.54
          <span class="date">18 June 2024</span>
        </p>
        <p>[<b>BP</b>] The Booking Portfolio now utilizes new Felloh endpoints, introducing several additional features: setting allowed card types, defining allowed card regions, configuring the expiration date for payment links, and toggling surcharges on or off.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.53a
          <span class="date">29 May 2024</span>
        </p>
        <p>[<b>BP</b>] Corrected a typo in the "Welcome Home" letter.</p>
        <p>[<b>BP</b>] ATOL costs are now calculated based on passengers who are at least 2 years old.</p>
        <p>[<b>BP</b>] Generating an ATOL certificate will now automatically add the 'APC - ATOL Protection Contribution' supplement to the booking if it does not already exist (applicable to T-ATOL holders only).</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.53
          <span class="date">17 May 2024</span>
        </p>
        <p>[<b>BP</b>] The wording in the Balance Reminder has been updated.</p>
        <p>[<b>BP</b>] Three new documents have been added to the system: Welcome Home, Ticket Sent to Client, and Balance Overdue.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.52c
          <span class="date">15 May 2024</span>
        </p>
        <p>[<b>AP</b>] It is now possible to attach .msg files to a PRF as supporting documents.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.52b
          <span class="date">30 April 2024</span>
        </p>
        <p>[<b>FP</b>] Each transaction in a disbursement will now display the customer name for whom the payment link was created.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.52a
          <span class="date">26 April 2024</span>
        </p>
        <p>[<b>BP</b>] Added a switch in the top right corner that toggles between an Overview and Full Details when searching for a booking.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.52
          <span class="date">24 April 2024</span>
        </p>
        <p>[<b>BP</b>] It is now possible to add 'Blank Passengers' to a booking. These passengers are similar to any other passengers but without any details. This feature is useful when passenger details are not known at the time.</p>
        <p>[<b>BP</b>] The Safe Seat Guarantee is now based on both active and non-active passengers.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.51
          <span class="date">18 April 2024</span>
        </p>
        <p>[<b>BP</b>] Bookings accessed by a customer via QR code or a URL link now display all active Felloh payment links.<br><br>
          Note: The system will only display payment links with matching booking references. For example, booking TTAS-0000092 will only display payment links with the same reference. Temporary references or 'half references,' such as 92 or 0000092, will not be displayed.
        </p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.50d
          <span class="date">16 April 2024</span>
        </p>
        <p>[<b>AP</b>] Branches can now hold multiple contact details. A contact can be categorized as either 'Commercial' or 'TAPS', with only one type active at a time. While 'TAPS' contacts are self-explanatory, 'Commercial' contacts are used for purposes such as Felloh receipts and booking documents.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.50c
          <span class="date">06 April 2024</span>
        </p>
        <p>[<b>FP</b>] Supplier List is no longer required when creating TTA payment links.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.50b
          <span class="date">18 March 2024</span>
        </p>
        <p>[<b>AP</b>] "Cancelled" bookings are now allowed to be attached to Smart PRF.</p>
        <p>[<b>AP</b>] Changed "Gross Client Funds" to "Client Funds Banked" in PRF system.</p>
        <p>[<b>AP</b>] During the creation of a PRF, members must load at least one Bankline document or they will be stopped at Step 2.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.50a
          <span class="date">12 March 2024</span>
        </p>
        <p>[<b>BP</b>] Changed all 'Hotel' references to 'Accommodation' across the entire system</p>
        <p>[<b>BP</b>] Remittance letter is now disabled if no supplier is linked to the payment.</p>
        <p>[<b>FP</b>] Updated and fixed the Payment Charges page, now functioning properly.</p>
        <p>[<b>FP</b>] Small UI tweaks on Felloh pages.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.50
          <span class="date">05 March 2024</span>
        </p>
        <p>[<b>BP</b>] Fixed the way SAFI costs were calculated.</p>
        <p>[<b>BP</b>] Fixed a bug where the manual supplement's gross increased with each update if a discount was present.</p>
        <p>[<b>BP</b>] Visual update for supplements in the Costings tab.</p>
        <p>[<b>FP</b>] Restricted 'Team Member' users are no longer able to use the 'Payment In Person' and 'Payment Over Phone' options.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.49a
          <span class="date">29 February 2024</span>
        </p>
        <p>[<b>BP</b>] Restricted 'Team Member' users are now limited to accessing only their assigned customers.</p>
        <p>[<b>BP</b>] Managers can now set customers' availability for all users or a single user.</p>
        <p>[<b>BP</b>] Customer CSV upload is now available only to managers.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.49
          <span class="date">27 February 2024</span>
        </p>
        <p>[<b>BP</b>] Receipts can now be linked to passengers directly from the 'Receipts' table, same as how payments are linked to elements.</p>
        <p>[<b>BP</b>] Receipts automatically generated by paying through Felloh will now also automatically link passengers to them.</p>
        <p>[<b>BP</b>] Introducing a new 'Paid To Date' column in the 'Passenger List' table, displaying payments made up to the current date.</p>
        <p>[<b>BP</b>] Marking a payment as a 'Mistake' will now effectively remove outdated payment linkage.</p>
        <p>[<b>BP</b>] A new 'Payer Name' column has been added to both the Banking Report and Daily Banking for clearer payment identification.</p>
        <p>[<b>BP</b>] Few visual tweaks in Receipt and Payment Lists.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.48c
          <span class="date">20 February 2024</span>
        </p>
        <p>[<b>BP</b>] Passengers can now be cancelled from the booking. Unlike 'removing', this leaves a trace of cancelled passengers within the booking.</p>
        <p>[<b>BP</b>] Changes made to the Passenger List will now be recorded in the Booking History.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.48b
          <span class="date">16 February 2024</span>
        </p>
        <p>[<b>BP</b>] Added 'Return Date' to the Customer Balance Due Report and Reminders. Additionally, columns containing dates have been reordered.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.48a
          <span class="date">15 February 2024</span>
        </p>
        <p>[<b>BP</b>] Added option to restrict 'Team Member' access to their own bookings.</p>
        <p>[<b>BP</b>] Fixed bug causing empty 'Reminders' tab.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.48
          <span class="date">14 February 2024</span>
        </p>
        <p>[<b>FP</b>] Added 'Felloh Transactions' page listing all transactions and their details: Completed, Declined, Refunded, etc.</p>
        <p>[<b>FP</b>] Renamed 'New Transaction' button to 'New Payment Link'.</p>
        <p>[<b>FP</b>] Renamed 'Transactions' button to 'Payment Links'.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.47a
          <span class="date">12 February 2024</span>
        </p>
        <p>[<b>BP</b>] The Travel Documents section in the Booking Confirmation will now appear only if there are 10 or more days until the departure date.</p>
        <p>[<b>BP</b>] Generating an ATOL certificate will now be recorded in the Booking History.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.47
          <span class="date">08 February 2024</span>
        </p>
        <p>[<b>BP</b>] New documents (marked with a star) now offer the option to customize the document's salutation (Dear / Hi).</p>
        <p>[<b>BP</b>] Users can now toggle the inclusion of the agent's signature in all documents.</p>
        <p>[<b>AP</b>] Users can upload their own signature via account settings in the top right corner. This signature will be displayed in all documents, provided the option is enabled.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.46a
          <span class="date">31 January 2024</span>
        </p>
        <p>[<b>BP</b>] Added 'Balance Paid', 'Deposit Amount', and 'Balance Outstanding' values to the 'Booking Confirmation' document.</p>
        <p>[<b>BP</b>] The following documents have been added to the system: 'Booking Confirmation - Balance Outstanding,' 'Booking Confirmation - Paid in Full,' and 'Balance Reminder. These documents are highly customisable. Additional documents are on their way.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.46
          <span class="date">23 January 2024</span>
        </p>
        <p>[<b>BP</b>] Booking history will now show when someone has printed or sent documents to themselves or a customer.</p>
        <p>[<b>BP</b>] The way SFC supplements were calculated has been corrected. Calculations will now sum the costs of suppliers under SFC only. It will then divide the sum by the number of passengers in the booking. This number is the coverage for each passenger (i.e., £10k / 2 pax => £5k cover x 2 pax => £16.94 x 2 pax => £33.88).</p>
        <p>[<b>BP</b>] Coverage calculation for SFC can now be based on either the sum of Gross values or the sum of Net values.</p>
        <p>[<b>BP</b>] Auto-priced supplements can now be discounted.</p>
        <p>[<b>BP</b>] It is now possible to change the Travel Agent in the Booking Portfolio.</p>
        <p>[<b>AP</b>] Resolved an issue that prevented the upload of certain files in PRF and the Booking System.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.45
          <span class="date">4 January 2024</span>
        </p>
        <p>[<b>AP</b>] Three new fields have been added to the Branch setup, allowing you to save your social media links. These links will be displayed in updated (and new) documents.</p>
        <p>[<b>BP</b>] Each document can now be customised individually. Although the number of configurations is currently minimal, it sets the foundation for creating more customised documents in the future.</p>
        <p>[<b>BP</b>] The UI for sending PDF files directly to yourself or the lead passenger has been updated.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.44a
          <span class="date">28 November 2023</span>
        </p>
        <p>[<b>AP</b>] The following special characters are now allowed in the system: <b>!, #, ^, *, -, =, [, ], “, <, >, ?</b></p>
        <p>[<b>BP</b>] The Passenger List now includes new buttons that list passengers with whom the selected customer has previously traveled.</p>
        <p>[<b>BP</b>] Supplements have been removed from the Booking Confirmation document.</p>
        <p>[<b>BP</b>] The blurry text issue in the direct email window pop-up has been fixed. Additionally, the window has been adjusted for smaller screens.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.44
          <span class="date">23 November 2023</span>
        </p>
        <p>[<b>BP</b>] It is now possible to send documents directly from the system. To do this, please look for a new button located where you would typically find the print option for any document. Please note that the sender's email will be set to singsadmin@thetravelnetworkgroup.co.uk.</p>
        <p>[<b>BP</b>] The QR code in the Graphs tab is now clickable. Furthermore, all QR codes within the documents are also clickable.</p>
        <p>[<b>BP</b>] It is now possible to change the order of elements in which they will appear on the Itinerary. This can be done in the Branch Setup -> Customer Side Setup.</p>
        <p>[<b>BP</b>] Customers can now add custom elements to their Itinerary via the booking link, accessed through the QR code or URL link.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.43a
          <span class="date">15 November 2023</span>
        </p>
        <p>[<b>BP</b>] Onboard credit has been added to the Cruise element.</p>
        <p>[<b>BP</b>] Changing the departure date will automatically change the return date.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.43
          <span class="date">23 October 2023</span>
        </p>
        <p>[<b>BP</b>] A new 'Train' element type has been added to the system.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.42a
          <span class="date">13 October 2023</span>
        </p>
        <p>[<b>BP</b>] A new report called 'Destination Report' has been added to the system.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.42
          <span class="date">10 October 2023</span>
        </p>
        <p>[<b>BP</b>] The first version of the History tab in the booking portfolio has been released. The system will compare and display how certain values (not all) have changed over time.</p>
        <p>[<b>BP</b>] It is now possible to update the Country of Destination from the booking portfolio.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.41
          <span class="date">13 September 2023</span>
        </p>
        <p>[<b>AP</b>] PRFs can now offer multiple options for financial protection.</p>
        <p>[<b>BP</b>] You can now assign bookings to a group on the Booking Portfolio page. Additionally, you have the option to run various reports and booking lists using the 'Booking Group' filter.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.40
          <span class="date">05 September 2023</span>
        </p>
        <p>[<b>AP</b>] It is now possible to associate multiple bookings with a single PRF.</p>
        <p>[<b>BP</b>] Each booking's element can now contain an individual deposit amount, which is then aggregated at the booking level. The final deposit for the booking can be adjusted if needed from the 'Summary' page of the Booking.</p>
        <p>[<b>BP</b>] During the creation and modification of elements, the deposit amount will be automatically suggested based on the Supplier's configured deposit rate.</p>
        <p>[<b>BP</b>] The 'Payments' tab on the Booking's page has been enhanced. Every payment is now linked to a specific element. Each element displays 'Paid' and 'To Pay' values, which are taken into account when initiating a new payment. Furthermore, payments can be associated or disassociated from elements at any point.</p>
        <p>[<b>BP</b>] The 'Paid' values for all elements have been updated to reflect the most current payment information.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.39b
          <span class="date">14 August 2023</span>
        </p>
        <p>[<b>BP</b>] Each member can now set their own transaction fees for Amex, credit card, and debit card payments. When adding a receipt using any of the mentioned payment methods, the system will attempt to calculate and create the fee.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.39a
          <span class="date">08 August 2023</span>
        </p>
        <p>[<b>FP</b>] Improved Transaction Filtering: Easily filter Felloh transactions using any table column.</p>
        <p>[<b>FP</b>] Enhanced Reference Editing: Modify non-temporary Felloh references for better data control.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.39
          <span class="date">03 August 2023</span>
        </p>
        <p>[<b>BP</b>] The "Notes" page in the booking system has been updated. Additionally, it is now possible to set the importance of a note, which will be marked and stay on the top of the list.</p>
        <p>[<b>AP</b>] The "Booking Date" can no longer be set in the future.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.38c
          <span class="date">13 July 2023</span>
        </p>
        <p>[<b>BP</b>] Resolved an issue with CSV exports in the Daily Banking and Reminders sections on the Dashboard page.</p>
        <p>[<b>AP</b>] Excluded declined/abandoned Felloh transactions from Sunday reminder emails.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.38b
          <span class="date">16 June 2023</span>
        </p>
        <p>[<b>FP</b>] You can now re-send email confirmations for any successful transactions done via Felloh. Both emails will be sent to the email address assigned to the user.</p>
        <p>[<b>FP</b>] The email notification sent after a successful Felloh transaction has been reworded.</p>
        <p>[<b>AP</b>] Felloh-related pages have received a visual update.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.38a
          <span class="date">13 June 2023</span>
        </p>
        <p>[<b>AP</b>] The payment link email has been reworded.</p>
        <p>[<b>AP</b>] You can now upload image files in Booking Documentation and PRF Documentation (.jpg and .jpeg, max. 4MB).</p>
        <p>[<b>BP</b>] Documents will now display the current date as Day Month Year (e.g., 01 January 2023) rather than January 1st 2023.</p>
        <p>[<b>BP</b>] The wording of the Booking Confirmation document has been updated.</p>
        <p>[<b>BP</b>] The Daily Banking page now allows date range selection on the Home page.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.38
          <span class="date">05 June 2023</span>
        </p>
        <p>[<b>BP</b>] Auto-created receipts will now have their references taken from the 'Customer Name' fields rather than 'Notes' (Felloh receipts).</p>
        <p>[<b>BP</b>] All receipts and payments marked as a 'Mistake' are now hidden by default, with a checkbox to display them on demand.</p>
        <p>[<b>BP</b>] All receipts and payments are now sorted by their dates.</p>
        <p>[<b>FP</b>] The system will now send email reminders to any member having at least one temporary reference link on Sunday evenings.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.37a
          <span class="date">26 May 2023</span>
        </p>
        <p>[<b>AP</b>] The scroll wheel no longer increases/decreases numbers in form fields, preventing the rare submission of incorrect numbers to the system.</p>
        <p>[<b>AP</b>] Fixed a bug where the system generated elements with incorrect date and time under certain circumstances.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.37
          <span class="date">11 May 2023</span>
        </p>
        <p>[<b>AP</b>] An open beta for the PRF System is about to begin, with the rollout scheduled to commence in the coming weeks.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.36b
          <span class="date">22 February 2023</span>
        </p>
        <p>[<b>BP</b>] The "Receipts" tab has received a small visual update. Additionally, two new "Payment Methods" have been added: Amex and Cheque.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.36a
          <span class="date">07 February 2023</span>
        </p>
        <p>[<b>AP</b>] Users will now be automatically logged out after 30 minutes of inactivity.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.36
          <span class="date">31 January 2023</span>
        </p>
        <p>[<b>BP</b>] Safe Seat Plan Guarantee will now be added to every TTA booking upon creating the first passenger.</p>
        <p>[<b>BP</b>] It is now possible to request a new Supplier or Supplier Type in the "New Supplier" page.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.35
          <span class="date">27 January 2023</span>
        </p>
        <p>[<b>BP</b>] Car Hire elements have been updated with two new fields: Pick Up Location and Drop Off Location.</p>
        <p>[<b>BP</b>] A new report called Supplement Report has been added to the system.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.34
          <span class="date">08 January 2023</span>
        </p>
        <p>[<b>AP</b>] Updated all Felloh APIs within the system, paving the way for more features in the future.</p>
        <p>[<b>BP</b>] It is now possible to enter alphanumeric characters (e.g., 10, 55B, C32) in the Cabin Number field for cruise elements.</p>
      </div>
    </div>
    
    <div>
      <div class="changes">
        <p class="version">
          v1.33b
          <span class="date">09 December 2022</span>
        </p>
        <p>[<b>FP</b>] It is no longer possible to select a date range longer than 30 days in the "Transactions," "Disbursement," and "Payment Charges" pages.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.33a
          <span class="date">23 November 2022</span>
        </p>
        <p>[<b>BP</b>] It is now possible to upload multiple files at once in the "Documentation" tab.</p>
        <p>[<b>BP</b>] Fixed a bug where the "Contact Name" in the "Summary" tab would not change if the lead passenger was updated from the CRM page.</p>
        <p>[<b>BP</b>] Made a small visual update to the "Costings" tab for improved aesthetics.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.33
          <span class="date">31 October 2022</span>
        </p>
        <p>[<b>BP</b>] For Attractions and Miscellaneous elements, it is now possible to record time. The 'Duration' field has been replaced with an 'End Date'.</p>
        <p>[<b>BP</b>] Added a new flight direction to the list: Internal.</p>
        <p>[<b>BP</b>] Increased the maximum number of allowed characters in the following fields:</p>
        <ul style="margin-left: 35px; text-align: left;">
          <li>Car Type (car hire elements): from 30 to 255 characters.</li>
          <li>Flight Number (flight elements): from 15 to 30 characters.</li>
          <li>Carrier (flight elements): from 25 to 50 characters.</li>
        </ul>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.32
          <span class="date">26 October 2022</span>
        </p>
        <p>[<b>AP</b>] Customers can now have multiple holiday interests, which can be used for filtering customers. Additionally, a new field called "Company Name" has been added to the CRM and Passengers page.</p>
        <p>[<b>BP</b>] A new 'Calendar' functionality has been introduced in the top-right corner. For more information, please click <a
          href="https://docs.google.com/document/d/1kDn5NpJEuKYYh9TzpY__Hg4BQmbGB7DvPUgyZw5I7Ek/edit?usp=sharing" target="_blank">here</a> 
        </p>
        <p>[<b>BP</b>] Bookings can now be ordered by one of the following columns: booking reference, booking date, or departure date.</p>
        <p>[<b>BP</b>] The lead passenger field will now display the full name rather than just the surname.</p>
        <p>[<b>AP</b>] Various UI tweaks and bug fixes have been implemented.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.31
          <span class="date">12 October 2022</span>
        </p>
        <p>[<b>BP</b>] Hotel elements can now accommodate multiple rooms.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.30
          <span class="date">03 October 2022</span>
        </p>
        <p>[<b>AP</b>] The system now accepts the following special characters: <b>' ; : & @ £ $ % ( ) + /</b></p>
        <p>[<b>BP</b>] Supplier names have been updated, and names with special characters will now be correctly displayed by the system.</p>
        <p>[<b>BP</b>] Fixed all detected dates in the wrong format and ensured that all numbers have two trailing zeros in documents from now on.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.29a
          <span class="date">26 September 2022</span>
        </p>
        <p>[<b>BP</b>] Fixed an issue where the customer's date of birth would not be saved if the customer was created from the booking.</p>
        <p>[<b>BP</b>] Added postcode address search to the Passengers tab.</p>
        <p>[<b>BP</b>] Introduced a new holiday interest - Golf Holidays.</p>
        <p>[<b>AP</b>] Changing a customer's existing postcode will now correctly reset all address lines.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.29
          <span class="date">07 September 2022</span>
        </p>
        <p>[<b>AP</b>] Users from the booking system are now permitted to create Felloh payment links with temporary references.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.28
          <span class="date">24 August 2022</span>
        </p>
        <p>[<b>BP</b>] The customer-view has been updated to provide control over the website's flow.
          Click <a href="https://docs.google.com/document/d/1yznNcziQNVBzfe2KKFMPYDKNnMIQG2p4wPcF4nK7Jzg/edit?usp=sharing" target="_blank">here</a>
          for more details</p>
        <p>[<b>BP</b>] Added auto-sizing to text area fields, such as "Add New Note," in the Booking Portfolio.</p>
        <p>[<b>BP</b>] It is now possible to amend an element's supplier reference.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.27a
          <span class="date">26 July 2022</span>
        </p>
        <p>[<b>FP</b>] Fixed an issue where the home screen paginators displayed "0" instead of the actual number of rows.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.27
          <span class="date">20 July 2022</span>
        </p>
        <p>[<b>AP</b>] Customers are now sorted in order (from A to Z) based on last name, first name, and email address.</p>
        <p>[<b>AP</b>] Fixed a bug in CRM where address fields would not be cleared out after adding a customer to the system.</p>
        <p>[<b>AP</b>] Exporting the customer list will now include all customers based on the set search criteria.</p>
        <p>[<b>AP</b>] Added the missing Post Code field to Customer List Export files.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.26
          <span class="date">18 July 2022</span>
        </p>
        <p>[<b>AP</b>] Customers now have two new fields: 'holiday interests' and 'customer notes.'</p>
        <p>[<b>AP</b>] Added additional password validations to enforce password complexity for users.</p>
        <p>[<b>BP</b>] Increased the maximum number of passengers in elements to 100.</p>
        <p>[<b>BP</b>] Cruise elements can now accommodate multiple cabins.</p>
        <p>[<b>BP</b>] Redesigned the process for adding passengers to a booking, making it more efficient and requiring fewer steps.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.25
          <span class="date">21 June 2022</span>
        </p>
        <p>[<b>FP</b>] Added a new page called Felloh Charges, which displays Felloh charges based on individual payments.</p>
        <p>[<b>FP</b>] All pages now include 'Reload Transactions' buttons, replacing the previous auto-reload when switching tabs.</p>
        <p>[<b>FP</b>] Improved the way Felloh services are called, which should enhance the speed of service.</p>
        <p>[<b>FP</b>] Made minor updates to table presentations for improved visuals.</p>
        <p>[<b>FP</b>] Fixed a few discovered typos and bugs.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.24
          <span class="date">13 June 2022</span>
        </p>
        <p>[<b>BP</b>] Managers can now undo payments and receipts. If undone, their status will be set to 'Mistake,' and they will not be included in calculations.</p>
        <p>[<b>BP</b>] Added a Supplier Due Date feature to suppliers within bookings. It will be automatically calculated when creating and editing them.</p>
        <p>[<b>BP</b>] Managers can now edit the date of receipt and payment, as well as remove booking notes.</p>
        <p>[<b>BP</b>] Increased the maximum number of allowed characters in Notes from 100 to 500.</p>
        <p>[<b>BP</b>] It is now possible to change the Lead Supplier from the Summary tab.</p>
        <p>[<b>BP</b>] Uploaded documents are now auto-loaded when opening the Docs tab.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.23
          <span class="date">02 June 2022</span>
        </p>
        <p>[<b>FP</b>] Fixed a bug where AMEX payments were not appearing on the Transaction List page.</p>
        <p>[<b>FP</b>] Fixed a bug where creating 'Open Banking Only' payments would result in an 'Invalid Character' message.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.22
          <span class="date">31 May 2022</span>
        </p>
        <p>[<b>BP</b>] Added a new tab called Performance to the Dashboard. This tab includes two charts that illustrate and compare information with the previous months, covering up to 12 months prior to the current date.</p>
        <p>[<b>BP</b>] Increased the maximum number of passengers in all elements from 15 to 50.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.21
          <span class="date">12 May 2022</span>
        </p>
        <p>[<b>BP</b>] Increased the maximum number of characters in the hotel's name and city from 30 to 100.</p>
        <p>[<b>BP</b>] Increased the maximum number of characters in transfer's pick-up and drop-off instructions from 50 to 200.</p>
        <p>[<b>BP</b>] The booking summary now displays the number of passengers.</p>
        <p>[<b>BP</b>] Fixed an issue where the page would break if the supplier happened to be hidden, not available to a member, or no longer existed in the system.</p>
        <p>[<b>BP</b>] Suppliers can now be categorized as either globally available, exclusive to a membership, or exclusive to selected members.</p>
        <p>[<b>AP</b>] Implemented character validation for all forms. Submissions will be halted if a field contains special characters (although some characters are still allowed).</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.20
          <span class="date">27 April 2022</span>
        </p>
        <p>The site has been "zoomed out" by default, resulting in pages feeling slightly smaller. You can still zoom in by holding the Ctrl button and using your scroll wheel.</p>
        <p>Reorganized some fields, such as those on the Branch and CRM pages, for improved organization and usability.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.19
          <span class="date">20 April 2022</span>
        </p>
        <p>Managers now have the ability to remove their customers from the system. The "Remove" button can be found on the CRM page.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.18
          <span class="date">18 February 2022</span>
        </p>
        <p>Felloh receipts/confirmations will now be sent to the person who created the link, rather than to the branch default email address.</p>
        <p>The "Get Template" button on the CRM page (Upload Customer Data) now downloads the correct file.</p>
        <p>Improved the intuitiveness of the Supplier Names field when creating a new payment link.</p>
        <p>Made various small improvements to the User Interface for a better user experience.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.17
          <span class="date">14 February 2022</span>
        </p>
        <p>Team Members within the same branch can no longer view other users' transactions. This change does not impact Shop Managers.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.16
          <span class="date">02 February 2022</span>
        </p>
        <p>The CRM has received an update, including a visual refresh and additional functionality. Users can now download templates and use them to upload their customer base into the system.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.15
          <span class="date">25 January 2022</span>
        </p>
        <p>Introduced a new page called "Disbursement," accessible to all users, allowing tracking of payments from the Trust account.</p>
        <p>Added the last four digits and cardholder's name to the Payment List table for enhanced payment details.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.14
          <span class="date">24 January 2022</span>
        </p>
        <p>Added a new payment option that specifies the accepted payment method (Any / Open Banking / Card).</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.13
          <span class="date">18 January 2022</span>
        </p>
        <p>Fixed a bug where Felloh payments were not sorted properly by their statuses (Created / Paid).</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.12
          <span class="date">14 January 2022</span>
        </p>
        <p>Confirmation emails sent to shops will now include the amount paid by customers.</p>
        <p>Added an extra column, "Card Type," to the Transaction List table, and updated the file export accordingly.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.11
          <span class="date">04 January 2022</span>
        </p>
        <p>Renamed user types.</p>
        <p>Special characters are no longer allowed in Felloh payment descriptions.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.1
          <span class="date">07 December 2021</span>
        </p>
        <p>TTA members can now create Felloh payment links.</p>
        <p>Added animations for a smoother user experience.</p>
        <p>Users can now duplicate Felloh payment links.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.04
          <span class="date">18 November 2021</span>
        </p>
        <p>Receipts will now be sent to the shop if the customer's email was not provided.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.03
          <span class="date">12 November 2021</span>
        </p>
        <p>Managers now have the capability to update their logos and footers.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.02
          <span class="date">03 November 2021</span>
        </p>
        <p>Appropriate messages are now displayed after declined payments in Felloh.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.01
          <span class="date">28 October 2021</span>
        </p>
        <p>Pop-up windows will no longer force the scrollbar to appear on the screen.</p>
        <p>Corrected some issues with table styling for improved appearance and consistency.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v1.00
          <span class="date">27 October 2021</span>
        </p>
        <p>Customer emails are no longer mandatory when creating payment links, although they are highly recommended.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v0.42
          <span class="date">26 October 2021</span>
        </p>
        <p>Adjusted Felloh emails for improved functionality.</p>
        <p>Removed the 'Rows' field from the page.</p>
        <p>Set the 'Payment List' tab to open first for user convenience.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v0.41
          <span class="date">22 October 2021</span>
        </p>
        <p>Made adjustments to improve the user experience on smaller screens.</p>
        <p>Increased the minimum payment on Felloh to £1.</p>
        <p>Grouped Felloh payments into two categories: Requested and Paid.</p>
        <p>Resolved an issue with Time Zones where the time was not displaying correctly.</p>
        <p>Implemented animation for table rows, ensuring they close properly upon clicking.</p>
        <p>Made spelling and style corrections for enhanced readability and consistency.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v0.4
          <span class="date">13 October 2021</span>
        </p>
        <p>Increased the £ limit when creating Felloh links.</p>
        <p>Member Staff now have the ability to change TEMP reference links.</p>
        <p>FX Transactions are now available only for selected shops.</p>
        <p>Made wording fixes in various places for improved clarity and consistency.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v0.39
          <span class="date">12 October 2021</span>
        </p>
        <p>Managers now have the ability to update their branch's general information.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v0.38
          <span class="date">11 October 2021</span>
        </p>
        <p>Added a new filter for Felloh payments: All/Created/Received.</p>
        <p>Introduced a new user type, Felloh Manager, which is positioned above the existing Felloh Staff.</p>
        <p>Released a new version of FX Transactions.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v0.37
          <span class="date">09 October 2021</span>
        </p>
        <p>Time zones have been changed to GMT.</p>
        <p>On mobile devices, adjusted the positions and sizes of some elements to improve the user experience on smaller screens.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v0.36
          <span class="date">07 October 2021</span>
        </p>
        <p>Set autocomplete form color to match the company's branding color.</p>
        <p>Added the SinGS logo to the home page.</p>
        <p>CRM now provides a table for all user types, which can be easily exported.</p>
        <p>Made various code and spelling fixes for improved performance and accuracy.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v0.35
          <span class="date">07 October 2021</span>
        </p>
        <p>Successfully completing the Felloh payment will now redirect to the Customer page.</p>
        <p>Customer export functionality is now available for SinGS admins and staff.</p>
        <p>Moved the logout button to the top navigation bar, resulting in a slight alteration to the bar.</p>
        <p>On mobile devices, as the screen width decreases, the page will now "zoom out," making the elements slightly smaller for improved usability.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v0.34
          <span class="date">04 October 2021</span>
        </p>
        <p>Added new email templates.</p>
        <p>Made the existing Felloh booking option available for use.</p>
        <p>Implemented a few other minor fixes.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v0.33
          <span class="date">30 September 2021</span>
        </p>
        <p>Updated the 'Overview' menu in Felloh.</p>
        <p>Made adjustments to some pages to improve compactness.</p>
        <p>Added buttons for closing pop-up messages.</p>
        <p>Implemented a few UI improvements.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v0.32
          <span class="date">26 September 2021</span>
        </p>
        <p>Added 'Help' buttons in the top right corners of the page to provide explanations about the page's structure.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v0.31
          <span class="date">24 September 2021</span>
        </p>
        <p>Added postcode auto-fill functionality to the CRM page.</p>
        <p>Made a few minor UI tweaks.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v0.3
          <span class="date">04 August 2021</span>
        </p>
        <p>Implemented the latest UI update, which has been approved for online testing. Version details have been finalized.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v0.2
          <span class="date">15 July 2021</span>
        </p>
        <p>Success and failure messages will now be displayed directly on the page, rather than above or below the submit buttons.</p>
      </div>
    </div>

    <div>
      <div class="changes">
        <p class="version">
          v0.1
          <span class="date">14 July 2021</span>
        </p>
        <p>Added a new changelog feature to keep users informed about the latest changes and fixes.</p>
        <p>Made additional UI improvements for mobile and tablet devices.</p>
      </div>
    </div>

  </mat-card>
</div>