import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { BranchService } from '../../services/branch.service';
import { SupplierService } from '../../services/supplier.service';
import { GlobalConstants } from '../../common/global-constants';
import { AppComponent } from '../../app.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Session } from '../../common/session';
import { NgForm } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', marginTop: '-1.25px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0 }),
            animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)',
              style({ height: 230 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 300 }),
            animate('250ms cubic-bezier(0.4, 0.0, 0.2, 1)',
              style({ height: 0 }))
          ]
        )
      ]
    ),
    trigger('inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('375ms cubic-bezier(.67,.52,.34,.82)',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})

export class UserListComponent implements OnInit {
  // Boolean deciding whether user has access or not
  loggedInType = '';
  loggedEmail = '';
  haveAccess = false;
  pageLoaded = false;
  createView = false;
  editView = true;
  isSupplier = false;

  // Imported variables from outside
  constants = new GlobalConstants();
  innerWidth = AppComponent.myapp.innerWidth;

  // The userData from API will be assign to this variable. The MatTableDataSource class
  // is used for to the whole Component to work (+ it has its own built-in functions)
  userData: any = new MatTableDataSource<any>();

  // List of the table columns which are displayed in .html file
  displayedColumns = ['fullName', 'company', 'operation', 'tradeCode', 'userType', 'blocked', 'lastLoggedIn', 'expand'];

  // Varaible imported from global consts as a total pool of options
  companies: any = [];
  operations: any = [];
  branchData: any = [];
  userTypes: any = [];
  bookingPrefixes: any = [];
  userGroups: any = [null];
  supplierList: any = [];

  // Variables used in view as a filtered pool of options + chosen option
  filteredOperations: any = [];
  chosenOperation: any = '';
  filteredBranches: any = [];
  chosenBranch: any = '';
  filteredPrefixes: any = [];
  chosenPrefix: any = '';
  othersAccess = true;
  othersDisabled = true;
  userTypeAllowed = true;

  // Other variables
  currentRequest: any = {};
  errorMessage: any = '';
  successMessage: any = '';
  passwordMessage: any = ''; // Used to print out password feedback to the user

  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;
  @ViewChild('helpDialog') helpDialog!: TemplateRef<any>;

  // Stuff needed for the 'expandable rows' to work
  expandedElement: any;
  isExpansionDetailRow = (i: number, row: object) => row.hasOwnProperty('detailRow');

  constructor(private router: Router, private userService: UserService, private supplierService: SupplierService,
              private branchService: BranchService, public dialog: MatDialog) { }

  ngOnInit(): void {
    if (sessionStorage.length === 0 || Session.mySession === undefined) {
      // If the browser refreshed, SinGS redirects the page to the main page (components/home)
      this.router.navigate(['/']);
    } else {
      // If the page has been accessed by clicking a button - get userType & userCompany and load the page
      this.loggedInType = Session.mySession.getUser().userType;
      this.loggedEmail = Session.mySession.getUser().email;
      this.currentRequest = {
        tradeCode: Session.mySession.getUser().tradeCode, token: Session.mySession.get('user').token
      };
      this.loadPage();
    }
  }

  loadPage(): void {
    if (this.loggedInType === 'sinGSAdmin' || this.loggedInType === 'memberManager' || this.loggedInType === 'wcManager' || this.loggedInType === 'tapsAdmin') {
      this.haveAccess = true; // Page available to all non-Felloh users
      this.companies = this.constants.getCompanies(Session.mySession.getUser()); // Get list based on the user
      this.operations = this.constants.getOperations(Session.mySession.getUser()); // Get list based on the user
      this.userTypes = this.constants.getUserTypes(Session.mySession.getUser()); // Get list based on the user
      this.bookingPrefixes = this.constants.getBookingPrefixes(Session.mySession.getUser()); // Get list based on the user

      // Call getBranches API and assign its output to 'branches'
      if (Session.mySession.getUsersGroup().length > 0) {
        // Logged in user is in the group and branch list already exists within the session variable..
        this.branchData = Session.mySession.getUsersGroup(); this.filteredBranches = this.branchData;
      } else if (Session.mySession.getBranchList().expiryTime === 'EXPIRED') {
        this.branchService.getBranches(Session.mySession.getUser()).then((branches: any) => {
          if (branches.status === 'OK') {
            // Assign sorted to global variable and session varaible (for later use) and call loadBookings()
            const sorted = branches.data.sort((a: any, b: any) => (a.fullName > b.fullName) ? 1 : -1);
            this.branchData = sorted;
            Session.mySession.setBranchList(sorted);
          } else {
            // Status was not OK meaning something went wrong with it.. display it to the user
            this.sendMessageToDialog('', branches.status, '', '');
            this.haveAccess = false;
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0601S)', error, Session.mySession.getUser());
        });
      } else {
        // Get branch list from the session varaible - no need to call API
        this.branchData = Session.mySession.getBranchList().branchList;
        // Assign sorted to global variable and session varaible (for later use) and call loadBookings()
        this.branchData = this.branchData.sort((a: any, b: any) => (a.fullName > b.fullName) ? 1 : -1);
      }

      // Only admin is able to pull user groups and assign users to them
      if (this.loggedInType === 'sinGSAdmin' || this.loggedInType === 'tapsAdmin') {
        this.branchService.getUserGroupList(Session.mySession.getUser()).then((userGroups: any) => {
          if (userGroups.status === 'OK') {
            // In order to make the table 'expandable', we need to add a new property to each User Group object
            userGroups.data.forEach((element: any) => {
              element.tradeCodes = element.tradeCodes.split(';'); // Transalte string to array here..
              element.listCodes = ''; // Create new property to hold string-list
              // Add branch to the string-list
              // tslint:disable-next-line:only-arrow-functions
              element.tradeCodes.forEach(function(tradeCode: any, index: any): any {
                element.listCodes = element.listCodes + tradeCode + ', ';
                // Check if this is the last item in an array - if so, remove last two characters in the string
                if (index === element.tradeCodes.length - 1) { element.listCodes = element.listCodes.substring(0, element.listCodes.length - 2); }
              });
            });
            // Sort users by full name and assign paginator to the data table
            const userGroupsSorted = userGroups.data.sort((a: any, b: any) => (a.groupName > b.groupName) ? 1 : -1);
            this.userGroups = userGroupsSorted; this.userGroups.unshift(null); // Add 'no branch' to the beginning of the array
            this.getSupplierList();
            this.getUserList();
          } else {
            this.sendMessageToDialog('', userGroups.status, '', ''); this.haveAccess = false; // No access for the user - sorry!
          }
        });
      } else {
        // If user is already in the group, add that group to the array list so it's selectable (by managers only)
        if (Session.mySession.getUser().group != null && this.userGroups.length === 1) { this.userGroups.push(Session.mySession.getUser().group); }
        this.getUserList();
      }
    } else {
      this.pageLoaded = true; // Page loaded but no access - sorry
    }
  }

  getUserList(): void {
    this.userService.getUserList(this.currentRequest).then((users: any) => {
      if (users.status === 'OK') {
        // In order to make the table 'expandable', we need to add a new property to each User object
        users.data.forEach((element: any) => element.detailRow = true, (element: any) => element.resetPasswordOpen = false);

        let niceUserTypes: any = []; // Array list which we'll override multiple times..
        users.data.forEach((element: any) => {
          // Override types again..
          niceUserTypes = this.userTypes.filter((type: any) => type.value === element.userType);
          // Found mapping - assign it to new property..
          if (niceUserTypes.length > 0) { element.niceUserType = niceUserTypes[0].viewValue; }
          // Mapping not found - user type becomes nice user type (oh well..)
          else { element.niceUserType = ''; }
          // Format last logged in property in the right time format
          if (element.lastLoggedIn != null) { element.lastLoggedIn = element.lastLoggedIn.slice(0, -5).replace('T', ' '); }
        });
        // Sort users by full name and assign paginator to the data table
        const usersSorted = users.data.sort((a: any, b: any) => (a.fullName > b.fullName) ? 1 : -1);
        this.userData.data = usersSorted;
        this.userData.paginator = this.paginator;
        this.pageLoaded = true;
      } else {
        this.sendMessageToDialog('', users.status, '', '');
        this.haveAccess = false; // No access for the user - sorry!
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0602S)', error, this.currentRequest);
    });
  }

  getSupplierList(): void {
    if (Session.mySession.getSupplierList('Q0000').expiryTime === 'EXPIRED') {
      const request = {
        company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation,
        tradeCode: Session.mySession.getUser().tradeCode, token: Session.mySession.get('user').token
      };
      this.supplierService.getSupplierList(request).then((suppliers: any) => {
        if (suppliers.status === 'OK') {
          const sorted = suppliers.data.sort((a: any, b: any) => (a.supplierNameM > b.supplierNameM) ? 1 : -1); // Sort suppliers alphabetically
          Session.mySession.setSupplierList(Session.mySession.getUser().tradeCode, sorted); // Set suppliers values in session
          this.supplierList = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a.supplierNameM === e.supplierNameM) === i); // Group suppliers (1 of each name) here
        } else {
          this.sendMessageToDialog('', 'SinGS could not load suppliers (' + suppliers.status + ')', '', ''); // Print error message..
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0608S)', error, request);
      });
    } else {
      const sorted = Session.mySession.getSupplierList(Session.mySession.getUser().tradeCode).supplierList.sort((a: any, b: any) => (a.supplierNameM > b.supplierNameM) ? 1 : -1);
      this.supplierList = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a.supplierNameM === e.supplierNameM) === i); // Group suppliers (1 of each name) here
    }
  }

  editUser(form: NgForm, user: any): void {
    // Check if the booking prefix actually can be assigned to the company
    if ((form.value.bookRefStr === 'GTGS' && form.value.company !== 'gtg') ||
      (form.value.bookRefStr === 'ttng' && form.value.company !== 'ttng') ||
      ((form.value.bookRefStr === 'TTAS' || form.value.bookRefStr === 'TTAF') && form.value.company !== 'tta')) {
      // Prefix must belong to the right user type (check only in Felloh users)
      this.sendMessageToDialog('', 'Prefix ' + form.value.bookRefStr.toUpperCase() + ' does not belong to ' + form.value.company.toUpperCase(), '', '');
    } else if ((form.value.userType === 'sinGSAdmin' || form.value.userType === 'sinGSstaff' || form.value.userType === 'trustee' ||
                form.value.userType === 'supplier' || form.value.userType === 'tapsAdmin') && form.value.tradeCode !== 'Q0000') {
      // Check if admin / trustee branch is set to Q0000
      this.sendMessageToDialog('', 'This user type must have its branch set to Q0000 Head Office', '', '');
    } else if (this.constants.validateFormCharacters(form) !== true) {
      // Special characters check
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else {
      // Set access to other's bookings - mainly for staff and no one else..
      if (user.userType === 'wcMember' || user.userType === 'memberStaff') { form.value.othersBookingAccess = user.othersBookingAccess; }
      else { form.value.othersBookingAccess = 'yes'; }

      this.pageLoaded = false;
      form.value.token = Session.mySession.get('user').token;
      // Update user and assign appropiate output message to it
      this.userService.updateUser(form.value).then((result: any) => {
        if (result.status === 'OK') {
          // Try to assign newly (if so) updated user type here..
          try { user.niceUserType = this.userTypes.filter((type: any) => type.value === user.userType)[0].viewValue; }
          catch { }
          this.sendMessageToDialog('User has been updated', '', '', '');
        } else {
          this.sendMessageToDialog('', result.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0603S)', error, form.value);
      });
    }
  }

  doFilter(event: Event): void {
    // Function used to Filter the table by the user's input
    const filterValue = (event.target as HTMLInputElement).value;
    this.userData.filter = filterValue.trim().toLowerCase();
  }

  changeCompany(user: any): void {
    // Automatically change the user's operation after selecting new company
    if (user.company === 'ttng') {
      user.operation = 'retail';
    } else if (user.company === 'gtg') {
      user.operation = 'member';
    } else if (user.company === 'tta') {
      user.operation = 'tta';
    }
    // Company has been changed which means we need to filter branches again
    this.filterBranches(user.company, user);
  }

  filterType(event: any): void {
    // Filter companies below - some user types will only belong to TTNG
    if (event === 'sinGSAdmin' || event === 'sinGSstaff' || event === 'trustee' || event === 'supplier' || event === 'tapsAdmin') {
      this.companies = [{ value: 'ttng', viewValue: 'TTNG' }];
    } else {
      this.companies = this.constants.getCompanies(Session.mySession.getUser());
    }
    // Check if selected type is 'supplier' if so, set global variable to lock/unlock select field
    if (event === 'supplier') { this.isSupplier = true; }
    else { this.isSupplier = false; }
    // Check if selected type is staff. If so, set others booking access to false as a default
    if (event === 'memberStaff' || event === 'wcMember') { this.othersAccess = false; this.othersDisabled = false; }
    else { this.othersAccess = true; this.othersDisabled = true; }
  }

  filterBranches(event: any, user: any): void {
    // Depending on selected company, we need to filter out any unnecessary branches (membership types don't belong to some and belong to others)
    if (event === 'ttng') {
      this.filteredBranches = this.branchData.filter((branch: any) => branch.membershipType === 'worldchoicePlus' || branch.membershipType === 'worldchoice');
      this.filteredOperations = this.operations.filter((operation: any) => operation.value === 'retail');
      this.filteredPrefixes = this.bookingPrefixes.filter((prefix: any) => prefix.value === 'ttng');
    } else if (event === 'gtg') {
      this.filteredBranches = this.branchData.filter((branch: any) => branch.membershipType === 'globalTravel');
      this.filteredOperations = this.operations.filter((operation: any) => operation.value === 'member');
      this.filteredPrefixes = this.bookingPrefixes.filter((prefix: any) => prefix.value === 'GTGS');
    } else if (event === 'tta') {
      this.filteredBranches = this.branchData.filter((branch: any) => branch.membershipType === 'tta');
      this.filteredOperations = this.operations.filter((operation: any) => operation.value === 'tta');
      this.filteredPrefixes = this.bookingPrefixes.filter((prefix: any) => prefix.value === 'TTAF' || prefix.value === 'TTAS');
    }
    // Whether we're editing user or creating new one, we ought to reset selected operation, branch and booking prefix to undefined
    if (user == null) {
      this.chosenOperation = '';
      this.chosenBranch = '';
      this.chosenPrefix = '';
    } else {
      user.tradeCode = '';
      user.operation = '';
      user.bookRefStr = '';
    }
  }

  changeBranch(tradeCode: any): void {
    this.currentRequest.tradeCode = tradeCode; // Assign new trade code to the 'global' request
    this.pageLoaded = false; this.getUserList(); // Get user list for selected trade code
  }

  createUser(form: NgForm): void {
    if (form.value.password !== form.value.passwordRepeat) {
      // Passwords must match (may implement strong password thingy at some point - TBD)
      this.sendMessageToDialog('', 'Passwords don\'t match', '', '');
    } else if (form.valid === false) {
      // All selects must be 'selected'
      this.sendMessageToDialog('', 'Please fill in all required fields', '', '');
    } else if (this.constants.validateFormCharacters(form) !== true) {
      // Special characters check
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else if ((form.value.userType === 'sinGSAdmin' || form.value.userType === 'sinGSstaff' || form.value.userType === 'trustee' ||
                form.value.userType === 'supplier' || form.value.userType === 'tapsAdmin') && form.value.tradeCode !== 'Q0000') {
      // Check if admin / trustee branch is set to Q0000
      this.sendMessageToDialog('', 'This user type must have its branch set to Q0000 Head Office', '', '');
    } else if ((form.value.company === 'ttng' && form.value.operation !== 'retail') || (form.value.company === 'gtg' && form.value.operation !== 'member') ||
      (form.value.company === 'tta' && form.value.operation !== 'tta')) {
      // This checks if operation belongs to company
      this.sendMessageToDialog('', form.value.operation[0].toUpperCase() + form.value.operation.slice(1) + ' does not belong to ' + form.value.company.toUpperCase(), '', '');
    } else if ((form.value.bookRefStr === 'GTGS' && form.value.company !== 'gtg') ||
      (form.value.bookRefStr === 'ttng' && form.value.company !== 'ttng') ||
      ((form.value.bookRefStr === 'TTAS' || form.value.bookRefStr === 'TTAF') && form.value.company !== 'tta')) {
      // This checks if prefix belongs to company
      this.sendMessageToDialog('', 'Prefix ' + form.value.bookRefStr.toUpperCase() + ' does not belong to ' + form.value.company.toUpperCase(), '', '');
    } else {
      // Production property used in Ruby's bit where we're sending emails to new users (we include URL there..)
      form.value.production = environment.production;
      form.value.token = Session.mySession.get('user').token;
      // Set other's booking access below. It's pretty much done only for staff
      if (this.othersAccess) { form.value.othersBookingAccess = 'yes' }
      else { form.value.othersBookingAccess = 'no'; }

      this.pageLoaded = false;
      this.userService.createUser(form.value).then((result: any) => {
        if (result.status === 'OK') {
          // If user creation was successful, switch view to user list
          if (form.value.sendEmail === true) {
            this.sendMessageToDialog('User created! Details have been sent to the user', '', '', '');
          } else {
            this.sendMessageToDialog('User created!', '', '', '');
          }
          this.switchView('edit');
        } else {
          this.sendMessageToDialog('', result.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0604S)', error, form.value);
      });
    }
  }

  removeUser(user: any): void {
    // User needs to confirm twice before sending removeUser call
    if (confirm('Are you sure you want to remove ' + user.fullName + '?')) {
      if (confirm('Still sure you want to remove ' + user.fullName + '?')) {
        this.pageLoaded = false;
        user.token = Session.mySession.get('user').token;
        this.userService.removeUser(user).then((result: any) => {
          if (result.status === 'OK') {
            // If user removed successfully - filter it out from the data table
            this.userData.data = this.userData.filteredData.filter((item: any) => item.id !== user.id);
            this.sendMessageToDialog('User has been removed', '', '', '');
          } else {
            this.sendMessageToDialog('', result.status, '', '');
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0605S)', error, user);
        });
      }
    }
  }

  switchBlockUser(user: any, status: any): void {
    // User needs to confirm that it wants to block another user
    if (confirm('Are you sure you want to ' + status + ' user ' + user.fullName)) {
      this.pageLoaded = false;
      user.token = Session.mySession.get('user').token;
      this.userService.switchBlockUser(user).then((result: any) => {
        if (result.status === 'OK') {
          if (user.blocked === 'yes') {
            user.blocked = 'no';
            this.sendMessageToDialog('User has been unblocked', '', '', '');
          } else if (user.blocked === 'no') {
            user.blocked = 'yes';
            this.sendMessageToDialog('User has been blocked', '', '', '');
          }
        } else {
          this.sendMessageToDialog('', result.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0606S)', error, user);
      });
    }
  }

  checkboxYesNo(event: any, object: any, property: any): void {
    if (!event.target.checked) { object[property] = 'no'; }
    else { object[property] = 'yes'; }
  }

  openClosePasswordDiv(user: any, status: boolean): void {
    // Calling this function will switch users property used to open/close its password input boxes
    user.resetPasswordOpen = status;
  }

  validatePassword(input: any): void {
    this.passwordMessage = this.constants.validatePassword(input.value);
  }

  changeUserPassword(form: NgForm, user: any): void {
    if (form.value.password !== form.value.passwordRepeat) {
      // Passwords need to match first
      this.sendMessageToDialog('', 'Passwords don\'t match', '', '');
    } else {
      this.pageLoaded = false;
      const request = { id: user.id, password: form.value.password, sendEmail: form.value.sendEmail, production: environment.production, token: Session.mySession.get('user').token };
      this.userService.setOtherUserPassword(request).then((result: any) => {
        if (result.status === 'OK') {
          if (form.value.sendEmail === true) {
            this.sendMessageToDialog('Password has been updated and sent to the user', '', '', '');
          } else {
            this.sendMessageToDialog('Password has been updated', '', '', '');
          }
        } else {
          this.sendMessageToDialog('', result.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0607S)', error, request);
      });
    }
  }

  exportToExcel(): void {
    const exportMe: any = [];
    let oneLine: any = {};
    // For each customer object, oneLine will be generated with appropiate values
    // And then pushed to exportMe which will be.. exported
    this.userData.data.forEach((data: any) => {
      oneLine = {};
      oneLine.fullName = data.fullName;
      oneLine.email = data.email;
      oneLine.userType = data.niceUserType;
      if (data.userType === 'sinGSAdmin' || data.userType === 'sinGSstaff') {
        oneLine.access = 'Admin';
      } else if (data.userType === 'memberManager' || data.userType === 'memberStaff') {
        oneLine.access = 'Booking & Felloh';
      } else if (data.userType === 'wcManager' || data.userType === 'wcMember') {
        oneLine.access = 'Felloh Only';
      } else if (data.userType === 'trustee') {
        oneLine.access = 'Trustee';
      } else if (data.userType === 'supplier') {
        oneLine.access = 'Supplier';
      } else if (data.userType === 'tapsAdmin') {
        oneLine.access = 'TAPS Admin';
      }
      oneLine.company = data.company;
      oneLine.tradeCode = data.tradeCode;
      oneLine.blocked = data.blocked;
      oneLine.lastLoggedIn = data.lastLoggedIn;
      exportMe.push(oneLine);
    });
    this.constants.exportAsExcelFile(exportMe, 'userList');
  }

  switchView(view: any): void {
    this.pageLoaded = false;
    this.isSupplier = false;
    this.expandedElement = null;

    this.filteredOperations = [];
    this.filteredBranches = [];
    this.filteredPrefixes = [];
    this.passwordMessage = '';

    if (view === 'create') {
      this.createView = true;
      this.editView = false;
      this.pageLoaded = true;
    } else if (view === 'edit') {
      this.createView = false;
      this.editView = true;
      this.loadPage();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    this.pageLoaded = true; this.dialog.open(this.statusDialog);
  }

  showHelp(): void {
    this.dialog.open(this.helpDialog);
  }
}
